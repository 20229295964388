define("client/pods/components/guide-menu/guide-menu-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FJMwNe8N",
    "block": "{\"symbols\":[\"@formInitData\",\"&default\"],\"statements\":[[0,\"\\n\"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guide-menu/guide-menu-nav/template.hbs"
    }
  });
});