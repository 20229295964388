define("client/pods/components/menus/nav-menu/nav-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZIsM+Vde",
    "block": "{\"symbols\":[\"@item\",\"@translate\",\"@errors\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"button nav-item\\n    \",[28,\"if\",[[23,1,[\"_data\",\"className\"]],[23,1,[\"_data\",\"className\"]],\"\"],null],\"\\n    \",[28,\"if\",[[23,0,[\"isActive\"]],\"is-active\",\"\"],null],\"\\n  \"]]],[12,\"disabled\",[23,0,[\"isDisabled\"]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"select\"]],[23,1,[]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"_data\",\"icon\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[23,1,[\"_data\",\"icon\"]]]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"u-icon-spacer--right\"],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"label\"]]],[[\"translate\"],[[23,2,[]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"_data\",\"badge\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"element-btn__badge\"],[8],[0,\"\\n      \"],[1,[23,1,[\"_data\",\"badge\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"msgs/error-badge\",null,[[\"errors\",\"errorsTracker\"],[[23,3,[]],[23,1,[\"_data\",\"errorsTracker\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-menu/nav-item/template.hbs"
    }
  });
});