define("client/pods/components/channels/shop/campaigns/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4++8ihVa",
    "block": "{\"symbols\":[\"@parentTab\",\"@tab\",\"@tabContentOnly\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor\",[],[[\"@isEditing\",\"@isNew\",\"@model\",\"@onCancel\",\"@parentTab\",\"@resourceList\",\"@saveTask\",\"@tab\",\"@tabContentOnly\"],[true,true,[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],\"cancel\"],null],[23,1,[]],[23,0,[\"crud\",\"lists\",\"channels-shop-campaigns-lists-editorSubPanels\"]],[22,\"createRecordTask\"],[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-new/template.hbs"
    }
  });
});