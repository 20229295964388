define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/regions-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VKq5nQIp",
    "block": "{\"symbols\":[\"region\",\"@isReadonly\",\"@country\",\"@shippingZone\"],\"statements\":[[4,\"if\",[[23,0,[\"regions\"]]],null,{\"statements\":[[4,\"each\",[[23,0,[\"regions\"]]],null,{\"statements\":[[0,\"    \"],[5,\"channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/regions-column/regions-row\",[],[[\"@country\",\"@hasSelectedRegions\",\"@isReadonly\",\"@shippingZone\",\"@region\"],[[23,3,[]],[23,0,[\"hasSelectedRegions\"]],[23,2,[]],[23,4,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"u-padding-top-5 u-padding-bottom-5\"],[8],[0,\"\\n    \"],[5,\"elements/element-checkbox\",[],[[\"@disabled\",\"@class\",\"@allowToggle\",\"@value\"],[[23,2,[]],\"u-display-block\",false,true]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"tt\",[\"all\"],null],false],[0,\" - \"],[1,[23,3,[\"_data\",\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/regions-column/template.hbs"
    }
  });
});