define("client/pods/components/dispatch/export-btn/component", ["exports", "ember-concurrency", "client/mixins/date", "client/mixins/big", "client/mixins/crud", "client/mixins/adapters-new", "client/utils/nventor", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _emberConcurrency, _date, _big, _crud, _adaptersNew, _nventor, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_adaptersNew.default, _big.default, _date.default, _crud.default, (_dec = Ember._action, _obj = {
    server: Ember.inject.service(),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    dispatchService: Ember.inject.service('dispatch'),
    excel: Ember.inject.service(),
    exportService: Ember.inject.service('export'),
    tagName: '',
    isDispatched: false,
    dispatchDateZ: '',
    nextDispatchProcessCode: '',
    // exportOrdersTotal: true,
    // exportPayOnDeliveryOrders: true,
    // exportPayOnDeliveryTransporter: true,
    // exportPaidOrders: true,
    // exportPaidTransporter: true,
    // exportTransportersTotal: true,
    isExporting: false,
    selectedSpec: '',
    sharedWorksheets: null,
    customWorksheets: null,
    groupDocumentsByTransporterProxy: false,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['dispatch/lists/exportOptions', 'dispatch/lists/export-file-type', 'dispatch/lists/date-header']);
      this.set('filename', this.getDefaultFilename());
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);

      // this.set('exportOption', 'exportAndUpdateStatus')
      this.set('exportOption', 'exportOnly');
      if (this.dispatchProcess) {
        const model = this.crud.setupDirty({
          adapterName: 'dispatch/lists/processes',
          model: this.dispatchProcess
        });
        const exportFileType = model.getData('exportFileType');
        const exportFileTypeLabel = model.getData('exportFileType');
        if (RA.isNilOrEmpty(exportFileType) || RA.isNilOrEmpty(exportFileTypeLabel)) {
          model.setData('exportFileType', 'xlsx');
          model.setData('exportFileTypeLabel', 'xlsx');
        }
        this.set('model', model);
      }
      this.model.set('isGroupDocs', true);
      const defaultExportSheets = this.get('model._data.defaultExportSheets') || [];
      const defaultExportCustomSheets = this.get('model._data.defaultExportCustomSheets') || {};
      this.set('sharedWorksheets', defaultExportSheets);
      this.set('customWorksheets', defaultExportCustomSheets);
      const groupDocumentsByTransporter = this?.groupDocumentsByTransporter || false;
      this.set('groupDocumentsByTransporterProxy', groupDocumentsByTransporter);
      this.dispatchService.fetchSpecsTask.perform(this);
    },
    // addCustomWorksheetSpec (specKey = '') {
    //   const customWorksheets = this.get('customWorksheets') || []
    //   customWorksheets.pushObject(EmberObject.create({
    //     specKey
    //   }))

    //   this.set('customWorksheets', customWorksheets)
    // },

    getDefaultFilename() {
      const today = this.formatDate(this.getToday(), 'YYYYMMDD');
      const dispatchProcess = this.dispatchProcess;
      const name = R.path(['_data', 'name'])(dispatchProcess);
      const translate = dispatchProcess.getData('translate') || false;
      if (!translate) {
        return `${name}-${today}`;
      }
      const translatedName = this.intl.t(name);
      return `${translatedName}-${today}`;
    },
    _sumProperty({
      property,
      docData,
      groups = []
    }) {
      const roundingCurrency = docData.roundingCurrency || 0;
      let totalBig = this.newBig(docData[property]);
      R.forEach(group => {
        const groupTotalBig = this.newBig(group[property]);
        totalBig = totalBig.add(groupTotalBig);
      })(groups);
      return totalBig.toFixed(roundingCurrency);
    },
    _transformGroupedDocs({
      serialized,
      isGroupDocs = true
    }) {
      const toSumProps = ['cashCollectionInclTotal', 'discExclTotal', 'discInclTotal', 'exclTotal', 'exclTotalOnSale', 'inclTotal', 'inclTotalOnSale', 'netInclTotal', 'paidInclTotal', 'preDiscExclTotal', 'preDiscInclTotal', 'postDiscExclTotal', 'postDiscInclTotal', 'returnRewardAdjustmentAmount', 'returnRewardAdjustmentPoints', 'rewardAmountTotal', 'rewardAmountTotalWithoutPromoCode', 'rewardExclTotal', 'rewardInclTotal', 'rewardPointApplied', 'rewardPointEarned', 'taxTotal'];
      if (isGroupDocs) {
        serialized = R.map(docData => {
          const groups = R.propOr([], 'groups')(docData);
          const paymentMethodKey = R.propOr('', 'paymentMethodKey')(docData);
          let details = R.propOr([], 'details')(docData);
          let paymentMethodKeys = [paymentMethodKey];
          let unpaidInclTotal = this.newBig(0);
          if (docData.paymentMethodKey === 'cash') {
            unpaidInclTotal = this.newBig(docData.cashCollectionInclTotal);
          }
          R.forEach(group => {
            const groupDetails = R.propOr([], 'details')(group);
            details = R.concat(details, groupDetails);
            const groupPaymentMethodKey = R.propOr('', 'paymentMethodKey')(group);
            paymentMethodKeys = R.append(groupPaymentMethodKey, paymentMethodKeys);
            if (group.paymentMethodKey === 'cash') {
              unpaidInclTotal = unpaidInclTotal.add(group.cashCollectionInclTotal);
            }
          })(groups);
          R.forEach(property => {
            docData[property] = this._sumProperty({
              property,
              docData,
              groups
            });
          })(toSumProps);
          docData.details = details;
          docData.paymentMethodKey = R.uniq(paymentMethodKeys) || [];
          docData.unpaidInclTotal = unpaidInclTotal.toFixed(docData.roundingCurrency);
          return docData;
        })(serialized);
      } else if (!isGroupDocs) {
        serialized = R.pipe(R.reduce((obj, docData) => {
          const groups = R.propOr([], 'groups')(docData);
          const docDataMaster = [R.omit(['groups'], docData)]; // should be array

          const docDataMasterAndChilds = R.concat(docDataMaster, groups);
          return R.concat(obj, docDataMasterAndChilds);
        }, []), R.flatten)(serialized);
      }
      return serialized;
    },
    exportTask: (0, _emberConcurrency.task)(function* /* filter */
    () {
      let filteredResults = this.filteredResults || [];
      if (RA.isNilOrEmpty(filteredResults)) {
        // fetch dispatch here now
        const filters = this?.filters || {};
        const exportFilters = this.search.copyFilters({
          filters,
          adapterName: 'documents/dispatch/filters'
        });
        const model = this?.model || {};
        const groupDocumentsByTransporter = this?.groupDocumentsByTransporterProxy || false;
        this.dispatchService.setFiltersBasedOnDispatchProcessModel({
          filters: exportFilters,
          model,
          groupDocumentsByTransporter
        });
        const allUnpaginated = yield this.onFetchAllUnpaginatedTask.perform({
          filters: exportFilters
        });
        this.set('filteredResults', allUnpaginated);
      }
      if (this.hasUpdateStatus && !this.nextDispatchProcessCode) {
        this.set('errorMsg', 'please select');
        return false;
      }
      this.set('isExporting', true);
      let filename = this.filename;
      if (!filename) {
        filename = this.getDefaultFilename();
      }
      const serializedCustomSpecs = this.specsCollection.getSerializedByProp({
        prop: 'custom',
        worksheets: this.customWorksheets,
        selected: true
      });
      const serializedSharedSpecs = this.specsCollection.getSerializedByProp({
        prop: 'shared',
        worksheets: this.sharedWorksheets,
        selected: false
      });
      let newSerializedCustomSpecs = [];
      R.forEach(customWorksheet => {
        const key = R_.dotPathOr('', '_data.value', customWorksheet);
        R.forEach(serializedCustomSpec => {
          if (R.equals(key, serializedCustomSpec._key)) {
            newSerializedCustomSpecs = R.append(R.merge(customWorksheet, serializedCustomSpec), newSerializedCustomSpecs);
          }
        })(serializedCustomSpecs);
      })(this.customWorksheets);
      const worksheets = [];
      const specsModel = this.crud.setupNewRecord({
        adapterName: 'specs'
      });

      // for dispatch, default true
      const isGroupDocs = R.pathOr(true, ['model', '_data', 'isGroupDocs'])(this);
      const options = {
        showSetDetails: R.pathOr(false, ['model', '_data', 'showSetDetails'])(this),
        hideSetParents: R.pathOr(false, ['model', '_data', 'hideSetParents'])(this),
        showProductSetWithChoices: R.pathOr(false, ['model', '_data', 'showProductSetWithChoices'])(this),
        hideShippingFee: R.pathOr(false, ['model', '_data', 'hideShippingFee'])(this),
        availableNormalSpecs: R.pathOr([], ['availableNormalSpecs'])(specsModel),
        detailsProp: 'details',
        isGroupDocs
      };
      let serialized = R.propOr([], 'resultsData')(this);
      serialized = this._transformGroupedDocs({
        serialized,
        isGroupDocs
      });
      serialized = this.exportService.calculateDocsNetReturns({
        serialized,
        useOriginalCashCollectionInclTotal: false,
        options
      });
      let shopSortOrderDetailsByIndexCount = 0;
      R.forEach(spec => {
        if (spec.shopSortOrderDetailsByIndex) {
          shopSortOrderDetailsByIndexCount += 1;
        }
      })(R.concat(serializedSharedSpecs, newSerializedCustomSpecs));
      if (R.gt(shopSortOrderDetailsByIndexCount, 0)) {
        // @TODO: cannot do this due to performance issues
        // const sourceKeys = R.pipe(
        //   R.pluck('sourceKey'),
        //   R.reject(RA.isNilOrEmpty),
        //   R.uniq,
        //   R.take(5)
        // )(serialized)

        // const data = {
        //   sourceKeys
        // }

        // const itemWithIndexesByShopKey = yield this.server.call('GET', 'api/protected/dispatch/processes/export/getShopCampaignInfoAndDetailsDataByKey', data) || {}
        // const itemWithIndexesByShopKeyData = R.mergeAll(RA.ensureArray(itemWithIndexesByShopKey.data || []))

        // serialized = R.map(docData => {
        //   const sourceKey = docData.sourceKey
        //   if (RA.isNotNilOrEmpty(sourceKey)) {
        //     const itemWithIndexes = itemWithIndexesByShopKeyData[sourceKey]
        //     const docDataDetails = R.map(docDataDetail => {
        //       const itemKey = docDataDetail.itemKey
        //       const shopIndex = R.pipe(
        //         R.find(R.propEq('itemKey', itemKey)),
        //         R.propOr(itemWithIndexes.length, 'index')
        //       )(itemWithIndexes)
        //       docDataDetail.index = shopIndex
        //       return docDataDetail
        //     })(docData.details || [])
        //     docData.details = docDataDetails
        //   }
        //   return docData
        // })(serialized)
      }
      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);
        if (method) {
          const sharedSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized,
            options
          });
          worksheets.push(sharedSheetToPush);
        }
      })(serializedSharedSpecs);
      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);
        if (method) {
          const customSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized,
            options
          });
          worksheets.push(customSheetToPush);
        }
      })(newSerializedCustomSpecs);

      // TEMP solution for customer
      // TEMP solution for customer
      // TEMP solution for customer
      // const specsForSummary = [{
      //   column: '收貨人',
      //   prop: 'contact'
      // }, {
      //   column: '單號',
      //   prop: 'ref'
      // }, {
      //   column: '雪蛤人篸',
      //   item: '唐太盅甜湯-雪蛤人蔘燉雪耳',
      //   isDetailColumn: true
      // }, {
      //   column: '紅豆紫米',
      //   item: '唐太盅甜湯-紅豆桂圓紫米粥',
      //   isDetailColumn: true
      // }, {
      //   column: '雪蓮四寶',
      //   item: '唐太盅甜湯-雪蓮子燉四寶湯',
      //   isDetailColumn: true
      // }, {
      //   column: '寒天枸杞',
      //   item: '',
      //   isDetailColumn: true
      // }, {
      //   column: '胡椒豬肚',
      //   item: '唐太盅-胡椒豬肚雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '何首烏',
      //   item: '唐太盅-何首烏燉雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '黑蒜頭',
      //   item: '唐太盅-黑蒜頭燉雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '人嵾糯米',
      //   item: '唐太盅-人蔘糯米雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '十全排骨',
      //   item: '唐太盅-十全藥膳排骨',
      //   isDetailColumn: true
      // }, {
      //   column: '十全豬腳',
      //   item: '唐太盅-十全藥膳豬腳',
      //   isDetailColumn: true
      // }, {
      //   column: '素當歸羊',
      //   item: '',
      //   isDetailColumn: true
      // }, {
      //   column: '南洋肉骨',
      //   item: '',
      //   isDetailColumn: true
      // }, {
      //   column: '狗尾草',
      //   item: '唐太盅-狗尾草燉雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '四物雞',
      //   item: '唐太盅-四物燉雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '麻油燉雞',
      //   item: '唐太盅-麻油燉雞盅',
      //   isDetailColumn: true
      // }, {
      //   column: '滴雞精',
      //   item: '',
      //   isDetailColumn: true
      // }, {
      //   column: '十入保冷袋 ',
      //   item: '贈唐太盅保冷袋(可裝盅湯10包)隨機出貨',
      //   isDetailColumn: true
      // }, {
      //   column: '四入保冷袋',
      //   item: '贈唐太盅保冷袋(可裝盅湯4包)',
      //   isDetailColumn: true
      // }]

      // const detailSpecsForSummary = [{
      //   column: '',
      //   prop: 'item',
      //   transform (value, data) {
      //     const columns = R.pipe(
      //       R.filter(R.propEq('isDetailColumn', true)),
      //       R.pluck('item')
      //     )(specsForSummary)

      //     return R.reduce((acc, item) => {
      //       const detail = R.find(R.propEq('item', item))(data)
      //       if (detail) {
      //         acc.push(detail.qty)
      //       } else {
      //         acc.push('')
      //       }
      //       return acc
      //     }, [])(columns)
      //   }
      // }]
      // worksheets.push({
      //   sheetName: 'Summary',
      //   data: this._exportSummary(today, specsForSummary, detailSpecsForSummary, serialized, false)
      // })
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////

      // // TEMP solution for customer
      // // TEMP solution for customer
      // // TEMP solution for customer
      // const self = this
      // const productData = [
      //   { itemCode: 'Z012', items: ['農純鄉 滴雞精40+10入 (冷凍/無盒)'], item: '農純鄉 滴雞精40+10入 (冷凍/無盒)', transporterAccount: 3 },
      //   { itemCode: 'M260', items: ['農純鄉 滴雞精21+2入 (冷凍)'], item: '農純鄉 滴雞精21+2入 (冷凍)', transporterAccount: 3 },
      //   { itemCode: 'Z101', items: ['農純鄉 媽媽茶28入(盒裝)'], item: '農純鄉 媽媽茶28入(盒裝)', transporterAccount: 1 },
      //   { itemCode: 'M212', items: ['農純鄉 媽媽茶56(盒裝)+5入'], item: '農純鄉 媽媽茶56(盒裝)+5入', transporterAccount: 1 },
      //   { itemCode: 'M204', items: ['農純鄉 媽媽茶80 (無盒)+彈力燕窩飲7入'], item: '農純鄉 媽媽茶80 (無盒)+彈力燕窩飲7入', transporterAccount: 1 },
      //   { itemCode: 'M261', items: ['寶寶粥28入(豬肉/南瓜/干貝/雞肉)+益生菌5入+蘋果肉燥醬2包'], item: '寶寶粥28入(豬肉/南瓜/干貝/雞肉)+益生菌5入+蘋果肉燥醬2包', transporterAccount: 1 },
      //   { itemCode: 'M111', items: ['農純鄉 寶寶粥28入(豬肉/南瓜/干貝/鮭魚)+大本山益生菌5入'], item: '農純鄉 寶寶粥28入(豬肉/南瓜/干貝/鮭魚)+大本山益生菌5入', transporterAccount: 1 },
      //   { itemCode: 'M245', items: ['農純鄉 飽寶醬16入(蘋果肉燥+番茄牛肉)+波菜麵8入'], item: '農純鄉 飽寶醬16入(蘋果肉燥+番茄牛肉)+波菜麵8入', transporterAccount: 1 },
      //   { itemCode: 'Z902', items: ['農純鄉 蔓越莓珍珠粉30入 x 4盒'], item: '農純鄉 蔓越莓珍珠粉30入 x 4盒', transporterAccount: 1 },
      //   { itemCode: 'M218', items: ['農純鄉 彈力燕窩飲 28入+蔓越莓珍珠粉10入'], item: '農純鄉 彈力燕窩飲 28入+蔓越莓珍珠粉10入', transporterAccount: 1 },
      //   { itemCode: 'M226', items: ['農純鄉 燕盞3片x6g+蔓越莓珍珠粉30入'], item: '農純鄉 燕盞3片x6g+蔓越莓珍珠粉30入', transporterAccount: 1 },
      //   { itemCode: 'Z723', items: ['大本山益生菌 150入+加贈30入<草莓90+香蕉90>'], item: '大本山益生菌 150入+加贈30入<草莓90+香蕉90>', transporterAccount: 1 },
      //   { itemCode: 'Z727', items: ['【預購中8/3後依序出貨】大本山益生菌 150入+30入(草莓90+香蕉60+隨機30)', '大本山益生菌 150入+30入(草莓90+香蕉60+隨機30)'], transporterAccount: 1 },
      //   { itemCode: 'Z708', items: ['大本山益生菌 150入+30入(草莓180)', '【預購中8/3後依序出貨】大本山益生菌 150入+30入(草莓180)'], transporterAccount: 1 },
      //   { itemCode: 'HD09', items: ['贈農純鄉飽寶醬4入/盒(不挑款)'], item: '贈農純鄉飽寶醬4入/盒(不挑款)', transporterAccount: 1 }
      // ]

      // const specsForSummary = [{
      //   column: '訂購日',
      //   prop: 'dateZ',
      //   transform (value) {
      //     return self.dateService.formatDate(value, 'YYYY/MM/DD')
      //   }
      // }, {
      //   column: '訂購時間',
      //   prop: 'timestampZ',
      //   transform (value) {
      //     return self.dateService.formatDate(value, 'YYYY/MM/DD HH:mm:ss')
      //   }
      // }, {
      //   column: '訂單編號',
      //   prop: 'ref'
      // }, {
      //   column: '出貨物流',
      //   prop: '',
      //   returnValue: '宅配'
      // }, {
      //   column: '收件人',
      //   prop: ['person', 'contact'],
      //   joinWith: ' / '
      // }, {
      //   column: '收件人電話',
      //   prop: 'telephone'
      // }, {
      //   column: '收件人地址',
      //   prop: 'address'
      // }, {
      //   column: '會員備註',
      //   prop: 'notes'
      // }, {
      //   column: '規格編號',
      //   prop: 'itemCode',
      //   isDetail: true,
      //   transform (code, data) {
      //     if (!code) {
      //       const item = data.item
      //       const found = R.find(detail => {
      //         return R.includes(item)(detail.items)
      //       })(productData)
      //       return R.prop('itemCode')(found) || ''
      //     }
      //     return code
      //   }
      // }, {
      //   column: '商品名稱',
      //   prop: 'item',
      //   isDetail: true,
      //   transform (item) {
      //     const found = R.find(detail => {
      //       return R.includes(item)(detail.items)
      //     })(productData)
      //     return R.prop('item')(found) || item
      //   }
      // }, {
      //   column: '訂購數',
      //   prop: 'qty',
      //   isDetail: true
      // }, {
      //   column: '商品金額',
      //   prop: 'postDiscInclAmt',
      //   isDetail: true
      // }, {
      //   column: '單價',
      //   prop: 'discInclPrice',
      //   isDetail: true
      // }, {
      //   column: '出貨時間',
      //   prop: ''
      // }, {
      //   column: '到貨時間',
      //   prop: ''
      // }, {
      //   column: '指定時段',
      //   prop: ''
      // }, {
      //   column: '配送溫層',
      //   prop: 'itemCode',
      //   isDetail: true,
      //   transform (code, data) {
      //     let found = R.find(R.propEq('code', code))(productData)
      //     if (!found) {
      //       const item = data.item
      //       found = R.find(detail => {
      //         return R.includes(item)(detail.items)
      //       })(productData)
      //     }
      //     if (found) {
      //       return R.prop('transporterAccount')(found) || ''
      //     }

      //     return ''
      //   }
      // }]

      // worksheets.push({
      //   sheetName: 'Summary',
      //   data: this._exportByDetails(today, specsForSummary, serialized, false)
      // })
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////
      // // /// ////^^^above^^ temp solution for customer ^^^//////

      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename,
          bookType: this?.model?._data?.exportFileType,
          bookTypeLabel: this?.model?._data?.exportFileTypeLabel
        });
        if (this.hasUpdateStatus) {
          yield this.setDispatchProcessAfterExportTask.perform();
        }
        this.set('isExporting', false);
        this.set('filteredResults', []); // remove selected after export

        this.onCloseDialogAction();
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
    }).drop(),
    _exportOrders(today, specs, detailSpecs = [], results, hasSummary = false) {
      // const intl = this.intl
      const columns = R.pluck('column', specs);
      // const props = R.pluck('prop', specs)
      let detailProps = false;
      if (!R.isEmpty(detailSpecs)) {
        detailProps = R.pluck('prop', detailSpecs);
      }
      let index = 0;
      // let totalQty = 0
      let data = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = R.propOr([], 'details', doc);
        let inclTotalBig = this.newBig(0);
        detailsRows = R.map(detailData => {
          inclTotalBig = inclTotalBig.plus(detailData.postDiscInclAmt);
          return this._getDataBySpecs({
            data: detailData
          }, detailSpecs);
        })(detailsRows);
        // detailsRows = R.pipe(

        //   // @TODO: filter by product/tag/supplier
        //   // R.filter(
        //   //   detail => {
        //   //     if (R.pathEq(['_data', 'itemKey'], '3040094470030', detail)) {
        //   //       totalQty = totalQty + parseInt(R.path(['_data', 'qty'], detail))
        //   //     }

        //   //     return R.pathEq(['_data', 'itemKey'], '3040094470030', detail) || R.pathEq(['_data', 'item'], '運費', detail)
        //   //   }
        //   // ),
        //   R.map(detailData => {
        //     inclTotalBig = inclTotalBig.plus(detailData.postDiscInclAmt)
        //     return R.pipe(
        //       R.pick(detailProps),
        //       R.values,
        //       R.concat(['']),
        //       R.concat([''])
        //     )(detailData)
        //   })
        // )(detailsRows)
        // }

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          inclTotalBig,
          index,
          today
        }, specs);
        // const docInfoRow = R.map(prop => {
        //   if (prop === '_index_') {
        //     return index
        //   }
        //   if (prop === '_today_') {
        //     return today
        //   }
        //   if (prop === 'paymentMethodKey') {
        //     const v = R.propOr('', prop, doc)
        //     if (v) {
        //       return intl.t(v)
        //     }
        //     return ''
        //   }

        //   if (prop === '_inclTotal_') {
        //     return parseInt(inclTotalBig.toFixed(0))
        //   }

        //   if (prop === 'qty') {
        //     return parseInt(R.propOr('', prop, doc))
        //   }

        //   return R.propOr('', prop, doc)
        // }, props)

        if (detailProps) {
          return R.concat([docInfoRow], detailsRows);
        } else {
          return [docInfoRow];
        }
      }), R.unnest, R.concat([columns]))(results);
      if (hasSummary && detailProps) {
        data = this._summaryRows(data, results);
      }
      return data;
    },
    _summaryRows(data, results) {
      const intl = this.intl;
      const groupByItem = doc => {
        const itemKey = R.prop('itemKey')(doc);
        if (itemKey) {
          return itemKey;
        }
        return R.prop('item')(doc);
      };
      let grandTotal = 0;
      data = R.pipe(R.pluck('details'), R.flatten, R.groupBy(groupByItem), R.mapObjIndexed(groupData => {
        const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', groupData)) || 0;
        const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', groupData)) || 0;
        grandTotal = grandTotal + groupTotalInclAmt;
        const item = R.pipe(R.head, R.propOr('', 'item'))(groupData);
        const itemCode = R.pipe(R.head, R.propOr('', 'itemCode'))(groupData);
        return ['', '', groupTotalQty, item, itemCode, groupTotalInclAmt];
      }), R.values, R.concat([[], ['', '', intl.t('qty'), intl.t('product'), intl.t('code'), intl.t('total')]]), R.concat(data), R.append([]))(results);
      data = R.append(['', '', '', '', intl.t('total'), grandTotal], data);
      return data;
    },
    _exportSummary(today, specs, detailSpecs = [], results, hasSummary = false) {
      // const intl = this.get('intl')

      const allSpecs = R.concat(specs, detailSpecs);
      const columns = R.pluck('column', allSpecs);
      // const props = R.pluck('prop', specs)
      if (!R.is(Array)(detailSpecs)) {
        detailSpecs = [detailSpecs];
      }
      let detailProps = false;
      if (!R.isEmpty(detailSpecs)) {
        detailProps = R.pipe(R.pluck('prop'), R.uniq)(detailSpecs);
      }
      let index = 0;
      // let totalQty = 0
      const data = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = R.propOr([], 'details', doc);
        detailsRows = R.pipe(R.map(R.pick(['item', 'qty'])), R.groupBy(R.prop('item')), R.mapObjIndexed(group => {
          return R.reduce((acc, detail) => {
            acc.item = detail.item;
            const qty = parseInt(detail.qty);
            acc.qty = qty + acc.qty;
            return acc;
          }, {
            qty: 0
          })(group);
        }), R.values, R.flatten)(detailsRows);
        detailsRows = this._getDataBySpecs({
          data: detailsRows
        }, detailSpecs);
        const docInfoRow = this._getDataBySpecs({
          data: doc,
          index,
          today
        }, specs);
        if (detailProps) {
          return [R.concat([docInfoRow[0], docInfoRow[1]], detailsRows[0])];
        } else {
          return [docInfoRow];
        }
      }), R.unnest, R.concat([columns]))(results);
      return data;
    },
    _exportByDetails(today, specs, results, hasSummary = false) {
      const columns = R.pluck('column', specs);
      // if (!R.is(Array)(detailSpecs)) {
      //   detailSpecs = [detailSpecs]
      // }

      let detailProps = [];
      // if (!R.isEmpty(detailSpecs)) {
      detailProps = R.pipe(R.filter(R.propEq('isDetail', true)), R.pluck('prop'))(specs);
      // }

      let index = 0;
      const details = R.pipe(R.map(doc => {
        const details = R.pipe(R.propOr([], 'details'), R.map(detail => {
          const detailData = R.pick(detailProps)(detail);
          return R.mergeRight(doc, detailData);
        }))(doc);
        return details;
      }), R.flatten)(results);
      const data = R.pipe(R.map(detail => {
        index = index + 1;
        const row = this._getDataBySpecs({
          data: detail,
          index,
          today
        }, specs);
        return row;
      }), R.concat([columns]))(details);
      return data;
    },
    _getDataBySpecs({
      data,
      inclTotalBig = false,
      index,
      today
    }, specs) {
      return R.map(spec => {
        const returnValue = R.prop('returnValue')(spec);
        if (returnValue) {
          return returnValue;
        }
        let props = R.prop('prop', spec);
        if (!R.is(Array, props)) {
          props = [props];
        }
        const values = R.map(prop => {
          return this._getPropData({
            today,
            index,
            data,
            spec,
            prop,
            inclTotalBig
          });
        })(props);
        if (props.length > 1) {
          const joinWith = R.prop('joinWith', spec) || '-';
          return R.pipe(R.reject(RA.isNilOrEmpty), R.join(joinWith))(values);
        }
        return _nventor.default.safeHeadOr('', values);
      }, specs);
    },
    _getPropData({
      today,
      index,
      data,
      spec,
      prop,
      inclTotalBig
    }) {
      const intl = this.intl;
      if (prop == null) {
        return '';
      }
      if (spec.returnProp) {
        return R.propOr('', spec.returnProp)(data);
      }
      const transform = R.prop('transform')(spec);
      let value = R_.dotPathOr('', prop, data);
      if (prop === '_index_') {
        value = index;
      }
      if (prop === '_today_') {
        return today;
      }

      // if (prop === 'dateZ') {
      //   value = this.dateService.formatDate(value)
      // }

      // if (prop === 'timestampZ') {
      //   value = this.dateService.formatDate(value)
      // }

      if (prop === 'paymentMethodKey') {
        const v = R_.dotPathOr('', prop, data);
        if (v) {
          value = intl.t(v);
        }
      }
      if (prop === '_inclTotal_' && inclTotalBig) {
        value = inclTotalBig.toFixed(0);
      }
      if (transform) {
        value = transform(value, data, spec);
      }
      return value;
    },
    exportData({
      results,
      filterByProp,
      filterValue,
      isReject = false,
      fromNo,
      toNo
    } = {}) {
      let resultsClone = R.clone(results);
      if (fromNo != null && toNo != null) {
        resultsClone = R.pipe(R.drop(fromNo), R.take(toNo))(resultsClone);
      }
      let filterByPath = filterByProp;
      if (!R.is(Array, filterByProp)) {
        filterByPath = [filterByProp];
      }
      if (filterByProp) {
        if (isReject) {
          resultsClone = R.reject(R.pathEq(filterByPath, filterValue))(resultsClone);
        } else {
          resultsClone = R.filter(R.pathEq(filterByPath, filterValue))(resultsClone);
        }
      }
      return resultsClone;
    },
    setDefaultDispatchDateZ(dispatchProcessModel) {
      Ember.set(this, 'isDispatched', false);
      if (dispatchProcessModel?._data?.isDispatched) {
        const todayZ = this.dateService.getStartOfDayZ();
        Ember.set(this, 'dispatchDateZ', todayZ);
        Ember.set(this, 'isDispatched', true);
      }
    },
    hasUpdateStatus: Ember.computed('exportOption', function () {
      if (this.exportOption === 'exportAndUpdateStatus') {
        return true;
      }
      return false;
    }),
    exportLabel: Ember.computed('nextDispatchProcessCode', 'hasUpdateStatus', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      let label = this.intl.t('export');
      if (this.hasUpdateStatus) {
        label = this.intl.t('export data and update status');
        if (nextDispatchProcessCode) {
          const dispatchProcesses = this.dispatchProcesses || [];
          const nextDispatchProcess = this.dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);
          if (nextDispatchProcess) {
            let name = nextDispatchProcess.getData('name');
            if (nextDispatchProcess.getData('translate')) {
              name = this.intl.t(name);
            }
            label = `${label}: ${name}`;
          }
        }
        return label;
      }
      return label;
    }),
    isDisableSetNextDispatchProcess: Ember.computed('nextDispatchProcessCode', 'hasUpdateStatus', function () {
      if (!this.hasUpdateStatus) {
        return false;
      }
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      if (nextDispatchProcessCode) {
        return false;
      }
      return true;
    }),
    resultsData: Ember.computed('filteredResults.[]', function () {
      const results = this.filteredResults || [];
      return R.pipe(R.map(result => {
        const model = result.model;
        const serializedModel = this.serialize(model);
        const docType = serializedModel?.docType || '';
        const docName = serializedModel?.docName || '';
        if (R.equals(docType, 'sales') && R.equals(docName, 'invoices')) {
          // add eInvoices to serializedModel
          const eInvoicesModel = model?.eInvoicesCollection?._data?.invoices || [];
          const eInvoices = {
            // only need number for now
            numbers: R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('number'), R.reject(RA.isNilOrEmpty), R.join(','))(eInvoicesModel)
          };
          serializedModel.eInvoices = eInvoices;
        }
        return serializedModel;
      }))(results);
    }),
    setDispatchProcessAfterExportTask: (0, _emberConcurrency.task)(function* () {
      const results = this.filteredResults || [];
      const models = R.pluck('model')(results);
      const dispatchProcesses = this.dispatchProcesses || [];
      const dispatchProcess = this.dispatchProcess;
      const dispatchDateZ = this.dispatchDateZ;
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      if (dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        const nextDispatchProcess = this.dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);
        if (nextDispatchProcess) {
          return yield this.get('dispatchService.setDispatchProcessTask').perform(models, nextDispatchProcess, dispatchDateZ);
        }
      }
      return false;
    }).drop(),
    actions: {
      // addCustomWorksheetSpec () {
      //   this.addCustomWorksheetSpec(...arguments)
      // }
    }
  }, _applyDecoratedDescriptor(_obj, "setDefaultDispatchDateZ", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDefaultDispatchDateZ"), _obj), _obj));
});