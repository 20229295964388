define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/convenience-store-zones/component", ["exports", "ramda", "ramda-adjunct", "@glimmer/component"], function (_exports, R, RA, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopShippingZonesShippingZonesEditorDetailsEditorConvenienceStoreZones = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _class = class ChannelsShopShippingZonesShippingZonesEditorDetailsEditorConvenienceStoreZones extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "isMainSelected", _descriptor2, this);
      _initializerDefineProperty(this, "isOutlyingSelected", _descriptor3, this);
      const regions = this.crud.lists['settings-countries-regions'];
      const selectedRegions = isOutlyingValue => R.pipe(R.map(region => {
        const districts = R.pipe(R.pathOr([], ['_data', 'districts']), R.filter(R.pathEq(['_data', 'is_outlying_island'], isOutlyingValue.toString())))(region);
        if (RA.isNotNilOrEmpty(districts)) {
          return R.path(['_data', 'key'])(region);
        }
        return false;
      }), R.reject(R.equals(false)))(regions);
      const selectedDistricts = isOutlyingValue => R.pipe(R.map(region => {
        const districts = R.pipe(R.pathOr([], ['_data', 'districts']), R.filter(R.pathEq(['_data', 'is_outlying_island'], isOutlyingValue.toString())), R.map(R.path(['_data', 'postcode'])))(region);
        const totalDistricts = R.length(region._data.districts);
        if (totalDistricts !== R.length(districts)) {
          return districts;
        }
        return false;
      }), R.reject(R.equals(false)), R.flatten)(regions);
      this.mainRegions = selectedRegions(false) || [];
      this.outlyingRegions = selectedRegions(true) || [];
      this.mainDistricts = selectedDistricts(false) || [];
      this.outlyingDistricts = selectedDistricts(true) || [];
    }
    setup(model) {
      this.isMainSelected = model?._data?.isMainSelected || false;
      this.isOutlyingSelected = model?._data?.isOutlyingSelected || false;
    }
    onToggleSelectRegionsGroup(toggleOutlying) {
      const model = this.args.model;
      const countries = model.getData('countries') || [];
      const country = R.find(R.pathEq(['_data', 'code'], 'TW'))(countries); // only TW is allowed

      if (toggleOutlying) {
        this.isOutlyingSelected = !this.isOutlyingSelected;
        model.setData('isOutlyingSelected', this.isOutlyingSelected);
      } else {
        this.isMainSelected = !this.isMainSelected;
        model.setData('isMainSelected', this.isMainSelected);
      }
      let mainRegions = [];
      let outlyingRegions = [];
      let mainDistricts = [];
      let outlyingDistricts = [];
      if (this.isOutlyingSelected) {
        outlyingRegions = this.outlyingRegions;
        outlyingDistricts = this.outlyingDistricts;
      }
      if (this.isMainSelected) {
        mainRegions = this.mainRegions;
        mainDistricts = this.mainDistricts;
      }
      country._data.regions = R.uniq(R.concat(mainRegions, outlyingRegions));
      country._data.districts = [];
      if (!(this.isOutlyingSelected && this.isMainSelected)) {
        country._data.districts = R.concat(mainDistricts, outlyingDistricts);
      }
      model.setData('countries', []);
      Ember.run.scheduleOnce('afterRender', () => {
        model.setData('countries', [country]);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isMainSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isOutlyingSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSelectRegionsGroup", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSelectRegionsGroup"), _class.prototype), _class);
});