define("client/pods/components/comments/comments-manager/modal-btn/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct", "ember-concurrency"], function (_exports, R, R_, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    // commentsService: service('comments'),
    // dateService: service('date'),
    // settingsService: service('settings'),

    // tagName: 'span',
    // classNames: ['comments__display-container'],
    // tooltipLabel: 'internal comments',
    // label: '',
    // adapterName: 'comments',
    // showEditor: false,
    // isEditing: false,
    _icon: 'far fa-comment',
    icon: Ember.computed('options.btnIcon', '_icon', function () {
      return this?.options?.btnIcon || this._icon;
    }),
    isAllowed: Ember.computed('isPublic', function () {
      if (!this.isPublic) {
        return true;
      }
      if (!R.equals(this.subType, 'sales-invoices')) {
        return false;
      }
      return this.settingsService?.settingsModel?._data?.allowOrderMessages;
    })

    // init () {
    //   this._super(...arguments)
    //   this.set('isEditing', false)

    //   const firstCommentFilters = this.search.setupFilters(
    //     {
    //       adapterName: 'comments/contacts/filters',
    //       defaultFiltersData: {
    //         fetchWithActiveCampaigns: true
    //       }
    //     }
    //   )

    //   this.set('firstCommentFilters', firstCommentFilters)
    // },

    // didInsertElement () {
    //   this._super(...arguments)

    //   this.setup()
    // },

    // setup () {
    //   if (!this.isAllowed) {
    //     return false
    //   }

    //   this.setupNewCommentRecord()

    //   const master = this.master
    //   if (master) {
    //     this.fetchCommentsTask.perform(master)
    //     const filters = this.firstCommentFilters
    //     this.fetchFirstCommentDataTask.perform({ adapterName: 'comments', filters })
    //   }
    // },

    // setupNewCommentRecord () {
    //   if (this?.isDestroyed === false) {
    //     const master = this.master
    //     const tokenData = this.storage.get('tokenData')
    //     const data = {
    //       username: tokenData.username,
    //       master: master
    //     }
    //     const model = this.crud.setupNewRecord({ data })
    //     this.set('newModel', model)
    //   }
    // },

    // comments: computed('commentsCollection._data.comments.[]', 'commentsCollection.synced', function () {
    //   return this.get('commentsCollection._data.comments') || []
    // }),

    // commentsCount: computed('comments.[]', 'commentsCollection.synced', function () {
    //   const comments = this.comments || []
    //   return comments.length
    // }),

    // fetchFirstCommentDataTask: task(function * ({ adapterName, filters }) {
    //   const master = this.master

    //   filters.setData('master', master)

    //   const results = yield this.crud.searchRecordsConcurrentlyTask.perform({
    //     adapterName: `${adapterName}/contacts`,
    //     filters
    //   })

    //   const firstCommentModel = nventor.safeHeadOr({}, results)
    //   if (RA.isNotNilOrEmpty(firstCommentModel)) {
    //     this.set('firstCommentModel', firstCommentModel)
    //   }

    //   if (this.isPublic) {
    //     this.metaData.name = firstCommentModel?._data?.docNo || ''
    //   }
    // }),

    // fetchCommentsTask: task(function * (master) {
    //   if (!this.isAllowed) {
    //     return false
    //   }

    //   let data = {}

    //   if (master) {
    //     data.master = master
    //   }

    //   if (this.type) {
    //     data.type = this.type
    //   }

    //   const metaData = this.metaData
    //   if (metaData) {
    //     data = R.mergeRight(data, metaData)
    //   }

    //   const excludeFromFilters = this.excludeFromFilters || []
    //   data = R.omit(excludeFromFilters)(data)

    //   data.related = this.related || []

    //   this.commentsService.qFetch({ data }, (batchCommentsCollection = []) => {
    //     if (this?.isDestroyed === false) {
    //       if (!R.is(Array, batchCommentsCollection)) {
    //         return false
    //       }
    //       const type = this.type
    //       let criteria = R_.dotPathEq('_data.master', master)

    //       if (type) {
    //         criteria = R.allPass(
    //           [
    //             R_.dotPathEq('_data.master', master),
    //             R_.dotPathEq('_data.type', type)
    //           ]
    //         )
    //       }
    //       const commentsCollection = R.find(criteria)(batchCommentsCollection) || []
    //       this.set('commentsCollection', commentsCollection)
    //     }
    //   })
    // }),

    //   createCommentTask: task(function * (onUpdateContent) {
    //     try {
    //       const nowZ = this.dateService.getNowZ()
    //       const type = this.type
    //       const subType = this.subType
    //       const model = this.newModel
    //       // const message = model.getData('message.string')
    //       // model.setData('message', message)

    //       if (!model.getData('message')) {
    //         return false
    //       }

    //       let confirmed = true
    //       if (this.isPublic) {
    //         const msg = this.intl.t('are you sure you want to comment and notify customer')
    //         confirmed = window.confirm(msg)
    //       }
    //       if (confirmed) {
    //         model.setData('dateZ', nowZ)
    //         model.setData('type', type)

    //         if (subType) {
    //           model.setData('subType', subType)
    //         }

    //         const metaData = this.metaData
    //         if (metaData) {
    //           Object.keys(metaData).map(key => {
    //             const value = metaData[key]
    //             model.setData(key, value)
    //           })
    //         }

    //         if (this.onBeforeCreateComment) {
    //           this.onBeforeCreateComment(model)
    //         }

    //         let firstCommentModel = this.firstCommentModel
    //         if (RA.isNilOrEmpty(firstCommentModel)) {
    //         // check if there's first comment model one more time
    //         // first comment will not be there if somehow APP user is already inside comment modal, and the broadcast updated customer message from SHOP
    //           yield this.fetchFirstCommentDataTask.perform({ adapterName: 'comments', filters: this.firstCommentFilters })
    //           firstCommentModel = this.firstCommentModel
    //         }

    //         // set as isFirst if there's still no firstCommentModel
    //         if (RA.isNilOrEmpty(firstCommentModel)) {
    //           model.setData('isFirst', true)
    //         }

    //         const msg = yield this.createRecordTask.perform({ model })

    //         if (RA.isNilOrEmpty(firstCommentModel)) {
    //           yield this.fetchFirstCommentDataTask.perform({ adapterName: 'comments', filters: this.firstCommentFilters })
    //         }

    //         this.setupNewCommentRecord()

    //         if (onUpdateContent) {
    //           onUpdateContent(msg)
    //         }
    //       }
    //     } catch (err) {
    //       this.set('errors', err.data)
    //       nventor.throw('createCommentTask failed', err)
    //     }
    //   }).drop(),

    //   afterRemoveRecordTask (model) {
    //     const dirty = this.dirty
    //     const comments = this.comments
    //     comments.removeObject(model)
    //     if (dirty && model) {
    //       if (dirty.getData('_id') === model.getData('_id')) {
    //         this.cancelEdit()
    //       }
    //     }
    //   },

    //   willDestroyElement () {
    //     if (this?.isDestroyed === false) {
    //       this.cancelEdit()
    //     }
    //   },

    //   cancelEdit () {
    //     this.set('dirty', '')
    //     this.set('isEditing', false)
    //   },

    //   actions: {
    //     toggleEditor () {
    //       this.toggleProperty('showEditor')
    //     },

    //     edit (model) {
    //       const dirty = this.setupDirty({
    //         adapterName: this.adapterName,
    //         model
    //       })
    //       this.set('dirty', dirty)
    //       this.set('isEditing', true)
    //     },

    //     cancelEdit () {
    //       this.cancelEdit()
    //     }
    //   }
  });
});