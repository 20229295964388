define("client/pods/components/layouts/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "J1NLdSUT",
    "block": "{\"symbols\":[\"@isLeft\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"layouts__sidebar \",[28,\"if\",[[23,1,[]],\"tab-grid__sidebar tab-grid__sidebar--left\",\"tab-grid__sidebar-right\"],null]]]],[13,2],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"    \\n  \"],[14,3,[[23,0,[]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/sidebar/template.hbs"
    }
  });
});