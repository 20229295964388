define("client/pods/components/contacts/statement-details/statement-transactions/tx-returns/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ContactsStatementDetailsStatementTransactionsTxReturns extends _component.default {}
  _exports.default = ContactsStatementDetailsStatementTransactionsTxReturns;
  ;
});