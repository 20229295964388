define("client/pods/components/transporters/sender-list-btn/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ember-concurrency-decorators"], function (_exports, _component, R, RA, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersSenderListBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('transporter'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _class = class TransportersSenderListBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "transporterService", _descriptor3, this);
      _initializerDefineProperty(this, "selectedSenderName", _descriptor4, this);
      _initializerDefineProperty(this, "selectedPersonId", _descriptor5, this);
      _initializerDefineProperty(this, "transporterModel", _descriptor6, this);
      _initializerDefineProperty(this, "useDefaults", _descriptor7, this);
      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time']);
      this.fetchTransporterTask.perform();
    }
    get isLoading() {
      if (this.fetchTransporterTask.isRunning) {
        return true;
      }
      const model = this.args?.model || false;
      const transporterModel = this.args?.transporterModel || this.transporterModel || false;
      if (!model || !transporterModel) {
        return true;
      }
      return false;
    }
    get listSelectorValueKey() {
      const listSelectorValueKey = this.args.listSelectorValueKey;
      if (listSelectorValueKey) {
        return listSelectorValueKey;
      }
      return '_data.defaultPickupLocationPersonId';
    }

    /**
     * Return a list of pickup locations
     * @return {Array<{
     * transporterSenderName: string,
     * transporterSenderTelephone: string,
     * transporterSenderAddress: string,
     * transporterDeliveryTime: string,
     * transporterPackageTemperature: string,
     * transporterPackageSize: string,
     * transporterPackageType: string,
     * transporterNotes: string,
     * personId: string,
     * name: string,
     * telephone: string,
     * address: string,
     * label: string,
     * model: Object
     * }>}
     */
    get pickupLocationsList() {
      const persons = this.transporterModel?._data?.persons || [];
      const pickupLocations = R.filter(R.pathEq(['_data', 'type'], 'pickupLocation'))(persons);
      const findDetailByType = (details, type) => {
        const detailType = details.find(detail => detail?._data?.subType === type) || {};
        return detailType?._data?.value;
      };
      return R.map(pickupLocation => {
        const name = pickupLocation?._data?.name || '';
        const label = pickupLocation?._data?.label || '';
        const personId = pickupLocation?._data?.personId || '';
        const details = pickupLocation?._data?.details || [];
        const telephone = findDetailByType(details, 'telephone');
        const address = findDetailByType(details, 'address');
        const transporterSenderName = name;
        const transporterSenderTelephone = findDetailByType(details, 'telephone');
        const transporterSenderAddress = findDetailByType(details, 'address');
        const transporterDeliveryTime = findDetailByType(details, 'deliveryTime');
        const transporterAccount = findDetailByType(details, 'transporterAccount');
        const transporterAccountNumber = findDetailByType(details, 'transporterAccountNumber') || transporterAccount;
        const transporterPackageTemperature = findDetailByType(details, 'transporterPackageTemperature');
        const transporterPackageSize = findDetailByType(details, 'packageSize');
        const transporterPackageType = findDetailByType(details, 'packageType');
        const transporterNotes = findDetailByType(details, 'note');
        const storeId = findDetailByType(details, 'storeId');
        const storeName = findDetailByType(details, 'storeName');
        const storeAddress = findDetailByType(details, 'storeAddress');
        const transporterAccountLabel = R.pipe(R.pathOr([], ['transporterModel', '_data', 'transporterAccounts']), R.find(R.anyPass([R.pathEq(['_data', 'account'], transporterAccount), R.pathEq(['_data', 'account'], transporterAccountNumber)])), R.pathOr('', ['_data', 'name']))(this);
        const elementOption = this.genLabel({
          name,
          label,
          telephone,
          address,
          transporterDeliveryTime,
          transporterPackageTemperature,
          transporterPackageSize,
          transporterPackageType,
          transporterNotes,
          transporterAccount: transporterAccount,
          storeId,
          storeName,
          storeAddress
        });
        return {
          transporterAccount: transporterAccountLabel,
          transporterSenderName,
          transporterSenderTelephone,
          transporterSenderAddress,
          transporterDeliveryTime,
          transporterPackageTemperature,
          transporterPackageSize,
          transporterPackageType,
          transporterNotes,
          transporterDefaultPickupLocationPersonId: personId,
          personId,
          name,
          telephone,
          address,
          label: elementOption,
          model: pickupLocation,
          storeId,
          storeName,
          storeAddress
        };
      })(pickupLocations);
    }
    get defaultPickupLocation() {
      const selectedPersonId = this.selectedPersonId;
      const useDefaults = this.args?.model?._data?.transporterUseDefaultPickupLocation;
      if (useDefaults) {
        const defaultPersonId = this.transporterModel?._data?.defaultPickupLocationPersonId;
        if (defaultPersonId) {
          return this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === defaultPersonId);
        }
        return this?.transporterModel?.transporterData;
      }
      const pathArray = R.split('.')(this.listSelectorValueKey);
      let defaultPersonId = R.path(pathArray)(this.args?.model) || selectedPersonId || this.selectedSenderName;
      if (!defaultPersonId) {
        defaultPersonId = this?.transporterModel?._data?.defaultPickupLocationPersonId;
      }
      if (defaultPersonId) {
        return this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === defaultPersonId);
      }
      return this?.transporterModel?.transporterData;
    }
    genLabel({
      name,
      telephone,
      address,
      transporterDeliveryTime,
      transporterPackageSize,
      transporterPackageType,
      transporterAccount,
      transporterPackageTemperature,
      storeId,
      storeName,
      storeAddress,
      label
    } = {}) {
      let elementOption = '<span>';
      if (label) {
        elementOption += `${this.intl.t('label')} : ${label}<br>`;
      }
      if (name) {
        elementOption += `${this.intl.t('name')} : ${name}<br>`;
      }
      if (telephone) {
        elementOption += `${this.intl.t('telephone')}: ${telephone}<br>`;
      }
      if (address) {
        elementOption += `${this.intl.t('address')} : ${address}<br>`;
      }
      if (RA.isNotNilOrEmpty(transporterAccount)) {
        elementOption += `${this.intl.t('transporter account')} : ${this.intl.t(transporterAccount)}<br>`;
      }
      if (RA.isNotNilOrEmpty(transporterDeliveryTime)) {
        elementOption += `${this.intl.t('delivery time')} : ${this.intl.t(transporterDeliveryTime)}<br>`;
      }
      if (RA.isNotNilOrEmpty(transporterPackageSize)) {
        elementOption += `${this.intl.t('package size')} : ${transporterPackageSize}<br>`;
      }
      if (RA.isNotNilOrEmpty(transporterPackageType)) {
        elementOption += `${this.intl.t('product type')} : ${this.intl.t(transporterPackageType)}<br>`;
      }
      if (RA.isNotNilOrEmpty(transporterPackageTemperature)) {
        elementOption += `${this.intl.t('transporter package temperature')} : ${this.intl.t(transporterPackageTemperature)}<br>`;
      }
      if (RA.isNotNilOrEmpty(storeId)) {
        elementOption += `${this.intl.t('storeId')} : ${storeId}<br>`;
      }
      if (RA.isNotNilOrEmpty(storeName)) {
        elementOption += `${this.intl.t('storeName')} : ${storeName}<br>`;
      }
      if (RA.isNotNilOrEmpty(storeAddress)) {
        elementOption += `${this.intl.t('storeAddress')} : ${storeAddress}<br>`;
      }
      elementOption += '</span>';
      return elementOption;
    }
    *fetchTransporterTask() {
      const transporterModel = this.args?.transporterModel;
      this.transporterModel = transporterModel;
      const transporterKey = transporterModel?._data?._key || this.args.model._data.transporterKey;
      if (!transporterModel || transporterKey) {
        this.transporterModel = yield this.transporterService.fetchTransporterTask.perform({
          transporterKey
        });
      }
      this.setTransporterSenderDefaults();
    }
    getCurrentPerson(personId) {
      const defaultPersonId = this.args.defaultPersonId || '';
      if (!this.selectedSenderName && defaultPersonId) {
        personId = defaultPersonId;
      }
      let person = this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === personId);
      if (this.selectedSenderName !== person?.name && defaultPersonId) {
        person = this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === defaultPersonId);
      }
      if (!person?.personId) {
        this.selectedSenderName = '';
        return;
      }
      return person;
    }
    setTransporterSenderDefaults() {
      const personId = this.transporterModel?._data?.defaultPickupLocationPersonId;
      if (!personId) {
        return;
      }
      const person = this.getCurrentPerson(personId);
      this.onSelectAction(person);
    }
    onSelectAction(sender) {
      this.selectedPersonId = sender?.personId;
      this.selectedSenderName = sender?.name;
      if (this.args?.isReadonly) {
        return;
      }
      const senderModel = R.pipe(R.pathOr([], ['transporterModel', 'pickupLocationList']), R.find(R.pathEq(['_data', 'personId'], this.selectedPersonId)))(this);
      this.args?.onSelect?.(this.transporterModel, senderModel);
    }
    onSelectWrapper(transporterModel, senderModel) {
      this.args?.onSelect?.(transporterModel, senderModel);
    }
    autoSelectOnFirstPerson(model = {}, onCloseDialogAction = fn => fn) {
      if (this.pickupLocationsList.length === 1) {
        const defaultPickupLocationPersonId = this.pickupLocationsList[0].personId;
        model.set('_data.defaultPickupLocationPersonId', defaultPickupLocationPersonId);
        model.set('_data.transporterDefaultPickupLocationPersonId', defaultPickupLocationPersonId);
        this.transporterModel = model;
        this.setTransporterSenderDefaults();
        this.args.onRefreshModel(model);
        onCloseDialogAction();
      }
    }
    autoSelectFirstPerson(model, transporterModel) {
      const persons = transporterModel?._data?.persons || [];
      const hasTransporterDefaultPickupLocationPersonId = R.pipe(R.path(['_data', 'transporterDefaultPickupLocationPersonId']), RA.isNotNilOrEmpty)(model);
      if (persons.length < 1 || hasTransporterDefaultPickupLocationPersonId) {
        return;
      }
      const defaultPickupLocationPersonId = R.pathOr('', [0, '_data', 'personId'])(persons);
      model.set('_data.transporterDefaultPickupLocationPersonId', defaultPickupLocationPersonId);
    }
    refreshTransporterModel(model) {
      this.transporterModel = model;
    }
    setDefaults(toggle) {
      this.useDefaults = toggle;
      if (toggle) {
        this.selectedPersonId = this.transporterModel?._data?.defaultPickupLocationPersonId;
      } else {
        this.selectedSenderName = '';
        this.setTransporterSenderDefaults(this.args.model);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedSenderName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedPersonId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "useDefaults", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCurrentPerson", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "getCurrentPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTransporterSenderDefaults", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setTransporterSenderDefaults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectAction", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectWrapper", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectWrapper"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoSelectOnFirstPerson", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "autoSelectOnFirstPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoSelectFirstPerson", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "autoSelectFirstPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshTransporterModel", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "refreshTransporterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaults", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaults"), _class.prototype), _class);
});