define("client/pods/components/blogs/panels-dash/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    isGuest: false,
    adapterName: 'blogs',
    selectedStatusKey: null,
    selectedTagKeys: null,
    untagSelectedTagKeys: false,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['blogs/lists/tags', 'statuses']);
      this.set('selectedItems', []);
      this.set('selectedStatusKey', null);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
    },
    actions: {
      selectItem(item, isSelected) {
        const selected = this.get('selectedItems') || [];
        if (isSelected === true) {
          selected.pushObject(item);
        } else {
          selected.removeObject(item);
        }
        this.set('selectedItems', selected);
      },
      toggleSelectAllItems() {
        const results = this.get('results') || [];
        const selectedItems = this.get('selectedItems') || [];
        if (selectedItems === results) {
          this.set('selectedItems', []);
        } else {
          this.set('selectedItems', results);
        }
      },
      resetSelectedItems() {
        this.set('selectedItems', []);
      },
      resetSelectedStatusKeyAndSelectedItems() {
        this.set('selectedStatusKey', null);
        this.set('selectedItems', []);
      },
      resetSelectedTagKeys() {
        this.set('selectedTagKeys', []);
        this.set('untagSelectedTagKeys', false);
      },
      resetSelectedTagKeysAndSelectedItems() {
        this.set('selectedTagKeys', []);
        this.set('untagSelectedTagKeys', false);
        this.set('selectedItems', []);
      },
      resetSelectedTagKeysAndStatusKeyAndSelectedItems() {
        this.set('selectedStatusKey', null);
        this.set('selectedTagKeys', []);
        this.set('untagSelectedTagKeys', false);
        this.set('selectedItems', []);
      }
    }
  });
});