define("client/helpers/compare", ["exports", "client/mixins/date", "ramda"], function (_exports, _date, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_date.default, {
    compute(params /* ,  hash */) {
      // @TODO: clean up and test
      if (params[3]) {
        // handle case insensitive conditions if 4 param is passed.
        let paramsZero = params[0];
        if (R.is(String, paramsZero)) {
          paramsZero = paramsZero.toLowerCase();
          params[0] = paramsZero;
        }
        let paramsTwo = params[2];
        if (R.is(String, paramsTwo)) {
          paramsTwo = paramsTwo.toLowerCase();
          params[2] = paramsTwo;
        }
      }
      const v1 = params[0];
      const operator = params[1];
      const v2 = params[2];
      switch (operator) {
        case '==':
          return v1 == v2;
        case '!=':
          return v1 != v2;
        case '===':
          return v1 === v2;
        case '<':
          return v1 < v2;
        case '<=':
          return v1 <= v2;
        case '>':
          return v1 > v2;
        case '>=':
          return v1 >= v2;
        case '&&':
          return !!(v1 && v2);
        case '||':
          return !!(v1 || v2);
        default:
          return false;
      }
    }
  });
});