define("client/pods/components/products/products-editor/customizations-editor/customization-editor/options-editor/component", ["exports", "client/mixins/errors", "client/mixins/droppable", "ramda", "ramda-adjunct"], function (_exports, _errors, _droppable, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_droppable.default, _errors.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    tagName: 'tr',
    originalOptionName: '',
    fillerType: '',
    fillerLabel: '',
    init() {
      this._super(...arguments);
      this.set('originalOptionName', this?.option?._data?.name || '');
      this.crud.addLists(this, ['channels/shop/campaigns/lists/commission-filler']);
    },
    droppable: Ember.computed('options.[]', 'index', function () {
      const index = parseInt(this.index) + 1;
      const options = this.options || [];
      if (index >= options.length) {
        return false;
      }
      return true;
    }),
    draggable: Ember.computed('droppable', 'isReadonly', function () {
      if (this.isFromCampaignDetail) {
        return false;
      }
      if (!this.isReadonly) {
        return this.droppable;
      }
      return false;
    }),
    isCustomizationOptionReadonly: Ember.computed('isReadonly', 'option.{noInitialOptionUid,_data.name}', function () {
      let isCustomizationOptionReadonly = false;
      const option = this.option;
      if (this.isEditing && option.get('noInitialOptionUid') && option.get('_data.name')) {
        isCustomizationOptionReadonly = true;
      }
      if (!isCustomizationOptionReadonly) {
        isCustomizationOptionReadonly = this.isReadonly;
      }
      return isCustomizationOptionReadonly;
    }),
    setAllOptionProp({
      option,
      commissionPropertyName,
      optionIndex
    }) {
      const fillerType = this?.fillerType || '';
      const fillerValue = option.getData(commissionPropertyName);
      const customizationModel = this?.customization || {};
      R.pipe(R.pathOr([], ['_data', 'options']), RA.mapIndexed((option, index) => {
        if (R.equals(fillerType, 'fillTop') && R.lt(index, optionIndex) || R.equals(fillerType, 'fillBottom') && R.gt(index, optionIndex) || R.equals(fillerType, 'fillTopAndBottom')) {
          option.setData(commissionPropertyName, fillerValue);
        }
      }))(customizationModel);
      this.set('fillerType', '');
      this.set('fillerLabel', '');
    }
  }, _applyDecoratedDescriptor(_obj, "setAllOptionProp", [_dec], Object.getOwnPropertyDescriptor(_obj, "setAllOptionProp"), _obj), _obj));
});