define("client/pods/grapesjs/plugins/tabs/components/TabContent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.role = _exports.default = void 0;
  const role = _exports.role = 'tabpanel';
  var _default = (dc, {
    intl = {
      t: txt => txt
    },
    ...config
  }) => {
    dc.addType(config.typeTabContent, {
      model: {
        defaults: {
          name: intl.t('tab content'),
          draggable: false,
          droppable: false,
          copyable: false,
          removable: false,
          highlightable: false,
          attributes: {
            role
          },
          classes: config.classTabContent,
          traits: [],
          ...config.tabContentProps
        }
      }
    });
  };
  _exports.default = _default;
});