define("client/pods/components/emails/panels-activity/component", ["exports", "client/pods/components/emails/mixins/emails-list-by", "client/mixins/crud"], function (_exports, _emailsListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _emailsListBy.default, {
    applicationService: Ember.inject.service('application'),
    adapterName: 'emails',
    filtersAdapterName: 'emails/filters',
    init() {
      this._super(...arguments);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
    },
    didInsertElement() {
      this._super(...arguments);
      const filters = this.filters;
      const adapterName = this.adapterName;
      this.fetchDefaultDataTask.perform({
        adapterName,
        filters,
        resultsProperty: 'results'
      });
    }

    // actions: {

    //   setupResendEmail (email) {
    //     const dirty = this.setupDirty({
    //       adapterName: 'emails',
    //       model: email
    //     })
    //     this.set('dirty', dirty)
    //   },

    //   clearResendEmail () {
    //     this.set('dirty', null)
    //   },

    //   async resendEmail (dirty, onCloseDialogAction) {
    //     await this.get('createRecordTask').perform({
    //       adapterName: 'emails',
    //       appendPath: '/resend',
    //       model: dirty
    //     })

    //     onCloseDialogAction()

    //     const results = await this.get('listByTask').perform({ resultsToggle: '' })
    //     this.set('results', results)
    //   },

    //   cancelResendEmail (onCloseDialogAction) {
    //     onCloseDialogAction()
    //     this.set('dirty', null)
    //   }
    // }
  });
});