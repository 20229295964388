define("client/pods/components/transporters/waybill-display/list/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TransportersWaybillDisplayListComponent = _exports.default = (_dec = Ember.computed('args.model.synced', 'args.shippingWaybill.[]'), _class = class TransportersWaybillDisplayListComponent extends _component.default {
    get transporterWaybillRecords() {
      const shippingWaybill = this.args.shippingWaybill;
      const hasShippingWaybills = RA.isNotNilOrEmpty(shippingWaybill);
      if (hasShippingWaybills) {
        return RA.ensureArray(shippingWaybill);
      }
      return this.args.model?._data?.transporterWaybillRecords || [];
    }
    get isPickingList() {
      let tab = R.pathOr('', ['args', 'tab', 'value'])(this);
      return R.includes('pickingList')(tab);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "transporterWaybillRecords", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "transporterWaybillRecords"), _class.prototype), _class);
});