define("client/pods/components/grapesjs/schedules-modal/component", ["exports", "ramda", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct", "client/utils/nventor"], function (_exports, R, _component, _emberConcurrencyDecorators, _ramdaAdjunct, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GrapesjsSchedulesModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _class = class GrapesjsSchedulesModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "schedulesModelArray", _descriptor2, this);
      _initializerDefineProperty(this, "applySchedulesToAll", _descriptor3, this);
      _initializerDefineProperty(this, "saveDisabled", _descriptor4, this);
      _initializerDefineProperty(this, "schedulesTargetContainerClass", _descriptor5, this);
      _initializerDefineProperty(this, "schedulesTatgetClass", _descriptor6, this);
      this.crud.addLists(this, ['statuses']);
      this.schedulesTargetContainerClass = this.args.schedulesTargetContainerClass || null;
      this.schedulesTatgetClass = this.args.schedulesTatgetClass || null;
      const schedulesTatgetIsArray = this.args.schedulesTatgetIsArray || false;
      this.applySchedulesToAll = !schedulesTatgetIsArray;
      // If is not array treat as apply to all
      // If is array default to false

      this.setSchedulesModelArray();
    }

    /**
     * Set schedules array from stored one
     * If only one object still treats as an array
     */
    setSchedulesModelArray(_applytoall = null) {
      const selected = this.args.editor.getSelected();
      const selectedElement = selected.getEl();

      // The outer container of the target
      let frame;
      if (this.schedulesTargetContainerClass) {
        const containerClass = `.${this.schedulesTargetContainerClass}`;
        frame = selectedElement.querySelector(containerClass);
      }

      // The target classname, will select all inside the target container
      let targets = [];
      if (frame && this.schedulesTatgetClass) {
        const targetClass = `.${this.schedulesTatgetClass}`;
        targets = frame.querySelectorAll(targetClass);
      }
      this.schedulesModelArray = R.map(child => {
        let childSchedules = child.getAttribute('data-gjs-model-schedule') || '{}'; // should be string
        childSchedules = _nventor.default.safeParseJSON(childSchedules);
        let applytoall = false;
        if (_applytoall === null) {
          let childApplytoall = child.getAttribute('data-gjs-model-applytoall');
          childApplytoall = _nventor.default.safeParseJSON(childApplytoall);
          applytoall = childApplytoall === 'true' || childApplytoall === true;
        } else {
          applytoall = _applytoall;
        }
        const elementData = R.pipe(
        // Get only the relevant data
        R.propOr({}, 'dataset'), R.toPairs, R.filter(str => R.test(/Model/)(str)),
        // dynamically rename keys
        R.map(R.over(R.lensPath([0]), (key = ['']) => R.pipe(R.split('Model'), R.last, R.toLower)(key))), R.fromPairs)(child);
        const schedulesModel = this.crud.setupNewRecord({
          adapterName: 'grapesjs/schedules',
          data: elementData
        });
        const schedules = schedulesModel.getData('schedules');
        if (_ramdaAdjunct.default.isNilOrEmpty(schedules)) {
          R.forEach(schedule => schedulesModel.addSchedule({
            ...schedule,
            scheduleId: _nventor.default.random.alphaNum(18)
          }))(childSchedules);
        }
        this.applySchedulesToAll = applytoall;
        return schedulesModel;
      })(targets);
      if (this.applySchedulesToAll) {
        this.schedulesModelArray = this.schedulesModelArray.splice(0, 1);
      }
    }
    *onSave(onCloseDialogAction) {
      const gjsSelectedElement = this.args.editor.getSelected();
      const applySchedulesToAll = this.applySchedulesToAll;
      const schedulesModelArray = this.schedulesModelArray;
      const allSchedules = R.pipe(R.map(schedule => R.pipe(R.pathOr([], ['_data', 'schedules']))(schedule)))(schedulesModelArray);

      // Clear GrapesJs component
      R.reject(R.isNil)(gjsSelectedElement.components());
      const schedulesData = allSchedules.map(_scheduleData => {
        let schedules = [];
        if (applySchedulesToAll) {
          schedules = allSchedules[0];
        } else {
          schedules = _scheduleData;
        }
        schedules = R.pipe(R.map(schedule => {
          if (schedule?.serialize) {
            schedule.serialize();
          }
          return R.pathOr([], ['_data'])(schedule);
        }), R.reject(_ramdaAdjunct.default.isNilOrEmpty))(schedules);
        const gjsModelAttributes = {
          'data-gjs-model-schedule': JSON.stringify(schedules),
          'data-gjs-model-applytoall': applySchedulesToAll
        };
        const _data = {};
        _data.schedulesAttributes = gjsModelAttributes;
        return _data;
      });
      gjsSelectedElement.updateScheddules(schedulesData);
      onCloseDialogAction();
    }
    checkOverlapDate(model = {}, schedules = []) {
      const overlapDate = model.checkOverlapDate({
        model
      });
      if (overlapDate.hasOverlap) {
        this.saveDisabled = true;
        const overlapIds = overlapDate.ids || [];
        R.forEach(schedule => {
          const scheduleId = schedule.getData('scheduleId');
          if (R.includes(scheduleId, overlapIds)) {
            Ember.set(schedule, 'hasOverlap', true);
          } else {
            Ember.set(schedule, 'hasOverlap', false);
          }
        })(schedules);
      } else {
        this.saveDisabled = false;
        R.forEach(schedule => {
          Ember.set(schedule, 'hasOverlap', false);
        })(schedules);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "schedulesModelArray", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "applySchedulesToAll", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "schedulesTargetContainerClass", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "schedulesTatgetClass", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setSchedulesModelArray", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setSchedulesModelArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkOverlapDate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "checkOverlapDate"), _class.prototype), _class);
});