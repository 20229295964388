define("client/pods/channels/shop/rewards/types/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "client/constants"], function (_exports, _adapter, _nventor, _adaptersNew, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    // resourcePath: '',
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: _constants.default.rewardEvents.birthday,
        label: `reward_${_constants.default.rewardEvents.birthday}`
      }, {
        value: _constants.default.rewardEvents.order,
        label: `reward_${_constants.default.rewardEvents.order}`
      }, {
        value: _constants.default.rewardEvents.signup,
        label: `reward_${_constants.default.rewardEvents.signup}`
      }, {
        value: _constants.default.rewardEvents.promotion,
        label: `reward_${_constants.default.rewardEvents.promotion}`
      }, {
        value: _constants.default.rewardEvents.opening,
        label: `reward_${_constants.default.rewardEvents.opening}`
      }, {
        value: _constants.default.rewardEvents.adjustment,
        label: `reward_${_constants.default.rewardEvents.adjustment}`
      }, {
        value: _constants.default.rewardEvents.memberLevelAdjustment,
        label: `reward_${_constants.default.rewardEvents.memberLevelAdjustment}`
      }, {
        value: _constants.default.rewardEvents.pointsAdjustment,
        label: `reward_${_constants.default.rewardEvents.pointsAdjustment}`
      }, {
        value: _constants.default.rewardEvents.referral,
        label: `reward_${_constants.default.rewardEvents.referral}`
      }];
      return _nventor.default.resolve(data);
    }
  });
});