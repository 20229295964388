define("client/pods/components/documents/pos/view/checkout-details/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsPosViewCheckoutDetailsComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('documents'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec6 = Ember._action, _dec7 = Ember._action, _class = class DocumentsPosViewCheckoutDetailsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "documentsService", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "eInvoicesService", _descriptor5, this);
      this.crud.addLists(this, ['paymentMethods', 'extensions/apps/tw/e-invoices/carrier-types']);
    }
    get posPaymentMethods() {
      // @NOTE: only type 'cash' and 'other' are allowed.
      const posMandatoryPaymentMethodsFilters = [R.pathEq(['_data', 'value'], this.constants.paymentMethods.cash), R.pathEq(['_data', 'value'], this.constants.paymentMethods.other)];
      return R.pipe(R.pathOr([], ['crud', 'lists', 'paymentMethods']), R.filter(R.anyPass(posMandatoryPaymentMethodsFilters)))(this);
    }
    get submitBtnLabel() {
      return 'payout';
    }
    afterLoadLists() {
      const cashPaymentMethod = R.pipe(R.pathOr([], ['posPaymentMethods']), R.find(R.pathEq(['_data', 'value'], this.constants.paymentMethods.cash)))(this);
      this.loadPaymentMethod(cashPaymentMethod);
    }
    loadPaymentMethod(paymentMethod) {
      this.args.model.loadPaymentMethod({
        paymentMethod
      });
    }
    *submitPOSTask() {
      const posProfileModel = this.args?.tabParent?.posProfileModel;
      if (!posProfileModel?._data?.autoIssueEInvoice) {
        return yield this.args.onSubmitFinalTask();
      }

      // if userKey is not there, it will use any available
      const userKey = R.pathOr('', ['_data', 'userKey'])(posProfileModel);
      const fetchAvailableCountToIssueData = yield this.eInvoicesService.fetchAvailableCountToIssueTask.perform({
        userKey
      });
      if (!fetchAvailableCountToIssueData?.hasRemainingCount) {
        const message = this.intl.t('tw_eInvoices_numbers_no_available_count_create_docSales_without_eInvoice');
        if (message && window.confirm(message)) {
          return yield this.args.onSubmitFinalTask();
        }
        return;
      }
      yield this.args.onSubmitFinalTask();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "documentsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadPaymentMethod", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadPaymentMethod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitPOSTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "submitPOSTask"), _class.prototype), _class);
});