define("client/pods/components/websites/websites-editor/website-wizard/quick-shop/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WebsitesWebsitesEditorWebsiteWizardWebsiteShopComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _class = class WebsitesWebsitesEditorWebsiteWizardWebsiteShopComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _defineProperty(this, "adapterName", 'websites');
      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/filters');
      _defineProperty(this, "campaignsAdapterName", 'channels/shop/campaigns');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      Ember.set(this, 'filters', filters);
    }
    get shopEditBtnIcon() {
      const model = this.args.model || {};
      const shopKey = model?._data?.shopKey;
      if (shopKey) {
        return 'fas fa-pen';
      }
      return 'fas fa-plus';
    }
    *fetchShopTask(model = {}) {
      const shopKey = model?._data?.shopKey;
      if (shopKey) {
        const shop = yield this.crud.findTask.perform({
          adapterName: this.campaignsAdapterName,
          appendPath: `/${shopKey}`
        });
        this.setShopModel(shop);
      }
      this.unloadShopModel();
    }
    setShopModel(shop) {
      const tab = this.args.tab;
      Ember.set(tab, 'model', shop);
      Ember.set(this, 'shop', shop);
    }
    unloadShopModel() {
      const tab = this.args.tab;
      Ember.set(tab, 'model', '');
      Ember.set(this, 'shop', '');
    }
    loadShopSource(dirtyModel = {}, shop = {}) {
      const shopKey = shop?._data?._key;
      if (shopKey) {
        dirtyModel.setData('shopKey', shopKey);
      }
    }
    unloadShopSource(dirtyModel = {}) {
      dirtyModel.setData('shopKey', '');
    }
    fetchAndSetShopModel(onCloseDialogAction, model = {}) {
      onCloseDialogAction?.();
      this.fetchShopTask.perform(model);
    }
    editShopSource(model = {}) {
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        tab: this.args.tab
      });
      Ember.set(this, 'dirtyModel', dirty);
    }
    cancelEditShopSource() {
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty: this.dirtyModel,
        tab: this.args.tab
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchShopTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchShopTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadShopSource", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadShopSource", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchAndSetShopModel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAndSetShopModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editShopSource", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "editShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEditShopSource", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEditShopSource"), _class.prototype), _class);
});