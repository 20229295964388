define("client/pods/components/files/files-browser-modal-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I2jZdGFV",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"icon\",\"disabled\"],[\"fas fa-images\",[24,[\"disabled\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"files/files-browser\",null,[[\"resource\",\"resourceKey\",\"onCloseDialogAction\",\"onSubmit\"],[[24,[\"resource\"]],[24,[\"resourceKey\"]],[23,1,[]],[24,[\"onSubmit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/files-browser-modal-btn/template.hbs"
    }
  });
});