define("client/pods/payment-methods/credit-cards/banks/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _nventor, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      let data = [{
        label: 'bank of china',
        value: 'boc'
      }, {
        label: 'cathay united bank',
        value: 'cathay'
      }, {
        label: 'chang hwa bank',
        value: 'chb'
      }, {
        label: 'taiwan cooperative bank',
        value: 'coop'
      }, {
        label: 'ctbc',
        value: 'ctbc'
      }, {
        label: 'esun',
        value: 'esun'
      }, {
        label: 'first bank',
        value: 'first'
      }, {
        label: 'fubon',
        value: 'fubon'
      }, {
        label: 'taishin',
        value: 'taishin'
      }, {
        label: 'kgi Bank',
        value: 'kgi'
      }, {
        label: 'national credit card center of ROC',
        value: 'nccc'
      }, {
        label: 'neweb Pay',
        value: 'neweb'
      }, {
        label: 'sinopac',
        value: 'bsp'
      }, {
        label: 'sunny Bank',
        value: 'sunny'
      }, {
        label: 'union bank Of taiwan',
        value: 'ubot'
      }];
      return _nventor.default.resolve(data);
    }
  });
});