define("client/pods/options/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: '_id',
    serialize(model) {
      let data = this._super(model);
      data = this.removeNotRequired(data);
      return data;
    },
    removeNotRequired(data) {
      const relationships = data.relationships || [];
      let hasSales = false;
      if (relationships.indexOf('customer') > -1) {
        hasSales = true;
      }
      let hasPurchases = false;
      if (relationships.indexOf('supplier') > -1) {
        hasPurchases = true;
      }
      if (!hasSales) {
        data.salesTaxMethodKey = '';
        data.salesPaymentMethodKey = '';
        data.salesPaymentTermsKey = '';
        data.salesAccount = '';
      }
      if (!hasPurchases) {
        data.purchasesTaxMethodKey = '';
        data.purchasesPaymentMethodKey = '';
        data.purchasesPaymentTermsKey = '';
        data.purchasesAccount = '';
      }
      return data;
    }
  });
});