define("client/services/page-blocks", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PageBlocksService extends Ember.Service {
    // @action
    // addBlock(
    //   {
    //     model,
    //     pageBlocksPath,
    //     pageBlock
    //   }
    // ) {
    //   const pageBlocks = model[pageBlocksPath] || []
    //   pageBlocks.pushObject(pageBlock)

    //   if (R.startsWith('_data.')(pageBlocksPath)) {
    //     return model.setData('pageBlocks', pageBlocks)
    //   }

    //   return model.set(pageBlocksPath, pageBlocks)
    // }

    // @action
    // removeBlock(
    //   {
    //     model,
    //     pageBlocksPath,
    //     pageBlock
    //   }
    // ) {
    //   const pageBlocks = model[pageBlocksPath] || []
    //   blocks.removeObject(pageBlock)

    //   if (R.startsWith('_data.')(pageBlocksPath)) {
    //     return model.setData('pageBlocks', pageBlocks)
    //   }

    //   return model.set(pageBlocksPath, pageBlocks)
    // }
  }
  _exports.default = PageBlocksService;
});