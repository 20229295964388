define("client/pods/components/documents/table-view/doc-row/component", ["exports", "ramda", "ramda-adjunct", "ember-concurrency-decorators", "client/mixins/crud", "client/mixins/dispatch", "client/mixins/transporter", "ramda-extension"], function (_exports, R, RA, _emberConcurrencyDecorators, _crud, _dispatch, _transporter, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _dispatch.default, _transporter.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    big: Ember.inject.service(),
    tagName: '',
    showAllDetails: false,
    showSplitMergePrintBtns: false,
    printType: '',
    isShowGroupedDocuments: false,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['dispatch/lists/date-header', 'extensions/apps/tw/t-cat/lists/delivery-time', 'documents/lists/filters/dispatch-status', {
        adapterName: 'channels/shop/delivery-date-options',
        appendPath: 'dispatch-type/zones'
      }, {
        adapterName: 'channels/shop/delivery-date-options',
        appendPath: 'dispatch-type/pickupLocations'
      }]);
      if (this.isFilters) {
        const defaultFiltersData = {};
        const source = R.path(['filters', '_data', 'source'])(this);
        if (source) {
          defaultFiltersData.query = source;
        }
        const campaignFilters = this.search.setupFilters({
          adapterName: 'channels/shop/campaigns/filters',
          defaultFiltersData
        });
        this.set('campaignFilters', campaignFilters);
      }
      this.updateModelWithProxyModel(this.model);
    },
    updateModelWithProxyModel(modelProxy) {
      if (!modelProxy?.model) {
        return;
      }
      this.set('modelProxy', modelProxy);
      this.set('model', modelProxy.model);
    },
    tCatResults: Ember.computed('modelProxy', function () {
      return R.pipe(R.pathOr([], ['modelProxy', 'modal']), RA.ensureArray)(this);
    }),
    tCatAllResults: Ember.computed('modelProxy', function () {
      return RA.ensureArray(this.modelProxy);
    }),
    showDispatchDateZ: Ember.computed('settings.settingsModel._data.salesInvoicesDatesColumns.[]', function () {
      const settingsModel = this?.settings?.settingsModel;
      const salesInvoicesDatesColumns = settingsModel?._data?.salesInvoicesDatesColumns || [];
      if (R.includes('dispatchDateZ')(salesInvoicesDatesColumns)) {
        return true;
      }
      return false;
    }),
    showExpectedPrepareDateZ: Ember.computed('settings.settingsModel._data.salesInvoicesDatesColumns.[]', function () {
      const settingsModel = this?.settings?.settingsModel;
      const salesInvoicesDatesColumns = settingsModel?._data?.salesInvoicesDatesColumns || [];
      if (R.includes('expectedPreparedDateZ')(salesInvoicesDatesColumns)) {
        return true;
      }
      return false;
    }),
    showExpectedDispatchDateZ: Ember.computed('settings.settingsModel._data.salesInvoicesDatesColumns.[]', function () {
      const settingsModel = this?.settings?.settingsModel;
      const salesInvoicesDatesColumns = settingsModel?._data?.salesInvoicesDatesColumns || [];
      if (R.includes('latestDispatchDateZ')(salesInvoicesDatesColumns)) {
        return true;
      }
      return false;
    }),
    showTransporterArrivalDateZ: Ember.computed('settings.settingsModel._data.salesInvoicesDatesColumns.[]', function () {
      const settingsModel = this?.settings?.settingsModel;
      const salesInvoicesDatesColumns = settingsModel?._data?.salesInvoicesDatesColumns || [];
      if (R.includes('expectedArrivalDateZ')(salesInvoicesDatesColumns)) {
        return true;
      }
      return false;
    }),
    *tCatSetDefaults() {
      const tCatModel = yield this.dispatch.tCatGetModel.perform();
      const printType = tCatModel._data.defaultTransporterWaybillPrintType;
      this.set('printType', printType);
    },
    isDocumentsGroupMaster: Ember.computed('modelProxy.model._data.{isGrouped,groupedWith}', function () {
      const model = this?.modelProxy?.model || {};
      const isGrouped = model.getData('isGrouped') || false;
      const groupedWith = model.getData('groupedWith') || [];
      if (!isGrouped && RA.isNotNilOrEmpty(groupedWith)) {
        return true;
      }
      return false;
    }),
    // {{if (isEven rowIndex) 'even' 'odd'}}
    detailInfoRowClassName: Ember.computed('isDetailRow', 'rowIndex', 'tableViewOptions.{allowDispatching,isDispatchTab}', function () {
      if (this.isDetailRow && this.get('tableViewOptions.allowDispatching') && this?.tableViewOptions?.isDispatchTab) {
        return 'dispatch-list__table-info-row';
      }

      // if (this.isDetailRow) {
      //   if (nventor.isEven(this.rowIndex)) {
      //     return 'even'
      //   }

      //   return 'odd'
      // }

      return '';
    }),
    detailRowClassName: Ember.computed('isDetailRow', 'rowIndex', 'tableViewOptions.allowDispatching', function () {
      // if (this.isDetailRow && this.get('tableViewOptions.allowDispatching')) {
      //   return ''
      // }

      // if (this.isDetailRow) {
      //   if (nventor.isEven(this.rowIndex)) {
      //     return 'even'
      //   }

      //   return 'odd'
      // }

      return '';
    }),
    customPaymentMethods: Ember.computed('crud.lists.paymentMethods', function () {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.reject(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    }),
    defaultPaymentMethods: Ember.computed('crud.lists.paymentMethods', function () {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.filter(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    }),
    isShowImagesOnDocuments: Ember.computed('tableViewOptions.isDispatchTab', 'settings.settingsModel._data.showImagesOnDocuments', function () {
      const isDispatchTab = this?.tableViewOptions?.isDispatchTab || false;
      if (isDispatchTab) {
        // show images will handled by dispatch.showDocumentDetailsThumbnailImage
        return false;
      }
      return this?.settings?.settingsModel?._data?.showImagesOnDocuments || false;
    }),
    allowIndividualPrintWaybill: Ember.computed('tab.isDispatched', 'tab.dispatchProcess._data.allowPrintAllWaybills', 'model.allowPrintWaybill', 'model._data.transporterExtension', function () {
      const allowPrintAllWaybills = R.pathEq(['tab', 'dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);
      const isDispatched = R.pathEq(['tab', 'isDispatched'], true)(this);
      const allowPrintWaybill = R.pathEq(['model', 'allowPrintWaybill'], true)(this);
      if (allowPrintWaybill && (allowPrintAllWaybills || isDispatched)) {
        return true;
      }
      return false;
    }),
    hasDeliveryDateOptions: Ember.computed('crud.lists.{channels-shop-delivery-date-options--dispatch-type-zones.[],channels-shop-delivery-date-options--dispatch-type-pickupLocations.[]}', function () {
      if (RA.isNotNilOrEmpty(this.crud.lists['channels-shop-delivery-date-options--dispatch-type-zones'])) {
        return true;
      }
      if (RA.isNotNilOrEmpty(this.crud.lists['channels-shop-delivery-date-options--dispatch-type-pickupLocations'])) {
        return true;
      }
      return false;
    }),
    toggleMoreBtns() {
      this.set('showSplitMergePrintBtns', !this.showSplitMergePrintBtns);
    },
    *reloadSortWithNewHeader() {
      const hasHeadersSort = this?.hasHeadersSort;
      if (!hasHeadersSort) {
        return;
      }
      const filters = this.filters;
      const dateHeaderValue = filters?._data?.dateHeaderValue || 'timestampZ_latest';
      filters.replaceSort(dateHeaderValue, 'DESC');
      yield this.onReloadSearchRecordsTask.perform({
        dispatchProcess: this.tableViewOptions.dispatchProcess,
        period: this.period,
        filters
      });
    },
    afterMerge() {
      const dispatchProcess = this.tableViewOptions?.dispatchProcess || this.tab?.dispatchProcess;
      const onAfterMerge = this.get('tableViewOptions.onAfterMerge');
      onAfterMerge(dispatchProcess);
    },
    afterSplit() {
      const dispatchProcess = this.tableViewOptions?.dispatchProcess || this.tab?.dispatchProcess;
      const onAfterSplit = this.get('tableViewOptions.onAfterSplit');
      onAfterSplit(dispatchProcess);
    },
    actions: {
      select(/* model */
      ) {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },
      loadShopSource(shop) {
        const filters = this.filters;
        filters.setData('sourceKey', shop.getData('_key'));
        if (this.onSearchTask) {
          this.onSearchTask.perform({
            filters: this.filters
          });
        }
      },
      unloadShopSource() {
        const filters = this.filters;
        filters.setData('sourceKey', '');
        if (this.onSearchTask) {
          this.onSearchTask.perform({
            filters: this.filters
          });
        }
      },
      selectDispatchProcesses() {
        const filters = this.filters;
        const dispatchProcessesModels = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
        const dispatchProcesses = filters.getData('dispatchProcesses');
        const dispatchProcessesCriterias = R.pipe(R.filter(dispatchProcess => {
          return R.includes(dispatchProcess.getData('code'), dispatchProcesses);
        }), R.map(R.path(['_data', 'criterias'])))(dispatchProcessesModels);
        filters.setData('multipleDispatchProcessesCriterias', dispatchProcessesCriterias);
      },
      toggleAllDetails() {
        let showAllDetails = this.showAllDetails;
        if (showAllDetails) {
          showAllDetails = false;
        } else {
          showAllDetails = true;
        }
        this.set('showAllDetails', showAllDetails);
        const results = this.results;
        results.forEach(modelProxy => {
          modelProxy.set('showDetails', showAllDetails);
        });
      },
      onClickShowGroupedDocuments() {
        const isShowGroupedDocuments = this.isShowGroupedDocuments || false;
        this.set('isShowGroupedDocuments', !isShowGroupedDocuments);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "updateModelWithProxyModel", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateModelWithProxyModel"), _obj), _applyDecoratedDescriptor(_obj, "tCatSetDefaults", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "tCatSetDefaults"), _obj), _applyDecoratedDescriptor(_obj, "toggleMoreBtns", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleMoreBtns"), _obj), _applyDecoratedDescriptor(_obj, "reloadSortWithNewHeader", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "reloadSortWithNewHeader"), _obj), _applyDecoratedDescriptor(_obj, "afterMerge", [_dec3], Object.getOwnPropertyDescriptor(_obj, "afterMerge"), _obj), _applyDecoratedDescriptor(_obj, "afterSplit", [_dec4], Object.getOwnPropertyDescriptor(_obj, "afterSplit"), _obj), _obj));
});