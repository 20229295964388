define("client/pods/grapesjs/plugins/slider-native/index", ["exports", "ramda", "client/pods/grapesjs/plugins/slider-native/components", "client/pods/grapesjs/plugins/slider-native/blocks"], function (_exports, R, _components, _blocks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: slider
   * @author Nafis Rubio
   * @since 26/05/2022
   * @copyright (C) 2022 BigOrdr
   * @description: Slider plugin for grapesjs
   */
  var _default = editor => {
    const bigordrSliderNative = 'bigordr-slider-native';
    const customOptions = R.pathOr({}, ['Config', 'pluginsOpts', bigordrSliderNative])(editor);
    const slideId = Math.random().toString(36).substring(2, 15);
    const defaultOptions = {
      slideId,
      // Object to extend the default slider block, eg. `{ label: 'Slider', attributes: { ... } }`
      // Pass a falsy value to avoid adding the block
      sliderBlock: {},
      // Object to extend the default slider properties, eg. `{ name: 'My Slider', draggable: false, ... }`
      sliderProps: {},
      // Object to extend the default slider frame properties
      frameProps: {},
      // Object to extend the default slides properties
      slidesProps: {},
      // Object to extend the default slide properties
      slideProps: {},
      // Object to extend the default previous nav properties
      prevProps: {},
      // Object to extend the default next nav properties
      nextProps: {},
      // Default slides
      slideEls: `
            <li class="gjs-lory-slide-min-width gjs-lory-slide gjs-lory-slide-id-${slideId} js_slide"></li>
            <li class="gjs-lory-slide-min-width gjs-lory-slide gjs-lory-slide-id-${slideId} js_slide"></li>
            <li class="gjs-lory-slide-min-width gjs-lory-slide gjs-lory-slide-id-${slideId} js_slide"></li>
    `,
      // Previous nav element string, as for example, an HTML string
      prevEl: `<i class="fas fa-angle-left"></i>`,
      // prevEl: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 501.5 501.5">
      //     <g><path fill="#2E435A" d="M302.67 90.877l55.77 55.508L254.575 250.75 358.44 355.116l-55.77 55.506L143.56 250.75z"/></g>
      //   </svg>`,

      // Next nav element string, as for example, an HTML string
      nextEl: `<i class="fas fa-angle-right"></i>`,
      // Class name for the slider frame
      classFrame: 'gjs-lory-frame gjs-lory-frame-min-width',
      // Class name for slides container
      classSlides: 'gjs-lory-slides',
      // Class name for slides container
      classSlide: 'gjs-lory-slide',
      // Class name for slider previous control
      classPrev: 'lory-prev',
      // Class name for slider next control
      classNext: 'lory-next',
      // Script to load dynamically in case no lory instance was found
      script: 'https://cdnjs.cloudflare.com/ajax/libs/lory.js/2.3.4/lory.min.js'
    };
    const options = R.mergeRight(defaultOptions, customOptions);

    // Add components
    (0, _components.default)(editor, options);

    // Add blocks
    (0, _blocks.default)(editor, options);
  };
  _exports.default = _default;
});