define("client/pods/components/schedules/btn-scheduled/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SchedulesComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _class = class SchedulesComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "constants", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _initializerDefineProperty(this, "settings", _descriptor4, this);
      _initializerDefineProperty(this, "results", _descriptor5, this);
      _initializerDefineProperty(this, "pending", _descriptor6, this);
      let resources = this.args.resources || [];
      resources = R.reject(_nventor.default.isNilOrEmptyDotPath('resourceKey'))(resources);
      const filters = this.search.setupFilters({
        adapterName: 'schedules/filters',
        appendPath: '/resources',
        defaultFiltersData: {
          resources
        }
      });
      this.filters = filters;
      this.fetchScheduledTasksByResourceTask.perform();
    }
    get isDisabled() {
      const hasResults = RA.isNotNilOrEmpty(this.results);
      if (hasResults) {
        return false;
      }
      return true;
    }
    *fetchScheduledTasksByResourceTask(options = {}) {
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'schedules',
        filters: this.filters
      });
      this.results = results;
      this.pending = R.filter(R.pathEq(['_data', 'status'], this.constants.schedulesStatus.pending))(results);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pending", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchScheduledTasksByResourceTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchScheduledTasksByResourceTask"), _class.prototype), _class);
});