define("client/pods/components/dispatch/processes-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/crud"], function (_exports, R, RA, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    dispatchService: Ember.inject.service('dispatch'),
    shopsList: '',
    init() {
      this.crud.addLists(this, ['paymentMethods', 'dispatch/lists/export-file-type']);
      this._super(...arguments);
      const isEditing = this.isEditing;
      const model = this.model;
      if (isEditing) {
        const dirty = this.crud.setupDirty({
          adapterName: 'dispatch/lists/processes',
          model
        });
        dirty.set('isPartial', true);
        const exportFileType = dirty.getData('exportFileType');
        const exportFileTypeLabel = dirty.getData('exportFileType');
        if (RA.isNilOrEmpty(exportFileType) || RA.isNilOrEmpty(exportFileTypeLabel)) {
          dirty.setData('exportFileType', 'xlsx');
          dirty.setData('exportFileTypeLabel', 'xlsx');
        }
        this.set('dirty', dirty);
      } else {
        let data = {};
        if (model) {
          data = model.serialize();
        }
        const newModel = this.crud.setupNewRecord({
          adapterName: 'dispatch/lists/processes'
        });
        newModel.populate(data);
        const exportFileType = newModel.getData('exportFileType');
        const exportFileTypeLabel = newModel.getData('exportFileType');
        if (RA.isNilOrEmpty(exportFileType) || RA.isNilOrEmpty(exportFileTypeLabel)) {
          newModel.setData('exportFileType', 'xlsx');
          newModel.setData('exportFileTypeLabel', 'xlsx');
        }
        this.set('dirty', newModel);
        if (!newModel.getData('isSystemProcess')) {
          const list = this.list;
          const onlyCustomSteps = R.filter(R.hasPath(['_data', '_key']))(list) || [];
          const lastStep = onlyCustomSteps.get('lastObject');
          let nextIndex = 0;
          if (lastStep) {
            nextIndex = lastStep?._data?.index + 1;
          }
          newModel.setData('index', nextIndex);
        }
      }
      this.dispatchService.fetchSpecsTask.perform(this);
    },
    afterCreateRecordTask() {
      this.onCloseDialogAction();
    },
    afterReplaceRecordTask(/*updatedDispatchProcessModel*/
    ) {
      const onRefresh = this.onRefresh;
      if (onRefresh) {
        onRefresh(this.model);
      }
      this.onCloseDialogAction();
    },
    afterRemoveRecordTask() {
      this.onCloseDialogAction();
    },
    actions: {
      cancel(model) {
        if (this.isEditing) {
          this.crud.cancelEditing({
            adapterName: 'dispatch/lists/processes',
            dirty: model
          });
        }
        this.onCloseDialogAction();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "afterReplaceRecordTask", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterReplaceRecordTask"), _obj), _obj));
});