define("client/pods/components/documents/panel-display/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, {
    tabsManager: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setupTask.perform();
    },
    setupTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.get('tab');
      let model = tab?.model;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      const filters = this.filters;
      if (!model) {
        model = loadInstructions?._data?.model;
      }
      const id = loadInstructions?._data?.find?.id;
      if (!model && id) {
        const found = yield this.findTask.perform({
          appendPath: id
        });
        model = found;
      }
      const query = loadInstructions?._data?.search?.query;
      if (!model && query) {
        filters.setData('query', query);
        const results = yield this.searchRecordsTask.perform({
          filters
        });
        const found = results.get('firstObject');
        model = found;
      }

      // @TODO: handle when no model found. needs a message
      if (!model) {
        return;
      }
      this.set('model', model);
      if (!model.getData('discountMethod')) {
        model.setData('discountMethod', this.get('constants.discountMethods.onTotal'));
      }
      if (!model.getData('discountType')) {
        model.setData('discountType', this.get('constants.discountTypes.rate'));
      }
      const adapterName = this.get('tab.config.adapterName');
      this.set('adapterName', adapterName);
      this.tabsManager.setHeader(this.tabParent, '#' + model.getData('docNo') + ' ' + model.getData('contact'));
    })
  });
});