define("client/pods/components/contacts/groups/groups-editor/criteria-editor/by/shop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GgKwrp6k",
    "block": "{\"symbols\":[\"@model\",\"@isReadonly\"],\"statements\":[[5,\"forms/form-section\",[],[[\"@context\",\"@documentationResource\"],[\"rewardsEditor\",\"emailsCampaigns\"]],{\"statements\":[[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"documentationResource\"],[\"shop\",[24,[\"errors\"]],\"shopKey\",\"emailsCampaigns\"]],{\"statements\":[[0,\"    \"],[1,[28,\"lists/list-searcher\",null,[[\"allowCustom\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"selectedItemValueKey\",\"value\",\"hasSelected\",\"readonly\",\"disabled\",\"resultsItemComponent\",\"isRunning\",\"onSearch\",\"onSelect\",\"onDeselect\"],[false,[23,0,[\"filters\",\"results\"]],\"_data._key\",\"_data.name\",\"_data.name\",[23,1,[\"_data\",\"shop\"]],[23,1,[\"_data\",\"shopKey\"]],[23,2,[]],[23,2,[]],\"channels/shop/campaigns/search-results\",[23,0,[\"crud\",\"searchInputTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"crud\",\"searchInputTask\"]],[28,\"hash\",null,[[\"adapterName\",\"filters\",\"query\"],[\"channels/shop/campaigns\",[23,0,[\"filters\"]],[23,1,[\"_data\",\"source\"]]]]]],null],[28,\"action\",[[23,0,[]],\"loadShopSource\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"unloadShopSource\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/groups/groups-editor/criteria-editor/by/shop/template.hbs"
    }
  });
});