define("client/pods/components/contacts/details/editor-details/editor-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/7DL2rnw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,[[\"formInitData\",\"label\",\"customBlock\",\"errors\",\"context\"],[[24,[\"formInitData\"]],\"email\",true,[24,[\"errors\"]],\"value\"]],{\"statements\":[[0,\"\\t\\n\"],[4,\"forms/form-label\",null,[[\"context\",\"documentationResource\"],[[24,[\"detail\",\"_data\",\"type\"]],\"contacts\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"tt\",[\"email\"],null],false],[0,\"\\n\\t\\t\"],[1,[28,\"contacts/icons/detail-icon\",null,[[\"detail\"],[[24,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\n\"],[4,\"forms/form-input\",null,null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"value\",\"onSelect\"],[[24,[\"autofocus\"]],[24,[\"detail\",\"_data\",\"value\"]],[24,[\"onCheckForDefaults\"]]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"contacts/icons/default-icon\",null,[[\"detail\"],[[24,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/details/editor-details/editor-email/template.hbs"
    }
  });
});