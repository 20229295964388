define("client/pods/components/contacts/groups/groups-editor/criteria-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DTcHnivf",
    "block": "{\"symbols\":[\"@errorIndex\",\"@errors\",\"@criteria\",\"@isReadonly\",\"@model\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"u-width-20\"],[8],[0,\"\\n    \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"forms/form-input-row\",[],[[\"@formInputClassNames\",\"@errors\",\"@context\"],[\"flex-container-row flex-container--justify-space-between\",[23,2,[]],\"criterias.by\"]],{\"statements\":[[0,\"\\n        \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@readonly\",\"@disabled\",\"@translate\",\"@list\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\"],[true,[23,4,[]],[23,4,[]],true,[23,0,[\"crud\",\"lists\",\"contacts-groups-criterias-by\"]],\"_data.value\",\"_data.label\",[23,3,[\"_data\",\"by\"]]]]],[0,\"\\n\\n        \"],[5,\"elements/btn-delete\",[],[[\"@label\",\"@disabled\",\"@onSubmit\"],[\"\",[23,4,[]],[28,\"fn\",[[23,5,[\"removeCriteria\"]],[23,3,[]]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"criteriaByComponent\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[23,0,[\"criteriaByComponent\"]]],[[\"isReadonly\",\"model\",\"errors\",\"errorIndex\"],[[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/groups/groups-editor/criteria-editor/template.hbs"
    }
  });
});