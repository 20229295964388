define("client/pods/components/elements/element-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IrS6tRbp",
    "block": "{\"symbols\":[\"opt\",\"@value\",\"@selectClassNames\",\"@disabled\",\"@selectBackgroundClassNames\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"select \",[23,3,[]]]]],[8],[0,\"\\n  \"],[7,\"select\",true],[11,\"disabled\",[23,4,[]]],[11,\"class\",[29,[\"full-width \",[23,5,[]]]]],[11,\"onchange\",[28,\"fn\",[[23,0,[\"select\"]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasEmpty\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\",true],[10,\"value\",\"\"],[8],[0,\"\\n        \"],[1,[28,\"tt\",[\"please select\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,0,[\"_options\"]]],null,{\"statements\":[[0,\"      \"],[5,\"elements/element-select/select-option\",[],[[\"@option\",\"@optionLabelKey\",\"@optionValueKey\",\"@translate\",\"@value\"],[[23,1,[]],[23,0,[\"optionLabelKey\"]],[23,0,[\"optionValueKey\"]],[23,0,[\"translate\"]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-select/template.hbs"
    }
  });
});