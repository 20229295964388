define("client/pods/components/msgs/warning-msg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rbUZfKHI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"elements/element-icon\",[],[[\"@icon\",\"@classNames\"],[\"fas fa-exclamation-triangle\",\"has-text-warning\"]]],[0,\"\\n\\n\"],[1,[23,0,[\"translatedMessage\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/msgs/warning-msg/template.hbs"
    }
  });
});