define("client/pods/components/elements/btn-delete/component", ["exports", "ramda", "ember-concurrency-decorators"], function (_exports, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    label: 'delete',
    title: 'delete',
    btnClassNames: 'button--delete',
    icon: 'fas fa-trash',
    requiresConfirmation: true,
    typeToDelete: false,
    init() {
      this._super(...arguments);
      this.set('typedConfirmationText', '');
      let translate = true;
      if (!R.isNil(this.translate)) {
        translate = this.translate;
      }
      this.set('_translate', translate);
    },
    _requiresConfirmation: Ember.computed('requiresConfirmation', 'typeToDelete', 'confirmMsg', 'confirmMsgTranslate', function () {
      if (this.requiresConfirmation || this.typeToDelete || this.confirmMsg || this.confirmMsgTranslate) {
        return true;
      }
      return false;
    }),
    isDeleteDisabled: Ember.computed('typedConfirmationText', function () {
      const deleteKeyword = this.intl.t('delete_keyword');
      const typedConfirmationText = this?.typedConfirmationText || '';
      const confirmation = R.pipe(R.trim, R.toUpper)(typedConfirmationText);
      if (confirmation === deleteKeyword) {
        return false;
      }
      return true;
    }),
    *deleteWithTypedConfirmationTask(onCloseDialogAction, ...rest) {
      yield this.onSubmit(...rest, {
        preOnAfter: onCloseDialogAction
      });
    },
    cancel(onCloseDialogAction) {
      this.set('typedConfirmationText', '');
      onCloseDialogAction();
    },
    clearTypeToDelete() {
      this.set('typedConfirmationText', '');
    },
    copyText() {
      this.application.copyText(this.intl.t('delete_keyword'));
    }
  }, _applyDecoratedDescriptor(_obj, "deleteWithTypedConfirmationTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "deleteWithTypedConfirmationTask"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj), _applyDecoratedDescriptor(_obj, "clearTypeToDelete", [_dec2], Object.getOwnPropertyDescriptor(_obj, "clearTypeToDelete"), _obj), _applyDecoratedDescriptor(_obj, "copyText", [_dec3], Object.getOwnPropertyDescriptor(_obj, "copyText"), _obj), _obj));
});