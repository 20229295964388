define("client/pods/components/products/inventory/table-view/detail-row/component", ["exports", "ember-concurrency", "client/mixins/crud", "client/mixins/date", "client/mixins/errors"], function (_exports, _emberConcurrency, _crud, _date, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, _errors.default, {
    crud: Ember.inject.service(),
    tagName: '',
    adapterName: 'products/inventory/adjustments',
    init() {
      this.crud.addLists(this, ['units', 'products/inventory/lists/adjustments']);
      this._super(...arguments);
      this.setup();
    },
    setup(direction) {
      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          types: [this.get('constants.productTypes.inventory')],
          tracking: this.get('constants.productTracking.on'),
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }, {
            key: 'setType',
            value: 'isSet'
          }]
        }
      });
      this.set('productsFilters', productsFilters);
      if (this.isNew) {
        const dateZ = this.getStartOfDayZ();
        direction = direction || 'increase';
        const description = this.getDescription(direction);
        const data = {
          dateZ,
          adjustment: direction,
          description
        };
        const model = this.setupNewRecord({
          adapterName: this.adapterName,
          data
        });
        this.set('model', model);
        const defaultProduct = this.defaultProduct;
        if (defaultProduct) {
          model.setData('itemKey', defaultProduct.getData('_key'));
          model.setData('itemCode', defaultProduct.getData('code'));
          this.loadProductTask.perform(defaultProduct);
        } else {
          this.set('product', '');
        }
      } else {
        this.set('product', '');
      }
    },
    isIncrease: Ember.computed('model._data.adjustment', function () {
      if (this.get('model._data.adjustment') === 'increase') {
        return true;
      }
      return false;
    }),
    isReadonly: Ember.computed('isEditing', function () {
      return !this.isEditing;
    }),
    isItemReadonly: Ember.computed('isReadonly', function () {
      if (this.isReadonly) {
        return true;
      }
      return this.defaultProduct;
    }),
    getDescription(direction) {
      return this.intl.t('inventory adjustment') + ': ' + this.intl.t(direction);
    },
    saveTask: (0, _emberConcurrency.task)(function* () {
      const isNew = this.isNew;
      if (isNew) {
        const adapterName = this.adapterName;
        const model = this.model;
        const saved = yield this.createRecordTask.perform({
          adapterName,
          model
        });
        if (saved) {
          this.reset();
        }
      }
    }),
    searchProductsTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      query
    }) {
      if (query?.trim?.() !== '') {
        const filters = this.productsFilters;
        filters.setData('query', query);
        const results = yield this.searchInputTask.perform({
          adapterName,
          filters
        }, query);
        return results;
      }
    }),
    loadProductTask: (0, _emberConcurrency.task)(function* (product) {
      this.set('product', product);
      const productsService = this.productsService;
      const model = this.model;
      const itemKey = model.getData('itemKey');
      const {
        preferredHistoryData
      } = yield productsService.get('fetchPurchasesHistoryTask').perform(model, 'others', product, itemKey);
      model.loadProduct(product, preferredHistoryData);
    }),
    reset() {
      const direction = this.model.getData('adjustment');
      this.setup(direction);
    },
    actions: {
      reset() {
        this.reset();
      },
      resetProduct() {
        const detail = this.model;
        detail.setData('item', '');
        detail.setData('itemCode', '');
        detail.setData('itemKey', '');
        this.set('product', '');
      },
      setDescription(adjustment) {
        const description = this.getDescription(adjustment.getData('value'));
        this.model.setData('description', description);
        if (description === 'decrease') {
          this.model.setData('exclPrice', '');
        }
      }
    }
  });
});