define("client/pods/components/helpers/creator-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _obj = {
    tagName: 'span',
    classNames: ['u-desc', 'u-desc--mini', 'u-desc--italics', 'u-padding--3'],
    iconOnly: false,
    popupIsVisible: false,
    showPopup: false,
    sortedModified: Ember.computed('model._data.modified.[]', function () {
      let modified = this.get('model._data.modified') || [];
      if (!Ember.isArray(modified)) {
        modified = [modified];
      }
      return modified.sortBy('md');
    }),
    isDropdownVisible: Ember.computed('showDropdown', 'iconOnly', 'sortedModified.[]', function () {
      if (this.iconOnly && this.showDropdown) {
        return true;
      }
      if (this.sortedModified.length > 0 && this.showDropdown) {
        return true;
      }
      return false;
    }),
    togglePopup() {
      this.set('showDropdown', true);
    },
    onToggleModalDialog() {
      this.set('showDropdown', false);
    }
  }, _applyDecoratedDescriptor(_obj, "togglePopup", [_dec], Object.getOwnPropertyDescriptor(_obj, "togglePopup"), _obj), _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj), _obj));
});