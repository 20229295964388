define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/onsale-schedules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "phlM30rm",
    "block": "{\"symbols\":[\"@isReadonly\",\"@schedule\",\"@model\",\"@isHeader\",\"@isBody\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"th\",true],[8],[0,\"\\n    \"],[1,[28,\"tt\",[\"on sale\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,3,[\"isStandardProduct\"]]],null,{\"statements\":[[0,\"    \"],[7,\"th\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"inclusive discounted price\"],null],false],[0,\"\\n    \"],[9],[0,\" \\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n\"],[0,\"    \"],[5,\"elements/element-toggler\",[],[[\"@disabled\",\"@toggleValue\"],[true,[23,2,[\"_data\",\"isOnSale\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,3,[\"isStandardProduct\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[[23,1,[]],[23,2,[\"_data\",\"priceDiscounted\"]]]]],[0,\"\\n    \"],[9],[0,\" \\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/onsale-schedules/template.hbs"
    }
  });
});