define("client/pods/documents/data/pending/filters/model", ["exports", "client/pods/documents/filters/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      const resetDefaults = this.resetDefaults || {};
      return R.mergeRight({
        page: 1,
        count: 10,
        query: '',
        item: '',
        itemCode: '',
        contact: '',
        master: '',
        match: ['all'],
        code: '',
        docNo: '',
        taxMethod: 'exclusive',
        // statuses: [this.get('constants.status.active')],
        method: 'byProducts',
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      }, resetDefaults);
    },
    isTaxExclusive: Ember.computed('_data.taxMethod', function () {
      if (this?._data?.taxMethod === 'inclusive') {
        return false;
      }
      return true;
    })
  });
});