define("client/pods/components/lists/list-option/component", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: 'span',
    isCheckbox: false,
    multiSelect: false,
    classNameBindings: ['isSelectedClass:is-selected', 'isTag:tag', 'disabled', 'isFilterTag:filter-tag'],
    isTag: false,
    isFilterTag: false,
    rememberSelected: true,
    // determine if it should remember selected state
    isSelected: false,
    translate: true,
    disabled: false,
    optionsComponents: null,
    // selectedComponents: null, //array to keep track of selected components
    optionLabelKey: '_data.label',
    // key that points to option's label
    optionValueKey: '_data.value',
    // key that points to option's value
    prefix: '',
    // prefix added to option
    prefixKey: '',
    suffix: '',
    // suffix added to option
    suffixKey: '',
    optionClassNames: '',
    didInsertElement(element) {
      this._super(...arguments);
      this.setup();
      if (this.hasColors && this.optionColorKey) {
        const optionColorKey = this.optionColorKey || '';
        const option = this.option || {};
        const color = R_.dotPath(optionColorKey)(option);
        if (color) {
          this.element.style.color = color;
        }
      }
    },
    _icon: Ember.computed('icon', 'optionIconKey', function () {
      if (this.icon) {
        return this.icon;
      }
      const optionIconKey = this.optionIconKey || '';
      if (!optionIconKey) {
        return '';
      }
      const option = this.option;
      const icon = R_.dotPath(optionIconKey)(option);
      if (icon) {
        return icon;
      }
      return '';
    }),
    prefixLabel: Ember.computed('prefix', 'prefixKey', function () {
      const prefix = this.get('prefix');
      const prefixKey = this.get('prefixKey');
      if (prefixKey) {
        const prefixValue = this.option.get(prefixKey);
        if (prefixValue) {
          return `(${prefixValue})`;
        }
        return '';
      }
      return prefix;
    }),
    suffixLabel: Ember.computed('suffix', 'suffixKey', function () {
      const suffix = this.get('suffix');
      const suffixKey = this.get('suffixKey');
      if (suffixKey) {
        const suffixValue = this.option.get(suffixKey);
        if (suffixValue) {
          return `(${suffixValue})`;
        }
        return '';
      }
      return suffix;
    }),
    isSelectedClass: Ember.computed('isSelected', function () {
      if (this.isCheckbox) {
        return false;
      }
      return this.isSelected;
    }),
    allowTranslate: Ember.computed('option._data.translate', function () {
      const optionTranslate = this.get('option._data.translate');
      if (optionTranslate === '' || optionTranslate == null) {
        return this.translate;
      }
      return optionTranslate;
    }),
    // determine whether the option component should be selected or not
    setup() {
      var self = this;
      if (!self.get('isTag') && self.get('rememberSelected') === true) {
        // only setup selected if isTag=false
        let isSelected = false;
        const optionValue = self.get('optionValue');
        const selected = self.get('selected');
        if (R.is(Array, selected)) {
          if (self.get('selected').indexOf(optionValue) > -1) {
            isSelected = true;
          }
        } else {
          if (selected === self.get('optionValue') && self.get('optionValue') != null) {
            isSelected = true;
          }
        }
        if (!this.multiSelect && this.selectedComponents.length >= 1) {
          isSelected = false;
        }
        if (isSelected) {
          self.set('isSelected', isSelected);
          self.get('selectedComponents').addObject(self);
        }
        self.get('optionsComponents').addObject(self);
      }
    },
    // handle click action
    click() {
      if (!this.disabled) {
        if (this.onToggle) {
          const option = this.option;
          this.onToggle(option, this);
        }
      }
    },
    optionLabel: Ember.computed('option', 'option.synced', function () {
      return this.get('option.' + this.optionLabelKey);
    }),
    optionValue: Ember.computed('option', 'option.synced', function () {
      return this.get('option.' + this.optionValueKey);
    }),
    getValueKey() {
      return this.key;
    },
    actions: {
      edit(e) {
        e.stopPropagation();
        if (!this.disabled) {
          const option = this.option;
          this.onEdit(option);
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "didInsertElement", [_dec], Object.getOwnPropertyDescriptor(_obj, "didInsertElement"), _obj), _obj));
});