define("client/pods/components/files-manager/finder/image/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ember-concurrency", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, _emberConcurrency, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DESKTOP = 'desktop';
  const TABLET = 'tablet';
  const MOBILE = 'mobile';
  let FilesManagerFinderImageComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('uploads'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class FilesManagerFinderImageComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      /** @type {UploadsService} */
      _initializerDefineProperty(this, "uploadsService", _descriptor3, this);
      _initializerDefineProperty(this, "mobile", _descriptor4, this);
      _initializerDefineProperty(this, "tablet", _descriptor5, this);
      _initializerDefineProperty(this, "desktop", _descriptor6, this);
      if (this.args.model) {
        this.setupModel();
      }
    }
    get hasSelected() {
      const isMobileSelected = this.isSelected(this.mobile);
      const isTabletSelected = this.isSelected(this.tablet);
      const isDesktopSelected = this.isSelected(this.desktop);
      return isMobileSelected || isTabletSelected || isDesktopSelected;
    }
    get hasAllSelected() {
      const isMobileSelected = this.isSelected(this.mobile);
      const isTabletSelected = this.isSelected(this.tablet);
      const isDesktopSelected = this.isSelected(this.desktop);
      return isMobileSelected && isTabletSelected && isDesktopSelected;
    }
    get isBackgroundType() {
      return R.pipe(R.pathOr(false, ['args', 'previousImage', 'imgSet', 'imgType']), R.equals('background-img'))(this);
    }
    isSelected(item) {
      return R.pipe(R.propOr(false, 'isSelected'), R.equals(true))(item);
    }

    /**
     * Toggle all devices isSelected to a specific boolean
     * @param {boolean} isSelected
     */
    toggleIsSelectedOnAll(isSelected = true) {
      const items = [this.desktop, this.tablet, this.mobile];
      R.pipe(R.forEach(item => {
        item.set('isSelected', isSelected);
      }))(items);
    }
    getSelectedName() {
      const isMobileSelected = this.isSelected(this.mobile);
      const isTabletSelected = this.isSelected(this.tablet);
      const isDesktopSelected = this.isSelected(this.desktop);
      if (isDesktopSelected) {
        return DESKTOP;
      }
      if (isTabletSelected) {
        return TABLET;
      }
      if (isMobileSelected) {
        return MOBILE;
      }
    }
    resetAllImages(dirty) {
      this.mobile = this.crud.setupDirty(dirty);
      this.tablet = this.crud.setupDirty(dirty);
      this.desktop = this.crud.setupDirty(dirty);
      this.toggleIsSelectedOnAll(true);
    }
    setCurrentSelected(model) {
      const imgSet = {
        mobile: this.mobile,
        tablet: this.tablet,
        desktop: this.desktop,
        desktopUrl: this.desktop.url,
        desktopUuid: this.desktop.getData('uuid'),
        tabletUrl: this.tablet.url,
        tabletUuid: this.tablet.getData('uuid'),
        mobileUrl: this.mobile.url,
        mobileUuid: this.mobile.getData('uuid')
      };
      model.set('imgSet', imgSet);
    }
    setupModel(model) {
      model = model || this.args.model;
      if (!model) {
        this.mobile = null;
        this.tablet = null;
        this.desktop = null;
        return;
      }
      const dirty = {
        adapterName: 'uploads',
        model
      };
      if (this.hasSelected && !this.hasAllSelected) {
        const selectedName = this.getSelectedName();
        this[selectedName] = this.crud.setupDirty(dirty);
        this[selectedName].set('isSelected', true);
      } else {
        this.resetAllImages(dirty);
      }
      if (model.isGif || this.isBackgroundType) {
        this.resetAllImages(dirty);
      }
      this.setCurrentSelected(model);
    }
    onSelectImg(model) {
      if (RA.isNotNilOrEmpty(model)) {
        if (model.isGif || this.isBackgroundType) {
          return;
        }
        this.toggleIsSelectedOnAll(false);
        model.set('isSelected', true);
      }
    }
    *fetchPreviousImagesTask() {
      const previousImage = this?.args?.previousImage;
      if (!previousImage) {
        return null;
      }
      const uuids = [R.pathOr('', ['imgSet', 'desktopUuid'])(previousImage), R.pathOr('', ['imgSet', 'tabletUuid'])(previousImage), R.pathOr('', ['imgSet', 'mobileUuid'])(previousImage)];
      const hasAllUuids = R.pipe(R.map(RA.isNilOrEmpty), R.all(R.equals(false)))(uuids);
      if (!hasAllUuids) {
        return null;
      }
      const devices = [DESKTOP, TABLET, MOBILE];
      const results = yield (0, _emberConcurrency.all)(R.map(uuid => this.uploadsService.fetchByUuid.perform(uuid))(uuids));
      R.pipe(R.map(result => {
        if (RA.isNilOrEmpty(result)) {
          return this.crud.setupNewRecord({
            adapterName: 'uploads',
            data: {}
          });
        }
        return result;
      }), R.flatten, R.forEachObjIndexed((uploadsModel, index) => {
        const device = devices[index];
        this[device] = this.crud.setupDirty({
          adapterName: 'uploads',
          model: uploadsModel
        });
      }))(results);
      this.toggleIsSelectedOnAll();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uploadsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mobile", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tablet", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "desktop", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setCurrentSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setCurrentSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupModel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setupModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectImg", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectImg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPreviousImagesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPreviousImagesTask"), _class.prototype), _class);
});