define("client/pods/grapesjs/service", ["exports", "handlebars/dist/handlebars", "client/pods/grapesjs/plugins/banner", "client/pods/grapesjs/plugins/blocks", "client/pods/grapesjs/plugins/button", "client/pods/grapesjs/categories", "client/pods/grapesjs/plugins/cell", "client/pods/grapesjs/plugins/full-width-image", "client/pods/grapesjs/plugins/image", "client/pods/grapesjs/plugins/non-editable", "client/pods/grapesjs/plugins/panels", "client/pods/grapesjs/plugins/product", "client/pods/grapesjs/plugins/products", "client/pods/grapesjs/plugins/row", "client/pods/grapesjs/plugins/slider", "client/pods/grapesjs/plugins/strip", "client/pods/grapesjs/plugins/video", "client/pods/grapesjs/plugins/grid", "client/pods/grapesjs/plugins/double-row", "client/pods/grapesjs/plugins/form", "client/pods/grapesjs/plugins/text", "client/pods/grapesjs/plugins/tabs", "client/pods/grapesjs/plugins/background-fixed", "client/pods/grapesjs/plugins/blogs", "client/pods/grapesjs/plugins/animation-trait", "client/pods/grapesjs/plugins/traits/link", "client/pods/grapesjs/plugins/tabs-with-header", "client/pods/grapesjs/plugins/picture", "client/pods/grapesjs/plugins/picture-layer-container", "client/pods/grapesjs/plugins/ckeditor", "client/pods/grapesjs/plugins/slider-native", "client/pods/grapesjs/plugins/sessionStorage", "client/pods/grapesjs/plugins/scratch-card", "client/pods/grapesjs/plugins/stories", "ramda", "ramda-adjunct", "client/config/environment"], function (_exports, _handlebars, _banner, _blocks, _button, _categories, _cell, _fullWidthImage, _image, _nonEditable, _panels, _product, _products, _row, _slider, _strip, _video, _grid, _doubleRow, _form, _text, _tabs, _backgroundFixed, _blogs, _animationTrait, _link, _tabsWithHeader, _picture, _pictureLayerContainer, _ckeditor, _sliderNative, _sessionStorage, _scratchCard, _stories, R, RA, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global grapesjs, store, CKEDITOR */

  // import customCodePlugin from 'grapesjs-custom-code'

  const isCKEditorLoaded = R.pipe(R.prop('CKEDITOR'), RA.isNotNilOrEmpty)(window);
  if (isCKEditorLoaded) {
    window.CKEDITOR = R.pipe(R.prop('CKEDITOR'), R.set(R.lensPath(['dtd', '$editable', 'span']), 1), R.set(R.lensPath(['dtd', '$editable', 'a']), 1))(window);
  }
  const genBtnStyles = website => {
    const cssBtnThemes = R.pipe(R.pathOr([], ['colors']), R.filter(R.pipe(R.propOr('', 'key'), R.test(/^themeButton/))), R.groupBy(theme => {
      const {
        key = ''
      } = theme;
      return key.includes('3') ? 'button3' : key.includes('2') ? 'button2' : 'button';
    }), R.map(R.map(R.values)), R.map(R.map(R.over(R.lensIndex(0), R.replace(/\d/, '')))), R.map(R.fromPairs), R.mapObjIndexed(btnStyles => {
      if (!btnStyles.themeButtonBorderRadius) {
        btnStyles.themeButtonBorderRadius = '20px';
      }
      return btnStyles;
    }))(website);
    const cssTemplate = _handlebars.default.compile(`
  {{#each this.cssBtnThemes as |btnTheme index|}}
   .{{index}} {
      border: 1px solid {{btnTheme.themeButtonBorderColor}} !important;
      background-color: {{btnTheme.themeButtonColor}} !important;
      color: {{btnTheme.themeButtonTextColor}} !important;
      border-radius: {{btnTheme.themeButtonBorderRadius}} !important;
  }

   .{{index}}:hover {
      background-color: {{btnTheme.themeButtonHoverColor}} !important;
  }
{{/each}}
      `);
    return cssTemplate({
      cssBtnThemes
    });
  };
  const create = (id = 'gjs', storagePrefix, intl, opts = {}, btnStyles = '') => {
    const categories = (0, _categories.default)(intl);
    const plugins = R.pipe(R.propOr([], 'plugins'), R.concat([_sessionStorage.default, _ckeditor.default, _link.default, _animationTrait.default, _row.default, _cell.default, 'gjs-preset-webpage', _panels.default, _text.default, _blocks.default, _fullWidthImage.default, _video.default, _slider.default, _button.default, _nonEditable.default, _image.default, _product.default, _products.default, _blogs.default, _pictureLayerContainer.default, _banner.default, _strip.default, _grid.default, _doubleRow.default, _tabs.default, _tabsWithHeader.default, _picture.default, _sliderNative.default, _scratchCard.default, _backgroundFixed.default, _stories.default, _form.default, 'grapesjs-custom-code']))(opts);
    opts = R.dissoc('plugins', opts);

    // language=CSS
    let baseCss = `
      * {
          box-sizing: border-box;
      }

      html, body, [data-gjs-type=wrapper] {
          min-height: 100vh;
          background-color: var(--themeBackgroundPrimaryColor);
      }

      body {
          margin: 0;
          height: 100%;
      }

      [data-gjs-type=wrapper] {
          overflow: auto;
          overflow-x: hidden;
      }

      .grapesjs-custom-code {
          min-height: 75px;
      }

      * ::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.1)
      }

      * ::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2)
      }

      * ::-webkit-scrollbar {
          width: 10px
      }

      /*********************
      ****** Flex Box ******
      **********************/
      .flex-container-column {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: vertical;
          -moz-box-direction: normal;
          -moz-box-orient: vertical;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
      }

      .flex-container-row {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -moz-box-direction: normal;
          -moz-box-orient: horizontal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
      }

      .flex-container--no-wrap {
          -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: none;
          flex-wrap: nowrap;
      }

      .flex-container--wrap-reverse {
          -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
      }

      /* The justify-content property is used to align the flex items */
      .flex-container--justify-center {
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
      }

      .flex-container--justify-start {
          -webkit-box-pack: start;
          -moz-box-pack: start;
          -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
      }

      .flex-container--justify-end {
          -webkit-box-pack: end;
          -moz-box-pack: end;
          -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;
      }

      .flex-container--justify-space-around {
          -ms-flex-pack: distribute;
          -webkit-justify-content: space-around;
          justify-content: space-around;
      }

      .flex-container--justify-space-between {
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
      }

      /* The align-items property is used to align the flex items vertically */
      .flex-container--align-items-center {
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
      }

      .flex-container--align-items-start {
          -webkit-box-align: start;
          -moz-box-align: start;
          -ms-flex-align: start;
          -webkit-align-items: flex-start;
          align-items: flex-start;
      }

      .flex-container--align-items-end {
          -webkit-box-align: end;
          -moz-box-align: end;
          -ms-flex-align: end;
          -webkit-align-items: flex-end;
          align-items: flex-end;
      }

      .flex-container--align-items-stretch {
          -webkit-box-align: stretch;
          -moz-box-align: stretch;
          -ms-flex-align: stretch;
          -webkit-align-items: stretch;
          align-items: stretch;
      }

      /* The align-content property is used to align the flex lines. used for multiple flex lines in a flex wrap container */
      .flex-container--align-content-center {
          -webkit-align-content: center;
          -ms-flex-line-pack: center;
          align-content: center;
      }

      .flex-container--align-content-start {
          -webkit-align-content: flex-start;
          -ms-flex-line-pack: start;
          align-content: flex-start;
      }

      .flex-container--align-content-end {
          -webkit-align-content: flex-end;
          -ms-flex-line-pack: end;
          align-content: flex-end;
      }

      .flex-container--align-content-space-around {
          -webkit-align-content: space-around;
          -ms-flex-line-pack: space-around;
          align-content: space-around;
      }

      .flex-container--align-content-space-between {
          -webkit-align-content: space-between;
          -ms-flex-line-pack: space-between;
          align-content: space-between;
      }

      .flex-container--align-content-space-evenly {
          -webkit-align-content: space-evenly;
          -ms-flex-line-pack: space-evenly;
          align-content: space-evenly;
      }

      /* The align-self property specifies the alignment for the selected item inside the flexible container. Note: The align-self property overrides the flexible container's align-items property. */
      .flex-item--align-self-center {
          -webkit-align-self: center;
          -ms-flex-item-align: center;
          align-self: center;
      }

      .flex-item--align-self-start {
          -webkit-align-self: flex-start;
          -ms-flex-item-align: start;
          align-self: flex-start;
      }

      .flex-item--align-self-end {
          -webkit-align-self: flex-end;
          -ms-flex-item-align: end;
          align-self: flex-end;
      }

      .flex-item--align-self-stretch {
          -webkit-align-self: stretch;
          -ms-flex-item-align: stretch;
          align-self: stretch;
      }

      .flex-row-space-between {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -moz-box-direction: normal;
          -moz-box-orient: horizontal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
      }

      .flex-row-space-around {
          display: -webkit-box;
          display: -moz-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-direction: normal;
          -webkit-box-orient: horizontal;
          -moz-box-direction: normal;
          -moz-box-orient: horizontal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -ms-flex-pack: distribute;
          -webkit-justify-content: space-around;
          justify-content: space-around;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
      }

      /*********************
      ****** Flex Box ******
      **********************/

      $
      {
          btnStyles
      }
      .full-width-image--display-none {
          display: none;
      }

      /*********************
      ****** Flex Box ******
      **********************/

      .bigordr-tab {
          cursor: pointer;
          padding: 7px 14px;
          display: inline-block;
          border-radius: 3px;
          margin-right: 10px;
      }

      .bigordr-tab:hover {
          background-color: var(--themeActionHoverColor);
          color: var(--themeActionTextColor);
      }

      .bigordr-tab:focus {
          outline: none;
      }

      .bigordr-tab.bigordr-tab-active {
          background-color: var(--themeActionColor);
          color: var(--themeActionTextColor);
      }

      .bigordr-tab.bigordr-tab-active:hover {
          background-color: var(--themeActionHoverColor);
          color: var(--themeActionTextColor);
      }

      .bigordr-tab-container {
          display: inline-block;
          padding-left: 25px;
      }

      .bigordr-tab-content {
          animation: fadeEffect 1s;
      }

      .bigordr-tab-contents {
          min-height: 100px;
          padding: 10px;
      }

      @keyframes fadeEffect {
          from {
              opacity: 0;
          }
          to {
              opacity: 1;
          }
      }

      /*********************
      ****** Flex Box ******
      **********************/
      .bigordr-blogs {
          display: flex;
      }

      .bigordr-blog {
          width: 100%;
          height: 100%;
          min-width: 5px;
          min-height: 5px;
      }

      .bigordr-products-wrapper {
          display: grid;
          grid-template-rows: auto;
          grid-template-areas: "btnLeft products btnRight";
          grid-template-columns: auto auto auto;
      }

      .bigordr-products-wrapper-controls {
          align-self: center;
          margin: 10px;
      }

      .bigordr-products {
          overflow-x: auto;
          white-space: nowrap;
          padding: 15px 0;
          display: flex;
      }

      .slider__products-card {
          display: inline-block;
          margin: 0 10px;
      }

      .checkout-form__extra-max-width {
          max-width: 1255px;
      }

      .sider-cell__view.bigordr-cell {
          min-height: 150px;
      }


      /*  Video  */

      .bigordr-video-inner-wrapper {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
      }

      .bigordr-video-inner-wrapper > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }

      .bigordr-video-inner-wrapper > video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }

      .bigordr-video-inner-wrapper > iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }

      .bigordr-tab-container__sticky-tabs {
          top: 0;
          background-color: transparent;
          display: block;
          overflow-x: auto;
          white-space: nowrap;
      }

      /* TEXT STYLE */
      .bigordr-title {
          color: rgb(54, 54, 54);
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.125;
      }

      .bigordr-subtitle {
          color: rgb(54, 54, 54);
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.125;
      }

      /* Slider */

      @media screen and (min-width: 640px) {
          /**
           * Demo Page Styles
           */
          .examplecode, .frame, .events_log {
              width: 580px;
          }

          .testscreen {
              width: 500px;
          }

          table {
              width: 580px;
              margin: 0 auto;
          }

          tr {
              padding: 0;
          }

          /**
           * Slider specific styles
           */
          .slider {
              width: 640px;
              margin: 0 auto 20px;
          }

          .simple > .frame li,
          .rewind > .frame li,
          .events > .frame li {
              width: 580px;
          }

          .variablewidth li {
              width: 280px;
              margin-right: 10px;
          }

          .multipleelements li, .multislides li, .ease li {
              width: 130px;
              margin-right: 20px;
          }
      }

      /* bigordr-picture-layer-container */
      .bigordr-picture-layer-container {
          position: relative;
          line-height: 1.5;
      }

      /*  CUSTOM SLIDER */
      .lory-slider {
          position: relative;
      }

      .lory-slider.lory-slider--margin-zero {
          margin: 0 auto;
      }

      .gjs-lory-frame {
          margin: 0 auto;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
      }

      li.gjs-lory-slide-min-width.gjs-lory-slide.js_slide.gjs-lory-slide--inside-arrows {
          width: 100%;
      }

      li.gjs-lory-slide-auto-width.gjs-lory-slide.js_slide.gjs-lory-slide--inside-arrows {
          width: auto;
      }

      .gjs-lory-slide {
          display: inline-block;
          position: relative;
          vertical-align: top;
          white-space: normal;
          min-height: 50px;
      }

      .gjs-lory-slide-min-width {
          min-width: 50px;
      }

      .gjs-lory-slide-min-width__2_slides {
          width: 50%;
          min-width: 50px;
      }

      .gjs-lory-slide-min-width__3_slides {
          width: 33.33%;
          min-width: 50px;
      }

      .gjs-lory-slide-min-width__4_slides {
          width: 25%;
          min-width: 50px;
      }

      .gjs-lory-slider-min-width {
          width: 100%;
          min-width: 50px;
      }

      .gjs-lory-slides {
          display: inline-block;
      }

      .gjs-lory-slides__smooth-scroll {
          overflow: scroll;
          scroll-behavior: smooth;
      }

      li.gjs-lory-slide.js_slide {
          background-color: rgba(0, 0, 0, 0.1);
      }

      .lory-controls-arrows {
          position: absolute;
          display: block;
          cursor: pointer;
          top: 50%;
          margin-top: -2%;
          font-size: 30px;
          padding: 5px 15px;
          background-color: #e4e4e4d2;
          border: none;
          border-radius: 100%;
      }

      .lory-controls-arrows.hidden-background {
          background-color: transparent;
      }

      .lory-controls-arrows.size-small {
          font-size: 20px;
          padding: 10px 15px;
      }

      .lory-controls-arrows.size-medium {
          font-size: 30px;
          padding: 12.5px 20px;
      }

      .lory-controls-arrows.size-large {
          font-size: 40px;
          padding: 12.5px 22.5px;
      }

      .lory-controls-arrows-left {
          left: 0;
          margin-left: 15px;
      }

      .lory-controls-arrows-right {
          right: 0;
          margin-right: 15px;
      }

      .lory-controls-arrows-color {
          color: #000
      }

      span.lory-controls-arrows--hidden {
          display: none;
      }

      span.lory-controls-arrows--always-hidden {
          display: none;
      }

      ul.lory-dots--hidden {
          display: none;
      }

      .lory-dots {
          padding: 0;
          text-align: center;
          position: relative;
          width: 100%;
          margin: 5px 0;
      }

      .lory-dots.lory-dots--inside-dots {
          bottom: 0;
          position: absolute;
      }

      .lory-dot {
          border-radius: 100%;
          display: inline-block;
          height: 10px;
          width: 10px;
          margin: 0 5px;
          cursor: pointer;
      }

      .lory-dot-color {
          background-color: #eee;
      }

      .lory-dot-color.dot-active-color {
          background-color: #41ABE5;
      }

      .lory-dot.size-small {
          height: 8px;
          width: 8px;
      }

      .lory-dot.size-medium {
          height: 10px;
          width: 10px;
      }

      .lory-dot.size-large {
          height: 15px;
          width: 15px;
      }

      .lory-dot.lory-dot--style-line {
          width: 25px;
          height: 4px;
          padding: 0;
          margin: 6px;
          border-radius: 0;
      }

      .lory-dot.lory-dot--style-line.size-small {
          height: 2px;
          margin: 5px;
      }

      .lory-dot.lory-dot--style-line.size-medium {
          height: 4px;
          margin: 6px;
      }

      .lory-dot.lory-dot--style-line.size-large {
          height: 5px;
          margin: 7px;
      }


      @media (min-width: 900px) {
          /*
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width {
            width: 880px;
          }
          */
      }

      @media (min-width: 800px) {
          /*
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width {
            width: 580px;
          }
    
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width.gjs-lory-frame--inside-arrows {
            width: 100%;
          }
          */
      }

      @media (max-width: 780px) {
          .lory-slider {
              /*width: 680px !important;*/
          }

          /*
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width {
            width: 580px;
          }
    
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width.gjs-lory-frame--inside-arrows {
            width: 100%;
          }
          */
      }

      @media (max-width: 500px) {
          li.gjs-lory-slide-min-width.gjs-lory-slide.js_slide {
              width: 100%;
          }

          .lory-slider {
              /*width: 425px !important;*/
          }

          /*
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width {
            width: 325px;
          }
    
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width.gjs-lory-frame--inside-arrows {
            width: 100%;
          }
          */
      }

      @media (max-width: 375px) {
          li.gjs-lory-slide-min-width.gjs-lory-slide.js_slide {
              width: 100%;
          }

          .lory-slider {
              /*width: 375px !important;*/
          }

          /*
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width {
            width: 275px;
          }
    
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width.gjs-lory-frame--inside-arrows {
            width: 100%;
          }
          */
      }

      @media (max-width: 320px) {
          li.gjs-lory-slide-min-width.gjs-lory-slide.js_slide {
              width: 100%;
          }

          .lory-slider {
              /*width: 320px !important;*/
              margin: 0 auto 40px !important;
          }

          /*
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width {
            width: 220px;
          }
    
          div.lory-frame.gjs-lory-frame.gjs-lory-frame-min-width.gjs-lory-frame--inside-arrows {
            width: 100%;
          }
          */
      }

      html {
          scroll-behavior: smooth;
      }
  `;
    if (!opts.isReadonly) {
      baseCss += `
    #wrapper {
      padding-top: 10px;
      padding-bottom: 500px;
    }
    `;
    }
    const ss = ', sans-serif';
    const ms = ', monospace';
    const customStyleManager = [{
      name: intl.t('gjs-Style'),
      open: true,
      buildProps: ['font-family', 'font-size', 'color', 'text-align',
      // Background Settings
      'opacity', 'background-color', 'border-radius-c', 'border', 'box-shadow', 'background'],
      properties: [{
        name: intl.t('gjs-Font Size'),
        property: 'font-size',
        type: 'number',
        units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
        default: 'medium',
        min: 0,
        fixedValues: ['medium', 'xx-small', 'x-small', 'small', 'large', 'x-large', 'xx-large', 'smaller', 'larger', 'length', 'initial', 'inherit']
      }, {
        name: intl.t('gjs-Font Family'),
        property: 'font-family',
        type: 'select',
        default: `Arial, Helvetica${ss}`,
        options: [{
          id: `Arial Black, Gadget${ss}`,
          label: 'Arial Black'
        }, {
          id: `Arial, Helvetica${ss}`,
          label: 'Arial'
        }, {
          id: `Brush Script MT${ss}`,
          label: 'Brush Script MT'
        }, {
          id: `Comic Sans MS, cursive${ss}`,
          label: 'Comic Sans MS'
        }, {
          id: `Courier New, Courier${ms}`,
          label: 'Courier New'
        }, {
          id: `Georgia, serif${ss}`,
          label: 'Georgia'
        }, {
          id: `Helvetica${ss}`,
          label: 'Helvetica'
        }, {
          id: `Impact, Charcoal${ss}`,
          label: 'Impact'
        }, {
          id: `Lucida Sans Unicode, Lucida Grande${ss}`,
          label: 'Lucida Sans Unicode'
        }, {
          id: `Noto Sans TC${ss}`,
          label: 'Noto Sans TC'
        }, {
          id: `Noto Serif TC${ss}`,
          label: 'Noto Serif TC'
        }, {
          id: `Tahoma, Geneva${ss}`,
          label: 'Tahoma'
        }, {
          id: `Times New Roman, Times, serif${ss}`,
          label: 'Times New Roman'
        }, {
          id: `Trebuchet MS, Helvetica${ss}`,
          label: 'Trebuchet MS'
        }, {
          id: `Verdana, Geneva${ss}`,
          label: 'Verdana'
        }]
      }, {
        name: intl.t('gjs-Text align'),
        property: 'text-align',
        type: 'radio',
        defaults: 'left',
        options: [{
          value: 'left',
          name: intl.t('gjs-Left'),
          className: 'fa fa-align-left'
        }, {
          value: 'center',
          name: intl.t('gjs-Center'),
          className: 'fa fa-align-center'
        }, {
          value: 'right',
          name: intl.t('gjs-Right'),
          className: 'fa fa-align-right'
        }, {
          value: 'justify',
          name: intl.t('gjs-Justify'),
          className: 'fa fa-align-justify'
        }]
      }, {
        name: intl.t('gjs-Color'),
        property: 'color',
        type: 'color'
      }, {
        name: intl.t('gjs-Background Color'),
        property: 'background-color',
        type: 'color'
      }, {
        name: intl.t('gjs-Border Radius'),
        property: 'border-radius',
        type: 'number',
        units: ['px', '%'],
        defaults: '0',
        min: 0
      }, {
        type: 'composite',
        name: intl.t('gjs-Border'),
        property: 'border',
        // info: 'SOME BORDER INFO',
        properties: [{
          name: intl.t('gjs-Border Width'),
          property: 'border-width',
          id: `border-width-${id}`,
          type: 'number',
          units: ['px', 'em', 'rem', 'vh', 'vw'],
          default: '0',
          min: 0
        }, {
          name: intl.t('gjs-Border Style'),
          property: 'border-style',
          id: `border-style-${id}`,
          type: 'select',
          default: 'solid',
          options: [{
            name: intl.t('gjs-none'),
            value: 'none'
          }, {
            name: intl.t('gjs-solid'),
            value: 'solid'
          }, {
            name: intl.t('gjs-dotted'),
            value: 'dotted'
          }, {
            name: intl.t('gjs-dashed'),
            value: 'dashed'
          }, {
            name: intl.t('gjs-double'),
            value: 'double'
          }
          // { id: 'groove' },
          // { id: 'ridge' },
          // { id: 'inset' },
          // { id: 'outset' }
          ]
        }, {
          name: intl.t('gjs-Border Color'),
          property: 'border-color',
          id: `border-color-${id}`,
          type: 'color',
          default: 'black'
        }]
      }, {
        name: intl.t('gjs-Opacity'),
        type: 'slider',
        property: 'opacity',
        defaults: 1,
        step: 0.01,
        max: 1,
        min: 0
      }, {
        name: intl.t('gjs-Box Shadow'),
        property: 'box-shadow',
        type: 'stack',
        preview: true,
        layerLabel: (l, {
          values
        }) => {
          const x = values[`box-shadow-h-${id}`];
          const y = values[`box-shadow-v-${id}`];
          const blur = values[`box-shadow-blur-${id}`];
          const spread = values[`box-shadow-spread-${id}`];
          return `${x} ${y} ${blur} ${spread}`;
        },
        properties: [{
          name: intl.t('gjs-X position'),
          property: 'box-shadow-h',
          id: `box-shadow-h-${id}`,
          type: 'number',
          units: ['px', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }, {
          name: intl.t('gjs-Y position'),
          property: 'box-shadow-v',
          id: `box-shadow-v-${id}`,
          type: 'number',
          units: ['px', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }, {
          name: intl.t('gjs-Blur'),
          property: 'box-shadow-blur',
          id: `box-shadow-blur-${id}`,
          type: 'number',
          units: ['px', 'em', 'rem', 'vh', 'vw'],
          default: '5px',
          min: 0
        }, {
          name: intl.t('gjs-Spread'),
          property: 'box-shadow-spread',
          id: `box-shadow-spread-${id}`,
          type: 'number',
          units: ['px', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }, {
          name: intl.t('gjs-box-shadow-color'),
          property: 'box-shadow-color',
          id: `box-shadow-color-${id}`,
          type: 'color',
          default: 'black'
        }, {
          name: intl.t('gjs-shadow-type'),
          property: 'box-shadow-type',
          id: `box-shadow-type-${id}`,
          type: 'select',
          default: '',
          options: [{
            id: '',
            label: intl.t('gjs-outside')
          }, {
            id: 'inset',
            label: intl.t('gjs-inside')
          }]
        }]
      }, {
        name: intl.t('gjs-Background'),
        property: 'background',
        type: 'stack',
        preview: true,
        detached: true,
        layerLabel: (l, args = {}) => {
          const {
            values
          } = args;
          const repeat = values[`background-repeat-${id}`];
          const pos = values[`background-position-${id}`];
          const att = values[`background-attachment-${id}`];
          const size = values[`background-size-${id}`];
          const repeatLabel = intl.t(`gjs-${repeat}`);
          const repeatPos = intl.t(`gjs-${pos}`);
          const repeatAtt = intl.t(`gjs-${att}`);
          const repeatSize = intl.t(`gjs-${size}`);
          return `${repeatLabel} | ${repeatPos} | ${repeatAtt} | ${repeatSize}`;
        },
        properties: [{
          name: intl.t('gjs-Image'),
          property: 'background-image',
          id: `background-image-${id}`,
          type: 'file',
          functionName: 'url',
          full: true
        }, {
          name: intl.t('gjs-Repeat'),
          property: 'background-repeat',
          id: `background-repeat-${id}`,
          type: 'select',
          default: 'no-repeat',
          options: [{
            id: 'repeat',
            name: intl.t('gjs-repeat')
          }, {
            id: 'repeat-x',
            name: intl.t('gjs-repeat-x')
          }, {
            id: 'repeat-y',
            name: intl.t('gjs-repeat-y')
          }, {
            id: 'no-repeat',
            name: intl.t('gjs-no-repeat')
          }]
        }, {
          name: intl.t('gjs-Position'),
          property: 'background-position',
          id: `background-position-${id}`,
          type: 'select',
          default: 'center center',
          options: [{
            id: 'left top',
            name: intl.t('gjs-left top')
          }, {
            id: 'left center',
            name: intl.t('gjs-left center')
          }, {
            id: 'left bottom',
            name: intl.t('gjs-left bottom')
          }, {
            id: 'right top',
            name: intl.t('gjs-right top')
          }, {
            id: 'right center',
            name: intl.t('gjs-right center')
          }, {
            id: 'right bottom',
            name: intl.t('gjs-right bottom')
          }, {
            id: 'center top',
            name: intl.t('gjs-center top')
          }, {
            id: 'center center',
            name: intl.t('gjs-center center')
          }, {
            id: 'center bottom',
            name: intl.t('gjs-center bottom')
          }]
        }, {
          name: intl.t('gjs-Attachment'),
          property: 'background-attachment',
          id: `background-attachment-${id}`,
          type: 'select',
          default: 'scroll',
          options: [{
            id: 'scroll',
            name: intl.t('gjs-scroll')
          }, {
            id: 'fixed',
            name: intl.t('gjs-fixed')
          }, {
            id: 'local',
            name: intl.t('gjs-local')
          }]
        }, {
          name: intl.t('gjs-Size'),
          property: 'background-size',
          id: `background-size-${id}`,
          type: 'select',
          default: 'auto',
          options: [{
            id: 'auto',
            name: intl.t('gjs-auto')
          }, {
            id: 'cover',
            name: intl.t('gjs-cover')
          }, {
            id: 'contain',
            name: intl.t('gjs-contain')
          }]
        }]
      }]
    }, {
      name: intl.t('gjs-Dimension') || 'Dimension',
      open: false,
      buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
      properties: [{
        name: intl.t('gjs-Width') || 'Width',
        property: 'width',
        id: `width-${id}`,
        type: 'number',
        units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
        default: 'auto',
        fixedValues: ['initial', 'inherit', 'auto', 'fit-content'],
        min: 0
      }, {
        name: intl.t('gjs-Height') || 'Height',
        property: 'height',
        id: `height-${id}`,
        type: 'number',
        units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
        default: 'auto',
        fixedValues: ['initial', 'inherit', 'auto', 'fit-content'],
        min: 0
      }, {
        name: intl.t('gjs-Max-width') || 'Max width',
        property: 'max-width',
        id: `max-width-${id}`,
        type: 'number',
        units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
        default: 'auto',
        fixedValues: ['initial', 'inherit', 'auto'],
        min: 0
      }, {
        name: intl.t('gjs-Min-height') || 'Min height',
        property: 'min-height',
        id: `min-height-${id}`,
        type: 'number',
        units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
        default: 'auto',
        fixedValues: ['initial', 'inherit', 'auto'],
        min: 0
      }, {
        name: intl.t('gjs-Margin') || 'Margin',
        property: 'margin',
        type: 'composite',
        properties: [{
          name: intl.t('gjs-Top') || 'Top',
          property: 'margin-top',
          id: `margin-top-${id}`,
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }, {
          name: intl.t('gjs-Right') || 'Right',
          property: 'margin-right',
          id: `margin-right-${id}`,
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }, {
          name: intl.t('gjs-Bottom') || 'Bottom',
          property: 'margin-bottom',
          id: `margin-bottom-${id}`,
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }, {
          name: intl.t('gjs-Left') || 'Left',
          property: 'margin-left',
          id: `margin-left-${id}`,
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0'
        }]
      }, {
        name: intl.t('gjs-Padding') || 'Padding',
        property: 'padding',
        type: 'composite',
        properties: [{
          name: intl.t('gjs-Top') || 'Top',
          property: 'padding-top',
          id: `padding-top-${id}`,
          fixedValues: ['initial', 'inherit', 'auto'],
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0',
          min: 0
        }, {
          name: intl.t('gjs-Right') || 'Right',
          property: 'padding-right',
          id: `padding-right-${id}`,
          fixedValues: ['initial', 'inherit', 'auto'],
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0',
          min: 0
        }, {
          name: intl.t('gjs-Bottom') || 'Bottom',
          property: 'padding-bottom',
          id: `padding-bottom-${id}`,
          fixedValues: ['initial', 'inherit', 'auto'],
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0',
          min: 0
        }, {
          name: intl.t('gjs-Left') || 'Left',
          property: 'padding-left',
          id: `padding-left-${id}`,
          fixedValues: ['initial', 'inherit', 'auto'],
          type: 'number',
          units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
          default: '0',
          min: 0
        }]
      }]
    }];
    // Editor config https://github.com/artf/grapesjs/blob/dev/src/editor/config/config.js
    const defaults = {
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: `#${id}`,
      allowScripts: 1,
      // dont allow scripts
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: false,
      // Size of the editor
      height: '100%',
      width: 'auto',
      // On creation of a new Component (via object), if the 'style' attribute is not
      // empty, all those roles will be moved in its new class
      forceClass: true,
      // Type of logs to print with the logger (by default is used the devtool console).
      // Available by default: debug, info, warning, error
      // You can use `false` to disable all of them or `true` to print all of them
      log: ['error'],
      // ['warning', 'error'],

      // By default Grapes injects base CSS into the canvas. For example, it sets body margin to 0
      // and sets a default background color of white. This CSS is desired in most cases.
      // use this property if you wish to overwrite the base CSS to your own CSS. This is most
      // useful if for example your template is not based off a document with 0 as body margin.
      baseCss,
      canvasCss: 'body {height: auto;}',
      canvas: {
        styles: [`${_environment.default.APP.storageUrl}/api/proxy/file/vendor/css`, `${_environment.default.APP.storageUrl}/api/proxy/file/shop/css`, 'https://fonts.googleapis.com/css?family=Noto+Sans+TC|Noto+Serif+TC'],
        scripts: ['https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/js/all.min.js', 'https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js']
      },
      // Disable the storage manager for the moment
      storageManager: {
        id: '',
        type: 'indexedDB',
        // Type of the storage
        autosave: true,
        // Store data automatically
        autoload: false,
        // Autoload stored data on init
        stepsBeforeSave: 1,
        // If autosave enabled, indicates how many changes are necessary before store method is
        options: {
          indexedDB: {
            key: storagePrefix
          }
        }
        // triggered
      },
      // Avoid any default panel
      // selectorManager: {
      //   appendTo: `#style-container-${id}`
      // },
      styleManager: {
        appendTo: `#style-container-${id}`,
        sectors: []
      },
      traitManager: {
        appendTo: `#trait-container-${id}`
      },
      blockManager: {
        appendTo: `#blocks-container-${id}`
      },
      layerManager: {
        appendTo: `#layer-container-${id}`,
        hidable: false,
        custom: true
      },
      plugins,
      pluginsOpts: {
        'bigordr-slider-native': {
          categories,
          intl,
          ...opts
        },
        'ckeditor-plugin': {
          categories,
          intl,
          ...opts
        },
        'bigordr-animation-trait': {
          categories,
          intl,
          ...opts
        },
        'bigordr-animation-text-trait': {
          categories,
          intl,
          ...opts
        },
        'bigordr-animation-iteration-trait': {
          categories,
          intl,
          ...opts
        },
        'bigordr-link-trait': {
          categories,
          intl,
          ...opts
        },
        tabs: {
          categories,
          intl,
          ...opts
        },
        'bigordr-tabs-with-header': {
          categories,
          intl,
          ...opts
        },
        'bigordr-background-fixed': {
          categories,
          intl,
          ...opts
        },
        'bigordr-blogs': {
          categories,
          intl,
          ...opts
        },
        'double-row': {
          categories,
          intl,
          ...opts
        },
        'bigordr-form': {
          categories,
          intl,
          ...opts
        },
        text: {
          categories,
          intl,
          ...opts
        },
        row: {
          categories,
          intl,
          ...opts
        },
        cell: {
          categories,
          intl,
          ...opts
        },
        'full-width-image': {
          categories,
          intl,
          ...opts
        },
        'bigordr-grid': {
          categories,
          intl,
          ...opts
        },
        'bigordr-grid-images': {
          categories,
          intl,
          ...opts
        },
        'bigordr-blocks': {
          categories,
          intl,
          ...opts
        },
        'bigordr-banner': {
          categories,
          intl,
          ...opts
        },
        'bigordr-strip': {
          categories,
          intl,
          ...opts
        },
        'bigordr-panels': {
          categories,
          intl,
          ...opts
        },
        'bigordr-button': {
          categories,
          intl,
          ...opts
        },
        'bigordr-slider': {
          categories,
          intl,
          ...opts
        },
        'bigordr-video': {
          categories,
          intl,
          ...opts
        },
        'bigordr-product': {
          categories,
          intl,
          ...opts
        },
        'bigordr-products': {
          categories,
          intl,
          ...opts
        },
        'bigordr-video-full-width': {
          categories,
          intl,
          ...opts
        },
        'bigordr-picture': {
          categories,
          intl,
          ...opts
        },
        'bigordr-picture-layer-container': {
          categories,
          intl,
          ...opts
        },
        'gjs-preset-webpage': {
          editorId: id,
          customStyleManager
        },
        'grapesjs-custom-code': {
          blockCustomCode: {
            category: 'complex',
            media: `<div class='gjs-custom-code'><i class="fas fa-code"></i></div><div class="block-label">${intl.t('html-custom-code')}</div>`,
            attributes: {
              title: intl.t('html-custom-code')
            }
          },
          propsCustomCode: {
            attributes: {
              title: intl.t('html-custom-code'),
              class: 'grapesjs-custom-code'
            }
          }
        },
        'bigordr-tabs': {
          categories,
          intl,
          ...opts
        },
        'bigordr-scratch-card': {
          categories,
          intl,
          ...opts
        },
        'bigordr-stories': {
          categories,
          intl,
          ...opts
        }
      }
    };
    opts = R.mergeDeepRight(defaults, opts);
    clearPanels(id);
    const editor = grapesjs.init(opts);
    return editor;
  };
  const clearPanels = id => {
    const panelDevices = document.getElementsByClassName(`panel__devices-${id}`)[0];
    if (panelDevices) {
      while (panelDevices.firstChild) {
        panelDevices.removeChild(panelDevices.firstChild);
      }
    }
    const panelBasic = document.getElementsByClassName(`panel__basic-actions-${id}`)[0];
    if (panelBasic) {
      while (panelBasic.firstChild) {
        panelBasic.removeChild(panelBasic.firstChild);
      }
    }
  };
  var _default = _exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    initialCanvasSize: 600,
    didDestroyElement() {
      this._super(...arguments);
      window.removeEventListener('resize', this.resetHeight);
    },
    create(editorId, storagePrefix, pageDataString = '{}', options, model, website) {
      const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
          const later = () => {
            clearTimeout(timeout);
            func(...args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      };
      const storageService = this.storage;
      const activeEditors = storageService.get('activeEditors') || [];
      activeEditors.push(storagePrefix);
      storageService.set('activeEditors', activeEditors);
      let pageData = {};
      try {
        if (R.type(pageDataString) === 'String') {
          pageData = JSON.parse(pageDataString);
        } else {
          pageData = pageDataString;
        }
      } catch (e) {
        pageData = {};
      }
      const intl = this.intl;
      try {
        // const canvasHeight = options.canvasHeight
        options.model = model;
        options.editorId = editorId;
        options.intl = intl;
        const btnStyles = genBtnStyles(website);
        const editor = create(editorId, storagePrefix, intl, options, btnStyles);
        // Re-Bind delete and backspace to handle bug on GJS
        editor.Keymaps.add('delete', 'delete', 'core:component-delete');
        editor.Keymaps.add('backspace', 'backspace', 'core:component-delete');
        const {
          isReadonly
        } = options;
        const timeoutTime = 1000;
        let components = R.pipe(R.propOr([], 'components'), R.filter(component => {
          if (component.tagName === '' && component.type === 'textnode') {
            return false;
          }
          return true;
        }))(pageData);
        if (RA.isNilOrEmpty(components)) {
          components = pageData.html;
        }
        const styles = pageData.styles || pageData.css;
        const rHeight = debounce(() => {
          this.resetHeight(editor, {
            isReadonly,
            canvasHeight: options.canvasHeight
          });
        }, timeoutTime);
        if (isReadonly) {
          editor.setComponents('<div></div>');
          editor.DomComponents.getWrapper().onAll(comp => comp.set({
            badgable: false,
            copyable: false,
            draggable: false,
            droppable: false,
            editable: false,
            highlightable: false,
            hoverable: false,
            layerable: false,
            propagate: ['badgable', 'copyable', 'draggable', 'droppable', 'editable', 'highlightable', 'hoverable', 'layerable', 'removable', 'resizable', 'selectable', 'stylable'],
            removable: false,
            resizable: false,
            selectable: false,
            stylable: false
          }));
          editor.on('component:update', rHeight);
          editor.on('component:add', rHeight);
          window.addEventListener('resize', rHeight);
        } else {
          editor.on('component:deselected', (component, ...args) => {
            try {
              editor.refresh({
                tools: true
              });
              const editorElement = editor.getEl();
              if (!component?.getAttributes) {
                return;
              }
              const attributes = component.getAttributes() || {};
              const elementId = attributes?.id;
              if (!elementId) {
                return;
              }
              const toolbar = editorElement.querySelector(`.gjs-link-tooltip-${elementId}`);
              toolbar.remove();
            } catch (e) {
              // do nothing
            }
          });
          editor.on('component:select', (component, ...args) => {
            editor.refresh({
              tools: true
            });
            const selected = editor.getSelected();
            if (!selected?.getAttributes) {
              return;
            }
            const toolbar = selected.get('toolbar') || [];
            const alreadyAdded = R.find(R.pathEq(['attributes', 'class'], 'fa-solid fa-link'))(toolbar);
            if (!alreadyAdded) {
              toolbar.unshift({
                attributes: {
                  class: 'fa-solid fa-link'
                },
                command() {
                  options.openLinksModal(editor);
                }
              });
              selected.set('toolbar', toolbar);
            }
            const attributes = selected.getAttributes() || {};
            const elementId = attributes?.id;
            if (!elementId) {
              return;
            }
            let linkUrl = R.pathOr('', ['data-href'])(attributes);
            if (!linkUrl) {
              return;
            }
            // gjs does something with the toolbar, need to wait till its ready?
            setTimeout(() => {
              const linkType = R.pathOr('url', ['data-href-link-type'])(attributes);
              if (linkType !== 'url') {
                const linkTitle = R.pathOr('', ['data-href-title'])(attributes);
                linkUrl = linkTitle;
              }
              const editorElement = editor.getEl();
              const toolbar = editorElement.querySelector('.gjs-toolbar');
              const linkToolTipContainer = document.createElement('div');
              linkToolTipContainer.classList.add('gjs-link-tooltip', `gjs-link-tooltip-${elementId}`);
              linkToolTipContainer.innerHTML = `<div class='u-truncate'>${intl.t('link')}: ${linkUrl}</div>`;
              toolbar.appendChild(linkToolTipContainer);
            }, 250);
          });
        }
        // Component is mounted to an element and rendered in canvas
        editor.on('component:mount', rHeight);

        // Set body id to previous id if any
        const vDomHtml = document.createElement('html');
        vDomHtml.innerHTML = pageData.html;
        const previousBodyId = vDomHtml.querySelector('body').getAttribute('id');
        if (previousBodyId) {
          const editorWrapper = editor.getWrapper();
          editorWrapper.setId(previousBodyId);
        }
        editor.setComponents(components);

        // Uploadcare Adaptive Delivery
        // This is our custom script (avoid using arrow functions)
        const script = function () {
          window.Blinkloader.optimize({
            pubkey: '95796c658d4dbb1d6fc3',
            fadeIn: false,
            lazyload: true,
            smartCompression: true,
            responsive: true,
            retina: false,
            webp: true
          });
        };

        // Define a new custom component
        editor.Components.addType('blink-loader-js', {
          model: {
            defaults: {
              script
            }
          }
        });
        // editor.addComponents({ type: 'blink-loader-js' })
        // TODO: THIS CLEANER STYLE SHOULD BE REMOVE ONCE ALL THE WEBSITES ARE MODIFY
        let cleanStyles = styles;
        if (R.is(Array, styles)) {
          cleanStyles = R.map(R.over(R.lensPath(['selectorsAdd']), (s = '') => R.replace('#wrapper > ', '')(s)))(styles);

          // REMOVE ROOT STYLE IF ANY
          cleanStyles = R.reject(R.propEq('selectorsAdd', ':root'))(styles);

          // REMOVE gjs-dashed copies
          cleanStyles = R.reject(R.pathEq(['selectors', 0], 'gjs-dashed'))(cleanStyles);

          // Omit old embedded gjs component styles
          const oldEmbeddedCssClasses = ['bigordr-product', 'bigordr-products-wrapper'];
          cleanStyles = R.reduce((acc, style) => {
            const selectors = R.pathOr([], ['selectors'])(style);
            if (selectors.some(selector => oldEmbeddedCssClasses.includes(selector))) {
              return acc;
            }
            return acc.concat(style);
          }, [])(cleanStyles);

          // cleanStyles = R.concat(cleanStyles, lastGjsDashedStyle)

          // @NOTE: this cleans up old templates where button has static style in template
          // cleanStyles = R.pipe(
          //   R.map(item => {
          //     const isButton = R.pipe(
          //       R.propOr([], 'selectors'),
          //       R.find(R.propEq('name', 'button'))
          //     )(item)

          //     if (!isButton) {
          //       return item
          //     }

          //     if (item.style) {
          //       return R.omit(['style'])(item)
          //     }

          //     return item
          //   })
          // )(cleanStyles)
        }
        editor.setStyle(cleanStyles);
        editor.getStyle().add(this.genThemeFromWebsiteModel(website));
        return editor;
      } catch (e) {
        console.error('==> Error Initializing GrapesJs <== \n%O\n==> Error Initializing GrapesJs <== ', e);
      }
    },
    setTemplate(editor, {
      pageData,
      website
    }) {
      // Remove current template
      this.clearPage(editor, website);

      // Set new template with styles
      editor.setComponents(pageData?.components);
      editor.setStyle(pageData?.styles);

      // Save temp[late to indexedDB
      const StorageManager = editor.StorageManager;
      const currentStorage = StorageManager.getCurrent();
      const storage = StorageManager.get(currentStorage);
      storage.store(pageData).then(() => {
        return editor.load();
      }).catch(console.error);
    },
    resetHeight(editor, options = {}) {
      const {
        addComponent = true,
        isReadonly
      } = options;
      const canvasHeight = options.canvasHeight;
      if (editor.Canvas) {
        const canvasDocument = editor.Canvas.getDocument();
        if (canvasDocument) {
          const canvasBody = canvasDocument.body;
          const canvasHtml = canvasDocument.documentElement;
          // if (!canvasHeight) {
          const newCanvasHeight = Math.max(canvasBody.clientHeight, canvasBody.scrollHeight, canvasBody.offsetHeight, canvasHtml.clientHeight, canvasHtml.scrollHeight, canvasHtml.offsetHeight);

          // }

          // const h = canvasBody.scrollHeight //+ 300
          const h = editor.Canvas.getDocument().querySelector('[data-gjs-type=\'wrapper\']').clientHeight + 300;

          // const isInitial = editor.getEl().parentElement.style.height === '300px' || editor.getEl().parentElement.style.height === ''

          if (isReadonly) {
            this.set('initialCanvasSize', h);
            editor.getEl().parentElement.style.height = `${h}px`;
          } else if (addComponent) {
            this.set('initialCanvasSize', h);
            editor.addStyle({
              selectors: 'gjs-dashed',
              style: {
                height: `${h}px`
              }
            });
          }
        }
      }
    },
    getPlugins() {
      return {
        FullWidthImagePlugin: _fullWidthImage.default,
        ImagePlugin: _image.default,
        NonEditablePlugin: _nonEditable.default,
        ProductPlugin: _product.default
      };
    },
    getPluginsAsArray() {
      const obj = this.getPlugins();
      return R.values(obj);
    },
    async getPageDataStringFromStorage(editor, storagePrefix) {
      editor.store();
      const pageData = await this.getPageDataFromStorage(editor, storagePrefix);
      const pageDataString = JSON.stringify(pageData);
      const html = editor.getHtml();
      return {
        css: R.propOr('', 'css', pageData),
        html: html || R.propOr('', 'html', pageData),
        pageDataString
      };
    },
    async getPageDataFromStorage(editor, storagePrefix) {
      editor.store();
      const data = await idbKeyval.get(storagePrefix);
      return JSON.parse(data);
    },
    async setPageDataToStorage(storagePrefix, pageData) {
      await idbKeyval.set(storagePrefix, JSON.stringify(pageData));
      return pageData;
    },
    removeFromStorage(storagePrefix) {
      // using global store property as grapesjs doesnt store these using storage service
      if (storagePrefix) {
        idbKeyval.del(storagePrefix);
      }
    },
    clearPage(editor, website) {
      if (editor) {
        const domComponents = editor.DomComponents;
        const cssComposer = editor.CssComposer;
        domComponents.clear();
        cssComposer.clear();
        editor.getStyle().add(this.genThemeFromWebsiteModel(website));
      }
    },
    /**
     * Generate css var from website
     * @param {WebsiteType} website
     * @return {string}
     */
    genThemeFromWebsiteModel(website) {
      const customTheme = R.propOr({}, '_data')(website);
      const themeData = {
        themeWidthDefault: '1344px'
      };
      const activeColor = '#000000';
      const backgroundColor = '#ffffff';
      const borderColor = '#eeeeee';
      const chatColor = '#66aee4';
      const chatHoverColor = '#4390ca';
      const chatTextColor = '#ffffff';
      const linkColor = '#000000';
      const linkHoverColor = '#262626';
      const neutralColor = '#ffffff';
      const neutralHoverColor = '#eeeeee';
      const primaryColor = '#e4666f';
      const primaryContrastColor = '#ffffff';
      const primaryHoverColor = '#fa2e3c';
      const saleColor = '#ff4a68';
      const saleTextColor = '#ffffff';
      const secondaryBackgroundColor = '#eeeeee';
      const secondaryBackgroundLinkColor = '#000000';
      const secondaryBackgroundLinkHoverColor = '#262626';
      const secondaryBackgroundTextColor = '#666666';
      const secondaryColor = '#000000';
      const secondaryContrastColor = '#ffffff';
      const secondaryHoverColor = '#262626';
      const textColor = '#666666';
      const themeChatButtonColor = R.propOr(chatColor, 'themeChatButtonColor', customTheme);
      const themeChatButtonTextColor = R.propOr(chatTextColor, 'themeChatButtonTextColor', customTheme);
      const white = '#ffffff';
      if (themeChatButtonColor === white && themeChatButtonTextColor === white) {
        website.setData('themeChatButtonColor', chatColor);
      }
      themeData.themeActionColor = R.propOr(primaryColor, 'themeActionColor', customTheme);
      themeData.themeActionFinalColor = R.propOr(secondaryColor, 'themeActionFinalColor', customTheme);
      themeData.themeActionFinalHoverColor = R.propOr(secondaryHoverColor, 'themeActionFinalHoverColor', customTheme);
      themeData.themeActionFinalTextColor = R.propOr(secondaryContrastColor, 'themeActionFinalTextColor', customTheme);
      themeData.themeActionHoverColor = R.propOr(primaryHoverColor, 'themeActionHoverColor', customTheme);
      themeData.themeActionTextColor = R.propOr(primaryContrastColor, 'themeActionTextColor', customTheme);
      themeData.themeActiveColor = R.propOr(activeColor, 'themeActiveColor', customTheme);
      themeData.themeBackgroundPrimaryColor = R.propOr(backgroundColor, 'themeBackgroundPrimaryColor', customTheme);
      themeData.themeBackgroundSecondaryColor = R.propOr(backgroundColor, 'themeBackgroundSecondaryColor', customTheme);
      themeData.themeBorderColor = R.propOr(borderColor, 'themeBorderColor', customTheme);
      themeData.themeButtonBorderColor = R.propOr(borderColor, 'themeButtonBorderColor', customTheme);
      themeData.themeButtonColor = R.propOr(neutralColor, 'themeButtonColor', customTheme);
      themeData.themeButtonHoverColor = R.propOr(neutralHoverColor, 'themeButtonHoverColor', customTheme);
      themeData.themeButtonHoverTextColor = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor', customTheme);
      themeData.themeButtonHoverTextColor1 = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor1', customTheme);
      themeData.themeButtonTextColor = R.propOr(textColor, 'themeButtonTextColor', customTheme);
      themeData.themeButtonBorderColor1 = R.propOr(borderColor, 'themeButtonBorderColor1', customTheme);
      themeData.themeButtonColor1 = R.propOr(neutralColor, 'themeButtonColor1', customTheme);
      themeData.themeButtonHoverColor1 = R.propOr(neutralHoverColor, 'themeButtonHoverColor1', customTheme);
      themeData.themeButtonTextColor1 = R.propOr(textColor, 'themeButtonTextColor1', customTheme);
      themeData.themeButtonHoverTextColor2 = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor2', customTheme);
      themeData.themeButtonBorderColor2 = R.propOr(borderColor, 'themeButtonBorderColor2', customTheme);
      themeData.themeButtonColor2 = R.propOr(neutralColor, 'themeButtonColor2', customTheme);
      themeData.themeButtonHoverColor2 = R.propOr(neutralHoverColor, 'themeButtonHoverColor2', customTheme);
      themeData.themeButtonTextColor2 = R.propOr(textColor, 'themeButtonTextColor2', customTheme);
      themeData.themeButtonHoverTextColor3 = R.propOr(neutralHoverColor, 'themeButtonHoverTextColor3', customTheme);
      themeData.themeButtonBorderColor3 = R.propOr(borderColor, 'themeButtonBorderColor3', customTheme);
      themeData.themeButtonColor3 = R.propOr(neutralColor, 'themeButtonColor3', customTheme);
      themeData.themeButtonHoverColor3 = R.propOr(neutralHoverColor, 'themeButtonHoverColor3', customTheme);
      themeData.themeButtonTextColor3 = R.propOr(textColor, 'themeButtonTextColor3', customTheme);
      themeData.themeVariantButtonColor = R.propOr(primaryContrastColor, 'themeVariantButtonColor')(customTheme);
      themeData.themeVariantButtonTextColor = R.propOr(primaryColor, 'themeVariantButtonTextColor')(customTheme);
      themeData.themeVariantButtonBorderColor = R.propOr(primaryColor, 'themeVariantButtonBorderColor')(customTheme);
      themeData.themeVariantButtonHoverColor = R.propOr(primaryContrastColor, 'themeVariantButtonHoverColor')(customTheme);
      themeData.themeVariantButtonHoverTextColor = R.propOr(primaryHoverColor, 'themeVariantButtonHoverTextColor')(customTheme);
      themeData.themeVariantButtonHoverBorderColor = R.propOr(primaryHoverColor, 'themeVariantButtonHoverBorderColor')(customTheme);
      themeData.themeVariantButtonSelectedColor = R.propOr(primaryColor, 'themeVariantButtonSelectedColor')(customTheme);
      themeData.themeVariantButtonSelectedTextColor = R.propOr(primaryContrastColor, 'themeVariantButtonSelectedTextColor')(customTheme);
      themeData.themeVariantButtonSelectedBorderColor = R.propOr(primaryColor, 'themeVariantButtonSelectedBorderColor')(customTheme);
      themeData.themeButtonBorderRadius = R.prop('themeButtonBorderRadius')(customTheme) || '20px';
      themeData.themeButtonBorderRadius2 = R.prop('themeButtonBorderRadius2')(customTheme) || '20px';
      themeData.themeButtonBorderRadius3 = R.prop('themeButtonBorderRadius3')(customTheme) || '20px';
      themeData.themeVariantButtonBorderRadius = R.prop('themeVariantButtonBorderRadius')(customTheme) || '20px';
      themeData.themeChatButtonColor = R.propOr(chatColor, 'themeChatButtonColor', customTheme);
      themeData.themeChatButtonHoverColor = R.propOr(chatHoverColor, 'themeChatButtonHoverColor', customTheme);
      themeData.themeChatButtonTextColor = R.propOr(chatTextColor, 'themeChatButtonTextColor', customTheme);
      themeData.themeCountDownColor = R.propOr(secondaryColor, 'themeCountDownColor', customTheme);
      themeData.themeFooterBackgroundColor = R.propOr(secondaryBackgroundColor, 'themeFooterBackgroundColor', customTheme);
      themeData.themeFooterLinkColor = R.propOr(secondaryBackgroundLinkColor, 'themeFooterLinkColor', customTheme);
      themeData.themeFooterLinkHoverColor = R.propOr(secondaryBackgroundLinkHoverColor, 'themeFooterLinkHoverColor', customTheme);
      themeData.themeFooterTextColor = R.propOr(secondaryBackgroundTextColor, 'themeFooterTextColor', customTheme);
      themeData.themeInputBackgroundColor = R.propOr(backgroundColor, 'themeInputBackgroundColor', customTheme);
      themeData.themeInputBorderColor = R.propOr(borderColor, 'themeInputBorderColor', customTheme);
      themeData.themeInputBorderFocusColor = R.propOr(borderColor, 'themeInputBorderFocusColor', customTheme);
      themeData.themeLinkColor = R.propOr(linkColor, 'themeLinkColor', customTheme);
      themeData.themeLinkHoverColor = R.propOr(linkHoverColor, 'themeLinkHoverColor', customTheme);
      themeData.themeMenuBackgroundPrimaryColor = R.propOr(backgroundColor, 'themeMenuBackgroundPrimaryColor', customTheme);
      themeData.themeMenuBackgroundSecondaryColor = R.propOr(backgroundColor, 'themeMenuBackgroundSecondaryColor', customTheme);
      themeData.themeMenuBorderColor = R.propOr(borderColor, 'themeMenuBorderColor', customTheme);
      themeData.themeMenuItemBorderColor = R.propOr(themeData.themeMenuBackgroundPrimaryColor, 'themeMenuItemBorderColor', customTheme);
      themeData.themeMenuLinkColor = R.propOr(linkColor, 'themeMenuLinkColor', customTheme);
      themeData.themeMenuLinkHoverColor = R.propOr(linkHoverColor, 'themeMenuLinkHoverColor', customTheme);
      themeData.themeSubmenuBackgroundPrimaryColor = R.propOr(backgroundColor, 'themeSubmenuBackgroundPrimaryColor', customTheme);
      themeData.themeSubmenuBackgroundSecondaryColor = R.propOr(backgroundColor, 'themeSubmenuBackgroundSecondaryColor', customTheme);
      themeData.themeSubmenuBorderColor = R.propOr(borderColor, 'themeSubmenuBorderColor', customTheme);
      themeData.themeSubMenuItemBorderColor = R.propOr(themeData.themeMenuItemBorderColor, 'themeSubMenuItemBorderColor', customTheme);
      themeData.themeSubmenuLinkColor = R.propOr(linkColor, 'themeSubmenuLinkColor', customTheme);
      themeData.themeSubmenuLinkHoverColor = R.propOr(linkHoverColor, 'themeSubmenuLinkHoverColor', customTheme);
      themeData.themeSaleColor = R.propOr(saleColor, 'themeSaleColor', customTheme);
      themeData.themeSaleTextColor = R.propOr(saleTextColor, 'themeSaleTextColor', customTheme);
      themeData.themeText2Color = R.propOr(textColor, 'themeText2Color', customTheme);
      themeData.themeText3Color = R.propOr(textColor, 'themeText3Color', customTheme);
      themeData.themeTextColor = R.propOr(textColor, 'themeTextColor', customTheme);
      themeData.themeSalePriceTextColor = R.propOr(textColor, 'themeSalePriceTextColor', customTheme);
      themeData.themeOriginalPriceTextColor = R.propOr(textColor, 'themeOriginalPriceTextColor', customTheme);
      themeData.themeCampaignHeaderBackgroundColor = R.propOr(secondaryBackgroundColor, 'themeCampaignHeaderBackgroundColor', customTheme);
      themeData.themeCampaignHeaderTitleColor = R.propOr(themeData.themeTextColor, 'themeCampaignHeaderTitleColor', customTheme);
      themeData.themeCampaignHeaderSubtitleColor = R.propOr(secondaryBackgroundTextColor, 'themeCampaignHeaderSubtitleColor', customTheme);
      themeData.themeCampaignHeaderNoticeColor = R.propOr(secondaryBackgroundTextColor, 'themeCampaignHeaderNoticeColor', customTheme);
      if (themeData) {
        let stylesToInsert = ':root {';
        Object.keys(themeData).forEach(key => {
          stylesToInsert += `--${key}: ${themeData[key]} !important;`;
        });
        stylesToInsert += '}';
        return stylesToInsert;
      }
    }
  });
});