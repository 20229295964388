define("client/pods/components/documents/document-print/packing-list-simple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['u-print-container'],
    // @NOTE: PAGE SIZE INFO
    // A4: 210 × 297 millimeters OR 8.27 × 11.69 inches
    // US Letter: 216 × 279 millimeters or 8.5 × 11 inches
    // pixels (1px = 1/96th of 1in)
    // inches (1in = 96px = 2.54cm)

    // work out height in px for given page size
    // A4 SIZES:
    //  HEIGHT: 11.69*96 = 1122.24 ~ round to: 1122px for A4
    //  WIDTH: 8.27*96 = 793.92 ~ round to: 793px
    //
    // US LETTER
    // 11*96 = 1056

    init() {
      this._super(...arguments);
      const pageHeight = 1040;
      this.set('page', {
        pageSize: pageHeight
      });
      const model = this.model;
      const details = model.getData('details');
      const pagesData = [{
        page: 1,
        details: details
      }];
      this.set('pagesData', pagesData);
    },
    didInsertElement() {
      const self = this;
      self._super(...arguments);
      const model = self.get('model');
      const details = model.getData('details');
      const pageHeight = self.get('page.pageSize');
      const entireHeight = self.$('.documents__outer-container').outerHeight() || 0;
      const tBodyHeight = self.$('.u-print-table__body').outerHeight() || 0;
      const allowedTBodyHeight = pageHeight - entireHeight + tBodyHeight;
      let currentPage = 0;
      let pageAccumulatedHeight = 0;
      const pagesData = [];
      const rows = self.$('.u-print-table__data-row');
      rows.each(function (index, row) {
        row = self.$(row);
        const rowHeight = row.outerHeight() || 0;
        pageAccumulatedHeight = pageAccumulatedHeight + rowHeight;
        if (pageAccumulatedHeight > allowedTBodyHeight) {
          ++currentPage;
          pageAccumulatedHeight = 0;
        }
        if (!pagesData[currentPage]) {
          pagesData[currentPage] = {
            page: currentPage + 1,
            details: []
          };
        }
        pagesData[currentPage].details.pushObject(details.objectAt(index));
      });
      self.set('pagesData', pagesData);
    },
    didRender() {
      const pageHeight = this.get('page.pageSize');
      this.$('.documents__outer-container').outerHeight(pageHeight);
    }
  });
});