define("client/pods/components/blogs/files/files-default-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oHs3sClP",
    "block": "{\"symbols\":[\"response\"],\"statements\":[[4,\"if\",[[24,[\"resourceKey\"]]],null,{\"statements\":[[4,\"files/files-fetcher\",null,[[\"resource\",\"resourceKey\",\"adapterName\",\"fetchUploadsOnInit\",\"fetchUploadsCollectionOnInit\",\"onSetImage\"],[[24,[\"resource\"]],[24,[\"resourceKey\"]],\"uploads/collection\",false,true,[24,[\"onSetImage\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"files/image-item\",null,[[\"image\",\"transform\",\"width\",\"height\"],[[23,1,[\"uploadsCollection\",\"defaultImageUuid\"]],[24,[\"transform\"]],[24,[\"width\"]],[24,[\"height\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/files/files-default-image/template.hbs"
    }
  });
});