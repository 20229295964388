define("client/pods/components/extensions/apps/tw/e-invoices/panels-display/panels-activity/component", ["exports", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices-printer", "client/mixins/date", "ramda", "ramda-extension"], function (_exports, _invoicesPrinter, _date, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, _invoicesPrinter.default, {
    crud: Ember.inject.service(),
    hideExcl: true,
    adapterName: 'extensions/apps/tw/e-invoices',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['dispatch/lists/processes']);
    },
    isDispatchedDispatchProcess: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      return R.find(R.pathEq(['_data', 'code'], 'dispatched'), dispatchProcesses);
    }),
    actions: {
      toggleView() {
        this.toggleProperty('showDetails');
      }
    }
  });
});