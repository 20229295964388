define("client/pods/components/documents/pos/profiles-selector/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ember-concurrency-decorators"], function (_exports, _component, R, RA, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsPosProfilesSelectorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service('pos'), _dec4 = Ember.inject.service('users'), _dec5 = Ember.inject.service('storage'), _dec6 = Ember.inject.service('terminal'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember.computed('args.tab.posProfileKey', 'selectedPosProfileModel.{_data.autoIssueEInvoice,user._data.printerId,user._data.printerKey}', 'hasValidPrinterSetup'), _dec12 = Ember.computed('selectedPosProfileModel.{_data.autoIssueEInvoice,user._data.printerId,user._data.printerKey}'), _class = class DocumentsPosProfilesSelectorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "posService", _descriptor3, this);
      _initializerDefineProperty(this, "usersService", _descriptor4, this);
      _initializerDefineProperty(this, "storageService", _descriptor5, this);
      _initializerDefineProperty(this, "terminalService", _descriptor6, this);
      _initializerDefineProperty(this, "selectedPosProfileModel", _descriptor7, this);
      _initializerDefineProperty(this, "defaultSavedPosProfile", _descriptor8, this);
      this.crud.addLists(this, ['pos/lists/profiles']);
    }
    afterLoadLists() {
      this.setupDefaultProfileTask.perform();
    }
    *setupDefaultProfileTask() {
      const tab = this.args.tab;
      if (tab?.hasAlreadyEnteredPosMode) {
        const posProfileModel = tab?.posProfileModel;
        this.selectProfile(posProfileModel);
        return this.enterPosWithProfileTask.perform();
      }
      const profiles = this.crud.lists['pos-lists-profiles'] || [];
      if (profiles.length === 1) {
        const posProfileModel = R.head(profiles);
        return this.selectProfile(posProfileModel);
      }
      const defaultSavedPosProfileModel = yield this.fetchDefaultSavedPosProfileTask.perform();
      if (RA.isNotNilOrEmpty(defaultSavedPosProfileModel)) {
        console.log('default profile', defaultSavedPosProfileModel);
        const defaultSavedPosProfile = R.find(R.pathEq(['_data', '_key'], defaultSavedPosProfileModel?._data?.data?.resourceKey))(profiles);
        if (defaultSavedPosProfile) {
          this.selectProfile(defaultSavedPosProfile);
        }
      }
      const posProfileKey = tab.get('posProfileKey');
      if (!posProfileKey) {
        return;
      }
      const defaultSavedPosProfile = R.find(R.pathEq(['_data', '_key'], posProfileKey))(profiles);
      if (!defaultSavedPosProfile) {
        return;
      }
      this.selectProfile(defaultSavedPosProfile);
    }
    *fetchDefaultSavedPosProfileTask() {
      const defaultSavedPosProfile = yield this.usersService.getSinglePersonalSettingsTask.perform({
        menuId: 'posProfileDefault_single'
      });
      this.defaultSavedPosProfile = defaultSavedPosProfile;
      return defaultSavedPosProfile;
    }
    selectProfile(posProfileModel) {
      this.selectedPosProfileModel = posProfileModel;
      const tab = this.args.tab;
      const posProfileKey = posProfileModel?._data?._key;
      tab.set('posProfileModel', posProfileModel);
      tab.set('posProfileKey', posProfileKey);
    }
    *enterPosWithProfileTask() {
      const tab = this.args.tab;
      const posProfileModel = this.selectedPosProfileModel;
      if (!posProfileModel) {
        return;
      }
      const latestPosProfileModel = yield this.crud.findTask.perform({
        adapterName: posProfileModel?.adapterName,
        appendPath: posProfileModel?._data?._key
      });
      if (!latestPosProfileModel?._data?._key) {
        alert(this.intl.t('profile not found'));
        this.selectProfile(); // reset profile
        return;
      }
      const shopKey = latestPosProfileModel?._data?.shopKey;
      const shopModel = yield this.posService.fetchCampaignWithPosEnableTask.perform(shopKey);
      if (!shopModel) {
        alert(this.intl.t('shop not found'));
        return;
      }
      latestPosProfileModel.set('shop', shopModel);
      this.posService.enterPosProfile(tab, latestPosProfileModel);
    }
    get disallowEnterPosMode() {
      const tab = this.args.tab;
      if (!tab.posProfileKey) {
        return true;
      }
      return this.hasInvalidPrinterSetup;
    }
    get hasInvalidPrinterSetup() {
      const posProfileModel = this.selectedPosProfileModel;
      const autoIssueEInvoice = posProfileModel?._data?.autoIssueEInvoice;
      if (!autoIssueEInvoice) {
        return false;
      }
      if (!posProfileModel?.user?._data?.printerId) {
        return true;
      }
      if (!posProfileModel?.user?._data?.printerKey) {
        return true;
      }
      return false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "posService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "usersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "storageService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "terminalService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedPosProfileModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "defaultSavedPosProfile", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDefaultProfileTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "setupDefaultProfileTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchDefaultSavedPosProfileTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDefaultSavedPosProfileTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProfile", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectProfile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enterPosWithProfileTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "enterPosWithProfileTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disallowEnterPosMode", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "disallowEnterPosMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasInvalidPrinterSetup", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "hasInvalidPrinterSetup"), _class.prototype), _class);
});