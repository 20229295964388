define("client/pods/components/products/panels-display/component", ["exports", "ramda", "client/mixins/crud", "client/utils/nventor", "ember-concurrency", "ramda-extension", "ramda-adjunct"], function (_exports, R, _crud, _nventor, _emberConcurrency, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    dataManager: Ember.inject.service(),
    adapterName: 'products',
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    trackingTagLabel: Ember.computed(function () {
      return this.intl.t('tracking') + ': ';
    }),
    init() {
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'products/lists/displaySubPanels', 'products/lists/editorSubPanels', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypes', 'products/lists/setTypes/variants', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'statuses']);
      this._super(...arguments);
      this.setup();
    },
    setup() {
      const tab = this.tab;
      const model = tab?.model || {};
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    },
    didInsertElement() {
      this._super(...arguments);
      this.fetchParentModelTask.perform();
    },
    fetchParentModelTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const tab = this.tab;
      const isVariantChildOf = model.getData('isVariantChildOf');
      if (isVariantChildOf) {
        const adapterName = 'products';
        const appendPath = `/${isVariantChildOf}`;
        const parent = yield this.crud.findTask.perform({
          adapterName,
          appendPath,
          model,
          tab
        });
        this.set('parent', parent);
      }
    }),
    displaySubPanels: Ember.computed('crud.lists.products-lists-displaySubPanels.[]', 'model._data.setType', function () {
      let panels = R_.dotPath('crud.lists.products-lists-displaySubPanels')(this) || [];
      let removeAll = false;
      const setType = this.get('model._data.setType');
      if (_nventor.default.isNilOrEmpty(setType)) {
        removeAll = true;
      }
      if (setType !== 'isSet' && setType !== 'isSetWithChoices' && setType !== 'hasVariants') {
        removeAll = true;
      }

      // remove all for general product
      if (removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
      }
      if (setType === 'isSet') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'customizations'))(panels);
      } else if (setType === 'isSetWithChoices') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'customizations'))(panels);
      } else if (setType === 'hasVariants') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }
      return panels;
    }),
    /**
     * dynamically determine classname for status tag.
     * green if active, otherwise gray for inactive
     */
    statusTagClassName: Ember.computed('model._data.status', function () {
      const status = this.get('model._data.status');
      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    }),
    displaySubPanel(resourceData) {
      const subTabOptions = {
        component: resourceData.getData('component'),
        docType: resourceData.getData('docType'),
        model: this.model,
        id: resourceData.getData('value'),
        value: resourceData.getData('value')
      };
      const tab = this.tab;
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    },
    copyProduct() {
      const model = this.model;
      const adapterName = this.adapterName;
      const newModel = this.crud.setupNewRecord({
        adapterName,
        model
      });
      let name = model.getData('name');
      let code = model.getData('code');
      let barcode = model.getData('barcode');
      name = `${name}-copy-` + _nventor.default.random.digits(3);
      if (code) {
        code = `${code}-copy-` + _nventor.default.random.digits(3);
      }
      if (barcode) {
        barcode = `${barcode}-copy-` + _nventor.default.random.digits(3);
      }
      newModel.setData('status', this.get('constants.status.active'));
      newModel.setData('name', name);
      newModel.setData('password', '');
      newModel.setData('code', code);
      newModel.setData('barcode', barcode);
      newModel.setData('_key', '');
      newModel.setData('_id', '');
      newModel.setData('_rev', '');
      newModel.setData('modified', '');
      newModel.setData('created', '');
      newModel.setData('copyFromKey', model.getData('_key'));
      newModel.setData('copyImages', true);
      if (newModel?._data?.hasVariants) {
        const variants = newModel?._data?.variants || [];
        R.forEach(variantData => {
          const variantOptions = variantData._data.options || [];
          R.forEach(variantOptionData => {
            variantOptionData.setData('optionUid', _nventor.default.random.alphaNum(10));
          })(variantOptions);
          return variantData;
        })(variants);
        // let variantChildren = newModel?._data?.variantChildren || []
        // variantChildren = R.reject(variantChild => {
        //   const permanentlyUnavailable = R.pathOr(false, ['_data', 'permanentlyUnavailable'])(variantChild)

        //   if (permanentlyUnavailable) {
        //     return true
        //   }

        //   return false
        // })(variantChildren)
        newModel.setData('variantChildren', []);
        // newModel.updateVariantChildrenList()
      }
      newModel.set('isCopying', true);
      this.set('dirty', newModel);
      const tab = this.tab;
      const tabCopy = this.tabsManager.copyTab({
        tab
      });
      this.set('tabCopy', tabCopy);
    },
    cancelCopyProduct(onCloseDialogAction) {
      const dirty = this.dirty;
      const adapterName = this.adapterName;
      this.dataManager.cancelDirty(adapterName, dirty);
      onCloseDialogAction();
    },
    displayProduct(onCloseDialogAction, saved) {
      const dirty = this.dirty;
      const adapterName = this.adapterName;
      this.dataManager.cancelDirty(adapterName, dirty);
      this.set('dirty', '');
      onCloseDialogAction();
      return this.onDisplay(saved);
    },
    actions: {
      editPanel() {
        const tab = this.tab;
        const model = this.model;
        const activeSubTab = this.tabsManager.getActiveSubTab(tab);

        // activeSubTab could be an empty object, on those cases it should use dot path rather than .get fn from model
        const activeSubTabLabel = activeSubTab?.label || '';
        const activeSubTabEditingComponent = activeSubTab?.editingComponent || '';
        const subPanels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
        let subTabOptions = subPanels.findBy('_data.label', activeSubTabLabel);
        if (RA.isNilOrEmpty(subTabOptions)) {
          subTabOptions = subPanels.findBy('_data.component', activeSubTabEditingComponent) || _nventor.default.safeHeadOr({}, subPanels);
        }
        const tabOptions = {
          loadInstructions: {
            component: subTabOptions.get?.('_data.component'),
            label: subTabOptions.get?.('_data.label'),
            value: subTabOptions.get?.('_data.value')
          }
        };
        this.onEdit(model, {
          tabOptions
        });
      }
    }
  }, _applyDecoratedDescriptor(_obj, "copyProduct", [_dec], Object.getOwnPropertyDescriptor(_obj, "copyProduct"), _obj), _applyDecoratedDescriptor(_obj, "cancelCopyProduct", [_dec2], Object.getOwnPropertyDescriptor(_obj, "cancelCopyProduct"), _obj), _applyDecoratedDescriptor(_obj, "displayProduct", [_dec3], Object.getOwnPropertyDescriptor(_obj, "displayProduct"), _obj), _obj));
});