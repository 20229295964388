define("client/helpers/number", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    intl: Ember.inject.service(),
    compute(params, hash) {
      let number = params[0];
      const defaultValue = hash.defaultValue;
      const rounding = hash.rounding;
      const negative = hash.negative;
      const str = number + '';
      if (number == null || str.trim() === '') {
        if (defaultValue != null) {
          return defaultValue;
        }
        return '0';
      }
      let options = {};
      if (rounding != null) {
        number = this.newBig(number).toFixed(rounding);
        options = {
          maximumFractionDigits: rounding
        };
      }
      const finalNumber = this.intl.formatNumber(number, options);
      if (negative) {
        return `- ${finalNumber}`;
      }
      return finalNumber;
    }
  });
});