define("client/pods/components/channels/shop/campaigns/panels-dash-linked/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsPanelsDashLinkedComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('settings'), _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _class = class ChannelsShopCampaignsCampaignsPanelsDashLinkedComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "server", _descriptor2, this);
      _initializerDefineProperty(this, "settingsService", _descriptor3, this);
      _initializerDefineProperty(this, "selectedItems", _descriptor4, this);
    }
    selectItem(item, isSelected) {
      const selected = this.selectedItems || [];
      if (isSelected === true) {
        selected.pushObject(item);
      } else {
        selected.removeObject(item);
      }
      Ember.set(this, 'selectedItems', selected);
    }
    toggleSelectAllItems() {
      const selectedItems = this.selectedItems || [];
      let results = this.args?.linkedFilters?.results || [];
      results = R.reject(R.propEq('isAllChildrenSynced', true))(results);
      if (R.equals(selectedItems, results)) {
        Ember.set(this, 'selectedItems', []);
      } else {
        Ember.set(this, 'selectedItems', results);
      }
    }
    *syncCampaignsTask() {
      if (!this.settingsService.getProp('features.allowMasterChildCampaigns')) {
        return false;
      }
      const selectedItems = this?.selectedItems || [];
      const masters = R.map(selected => {
        const campaign = selected.serialize();
        return {
          _id: campaign._id,
          _key: campaign._key
        };
      })(selectedItems);
      const data = {
        masters
      };
      yield this.server.call('PATCH', `api/protected/channels/shop/campaigns/master/batch`, data);
      Ember.set(this, 'selectedItems', []);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedItems", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSelectAllItems", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelectAllItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncCampaignsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "syncCampaignsTask"), _class.prototype), _class);
});