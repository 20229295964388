define("client/pods/components/app-companies/selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zBXOEZKp",
    "block": "{\"symbols\":[\"@companyLogoUrl\"],\"statements\":[[5,\"lists/list-dropdown\",[],[[\"@imageUrl\",\"@imageHeight\",\"@imageWidth\",\"@imageTransform\",\"@imageSrcsetTransform\",\"@isBtn\",\"@list\",\"@translate\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\",\"@isRunning\",\"@onSelect\"],[[23,1,[]],\"30px\",\"auto\",\"/-/scale_crop/60x60/center/-/format/webp/-/progressive/yes/\",\"/-/scale_crop/120x120/center/-/format/webp/-/progressive/yes/ 2x\",true,[23,0,[\"pods\"]],false,\"podKey\",\"company\",[23,0,[\"auth\",\"selectedPod\"]],[23,0,[\"auth\",\"selectCompanyToLoginTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"auth\",\"selectCompanyToLoginTask\"]]],null]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/app-companies/selector/template.hbs"
    }
  });
});