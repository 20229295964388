define("client/pods/components/channels/shop/rewards/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M0Qe6vcS",
    "block": "{\"symbols\":[\"tabsHeaderComponent\",\"isOverflowMenu\",\"onToggleOverflowMenu\",\"onCloseDialogAction\",\"@tab\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onEdit\",\"@onDisplay\",\"@onPaginationTask\",\"@errors\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container flex-container-row flex-container--wrap\"],[8],[0,\"\\n    \"],[5,\"layouts/table-view\",[],[[\"@tab\",\"@tableClassNames\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onEdit\",\"@onDisplay\"],[[23,5,[]],\"rewards-dash\",\"channels/shop/rewards/table-view/detail-row\",[23,0,[\"model\"]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[[12,\"class\",\"show-when-overflow\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"helpers/pagination-controls\",[],[[\"@showPageNumbers\",\"@filters\",\"@list\",\"@onPaginationTask\"],[true,[23,6,[]],[23,0,[\"model\"]],[23,11,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"layouts/btns-group\",[[12,\"class\",\"hide-when-overflow\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@hasModalDialogHeader\",\"@icon\",\"@modalDialogTitleTranslate\",\"@modalDialogTitle\",\"@isRunning\",\"@label\"],[false,\"fas fa-gift\",true,\"reward\",\"\",\"send reward manually\"]],{\"statements\":[[0,\"\\n        \"],[5,\"channels/shop/rewards/panels-dash/rewards-dialog\",[],[[\"@tab\",\"@sendLabel\",\"@onCloseDialogAction\",\"@errors\"],[[23,5,[]],\"send reward\",[23,4,[]],[23,12,[]]]]],[0,\"\\n      \"]],\"parameters\":[4]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n          \\n  \"]],\"parameters\":[1,2,3]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/panels-dash/template.hbs"
    }
  });
});