define("client/pods/websites/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/utils/nventor", "client/pods/open-hours/model"], function (_exports, R, RA, _model, _nventor, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Website Model Type
   * @typedef {Object} WebsiteDataType
   * @property {boolean} facebookPluginEnable
   * @property {boolean} hasCustomDomain
   * @property {boolean} lineChatEnable
   * @property {boolean} menuExpandSubMenus
   * @property {boolean} menuIsAlwaysHidden
   * @property {boolean} menuShowName
   * @property {boolean} menuShowLogo
   * @property {String} menuShowLogoSize
   * @property {String} menuShowLogoMobileSize
   * @property {String} menuShowLogoDesktopOnlySize
   * @property {boolean} widthDefault
   * @property {boolean} widthFull
   * @property {boolean} useDefaultsContactInfo
   * @property {string} defaultHomePageTemplate
   * @property {string} domain
   * @property {string} menuPosition
   * @property {string} menuStyle
   * @property {string} name
   * @property {string} status
   * @property {string} subdomain
   * @property {string} blogsImagesRatio
   * @property {string} themeActionColor
   * @property {string} themeActionFinalColor
   * @property {string} themeActionFinalFocusColor
   * @property {string} themeActionFinalHoverColor
   * @property {string} themeActionFinalTextColor
   * @property {string} themeActionHoverColor
   * @property {string} themeActionTextColor
   * @property {string} themeActiveColor
   * @property {string} themeBackgroundPrimaryColor
   * @property {string} themeBackgroundSecondaryColor
   * @property {string} themeBorderColor
   * @property {string} themeButtonBorderRadius
   * @property {string} themeButtonBorderColor
   * @property {string} themeButtonBorderColor2
   * @property {string} themeButtonBorderColor3
   * @property {string} themeButtonColor
   * @property {string} themeButtonColor2
   * @property {string} themeButtonColor3
   * @property {string} themeButtonHoverColor
   * @property {string} themeButtonHoverColor2
   * @property {string} themeButtonHoverColor3
   * @property {string} themeButtonTextColor
   * @property {string} themeButtonTextColor2
   * @property {string} themeButtonTextColor3
   * @property {string} themeVariantButtonColor
   * @property {string} themeVariantButtonTextColor
   * @property {string} themeVariantButtonBorderColor
   * @property {string} themeVariantButtonHoverColor
   * @property {string} themeVariantButtonHoverTextColor
   * @property {string} themeVariantButtonHoverBorderColor
   * @property {string} themeVariantButtonSelectedColor
   * @property {string} themeVariantButtonSelectedTextColor
   * @property {string} themeVariantButtonSelectedBorderColor
   * @property {string} themeVariantButtonBorderRadius
   * @property {string} themeChatButtonColor
   * @property {string} themeChatButtonHoverColor
   * @property {string} themeChatButtonTextColor
   * @property {string} themeCountDownColor
   * @property {string} themeCountDownTextColor
   * @property {string} themeFooterBackgroundColor
   * @property {string} themeFooterLinkColor
   * @property {string} themeFooterLinkHoverColor
   * @property {string} themeFooterTextColor
   * @property {string} themeInputBackgroundColor
   * @property {string} themeInputBorderFocusColor
   * @property {string} themeInputTextColor
   * @property {string} themeLinkColor
   * @property {string} themeLinkHoverColor
   * @property {string} themeMenuBackgroundPrimaryColor
   * @property {string} themeMenuBackgroundSecondaryColor
   * @property {string} themeMenuBorderColor
   * @property {string} themeMenuItemBorderColor
   * @property {string} themeMenuLinkColor
   * @property {string} themeMenuLinkHoverColor
   * @property {string} themeSubmenuBackgroundPrimaryColor
   * @property {string} themeSubmenuBackgroundSecondaryColor
   * @property {string} themeSubmenuBorderColor
   * @property {string} themeSubmenuItemBorderColor
   * @property {string} themeSubmenuLinkColor
   * @property {string} themeSubmenuLinkHoverColor
   * @property {string} themeSaleColor
   * @property {string} themeSaleTextColor
   * @property {string} themeTagColor
   * @property {string} themeTagTextColor
   * @property {string} themeText2Color
   * @property {string} themeText3Color
   * @property {string} themeTextColor
   * @property {string} themeSalePriceTextColor
   * @property {string} themeOriginalPriceTextColor
   * @property {string} widthCustom
   * @property {string} themeCampaignHeaderBackgroundColor
   * @property {string} themeCampaignHeaderTitleColor
   * @property {string} themeCampaignHeaderSubtitleColor
   * @property {string} themeCampaignHeaderNoticeColor
   */

  /**
   * Website Model Type
   * @typedef {Object} WebsiteType
   * @property {WebsiteDataType} _data
   */

  const WebsitesBaseModel = _model.default.extend({
    defaults() {
      const settings = this.settings;
      const linkColor = '#000000';
      const linkHoverColor = '#262626';
      const primaryColor = '#e4666f';
      const primaryContrastColor = '#ffffff';
      const primaryHoverColor = '#fa2e3c';
      const textColor = '#666666';
      const inputTextColor = '#363636';
      const whiteTextColor = '#ffffff';
      const secondaryColor = '#000000';
      const secondaryContrastColor = '#ffffff';
      const secondaryHoverColor = '#262626';
      const neutralColor = '#ffffff';
      const neutralHoverColor = '#eeeeee';
      const backgroundColor = '#ffffff';
      const borderColor = '#eeeeee';
      const secondaryBackgroundColor = '#eeeeee';
      const secondaryBackgroundTextColor = '#666666';
      const secondaryBackgroundLinkColor = '#000000';
      const secondaryBackgroundLinkHoverColor = '#262626';
      const chatColor = '#66aee4';
      const chatHoverColor = '#4390ca';
      const chatTextColor = '#ffffff';
      const saleColor = '#ff4a68';
      const saleTextColor = '#ffffff';
      const activeColor = '#000000';
      const tagColor = '#f5f5f5';
      const tagTextColor = '#666666';
      const defaultRadius = '20px';
      const defaultSpacing = '0px';
      const isSimpleSite = settings.isFeatureAllowed('features.isVirtualOnly');
      return {
        name: '',
        subdomain: '',
        domain: '',
        defaultHomePageTemplate: {
          pageDataString: '{"html":"","css":"*{box-sizing: border-box;} body {margin: 0;}","assets":[],"styles":[],"components":[]}'
        },
        themeName: '',
        hasCustomDomain: false,
        blogsImagesRatio: settings.getProp('websiteBlogsImagesRatio'),
        facebookPluginEnable: settings.getProp('facebookPluginEnable', false),
        lineChatEnable: settings.getProp('lineChatEnable', false),
        status: this.get('constants.status.active'),
        widthDefault: true,
        widthFull: false,
        widthCustom: '1344',
        menuExpandAlignment: this.get('constants.alignment.left'),
        menuExpandBehavior: this.get('constants.menuExpandBehavior.autoHide'),
        menuIsAlwaysHidden: false,
        menuExpandSubMenus: false,
        menuStyle: 'horizontal',
        menuShowName: true,
        menuShowLogo: true,
        menuShowLogoSize: '20px',
        menuShowLogoMobileSize: '20px',
        menuShowLogoDesktopOnlySize: '20px',
        menuFontSize: '14px',
        menuPosition: 'left',
        facebookPixelKeys: [],
        googleTrackingKeys: [],
        showQuickNav: true,
        useDefaultsFacebookPixelKeys: true,
        useDefaultsGoogleTrackingKeys: true,
        useDefaultsContactInfo: true,
        themeActionColor: primaryColor,
        themeActionTextColor: primaryContrastColor,
        themeActionHoverColor: primaryHoverColor,
        themeActionFinalColor: secondaryColor,
        themeActionFinalFocusColor: secondaryHoverColor,
        themeActionFinalHoverColor: secondaryHoverColor,
        themeActionFinalTextColor: secondaryContrastColor,
        themeActiveColor: activeColor,
        themeBackgroundPrimaryColor: backgroundColor,
        themeBackgroundSecondaryColor: backgroundColor,
        themeBorderColor: borderColor,
        themeTagColor: tagColor,
        themeTagTextColor: tagTextColor,
        themeButtonBorderRadius: defaultRadius,
        themeButtonBorderRadius2: defaultRadius,
        themeButtonBorderRadius3: defaultRadius,
        themeVariantButtonBorderRadius: defaultRadius,
        themeButtonBorderColor: borderColor,
        themeButtonColor: neutralColor,
        themeButtonHoverColor: neutralHoverColor,
        themeButtonHoverTextColor: primaryHoverColor,
        themeButtonTextColor: textColor,
        themeButtonBorderColor2: '#b8b8b8',
        themeButtonColor2: '#b8b8b8',
        themeButtonHoverColor2: '#666666',
        themeButtonHoverTextColor2: primaryHoverColor,
        themeButtonTextColor2: secondaryContrastColor,
        themeButtonBorderColor3: '#666666',
        themeButtonColor3: '#666666',
        themeButtonHoverColor3: '#b8b8b8',
        themeButtonHoverTextColor3: primaryHoverColor,
        themeButtonTextColor3: secondaryContrastColor,
        themeVariantButtonColor: primaryContrastColor,
        themeVariantButtonTextColor: primaryColor,
        themeVariantButtonBorderColor: primaryColor,
        themeVariantButtonHoverColor: primaryContrastColor,
        themeVariantButtonHoverTextColor: primaryHoverColor,
        themeVariantButtonHoverBorderColor: primaryHoverColor,
        themeVariantButtonSelectedColor: primaryColor,
        themeVariantButtonSelectedTextColor: primaryContrastColor,
        themeVariantButtonSelectedBorderColor: primaryColor,
        themeChatButtonColor: chatColor,
        themeChatButtonHoverColor: chatHoverColor,
        themeChatButtonTextColor: chatTextColor,
        themeCountDownColor: secondaryColor,
        themeCountDownTextColor: whiteTextColor,
        themeFooterHeaderColor: primaryColor,
        themeFooterBackgroundColor: secondaryBackgroundColor,
        themeFooterLinkColor: secondaryBackgroundLinkColor,
        themeFooterLinkHoverColor: secondaryBackgroundLinkHoverColor,
        themeFooterTextColor: secondaryBackgroundTextColor,
        themeInputTextColor: inputTextColor,
        themeInputBackgroundColor: backgroundColor,
        themeInputBorderFocusColor: borderColor,
        themeLinkColor: linkColor,
        themeLinkHoverColor: linkHoverColor,
        themeMenuBackgroundPrimaryColor: backgroundColor,
        themeMenuBackgroundSecondaryColor: backgroundColor,
        themeMenuBorderColor: borderColor,
        themeMenuItemBorderColor: backgroundColor,
        themeMenuLinkColor: linkColor,
        themeMenuLinkHoverColor: linkHoverColor,
        themeSubmenuBackgroundPrimaryColor: backgroundColor,
        themeSubmenuBackgroundSecondaryColor: backgroundColor,
        themeSubmenuBorderColor: borderColor,
        themeSubmenuItemBorderColor: backgroundColor,
        themeSubmenuLinkColor: linkColor,
        themeSubmenuLinkHoverColor: linkHoverColor,
        themeText2Color: textColor,
        themeText3Color: textColor,
        themeTextColor: textColor,
        themeSalePriceTextColor: textColor,
        themeOriginalPriceTextColor: textColor,
        themeFaqsQuestionColor: textColor,
        themeFaqsQuestionHoverColor: textColor,
        themeFaqsAnswerColor: textColor,
        themeSaleColor: saleColor,
        themeSaleTextColor: saleTextColor,
        themeCampaignHeaderBackgroundColor: backgroundColor,
        themeCampaignHeaderTitleColor: textColor,
        themeCampaignHeaderSubtitleColor: textColor,
        themeCampaignHeaderNoticeColor: textColor,
        themeNavTreeItemPaddingX: '0px',
        themeNavTreeItemMarginX: '0px',
        themeGeneralLetterSpacing: defaultSpacing,
        themeHeaderH1LetterSpacing: defaultSpacing,
        themeHeaderH2LetterSpacing: defaultSpacing,
        themeHeaderH3LetterSpacing: defaultSpacing,
        themeHeaderH4LetterSpacing: defaultSpacing,
        themeHeaderH5LetterSpacing: defaultSpacing,
        themeHeaderH6LetterSpacing: defaultSpacing,
        openHours: {
          hasBreakTime: false,
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {}
        },
        openGraph: {},
        favicon: '',
        logoMobile: '',
        logoPosition: 'top',
        logoIconPosition: 'top',
        allowSeo: false,
        thirdParty: {
          rss: {
            enabled: false
          },
          shopDotCom: {}
        },
        isShowBlogsTags: true,
        isShowNewsTags: true,
        blogsPerRow: 3,
        newsPerRow: 3,
        isSimpleSite
      };
    },
    populate(data, attrs) {
      // @TODO: remove?
      data.orderedEmailCopyTo = this.emails.joinAsString(data.orderedEmailCopyTo);
      data.allowSeo = this._setSeoFlagOnOldWebsites(data);
      data.openHours = this._transformOpenHours(data);
      data.thirdParty = this._transformThirdParty(data);
      this._super(data, attrs);
    },
    _transformThirdParty(data) {
      const defaults = this.defaults() || {};
      return R.pipe(R.propOr({}, 'thirdParty'), R.mergeRight(defaults.thirdParty))(data);
    },
    colors: Ember.computed('synced', function () {
      return R.pipe(R.propOr({}, '_data'), R.toPairs, R.filter(R.pipe(R.head, R.test(/^theme(?!Name)/))), R.sort(([a], [b]) => a.localeCompare(b)), R.map(R.zipObj(['key', 'value'])))(this);
    }),
    wizardTabRequirements: [['name', 'subdomain'], ['defaultHomePageTemplate', 'themeName']],
    wizardRequirementsMet: Ember.computed('_data.{name,subdomain,defaultHomePageTemplate,themeName}', function () {
      const wizardTabRequirements = this.wizardTabRequirements || [];
      const requirements = R.pipe(R.map(req => R.pick(req)(this._data)), R.reject(R.isEmpty))(wizardTabRequirements);
      if (R.isEmpty(requirements)) {
        return requirements;
      }
      const noEmpty = R.pipe(R.isEmpty, R.not);
      return R.pipe(R.map(R.pipe(R.map(noEmpty), R.values, RA.allEqualTo(true))))(requirements);
    }),
    openDays: Ember.computed('_data.openHours.monday.{open,close}', '_data.openHours.tuesday.{open,close}', '_data.openHours.wednesday.{open,close}', '_data.openHours.thursday.{open,close}', '_data.openHours.friday.{open,close}', '_data.openHours.saturday.{open,close}', '_data.openHours.sunday.{open,close}', function () {
      const openHours = this._data.openHours;
      if (openHours) {
        return R.map(R.ifElse(R.propEq('open', 'none'), R.always({
          hasOpeningTime: true
        }), R.always({
          hasOpeningTime: false
        })))(openHours);
      }
    }),
    _transformOpenHours(data) {
      const defaults = this.defaults() || {};
      const defaultsOpenHours = defaults.openHours || {};
      const openHours = R.propOr(defaultsOpenHours, 'openHours')(data);
      return R.mapObjIndexed((value, key) => {
        if (R.type(value) === 'Boolean') {
          return value;
        }
        value.name = key;
        return _nventor.default.createModel(_model2.default, value);
      })(openHours);
    },
    _setSeoFlagOnOldWebsites(data) {
      if (data.hasCustomDomain && RA.isNilOrEmpty(data.allowSeo)) {
        return true;
      }
      return data?.allowSeo || false;
    },
    sortedOpenHours: Ember.computed('_data.openHours.monday.{open,close,hasOpeningHours}', '_data.openHours.tuesday.{open,close,hasOpeningHours}', '_data.openHours.wednesday.{open,close,hasOpeningHours}', '_data.openHours.thursday.{open,close,hasOpeningHours}', '_data.openHours.friday.{open,close,hasOpeningHours}', '_data.openHours.saturday.{open,close,hasOpeningHours}', '_data.openHours.sunday.{open,close,hasOpeningHours}', function () {
      const openHours = this._data.openHours;
      const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const sortDays = R.pipe(R.toPairs, R.sortBy(R.pipe(R.head, R.indexOf(R.__, weekdays))), R.fromPairs, R.values, R.reject(day => R.type(day) === 'Boolean'));
      return sortDays(openHours);
    }),
    rssUrls: Ember.computed('_data.{domain,subdomain}', function () {
      const rssConsumers = ['google', 'facebook'];
      return rssConsumers.reduce((acc, consumerName) => {
        const shouldUseCustomDomain = this._data?.domainVerified && this._data?.hasCustomDomain;
        let url = `https://${this._data.subdomain}.bigordr.com`;
        if (shouldUseCustomDomain) {
          url = `https://${this._data.domain}`;
        }
        const feedData = {
          name: consumerName,
          url: `${url}/api/public/3rdparty/rss/${consumerName}/products.xml`
        };
        return acc.concat(feedData);
      }, []);
    })
  });
  let WebsitesModel = _exports.default = (_dec = Ember._tracked, _class = class WebsitesModel extends WebsitesBaseModel {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});