define("client/pods/websites/lists/new-website-wizard/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        component: 'websites/websites-editor/website-wizard/quick-start',
        resource: 'api/protected/websites/wizard-steps/website-info',
        icon: 'fas fa-tasks',
        label: 'website info',
        tabType: 'reuseSubTab',
        value: 'websiteInfo',
        updateProps: ['name', 'subdomain'],
        _isNewTab: true
      }, {
        component: 'websites/websites-editor/website-wizard/quick-templates',
        resource: 'api/protected/websites/wizard-steps/public-templates',
        icon: 'fas fa-file-alt',
        label: 'public templates',
        tabType: 'reuseSubTab',
        value: 'publicTemplates',
        updateProps: ['themeName', 'defaultHomePageTemplate'],
        _isNewTab: true
      }, {
        component: 'websites/websites-editor/website-wizard/quick-shop',
        resource: 'api/protected/websites/wizard-steps/website-shop',
        icon: 'fas fa-file-alt',
        label: 'shop',
        tabType: 'reuseSubTab',
        value: 'websiteShop',
        updateProps: ['status'],
        _isNewTab: true
      }];
      return _nventor.default.resolve(data);
    }
  });
});