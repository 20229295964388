define("client/pods/components/menus/progress-bar/step/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MenusProgressBarStepComponent extends _component.default {
    get stepStatus() {
      const currentIndex = this.currentIndex;
      if (currentIndex === -1) {
        return 'inactive';
      }
      const index = this.args.index;
      if (index < currentIndex) {
        return 'done';
      } else if (index === currentIndex) {
        return 'active';
      } else {
        return 'inactive';
      }
    }
    get currentIndex() {
      if (!this.args.hasCurrent) {
        return -1;
      }
      const currentIndex = this.args.currentIndex;
      if (typeof currentIndex === 'number') {
        return currentIndex;
      }
      const current = this.args.current;
      const steps = this.args.steps;
      if (current && steps) {
        return R.findIndex(R.pathEq(['_data', 'value'], current.value))(steps);
      }
      return -1;
    }
  }
  _exports.default = MenusProgressBarStepComponent;
});