define("client/pods/components/ledgers/journals/cashbook/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "client/utils/nventor", "ember-concurrency", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _date, _crud, _nventor, _emberConcurrency, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    search: Ember.inject.service(),
    model: null,
    errors: null,
    adapterName: 'ledgers/journals/cashbook',
    init() {
      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'ledgers/journals/cashbook/lists/menu']);
      this.set('contactsList', []);
      const txFilters = this.search.setupFilters({
        adapterName: 'ledgers/journals/cashbook/filters'
      });
      this.set('txFilters', txFilters);
      this._super(...arguments);
    },
    afterLoadLists() {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        const id = loadInstructions.getData('find.id');
        if (id) {
          this.set('selectedBankAccountKey', id);
          this.displayTab();
        }
      } else {
        // when tab component is created, it will automatically fetch txData for default bank account.
        // this can only happen once list of bank accounts has been fetched.
        const defaultBankAccountKey = this.getDefaultBankAccountKey();
        this.set('selectedBankAccountKey', defaultBankAccountKey);
        this.displayTab();
      }
    },
    accountsListsBanksWithAll: Ember.computed('crud.lists.ledgers-accounts-lists-banks', function () {
      const bankLists = R_.dotPathOr([], 'crud.lists.ledgers-accounts-lists-banks')(this);
      const allBanks = this.setupNewRecord({
        data: {
          _key: 'allBanks',
          name: 'all banks',
          ledger: 'bank',
          isNotEditable: true,
          translate: true
        }
      });
      return R.prepend(allBanks, bankLists);
    }),
    displayTab() {
      const resourceData = R_.dotPathOr({}, 'tab.resourceData')(this);
      if (RA.isNotNilOrEmpty(resourceData)) {
        this.tabsManager.displaySubPanelNew(this.tab, resourceData);
      } else {
        const subPanels = R_.dotPath('crud.lists.ledgers-journals-cashbook-lists-menu')(this);
        const activeSubPanelModel = _nventor.default.safeHeadOr({}, subPanels);
        this.tabsManager.displaySubPanelNew(this.tab, activeSubPanelModel);
      }
    },
    getDefaultBankAccountKey() {
      const bankAccounts = this?.accountsListsBanksWithAll || [];
      let defaultAccount = bankAccounts.findBy('_data.isDefault', true);
      if (!defaultAccount) {
        defaultAccount = bankAccounts.get('firstObject');
      }
      this.set('account', defaultAccount);
      if (defaultAccount) {
        return defaultAccount.getData('_key');
      }
      return '';
    },
    isAllBanks: Ember.computed('account._data._key', function () {
      const account = this?.account?._data?._key || '';
      if (R.equals(account, 'allBanks')) {
        return true;
      }
      return false;
    }),
    setBankAccount(account) {
      // reset filters too when changing bank
      this.txFilters.reset();
      this.set('account', account);
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const subPanels = R_.dotPath('crud.lists.ledgers-journals-cashbook-lists-menu')(this);

      // activeSubTab could be an empty object, on those cases it should use dot path rather than .get fn from model
      const activeSubTabLabel = activeSubTab?.label || '';
      const activeSubTabComponent = activeSubTab?.component || '';
      let activeSubPanelModel = subPanels.findBy('_data.label', activeSubTabLabel);
      if (RA.isNilOrEmpty(activeSubPanelModel)) {
        activeSubPanelModel = subPanels.findBy('_data.component', activeSubTabComponent) || _nventor.default.safeHeadOr({}, subPanels);
      }
      this.tabsManager.displaySubPanelNew(tab, activeSubPanelModel);
    },
    toggleResults(results) {
      const subTab = this.tabsManager.getActiveSubTab(this.tab);
      if (subTab?.component === 'ledgers/journals/cashbook/panels-display') {
        return results;
      }
      this.filters.set('showResults', true);
      return results;
    }
  }, _applyDecoratedDescriptor(_obj, "setBankAccount", [_dec], Object.getOwnPropertyDescriptor(_obj, "setBankAccount"), _obj), _applyDecoratedDescriptor(_obj, "toggleResults", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleResults"), _obj), _obj));
});