define("client/pods/components/transporters/payuni-seven-eleven-editor/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersPayuniSevenElevenEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('transporter'), _dec3 = Ember.inject.service('settings'), _dec4 = Ember.inject.service('dispatch'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('args.model.originalModel._data.convenienceStoreData.storeId', 'args.model._data.convenienceStoreData.storeId'), _dec8 = Ember.computed('args.model.originalModel._data.convenienceStoreData.storeName', 'args.model._data.convenienceStoreData.storeName'), _dec9 = Ember._action, _class = class TransportersPayuniSevenElevenEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "transporterService", _descriptor2, this);
      _initializerDefineProperty(this, "settingsService", _descriptor3, this);
      _initializerDefineProperty(this, "dispatchService", _descriptor4, this);
      _initializerDefineProperty(this, "convenienceStoreWindow", _descriptor5, this);
      _initializerDefineProperty(this, "transporterModel", _descriptor6, this);
      this.crud.addLists(this, ['extensions/apps/tw/payuni-seven-eleven/accounts', 'extensions/apps/tw/payuni-seven-eleven/type']);
    }
    get storeId() {
      return R.path(['args', 'model', 'originalModel', '_data', 'convenienceStoreData', 'storeId'])(this) || R.pathOr('', ['args', 'model', '_data', 'convenienceStoreData', 'storeId'])(this);
    }
    get storeName() {
      return R.path(['args', 'model', 'originalModel', '_data', 'convenienceStoreData', 'storeName'])(this) || R.pathOr('', ['args', 'model', '_data', 'convenienceStoreData', 'storeName'])(this);
    }
    get senderNamePlaceHolder() {
      return this.transporterService.getSenderNamePlaceHolder();
    }
    get allowUpdatePackageQty() {
      const isReadonly = R.pathEq(['args', 'isReadonly'], true)(this);
      if (isReadonly) {
        return false;
      }
      const hasCollectionAmount = this.transporterService.hasCollectionAmount(this.args.model);
      return !hasCollectionAmount;
    }
    closeUpdateConvenienceStoreWindow(storeId) {
      try {
        if (this.convenienceStoreWindow && this.convenienceStoreWindow.close) {
          this.convenienceStoreWindow.close();
          if (this.args?.outerOnCloseDialogAction) {
            this.args.outerOnCloseDialogAction();
          }
        }
      } catch (error) {
        // DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }

    /**
     * @param {Object} model - The model object.
     * @return {void} - This method does not return anything.
     */
    *updateConvenienceStoreTask(model) {
      let personId;
      if (this.args.upsertPersonTask && this.args?.isNewPerson) {
        personId = this.args?.personModel?._data?.personId;
        const transporterModel = yield this.args.upsertPersonTask.perform();
        const hasPerson = R.pipe(R.pathOr([], ['_data', 'persons']), R.find(R.pathEq(['_data', 'personId'], personId)))(transporterModel);
        if (!hasPerson) {
          personId = null;
        }
      } else if (model?._data?.transporterDefaultPickupLocationPersonId) {
        personId = model?._data?.transporterDefaultPickupLocationPersonId;
      } else if (this.args?.personModel?._data?.personId) {
        personId = this.args?.personModel?._data?.personId;
      } else {
        const transporterAccount = this.args.model?._data?.transporterAccount;
        personId = this.transporterModel.getPersonIdByTransporterAccount(transporterAccount);
      }
      const transporterKey = this.args?.transporterKey || this.args?.model?._data?.transporterKey || '';
      try {
        const url = yield this.dispatchService.updateConvenienceStoreUrl.perform({
          model,
          transporterKey,
          personId
        });
        this.convenienceStoreWindow = window.open(url, '_blank');
        this.args.outerOnCloseDialogAction();
      } catch (error) {
        // DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreWindow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "storeId", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "storeId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeName", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "storeName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateConvenienceStoreTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateConvenienceStoreTask"), _class.prototype), _class);
});