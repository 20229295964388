define("client/pods/grapesjs/plugins/form", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = editor => {
    const bigordrForm = 'bigordr-form';
    const {
      DomComponents,
      BlockManager
    } = editor;
    const columnComponentClassName = 'bigordr-cell';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', bigordrForm])(editor);
    const blockId = `${bigordrForm}-block`;
    const {
      onDbClick,
      onDrop,
      intl = {
        t: txt => txt
      },
      isReadonly,
      onRender,
      categories,
      hasFormFlows = true
    } = options;
    const blockLabel = intl.t(bigordrForm);
    DomComponents.addType(bigordrForm, {
      model: {
        defaults: {
          draggable: `.${columnComponentClassName}`,
          droppable: false,
          name: blockLabel,
          classes: ['bigordr-form'],
          attributes: {
            'data-gjs-component': 'bigordr-form'
          },
          traits: [],
          style: {
            padding: '10px'
          }
        },
        updated(property, value) {
          if (property === 'attributes') {
            const formName = value?.content;
            const textNode = document.createTextNode(formName);
            this.view.el.innerText = '';
            this.view.el.appendChild(textNode);
          }
        }
      },
      view: {
        events: {
          dblclick: 'onDbClick'
        },
        onDbClick() {
          const parentType = this.model.parent().get('type');
          if (onDbClick && !isReadonly && parentType !== 'bigordr-form') {
            onDbClick(this.model);
          }
        },
        onRender() {
          const formName = this.model.getAttributes()['data-gjs-form-name'];
          if (formName) {
            const textNode = document.createTextNode(formName);
            this.el.appendChild(textNode);
          }
          if (onRender) {
            onRender(this.model);
          }
        }
      }
    });
    if (hasFormFlows) {
      BlockManager.add(blockId, {
        label: blockLabel,
        category: categories.basic,
        content: {
          type: bigordrForm,
          draggable: `.${columnComponentClassName}`
        },
        render: ({
          model
        }) => `
      <div class="gjs-block__media">
        <svg id="SvgjsSvg1012" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"><defs id="SvgjsDefs1013"></defs><g id="SvgjsG1014"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="88" height="58"><path d="m392 361c-66.167969 0-120 54.28125-120 121h-242v-391.011719h242v90.011719h90v36.28125c-26.460938 11.597656-45 38.027344-45 68.71875 0 41.355469 33.644531 75 75 75s75-33.644531 75-75c0-16.847656-5.585938-32.414062-15-44.949219v-241.050781h-392v61c-39.5625 0-24.207031 0-60 0v451h512v-30c0-66.71875-53.832031-121-120-121zm-90-210v-38.78125c12.527344 12.53125 26.273438 26.285156 38.746094 38.78125zm135 135c0 24.8125-20.1875 45-45 45s-45-20.1875-45-45 20.1875-45 45-45 45 20.1875 45 45zm-347-256h332v187.28125c-9.195312-4.027344-19.335938-6.28125-30-6.28125v-51.070312c-4.976562-4.984376-98.789062-98.921876-98.789062-98.921876-40.8125 0-142.957032.003907-203.210938.003907zm212 452c0-45.011719 32.488281-82.488281 75-89.730469v58.730469h30v-58.730469c42.511719 7.242188 75 44.71875 75 89.730469zm0 0" fill="#000000" class="color000 svgShape"></path><path d="M60 211h242v30h-242zm0 0M60 271h212v30h-212zm0 0M60 331h212v30h-212zm0 0M60 391h182v30h-182zm0 0M120 151h92v30h-92zm0 0" fill="#000000" class="color000 svgShape"></path></svg></g></svg>
        <div class="block-label">${model.get('label')}</div>
        </div>
        `
      });
    }
    editor.on('block:drag:stop', component => {
      if (component && component.is(bigordrForm)) {
        editor.select(component);
        if (onDrop) {
          onDrop(component);
        }
      }
    });
  };
  _exports.default = _default;
});