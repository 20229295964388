define("client/pods/reports/lists/contact-tags/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'uniqueTagsOnly',
        label: 'uniqueTagsOnly'
      }, {
        value: 'combinedTagsOnly',
        label: 'combinedTagsOnly'
      }, {
        value: 'allTags',
        label: 'allTags'
      }];
      return _nventor.default.resolve(data);
    }
  });
});