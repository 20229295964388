define("client/pods/components/channels/shop/campaigns/page-import-btn/component", ["exports", "client/mixins/crud", "client/utils/nventor", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _crud, _nventor, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tagName: 'span',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/pageImportBtnSubPanels']);
      const productsFilter = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList'
        }
      });
      const campaignsDetailsFilter = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          resultsProperty: 'campaignsDetailsList'
        }
      });
      const campaignsFilter = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters',
        defaultFiltersData: {
          resultsProperty: 'campaignsList'
        }
      });
      this.set('productsFilter', productsFilter);
      this.set('campaignsDetailsFilter', campaignsDetailsFilter);
      this.set('campaignsFilter', campaignsFilter);
    },
    pageImportBtnSubPanels: Ember.computed('crud.lists.channels-shop-campaigns-lists-pageImportBtnSubPanels.[]', 'productsList.[]', 'campaignsDetailsList.[]', 'campaignsList.[]', function () {
      const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-pageImportBtnSubPanels')(this) || [];
      if (!R.isEmpty(subPanels)) {
        const productsList = this.productsList || [];
        const campaignsList = this.campaignsList || [];
        const campaignsDetailsList = this.campaignsDetailsList || [];
        return R.map(panel => {
          if (panel._data.value === 'products') {
            panel.setData('badge', productsList.length);
          }
          if (panel._data.value === 'campaigns') {
            panel.setData('badge', campaignsList.length);
          }
          if (panel._data.value === 'campaignsDetails') {
            panel.setData('badge', campaignsDetailsList.length);
          }
          return panel;
        }, subPanels);
      }
      return subPanels;
    }),
    searchTask: (0, _emberConcurrency.task)(function* () {
      const q = [];
      const query = this.query || '';
      q.push(this._searchTask.perform({
        adapterName: 'channels/shop/campaigns/templates',
        resultsProperty: 'campaignsList',
        query,
        filters: this.campaignsFilter
      }));
      q.push(this._searchTask.perform({
        adapterName: 'channels/shop/campaigns/details/templates',
        resultsProperty: 'campaignsDetailsList',
        query,
        filters: this.campaignsDetailsFilter
      }));
      q.push(this._searchTask.perform({
        adapterName: 'products',
        resultsProperty: 'productsList',
        query,
        filters: this.productsFilter
      }));
      yield (0, _emberConcurrency.all)(q);
    }),
    _searchTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      query,
      filters,
      resultsProperty
    }) {
      const results = yield this.searchRecordsConcurrentlyTask.perform({
        adapterName,
        query,
        filters
      });
      this.set(resultsProperty, results);
      return results;
    }).enqueue(),
    actions: {
      replaceTemplate(onCloseDialogAction, template, templateImages = []) {
        const model = this.model;
        model.replaceTemplate(template, templateImages);
        this.onUpdateContent(model.getData('template'));
        onCloseDialogAction();
      },
      insertTemplate(onCloseDialogAction, template, templateImages = []) {
        const model = this.model;
        model.insertTemplate(template, templateImages);
        this.onUpdateContent(model.getData('template'));
        onCloseDialogAction();
      }
    }
  });
});