define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/document-display/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwEInvoicesPrintManagerDocumentDisplayComponent = _exports.default = (_dec = Ember.inject.service('big'), _dec2 = Ember.inject.service('constants'), _dec3 = Ember._tracked, _dec4 = Ember._action, _class = class ExtensionsAppsTwEInvoicesPrintManagerDocumentDisplayComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "bigService", _descriptor, this);
      _initializerDefineProperty(this, "constantsService", _descriptor2, this);
      _initializerDefineProperty(this, "showAllDetails", _descriptor3, this);
    }
    toggleAllDetails() {
      let showAllDetails = this.showAllDetails;
      if (showAllDetails) {
        showAllDetails = false;
      } else {
        showAllDetails = true;
      }
      this.showAllDetails = showAllDetails;
      const results = this?.args?.results || [];
      R.forEach(document => {
        document.set('showDetails', showAllDetails);
      })(results);
    }
    get notYetInvoicedTotals() {
      const document = this?.args?.document || {};
      if (RA.isNilOrEmpty(document)) {
        return 0;
      }
      const netInclTotals = document?._data?.netInclTotals || 0;
      const eInvoicesTotals = document?._data?.eInvoicesTotals || {};
      if (RA.isNilOrEmpty(eInvoicesTotals)) {
        return netInclTotals;
      }
      let eInvoicesTotalInvoiced = this.bigService.newBig(0);
      R.forEachObjIndexed((eInvoicesTotal, eInvoicesStatus) => {
        if (eInvoicesStatus === this.constantsService.eInvoicesStatus.cancelled) {
          return;
        }
        if (eInvoicesStatus === this.constantsService.eInvoicesStatus.pendingCancellation) {
          return;
        }
        eInvoicesTotalInvoiced = eInvoicesTotalInvoiced.plus(eInvoicesTotal?.invoicedTotal);
      })(eInvoicesTotals);
      return this.bigService.newBig(netInclTotals).minus(eInvoicesTotalInvoiced).toFixed();
    }
    get docEInvoiceStatus() {
      const document = this?.args?.document || {};
      const documentStatus = document?._data?.status || '';
      const netInclTotals = document?._data?.netInclTotals || 0;
      const eInvoicesTotals = document?._data?.eInvoicesTotals || {};
      const eInvoices = document?._data?.eInvoices || [];
      if (documentStatus === 'void') {
        return '';
      }
      const dispatchStatus = document?._data?.dispatchStatus || '';
      if (netInclTotals === this.notYetInvoicedTotals) {
        if (dispatchStatus === this.constantsService.dispatchStatus.dispatched) {
          // return danger when alr dispatched
          // return 'not yet invoiced'
          return 'tw_eInvoices_numbers_no_available_count';
        }

        // no status when not yet invoiced (but not dispatched)
        return '';
      }

      // always return danger when has attentionRequired
      const attentionRequiredInvoicedTotal = eInvoicesTotals?.attentionRequired?.invoicedTotal || 0;
      if (attentionRequiredInvoicedTotal) {
        return 'tw_eInvoices_attention_required';
      }

      // check when has pendingCancellation
      const pendingCancellationInvoicedTotal = eInvoicesTotals?.pendingCancellation?.invoicedTotal || 0;
      const cancelledInvoicedTotal = eInvoicesTotals?.cancelled?.invoicedTotal || 0;
      const successfulInvoicedTotal = eInvoicesTotals?.successful?.invoicedTotal || 0;
      if (successfulInvoicedTotal === netInclTotals) {
        // check when has pendingCancellation
        if (pendingCancellationInvoicedTotal !== 0) {
          return 'pendingCancellation';
        }
        return 'successful';
      }

      // danger when has pending cancellation or cancelled but no new eInvoices
      if (this.notYetInvoicedTotals > 0 && (pendingCancellationInvoicedTotal || cancelledInvoicedTotal)) {
        return 'have not reissued e-invoices';
      }

      // warning if have pending
      const pendingInvoicedTotal = eInvoicesTotals?.pending?.invoicedTotal || 0;
      if (pendingInvoicedTotal) {
        return 'pending';
      }
      return 'successful';
    }
    get docEInvoiceStatusClassNames() {
      const docEInvoiceStatus = this.docEInvoiceStatus;
      const warningStatus = ['pending', 'pendingCancellation'];
      const dangerStatus = ['tw_eInvoices_numbers_no_available_count', 'tw_eInvoices_attention_required', 'have not reissued e-invoices'];
      const okStatus = ['successful'];
      if (R.includes(docEInvoiceStatus, warningStatus)) {
        return 'has-text-warning';
      }
      if (R.includes(docEInvoiceStatus, dangerStatus)) {
        return 'has-text-danger';
      }
      if (R.includes(docEInvoiceStatus, okStatus)) {
        return '';
      }
      return '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "bigService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constantsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showAllDetails", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleAllDetails", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllDetails"), _class.prototype), _class);
});