define("client/pods/components/channels/shop/promo-codes/search-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    init() {
      this.crud.addLists(this, ['channels/shop/promo-codes/lists/filters/search', 'channels/shop/promo-codes/lists/filters/sort', 'discounts/types', 'filters/count', 'filters/sortDirection']);
      this._super(...arguments);
    }
  });
});