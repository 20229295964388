define("client/pods/components/workflows/related/editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HhU/kAy3",
    "block": "{\"symbols\":[\"related\",\"related\",\"@isReadonly\",\"@model\"],\"statements\":[[4,\"if\",[[23,4,[\"_data\",\"related\"]]],null,{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[4,\"each\",[[23,4,[\"_data\",\"related\"]]],null,{\"statements\":[[0,\"      \"],[5,\"workflows/related/editor/detail-searcher\",[],[[\"@related\",\"@isReadonly\"],[[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"table\",true],[10,\"class\",\"u-table table-form u-full-width\"],[8],[0,\"\\n      \"],[5,\"workflows/related/editor/detail-editor\",[],[[\"@isHeader\",\"@model\",\"@isReadonly\"],[true,[23,4,[]],[23,3,[]]]]],[0,\"\\n\"],[4,\"each\",[[23,4,[\"_data\",\"related\"]]],null,{\"statements\":[[0,\"        \"],[5,\"workflows/related/editor/detail-editor\",[],[[\"@model\",\"@related\",\"@isReadonly\"],[[23,4,[]],[23,1,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@classNames\",\"@icon\",\"@disabled\",\"@onSubmit\"],[\"add\",\"u-margin-top-10\",\"fas fa-plus\",[23,3,[]],[28,\"fn\",[[23,4,[\"addRelated\"]],\"\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/related/editor/template.hbs"
    }
  });
});