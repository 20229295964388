define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MoYj4ugG",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"channels/shop/campaigns/campaigns-item-editor\",null,[[\"tab\",\"campaign\",\"item\",\"errors\",\"isEditing\",\"isAutomations\",\"product\",\"limitedQty\",\"isNew\",\"saveTask\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"campaign\"]],[24,[\"dirty\"]],[24,[\"errors\"]],true,true,[24,[\"product\"]],[24,[\"campaign\",\"isMayAdd\"]],false,[24,[\"updateRecordTask\"]],[28,\"action\",[[23,0,[]],\"cancelEditing\",[28,\"hash\",null,[[\"dirty\"],[[24,[\"dirty\"]]]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-editor/template.hbs"
    }
  });
});