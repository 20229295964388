define("client/pods/components/websites/panels-display/sub-panels-pages/nav-menu-nested/nav-nested/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: 'div',
    classNames: ['nav-nested'],
    translate: true,
    item: null,
    isExpanded: false,
    childIsExpanded: false,
    disabled: false,
    isViewable: true,
    init() {
      this._super(...arguments);
      this.set('childIsExpanded', this.isExpanded);
      const selected = this.selected;
      const item = this.item;
      if (selected) {
        if (selected._data._key === item._data._key) {
          if (!this.disabled) {
            this.set('disabled', true);
          }
        }
      }
    },
    wasUpdated(isExpanded) {
      this.set('childIsExpanded', isExpanded);
    },
    draggable: Ember.computed('item._data.isHome', function () {
      if (this.get('item._data.isHome')) {
        return false;
      }
      return true;
    }),
    actions: {
      drop() {
        if (this.onDrop) {
          this.onDrop(...arguments);
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "wasUpdated", [_dec], Object.getOwnPropertyDescriptor(_obj, "wasUpdated"), _obj), _obj));
});