define("client/pods/grapesjs/plugins/ckeditor/index", ["exports", "grapesjs", "ramda"], function (_exports, _grapesjs, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global CKEDITOR */

  /**
   * @project: bigordr-app
   * @file: ckeditor
   * @author Nafis Rubio
   * @since 25/05/2022
   * @copyright (C) 2022 BigOrdr
   * @description: CKEditor plugin for grapesjs
   */

  const stopPropagation = e => e.stopPropagation();
  const EDITOR_REFRESH_TIMEOUT = 10;
  var _default = editor => {
    const ckeditorPlugin = 'ckeditor-plugin';
    const c = R.pathOr({}, ['Config', 'pluginsOpts', ckeditorPlugin])(editor);
    const {
      intl = {
        t: txt => txt
      }
    } = c;
    const defaults = {
      // CKEditor options
      options: {
        language: intl.t('ckeditor-language'),
        skin: 'moono-lisa',
        plugins: `a11yhelp,
        about,
        basicstyles,
        bidi,
        blockquote,
        button,
        clipboard,
        colorbutton,
        colordialog,
        contextmenu,
        copyformatting,
        dialog,
        dialogadvtab,
        dialogui,
        div,
        elementspath,
        enterkey,
        entities,
        fakeobjects,
        filebrowser,
        filetools,
        find,
        floatingspace,
        floatpanel,
        font,
        format,
        forms,
        horizontalrule,
        htmlwriter,
        iframe,
        image,
        indent,
        indentblock,
        indentlist,
        justify,
        language,
        lineutils,
        link,
        list,
        listblock,
        liststyle,
        magicline,
        maximize,
        menu,
        menubutton,
        newpage,
        notification,
        notificationaggregator,
        pagebreak,
        panel,
        panelbutton,
        pastefromword,
        pastetext,
        popup,
        preview,
        print,
        removeformat,
        resize,
        richcombo,
        save,
        scayt,
        selectall,
        showblocks,
        showborders,
        smiley,
        sourcearea,
        specialchar,
        stylescombo,
        tab,
        table,
        tableselection,
        tabletools,
        templates,
        toolbar,
        undo,
        uploadimage,
        uploadwidget,
        widget,
        widgetselection,
        wysiwygarea`,
        font_defaultLabel: 'Arial',
        toolbar: [{
          name: 'basicstyles',
          items: ['Undo', 'Redo', '-', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat']
        }, {
          name: 'paragraph',
          items: ['Outdent', 'Indent', '-', 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
        }, {
          name: 'links',
          items: ['Link', 'Unlink']
        }, {
          name: 'insert',
          items: ['Table']
        }, '/', {
          name: 'styles',
          items: []
        }, {
          name: 'colors',
          items: ['TextColor', 'BGColor']
        }]
      },
      // On which side of the element to position the toolbar
      // Available options: 'left|center|right'
      position: 'left'
    };

    // Load defaults
    for (const name in defaults) {
      if (!(name in c)) {
        c[name] = defaults[name];
      }
    }
    if (!CKEDITOR) {
      throw new Error('CKEDITOR instance not found');
    }
    const refreshEditor = () => setTimeout(() => editor.refresh(), EDITOR_REFRESH_TIMEOUT);
    editor.setCustomRte({
      enable(el, rte) {
        // If already exists I'll just focus on it
        if (rte && rte.status !== 'destroyed') {
          this.focus(el, rte);
          return rte;
        }
        el.contentEditable = true;

        // Seems like 'sharedspace' plugin doesn't work exactly as expected
        // so will help hiding other toolbars already created
        const rteToolbar = editor.RichTextEditor.getToolbarEl();
        [].forEach.call(rteToolbar.children, child => {
          child.style.display = 'none';
        });

        // Check for the mandatory options
        const opt = c.options;
        const plgName = 'sharedspace';
        if (opt.extraPlugins) {
          if (typeof opt.extraPlugins === 'string') {
            opt.extraPlugins += ',' + plgName;
          } else {
            opt.extraPlugins.push(plgName);
          }
        } else {
          opt.extraPlugins = plgName;
        }
        if (!c.options.sharedSpaces) {
          c.options.sharedSpaces = {
            top: rteToolbar
          };
        }

        // Init CkEditors
        rte = CKEDITOR.inline(el, c.options);

        /**
         * Implement the `rte.getContent` method so that GrapesJS is able to retrieve CKE's generated content (`rte.getData`) properly
         *
         * See:
         *  - {@link https://github.com/artf/grapesjs/issues/2916}
         *  - {@link https://github.com/artf/grapesjs/blob/dev/src/dom_components/view/ComponentTextView.js#L80}
         *  - {@link https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_editor.html#method-getData}
         */
        rte.getContent = rte.getData;

        // Make click event propogate
        rte.on('loaded', () => {
          refreshEditor();
        });
        // Make click event propogate
        rte.on('contentDom', () => {
          const editable = rte.editable();
          editable.attachListener(editable, 'click', () => {
            el.click();
          });
        });

        // The toolbar is not immediatly loaded so will be wrong positioned.
        // With this trick we trigger an event which updates the toolbar position
        rte.on('instanceReady', e => {
          const toolbar = rteToolbar.querySelector('#cke_' + rte.name);
          if (toolbar) {
            toolbar.style.display = 'block';
          }
          editor.trigger('canvasScroll');
        });

        // Prevent blur when some of CKEditor's element is clicked
        rte.on('dialogShow', e => {
          const editorEls = _grapesjs.default.$('.cke_dialog_background_cover, .cke_dialog');
          ['off', 'on'].forEach(m => editorEls[m]('mousedown', stopPropagation));
        });
        this.focus(el, rte);
        return rte;
      },
      disable(el, rte) {
        el.contentEditable = false;
        if (rte && rte.focusManager) {
          rte.focusManager.blur(true);
        }
      },
      focus(el, rte) {
        refreshEditor();
        // Do nothing if already focused
        if (rte && rte.focusManager && rte.focusManager.hasFocus) {
          return;
        }
        el.contentEditable = true;
        rte && rte.focus();
      }
    });

    // Update RTE toolbar position
    editor.on('rteToolbarPosUpdate', pos => {
      switch (c.position) {
        case 'center':
          pos.left = pos.elementLeft + (pos.elementWidth >> 1) - (pos.targetWidth >> 1);
          break;
        case 'right':
          pos.left = pos.elementLeft + pos.elementWidth - pos.targetWidth;
          break;
        default:
          break;
      }
      if (pos.top <= pos.canvasTop) {
        pos.top = pos.elementTop + pos.elementHeight;
      }

      // Check if not outside of the canvas
      if (pos.left < pos.canvasLeft) {
        pos.left = pos.canvasLeft;
      }
    });
  };
  _exports.default = _default;
});