define("client/pods/components/transporters/t-cat-waybill/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor", "client/pods/options/model"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersTCatWaybillComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('dispatch'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _class = class TransportersTCatWaybillComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "server", _descriptor2, this);
      _initializerDefineProperty(this, "date", _descriptor3, this);
      _initializerDefineProperty(this, "settings", _descriptor4, this);
      _initializerDefineProperty(this, "dispatchService", _descriptor5, this);
      _initializerDefineProperty(this, "printType", _descriptor6, this);
      _initializerDefineProperty(this, "transporterModel", _descriptor7, this);
      _defineProperty(this, "adapterNameContacts", 'contacts');
      _defineProperty(this, "transporterExtension", 't-cat');
      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/waybill-print-type']);
      this.fetchTransporterTask.perform();
    }
    get transporterAccountList() {
      const allOption = _nventor.default.createModel(_model.default, {
        label: 'all',
        value: 'all'
      });
      return R.pipe(R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-t-cat-lists-thermosphere']), R.concat([allOption]))(this);
    }
    get tCatDocs() {
      const tCatKey = this?.dispatchService?.tCatModel?._data?._key;
      return R.pipe(R.pathOr([], ['args', 'results']), R.filter(R.pathEq(['_data', 'transporterKey'], tCatKey)))(this);
    }
    get tCatDocsProxy() {
      return R.map(model => ({
        model
      }))(this.tCatDocs);
    }
    get tCatDocsDisplayList() {
      const tCatKey = this?.dispatchService?.tCatModel?._data?._key;
      return R.filter(R.anyPass([R.pathEq(['args', 'dispatchedResults', 'model', '_data', 'transporterExtension'], this.transporterExtension), R.pathEq(['_data', 'transporterKey'], tCatKey)]))(this);
    }
    *generateAndDownloadWaybillTask() {
      return yield this.dispatchService.tCatGenerateAndDownloadWaybillTask.perform({
        component: this,
        documents: this.tCatDocs,
        printType: this.printType
      });
    }
    *fetchTransporterTask() {
      this.transporterModel = yield this.dispatchService.tCatGetModel.perform();

      // not exists in transporterModel anymore, use settings
      const defaultTCatTransporterWaybillPrintType = this.settings.getProp('defaultTCatTransporterWaybillPrintType');
      this.printType = defaultTCatTransporterWaybillPrintType;
      if (RA.isNilOrEmpty(this.printType)) {
        this.printType = 'twoPerPage';
      }
      if (this?.args?.onSetPrintType) {
        this.args.onSetPrintType(this.printType);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "printType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generateAndDownloadWaybillTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "generateAndDownloadWaybillTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype), _class);
});