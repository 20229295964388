define("client/pods/components/reports/dashboard/card-page-views/card-detail/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    isDate: false,
    roundingCurrency: 0,
    showMoreDetails: false,
    showMoreTracking: false,
    getExcl(param, type) {
      return this.get(`${param}.${type}`);
    },
    getIncl(param, type) {
      return this.get(`${param}.${type}`);
    },
    getValue(param, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(param, exclParam) : this.getIncl(param, inclParam);
    },
    amount: Ember.computed('detail.{exclAmt,inclAmt}', 'isTaxExclusive', function () {
      return this.getValue('detail', 'exclAmt', 'inclAmt');
    }),
    choiceDetailsProxy: Ember.computed('detail.choiceDetails.[]', 'isTaxExclusive', function () {
      const choiceDetails = this.detail.choiceDetails || [];
      return R.map(choiceDetail => {
        if (this.isTaxExclusive) {
          choiceDetail.amount = choiceDetail.exclAmt;
        } else {
          choiceDetail.amount = choiceDetail.inclAmt;
        }
        return choiceDetail;
      })(choiceDetails);
    })

    // actions: {
    //   toggleDetails () {
    //     const details = R.path(['detail', 'choiceDetails'])(this)
    //     if (!R.isEmpty(details)) {
    //       this.toggleProperty('showDetails')
    //     }
    //   }
    // }
  });
});