define("client/pods/components/elements/btn-cancel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    label: '',
    tooltipLabel: 'cancel',
    btnClassNames: '',
    icon: 'fas fa-ban'
  });
});