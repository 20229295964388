define("client/pods/components/websites/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6sydyxe5",
    "block": "{\"symbols\":[\"@errors\",\"@onDash\",\"@tab\"],\"statements\":[[5,\"websites/websites-editor\",[],[[\"@errors\",\"@model\",\"@isEditing\",\"@isNew\",\"@onDash\",\"@onCancel\",\"@onSetAsDefaultImageTask\",\"@saveTask\",\"@tab\"],[[23,1,[]],[23,0,[\"dirty\"]],true,false,[23,2,[]],[28,\"action\",[[23,0,[]],\"cancel\",[24,[\"dirty\"]]],null],[22,\"onSetAsDefaultImageTask\"],[22,\"updateRecordTask\"],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-edit/template.hbs"
    }
  });
});