define("client/pods/drag-drop-sort/service", ["exports", "ramda-adjunct"], function (_exports, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let DragDropSortService = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _class = class DragDropSortService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "indexProp", '_data.index');
      _defineProperty(this, "checkingIndexProp", '_data.index');
    }
    dragStartItem(component, detail) {
      component.dragDetail = detail;
    }
    dragOverItem(component, detail, dragItem) {
      dragItem = dragItem || component.dragDetail;
      console.log('--dragItem', dragItem);
      if (_ramdaAdjunct.default.isNilOrEmpty(dragItem)) {
        console.log('no drag item!!');
        return '';
      }
      const checkingIndexProp = this?.checkingIndexProp || this?.indexProp || '_data.index';
      console.log('checkingIndexProp', checkingIndexProp);
      const dragIndex = dragItem.get(checkingIndexProp);
      console.log('dragIndex', dragIndex);
      const onOverItemIndex = detail.get(checkingIndexProp);
      console.log('onOverItemIndex', onOverItemIndex);

      // not sure if dragIndex is undefined should it become 'insert-above'?
      if (dragIndex === onOverItemIndex) {
        console.log('none');
        component.dropPosition = '';
      } else if (dragIndex < onOverItemIndex) {
        console.log('-below');
        component.dropPosition = 'insert-below';
      } else {
        console.log('-above');
        component.dropPosition = 'insert-above';
      }
      return component.dropPosition || '';
    }
    dragOverItemHorizontally(component, detail, dragItem) {
      dragItem = dragItem || component.dragDetail;
      const checkingIndexProp = this?.checkingIndexProp || this?.indexProp || '_data.index';
      const dragIndex = dragItem.get(checkingIndexProp);
      const onOverItemIndex = detail.get(checkingIndexProp);
      if (dragIndex === onOverItemIndex) {
        component.dropPosition = '';
      } else if (dragIndex < onOverItemIndex) {
        component.dropPosition = 'insert-right';
      } else {
        component.dropPosition = 'insert-left';
      }
      return component.dropPosition || '';
    }
  }, _applyDecoratedDescriptor(_class.prototype, "dragStartItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dragStartItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOverItem", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dragOverItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOverItemHorizontally", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "dragOverItemHorizontally"), _class.prototype), _class);
});