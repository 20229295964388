define("client/pods/components/contacts/panels-new/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'contacts',
    init() {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      this.crud.addLists(this, ['contacts/lists/panels']);
      const relationships = this.get('tab.relationships') || this.relationships;
      const name = this.name || '';
      const settingsModel = this.settings.getModel();
      const data = {
        name,
        relationships: relationships,
        salesPaymentMethodKey: settingsModel.getData('salesPaymentMethodKey', this.get('constants.paymentMethods.cash')),
        salesTaxMethodKey: this.get('constants.taxMethods.auto'),
        purchasesPaymentMethodKey: settingsModel.getData('purchasesPaymentMethodKey', this.get('constants.paymentMethods.cash')),
        purchasesTaxMethodKey: this.get('constants.taxMethods.auto'),
        status: this.get('constants.status.active'),
        invoicing: this.get('constants.invoicing.auto'),
        isTransporter: this.get('constants.isTransporter.off')
      };
      const model = this.setupNewRecord({
        data
      });
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    }
  });
});