define("client/pods/components/layouts/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LyN4dIS6",
    "block": "{\"symbols\":[\"@title\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"layouts-section\"],[13,2],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[[23,1,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/section/template.hbs"
    }
  });
});