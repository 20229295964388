define("client/pods/components/documents/links-conflict/notes-conflict/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    isSelected: Ember.computed('conflictCategory.selected.[]', function () {
      const value = this.valueGroup;
      return this.get(`conflictCategory.selected`).get('firstObject') === value;
    })
  });
});