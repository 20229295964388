define("client/pods/websites/service", ["exports", "ember-concurrency-decorators", "client/config/environment", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, _environment, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WebsitesService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _class = class WebsitesService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "server", _descriptor3, this);
      _initializerDefineProperty(this, "constants", _descriptor4, this);
      _initializerDefineProperty(this, 'all', _descriptor5, this);
    }
    getPreviewLink({
      model,
      website,
      dataTabContainer,
      isPreview = false
    } = {}) {
      if (dataTabContainer === 'campaigns') {
        return `${_environment.default.APP.shopUrl}/${model.getData('url')}`;
      }
      let url;
      if (_environment.default.environment === 'development') {
        url = _environment.default.APP.bigordrHost;
      } else {
        url = 'https://';
        if (website.getData('domainVerified')) {
          // if domain verified, use domain
          url += `${website.getData('domain')}`;
        } else {
          // if domain not verified, use subdomain
          url += `${website.getData('subdomain')}.`;
          url += _environment.default.APP.websiteHost;
        }
      }
      if (model.getData('url')) {
        url += `/${model.getData('url')}`;
      }
      if (isPreview) {
        url += '?preview=true';
      }
      return url;
    }
    *searchInputTask(args) {
      let {
        query = '',
        resultsProperty = '',
        adapterName = 'websites',
        filtersAdapterName = 'websites/filters',
        component,
        resultsToggle = false,
        global = true,
        filters
      } = args;
      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: filtersAdapterName,
          defaultFiltersData: {
            query
          }
        });
      }
      const results = yield this.crud.searchInputTask.perform({
        adapterName,
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle
      });
      if (global && resultsProperty) {
        this[resultsProperty] = results;
      }
      return results;
    }

    /**
     * fetches the page collections. the collection will sync update when
     * new pages are added.
     * @param {Object} website model of the website
     * @returns
     */
    *fetchPagesCollectionTask(website) {
      const collectionModel = yield this.crud.findTask.perform({
        adapterName: 'websites/pages/collection',
        appendPath: website.getData('_key')
      });
      return collectionModel;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, 'all', [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPreviewLink", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getPreviewLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPagesCollectionTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPagesCollectionTask"), _class.prototype), _class);
});