define("client/pods/components/elements/accordian-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BemCZ5Dq",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@container\",\"@isModal\"],\"statements\":[[4,\"if\",[[23,3,[\"toggleIsOn\"]]],null,{\"statements\":[[4,\"if\",[[23,3,[\"isAccordian\"]]],null,{\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[5,\"modals/modal-container-new\",[],[[\"@onToggleModalDialog\"],[[28,\"perform\",[[23,3,[\"toggleAccordianTask\"]],20],null]]],{\"statements\":[[0,\"\\n        \"],[14,2],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"accordian-dialog\"],[13,1],[8],[0,\"\\n        \"],[14,2],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"\"],[13,1],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/accordian-dialog/template.hbs"
    }
  });
});