define("client/pods/components/grapesjs/schedules-modal/schedules-dialog/component", ["exports", "@glimmer/component", "client/utils/nventor"], function (_exports, _component, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let GrapesjsSchedulesModalSchedulesDialogComponent = _exports.default = (_dec = Ember._action, _class = class GrapesjsSchedulesModalSchedulesDialogComponent extends _component.default {
    onSubmitAddSchedule(scheduleModel) {
      scheduleModel.addSchedule({
        scheduleId: _nventor.default.random.alphaNum(18)
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onSubmitAddSchedule", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddSchedule"), _class.prototype), _class);
});