define("client/pods/components/msgs/footer-msgs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K5aoyOyG",
    "block": "{\"symbols\":[\"@model\",\"@errors\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"footer-msgs__container\"],[8],[0,\"\\n  \"],[5,\"msgs/deleted-error\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\\n  \"],[5,\"msgs/error-badge\",[],[[\"@errors\"],[[23,2,[]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/msgs/footer-msgs/template.hbs"
    }
  });
});