define("client/pods/components/modals/modal-dialog/modal-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FcbcSejG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-dialog__footer-spacer u-no-print\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-dialog__footer u-no-print has-text-left\"],[8],[0,\"\\n  \"],[14,1,[[28,\"action\",[[23,0,[]],\"printDialog\"],null]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-dialog/modal-footer/template.hbs"
    }
  });
});