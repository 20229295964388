define("client/pods/components/admin/extensions/apps/tw/e-invoices/panels-display/component", ["exports", "client/mixins/date", "client/mixins/crud", "client/mixins/e-invoices-list-by", "ember-concurrency"], function (_exports, _date, _crud, _eInvoicesListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import EmberObject from '@ember/object'
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, _eInvoicesListBy.default, {
    count: 20,
    page: 1,
    hasStatement: false,
    init() {
      this._super(...arguments);
      const period = this.get('tab.period');
      this.set('period', period);

      // const filters = this.search.setupFilters({
      //   adapterName: this.get('filtersAdapterName')
      // })
      // this.set('filters', filters)

      // const { dateStart, dateEnd } = period
      // const status = ['all']
      // const adapterName = 'admin/extensions/apps/tw/e-invoices'
      // this.get('listByTask').perform({ adapterName, dateStart, dateEnd, status })

      if (this.hasStatement) {
        this.fetchStatementTask.perform(period);
      }
    },
    fetchStatementTask: (0, _emberConcurrency.task)(function* (period) {
      period.provider = this.provider;
      const response = yield this.server.call('GET', 'api/protected/admin/extensions/apps/tw/e-invoices/statement', period);
      this.set('statements', response.data);
      return response.data;
    })
  });
});