define("client/pods/extensions/apps/tw/e-invoices/printer/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ExtensionsAppsTwEInvoicesPrinterModel extends _model.default {
    get printerLastSix() {
      return R.pipe(R.pathOr('', ['_data', 'printerId']), R.takeLast(6))(this);
    }
    get printerIdLastSix() {
      const label = this._data?.label;
      if (label) {
        return label;
      }
      return `ID: ${this.printerLastSix}`;
    }
  }

  // export default BaseModel.extend({})
  _exports.default = ExtensionsAppsTwEInvoicesPrinterModel;
});