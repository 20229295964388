define("client/pods/components/flows/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QVEaSdmY",
    "block": "{\"symbols\":[\"@onDisplay\",\"@tab\",\"@onDash\"],\"statements\":[[5,\"flows/flows-editor\",[],[[\"@model\",\"@errors\",\"@onSaveTask\",\"@isNew\",\"@tab\",\"@onCancel\",\"@isRunning\"],[[23,0,[\"model\"]],[23,0,[\"errors\"]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\",\"component\"],[[23,0,[\"adapterName\"]],[23,0,[\"model\"]],[23,1,[]],[23,0,[]]]]]],null],true,[23,2,[]],[23,3,[]],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/panels-new/template.hbs"
    }
  });
});