define("client/pods/grapesjs/plugins/full-width-image", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global grapesjs */

  const pluginName = 'full-width-image';
  const UPLOADCARE_TRANSFORMS = ''; // '/-/format/webp/-/quality/smart/-/preview/'
  const Plugin = grapesjs.plugins.add(pluginName, (editor, options = {}) => {
    const components = editor.DomComponents;
    const pluginOptions = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl = {
        t: foo => foo
      },
      /** @type {Categories} */
      categories
    } = pluginOptions;
    const blockLabel = intl.t(pluginName);
    const styles = `
    <style>
      .full-width-image {
        width: 100%;
      }
    </style>
  `;
    const getUploadcareUuid = R.pipe(R.split(/https:\/\/ucarecdn\.com\//), R.pathOr('', [1]), R.split('/'), R.pathOr('', [0]));
    const defaultOptions = {
      extend: 'image',
      model: {
        defaults: {
          resizable: false,
          components: [styles],
          classes: [pluginName],
          attributes: {
            type: pluginName
          },
          name: blockLabel,
          toolbar: [{
            attributes: {
              class: 'fa fa-arrow-up'
            },
            command: 'select-parent'
          }, {
            attributes: {
              class: 'fa fa-arrows'
            },
            command: 'tlb-move'
          }, {
            attributes: {
              class: 'fa fa-clone'
            },
            command: 'tlb-clone'
          }, {
            attributes: {
              class: 'fa fa-trash'
            },
            command: 'tlb-delete'
          }],
          traits: [{
            type: 'bigordr-animation-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }, {
            type: 'bigordr-link-trait'
          }]
        },
        init() {
          if (this?.parent()?.get('type') !== 'bigordr-picture') {
            // TODO: REMOVE UNUSED IMAGE ELEMENTS FROM CANVAS
            // debugger
            // this.remove()
          }
        }
      },
      view: {
        init() {
          // TODO: REMOVE UNUSED IMAGE ELEMENTS FROM CANVAS
          // console.log('Local hook: view.init')
        },
        onRender({
          model,
          editor,
          el
        }) {
          model.removeStyle('width');
          // add id as class name. so, style can be added to a specific image
          const className = `id-${model.getId()}`;
          model.addClass(className);

          // append parent id
          const isCopy = model.get('isCopy');
          const parentId = model.get('data-parent-id');
          const isParentTypeBigordrPicture = model.parent().get('type') === 'bigordr-picture';
          if (isParentTypeBigordrPicture && !parentId) {
            // get picture id
            model.set('data-parent-id', model.parent().getId());
          } else if (isParentTypeBigordrPicture && parentId && isCopy) {
            // RESET PARENT ID
            model.set('data-parent-id', model.parent().getId());
          } else if (parentId && !isParentTypeBigordrPicture) {
            el.setAttribute('data-remove-now', 'true');
            el.style.display = 'none';
            // move parent to here
            const parentId = model.get('data-parent-id');
            const parentPictureArray = editor.Components.getComponent().find(`#${parentId}`);
            if (RA.isNilOrEmpty(parentPictureArray)) {
              return;
            }
            const parentPicture = R.path([0])(parentPictureArray);
            const imageAttributes = model.getAttributes();
            const newPicture = model.replaceWith({
              desktopUrl: parentPicture.get('desktopUrl'),
              desktopUuid: parentPicture.get('desktopUuid'),
              tabletUrl: parentPicture.get('tabletUrl'),
              tabletUuid: parentPicture.get('tabletUuid'),
              mobileUrl: parentPicture.get('mobileUrl'),
              mobileUuid: parentPicture.get('mobileUuid'),
              imageType: pluginName,
              imageAttributes: R.pick(['data-href-title', 'data-href', 'data-href-target'])(imageAttributes),
              type: 'bigordr-picture'
            });
            editor.refresh();
            editor.selectRemove(model);
            parentPicture.remove();
            model.remove();
            editor.refresh();
            const newImg = newPicture.findType(pluginName)[0];
            editor.select(newImg);
            const previousClasses = model.getClasses();
            R.forEach(className => newImg.addClass(className))(previousClasses);
          }
        }
      }
    };
    const openAssetsFn = {
      run(editor, sender, opts = {}) {
        if (!options.onOpenAssets) {
          return;
        }
        let imgType = R.path(['target', 'attributes', 'imgType'], opts) || R.path(['target', 'attributes', 'type'])(opts);
        let target = editor.getSelected() || opts.target;
        const targetType = target.get('type');
        if (targetType === 'bigordr-picture-layer-container') {
          const bigordrPictureArr = target.findType('bigordr-picture');
          if (RA.isNotNilOrEmpty(bigordrPictureArr)) {
            const bigordrPicture = R.nth(0)(bigordrPictureArr);
            target = bigordrPicture.findType('full-width-image')[0];
            imgType = R.path(['attributes', 'imageType'])(bigordrPicture);
          }
        }
        if (!imgType) {
          const attributes = target?.getAttributes();
          imgType = R.prop('imgType')(attributes) || R.propOr('background-img', 'data-imgType')(attributes);
        }

        /** @type{HTMLImageElement|HTMLPictureElement} */
        const elementTarget = target?.getEl();
        let image = elementTarget;
        if (elementTarget.tagName === 'PICTURE') {
          image = elementTarget.querySelector('img');
        }
        let src = '';
        if (image && image.getAttribute) {
          src = image.getAttribute('src') || '';
        }
        const imgSet = {
          imgType,
          desktopUrl: src,
          tabletUrl: src,
          mobileUrl: src
        };
        if (imgType === 'background-img') {
          // SET METADATA ON DOM ELEMENT
          imgSet.desktopUrl = image.getAttribute('data-image-url');
          imgSet.tabletUrl = image.getAttribute('data-image-url');
          imgSet.mobileUrl = image.getAttribute('data-image-url');
          imgSet.desktopUuid = image.getAttribute('data-image-uuid');
          imgSet.tabletUuid = image.getAttribute('data-image-uuid');
          imgSet.mobileUuid = image.getAttribute('data-image-uuid');
        } else {
          // SET PREVIOUS IMAGES
          imgSet.desktopUuid = getUploadcareUuid(src);
          imgSet.tabletUuid = getUploadcareUuid(src);
          imgSet.mobileUuid = getUploadcareUuid(src);
        }
        if (target?.parent()?.get('type') === 'bigordr-picture') {
          const pictureSourceDesktop = image.parentElement.querySelector('[data-gjs-type="picture-desktop"]');
          if (pictureSourceDesktop) {
            src = pictureSourceDesktop.getAttribute('srcset');
            imgSet.desktopUrl = src;
            imgSet.desktopUuid = getUploadcareUuid(imgSet.desktopUrl);
          }
          const pictureSourceTablet = image.parentElement.querySelector('[data-gjs-type="picture-tablet"]');
          if (pictureSourceTablet) {
            imgSet.tabletUrl = pictureSourceTablet.getAttribute('srcset');
            imgSet.tabletUuid = getUploadcareUuid(imgSet.tabletUrl);
          }
          const pictureSourceMobile = image.parentElement.querySelector('[data-gjs-type="picture-mobile"]');
          if (pictureSourceMobile) {
            imgSet.mobileUrl = pictureSourceMobile.getAttribute('srcset');
            imgSet.mobileUuid = getUploadcareUuid(imgSet.mobileUrl);
          }
        }
        options.onOpenAssets(imgType, {
          onImageSelect(type, url, mimeType, uploadsModel, args = {}) {
            const editor = opts?.target?.em?.getEditor() || args?.editor;
            let selectedComponent = editor.getSelected() || opts.target;
            selectedComponent.em.refreshCanvas();
            const targetType = selectedComponent.get('type');
            if (targetType === 'bigordr-picture-layer-container') {
              const bigordrPictureArr = selectedComponent.findType('bigordr-picture');
              const fullWidthImageArr = selectedComponent.findType('full-width-image');
              if (RA.isNotNilOrEmpty(bigordrPictureArr)) {
                const bigordrPicture = R.nth(0)(bigordrPictureArr);
                selectedComponent = bigordrPicture.findType('full-width-image')[0];
                imgType = R.path(['attributes', 'imageType'])(bigordrPicture);
              } else if (RA.isNotNilOrEmpty(fullWidthImageArr)) {
                selectedComponent = R.nth(0)(fullWidthImageArr);
              }
            }
            const bigordrPictureOptions = R.pathOr({}, ['attributes', 'bigordrPictureOptions'])(selectedComponent);
            if (!selectedComponent) {
              selectedComponent = opts.target;
            }
            if (!selectedComponent) {
              console.error('No selected component!!!');
              return;
            }
            editor.refresh();
            if (type === 'background-img') {
              // Handle background-img
              selectedComponent.addAttributes({
                'data-image-uuid': getUploadcareUuid(url),
                'data-image-url': url
              });
              selectedComponent.addStyle({
                'background-image': `url(${url})`
              });
              return;
            }
            const imgSet = R.prop('imgSet')(uploadsModel);
            mimeType = uploadsModel.getData('mimeType');
            const pictureData = {
              ...bigordrPictureOptions,
              desktopUrl: url,
              desktopUuid: uploadsModel.getData('uuid'),
              tabletUrl: url,
              tabletUuid: uploadsModel.getData('uuid'),
              mobileUrl: url,
              mobileUuid: uploadsModel.getData('uuid'),
              fileType: mimeType,
              imageType: type,
              type: 'bigordr-picture'
            };
            const selectedComponentParent = selectedComponent.parent();
            const isGif = R.includes('gif')(mimeType);
            if (isGif) {
              const attributes = {
                src: url,
                attributes: {
                  src: url,
                  'data-gjs-type': type,
                  'data-mime-type': mimeType,
                  imgType
                },
                type
              };
              if (!selectedComponentParent) {
                console.error('No selected component parent!!!');
                return;
              }
              if (selectedComponentParent.get('type') === 'bigordr-picture') {
                selectedComponentParent.replaceWith(attributes);
              } else {
                selectedComponent.replaceWith(attributes);
              }
              return;
            }
            // <img
            // data-gjs-type="optimized-image"
            // draggable="true"
            // src="https://ucarecdn.com/3b1a5ccc-f577-4c2c-b1d9-7015e4bb9e25/"
            // data-mime-type="image/gif"
            // imgtype="optimized-image"
            // class="optimized-image if3cb gjs-selected"
            // id="if3cb">
            if (selectedComponentParent.get('type') !== 'bigordr-picture' && !imgSet) {
              selectedComponent.replaceWith(pictureData);
            } else if (selectedComponentParent.get('type') !== 'bigordr-picture' && imgSet) {
              const mobile = R.prop('mobile')(imgSet);
              pictureData.mobileUrl = mobile.getUrl(UPLOADCARE_TRANSFORMS);
              pictureData.mobileUuid = mobile.getData('uuid');
              const tablet = R.prop('tablet')(imgSet);
              pictureData.tabletUrl = tablet.getUrl(UPLOADCARE_TRANSFORMS);
              pictureData.tabletUuid = tablet.getData('uuid');
              const desktop = R.prop('desktop')(imgSet);
              pictureData.desktopUrl = desktop.getUrl(UPLOADCARE_TRANSFORMS);
              pictureData.desktopUuid = desktop.getData('uuid');
              selectedComponent.replaceWith(pictureData);
            } else if (selectedComponentParent.get('type') === 'bigordr-picture' && imgSet) {
              const mobile = R.prop('mobile')(imgSet);
              const mobileUrl = mobile.getUrl(UPLOADCARE_TRANSFORMS);
              const mobileUuid = mobile.getData('uuid');
              const tablet = R.prop('tablet')(imgSet);
              const tabletUrl = tablet.getUrl(UPLOADCARE_TRANSFORMS);
              const tabletUuid = tablet.getData('uuid');
              const desktop = R.prop('desktop')(imgSet);
              const desktopUrl = desktop.getUrl(UPLOADCARE_TRANSFORMS);
              const desktopUuid = desktop.getData('uuid');
              selectedComponentParent.set('mobileUrl', mobileUrl);
              selectedComponentParent.set('mobileUuid', mobileUuid);
              selectedComponentParent.set('tabletUrl', tabletUrl);
              selectedComponentParent.set('tabletUuid', tabletUuid);
              selectedComponentParent.set('desktopUrl', desktopUrl);
              selectedComponentParent.set('desktopUuid', desktopUuid);
              selectedComponentParent.set('fileType', mimeType);
            } else {
              selectedComponentParent.set('mobileUrl', pictureData.mobileUrl);
              selectedComponentParent.set('mobileUuid', pictureData.mobileUuid);
              selectedComponentParent.set('tabletUrl', pictureData.mobileUrl);
              selectedComponentParent.set('tabletUuid', pictureData.mobileUuid);
              selectedComponentParent.set('desktopUrl', pictureData.mobileUrl);
              selectedComponentParent.set('desktopUuid', pictureData.mobileUuid);
              selectedComponentParent.set('fileType', mimeType);
            }
          },
          imgSet
        });
      }
    };
    editor.Commands.add('open-assets', openAssetsFn);
    options = R.mergeDeepRight(defaultOptions, options);
    components.addType(pluginName, options);
    editor.BlockManager.add(pluginName, {
      label: blockLabel,
      category: categories.basic,
      content: {
        style: {
          width: '100%'
        },
        activeOnRender: 1,
        badgable: true,
        type: pluginName,
        imgType: pluginName,
        traits: [{
          type: 'bigordr-animation-trait',
          name: 'animationSelector',
          label: intl.t('select animation')
        }, {
          type: 'bigordr-animation-iteration-trait',
          name: 'animationIterationSelector',
          label: intl.t('select iteration count')
        }, {
          type: 'bigordr-animation-duration-trait',
          name: 'animationDuration',
          label: intl.t('enter duration (sec)')
        }]
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;'>
        <g transform='matrix(1,0,0,1,-3.32468,1.7053e-13)'>
            <g>
                <g transform='matrix(1,0,0,1,-3.28194,-1.08002e-12)'>
                    <g transform='matrix(1.37733,0,0,1.65228,-180.565,-864.206)'>
                        <path d='M207.809,832.911L800.909,832.911' style='fill:none;stroke:black;stroke-width:29.59px;'/>
                    </g>
                    <g transform='matrix(1,0,0,1,118.44,399.43)'>
                        <g transform='matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                        <g transform='matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                    </g>
                    <g transform='matrix(-1,-1.22465e-16,1.22465e-16,-1,918.773,624.57)'>
                        <g transform='matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                        <g transform='matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)'>
                            <path d='M207.809,832.911L828.197,832.911' style='fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;'/>
                        </g>
                    </g>
                </g>
                <g transform='matrix(1,0,0,1,1.86342,37.2616)'>
                    <g transform='matrix(1.00794,0,0,0.792537,12.4421,44.5258)'>
                        <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:38.6px;stroke-linecap:square;stroke-linejoin:miter;'/>
                    </g>
                    <g transform='matrix(1.08069,0,0,1.08069,-48.6111,-30.4861)'>
                        <g transform='matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)'>
                            <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:18.25px;'/>
                        </g>
                        <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                            <g transform='matrix(-0.93623,0,0,0.939727,1014.78,130.716)'>
                                <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                            </g>
                            <g transform='matrix(-1.01027,0,0,0.756475,916.709,228.489)'>
                                <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    <div class="block-label">${model.get('label')}</div>
    </div>
`
    });
    editor.on('component:remove', (component, ...args) => {
      if (component && component.is(pluginName)) {
        const editor = component.em.getEditor();
        const editorCanvas = editor.Canvas.getDocument();
        if (editorCanvas) {
          // remove images outside pictures
          const imgsToRemove = Array.from(editorCanvas.querySelectorAll('[data-remove-now="true"]'));
          if (RA.isNotNilOrEmpty(imgsToRemove)) {
            R.forEach(img => img.remove())(imgsToRemove);
          }
        }

        // REMOVE PICTURE TAG
        const componentParent = component.parent();
        if (componentParent) {
          const isParentAPicture = componentParent.get('type') === 'bigordr-picture';
          if (isParentAPicture) {
            componentParent.remove();
          }
        }
      }
    });
    editor.on('component:clone', component => {
      if (component && component.is(pluginName)) {
        component.set('isCopy', true);
      }
    });
  });
  var _default = _exports.default = Plugin;
});