define("client/pods/components/contacts/details/associates/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { action } from '@ember/object'
  // import { tracked } from '@glimmer/tracking'
  // import * as R from 'ramda'

  class ContactsDetailsAssociates extends _component.default {}
  _exports.default = ContactsDetailsAssociates;
});