define("client/pods/components/documents/document-print/document-thermal-print/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsDocumentPrintDocumentThermalPrintComponent = _exports.default = (_dec = Ember._tracked, _class = class DocumentsDocumentPrintDocumentThermalPrintComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isTermsVisible", _descriptor, this);
    }
    get source() {
      const model = this.args?.model || {};
      const source = model?._data?.source || '';
      if (!source || typeof source !== 'string') {
        return '';
      }
      if (source.length > 30) {
        return `${source.substring(0, 27)}...`;
      }
      return source;
    }
    get paymentMethodKeys() {
      const model = this.args?.model || {};
      const hasGroups = this.args?.hasGroups || false;
      if (model) {
        return model.getGroupedDocumentsPaymentMethodKeys({
          document: model,
          hasGroups,
          hasPaymentLastFiveDigits: true,
          isJoinResults: true,
          isTranslate: true
        });
      }
      return [];
    }
    get showSubHeader() {
      const model = this.args?.model || {};
      const ref = model?._data?.ref || '';
      const paymentMethodKey = model?._data?.paymentMethodKey || '';
      const showFromPerson = this?.args?.showFromPerson || false;
      if (ref) {
        return true;
      }
      if (paymentMethodKey) {
        return true;
      }
      if (showFromPerson) {
        return true;
      }
      return false;
    }
    get hasPrintTerms() {
      if (!this.isTermsVisible) {
        return false;
      }
      const model = this.args?.model || {};
      return RA.isNotNilOrEmpty(model?._data?.terms);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isTermsVisible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _class);
});