define("client/pods/components/channels/shop/campaigns/page-import-btn/campaigns-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Jg3+K0HR",
    "block": "{\"symbols\":[\"campaign\"],\"statements\":[[4,\"each\",[[24,[\"campaignsList\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"page-import-btn__item-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[23,1,[\"_data\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"page-import-btn__template-preview\"],[8],[0,\"\\n      \"],[1,[28,\"helpers/content-display\",null,[[\"content\"],[[23,1,[\"_data\",\"template\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"replace\",[28,\"action\",[[23,0,[]],[24,[\"onReplaceTemplate\"]],[23,1,[\"_data\",\"template\"]],[23,1,[\"_data\",\"templateImages\"]]],null]]]],false],[0,\"\\n    \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"insert\",[28,\"action\",[[23,0,[]],[24,[\"onInsertTemplate\"]],[23,1,[\"_data\",\"template\"]],[23,1,[\"_data\",\"templateImages\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/page-import-btn/campaigns-results/template.hbs"
    }
  });
});