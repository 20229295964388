define("client/pods/components/helpers/lazy-loader/lazy-loader-btn/component", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_emberInViewport.default, {
    router: Ember.inject.service(),
    tagName: 'div',
    didEnterViewport() {
      this.onAutoLazyLoadTask.perform();
    }
  });
});