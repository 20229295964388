define("client/pods/components/products/products-editor/sets-with-choices-editor/choices-editor/component", ["exports", "client/mixins/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_errors.default, {
    tagName: '',
    didInsertElement() {
      this._super(...arguments);
      if (this.isEditing || this.isNew) {
        const choice = this.choice;
        choice.addChoiceDetail();
      }
    },
    actions: {
      loadProduct(model, detail, product) {
        if (model.hasDuplicateSetWithChoicesDetailsProduct(product)) {
          const msg = this.intl.t('duplicate product');
          window.alert(msg);
          model.resetSetDetail(detail);
        } else {
          model.loadChildProduct(detail, product);
          const choice = this.choice;
          choice.addChoiceDetail();
        }
      },
      removeChoiceDetail(choice, detail) {
        choice.removeChoiceDetail(detail);
      },
      resetChoiceDetail(choice, detail) {
        choice.resetChoiceDetail(detail);
      }
    }
  });
});