define("client/pods/components/blogs/search-filters/component", ["exports", "client/mixins/blogs-list-by", "client/mixins/crud"], function (_exports, _blogsListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _blogsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['blogs/lists/filters/search', 'blogs/lists/filters/sort', 'blogs/lists/filters/status', 'filters/count', 'filters/sortDirection']);
    }
  });
});