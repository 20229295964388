define("client/pods/components/emails/campaigns/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eIgrIHwS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"emails/campaigns/campaigns-editor\",null,[[\"model\",\"errors\",\"saveTask\",\"onDash\",\"isNew\",\"isEditing\",\"tab\",\"onCancel\"],[[24,[\"model\"]],[24,[\"errors\"]],[23,0,[\"crud\",\"createRecordTask\"]],[24,[\"onDash\"]],false,false,[24,[\"tab\"]],[24,[\"onCancel\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/campaigns/panels-display/template.hbs"
    }
  });
});