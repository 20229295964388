define("client/pods/components/emails/campaigns/panels-activity/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    init() {
      this._super(...arguments);
      const filters = this.search.setupFilters({
        adapterName: 'emails/campaigns/filters'
      });
      this.set('filters', filters);
      this.fetchTask.perform();
    },
    fetchTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'emails/campaigns',
        filters
      });
      this.set('results', results);
    })

    // listByTask: task(function * ({ adapterName, appendPath, filters, match, date, dateStart, dateEnd, query = '', resultsProperty, resultsToggle } = {}, onHideDropdown) {
    //   filters.setData('page', 1)

    //   if (match) {
    //     filters.setData('match', match)
    //   }

    //   if (date) {
    //     filters.setData('match', ['timestampZ'])
    //     query = date
    //   }

    //   if (dateStart) {
    //     filters.setData('dateStart', dateStart)
    //   }

    //   if (dateEnd) {
    //     filters.setData('dateEnd', dateEnd)
    //   }

    //   const searchSettings = {
    //     adapterName,
    //     appendPath,
    //     resultsProperty,
    //     resultsToggle,
    //     query,
    //     filters
    //   }

    //   const results = yield this.searchRecordsTask.perform(searchSettings)

    //   if (onHideDropdown) {
    //     onHideDropdown()
    //   }

    //   return results
    // }),

    // didInsertElement () {
    //   this._super(...arguments)

    //   const filters = this.filters
    //   const adapterName = this.adapterName
    //   this.fetchDefaultDataTask.perform({ adapterName, filters, resultsProperty: 'results' })
    // }

    // actions: {

    //   setupResendEmail (email) {
    //     const dirty = this.setupDirty({
    //       adapterName: 'emails',
    //       model: email
    //     })
    //     this.set('dirty', dirty)
    //   },

    //   clearResendEmail () {
    //     this.set('dirty', null)
    //   },

    //   async resendEmail (dirty, onCloseDialogAction) {
    //     await this.get('createRecordTask').perform({
    //       adapterName: 'emails',
    //       appendPath: '/resend',
    //       model: dirty
    //     })

    //     onCloseDialogAction()

    //     const results = await this.get('listByTask').perform({ resultsToggle: '' })
    //     this.set('results', results)
    //   },

    //   cancelResendEmail (onCloseDialogAction) {
    //     onCloseDialogAction()
    //     this.set('dirty', null)
    //   }
    // }
  });
});