define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/transporter", "ember-concurrency", "ramda-extension", "client/utils/nventor"], function (_exports, R, RA, _searchContacts, _crud, _transporter, _emberConcurrency, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporter.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    date: Ember.inject.service(),
    contacts: Ember.inject.service(),
    constants: Ember.inject.service(),
    campaignsService: Ember.inject.service('channels/shop/campaigns'),
    transporterAccounts: null,
    hasCommissionBaseCost: false,
    summaryDataAdapterName: 'reports/commissions/data/all',
    adjustmentListAdapterName: 'reports/commissions/data/adjustment',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['contacts/lists/tags']);
      const model = this.model;
      const master = model.getData('_key');
      this.set('thisMonth', this.date.getPeriod());
      const commissionBaseCostFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/preset-commissions/filters',
        defaultFiltersData: {
          master
        }
      });
      this.set('commissionBaseCostFilters', commissionBaseCostFilters);
      if (this.get('model._data.transporterKey')) {
        this.fetchTransporterTask.perform({
          model: this.model
        });
      }
      model.loadSalesPersonSettings();
      this.makeSummaryDataTask.perform();
      this.setupTask.perform();
    },
    makeSummaryDataTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const bySalesPersonsFilters = this.search.setupFilters({
        adapterName: 'reports/commissions/data/all/filters'
      });
      const salesPersons = model.getData('salesPersons') || [];
      const salesPerson = _nventor.default.safeHead(salesPersons);
      if (RA.isNilOrEmpty(salesPerson)) {
        return false;
      }
      const salesPersonFilter = {
        name: salesPerson?._data?.name,
        key: salesPerson?._data?._key,
        username: salesPerson?._data?.username
      };
      bySalesPersonsFilters.setData('salesPersons', RA.list(salesPersonFilter));
      bySalesPersonsFilters.setData('sourceKey', model.getData('_key'));
      bySalesPersonsFilters.setData('query', '');
      bySalesPersonsFilters.setData('mm', this.thisMonth.mm);
      bySalesPersonsFilters.setData('yyyy', this.thisMonth.yyyy);
      bySalesPersonsFilters.setData('isDispatched', true);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.summaryDataAdapterName,
        filters: bySalesPersonsFilters,
        appendPath: '/by-sales-persons'
      });
      this.set('bySalesPersonsFilters', bySalesPersonsFilters);
      this.set('bySalesPersonsSummary', R.head(this.bySalesPersonsFilters.results));
    }),
    setupTask: (0, _emberConcurrency.task)(function* () {
      const adjustmentFilters = this.search.setupFilters({
        adapterName: 'reports/commissions/data/adjustment/filters'
      });
      this.set('adjustmentFilters', adjustmentFilters);
      if (!this.isEditing && !this.isNew) {
        yield this.loadAdjustmentTask.perform();
      }
      if (this.isEditing) {
        const hasAnyCommissionData = yield this.campaignsService.checkHasAnyCommissionData.perform({
          model: this.model
        });
        this.set('hasAnyCommissionData', hasAnyCommissionData?.hasAnyCommissions);
      }
      const associatesContactsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          isAssociates: true
        }
      });
      const associatesContacts = yield this.contacts.loadContactTask.perform(associatesContactsFilters);
      this.set('associatesContacts', associatesContacts);
    }),
    loadAdjustmentTask: (0, _emberConcurrency.task)(function* () {
      const campaignKey = this?.model?._data?._key || '';
      const salesPersons = R.pipe(R_.dotPathOr([], 'model._data.salesPersons'), R.map(user => {
        return {
          name: user?._data?.name || '',
          _key: user?._data?._key || '',
          username: user?._data?.username || ''
        };
      }))(this);
      const adjustmentFilters = this.get('adjustmentFilters');
      adjustmentFilters.setData('query', '');
      adjustmentFilters.setData('campaignKey', campaignKey);
      adjustmentFilters.setData('campaignSalesPersons', salesPersons);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adjustmentListAdapterName,
        filters: adjustmentFilters
      });
      const results = adjustmentFilters.results;
      const grandCommissionTotals = R.pipe(R.map(result => {
        return result._data.grandCommissionTotal;
      }), R.sum)(results);
      this.set('adjustmentFilters', adjustmentFilters);
      this.set('grandCommissionTotals', grandCommissionTotals);
    }),
    actions: {
      assignSalesPerson() {
        const model = this.model;
        this.onAssignSalesPerson(...arguments);
        model.loadSalesPersonSettings();
        this.loadAdjustmentTask.perform();
      },
      unassignSalesPerson() {
        const model = this.model;
        this.onUnassignSalesPerson(...arguments);
        model.loadSalesPersonSettings();
      },
      toggleCommissionMethod() {
        this.onToggleCommissionMethod(...arguments);
      }
    }
  });
});