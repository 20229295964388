define("client/pods/notifications/manager/model", ["exports", "client/pods/base/model", "ramda", "ramda-extension"], function (_exports, _model, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    defaults() {
      return {
        _id: 'notifications/manager'
      };
    },
    populate(data = {}) {
      data.alerts = R.pipe(R.propOr([], 'alerts'), R.map(alert => {
        return Ember.Object.create(alert);
      }))(data);
      this._super(...arguments);
    },
    /**
     * register resource with notifications manager to display unread count.
     * 
     * will store a REFERENCE to an array of models (that has isRead prop)
     * in notifications manager model, the list of notifications does not
     * need to be a model of notifications.
     */
    registerResource({
      resource,
      notifications
    }) {
      const registeredResources = this.registeredResources || [];
      registeredResources.pushObject(`resource__${resource}`);
      Ember.set(this, 'registeredResources', registeredResources);
      Ember.set(this, `resource__${resource}`, notifications);
      this.updateSynced();
    },
    markAsReadLocallyOnDestroy(notification) {
      notification.set('isReadLocally', true);
      if (notification?.setData) {
        const isReadDotPath = this.isReadDotPath || '_data.notificationStatus';
        notification.setData(isReadDotPath, this.constants.notificationsStatus.read);
      }
    },
    markAllBroadcastAlertsAsRead() {
      const unread = this.unreadAlerts || [];
      R.forEach(unread => {
        unread.set('isReadLocally', true);
      })(unread);
    },
    getUnreadForResource({
      resourceDotPath
    } = {}) {
      const notifications = R.pipe(R_.dotPathOr([], resourceDotPath), R.reject(notification => {
        if (notification?.read === true) {
          return true;
        }
        if (notification?.isReadLocally === true) {
          return true;
        }
        return notification?.isRead;
      }), R.sortWith([R.descend(R.prop('timestampZ'))]))(this);
      return notifications;
    },
    unreadAlerts: Ember.computed('_data.alerts.[]', 'synced', function () {
      return this.getUnreadForResource({
        resourceDotPath: '_data.alerts'
      });
    }),
    unread: Ember.computed('_data.{alerts.[],registeredResources.[]}', 'synced', function () {
      const interalResources = ['_data.alerts'];
      const registeredResources = this.registeredResources || [];
      const resources = R.concat(interalResources)(registeredResources);
      const allNotifications = R.reduce((acc, resourceDotPath) => {
        const notifications = this.getUnreadForResource({
          resourceDotPath: resourceDotPath
        });
        console.log('notifications', notifications);
        return R.concat(acc)(notifications);
      }, [])(resources);
      return allNotifications;
    })
    // read: computed('synced', function () {
    //   return R.pipe(
    //     R.pathOr([], ['_data', 'alerts']),
    //     R.filter(R.propEq('isReadLocally', true)),
    //     R.sortWith([
    //       R.descend(R.prop('timestampZ'))
    //     ])
    //   )(this)
    // })
  }, _applyDecoratedDescriptor(_obj, "markAsReadLocallyOnDestroy", [_dec], Object.getOwnPropertyDescriptor(_obj, "markAsReadLocallyOnDestroy"), _obj), _applyDecoratedDescriptor(_obj, "markAllBroadcastAlertsAsRead", [_dec2], Object.getOwnPropertyDescriptor(_obj, "markAllBroadcastAlertsAsRead"), _obj), _applyDecoratedDescriptor(_obj, "getUnreadForResource", [_dec3], Object.getOwnPropertyDescriptor(_obj, "getUnreadForResource"), _obj), _obj));
});