define("client/pods/components/websites/panels-display/sub-panels-overview/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    init() {
      // is this used? can't find any component calling websites/panels-display/sub-panels-overview.
      this.crud.addLists(this, ['websites/reports/filters', 'taxMethods/filters']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: 'ordered',
        taxMethods: 'inclusive'
      }));
      this._super(...arguments);
    },
    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');
      if (revenueBy === 'ordered') {
        return 'all';
      }
      return this.get('constants.dispatchStatus.dispatched');
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      const model = this.model;
      const sourceKey = model.getData('_key');
      this.fetchReportTask.perform({
        sourceKey
      });
    },
    fetchReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const report = yield this._fetchReportTask.perform({
        sourceKey,
        segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'status']
      });
      this.set('report', report);
      this.set('itemReport', []);
      this.set('paymentMethodKeyReport', []);
      this.set('contactReport', []);
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const itemReport = yield this._fetchReportTask.perform({
        sourceKey,
        segments: ['item']
      });
      this.set('itemReport', itemReport);
    }),
    displayPaymentMethodKeyReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const paymentMethodKeyReport = yield this._fetchReportTask.perform({
        sourceKey,
        segments: ['paymentMethodKey']
      });
      this.set('paymentMethodKeyReport', paymentMethodKeyReport);
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const contactReport = yield this._fetchReportTask.perform({
        sourceKey,
        segments: ['contact', 'contactTags', 'customerType', 'memberType', 'gender', 'age']
      });
      this.set('contactReport', contactReport);
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey,
      segments = []
    }) {
      const params = {
        sourceKey,
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        // segments: [
        //   'orderDateZ',
        //   'expectedDispatchDateZ',
        //   'dispatchDateZ',
        //   'contact',
        //   'item',
        //   'paymentMethodKey',
        //   'status',
        //   'customerType',
        //   'memberType',
        //   'gender',
        //   'age'
        // ]
        segments
      };
      const results = yield this.server.call('GET', 'api/protected/reports/campaigns/', params);
      return results.data;
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }
      return false;
    })
  });
});