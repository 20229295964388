define("client/mixins/contacts-persons", ["exports", "client/mixins/contacts-details", "client/utils/nventor"], function (_exports, _contactsDetails, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * helper methods for setting up lists for tabs and panels
   */
  var _default = _exports.default = Ember.Mixin.create(_contactsDetails.default, {
    init() {
      this._super(...arguments);
      this.setData('persons', []);
    },
    /**
     * populate with array of data into models
     */
    transformPersons(rawData) {
      const self = this;
      if (Ember.isArray(rawData.persons)) {
        let transformed = rawData.persons.map(function (data) {
          self.transformDetails(data);
          const personModel = self._createPersonModel(data);
          return personModel;
        });
        rawData.persons = transformed;
      }
    },
    /**
     * create person model
     */
    _createPersonModel(data) {
      return this.dataManager.setAsRecord({
        adapterName: 'contacts/persons',
        data
      });
    },
    addPerson(data) {
      const person = this._createPersonModel(data);
      const persons = this.getOrSetData('persons', []);
      const personId = _nventor.default.random.alphaNum(8);
      person.setData('personId', personId);
      persons.pushObject(person);
      return person;
    },
    removePerson(person) {
      const persons = this.getData('persons');
      persons.removeObject(person);
    }
  });
});