define("client/pods/components/elements/date-multiple-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Dnh+T5fS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"id\",[23,0,[\"containerId\"]]],[10,\"class\",\"multi-date-picker-outer-container\"],[8],[0,\"\\n  \"],[7,\"button\",true],[11,\"id\",[23,0,[\"elementId\"]]],[10,\"class\",\"button element-btn is-tappable\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"tt\",[\"select specific dates\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \\n  \"],[7,\"div\",true],[10,\"id\",\"multi-date-picker-container\"],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/date-multiple-picker/template.hbs"
    }
  });
});