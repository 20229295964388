define("client/pods/components/payments/virtual-accounts/status-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "x4ye/HeP",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"tag \",[23,0,[\"statusClassName\"]]]]],[8],[0,\"\\n\\t\"],[1,[28,\"tt\",[[28,\"concat\",[\"atm_\",[23,1,[\"_data\",\"status\"]]],null]],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/payments/virtual-accounts/status-tag/template.hbs"
    }
  });
});