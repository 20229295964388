define("client/pods/components/users/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hIdlT19I",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"users/users-editor\",null,[[\"model\",\"isNew\",\"isEditing\",\"errors\",\"onSubmitTask\",\"onRemoveTask\",\"onCancel\"],[[24,[\"dirty\"]],false,true,[24,[\"errors\"]],[24,[\"updateRecordTask\"]],[28,\"perform\",[[23,0,[\"crud\",\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"noConfirm\"],[\"users\",[24,[\"dirty\"]],true]]]],null],[28,\"action\",[[23,0,[]],\"cancelEditing\",[28,\"hash\",null,[[\"dirty\"],[[24,[\"dirty\"]]]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/users/panels-edit/template.hbs"
    }
  });
});