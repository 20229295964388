define("client/mixins/sortable-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    modelProp: 'model',
    indexProp: '_data.index',
    getModel() {
      const modelProp = this.modelProp;
      return this.get(modelProp);
    },
    actions: {
      dragStartItem(detail) {
        this.set('dragDetail', detail);
      },
      dragOverItem(detail, dragItem) {
        dragItem = dragItem || this.dragDetail;
        const indexProp = this.indexProp;
        if (!dragItem.get) {
          this.set('dropPosition', '');
          return false;
        }
        const dragIndex = dragItem.get(indexProp);
        const onOverItemIndex = detail.get(indexProp);
        let dropPosition = '';
        if (dragIndex === onOverItemIndex) {
          dropPosition = '';
        } else if (dragIndex < onOverItemIndex) {
          dropPosition = 'insert-below';
        } else {
          dropPosition = 'insert-above';
        }
        this.set('dropPosition', dropPosition);
        return dropPosition;
      },
      reorderDetails(detail) {
        const model = this.getModel();
        const dragItem = this.dragDetail;
        model.reorderDetails(detail, dragItem);
      }
    }
  });
});