define("client/pods/components/blogs/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LwXwg1y8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"placeholder\",\"value\",\"isRunning\",\"onSubmit\",\"isSearchBar\"],[[24,[\"autofocus\"]],\"blogs\",[24,[\"filters\",\"_data\",\"query\"]],[28,\"or\",[[24,[\"searchTask\",\"isRunning\"]],[24,[\"listByTask\",\"isRunning\"]]],null],[28,\"perform\",[[24,[\"searchTask\"]],[24,[\"searchOptions\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]]],null],true]]],false],[0,\"\\n\\n\"],[1,[28,\"blogs/search-filters\",null,[[\"adapterName\",\"filters\",\"results\"],[[24,[\"adapterName\"]],[24,[\"filters\"]],[24,[\"results\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/search-bar/template.hbs"
    }
  });
});