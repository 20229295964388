define("client/pods/components/documents/pos/product/card/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsPosProductCardComponent = _exports.default = (_dec = Ember.inject.service('uploadcare'), _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 5
  }), _class = class DocumentsPosProductCardComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "uploadcareService", _descriptor, this);
      _initializerDefineProperty(this, "dateService", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
    }
    get productKey() {
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      return productData.itemKey || productData._key || '';
    }
    get productName() {
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      const name = R.pathOr(R.propOr('', 'name')(productData), ['item'])(productData);
      const shouldTruncate = name.length > 20;
      if (shouldTruncate) {
        return R.pipe(R.take(20), R.splitEvery(1), R.append('...'), R.join(''))(name);
      }
      return name;
    }
    get productPrice() {
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      return productData.price || productData.salesInclPrice || '0';
    }
    get productLastUpdate() {
      const lastUpdate = R.pathOr('', ['args', 'product', '_data', 'timestampZ_update'])(this);
      return this.dateService.formatDate(lastUpdate, 'YYYY-MM-DD HH:mm:ss');
    }
    get qty() {
      const detail = this.detail;
      const qty = R.pathOr(0, ['_data', 'qty'])(detail);
      return qty;
    }
    get detail() {
      const details = this?.args?.details || [];
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      const itemKey = productData.itemKey || productData._key || '';
      return R.find(R.anyPass([R.pathEq(['_data', '_key'], itemKey), R.pathEq(['_data', 'itemKey'], itemKey)]))(details);
    }
    get isRunning() {
      const isOnAddItemToCartRunning = this?.args?.onAddItemToCart?.isRunning;
      const isOnRemoveItemFromCartRunning = this?.args?.onRemoveItemFromCart?.isRunning;
      if (isOnAddItemToCartRunning || isOnRemoveItemFromCartRunning) {
        return true;
      }
      return false;
    }
    addProduct(product) {
      const productName = product?._data?.name || product?._data?.item || 'P';
      alert(productName);
    }
    *updateDocDetailsTask(updateTask, detail) {
      const dirtyProduct = this.crud.setupDirty({
        adapterName: 'channels/shop/campaigns/details',
        model: detail
      });
      return yield updateTask.perform(dirtyProduct);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uploadcareService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addProduct", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDocDetailsTask", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateDocDetailsTask"), _class.prototype), _class);
});