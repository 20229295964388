define("client/pods/components/export/worksheet/editor/customworksheet-nested/customworksheet-item/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _ramda, RA, _ramdaExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExportWorksheetEditorCustomspecItem = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _class = class ExportWorksheetEditorCustomspecItem extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "dragAndDrop", _descriptor, this);
      _defineProperty(this, "tagName", 'span');
      _defineProperty(this, "classNames", []);
      _defineProperty(this, "translate", true);
      _defineProperty(this, "item", null);
      _defineProperty(this, "isViewable", true);
      _defineProperty(this, "labelKey", 'label');
      _defineProperty(this, "isDraggable", true);
      _defineProperty(this, "hasDropBelowContainer", true);
      _defineProperty(this, "hasDropAboveContainer", true);
      _defineProperty(this, "draggable", true);
    }
    get availableCustomWorksheetAndOwnWorksheets() {
      const originalList = this?.args?.specsCollection?._data?.custom || [];
      const availableCustomWorksheets = this?.args?.availableCustomWorksheets || [];
      if (RA.isNilOrEmpty(availableCustomWorksheets)) {
        return [];
      }
      const customSheet = this?.args?.customSheet;
      const customSpec = _ramda.default.find(_ramdaExtension.default.dotPathEq('_data._key', customSheet?._data?.value))(originalList);
      return _ramda.default.insert(1, customSpec, availableCustomWorksheets);
    }
    get _hasDropAboveContainer() {
      if (this.hasDropAboveContainer && this.index === 0) {
        return true;
      }
      return false;
    }
    drop() {
      if (this.onDrop) {
        this.onDrop(...arguments);
      }
    }
    select(disabled, item) {
      if (!disabled) {
        this.onSelect(item);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "drop", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
});