define("client/pods/components/settings/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cDY1CmYv",
    "block": "{\"symbols\":[\"subTab\",\"@tab\"],\"statements\":[[5,\"layouts/tabs/grid\",[],[[\"@tab\",\"@parentComponent\"],[[23,2,[]],[23,0,[]]]],{\"statements\":[[0,\"\\n\\n\"],[0,\"\\n\\t\"],[5,\"layouts/tabs/grid/content\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[28,\"if\",[[28,\"or\",[[23,0,[\"fetchAndDisplayTask\",\"isRunning\"]],[28,\"eq\",[[23,0,[\"listsIsLoaded\"]],null],null]],null],true,false],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,0,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"documentationResource\",\"isWizard\",\"model\",\"onCancel\",\"onDisplay\",\"onEdit\",\"tab\",\"tabParent\",\"wizardData\"],[\"settings\",[23,0,[\"isWizard\"]],[23,1,[\"model\"]],[23,0,[\"display\"]],[23,0,[\"display\"]],[23,0,[\"edit\"]],[23,1,[]],[23,0,[\"tab\"]],[28,\"hash\",null,[[\"isWizard\",\"onWizard\",\"onWizardCancel\",\"onWizardDone\",\"onWizardNext\",\"stepsList\",\"target\"],[[23,0,[\"isWizard\"]],[23,0,[\"wizard\"]],[23,0,[\"edit\"]],[23,0,[\"display\"]],[23,0,[\"wizardNext\"]],[23,0,[\"crud\",\"lists\",\"settings-wizard\"]],[23,1,[\"target\"]]]]]]]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/tab-container/template.hbs"
    }
  });
});