define("client/pods/components/froala/image-caption-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FroalaImageCaptionModalComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _class = class FroalaImageCaptionModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "imageCaption", _descriptor, this);
      _initializerDefineProperty(this, "disabled", _descriptor2, this);
      const editor = this.args.editor;
      const currentImage = this.args.currentImage;
      this.setup(editor, currentImage);
    }

    /**
     * Sets up the editor with the current image.
     *
     * @param {FroalaEditor} editor - The editor object that needs to be setup.
     * @param {HTMLImageElement} currentImage - The current image to be associated with the editor.
     */
    setup(editor, currentImage) {
      const imageWrapperWhenHasCaption = currentImage.closest('.fr-img-wrap');
      if (imageWrapperWhenHasCaption) {
        const captionWrapper = imageWrapperWhenHasCaption.querySelector('.fr-inner, .caption-wrapper');
        if (!captionWrapper) {
          return;
        }
        this.imageCaption = captionWrapper.innerHTML || '';
        if (this.imageCaption) {
          this.disabled = false;
        }
      }
    }
    upsertImageCaption(onCloseDialogAction) {
      const currentImage = this.args.currentImage;
      const imageWrapper = currentImage.closest('.fr-img-wrap');
      const isFigure = imageWrapper?.nodeName === 'FIGURE';
      if (!isFigure) {
        const figureElement = document.createElement('figure');
        figureElement.innerHTML = currentImage.outerHTML;
        figureElement.classList.add('fr-img-wrap');
        const figcaption = document.createElement('figcaption');
        figcaption.classList.add('caption-wrapper');
        figcaption.innerHTML = this.imageCaption;
        figcaption.setAttribute('contenteditable', 'false');
        figureElement.appendChild(figcaption);
        let captionStyle;
        const outerWrapper = currentImage.closest('.fr-img-space-wrap');
        if (outerWrapper) {
          const captionWrapper = outerWrapper.querySelector('.fr-img-caption.fr-fic.fr-dib');
          captionStyle = captionWrapper.getAttribute('style');
          outerWrapper.replaceWith(figureElement);
        } else {
          captionStyle = currentImage.getAttribute('style');
          currentImage.replaceWith(figureElement);
        }
        if (captionStyle) {
          figureElement.setAttribute('style', captionStyle);
          figureElement.style.maxWidth = currentImage.style.width;
          figureElement.style.width = '100%';
          figureElement.style.margin = figureElement.style.margin || 'auto';
        }
      } else {
        const captionWrapper = currentImage.closest('.fr-img-wrap').querySelector('.caption-wrapper');
        if (captionWrapper) {
          captionWrapper.innerHTML = this.imageCaption || '';
        }
      }
      onCloseDialogAction();
    }
    removeImageCaption(onCloseDialogAction) {
      const currentImage = this.args.currentImage;
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = currentImage.outerHTML;
      tempDiv.firstElementChild.classList.add('fr-fic', 'fr-dib');
      currentImage.parentElement.replaceWith(tempDiv.firstElementChild);
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "imageCaption", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "upsertImageCaption", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "upsertImageCaption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeImageCaption", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeImageCaption"), _class.prototype), _class);
});