define("client/pods/components/extensions/apps/tw/seven-eleven/sender-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uhvd4vsA",
    "block": "{\"symbols\":[\"@errors\",\"@model\",\"@isReadonly\",\"@tab\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"sender name\",[23,1,[]],\"sevenEleven\",\"senderName\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@value\",\"@maxlength\",\"@readonly\"],[[23,0,[\"senderNamePlaceHolder\"]],[23,2,[\"_data\",\"senderName\"]],[23,0,[\"maxLength\"]],[23,3,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"sender telephone\",[23,1,[]],\"sevenEleven\",\"senderTelephone\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@value\",\"@readonly\"],[[23,0,[\"settingsService\",\"settingsModel\",\"_data\",\"companyTel\"]],[23,2,[\"_data\",\"senderTelephone\"]],[23,3,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\\n\"],[5,\"extensions/apps/tw/seven-eleven/print-item-meta\",[],[[\"@tab\",\"@model\",\"@transporterKey\",\"@storeId\",\"@storeName\",\"@storeAddress\",\"@storeIdPlaceholder\",\"@storeNamePlaceholder\",\"@storeAddressPlaceholder\",\"@isUpdateReturnStore\",\"@onClearReturnShop\"],[[23,4,[]],[23,2,[]],[23,2,[\"_data\",\"_key\"]],[23,2,[\"_data\",\"sevenEleven\",\"returnStore\",\"storeId\"]],[23,2,[\"_data\",\"sevenEleven\",\"returnStore\",\"storeName\"]],[23,2,[\"_data\",\"sevenEleven\",\"returnStore\",\"storeAddress\"]],\"same as drop off\",\"same as drop off\",\"same as drop off\",true,[23,0,[\"onClearReturnShop\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/seven-eleven/sender-details/template.hbs"
    }
  });
});