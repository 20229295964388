define("client/utils/intl/missing-message", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;
  function missingMessage(key, locales) {
    const errorMissingTranslations = `[ember-intl] Missing translation for key: "${key}" for locales: "${locales}"`;
    if (_environment.default.environment === 'production') {
      console.error(errorMissingTranslations);
      return key;
    }
    return errorMissingTranslations;
  }
});