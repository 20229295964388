define("client/pods/components/dispatch/processes-bar-container/component", ["exports", "ramda", "ember-concurrency"], function (_exports, R, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: 'div',
    classNames: [],
    dispatch: Ember.inject.service(),
    date: Ember.inject.service(),
    showProcessBar: true,
    selectCustomDate: false,
    isDispatchProcessModel: Ember.computed('steps.@each.code', function () {
      const dispatchProcesses = this.steps || [];
      return R.find(R.pathEq(['_data', 'code'], 'dispatched'))(dispatchProcesses);
    }),
    allowDispatchProcesses: Ember.computed('model.allowDispatchProcesses', function () {
      if (this.model) {
        return this.get('model.allowDispatchProcesses');
      }
      return true;
    }),
    submitTask: (0, _emberConcurrency.task)(function* (step, dispatchDateZ) {
      const model = this.model || this.batch;
      yield this.preSetDispatchProcessTask.perform(model, step, dispatchDateZ);
      if (this.onAfterSubmit) {
        this.onAfterSubmit(model, step, dispatchDateZ);
      }
      if (this.onRefresh) {
        this.onRefresh(step);
      }
    }).drop(),
    submitCustomDateTask: (0, _emberConcurrency.task)(function* (step, onCloseDialogAction, batchDispatchDateZ) {
      // const batchDispatchDateZ = this.get('batchDispatchDateZ')
      const model = this.model || this.batch;
      yield this.preSetDispatchProcessTask.perform(model, step, batchDispatchDateZ);
      if (this.onAfterSubmit) {
        this.onAfterSubmit(model, step, batchDispatchDateZ);
      }
      if (this.onRefresh) {
        const period = this.date.getPeriod(batchDispatchDateZ);
        this.onRefresh(step, period);
      }
      onCloseDialogAction();
    }).drop(),
    preSetDispatchProcessTask: (0, _emberConcurrency.task)(function* (model, dispatchProcess, dispatchDateZ) {
      this.set('preDispatchProcess', '');
      this.set('preDispatchProcessDispatchDateZ', '');
      const preDispatchShippingModal = this.settings.getProp('preDispatchShippingModal');
      if (!this.hasPreDispatchDialog || !preDispatchShippingModal || dispatchProcess.getData('code') !== 'dispatched') {
        this.set('preDispatchProcess', dispatchProcess);
        this.set('preDispatchProcessDispatchDateZ', dispatchDateZ);
        return yield this.get('dispatch.setDispatchProcessTask').perform(model, dispatchProcess, dispatchDateZ);
      }
      if (!R.is(Array, model) && dispatchProcess.getData('code') === 'dispatched') {
        if (this.hasPreDispatchDialog) {
          this.set('preDispatchProcess', dispatchProcess);
          this.set('preDispatchProcessDispatchDateZ', dispatchDateZ);
          const onShowPreDispatchProcessDialog = this.onShowPreDispatchProcessDialog;
          if (onShowPreDispatchProcessDialog) {
            onShowPreDispatchProcessDialog(dispatchDateZ);
          }
        }
      }
    }).drop(),
    setIsDispatchedProcessTask: (0, _emberConcurrency.task)(function* (dispatchDateZ) {
      const model = this.model || this.batch;
      let dispatchProcess = this.preDispatchProcess;
      if (!dispatchProcess) {
        dispatchProcess = this.isDispatchProcessModel;
      }
      const results = yield this.get('dispatch.setDispatchProcessTask').perform(model, dispatchProcess, dispatchDateZ);
      const onClosePreDispatchProcessDialog = this.onClosePreDispatchProcessDialog;
      if (onClosePreDispatchProcessDialog) {
        onClosePreDispatchProcessDialog();
      }
      if (this.onAfterSubmit) {
        this.onAfterSubmit(model, dispatchProcess, dispatchDateZ);
      }
      if (this.onRefresh) {
        const period = this.date.getPeriod(dispatchDateZ);
        this.onRefresh(dispatchProcess, period, true);
      }
      return results;
    }).drop(),
    onToggleModalDialog() {
      this.set('showProcessBar', false);
    }
  }, _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj), _obj));
});