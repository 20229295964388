define("client/pods/components/workflows/panels-dash/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import DocsListByMixin from '../../../../mixins/doc-list-by'

  class WorkflowsPanelDashComponent extends _component.default {
    // crud: service(),
    // filtersAdapterName: 'emails/filters',
    // init () {
    //   this.crud.addLists(this, [
    //     'emails/lists/menu/notifications'
    //   ])

    //   this._super(...arguments)

    //   const filters = this.search.setupFilters({
    //     adapterName: this.filtersAdapterName,
    //     defaultFiltersData: {
    //       sort: [{
    //         by: 'paymentDueDateZ',
    //         direction: 'ASC'
    //       }]
    //     }
    //   })

    //   this.set('filters', filters)

    //   this.fetchScheduledTask.perform()
    // },

    // fetchScheduledTask: task(function * () {
    //   const filters = this.filters
    //   const results = yield this.listByTask.perform({
    //     adapterName: 'documents/sales/invoices',
    //     appendPath: 'emails/payments',
    //     filters
    //   })

    //   this.set('results', results)
    // })
  }
  _exports.default = WorkflowsPanelDashComponent;
});