define("client/mixins/droppable", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    classNameBindings: ['_isDragOver:is-drag-over', 'dropPositionClassName'],
    droppable: true,
    disableWhenReadonly: false,
    isDroppable: Ember.computed('droppable', 'disableWhenReadonly', function () {
      if (this.disableWhenReadonly) {
        return false;
      }
      return this.droppable;
    }),
    _isDragOver: Ember.computed('isDragOver', 'isDroppable', function () {
      if (!this.isDroppable) {
        return false;
      }
      return this.isDragOver;
    }),
    getTransferData(event) {
      let data = event.dataTransfer.getData('text/data');
      data = _nventor.default.safeParseJSON(data);
      return data;
    },
    dropPositionClassName: Ember.computed('dropPosition', 'isDragOver', function () {
      if (!this.isDragOver) {
        return '';
      }
      return this.dropPosition;
    }),
    dragLeave(event) {
      event.preventDefault();
      if (this.isDroppable) {
        this.set('isDragOver', false);
      }
      return false;
    },
    dragOver(event) {
      event.preventDefault();
      if (this.isDroppable) {
        this.set('isDragOver', true);
        if (this.onDragOver) {
          this.onDragOver(event);
        }
      }
      return false;
    },
    drop(event) {
      event.preventDefault();
      if (this.isDroppable) {
        this.set('isDragOver', false);
        const data = this.getTransferData(event);
        if (this.onDrop) {
          this.onDrop(data, event);
        }
      }
    }
  });
});