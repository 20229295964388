define("client/pods/components/documents/pos/product/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2KgkohSS",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@product\",\"@onAddItemToCart\",\"@tab\",\"@isPosFullScreenMode\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"card pos-product-card\"],[3,\"on\",[\"click\",[28,\"perform\",[[23,0,[\"updateDocDetailsTask\"]],[23,3,[]],[23,2,[]]],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-image\"],[8],[0,\"\\n    \"],[5,\"files-manager\",[],[[\"@showDefaultImagePreview\",\"@tab\",\"@allowRemove\",\"@allowSetDefault\",\"@allowUploads\",\"@allowRemove\",\"@showDefaultImagePreview\",\"@allowShowPreviewModal\",\"@model\",\"@onlyDisplayDefault\",\"@resource\",\"@resourceKey\",\"@transform\",\"@filesGalleryRowClassNames\"],[false,[23,4,[]],false,false,false,false,false,false,[23,2,[]],true,\"products\",[23,0,[\"productKey\"]],\"/-/scale_crop/300x300/smart\",\"margin-0\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-content pos-product-card-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content pos-product-card-content-content\"],[8],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"        \"],[1,[23,0,[\"productName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"helpers/tab-link\",[],[[\"@hasLink\",\"@tabOptions\"],[[23,2,[\"_data\",\"itemKey\"]],[28,\"hash\",null,[[\"component\",\"title\",\"loadInstructions\"],[\"products/tab-container\",\"products\",[28,\"hash\",null,[[\"find\"],[[28,\"hash\",null,[[\"id\"],[[23,2,[\"_data\",\"itemKey\"]]]]]]]]]]]]],{\"statements\":[[0,\"\\n          \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n            \"],[1,[23,0,[\"productName\"]],false],[0,\"\\n          \"]],\"parameters\":[1]}],[0,\"\\n\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/pos/product/card/template.hbs"
    }
  });
});