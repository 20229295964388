define("client/pods/grapesjs/plugins/tabs/components/TabContainer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.role = _exports.default = void 0;
  const role = _exports.role = 'tablist';
  var _default = (dc, {
    intl = {
      t: txt => txt
    },
    ...config
  }) => {
    dc.addType(config.typeTabContainer, {
      model: {
        defaults: {
          name: intl.t('tab container'),
          draggable: false,
          // `[data-gjs-type="${config.typeTabs}"]`,
          droppable: false,
          // `[data-gjs-type="${config.typeTab}"]`,
          copyable: false,
          removable: false,
          highlightable: false,
          attributes: {
            role
          },
          classes: config.classTabContainer,
          traits: [],
          ...config.tabContainerProps
        }
      },
      view: {
        onRender({
          model /* , editor, el */
        }) {
          // add id as class name. so, style can be added to a specific tabContainer
          const className = `id-${model.getId()}`;
          model.addClass(className);
        }
      }
    });
  };
  _exports.default = _default;
});