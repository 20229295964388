define("client/pods/emails/lists/menu/notifications/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    // resourcePath: 'api/protected/emails/lists/menu/notifications',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: 'payment reminders',
        action: 'paymentReminders'
      }, {
        label: 'dispatched',
        action: 'dispatched'
      }];
      return _nventor.default.resolve(data);
    }
  });
});