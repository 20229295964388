define("client/pods/components/documents/panel-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JOMWq38C",
    "block": "{\"symbols\":[\"posProfilesSelectorComponent\",\"@tabParent\"],\"statements\":[[5,\"documents/pos/profiles-selector\",[],[[\"@tab\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"documents/editor-main\",null,[[\"tab\",\"tabParent\",\"model\",\"infoComponent\",\"detailsComponent\",\"isEditing\",\"isNew\",\"onDash\",\"errors\",\"onPreSubmit\",\"onAddNewDetail\",\"onSubmitTask\",\"fetchHCTRegionDataTask\",\"onCancel\",\"onAfter\",\"isFromPendingShortcut\",\"isFromDocument\",\"isLinkingNeeded\",\"onAddItemToCart\",\"onRemoveItemFromCart\",\"areProductsFullScreen\",\"posGridClassNames\",\"onTogglePosFullscreenMode\",\"isPosFullScreenMode\"],[[24,[\"tab\"]],[24,[\"tabParent\"]],[24,[\"model\"]],[24,[\"tab\",\"editorInfoComponent\"]],[24,[\"tab\",\"editorDetailsComponent\"]],true,true,[24,[\"onDash\"]],[24,[\"errors\"]],[28,\"action\",[[23,0,[]],\"preSubmit\"],null],[28,\"action\",[[23,0,[]],\"addNewDetail\",[24,[\"model\"]]],null],[24,[\"createRecordTask\"]],[24,[\"fetchHCTRegionDataTask\"]],[24,[\"onDash\"]],[28,\"perform\",[[23,0,[\"onAfterSaveTask\"]]],null],[24,[\"isFromPendingShortcut\"]],[24,[\"isFromDocument\"]],[24,[\"isLinkingNeeded\"]],[23,0,[\"onAddItemToCartTask\"]],[23,0,[\"onRemoveItemFromCartTask\"]],[23,0,[\"areProductsFullScreen\"]],[23,0,[\"panelNewClassNames\"]],[23,0,[\"togglePosFullscreenMode\"]],[24,[\"tabParent\",\"isPosFullScreenMode\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\" \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-new/template.hbs"
    }
  });
});