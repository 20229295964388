define("client/pods/components/transporters/hct-waybills-list/component", ["exports", "client/mixins/sortable-actions", "ramda", "ramda-extension"], function (_exports, _sortableActions, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_sortableActions.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    settingsService: Ember.inject.service('settings'),
    filterTransporterAccountNumber: 'all',
    filterTransporterAccountNumberLabel: 'all',
    filterTransporterBatchNumber: 'all',
    filterTransporterBatchNumberLabel: 'all',
    indexProp: 'index',
    init() {
      this.crud.addLists(this, ['extensions/apps/tw/hct/accounts/filters', 'extensions/apps/tw/hct/batches/filters', 'extensions/apps/tw/hct/print/waybills', 'extensions/apps/tw/hct/print/waybills/positions']);
      const hctWaybillsPerPage = this.settings.getProp('hctWaybillsPerPage') || 1;
      this.set('hctWaybillsPerPage', hctWaybillsPerPage);
      this._super(...arguments);
    },
    afterLoadLists() {
      this.set('listsIsLoaded', Math.random());
      this.setup();
    },
    setup() {
      this.set('waybillsPrintStartPosition', 1);
      this.set('firstRowBottomPadding', this.settingsService?.settingsModel?._data?.hctOptions?.firstRowBottomPadding || null);
      this.set('lastRowTopPadding', this.settingsService?.settingsModel?._data?.hctOptions?.lastRowTopPadding || null);
    },
    mulitpleWaybillsPerPage: Ember.computed('hctWaybillsPerPage', 'refreshWaybills', function () {
      if (this.hctWaybillsPerPage > 1) {
        return true;
      }
      return false;
    }),
    allowPrintAllWaybills: Ember.computed('dispatchProcess._data.allowPrintAllWaybills', 'tab.dispatchProcess._data.allowPrintAllWaybills', function () {
      const dispatchProcess = this.dispatchProcess || this.tab?.dispatchProcess;
      return R.pathEq(['_data', 'allowPrintAllWaybills'], true)(dispatchProcess);
    }),
    allSummaryList: Ember.computed('listsIsLoaded', 'refreshWaybills', 'crud.lists.{extensions-apps-tw-hct-accounts-filters.[],extensions-apps-tw-hct-batches-filters}', 'results.[]', 'filterTransporterAccountNumber', 'filterTransporterBatchNumber', function () {
      const results = this.get('results') || [];
      const listsIsLoaded = this.listsIsLoaded;
      const filterTransporters = ['hct'];
      const filterDispatchStatus = this.get('constants.dispatchStatus.dispatched');
      const all = [];
      const filterTransporterAccountNumber = this.get('filterTransporterAccountNumber');
      const filterTransporterBatchNumber = this.get('filterTransporterBatchNumber');
      const accounts = R_.dotPath('crud.lists.extensions-apps-tw-hct-accounts-filters')(this) || [];
      const batches = R_.dotPath('crud.lists.extensions-apps-tw-hct-batches-filters')(this) || [];
      if (listsIsLoaded && !R.isEmpty(accounts) && !R.isEmpty(batches)) {
        // get summary lists for each account and each batch

        accounts.forEach(accountModel => {
          const accountNumber = accountModel.getData('value');
          batches.forEach(batchModel => {
            const batchNumber = batchModel.getData('value');
            if ((filterTransporterAccountNumber === 'all' || filterTransporterAccountNumber === accountNumber) && (filterTransporterBatchNumber === 'all' || filterTransporterBatchNumber === batchNumber)) {
              results.forEach(result => {
                const transporterExtension = result.getData('transporterExtension', '');
                const dispatchStatus = result.getData('dispatchStatus', false);
                const transporterAccountNumber = result.getData('transporterAccountNumber', 'all');
                const transporterBatchNumber = result.getData('transporterBatchNumber', 'all');
                const filterByTransporters = this._filterByTransporters(filterTransporters, transporterExtension);
                const filterByDispatchStatus = this._filterByDispatchStatus(filterDispatchStatus, dispatchStatus);
                if (this.allowPrintAllWaybills && dispatchStatus !== filterDispatchStatus || filterByTransporters && filterByDispatchStatus) {
                  if (transporterAccountNumber === accountNumber && transporterBatchNumber === batchNumber) {
                    const existInArray = all.find(a => a.getData('_key') === result.getData('_key'));
                    if (!existInArray) {
                      all.pushObject(result);
                    }
                  }
                }
              });
            }
          });
        });
      }
      return all;
    }),
    toPrintWaybills: Ember.computed('allSummaryList.@each.{index,printWaybill}', 'filter', 'refreshWaybills', function () {
      const allSummaryList = this.allSummaryList;
      const filter = this.filter;
      const mapIndexed = R.addIndex(R.map);
      return R.pipe(R.filter(doc => {
        if (R.prop('printWaybill', doc) === false && !filter) {
          return false;
        }
        if (filter) {
          const data = R.prop('_data', doc);
          const values = R.pipe(R.pick(['telephone', 'contact', 'transporterContactPerson', 'waybill', 'address']), R.values)(data);
          const matches = R.filter(value => {
            if (value.indexOf(filter) > -1) {
              return true;
            }
            return false;
          })(values);
          if (matches.length > 0) {
            return true;
          }
          return false;
        }
        return true;
      }), mapIndexed((doc, i) => {
        doc.set('index', i);
        return doc;
      }))(allSummaryList);
    }),
    hiddenWaybills: Ember.computed('refreshWaybills', 'allSummaryList.@each.printWaybill', 'filter', function () {
      const allSummaryList = this.allSummaryList;
      return R.reject(R.prop('printWaybill'), allSummaryList);
    }),
    groupedWaybills: Ember.computed('waybillsPrintStartPosition', 'hctWaybillsPerPage', 'toPrintWaybills.[]', 'refreshWaybills', function () {
      const hctWaybillsPerPage = parseInt(this.hctWaybillsPerPage) || 1;
      const waybillsPrintStartPosition = parseInt(this.waybillsPrintStartPosition) || 1;
      const adjustPositionBy = waybillsPrintStartPosition - 1;
      const addEmptyObject = n => n > 0 ? [false, n - 1] : false;
      const adjustment = R.unfold(addEmptyObject, adjustPositionBy);
      let toPrintWaybills = this.toPrintWaybills;
      toPrintWaybills = R.concat(adjustment, toPrintWaybills);
      const isOdd = n => n % 2 === 1;
      const processed = R.pipe(R.reduce((acc, doc) => {
        const packages = R.pathOr(1, ['_data', 'packages'], doc);
        return R.concat(acc, R.repeat(doc, packages));
      }, []), R.flatten, R.splitEvery(hctWaybillsPerPage), R.map(group => {
        if (isOdd(R.length(group))) {
          group = R.concat(group, [false]);
        }
        return R.splitEvery(2, group);
      }))(toPrintWaybills);
      return processed;
    }),
    _filterByTransporters(filterTransporters, transporterExtension) {
      if (Ember.isEmpty(filterTransporters)) {
        return true;
      }
      if (filterTransporters.indexOf(transporterExtension) > -1) {
        return true;
      }
      return false;
    },
    _filterByDispatchStatus(filterDispatchStatus, dispatchStatus) {
      // no filter set, dont filter
      if (Ember.isEmpty(filterDispatchStatus)) {
        return true;
      }

      // match status
      if (dispatchStatus === filterDispatchStatus) {
        return true;
      }
      return false;
    },
    actions: {
      togglePrintWaybill(model) {
        model.toggleProperty('printWaybill', false);
      },
      togglePrintAll() {
        this.set('filter', '');
        const toPrintWaybills = this.allSummaryList || [];
        toPrintWaybills.forEach(doc => doc.set('printWaybill', true));
      },
      toggleHidePrintAll() {
        this.set('filter', '');
        const toPrintWaybills = this.allSummaryList || [];
        toPrintWaybills.forEach(doc => doc.set('printWaybill', false));
      },
      reorderWaybills(model, dragItem) {
        // console.log(model)
        // console.log(dragItem)
        // const dragIndex = dragItem.index
        // const onOverItemIndex = model.get('index')
        // // const models = this.getData('models')
        // if (dragIndex === onOverItemIndex) {
        //   // do nothing
        // } else if (dragIndex < onOverItemIndex) {
        //   // move to below onOverItemIndex
        //   this.setData('details', details.map(detail => this.moveDetailBelow(detail, dragIndex, onOverItemIndex)))

        //   this.set('sortOrder')
        //   if (this.get('sortDetails')) {
        //     this.get('sortDetails')()
        //   }
        // } else {
        //   // move to above onOverItemIndex
        //   this.setData('details', details.map(detail => this.moveDetailAbove(detail, dragIndex, onOverItemIndex)))

        //   if (this.get('sortDetails')) {
        //     this.get('sortDetails')()
        //   }
        // }
      }
    }
  });
});