define("client/initializers/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'constants', 'service:constants');
    application.inject('adapter', 'constants', 'service:constants');
    application.inject('component', 'constants', 'service:constants');
  }
  var _default = _exports.default = {
    name: 'constants',
    initialize: initialize
  };
});