define("client/pods/home/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/pods/options/model", "client/utils/nventor"], function (_exports, R, RA, _model, _model2, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeModel extends _model.default {
    defaults() {
      return {};
    }
  }
  _exports.default = HomeModel;
});