define("client/pods/grapesjs/plugins/slider-native/blocks", ["exports", "client/pods/grapesjs/plugins/slider-native/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (editor, config = {}) => {
    const bm = editor.BlockManager;
    const {
      intl,
      categories,
      sliderBlock
    } = config;
    const {
      sliderName
    } = _constants.default;
    sliderBlock && bm.add(sliderName, {
      render: () => `
    <div class="gjs-block__media">
      <svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;">
        <g transform="matrix(1,0,0,1,-6.60663,21.9871)">
            <g transform="matrix(1.00794,-6.84695e-39,4.65812e-39,0.792537,158.108,-76.7203)">
                <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
            </g>
            <g transform="matrix(1.00794,0,1.30506e-54,0.792537,83.8538,-2.46648)">
                <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
            </g>
            <g transform="matrix(1.00794,-6.84695e-39,4.65812e-39,0.792537,17.5874,81.7874)">
                <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
            </g>
            <g transform="matrix(1.08069,-5.87747e-39,5.87747e-39,1.08069,-43.4658,6.77548)">
                <g transform="matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)">
                    <circle cx="567.803" cy="388.754" r="27.858" style="stroke:black;stroke-width:18.25px;stroke-linecap:round;stroke-linejoin:round;"/>
                </g>
                <g transform="matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)">
                    <g transform="matrix(-0.93623,0,0,0.939727,1014.78,130.716)">
                        <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z"/>
                    </g>
                    <g transform="matrix(-1.01027,0,0,0.756475,916.709,228.489)">
                        <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z"/>
                    </g>
                </g>
            </g>
        </g>
        <g transform="matrix(1,0,0,1,0.251863,-73.6856)">
            <g transform="matrix(1.43016,5.71454e-23,-5.71454e-23,1.43016,-956.422,144.288)">
                <g transform="matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)">
                    <circle cx="608.595" cy="433.442" r="79.613" style="stroke:black;stroke-width:22.38px;stroke-linejoin:round;"/>
                </g>
                <g transform="matrix(-0.794376,-9.7283e-17,9.7283e-17,-0.794376,1440.42,575.983)">
                    <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                    <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                </g>
            </g>
            <g transform="matrix(-1.43016,-1.75144e-16,1.75144e-16,-1.43016,1979.92,1536.01)">
                <g transform="matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)">
                    <circle cx="608.595" cy="433.442" r="79.613" style="stroke:black;stroke-width:22.38px;stroke-linejoin:round;"/>
                </g>
                <g transform="matrix(-0.794376,-9.7283e-17,9.7283e-17,-0.794376,1440.42,575.983)">
                    <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                    <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                        <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:white;stroke-width:328.44px;stroke-linejoin:round;"/>
                    </g>
                </g>
            </g>
        </g>
      </svg>
      <div class="block-label">${intl.t('slider')}</div>
      </div>
    `,
      category: categories.complex,
      content: {
        type: sliderName
      },
      ...sliderBlock
    });
  };
  _exports.default = _default;
});