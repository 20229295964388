define("client/mixins/big", ["exports", "client/utils/nventor", "ramda"], function (_exports, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const self = {
    /**
     * create new big number
     * @param  {string|integer|float} number
     * @return {class} big number class instance
     */
    newBig: function (number) {
      number = parseFloat(number);
      return new Big(number || 0);
    },
    /**
     * create new big number from object's property value
     * @param  {string} property name
     * @return {class} big number class instance
     */
    getBig: function (prop) {
      var number = parseFloat(this.get(prop));
      return new Big(number || 0);
    },
    /**
     * create new big number from object's _data.property value
     * @param  {string} property name
     * @return {class} big number class instance
     */
    getDataBig: function (prop) {
      var number = parseFloat(this.getData(prop));
      return new Big(number || 0);
    },
    toFixed: R.curry((rounding, bigNumber) => {
      bigNumber = self.newBig(bigNumber);
      if (rounding == null) {
        rounding = 8;
      }
      rounding = parseInt(rounding);
      return bigNumber.toFixed(rounding);
    }),
    autoRound: R.curry((rounding, bigNumber) => {
      bigNumber = self.newBig(bigNumber);
      if (rounding === 0) {
        return self.toFixed(rounding, bigNumber);
      }
      return R.pipe(self.toFixed(rounding), R.dropLastWhile(x => x === '0'), R.dropLastWhile(x => x === '.'))(bigNumber);
    }),
    sumArray: R.reduce((accumulator, amt) => accumulator.plus(amt), new Big(0)),
    sumPropAndAutoRound: R.curry((rounding, param, arrayOfObjects) => {
      arrayOfObjects = arrayOfObjects || [];
      return R.pipe(R.pluck(param), R.reject(_nventor.default.isNilOrEmpty), self.sumArray, self.autoRound(rounding))(arrayOfObjects);
    }),
    sumProp: R.curry((param, arrayOfObjects) => {
      arrayOfObjects = arrayOfObjects || [];
      return R.pipe(R.pluck(param), R.reject(_nventor.default.isNilOrEmpty), self.sumArray)(arrayOfObjects);
    })
  };
  var _default = _exports.default = Ember.Mixin.create(self);
});