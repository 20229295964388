define("client/mixins/adapters-new", ["exports", "client/utils/nventor", "client/config/environment", "ramda"], function (_exports, _nventor, _environment, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    host: _environment.default.APP.serverUrl,
    getAdapter(adapterName) {
      const adapter = Ember.getOwner(this).lookup(`adapter:${adapterName}`);
      if (!adapter) {
        _nventor.default.throw(`ADAPTER MIXIN: getAdapterError. Invalid adpaterName: ${adapterName}.`);
      }
      adapter.set('syncAdapterName', adapterName);
      return adapter;
    },
    getPath(pathName, appendPath) {
      let path = this.get(pathName) || this.resourcePath;
      if (appendPath) {
        if (appendPath.charAt(0) !== '/') {
          path = `${path}/${appendPath}`;
        } else {
          path = path + appendPath;
        }
      }
      return path;
    },
    /**
     * Retrieves the full path of a specified file or directory.
     *
     * @param {object} options - The options for getting the full path.
     * @param {string} [options.pathName=''] - The name of the path.
     * @param {string} [options.appendPath=''] - The additional path to append to the base path.
     * @param {IServerType} [options.serverType=''] - The server type.
     * @returns {string} - The full path of the specified file or directory.
     */
    getFullPath({
      pathName,
      appendPath,
      serverType = ''
    }) {
      let host = this.host;
      if (serverType === 'jobs') {
        host = _environment.default.APP.serverJobsUrl;
      }
      const path = this.getPath(pathName, appendPath);
      const fullPath = `${host}/${path}`;
      return fullPath;
    },
    /**
     * Executes an AJAX method call with the specified parameters.
     *
     * @param {string} method - The HTTP method to use.
     * @param {string} appendPath - The path to append to the base path.
     * @param {object} data - The data to send in the request body.
     * @param {object} options - The additional options for the AJAX call.
     * @param {string} serverType - The type of server to send the request to.
     * @param {function} options.filters.set - The function to set the filters.
     *
     * @return {Promise} A Promise that resolves with the response data.
     */
    call({
      method,
      appendPath,
      data,
      options = {},
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'resourcePath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      const {
        filters = {}
      } = options;
      return this.ajax[method](path, data).then(res => {
        if (filters?.set) {
          const hasFullCount = R.hasPath(['fullCount'])(res);
          if (hasFullCount) {
            let fullCount = R.path(['fullCount'])(res);
            fullCount = parseInt(fullCount) || 0;
            filters.set('fullCount', fullCount);
          }
          const hasCustom = R.hasPath(['custom'])(res);
          if (hasCustom) {
            const custom = R.pathOr({}, ['custom'])(res);
            filters.set('custom', custom);
          }
        }
        return res.data;
      });
    },
    createModel: function (adapterName, adapter, data, attrs = {}) {
      // attrs.adapterName = adapterName
      // attrs.adapter = adapter
      return _nventor.default.createModel(this.modelObj, data, attrs);
    },
    isModel: function (obj) {
      if (obj._data != null) {
        return true;
      }
      return false;
    },
    serialize(model) {
      // serialize on model

      // if (model.serialize) {
      // @NOTE: the problem with this is that existing custom serialize on adapters need to be moved to model first
      //   return model.serialize()
      // }
      // return this._getAllData(model)
      return this._serialize(model._data);
    },
    _serialize(data = {}) {
      // if (typeof data === 'string') {
      if (data == null) {
        return '';
      }
      if (!Ember.isArray(data) && !_nventor.default.confirm.isObject(data)) {
        return data;
      }
      if (Ember.isArray(data)) {
        return this._serializeArray(data);
      }
      if (data._data) {
        return this._serializeObject(data._data);
      }
      return this._serializeObject(data);
    },
    _serializeArray(data) {
      data = data.filter(item => item != null);
      return data.map(item => {
        let serialized;
        if (item._data) {
          serialized = this._serialize(item._data);
        } else {
          serialized = this._serialize(item);
        }
        if (serialized._data) {
          return serialized._data;
        }
        return serialized;
      });
    },
    _serializeObject(data) {
      const newData = {};
      Object.keys(data).map(key => {
        const value = data[key];
        if (Ember.isArray(value)) {
          newData[key] = this._serializeArray(value);
          return key;
        }

        // if (nventor.confirm.get(value, '_data')) {
        if (!R.isNil(value?._data)) {
          newData[key] = this._serialize(value._data);
          return key;
        }
        newData[key] = this._serialize(value);
        return key;
      });
      return newData;
    },
    isNotEmptyDetail(detail, requiredDataAttrs) {
      return requiredDataAttrs.reduce((isEmpty, attr) => {
        if (isEmpty === false) {
          return false;
        }
        if (detail[attr]) {
          return true;
        }
        return false;
      }, true);
    },
    /**
     * Add adapter information to the given data object.
     * This method is used to associate the data object with related adapters.
     *
     * @param {Object} data - The data object to add the adapter information to.
     * @param {Boolean} [appendPath] - Indicates whether to append the path to the adapter name.
     * @return {Object} - The data object with adapter information added.
     */
    _addAdapterInfo(data = {}, appendPath) {
      // use related adapters when a model belongs to two lists. this way it will push
      // the model changes to both lists
      const adapters = [{
        adapterName: this.syncAdapterName,
        appendPath
      }];
      const relatedAdapterNames = this.relatedAdapters || [];
      data.adapter = {
        adapters: adapters.concat(relatedAdapterNames),
        cacheId: data._cacheId
      };
      delete data._cacheId;
      return data;
    },
    /**
     * Finds data based on given parameters.
     *
     * @param {Object} options - The options object.
     * @param {Object} [options.data={}] - The data object to be sent in the request.
     * @param {string} [options.appendPath] - The additional path to be appended to the base path.
     * @param {string} options.token - The token for authentication.
     * @param {IServerType} options.serverType - The type of server to send the request to.
     * @returns {Promise} A Promise that resolves with the response data.
     */
    find({
      data = {},
      appendPath,
      token,
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'findPath',
        appendPath,
        serverType
      });
      return this.ajax.GET(path, data, token).then(res => res.data);
    },
    /**
     * Finds all data using the specified options and returns the result.
     * If options.filters is provided, it will also update the fullCount value.
     *
     * @param {Object} options - The options for finding the data.
     * @param {Object} options.data - The data payload to send.
     * @param {string} options.appendPath - The path to append to the base path.
     * @param {Object} options.options - The additional options.
     * @param {IServerType} options.serverType - The server type.
     * @returns {Promise<Array>} - A promise that resolves to an array of data or an empty array.
     */
    findAll({
      data = {},
      appendPath = '',
      options = {},
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      const filters = options.filters;
      const method = options.method || 'GET';
      let token;
      if (filters) {
        token = filters.get('token');
      }
      return this.ajax[method](path, data, token).then(function (res) {
        const hasFullCount = R.hasPath(['fullCount'])(res);
        if (hasFullCount) {
          let fullCount = R.path(['fullCount'])(res);
          fullCount = parseInt(fullCount) || 0;
          if (filters?.set) {
            filters.set('fullCount', fullCount);
          }
        }
        const hasCustom = R.hasPath(['custom'])(res);
        if (hasCustom) {
          const custom = R.pathOr({}, ['custom'])(res);
          if (filters?.set) {
            filters.set('custom', custom);
          }
        }
        return res.data || [];
      });
    },
    /**
     * Searches for data based on given criteria.
     *
     * @param {object} options - The options object.
     * @param {any} options.data - The data to search for.
     * @param {string} options.appendPath - The path to append to the data during search.
     * @param {IServerType} options.serverType - The type of server to search the data on.
     * @return {Promise} - A promise that resolves with the search results.
     */
    search({
      data,
      appendPath,
      serverType
    }) {
      return this.findAll({
        data,
        appendPath,
        serverType
      });
    },
    /**
     * Save data to the server.
     *
     * @param {Object} options - The options for saving the data.
     * @param {Object} options.data - The data to be saved.
     * @param {string} options.appendPath - The path to append to the base path.
     * @param {IServerType} options.serverType - The type of server to save the data to.
     * @param {string} options.method - The request method. Defaults to POST
     * @return {Promise} A promise that resolves to the saved data.
     */
    save({
      data,
      appendPath,
      serverType,
      method = 'POST'
    }) {
      // @TODO: test
      const path = this.getFullPath({
        pathName: 'createPath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax[method](path, data).then(res => {
        return res.data;
      });
    },
    /**
     * Saves a batch of data to the server.
     *
     * @param {Object} options - The options for saving the batch.
     * @param {any} options.data - The data to be saved.
     * @param {string} [options.appendPath='batch'] - The path to append to the base path.
     * @param {IServerType} options.serverType - The type of server to send the request to.
     *
     * @return {Promise} A promise that resolves with the saved data.
     */
    saveBatch({
      data,
      appendPath = 'batch',
      serverType
    }) {
      // @TODO: test
      const path = this.getFullPath({
        pathName: 'createPath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.POST(path, data).then(res => {
        return res.data;
      });
    },
    /**
     * Update method for sending PATCH request to server.
     *
     * @param {object} options - The options for updating data.
     * @param {object} options.data - The data to be updated.
     * @param {string} options.appendPath - The path to append to the base path.
     * @param {IServerType} options.serverType - The type of server to send request to.
     * @returns {Promise} A Promise that resolves to the updated data.
     */
    update({
      data,
      appendPath,
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'updatePath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.PATCH(path, data).then(res => {
        return res.data;
      });
    },
    /**
     * Updates a batch of data using PATCH method.
     *
     * @param {Object} options - The options for updating the batch.
     * @param {Object} options.data - The data to update.
     * @param {string} [options.appendPath='batch'] - The path to append to the base path.
     * @param {IServerType} options.serverType - The server type.
     *
     * @return {Promise} - A Promise that resolves with the updated data.
     */
    updateBatch({
      data,
      appendPath = 'batch',
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'updatePath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.PATCH(path, data).then(res => {
        return res.data;
      });
    },
    /**
     * Replaces data on the server.
     *
     * @param {Object} options - The options for replacing data.
     * @param {Object} options.data - The data to be replaced.
     * @param {string} options.appendPath - The path to append to the base path.
     * @param {IServerType} options.serverType - The type of server.
     *
     * @returns {Promise} A promise that resolves with the result of the replace operation.
     */
    replace({
      data,
      appendPath,
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'replacePath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.PUT(path, data).then(res => {
        if (this.afterUpdateRecord) {
          // trigger afterUpdate hook with raw response
          return this.afterUpdateRecord(res.data);
        } else {
          // return model data
          return _nventor.default.confirm.get(res.data, 'model', res.data);
        }
      });
    },
    /**
     * Removes a batch of data.
     *
     * @param {Object} options - The options object.
     * @param {Array} options.data - The data to be removed.
     * @param {string} [options.appendPath='batch'] - The path to append to the delete path.
     * @param {IServerType} options.serverType - The server type.
     * @returns {Promise} A Promise that resolves to the removed data.
     */
    removeBatch({
      data,
      appendPath = 'batch',
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'deletePath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.DELETE(path, data).then(res => {
        return res.data;
      });
    },
    /**
     * Removes data from the server.
     *
     * @param {Object} options - The options for the remove method.
     * @param {Object} options.data - The data to be removed from the server.
     * @param {string} [options.appendPath=''] - The additional path to be appended to the delete path.
     * @param {IServerType} options.serverType - The server type.
     *
     * @return {Promise} - A promise that resolves with the response data after the delete operation is complete.
     */
    remove({
      data,
      appendPath = '',
      serverType
    }) {
      const path = this.getFullPath({
        pathName: 'deletePath',
        appendPath,
        serverType
      });
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.DELETE(path, data).then(res => {
        const responseData = res.data;
        if (this.afterDeleteRecord) {
          // trigger afterDelete with raw response
          return this.afterDeleteRecord(responseData);
        } else {
          // return deleted keys
          if (R.is(Array(responseData))) {
            return responseData;
          }
          return _nventor.default.confirm.get(responseData, 'deleted', true);
        }
      });
    }
  });
  /**
   * The server type to use.
   * @typedef {''|'jobs'} IServerType
   * */
});