define("client/pods/components/dispatch/export-report-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DispatchExportReportBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _class = class DispatchExportReportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "server", _descriptor2, this);
      _initializerDefineProperty(this, "dateService", _descriptor3, this);
      _initializerDefineProperty(this, "excel", _descriptor4, this);
      _initializerDefineProperty(this, "export", _descriptor5, this);
      _initializerDefineProperty(this, "intl", _descriptor6, this);
      _initializerDefineProperty(this, "search", _descriptor7, this);
      _initializerDefineProperty(this, "fileName", _descriptor8, this);
      _initializerDefineProperty(this, "exportData", _descriptor9, this);
      _initializerDefineProperty(this, "today", _descriptor10, this);
      _initializerDefineProperty(this, "campaignKeys", _descriptor11, this);
      _initializerDefineProperty(this, "mm", _descriptor12, this);
      _initializerDefineProperty(this, "yyyy", _descriptor13, this);
      _initializerDefineProperty(this, "customDateStartZ", _descriptor14, this);
      _initializerDefineProperty(this, "customDateEndZ", _descriptor15, this);
      _initializerDefineProperty(this, "filters", _descriptor16, this);
      _initializerDefineProperty(this, "selectedCampaigns", _descriptor17, this);
      _initializerDefineProperty(this, "exportDateType", _descriptor18, this);
      _initializerDefineProperty(this, "customDateRangeInMonth", _descriptor19, this);
      _defineProperty(this, "adapterName", 'channels/shop/campaigns');
      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/filters');
      this.crud.addLists(this, ['reports/lists/export-date-type']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          count: 10
        }
      });
      this.filters = filters;
      this.today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD');
      this.fileName = this.getDefaultFilename();
      this.setDefaultPeriod();
    }
    setDefaultPeriod() {
      // set default month to last month
      const previousMonth = this.dateService.getPreviousPeriod();
      this.mm = previousMonth?.mm;
      this.yyyy = previousMonth?.yyyy;

      // need to set default customDate too?
    }
    getDefaultFilename() {
      return `${this.intl.t('campaign reports')}-${this.today}`;
    }
    *fetchDataTask({
      fetchMax = false
    }) {
      const campaignKeys = this.campaignKeys || [];
      let dateStart = '';
      let dateEnd = '';
      if (this.exportDateType === 'month') {
        const mm = this.mm;
        const yyyy = this.yyyy;
        if (mm && yyyy) {
          const monthEnd = this.dateService.getMonthEnd(yyyy, mm).format('YYYY-MM-DD');
          dateStart = `${yyyy}-${mm}-01`;
          dateEnd = monthEnd;
        }
      }
      if (this.exportDateType === 'customDate') {
        const customDateStartZ = R.clone(this?.customDateStartZ || '');
        const customDateEndZ = R.clone(this?.customDateEndZ || '');
        if (customDateStartZ) {
          dateStart = this.dateService.getMoment(customDateStartZ).format('YYYY-MM-DD');
          dateEnd = this.dateService.getMoment(customDateEndZ).format('YYYY-MM-DD');
        }
      }
      const response = yield this.server.callJobs('GET', 'api/protected/channels/shop/campaigns/export/reports', {
        exportDateType: this.exportDateType,
        campaignKeys,
        dateStart,
        dateEnd
      });
      const results = response?.data || [];
      const specsForSummary = [{
        column: this.intl.t('name'),
        prop: 'name'
      }, {
        column: this.intl.t('shop url'),
        prop: 'shopUrl'
      }, {
        column: this.intl.t('date start'),
        prop: 'dateStart'
      }, {
        column: this.intl.t('date end'),
        prop: 'dateEnd'
      }, {
        column: `${this.intl.t('order value')} (${this.intl.t('qty')})`,
        prop: 'report.revenue.invoices.docCount',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('order value')} (${this.intl.t('tax exclusive')})`,
        prop: 'report.revenue.net.exclTotal',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('order value')} (${this.intl.t('tax inclusive')})`,
        prop: 'report.revenue.net.inclTotal',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: this.intl.t('total commission'),
        prop: 'commissionsData.grandCommissionTotal',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('total commission')} (%)`,
        prop: 'commissionsData.commissionsPercentageAvg',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('dispatched')} (${this.intl.t('qty')})`,
        prop: 'report.segments.status.invoices',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const statusObj = R.find(R.pathEq(['segmentValue', 'dispatchStatus'], 'dispatched'))(value);
          if (RA.isNotNilOrEmpty(statusObj)) {
            // should be qty or docCount?
            return Number(statusObj?.docCount || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('dispatched')} (${this.intl.t('tax exclusive')})`,
        prop: 'report.segments.status.invoices',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const statusObj = R.find(R.pathEq(['segmentValue', 'dispatchStatus'], 'dispatched'))(value);
          if (RA.isNotNilOrEmpty(statusObj)) {
            return Number(statusObj?.postDiscExclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('dispatched')} (${this.intl.t('tax inclusive')})`,
        prop: 'report.segments.status.invoices',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const statusObj = R.find(R.pathEq(['segmentValue', 'dispatchStatus'], 'dispatched'))(value);
          if (RA.isNotNilOrEmpty(statusObj)) {
            return Number(statusObj?.postDiscInclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('undispatched')} (${this.intl.t('qty')})`,
        prop: 'report.segments.status.invoices',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const statusObj = R.find(R.pathEq(['segmentValue', 'dispatchStatus'], 'undispatched'))(value);
          if (RA.isNotNilOrEmpty(statusObj)) {
            // should be qty or docCount?
            return Number(statusObj?.docCount || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('undispatched')} (${this.intl.t('tax exclusive')})`,
        prop: 'report.segments.status.invoices',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const statusObj = R.find(R.pathEq(['segmentValue', 'dispatchStatus'], 'undispatched'))(value);
          if (RA.isNotNilOrEmpty(statusObj)) {
            return Number(statusObj?.postDiscExclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('undispatched')} (${this.intl.t('tax inclusive')})`,
        prop: 'report.segments.status.invoices',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const statusObj = R.find(R.pathEq(['segmentValue', 'dispatchStatus'], 'undispatched'))(value);
          if (RA.isNotNilOrEmpty(statusObj)) {
            return Number(statusObj?.postDiscInclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('export_paid-total')} (${this.intl.t('qty')})`,
        prop: 'report.segments.paidStatus.net',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const paidStatusObj = R.find(R.pathEq(['segmentValue'], 'paid'))(value);
          if (RA.isNotNilOrEmpty(paidStatusObj)) {
            // should be qty or docCount?
            return Number(paidStatusObj?.docCount || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('export_paid-total')} (${this.intl.t('tax exclusive')})`,
        prop: 'report.segments.paidStatus.net',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const paidStatusObj = R.find(R.pathEq(['segmentValue'], 'paid'))(value);
          if (RA.isNotNilOrEmpty(paidStatusObj)) {
            return Number(paidStatusObj?.postDiscExclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('export_paid-total')} (${this.intl.t('tax inclusive')})`,
        prop: 'report.segments.paidStatus.net',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const paidStatusObj = R.find(R.pathEq(['segmentValue'], 'paid'))(value);
          if (RA.isNotNilOrEmpty(paidStatusObj)) {
            return Number(paidStatusObj?.postDiscInclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('export_unpaid-total')} (${this.intl.t('qty')})`,
        prop: 'report.segments.paidStatus.net',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const paidStatusObj = R.find(R.pathEq(['segmentValue'], 'unpaid'))(value);
          if (RA.isNotNilOrEmpty(paidStatusObj)) {
            // should be qty or docCount?
            return Number(paidStatusObj?.docCount || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('export_unpaid-total')} (${this.intl.t('tax exclusive')})`,
        prop: 'report.segments.paidStatus.net',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const paidStatusObj = R.find(R.pathEq(['segmentValue'], 'unpaid'))(value);
          if (RA.isNotNilOrEmpty(paidStatusObj)) {
            return Number(paidStatusObj?.postDiscExclAmt || '0');
          }
          return 0;
        }
      }, {
        column: `${this.intl.t('export_unpaid-total')} (${this.intl.t('tax inclusive')})`,
        prop: 'report.segments.paidStatus.net',
        transform(value, row, spec) {
          if (RA.isNilOrEmpty(value)) {
            return 0;
          }
          const paidStatusObj = R.find(R.pathEq(['segmentValue'], 'unpaid'))(value);
          if (RA.isNotNilOrEmpty(paidStatusObj)) {
            return Number(paidStatusObj?.postDiscInclAmt || '0');
          }
          return 0;
        }
      }, {
        column: this.intl.t('page views'),
        prop: 'report.segments.activity.totals.views',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: this.intl.t('page views per person'),
        prop: 'report.segments.activity.totals.viewsPerPerson',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }, {
        column: this.intl.t('conversion rate'),
        prop: 'report.segments.activity.totals.conversion.persons.successRate',
        transform(value, row, spec) {
          if (RA.isNotNilOrEmpty(value)) {
            return Number(value);
          }
          return 0;
        }
      }];
      const worksheetData = R.map(row => {
        return R.map(spec => {
          let value;
          const path = spec.prop;
          if (!path) {
            value = '';
          }
          value = R_.dotPath(path)(row);
          if (spec.transform) {
            value = spec.transform(value, row, spec);
          }
          return value;
        })(specsForSummary);
      })(results || []);
      const headers = R.pluck('column')(specsForSummary);
      const exportData = {
        headers,
        data: worksheetData
      };
      this.exportData = exportData;
      return exportData;
    }
    *exportTask(onCloseDialogAction) {
      const exportData = yield this.fetchDataTask.perform({
        fetchMax: true
      });
      const headers = exportData.headers || [];
      const worksheetData = exportData.data || [];
      const sheetWithHeaders = R.prepend(headers)(worksheetData);
      const worksheets = [];
      worksheets.push({
        sheetName: this.today,
        data: sheetWithHeaders
      });
      yield this.excel.exportFile({
        worksheets,
        title: this.fileName
      });
      onCloseDialogAction();
    }
    *toggleCampaignStatusAndListByTask(campaignStatusToggled) {
      let newCampaignStatus = R.clone(campaignStatusToggled);
      const filters = this.filters;
      const currentCampaignStatus = filters.getData('campaignStatus') || '';
      if (currentCampaignStatus === campaignStatusToggled) {
        newCampaignStatus = '';
      }
      filters.setData('campaignStatus', newCampaignStatus);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters
      });
    }
    *fetchDefaultDataTask() {
      const filters = this.filters;
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters
      });
    }
    addSelectedCampaignLists(campaign) {
      const selectedCampaigns = this.selectedCampaigns;
      selectedCampaigns.pushObject(campaign);
    }
    removeSelectedCampaignLists(campaign) {
      const selectedCampaigns = this.selectedCampaigns;
      selectedCampaigns.removeObject(campaign);
    }
    get warningText() {
      const exportDateType = this?.exportDateType;
      if (!exportDateType) {
        return '';
      }
      if (exportDateType === 'campaignPeriod') {
        return 'will not export campaigns without start and end date';
      }
      if (exportDateType === 'customDate') {
        return 'max range 3 month';
      }
    }
    get customMinDateStartZ() {
      const customDateEndZ = this?.customDateEndZ || '';
      if (!customDateEndZ) {
        return '';
      }
      return this.dateService.getMoment(customDateEndZ).subtract(3, 'months').toISOString();
    }
    get customMaxDateEndZ() {
      const customDateStartZ = this?.customDateStartZ || '';
      if (!customDateStartZ) {
        return '';
      }
      return this.dateService.getMoment(customDateStartZ).add(3, 'months').toISOString();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "export", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "exportData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "today", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "campaignKeys", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "customDateStartZ", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "customDateEndZ", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "selectedCampaigns", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "exportDateType", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'campaignPeriod';
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "customDateRangeInMonth", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '3';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultPeriod", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultPeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getDefaultFilename", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultFilename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCampaignStatusAndListByTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCampaignStatusAndListByTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchDefaultDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addSelectedCampaignLists", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "addSelectedCampaignLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSelectedCampaignLists", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "removeSelectedCampaignLists"), _class.prototype), _class);
});