define("client/pods/components/forms/form-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "agtAo2HM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"loadControl\"]]],null,{\"statements\":[[0,\"  \"],[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\"],[[22,\"betaPath\"]]],{\"statements\":[[0,\"\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[22,\"isRunning\"]]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/section\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[14,1],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-section/template.hbs"
    }
  });
});