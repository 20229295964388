define("client/pods/dispatch/service", ["exports", "ramda", "ramda-adjunct", "ramda-extension", "ember-concurrency", "client/mixins/date", "client/config/environment"], function (_exports, R, RA, R_, _emberConcurrency, _date, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Service.extend(_date.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    server: Ember.inject.service(),
    constants: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tabsManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ifetch: Ember.inject.service(),
    tCatExtension: 't-cat',
    tCatModel: null,
    openWindow: null,
    showDocumentDetailsThumbnailImage: false,
    getAsDirty(model) {
      const docType = model.getData('docType');
      const docName = model.getData('docName');
      const adapterName = `documents/${docType}/${docName}`;
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      return dirty;
    },
    getDispatchProcesses({
      hasDrafts = false,
      hasAll = false,
      hasDispatched = false,
      results = [],
      defaultExportSheets = [],
      settings = {}
    } = {}) {
      let defaults = [];
      if (hasDrafts) {
        defaults = R.concat(defaults, [{
          _id: 'steps_draft',
          systemProcessId: 'steps_draft',
          isSystemProcess: true,
          isEditable: false,
          name: 'draft',
          code: 'draft',
          index: -3,
          defaultExportSheets,
          defaultExportCustomSheets: [],
          translate: true,
          isLocked: true,
          criterias: {
            status: 'draft'
          }
        }]);
      }
      defaults = R.concat(defaults, [{
        _id: 'steps_unprepared',
        systemProcessId: 'steps_unprepared',
        isSystemProcess: true,
        isEditable: true,
        name: 'unprepared',
        code: this.get('constants.dispatchProcess.unprepared'),
        index: 0,
        defaultExportSheets,
        defaultExportCustomSheets: [],
        translate: true,
        isLocked: true,
        criterias: {
          status: 'final',
          dispatchProcesses: [{
            criteria: 'equals',
            value: ''
          }, {
            criteria: 'equals',
            value: this.get('constants.dispatchProcess.unprepared')
          }]
        }
      }, {
        _id: 'steps_awaitingPayment',
        systemProcessId: 'steps_awaitingPayment',
        isSystemProcess: true,
        isEditable: true,
        name: 'awaiting payment',
        code: this.get('constants.dispatchProcess.awaitingPayment'),
        index: -2,
        defaultExportSheets,
        defaultExportCustomSheets: [],
        translate: true,
        isLocked: true,
        criterias: {
          status: 'final',
          dispatchProcesses: [{
            criteria: 'equals',
            value: this.get('constants.dispatchProcess.awaitingPayment')
          }]
        }
      }, {
        _id: 'steps_outOfStock',
        systemProcessId: 'steps_outOfStock',
        isSystemProcess: true,
        isEditable: true,
        index: -1,
        name: 'out of stock',
        code: this.get('constants.dispatchProcess.outOfStock'),
        status: 'active',
        defaultExportSheets,
        defaultExportCustomSheets: [],
        translate: true,
        isLocked: true,
        criterias: {
          status: 'final',
          dispatchProcesses: [{
            criteria: 'equals',
            value: this.get('constants.dispatchProcess.outOfStock')
          }]
        }
      }]);
      const isBetaAllowRecurringSchedules = settings?._data?.beta?.allowRecurringSchedules || false;
      const isFeaturesAllowRecurringSchedules = settings?._data?.features?.allowRecurringSchedules || false;
      if (isBetaAllowRecurringSchedules && isFeaturesAllowRecurringSchedules) {
        defaults = R.concat(defaults, [{
          _id: 'steps_awaitingRecurringPayment',
          systemProcessId: 'steps_awaitingRecurringPayment',
          isSystemProcess: true,
          isEditable: true,
          name: 'awaitingRecurringPayment',
          code: this.get('constants.dispatchProcess.awaitingRecurringPayment'),
          index: -3,
          defaultExportSheets,
          defaultExportCustomSheets: [],
          translate: true,
          isLocked: true,
          criterias: {
            status: 'final',
            dispatchProcesses: [{
              criteria: 'equals',
              value: this.get('constants.dispatchProcess.awaitingRecurringPayment')
            }]
          }
        }]);
      }
      if (hasDispatched) {
        defaults = R.concat(defaults, [{
          _id: 'steps_dispatched',
          systemProcessId: 'steps_dispatched',
          isSystemProcess: true,
          isEditable: true,
          isDispatched: true,
          allowEInvoices: true,
          allowEInvoicesForPaymentMethods: [],
          name: 'dispatched',
          code: this.get('constants.dispatchProcess.dispatched'),
          index: 10000,
          noPanel: true,
          defaultExportSheets,
          defaultExportCustomSheets: [],
          translate: true,
          isLocked: true,
          criterias: {
            status: 'final',
            dispatchProcesses: [{
              criteria: 'equals',
              value: this.get('constants.dispatchProcess.dispatched')
            }]
          }
        }]);
      }
      defaults = R.map(defaultData => {
        const settingsData = R.find(R.propEq('systemProcessId', defaultData.systemProcessId))(results) || {};
        return R.pipe(R.omit(['translate', 'code', 'name', 'isEditable']), R.mergeRight(defaultData))(settingsData);
      })(defaults);
      const customResults = R.reject(R.propEq('isSystemProcess', true))(results);
      defaults = R.concat(defaults, customResults);
      if (hasAll) {
        defaults = R.concat(defaults, [{
          _id: 'steps_All',
          systemProcessId: 'steps_All',
          isSystemProcess: true,
          isEditable: false,
          name: 'all',
          code: 'all',
          index: 1000,
          defaultExportSheets,
          defaultExportCustomSheets: [],
          noPanel: true,
          translate: true,
          isLocked: true,
          criterias: {
            dispatchProcesses: [{
              criteria: 'notEquals',
              value: this.get('constants.dispatchProcess.dispatched')
            }]
          }
        }]);
      }
      defaults = R.sortWith([R.ascend(R.prop('index'))])(defaults);
      return defaults;
    },
    findDispatchProcess(dispatchProcesses, dispatchProcessCode) {
      dispatchProcesses = dispatchProcesses || [];
      if (R.isEmpty(dispatchProcesses)) {
        return false;
      }
      return dispatchProcesses.findBy('_data.code', dispatchProcessCode);
    },
    fetchSpecsTask: (0, _emberConcurrency.task)(function* (component) {
      const specsCollection = yield this.crud.findTask.perform({
        adapterName: 'specs/collection'
      });
      if (component) {
        Ember.set(component, 'specsCollection', specsCollection);
      }
      return specsCollection;
    }),
    markAsPrintedTask: (0, _emberConcurrency.task)(function* (toPrintList, showMarkAsPrintedBtn, allowMarkAsPrinted) {
      if (showMarkAsPrintedBtn && allowMarkAsPrinted) {
        const payload = {
          batch: R.map(R.pipe(R.path(['model', '_data']), R.pick(['_key', '_id', 'docType', 'docName', 'printHistory', 'transporterExtension'])))(toPrintList)
        };
        return yield this.server.call('PATCH', 'api/protected/documents/sales/dispatch/printed/batch', payload);
      }
      return false;
    }),
    updateEntireDispatchProcessTask: (0, _emberConcurrency.task)(function* (filters, toDispatchProcess, toDispatchDateZ) {
      const toDispatchProcessCode = toDispatchProcess.getData('code');
      const toDispatchProcessLabel = toDispatchProcess.getData('name');
      const toDispatchProcessLabelTranslate = toDispatchProcess.getData('translate');
      const filtersData = filters.serialize();
      const payload = R.mergeRight({
        toDispatchProcess: toDispatchProcessCode,
        toDispatchDateZ,
        toDispatchProcessLabel,
        toDispatchProcessLabelTranslate
      }, filtersData);
      return yield this.server.call('PATCH', 'api/protected/documents/sales/dispatch/process/batch', payload);
    }),
    setDispatchProcessTask: (0, _emberConcurrency.task)(function* (model, dispatchProcess, dispatchDateZ) {
      // do not need dirty. always send as batch.
      // payload = {
      // dispatchDateZ,
      // dispatchProcess,
      // etc,
      // batch: [_key]
      // }
      if (R.is(Array, model)) {
        const models = model;
        const dispatchProcessCode = dispatchProcess.getData('code');
        const dispatchProcessLabel = dispatchProcess.getData('name');
        const dispatchProcessLabelTranslate = dispatchProcess.getData('translate');
        const batch = R.map(model => {
          return {
            docType: model.getData('docType'),
            docName: model.getData('docName'),
            _key: model.getData('_key')
          };
        }, models);
        const payload = {
          batch,
          toDispatchProcess: dispatchProcessCode,
          toDispatchDateZ: dispatchDateZ,
          toDispatchProcessLabel: dispatchProcessLabel,
          toDispatchProcessLabelTranslate: dispatchProcessLabelTranslate
        };
        return yield this.server.call('PATCH', 'api/protected/documents/sales/dispatch/process/batch', payload);
      } else {
        let confirmed = true;
        if (model.get('isDispatchedBeforeToday')) {
          const msg = this.intl.t('this may already have been dispatched on a previous day- are you sure you want to change its dispatch status');
          confirmed = window.confirm(msg);
        }
        if (confirmed) {
          const docType = model.getData('docType');
          const docName = model.getData('docName');
          const adapterName = `documents/${docType}/${docName}`;
          const dirty = this.getAsDirty(model);
          if (dispatchDateZ) {
            dirty.setData('dispatchDateZ', dispatchDateZ);
          }
          dirty.setData('dispatchProcess', dispatchProcess.getData('code'));
          dirty.setData('dispatchProcessLabel', dispatchProcess.getData('name'));
          dirty.setData('dispatchProcessLabelTranslate', dispatchProcess.getData('translate'));
          const appendPath = 'dispatch/process';
          const saved = yield this.dataManager.updateRecord({
            adapterName,
            appendPath,
            dirty
          });
          return [saved];
        }
      }
    }).enqueue().maxConcurrency(1),
    // NOTE: important, this cannot be more than 1 otherwise creating waybill numbers will have racing conditions

    tCatGenerateAndDownloadWaybillTask: (0, _emberConcurrency.task)(function* ({
      component,
      printType,
      documents,
      onAfter
    }) {
      const msg = this.intl.t('everytime you print it will generate a new Waybill number. are you sure you want to print?');
      const confirmed = window.confirm(msg);
      if (confirmed) {
        documents = RA.ensureArray(documents);
        if (RA.isNilOrEmpty(documents)) {
          return null;
        }
        const results = R.pipe(R.map(R.pipe(R.propOr({}, '_data'), R.pick(['_key', 'docType', 'docName']))))(documents);
        try {
          // The errors should be handled on the tabs manager
          if (component) {
            component.set('errors', []);
          }
          const result = yield this.server.call('POST', 'api/protected/extensions/apps/tw/transporters/t-cat', {
            transporterWaybillPrintType: printType,
            docs: results
          });
          const downloadFiles = R.pipe(R.propOr([], 'data'), R.map(data => this.tCatDownloadWaybill.perform(data)))(result);
          yield (0, _emberConcurrency.all)(downloadFiles);
        } catch (error) {
          const errors = R.pipe(R.pathOr([], ['data', 'errors']), RA.ensureArray)(error);
          if (component) {
            component.set('errors', errors);
          }
          const activeSubTab = this.tabsManager.getActiveSubTab(component.tab);
          activeSubTab.set('errors', errors);
          let errorMessages = R.pipe(R.map(R.pathOr('', ['Message'])), R.reject(RA.isNilOrEmpty), R.join('\n'))(errors);
          if (errorMessages) {
            R.pipe(R.pluck('Message'), R.map(R.pipe(R.split('執行失敗 -> 【第'), R.map(R.split('筆訂單資料')), R.flatten, R.nth(1), parseInt, R.dec)), R.forEachObjIndexed((value, index) => {
              const message = R.path([index, 'Message'])(errors);
              const docNo = R.path([value, '_data', 'docNo'])(documents);
              const ref = R.path([value, '_data', 'ref'])(documents);
              if (ref) {
                errors[index].Message = `#${docNo} | ${ref} - ${message}`;
              } else {
                errors[index].Message = `#${docNo} - ${message}`;
              }
              errors[index]._key = R.path([value, '_data', '_key'])(documents);
            }))(errors);
          } else {
            errorMessages = R.pipe(R.map(error => {
              const document = R.find(R.pathEq(['_data', '_key'], error._key))(documents);
              const docNo = R.pathOr('', ['_data', 'docNo'], document);
              const ref = R.pathOr('', ['_data', 'ref'], document);
              if (ref) {
                return `#${docNo} | ${ref}`;
              }
              return `#${docNo}`;
            }), R.join('\n'))(errors);
            const message = this.intl.t('error while generating waybill numbers');
            if (errorMessages) {
              errorMessages = `${message}:\n- ${errorMessages}`;
            } else {
              errorMessages = `${message}`;
            }
          }
          alert(errorMessages);
          throw error;
        }
        if (onAfter) {
          onAfter();
        }
      }
    }),
    tCatDownloadWaybill: (0, _emberConcurrency.task)(function* (result) {
      const transporterWaybillFileNumber = R.pathOr('', ['transporterWaybillFileNumber'])(result);
      const CustomerId = R.pathOr('', ['transporterCredentials', 'CustomerId'])(result);
      const CustomerToken = R.pathOr('', ['transporterCredentials', 'CustomerToken'])(result);
      if (RA.isNilOrEmpty(transporterWaybillFileNumber)) {
        return;
      }
      const obtNumbers = R.pipe(R.pathOr([], ['waybills']), R.map(R.pick(['OBTNumber'])))(result);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const raw = JSON.stringify({
        CustomerId,
        CustomerToken,
        FileNo: transporterWaybillFileNumber,
        Orders: obtNumbers
      });
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      const date = this.dateService.getMoment().format('YYYY-MM-DD_HH-mm-ss-SSSS');
      const filename = `黑貓_${date}.pdf`;

      // yield timeout(1000)

      yield fetch(_environment.default.tCat.url, requestOptions).then(async res => ({
        filename,
        blob: await res.blob()
      })).then(resObj => {
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([resObj.blob], {
          type: 'application/pdf'
        });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      }).catch(error => {
        console.error('DOWNLOAD ERROR', error);
      });
    }).enqueue(),
    tCatGetModel: (0, _emberConcurrency.task)(function* ({
      transporterKey = ''
    } = {}) {
      const transporters = this?.crud?.lists['contacts-transporters'] || [];
      let tCatModel = R.find(R.pathEq(['_data', 'transporterExtension'], 't-cat'))(transporters);
      if (transporterKey) {
        tCatModel = R.find(R.pathEq(['_data', '_key'], transporterKey))(transporters);
      }
      this.set('tCatModel', tCatModel);
      return tCatModel;
    }),
    tCatValidateDocAddress: (0, _emberConcurrency.task)(function* (document) {
      const documentData = R.pipe(R.propOr({}, '_data'), R.pick(['address', 'transporterExtension', 'docName', 'docType', '_key', '_id', '_rev']))(document);
      if (!document?._data?.isVoid) {
        const response = yield this.server.call('POST', 'api/protected/extensions/apps/tw/transporters/validate/address', documentData);
        return R.pipe(R.propOr({}, 'data'), R.pick(['postNumber', 'transporterSenderAddress', 'transporterSenderName', 'transporterSenderPostNumber', 'transporterSenderTelephone']))(response);
      }
    }),
    _handleWaybillErrors(error = []) {
      const errors = R.pipe(R.pathOr(error, ['data', 'errors']), RA.ensureArray)(error);
      let errorMessages = R.pipe(R.map(R.pathOr('', ['message'])), R.reject(RA.isNilOrEmpty), R.join('\n'))(errors);
      if (errorMessages) {
        errorMessages = R.pipe(R.map(error => {
          const document = error.doc || R.find(R.pathEq(['_data', '_key'], error._key))(documents);
          const docNo = R.pathOr('', ['docNo'], document) || R.pathOr('', ['_data', 'docNo'], document);
          const ref = R.pathOr('', ['ref'], document) || R.pathOr('', ['_data', 'ref'], document);
          let message = `#${docNo}`;
          if (ref) {
            message = `#${docNo} | ${ref}`;
          }
          if (error.type && error.context) {
            message += ` | ${this.intl.t(error.type, error.context)}`;
          } else {
            message += ` | ${error.message}`;
          }
          return message;
        }), R.join('\n'))(errors);
      } else {
        errorMessages = R.pipe(
        // R.map(error => {
        //   const document = R.find(
        //     R.pathEq(['_data', '_key'], error._key)
        //   )(documents)
        //   const docNo = R.pathOr('', ['_data', 'docNo'], document)
        //   const ref = R.pathOr('', ['_data', 'ref'], document)
        //   if (ref) {
        //     return `#${docNo} | ${ref}`
        //   }
        //   return `#${docNo}`
        // }),
        R.join('\n'))(errors);
        const message = this.intl.t('error while generating waybill numbers');
        errorMessages = `${message}:\n- ${errorMessages}`;
      }
      alert(errorMessages);
      throw error;
    },
    hctGenerateWaybillTask: (0, _emberConcurrency.task)(function* ({
      component,
      documents,
      onAfter,
      isNewWaybill,
      transporterExtension,
      isUpdate
    } = {}) {
      let msg;
      if (isNewWaybill) {
        msg = 'everytime you print it will generate a new Waybill number. are you sure you want to print?';
      } else if (isUpdate) {
        // When update ignore documents with expiry waybills
        documents = R.reject(R.propEq('hasExpiredWaybill', true))(documents);
        msg = 'update waybills with the latest changes. the update will omit any expired waybill. are you sure you want to update?';
      }
      const translatedMsg = this.intl.t(msg);
      const confirmed = window.confirm(translatedMsg);
      if (!confirmed) {
        return null;
      }
      documents = R.pipe(RA.ensureArray, R.map(documentModel => {
        documentModel.set('_data.isNewWaybill', isNewWaybill);
        documentModel.set('_data.isUpdate', isUpdate);
        return documentModel;
      }))(documents);
      if (RA.isNilOrEmpty(documents)) {
        return null;
      }
      try {
        // The errors should be handled on the tabs manager
        if (component && component.set) {
          component.set('errors', []);
        }
        const result = yield this.crud.updateBatchTask.perform({
          adapterName: `documents/waybills`,
          batch: documents
        });
        return result?.data || [];
      } catch (error) {
        this._handleWaybillErrors(error);
      }
      if (onAfter) {
        onAfter();
      }
    }),
    hctFetchWaybillRecordTask: (0, _emberConcurrency.task)(function* ({
      component,
      documents,
      transporterExtension = 'hct',
      subTab,
      onPrintDialogAction,
      waybillsPerPage,
      filtersData = {}
    } = {}) {
      const hasDocs = RA.isNotNilOrEmpty(documents);
      if (!hasDocs) {
        return;
      }
      documents = RA.ensureArray(documents);
      const docs = R.pipe(R.map(document => {
        let documentData = R.prop('_data')(document);
        if (!documentData) {
          documentData = document;
        }
        return {
          resource: `${documentData?.docType}-${documentData?.docName}`,
          resourceKey: documentData?._key
        };
      }))(documents);
      try {
        let result; // = yield this.server.getAdapter('documents/waybills').downloadBatch(documents)

        const supportPDFDownload = [this.constants.transporterExtensions.sevenEleven, this.constants.transporterExtensions.payuniSevenEleven];
        const shouldDownloadPDF = supportPDFDownload.includes(transporterExtension);
        if (shouldDownloadPDF) {
          return yield this.ifetch.iFetch({
            method: 'POST',
            path: `api/protected/extensions/apps/tw/transporters/${transporterExtension}/waybill/download`,
            data: {
              docs,
              waybillsPerPage,
              filtersData
            }
          });
        } else {
          // Split calls to fetch waybills
          const fetchArray = R.pipe(R.splitEvery(15), R.map(batch => {
            return this.server.call('GET', `api/protected/extensions/apps/tw/transporters/${transporterExtension}/waybill`, {
              docs: batch
            });
          }))(docs);
          const resultArray = yield (0, _emberConcurrency.all)(fetchArray);
          const data = R.pipe(R.map(R.propOr([], 'data')), R.flatten)(resultArray);
          result = {
            data
          };
        }
        return result;
      } catch (error) {
        console.error(error);
      }
    }).enqueue().maxConcurrency(15),
    generateAndDownloadWaybillsTask: (0, _emberConcurrency.task)(function* ({
      component,
      documents,
      transporterExtension = '',
      isNewWaybill = false,
      onlyFetch = false,
      onlyGenerate = false,
      isUpdate = false,
      onAfter,
      waybillsPerPage,
      filtersData = {}
    } = {}) {
      let result;
      const allowTransporterExtension = R.anyPass([R.equals(this.constants.transporterExtensions.hct), R.equals(this.constants.transporterExtensions.sevenEleven), R.equals(this.constants.transporterExtensions.payuniSevenEleven)])(transporterExtension);
      if (!allowTransporterExtension) {
        return;
      }
      const response = yield this.hctGenerateWaybillTask.perform({
        transporterExtension,
        component,
        documents,
        isNewWaybill,
        isUpdate
      });
      const successful = R.propOr([], 'successful')(response);
      const unsuccessful = R.propOr([], 'unsuccessful')(response);
      result = yield this.hctFetchWaybillRecordTask.perform({
        transporterExtension,
        component,
        documents: successful,
        waybillsPerPage,
        filtersData
      });
      if (unsuccessful.length) {
        return response;
      }
      if (onAfter) {
        onAfter(result);
      }
      return result;
    }).enqueue(),
    removeWaybillsTask: (0, _emberConcurrency.task)(function* ({
      component,
      documents,
      transporterExtension = '',
      isNewWaybill = false
    } = {}) {
      try {
        let result;
        if (transporterExtension === this.constants.transporterExtensions.hct) {
          yield this.hctGenerateWaybillTask.perform({
            component,
            documents,
            isNewWaybill
          });
        }
        return result;
      } catch (error) {
        console.error(error);
      }
    }).drop(),
    hctRemoveWaybillTask: (0, _emberConcurrency.task)(function* ({
      documents
    } = {}) {
      documents = RA.ensureArray(documents);
      if (RA.isNilOrEmpty(documents)) {
        return null;
      }
      const results = R.pipe(R.map(R.pipe(R.propOr({}, '_data'), R.pick(['_key', 'docType', 'docName', 'waybill', 'transporterWaybillRecord']))))(documents);
      try {
        yield this.server.call('DELETE', 'api/protected/extensions/apps/tw/transporters/hct/waybill', {
          docs: results
        });
      } catch (error) {}
    }),
    downloadPdf: (0, _emberConcurrency.task)(function* ({
      name = '黑貓',
      rawResponse,
      rawResponseBuffer
    } = {}) {
      const date = this.dateService.getMoment().format('YYYY-MM-DD_HH-mm-ss');
      const filename = `${name}_${date}.pdf`;

      // blob = blob || yield rawResponse.blob()

      // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
      const newBlob = new Blob([rawResponseBuffer], {
        type: 'application/pdf'
      });

      // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const objUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    }),
    /**
     * Updates the convenience store URL.
     * @deprecated
     * @async
     * @function updateConvenienceStoreUrl
     * @param {object} options - The options for updating the convenience store URL.
     * @param {object} options.model - The model containing the data.
     * @param {string} [options.transporterKey=''] - The transporter key.
     * @param {string} [options.personId=''] - The person ID.
     * @returns {Promise} - The promise that resolves to the response data.
     */
    updateConvenienceStoreUrl: (0, _emberConcurrency.task)(function* ({
      model,
      transporterKey = '',
      personId = ''
    } = {}) {
      const documentId = model?._data?._id || '';
      const documentKey = model?._data?._key || '';
      const docType = model?._data?.docType || '';
      const docName = model?._data?.docName || '';
      transporterKey = transporterKey || model?._data?.transporterKey || '';
      const data = {
        documentId,
        documentKey,
        docType,
        docName,
        personId
      };
      const path = `api/protected/extensions/apps/tw/transporters/${transporterKey}`;
      const response = yield this.ifetch.iFetch({
        method: 'PATCH',
        path,
        data
      });
      return response.data;
    }),
    setFiltersBasedOnDispatchProcessModel({
      filters,
      model,
      groupDocumentsByTransporter
    }) {
      if (RA.isNilOrEmpty(filters)) {
        return;
      }
      if (RA.isNilOrEmpty(model)) {
        return;
      }
      const sortBy = model?._data?.sortBy || 'none';
      const sortByDirection = model?._data?.sortByDirection || 'DESC';
      // const groupProductByTransporter  = model?._data?.groupProductByTransporter || false
      filters.setData('sort', []); // reset => remove all sort first
      if (groupDocumentsByTransporter) {
        // use transporterLabel and account instead of transporterExtension?
        filters.addSortNoModel('transporterLabel', 'ASC');
        filters.addSortNoModel('transporterAccount', 'ASC');
        filters.addSortNoModel('transporterAccountNumber', 'ASC');
      }
      filters.addSortNoModel(sortBy, sortByDirection);
      const dateHeader = R.pipe(R_.dotPathOr([], 'crud.lists.dispatch-lists-date-header'), R.find(R.pathEq(['_data', 'value'], sortBy)))(this);
      if (RA.isNotNilOrEmpty(dateHeader)) {
        filters.setData('dateHeaderValue', dateHeader?._data?.value);
        filters.setData('dateHeaderLabel', dateHeader?._data?.label);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "_handleWaybillErrors", [_dec], Object.getOwnPropertyDescriptor(_obj, "_handleWaybillErrors"), _obj), _applyDecoratedDescriptor(_obj, "setFiltersBasedOnDispatchProcessModel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setFiltersBasedOnDispatchProcessModel"), _obj), _obj));
});