define("client/pods/components/elements/element-btn-with-modal-dialog/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: 'span',
    tabindex: 0,
    translate: true,
    modalDialogTranslate: true,
    classNames: ['element-btn-with-modal-dialog'],
    modalDialogIsVisible: false,
    modalDialogClassName: 'has-text-left',
    modalDialogTitle: '',
    closeOnOverlayClick: true,
    isSetTypeModalVisible: false,
    hasToggleIcon: false,
    hasToggleIconRight: true,
    init() {
      this._super(...arguments);
      this.set('alreadyClosed', false);
      if (this.onInit) {
        this.onInit(this);
      }
      if (this.initialModalDialogIsVisible) {
        this.set('modalDialogIsVisible', this.initialModalDialogIsVisible);
      }
    },
    didInsertElement() {
      this._super(...arguments);
      if (!this.modalDialogTitle) {
        this.set('modalDialogTitle', this.label);
        this.set('modalDialogTranslate', this.translate);
      }
      if (this.onDidInsert) {
        this.onDidInsert(this);
      }
    },
    showModalDialog() {
      this.set('modalDialogIsVisible', true);
    },
    submitTask: (0, _emberConcurrency.task)(function* () {
      if (this?.isDestroyed === false) {
        if (!this.disabled) {
          this.set('alreadyClosed', false);
          if (this.onSubmit) {
            yield this.onSubmit();
          }
        }
      }
    }).drop(),
    toggleAndSubmitTask: (0, _emberConcurrency.task)(function* () {
      if (this?.isDestroyed === false) {
        if (!this.disabled) {
          this.showModalDialog();
          this.set('alreadyClosed', false);
          if (this.onSubmit) {
            yield this.onSubmit();
          }
        }
      }
    }).drop(),
    actions: {
      closeDialog() {
        if (this?.isDestroyed === false) {
          this.set('modalDialogIsVisible', false);
          if (this.onClose) {
            if (!this.alreadyClosed) {
              this.set('alreadyClosed', true);
              return this.onClose();
            }
          }
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "showModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "showModalDialog"), _obj), _obj));
});