define("client/helpers/notEmpty", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params) {
      const obj = params[0];
      const prop = params[1];
      let value;
      if (obj.get) {
        value = obj.get(prop);
      } else {
        value = obj[prop];
      }
      if (R.is(Array, value) || R.is(Object)) {
        return !R.isEmpty(value);
      }
      if (value) {
        return false;
      }
      return true;
    }
  });
});