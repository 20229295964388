define("client/pods/components/elements/element-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "17Px3XOC",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@disabled\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"role\",\"checkbox\"],[12,\"class\",[29,[\"element-checkbox \",[28,\"if\",[[23,3,[]],\"element-checkbox--disabled\"],null]]]],[13,4],[3,\"on\",[\"click\",[23,0,[\"toggle\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isSelected\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"element-checkbox--checked\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"elements/element-icon\",[],[[\"@icon\"],[\"fas fa-check-square\"]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"element-checkbox--unchecked\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"elements/element-icon\",[],[[\"@icon\"],[\"far fa-square\"]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[25,2],[23,1,[]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"element-checkbox__label\"],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"tt\",[[23,1,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-checkbox/template.hbs"
    }
  });
});