define("client/pods/components/files/image-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "D45lI6Uy",
    "block": "{\"symbols\":[\"@imageClassNames\",\"@alt\",\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"showImage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",false],[12,\"loading\",\"lazy\"],[12,\"class\",[29,[\"files__image-item \",[23,1,[]]]]],[12,\"src\",[23,0,[\"src\"]]],[12,\"alt\",[23,2,[]]],[13,3],[3,\"action\",[[23,0,[]],\"onSubmit\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/image-item/template.hbs"
    }
  });
});