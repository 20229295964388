define("client/pods/components/guests/campaign-main/campaign-products/component", ["exports", "ember-concurrency", "ramda-adjunct"], function (_exports, _emberConcurrency, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    guests: Ember.inject.service(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.fetchTask.perform();
    },
    fetchTask: (0, _emberConcurrency.task)(function* () {
      // const filters = this.search.setupFilters({ adapterName: 'guests/shop/campaigns/filters' })
      const token = this.get('guests').getToken();
      // filters.set('token', token)
      // filters.set('debug', true)
      const searchSettings = {
        adapterName: 'guests/shop/campaigns',
        token
      };
      let campaign;
      try {
        campaign = yield this.crud.findTask.perform(searchSettings);
        if (RA.isNilOrEmpty(campaign)) {
          throw Error('missing campaign');
        }
      } catch (error) {
        return this.guests.logout();
      }
      this.set('campaign', campaign);
      const master = campaign.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          master,
          appendPath: `/master/${master}`
        }
      });
      this.set('filters', filters);

      // const params = {
      //   // token,
      //   report: 'campaigns',
      //   status: this.get('constants.documentsStatus.final'),
      //   dispatchStatus: this.dispatchStatusFilter,
      //   segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'contact', 'item', 'itemWithGroupedSetWithChoices', 'trackingCodes', 'paymentMethodKey', 'status']
      // }
      // const report = yield this.guests.call('GET', 'api/guests/channels/shop/campaigns/report', params)

      // this.set('report', report.data)
    }),
    fetchCampaignDetailsTask: (0, _emberConcurrency.task)(function* () {
      const model = this.campaign;
      const master = model.getData('_key');
      let details = [];
      if (master) {
        try {
          details = yield this.crud.searchRecordsTask.perform({
            adapterName: 'guests/shop/campaigns/details',
            appendPath: `/master/${master}`,
            filters: this.filters
          });
        } catch (error) {
          return this.guests.logout();
        }
      }
      model.set('details', details);
      this.set('results', details);
    })
  });
});