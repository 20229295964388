define("client/pods/documents/details/calculations-always-inclusive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * returns line amt (qty * price), irrespective of exclusive/inclusive
     * @return {object}  lineAmt as a Big class
     */
    getLineAmtBig() {
      const qty = this.getDataBig('qty');
      const price = this.getDataBig('inclPrice');
      let lineAmtBig = qty.times(price);
      return this._deductDiscount(lineAmtBig);
    },
    /**
     * calculate line totals
     */
    calculateLineTotals({
      isTaxExclusive,
      hasTaxMethod
    }) {
      // only calculate if has details
      if (isTaxExclusive) {
        // prices are exclusive
        this._calculateAndSetInclPrice(hasTaxMethod);
      } else {
        this._calculateAndSetExclPrice(hasTaxMethod);
      }
      this._calculateLineWhenPricesAreInclusive(hasTaxMethod);
      this._calculateLineCommission();
    },
    _calculateAndSetInclPrice(hasTaxMethod) {
      const {
        roundingInventoryPrice
      } = this.getRounding();
      let taxRate = 0;
      if (hasTaxMethod) {
        taxRate = this.getData('taxRate') || 0;
      }
      const taxRateBig = this.newBig(taxRate);
      const inclPrice = this.getDataBig('price').times(taxRateBig.plus(1)).toFixed(roundingInventoryPrice);
      this.setData('inclPrice', inclPrice);
    },
    _calculateAndSetExclPrice(hasTaxMethod) {
      const {
        roundingInventoryPrice
      } = this.getRounding();
      let taxRateBig = this.newBig(0);
      if (hasTaxMethod) {
        taxRateBig = this.getDataBig('taxRate');
      }
      const exclPrice = this.getDataBig('price').div(taxRateBig.plus(1)).toFixed(roundingInventoryPrice);
      this.setData('exclPrice', exclPrice);
    },
    _calculateLineWhenPricesAreInclusive(hasTaxMethod) {
      // number of decimal places to round
      const {
        roundingCurrency,
        unroundedCurrency
      } = this.getRounding();
      let lineAmtBig = this.getLineAmtBig();
      let taxRateBig = this.newBig(0);
      if (hasTaxMethod) {
        taxRateBig = this.getDataBig('taxRate');
      }
      const inclAmt = lineAmtBig.toFixed(roundingCurrency);
      // use unrounded lineAmt to do tax calculations
      const taxAmtBig = lineAmtBig.times(taxRateBig).div(taxRateBig.plus(1));
      const taxAmt = taxAmtBig.toFixed(unroundedCurrency);
      // use rounded tax amt to calculate excl amt
      const exclAmt = lineAmtBig.minus(taxAmt).toFixed(roundingCurrency);
      this.setData('exclAmt', exclAmt);
      this.setData('taxAmt', taxAmt);
      this.setData('inclAmt', inclAmt);
    }
  });
});