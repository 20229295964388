define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-commissions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yOdcmRxQ",
    "block": "{\"symbols\":[\"@dirty\",\"@errorMsg\",\"@headerBottom\",\"@model\",\"@onAssignSalesPerson\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\",\"@allowDuplicating\",\"@copyErrors\",\"@duplicateRecordTask\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor\",[],[[\"@dirty\",\"@errorMsg\",\"@headerBottom\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@onAssignSalesPerson\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\"],[[23,1,[]],[23,2,[]],[23,3,[]],false,true,[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@hasEditBtn\",\"@model\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\"],[[23,12,[]],[23,13,[]],[23,1,[]],[23,14,[]],[23,2,[]],true,[23,4,[]],[23,5,[]],[23,15,[]],[23,16,[]],[23,17,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-commissions/template.hbs"
    }
  });
});