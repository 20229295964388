define("client/pods/grapesjs/plugins/picture-layer-container", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = editor => {
    const {
      DomComponents,
      BlockManager,
      Commands
    } = editor;
    const bigordrPictureWrapper = 'bigordr-picture-layer-container';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', bigordrPictureWrapper])(editor);
    const {
      onDbClick,
      onDrop,
      intl = {
        t: txt => txt
      },
      isReadonly,
      /** @type {Categories} */
      categories
    } = options;
    const bigordrPictureBlock = `${bigordrPictureWrapper}-block`;
    const bigordrPictureBlockLabel = intl.t(`${bigordrPictureWrapper}-block`);
    DomComponents.addType(bigordrPictureWrapper, {
      model: {
        defaults: {
          classes: [bigordrPictureWrapper],
          tagName: 'div',
          name: bigordrPictureBlockLabel,
          // style: {
          //   position: 'relative',
          // },
          components: [{
            bigordrPictureOptions: {
              'data-source-mobile': {
                droppable: false,
                draggable: false,
                selectable: false
              }
            },
            imgType: 'full-width-image',
            type: 'full-width-image',
            droppable: false,
            draggable: false,
            selectable: false,
            src: ''
          }, {
            type: 'row',
            removable: false,
            draggable: false,
            style: {
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              padding: '15px 0 15px 0',
              margin: '0 5% 0 5%',
              display: 'flex',
              height: '100%',
              width: '90%'
            },
            components: [{
              type: 'cell',
              classes: ['bigordr-cell'],
              style: {
                height: 'auto',
                'min-height': '100px',
                margin: 'auto 0 auto 0',
                display: 'block'
              },
              removable: false,
              draggable: false
            }]
          }],
          traits: [{
            type: 'bigordr-animation-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }, {
            type: 'bigordr-link-trait'
          }]
        }
      }
    });
    BlockManager.add(bigordrPictureBlock, {
      label: bigordrPictureBlockLabel,
      category: categories.complex,
      content: {
        type: bigordrPictureWrapper
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
    <svg width="100%" height="100%" viewBox="0 0 1024 530" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
    <g transform="matrix(1.08669,0,0,1.08669,-51.5659,-291.386)">
        <g>
            <g transform="matrix(1.37733,0,0,1.65228,-180.565,-864.206)">
                <path d="M207.809,832.911L800.909,832.911" style="fill:none;stroke:black;stroke-width:27.22px;"/>
            </g>
            <g transform="matrix(1,0,0,1,118.44,399.43)">
                <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:397.95px;stroke-linecap:square;"/>
                </g>
                <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:397.95px;stroke-linecap:square;"/>
                </g>
            </g>
            <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,918.773,624.57)">
                <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:397.95px;stroke-linecap:square;"/>
                </g>
                <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:397.95px;stroke-linecap:square;"/>
                </g>
            </g>
        </g>
        <g transform="matrix(1,0,0,1,5.14536,37.2616)">
            <g>
                <g transform="matrix(1.00794,0,0,0.792537,12.4421,44.5258)">
                    <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:35.52px;stroke-linecap:square;stroke-linejoin:miter;"/>
                </g>
                <g transform="matrix(1.08069,0,0,1.08069,-48.6111,-30.4861)">
                    <g transform="matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)">
                        <circle cx="567.803" cy="388.754" r="27.858" style="fill:rgb(209,209,209);stroke:rgb(209,209,209);stroke-width:16.79px;"/>
                    </g>
                    <g transform="matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)">
                        <g transform="matrix(-0.93623,0,0,0.939727,1014.78,130.716)">
                            <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z" style="fill:rgb(209,209,209);"/>
                        </g>
                        <g transform="matrix(-1.01027,0,0,0.756475,916.709,228.489)">
                            <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z" style="fill:rgb(209,209,209);"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g transform="matrix(4.61671e-17,0.753966,-0.753966,4.61671e-17,897.629,-102.853)">
        <g transform="matrix(6.58553e-17,-1.0755,0.673667,4.12502e-17,129.874,1059.88)">
            <path d="M809.5,237.609L209.331,237.609L209.331,745L809.5,745L809.5,237.609ZM770.022,300.635L770.022,681.974C770.022,681.974 248.809,681.974 248.809,681.974C248.809,681.974 248.809,300.635 248.809,300.635C376.488,300.635 770.022,300.635 770.022,300.635L770.022,300.635Z"/>
        </g>
        <g transform="matrix(1.24867,9.12172e-33,-9.12172e-33,1.24867,-367.618,-116.939)">
            <g transform="matrix(1.09375,0,0,1.09375,114.921,30.8775)">
                <circle cx="608.595" cy="433.442" r="79.613" style="stroke:black;stroke-width:14.57px;stroke-linecap:square;"/>
            </g>
            <g transform="matrix(3.30005e-17,-0.538939,0.538939,3.30005e-17,348.287,642.276)">
                <text x="172.705px" y="901.151px" style="font-family:'ArialMT', 'Arial', sans-serif;font-size:281.165px;fill:white;stroke:white;stroke-width:19.71px;stroke-linecap:butt;stroke-miterlimit:2;">+</text>
            </g>
        </g>
    </g>
</svg>
<div class="block-label">${model.get('label')}</div>
</div>`
    });
  };
  _exports.default = _default;
});