define("client/pods/components/extensions/apps/tw/invoices/invoices-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wb66pmvg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"invoice-editor u-no-print\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isEditing\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"extensions/apps/tw/invoices/invoices-editor\",null,[[\"model\",\"isEditing\"],[[24,[\"model\"]],[24,[\"isEditing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[24,[\"model\",\"_data\",\"number\"]],false],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[28,\"currency\",[[24,[\"model\",\"_data\",\"inclTotal\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[28,\"dateTime\",[[24,[\"model\",\"_data\",\"dateLocal\"]]],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\"],[0,\"\\t\\t\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"edit\",[28,\"action\",[[23,0,[]],\"edit\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"cancelled\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"isRunning\",\"onSubmit\"],[\"undo cancel invoice\",[24,[\"undoCancelInvoiceTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"undoCancelInvoiceTask\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"isRunning\",\"onSubmit\"],[\"cancel invoice\",[24,[\"cancelInvoiceTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"cancelInvoiceTask\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/invoices-display/template.hbs"
    }
  });
});