define("client/pods/components/settings/settings-editor/shipping-editor/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SettingsSettingsEditorShippingEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('date'), _dec4 = Ember._action, _class = class SettingsSettingsEditorShippingEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor2, this);
      _initializerDefineProperty(this, "dateService", _descriptor3, this);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/locations', 'channels/shop/shipping-zones/all', 'extensions/apps/tw/convenience-stores/lists/brands', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'channels/shop/shipping-zones/lists/rate-method', 'channels/shop/shipping-zones/lists/rate-method/free-shipping', 'extensions/apps/tw/hct/print/waybills', 'filters/count', 'dispatch/lists/dispatch-sort', 'dispatch/lists/dispatch-sort-direction', 'extensions/apps/tw/seven-eleven/lists/waybill-print-per-page-b2c', 'extensions/apps/tw/seven-eleven/lists/waybill-print-per-page-c2c']);
    }
    openTab() {
      this.tabsManager.openTab('left', {
        component: 'channels/shop/shipping-zones/tab-container',
        title: 'shipping zones'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openTab", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "openTab"), _class.prototype), _class);
});