define("client/pods/components/channels/shop/items/item-price/item-price-current/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    currentPrice: Ember.computed('price', 'item.currentPrice', function () {
      if (this.price) {
        return this.price;
      }
      return this.get('item.currentPrice');
    }),
    currentIsOnSale: Ember.computed('onSale', 'item.isOnSale', function () {
      if (this.onSale) {
        return this.onSale;
      }
      return this.get('item.isOnSale');
    }),
    currentHasPriceRange: Ember.computed('hasPriceRange', 'item.hasPriceRange', 'item.synced', function () {
      if (this.hasPriceRange) {
        return this.hasPriceRange;
      }
      return this.get('item.hasPriceRange');
    })
  });
});