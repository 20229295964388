define("client/pods/channels/shop/shipping-zones/rates/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    init() {
      this.set('requiredAttrs', ['min', 'max', 'rate']);
      this._super(...arguments);
    }
  });
});