define("client/pods/components/messages/tab-container/messages-window/message-bubble/file-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EGNLCeg+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"messageUrl\"]],[11,\"download\",[22,\"messageName\"]],[10,\"target\",\"_blank\"],[8],[0,\"\\n  \"],[1,[28,\"elements/btn-save\",null,[[\"translate\",\"icon\",\"label\",\"btnClassNames\"],[false,\"fas fa-file-alt\",[24,[\"messageName\"]],\"tag is-warning\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/messages-window/message-bubble/file-type/template.hbs"
    }
  });
});