define("client/pods/components/elements/btn-save/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nxHkN3EK",
    "block": "{\"symbols\":[\"@badge\",\"@debug\",\"@disabled\",\"@hasBadge\",\"@iconRight\",\"@isRunning\",\"@label\",\"@onSubmit\",\"@requiresConfirmation\",\"@confirmMsg\",\"@confirmMsgTranslate\"],\"statements\":[[5,\"elements/element-btn\",[],[[\"@badge\",\"@classNames\",\"@debug\",\"@disabled\",\"@hasBadge\",\"@icon\",\"@iconRight\",\"@isRunning\",\"@label\",\"@onSubmit\",\"@tooltipLabel\",\"@translate\",\"@requiresConfirmation\",\"@confirmMsg\",\"@confirmMsgTranslate\"],[[23,1,[]],[23,0,[\"btnClassNames\"]],[23,2,[]],[23,3,[]],[23,4,[]],[23,0,[\"defaultIcon\"]],[23,5,[]],[23,6,[]],[23,7,[]],[28,\"fn\",[[23,0,[\"submit\"]],[23,8,[]]],null],[23,0,[\"tooltipLabel\"]],[23,0,[\"translate\"]],[23,9,[]],[23,10,[]],[23,11,[]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/btn-save/template.hbs"
    }
  });
});