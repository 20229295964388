define("client/pods/components/search/results-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    results: null,
    classNames: ['search-results-list']

    // classNameBindings: ['isRetracted:search-results-list--retracted'],

    // isRetracted: Ember.computed('results', function () {
    //
    //   //@TODO: test
    //
    //   if (Ember.isEmpty(this.get('results'))) {
    //
    //     return true;
    //   }
    //
    //   return false;
    // }),
  });
});