define("client/pods/components/layouts/panels/panel-footer/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LayoutsPanelsPanelFooterComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class LayoutsPanelsPanelFooterComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "defaultParentContainerClassName", _descriptor2, this);
      _initializerDefineProperty(this, "element", _descriptor3, this);
      _initializerDefineProperty(this, "hasLoaded", _descriptor4, this);
      _initializerDefineProperty(this, "hasOverflowX", _descriptor5, this);
      // @tracked lastHasOverflowXState = false
      _initializerDefineProperty(this, "originalScrollWidth", _descriptor6, this);
      _initializerDefineProperty(this, "overFlowXWidth", _descriptor7, this);
      _initializerDefineProperty(this, "showSub", _descriptor8, this);
      _defineProperty(this, "resizeObserver", null);
      _defineProperty(this, "mutationObserver", null);
      _defineProperty(this, "hasAllBtnsLoaded", false);
      _defineProperty(this, "debounceWait", 150);
      // Adjust this value as needed
      _defineProperty(this, "isCheckingOverflow", false);
    }
    didInsert(paerntFooterElement) {
      const element = paerntFooterElement.firstElementChild;
      this.element = element;
      const debouncedResize = () => {
        Ember.run.debounce(this, this.checkHasOverflowX, this.debounceWait);
      };
      window.addEventListener('resize', debouncedResize);
      this.resizeObserver = new window.ResizeObserver(R.pipe(R.head,
      // Take first entry
      () => Ember.run.debounce(this, this.checkHasOverflowX, this.debounceWait)));
      this.resizeObserver.observe(element);
      this.mutationObserver = new window.MutationObserver(() => {
        const hasLoaded = this.checkHasLoaded();
        if (hasLoaded) {
          this.checkHasOverflowX();
          this.mutationObserver.disconnect();
          this.mutationObserver = null;
        }
      });
      this.mutationObserver.observe(element, {
        childList: true,
        subtree: true,
        attributes: true
      });
    }
    willDestroyElement() {
      if (this.isDestroyed) {
        return;
      }
      window.removeEventListener('resize', this.checkHasOverflowX);
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
        this.mutationObserver = null;
      }
    }
    toggleSub() {
      this.showSub = !this.showSub;
    }
    checkHasLoaded() {
      const element = this.element;
      const btnGroups = element.querySelectorAll('.layouts__btns-container');
      const hasAllBtnsLoaded = Array.from(btnGroups).reduce((acc, group) => {
        if (acc === false) {
          return false;
        }
        return group.offsetWidth > 0;
      }, true);
      this.hasAllBtnsLoaded = hasAllBtnsLoaded;
      return hasAllBtnsLoaded;
    }
    checkHasOverflowX() {
      if (this.isCheckingOverflow) {
        return;
      }
      this.isCheckingOverflow = true;
      const element = this.element;
      if (!this.hasAllBtnsLoaded) {
        this.isCheckingOverflow = false;
        return;
      }
      const width = window.innerWidth;
      if (width <= this.overFlowXWidth) {
        this.isCheckingOverflow = false;
        return;
      }
      if (width <= this.originalScrollWidth && this.hasOverflowX) {
        this.isCheckingOverflow = false;
        return;
      }
      const hasOverflowXData = this.applicationService.checkHasOverflowX(element);
      const hasOverflowX = hasOverflowXData?.hasOverflowX;
      this.hasOverflowX = hasOverflowX;
      if (this.originalScrollWidth < hasOverflowXData?.scrollWidth && this.lastHasOverflowXState === false && hasOverflowX) {
        this.originalScrollWidth = hasOverflowXData?.scrollWidth;
      }
      if (hasOverflowX) {
        this.overFlowXWidth = width;
      } else {
        this.overFlowXWidth = 0;
      }
      this.isCheckingOverflow = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "defaultParentContainerClassName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tab-parent-container';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "element", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasLoaded", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasOverflowX", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "originalScrollWidth", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "overFlowXWidth", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showSub", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyElement", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSub", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSub"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkHasOverflowX", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "checkHasOverflowX"), _class.prototype), _class);
});