define("client/pods/grapesjs/plugins/non-editable", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global grapesjs */

  const pluginName = 'bigordr-non-editable';
  const componentClassName = 'bigordr-non-editable';
  const Plugin = grapesjs.plugins.add(pluginName, (editor, options) => {
    const defaultOptions = {
      isComponent: el => {
        if (el.className) {
          if (R.includes(componentClassName, el.className)) {
            return true;
          }
        }
      },
      model: {
        defaults: {
          badgable: false,
          stylable: false,
          editable: false,
          selectable: false,
          hoverable: false,
          traits: []
        }
      }
    };
    options = R.mergeDeepRight(defaultOptions, options);
    const components = editor.DomComponents;
    components.addType(pluginName, options);
  });
  var _default = _exports.default = Plugin;
});