define("client/pods/components/contacts/panels-display/sub-panels-member-levels/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactsPanelsDisplaySubPanelsMemberLevelsComponent = _exports.default = (_dec = Ember.inject.service('date'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _class = class ContactsPanelsDisplaySubPanelsMemberLevelsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "dateService", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "contactRewardData", _descriptor4, this);
      _initializerDefineProperty(this, "isShowHistory", _descriptor5, this);
      _initializerDefineProperty(this, "levelChanges", _descriptor6, this);
      _initializerDefineProperty(this, "selectedMonths", _descriptor7, this);
    }
    *fetchContactRewardHistoryTask({
      rewardKey
    }) {
      if (RA.isNotNilOrEmpty(rewardKey)) {
        const model = this.args.model;
        const contactKey = model.getData('_key');
        let startDateZ = '';
        let endDateZ = '';
        const selectedMonths = this.selectedMonths;
        if (parseInt(selectedMonths) > 0) {
          startDateZ = this.dateService.getMoment().subtract(selectedMonths, 'months').toISOString();
          endDateZ = this.dateService.getMoment().toISOString();
        }
        const contact = yield this.crud.findTask.perform({
          adapterName: 'contacts/rewards',
          appendPath: contactKey,
          params: {
            rewardKey,
            startDateZ,
            endDateZ
          }
        });
        const contactRewardsData = contact.getData('pointsData') || {};
        const selectedRewardKey = R.propOr('', 'rewardKey')(contactRewardsData);
        const currentPoints = R.propOr(0, 'validPoints')(contactRewardsData);
        const currentLevel = R.propOr(0, 'currentLevel')(contactRewardsData);
        let rewardHistory = R.propOr([], 'rewardHistory')(contactRewardsData);
        const nextExpirePoints = R.find(R.propEq('isExpireSoon', true))(rewardHistory);
        const openingPoints = R.find(R.propEq('isOpening', true))(rewardHistory);
        rewardHistory = R.reject(R.propEq('isOpening', true))(rewardHistory);
        rewardHistory = R.map(contactRewardsData => {
          if (contactRewardsData.externalId) {
            contactRewardsData.ref = R.pipe(R.propOr('', 'externalId'), R.toUpper, R.splitEvery(4), R.join('-'))(contactRewardsData);
          }
          return contactRewardsData;
        })(rewardHistory);
        model.set('rewardHistory', rewardHistory);
        model.set('nextExpirePoints', nextExpirePoints);
        model.set('openingPoints', openingPoints);
        model.set('currentPoints', currentPoints);
        model.set('currentLevel', currentLevel);
        model.set('selectedRewardKey', selectedRewardKey);
      }
    }
    onSubmitShowRewardHistory(rewardKey) {
      Ember.set(this, 'isShowHistory', true);
      this.fetchContactRewardHistoryTask.perform({
        rewardKey
      });
    }
    onSubmitGetRewardHistory() {
      const model = this.args.model;
      this.fetchContactRewardHistoryTask.perform({
        rewardKey: model.get('selectedRewardKey')
      });
    }
    showLevelHistory(rewardKey) {
      const model = this.args.model;
      const contactRewardData = R.pipe(R.pathOr([], ['_data', 'contactRewardsData']), R.find(R.pathEq(['rewardData', '_key'], rewardKey)))(model);
      const levelChanges = R.pathOr([], ['level', 'levelChanges'])(contactRewardData);
      Ember.set(this, 'levelChanges', levelChanges);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactRewardData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isShowHistory", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "levelChanges", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedMonths", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 3;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchContactRewardHistoryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchContactRewardHistoryTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitShowRewardHistory", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitShowRewardHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitGetRewardHistory", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitGetRewardHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showLevelHistory", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showLevelHistory"), _class.prototype), _class);
});