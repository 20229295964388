define("client/pods/websites/lists/layout/menu-logo-size/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: '20px',
        label: '20x20'
      }, {
        value: '30px',
        label: '30x30'
      }, {
        value: '40px',
        label: '40x40'
      }, {
        value: '50px',
        label: '50x50'
      }];
      return _nventor.default.resolve(data);
    }
  });
});