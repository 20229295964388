define("client/pods/contacts/activity/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    // @TODO: use postingDateZ
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [],
        documentStatuses: [],
        dispatchStatus: [],
        sort: [{
          //   by: 'dispatchDateZ',
          //   direction: 'DESC'
          // }, {
          by: 'dateZ',
          direction: 'DESC'
        }
        // multi level sorting aql does not work
        // , {
        //   by: 'docNo',
        //   direction: 'DESC'
        // }
        ]
      };
    }
  });
});