define("client/pods/components/layouts/tabs/header/left/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vkjQ9i3g",
    "block": "{\"symbols\":[\"@isFloating\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"tab-grid__header-group tab-grid__header-group--left \",[28,\"if\",[[23,1,[]],\"is-floating\"],null]]]],[8],[0,\"\\n\\t\"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/tabs/header/left/template.hbs"
    }
  });
});