define("client/pods/components/documents/editor-main/editor-details/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/date", "client/mixins/droppable", "client/mixins/settings", "client/mixins/crud", "client/mixins/errors", "ember-concurrency", "client/utils/nventor", "ramda-extension"], function (_exports, R, RA, _date, _droppable, _settings, _crud, _errors, _emberConcurrency, _nventor, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, _droppable.default, _errors.default, _settings.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    big: Ember.inject.service(),
    documentsService: Ember.inject.service('documents'),
    tagName: 'tr',
    classNames: ['documents__details-table-row'],
    classNameBindings: ['detail._data.isSet:documents__details-table-row--isSet', 'detail._data.isSetChild:documents__details-table-row--isSetChild', 'isLastChild:documents__details-table-row--isSetChild-last'],
    unroundedCurrency: 8,
    // number of decimal places to keep for unrounded amounts

    init() {
      this._super(...arguments);
      const documentLinksFilters = this.search.setupFilters({
        adapterName: 'documents/data/links/filters'
      });
      this.set('documentLinksFilters', documentLinksFilters);
      const tabCopy = this.tabsManager.copyTab({
        tab: this.tab
      });
      this.set('tabCopy', tabCopy);
    },
    didInsertElement() {
      this._super(...arguments);
      const detail = this?.detail || {};
      if (detail?.autoLoadProduct) {
        this.loadProductTask.perform(detail?.autoLoadProduct);
      }
    },
    customProductsList: Ember.computed('detail.{autoLoadProduct,autoLoadProductResults.[],_data.itemKey}', 'productsList', function () {
      const detail = this.detail;
      const productsList = this.productsList || [];
      if (!detail?.autoLoadProduct) {
        return productsList;
      }
      return detail?.autoLoadProductResults || [];
    }),
    setDetailLinksListTask: (0, _emberConcurrency.task)(function* () {
      const itemKey = R.pathOr('', ['detail', '_data', 'itemKey'])(this);
      yield this.searchForLinksTask.perform({
        query: '',
        itemKey
      });
      this.setMaxQtyWhenLinkedWhenIsEditingAndNotNew();
    }),
    isTotalQtyExceededMaxQty: Ember.computed('model.detailQtyGroupedByLinkedList', 'detail._data.{fromDocName,consignmentsPendingId,ordersPendingId,fromDetailKey}', 'synced', function () {
      let pendingId = '';
      const fromDocName = R.pathOr('', ['detail', '_data', 'fromDocName'])(this);
      // @TODO: need to return false if is orders
      // add logic for sales returns (maybe check originalQty)

      pendingId = R.pathOr('', ['detail', '_data', 'consignmentsPendingId'])(this);
      if (R.equals(fromDocName, 'invoices')) {
        pendingId = R.pathOr('', ['detail', '_data', 'fromDetailKey'])(this);
      }
      if (RA.isNotNilOrEmpty(pendingId)) {
        const detailQtyGroupedByLinkedList = R.pathOr({}, ['model', 'detailQtyGroupedByLinkedList'])(this);
        if (RA.isNotNilOrEmpty(pendingId) && RA.isNotNilOrEmpty(detailQtyGroupedByLinkedList)) {
          const detailQtyGrouped = detailQtyGroupedByLinkedList[pendingId] || {};
          return detailQtyGrouped.isExceedMaxQty || false;
        }
        return false;
      }
      return false;
    }),
    isLastChild: Ember.computed('detail._data.isSetChild', 'model.sorted.[]', function () {
      if (this.get('detail._data.isSetChild')) {
        const details = this.get('model.sorted');
        const index = this.get('detail._data.index');
        const childIndex = this.get('detail._data.childIndex');
        return R.pipe(R.filter(R.pathEq(['_data', 'index', index])), R.pluck('_data'), R.pluck('childIndex'), R.flatten, R.sort((a, b) => {
          return a - b;
        }), R.last, R.equals(childIndex))(details);
      }
      return false;
    }),
    isPriceReadonlyOrDisabled: Ember.computed('isReadonlyOrDisabled', 'isQuickLinkDoc', function () {
      if (this.isQuickLinkDoc) {
        return false;
      }
      return this.isReadonlyOrDisabled;
    }),
    isReadonlyOrDisabled: Ember.computed('model.{_data.detailsLocked,isValidatedReturnsWithRewards}', 'detail._data.isSetChild', 'isReadonly', 'isSplitting', 'isMerging', 'isQuickLinkDoc', function () {
      if (this.isQuickLinkDoc) {
        return true;
      }

      // if (this.model._data.detailsLocked) {
      //   return true
      // }

      if (this.isSplitting) {
        return true;
      }
      if (this.isMerging) {
        return true;
      }
      if (this.get('detail._data.isSetChild') || this.get('detail._data.isSetWithChoices')) {
        return true;
      }
      if (this.get('model.isValidatedReturnsWithRewards')) {
        return true;
      }
      return this.isReadonly;
    }),
    isReadonlyOrDisabledSplitQty: Ember.computed('model._data.detailsLocked', 'detail._data.isSetChild', 'isReadonly', 'isSplitting', function () {
      if (this.isQuickLinkDoc) {
        const originalRemainingQty = parseFloat(this.detail.originalRemainingQty) || 0;
        if (originalRemainingQty <= 0) {
          return true;
        }
        if (this.get('detail._data.isSetChild') || this.get('detail._data.isSetWithChoices')) {
          return true;
        }
        return false;
      }
      if (this.isSplitting) {
        if (this.detail?._data?.isSetChild) {
          return true;
        }
        const netReturnsQty = this.detail?._data?.netReturnsQty || '0';
        if (this.big.newBig(netReturnsQty).lte('0')) {
          return true;
        }
        return false;
      }
      if (this.model._data.detailsLocked) {
        return true;
      }
      if (this.get('detail._data.isSetChild') || this.get('detail._data.isSetWithChoices')) {
        return true;
      }
      return this.isReadonly;
    }),
    isDisableItem: Ember.computed('isReadonlyOrDisabled', 'detail._data.isSetChild', 'isReadonly', function () {
      const isReadonlyOrDisabled = this.isReadonlyOrDisabled;
      if (isReadonlyOrDisabled) {
        return true;
      }
      if (this.get('detail._data.isSet') || this.get('detail._data.isSetWithChoices')) {
        return true;
      }
      return false;
    }),
    isSales: Ember.computed('docType', function () {
      if (this.docType === 'sales') {
        return true;
      }
      return false;
    }),
    isPurchases: Ember.computed('docType', function () {
      if (this.docType === 'purchases') {
        return true;
      }
      return false;
    }),
    /**
     * returns true if discount is a %
     * @type {Boolean}
     */
    isDiscountRate: Ember.computed('detail._data.discountType', function () {
      const detail = this.detail;
      const discType = detail.getData('discountType');
      if (discType === this.get('constants.discountTypes.rate')) {
        return true;
      }
      return false;
    }),
    /**
     * search for links products to link from other documents
     */
    searchForLinksTask: (0, _emberConcurrency.task)(function* ({
      query = '',
      itemKey = ''
    }) {
      const model = this.model;
      const contact = model.getData('contact');
      const contactKey = model.getData('contactKey') || '';
      if (!Ember.isEmpty(contact)) {
        const docType = this.docType;
        const docName = this.docName;
        const documentLinksFilters = this.documentLinksFilters;
        documentLinksFilters.setData('query', query);
        documentLinksFilters.setData('contact', contact);
        documentLinksFilters.setData('contactKey', contactKey);
        documentLinksFilters.setData('itemKey', itemKey);
        documentLinksFilters.setData('excludeFromLinkQty', {
          docType,
          docName,
          docKey: model.getData('_key')
        });
        if (this?.isNew) {
          documentLinksFilters.setData('includeCompletedPending', true);
        }

        // do not cache link lists
        const options = {
          cache: false
        };
        const results = yield this.crud.searchRecordsTask.perform({
          adapterName: 'documents/data/links',
          filters: documentLinksFilters,
          options,
          appendPath: `/${docType}/${docName}`,
          resultsProperty: 'linksList'
        });
        return results;
      }
    }),
    setMaxQtyWhenLinkedWhenIsEditingAndNotNew() {
      const isEditing = this.isEditing;
      const isNew = this.isNew;
      const documentLinksFilters = this.documentLinksFilters;
      const linksList = documentLinksFilters.get('linksList') || [];
      if (isEditing && !isNew && RA.isNotNilOrEmpty(linksList)) {
        const detail = this?.detail || {};
        const ref = R.pathOr('', ['_data', 'ref'])(detail);
        if (ref) {
          const linkQtyFromLinksList = R.pipe(R.find(R.pathEq(['_data', 'docNo'], ref)), R.pathOr('0', ['_data', 'linkQty']), qty => {
            return this.big.newBig(qty || '0').toFixed(0);
          })(linksList);
          const maxQtyWhenLinked = this.big.newBig(linkQtyFromLinksList).toFixed(0);
          const hasMaxQtyWhenLinked = R.hasPath(['_data', 'maxQtyWhenLinked'], detail);
          if (!hasMaxQtyWhenLinked) {
            detail.setData('maxQtyWhenLinked', maxQtyWhenLinked);
            detail.updateSynced();
          }
        }
      }
    },
    qtyForLinkedList: Ember.computed('model.synced', 'model._data.details.@each.synced', 'detail._data.{ordersPendingId,consignmentsPendingId,qty,maxQtyWhenLinked}', 'detail.synced', 'linksList', function () {
      const model = this.model;
      const details = model.getData('details') || [];
      let detailGroupedByLinked = {};
      if (RA.isNotNilOrEmpty(details)) {
        detailGroupedByLinked = R.pipe(R.filter(detail => {
          let pendingId = R.pathOr('', ['_data', 'ordersPendingId'])(detail);
          if (RA.isNilOrEmpty(pendingId)) {
            pendingId = R.pathOr('', ['_data', 'consignmentsPendingId'])(detail);
          }
          if (RA.isNilOrEmpty(pendingId)) {
            pendingId = R.pathOr('', ['_data', 'fromDetailKey'])(detail);
          }
          if (RA.isNotNilOrEmpty(pendingId)) {
            return true;
          }
          return false;
        }), R.groupBy(detail => {
          const fromDocName = R.pathOr('', ['_data', 'fromDocName'])(detail);
          if (R.equals(fromDocName, 'invoices')) {
            return R.path(['_data', 'fromDetailKey'])(detail);
          }
          return R.path(['_data', `${fromDocName}PendingId`])(detail);
        }), R.map(group => {
          const groupQtys = R.pipe(R.map(detail => {
            const qty = R.pathOr('0', ['_data', 'qty'])(detail);
            return this.big.newBig(qty).toFixed(0);
          }), x => {
            return this.big.sumArray(x).toFixed(0);
          })(group);
          return groupQtys;
        }))(details);
      }
      return detailGroupedByLinked;
    }),
    havePendingIdConflict: Ember.computed('detail._data.{fromDocName,fromDetailKey,consignmentsPendingId,ordersPendingId}', 'model.detailQtyGroupedByLinkedList', function () {
      const detailQtyGroupedByLinkedList = this.model.detailQtyGroupedByLinkedList;
      const detail = this?.detail || {};
      const fromDocName = detail.getData('fromDocName') || '';
      let pendingId = '';
      if (RA.isNotNilOrEmpty(fromDocName)) {
        pendingId = detail.getData(`${fromDocName}PendingId`);
        if (R.equals(fromDocName, 'invoices')) {
          pendingId = detail.getData('fromDetailKey');
        }
      }
      const pendingIdList = R.keys(detailQtyGroupedByLinkedList);
      if (R.includes(pendingId, pendingIdList)) {
        return true;
      }
      return false;
    }),
    resetAndLoadProductTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, product) {
      const detail = this.detail;
      detail.setData('itemKey', product?._data?._key);
      detail.setData('itemCode', product?._data?.code || '');
      detail.setData('price', product?._data?.price || '');
      yield this.loadProductTask.perform(product);
      onCloseDialogAction();
    }),
    /**
     * triggered when product is selected
     */
    loadProductTask: (0, _emberConcurrency.task)(function* (product) {
      const model = this.model;
      this.set('product', product);
      const itemKey = product.getData('_key');
      const productsService = this.productsService;
      const {
        preferredHistoryData
      } = yield productsService.get('fetchDocHistoryTask').perform(model, 'contact', product, itemKey);
      const taxRate = this.productsService.getTaxRate(model.getData('docType'), R_.dotPath('crud.lists.taxes')(this), product);
      const detail = this.detail;
      model.loadProduct(taxRate, detail, product, preferredHistoryData);
      this.updateDetailSyncedAndAddNewDetail();
      yield this.searchForLinksTask.perform({
        query: '',
        itemKey
      });
    }).enqueue().maxConcurrency(1),
    mergeWithDetails: Ember.computed('isMerging', 'toMergeWithModels.[]', function () {
      if (this.isMerging) {
        const model = this.model;
        const detailData = this.get('detail._data');
        const toMergeWithModels = this.toMergeWithModels || [];
        const props = model.get('mergeDetailsProps');
        const toMergeWithDetails = R.pipe(R.pluck('_data'), R.pluck('details'), R.flatten)(toMergeWithModels);
        const a = R.filter(toMergeWithDetail => {
          return _nventor.default.allEqProps(props, detailData, toMergeWithDetail._data);
        }, toMergeWithDetails);
        return a;
      }
    }),
    updateDetailSyncedAndAddNewDetail({
      onAfter
    } = {}) {
      this.onAddNewDetail();
      const detail = this?.detail || {};
      if (RA.isNotNilOrEmpty(detail)) {
        if (RA.isNotNilOrEmpty(onAfter) && R.is(Function, onAfter)) {
          onAfter();
        }
        detail.updateSynced();
      }
    },
    toggleShowQtyWarningMsgTask: (0, _emberConcurrency.task)(function* () {
      const model = this?.model || {};
      const detail = this?.detail || {};
      const fromDocNo = detail?._data?.fromDocNo || '';
      if (RA.isNotNilOrEmpty(fromDocNo) && this.isEditing && !this.isNew) {
        yield this.setDetailLinksListTask.perform();
      }
      if (RA.isNotNilOrEmpty(model)) {
        model.toggleShowQtyWarningMsg();
      }
      if (RA.isNotNilOrEmpty(detail)) {
        detail.toggleShowQtyWarningMsg();
      }
    }),
    // common actions (can be overridden from each document panel)
    actions: {
      searchForLinks(e, value) {
        this.searchForLinks(e, value);
      },
      preLoadLinkedDetailFromDropdown(linkFromDetail) {
        const detail = this.detail;
        return this.onPreLoadLinkedDetails(linkFromDetail, detail);
      },
      addAndLoadProduct(onCloseDialogAction, detail, product) {
        onCloseDialogAction();
        detail.setData('itemKey', product.getData('_key'));
        detail.setData('itemCode', product.getData('code'));
        this.loadProductTask.perform(product);
      },
      cancelAddProduct(onCloseDialogAction) {
        onCloseDialogAction();
      },
      setCommissionBaseCostAndOnSumTotal() {
        const commissionBaseCostFinal = this.detail.getData('commissionBaseCostFinal');
        this.detail.setData('commissionBaseCost', commissionBaseCostFinal);
        this.onSumTotals();
      },
      toggleDetailDiscountType(item) {
        const discType = item.getData('value');
        const detail = this.detail;
        if (discType === this.get('constants.discountTypes.rate')) {
          const currentDiscValue = detail.getData('discountAmount') || detail.getData('discountRate');
          detail.setData('discountRate', currentDiscValue);
          detail.setData('discountAmount', '');
        } else {
          const currentDiscValue = detail.getData('discountRate') || detail.getData('discountAmount');
          detail.setData('discountAmount', currentDiscValue);
          detail.setData('discountRate', '');
        }
        this.onSumTotals();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "updateDetailSyncedAndAddNewDetail", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateDetailSyncedAndAddNewDetail"), _obj), _obj));
});