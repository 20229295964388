define("client/pods/components/menus/menu-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SmF5eiif",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"menu\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"li\",false],[3,\"action\",[[23,0,[]],\"selected\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[[23,1,[\"_data\",\"label\"]],true],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/menu-list/template.hbs"
    }
  });
});