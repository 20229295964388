define("client/pods/components/notifications/display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8rHt8wLZ",
    "block": "{\"symbols\":[\"&default\",\"@notification\",\"@resource\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"span\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"notificationsService\",\"markAsReadTask\"]],[28,\"hash\",null,[[\"resource\",\"notification\"],[[23,3,[]],[23,2,[]]]]]],null]]],[3,\"will-destroy\",[[28,\"fn\",[[23,0,[\"notificationsService\",\"markAsReadLocallyOnDestroy\"]],[23,2,[]]],null]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/notifications/display/template.hbs"
    }
  });
});