define("client/pods/components/websites/websites-editor/website-wizard/quick-start/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { dropTask } from 'ember-concurrency-decorators'
  // import * as R from 'ramda'
  // import { tracked } from '@glimmer/tracking'
  // import { inject as service } from '@ember/service'

  class WebsitesWebsitesEditorWebsiteWizardQuickStartComponent extends _component.default {
    // @service server
    // @tracked domainVerified = false

    // get validDomainIcon () {
    //   return this.domainVerified ? 'fas fa-check-circle' : 'fas fa-times-circle'
    // }

    // @dropTask
    // * validateDomain () {
    //   const model = R.pathOr({}, ['args', 'model'])(this)
    //   const domain = R.pathOr('', ['_data', 'domain'])(model)
    //   const result = yield this.server.call('POST', 'api/protected/websites/validate/domain', { domain })
    //   this.domainVerified = R.pathOr(false, ['data'])(result)
    //   model.set('_data.domainVerified', this.domainVerified)
    // }
  }
  _exports.default = WebsitesWebsitesEditorWebsiteWizardQuickStartComponent;
});