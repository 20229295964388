define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor/component", ["exports", "client/mixins/sortable-actions"], function (_exports, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_sortableActions.default, (_dec = Ember._action, _obj = {
    tagName: '',
    modelProp: 'automationModel',
    resetThenSelectionQty() {
      const automationModel = this.automationModel || {};
      const thenSelectionType = automationModel?._data?.thenSelectionType;
      if (automationModel.isAutoAdd && thenSelectionType === 'all') {
        automationModel.setData('thenSelectionQty', '');
      }
    }
  }, _applyDecoratedDescriptor(_obj, "resetThenSelectionQty", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetThenSelectionQty"), _obj), _obj));
});