define("client/pods/channels/shop/rewards/model", ["exports", "client/pods/base/model", "ramda", "client/constants"], function (_exports, _model, R, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        name: '',
        websites: [],
        emailFromName: '',
        emailFromAddress: '',
        emailSignature: '',
        emailContent: '',
        status: _constants.default.status.active,
        hasExpirePointsNotification: false,
        expirePointsTime: 0,
        expirePointsPeriod: 'days',
        startTime: '',
        startPeriod: 'days',
        itemEvents: []
      };
    },
    populate(data, attrs) {
      data.memberLevels = this._transformMemberLevels(data);
      data.itemEvents = this._transformItemEvents(data);
      this._super(data, attrs);
    },
    _transformItemEvents(data) {
      const itemEvents = R.propOr([], 'itemEvents')(data);
      return R.map(itemEvent => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/item-events',
          data: itemEvent
        });
      })(itemEvents);
    },
    addItemEvent(data = {}) {
      const itemEvents = this.getData('itemEvents') || [];
      const itemEventModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/rewards/item-events',
        data
      });
      itemEvents.pushObject(itemEventModel);
      this.setData('itemEvents', itemEvents);
    },
    _transformMemberLevels(data) {
      let memberLevels = R.propOr([], 'memberLevels')(data);
      const itemEvents = R.propOr([], 'itemEvents')(data);
      memberLevels = R.map(memberLevel => {
        memberLevel.productTags = R.pipe(R.find(R.propEq('memberLevelId', memberLevel.memberLevelId)), R.propOr([], 'productTags'))(itemEvents);
        memberLevel.reward = data?.name || '';
        return memberLevel;
      })(memberLevels);
      return R.map(memberLevel => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/member-levels',
          data: memberLevel
        });
      })(memberLevels);
    },
    addMemberLevel(data = {}) {
      const memberLevels = this.getData('memberLevels') || [];
      const memberLevelModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/rewards/member-levels',
        data
      });
      memberLevels.pushObject(memberLevelModel);
      this.setData('memberLevels', memberLevels);
      memberLevelModel.addRewardEvent();
    }
  });
});