define("client/pods/components/channels/shop/shipping-zones/table-view/detail-row/detail-row-rates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jdgefPFo",
    "block": "{\"symbols\":[\"rate\",\"@rates\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table-form table-form--no-border\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"currency\",[[23,1,[\"_data\",\"min\"]]],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[23,1,[\"_data\",\"max\"]],\"0\"],null],[28,\"eq\",[[23,1,[\"_data\",\"max\"]],0],null]],null]],null,{\"statements\":[[0,\"          +\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          ~\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[23,1,[\"_data\",\"max\"]],\"0\"],null],[28,\"eq\",[[23,1,[\"_data\",\"max\"]],0],null]],null]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"currency\",[[23,1,[\"_data\",\"max\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\":\"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"currency\",[[23,1,[\"_data\",\"rate\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/table-view/detail-row/detail-row-rates/template.hbs"
    }
  });
});