define("client/pods/components/settings/is-beta-not-allowed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eetjVf9K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[23,0,[\"isBetaAllowed\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/is-beta-not-allowed/template.hbs"
    }
  });
});