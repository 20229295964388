define("client/pods/guests/shop/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Gmyl+lbW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n  \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[true]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/guests/shop/login/template.hbs"
    }
  });
});