define("client/mixins/doc-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // import { isEmpty } from '@ember/utils'
  var _default = _exports.default = Ember.Mixin.create(_crud.default, _date.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    listByTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      match,
      documentStatus,
      dispatchStatus,
      filters,
      query,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      showCampaignPeriodDataOnly,
      campaignPeriodDateStart,
      campaignPeriodDateEnd,
      isVoid,
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.reset();
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (isVoid) {
        filters.setData('isVoid', true);
      }
      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }
        filters.setData('documentStatus', documentStatus);
        filters.setData('documentStatuses', documentStatus);
      }
      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }
        filters.setData('dispatchStatus', dispatchStatus);
      }
      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }
        filters.setData('notDispatchStatus', notDispatchStatus);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        filters.setData('query', date);
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      if (showCampaignPeriodDataOnly) {
        filters.setData('showCampaignPeriodDataOnly', showCampaignPeriodDataOnly);
      }
      if (campaignPeriodDateStart) {
        filters.setData('campaignPeriodDateStart', campaignPeriodDateStart);
      }
      if (campaignPeriodDateEnd) {
        filters.setData('campaignPeriodDateEnd', campaignPeriodDateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }),
    listByDetailsTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      match,
      documentStatus,
      dispatchStatus,
      notDispatchStatus,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      itemKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (itemKey) {
        filters.setData('itemKey', itemKey);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }
        filters.setData('documentStatus', documentStatus);
      }
      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }
        filters.setData('dispatchStatus', dispatchStatus);
      }
      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }
        filters.setData('notDispatchStatus', notDispatchStatus);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath: '/details',
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }),
    listByDate(onHideDropdown, dateZ, date) {
      return this.listByTask.perform({
        filters: this.filters,
        date: this.getMoment(date).format('YYYY-MM-DD'),
        dateZ,
        resultsToggle: 'resultsToggle'
      }, onHideDropdown);
    }
  }, _applyDecoratedDescriptor(_obj, "listByDate", [_dec], Object.getOwnPropertyDescriptor(_obj, "listByDate"), _obj), _obj));
});