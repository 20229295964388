define("client/pods/components/documents/sales/invoices/print-btns/component", ["exports", "client/mixins/emails-dispatched", "client/mixins/crud", "ramda", "ramda-adjunct"], function (_exports, _emailsDispatched, _crud, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _emailsDispatched.default, {
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    settings: Ember.inject.service(),
    tagName: '',
    showPaymentLinkBtn: Ember.computed('settings.settingsModel._data.creditCard_tapPay', 'model._data.{paymentMethodKey,isVoidisRecurringChild,isRecurringMaster,hasAnyScheduledDateZ.[]}', function () {
      const isVoid = this.model._data.isVoid || '';
      if (isVoid) {
        return false;
      }
      const isTapPay = this.settings.getProp('creditCard_tapPay.enabled');
      if (isTapPay) {
        const paymentMethod = this.model._data.paymentMethodKey || '';
        if (paymentMethod === this.constants.paymentMethods.creditCard && !this.model._data.paymentCreditCardTransactionCode && this.model._data.externalUrl) {
          const isRecurringOrder = this.model._data?.isRecurringChild || this.model._data?.isRecurringMaster || false;
          if (!isRecurringOrder) {
            return true;
          }
          return R.pipe(R.pathOr([], ['model', '_data', 'hasAnyScheduledDateZ']), R.find(schedule => {
            const failedCCId = schedule.failedCCId || '';
            const errors = schedule.errors || [];
            const processingStatus = schedule.processingStatus;
            if ((failedCCId || RA.isNotNilOrEmpty(errors)) && processingStatus === 'shouldRetry') {
              return true;
            }
            return false;
          }), RA.isNotNilOrEmpty)(this);
        }
      }
      const isLinePayTapPay = this.settings.getProp('linePay_tapPay.enabled');
      if (isLinePayTapPay) {
        const paymentMethod = this.model._data.paymentMethodKey || '';
        if (paymentMethod === this.constants.paymentMethods.linePay && !this.model._data.paymentCreditCardTransactionCode && this.model._data.externalUrl) {
          return true;
        }
      }
      return false;
    }),
    isPos: Ember.computed('tab', function () {
      const masterTab = this.tabsManager.getMasterTab();
      const isPos = R.pathEq(['isPos'], true)(masterTab);
      if (isPos) {
        return true;
      }
      return false;
    }),
    qrCodeData: Ember.computed('model.paymentLink', function () {
      const paymentLink = this.model.paymentLink;
      if (paymentLink) {
        return `${paymentLink}?isPos=true`;
      }
      return '';
    }),
    actions: {
      goToPaymentPage(onHideDropdown) {
        const url = this.model.paymentLink;
        window.open(url, '_blank');
        onHideDropdown();
      }
    }
  });
});