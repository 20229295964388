define("client/pods/dispatch/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        date: '',
        docNo: '',
        contact: '',
        code: '',
        ref: '',
        inclTotal: '',
        transporterLabel: '',
        transporterAccount: '',
        waybill: '',
        packages: '',
        paymentMethods: [],
        paymentLastFiveDigits: '',
        sourceKey: '',
        statuses: [this.get('constants.status.active')],
        contactTags: [],
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    },
    productTags: Ember.computed('_data.productTags.[]', function () {
      return this.getPropLength('_data.productTags');
    }),
    contactTags: Ember.computed('_data.contactTags.[]', function () {
      return this.getPropLength('_data.contactTags');
    })
  });
});