define("client/pods/components/settings/defaults/3rd-party-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['facebook/pixels', 'google/tracking']);
      const useDefaultsShopDotCom = this.get('model._data.thirdParty.shopDotCom.useDefaultsFromSettings');
      this.set('useDefaultsShopDotCom', useDefaultsShopDotCom);
    },
    isSocialLinksReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsSocialLinks', function () {
      if (this.isReadonly) {
        return true;
      }
      return this.get('model._data.useDefaultsSocialLinks');
    }),
    isFacebookReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsFacebook', function () {
      if (this.isReadonly) {
        return true;
      }
      return this.get('model._data.useDefaultsFacebook');
    }),
    isShopDotComReadonly: Ember.computed('isReadonly', 'useDefaultsShopDotCom', function () {
      if (this.isReadonly) {
        return true;
      }
      return this.useDefaultsShopDotCom;
    }),
    shopDotCom: Ember.computed('useDefaultsShopDotCom', function () {
      const shopDotComData = this.get('model._data.thirdParty.shopDotCom');
      const settingsShopDotComData = this.settings?.settingsModel?._data?.thirdParty?.shopDotCom;
      if (this.useDefaultsShopDotCom) {
        return settingsShopDotComData;
      }
      return shopDotComData;
    }),
    toggleUseDefaultsShopDotCom() {
      this.set('model._data.thirdParty.shopDotCom.useDefaultsFromSettings', this.useDefaultsShopDotCom);
    }
  }, _applyDecoratedDescriptor(_obj, "toggleUseDefaultsShopDotCom", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleUseDefaultsShopDotCom"), _obj), _obj));
});