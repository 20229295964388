define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "big", _descriptor3, this);
      _initializerDefineProperty(this, "campaignsProducts", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "settings", _descriptor6, this);
      _initializerDefineProperty(this, "productList", _descriptor7, this);
      _initializerDefineProperty(this, "fillerType", _descriptor8, this);
      _defineProperty(this, "productsFilters", null);
      _defineProperty(this, "productsFiltersAdapterName", 'products/filters');
      this.crud.addLists(this, ['channels/shop/campaigns/lists/commission-filler']);
      let excludes = [];
      if (this.isAutomations) {
        excludes = [{
          key: 'setType',
          value: 'isSetWithChoices'
        }, {
          key: 'setType',
          value: 'hasVariants'
        }];
      }
      const productsFilters = this.search.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes,
          count: 20,
          page: 1
        }
      });
      this.productsFilters = productsFilters;
      this.productList = [];
      if (this?.args?.model && !this.args.isPresetTableView && !R.equals(this?.args?.tableViewOptions?.selectedCommissionBaseCostMethod, 'byBaseCost')) {
        this.args.tableViewOptions.recalculateCommissionTotal({
          useExclAmt: this.useExclAmt,
          item: this?.args?.model,
          isFromCommissionAmount: true
        });
      }
      this.commissionErrorMsg = this.intl.t('commission_adjustment_error_msg');
      const autoFillCommissionsFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/commission-base-cost/history/filters'
      });
      this.autoFillCommissionsFilters = autoFillCommissionsFilters;
    }
    *autoFillCommissionsTask({
      type = 'commissions'
    }) {
      let commissionPropertyName = this.commissionPropertyName;
      const isPresetTableView = this.args.isPresetTableView;
      const isUsingAverage = this?.args?.tableViewOptions?.isUsingAverage || true;
      const salesPersonKey = this?.args?.tableViewOptions?.salesPersonKey || '';
      const autoFillCommissionsFilters = this.autoFillCommissionsFilters;
      autoFillCommissionsFilters.setData('isUsingAverage', isUsingAverage);
      autoFillCommissionsFilters.setData('salesPersonKey', salesPersonKey);
      const selectedCommissionBaseCostMethod = this?.args?.tableViewOptions?.selectedCommissionBaseCostMethod || 'normalPercent';
      if (R.equals(type, 'commissions')) {
        autoFillCommissionsFilters.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
      }
      if (R.equals(type, 'adjustedShippingFee')) {
        const hasAdjustedShippingFee = R.pathOr(false, ['args', 'tableViewOptions', 'hasAdjustedShippingFee'])(this);
        const isCalculatedByExclAmt = R.pathOr(true, ['args', 'tableViewOptions', 'isCalculatedByExclAmt'])(this);
        autoFillCommissionsFilters.setData('type', 'adjustedShippingFee');
        autoFillCommissionsFilters.setData('hasAdjustedShippingFee', hasAdjustedShippingFee);
        autoFillCommissionsFilters.setData('isCalculatedByExclAmt', isCalculatedByExclAmt);
        commissionPropertyName = 'adjustedShippingFee';
      }
      const filterResults = this?.args?.results || [];
      let q = [];
      R.forEach(result => {
        const itemKey = result.getData('itemKey');
        const autoFillCommissionsFilterCopy = this.search.copyFilters({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost/history/filters',
          filters: autoFillCommissionsFilters
        });
        autoFillCommissionsFilterCopy.setData('itemKey', itemKey);
        let taxRate = result?._data?.taxRate;
        if (RA.isNilOrEmpty(taxRate)) {
          const taxRateFromSettings = this?.settings?.settingsModel?._data?.taxRate || 0;
          taxRate = taxRateFromSettings;
        }
        autoFillCommissionsFilterCopy.setData('taxRate', taxRate);
        const hasMultipleTaxRate = R.pathOr(false, ['_data', 'hasMultipleTaxRate'])(result);
        if (R.equals(type, 'adjustedShippingFee') && hasMultipleTaxRate) {
          q.push({});
          return;
        }
        q.push(this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost/history',
          filters: autoFillCommissionsFilterCopy,
          rawResponse: true
        }));
      })(filterResults);
      const allResults = yield (0, _emberConcurrency.all)(q);
      q = [];
      if (RA.isNotNilOrEmpty(allResults) && R.equals(R.length(allResults), R.length(filterResults))) {
        RA.mapIndexed((result, index) => {
          if (RA.isNotNilOrEmpty(allResults[index])) {
            let commissionBaseCostNew;
            if (isPresetTableView) {
              commissionBaseCostNew = result.commissionBaseCostNew;
              if (RA.isNilOrEmpty(commissionBaseCostNew.getData(commissionPropertyName))) {
                const autoFillValue = allResults[index][commissionPropertyName] || '';
                commissionBaseCostNew.setData(commissionPropertyName, autoFillValue);
                q.push(this.args.tableViewOptions.saveCommissionBaseCostNewModelTask.perform({
                  model: commissionBaseCostNew,
                  campaignsDetailsModel: result,
                  selectedCommissionBaseCostMethod,
                  isUsingAverage
                }));
              }
            } else {
              commissionBaseCostNew = result;
              if (RA.isNilOrEmpty(commissionBaseCostNew.getData(commissionPropertyName))) {
                const autoFillValue = allResults[index][commissionPropertyName] || '';
                commissionBaseCostNew.setData(commissionPropertyName, autoFillValue);
              }
            }
          }
        })(filterResults);
        if (RA.isNilOrEmpty(q) && !isPresetTableView) {
          this.args.tableViewOptions.recalculateAdjustmentTable();
        } else {
          yield (0, _emberConcurrency.all)(q);
        }
      }
    }
    *setAllCommissionsTask({
      model,
      commissionPropertyName
    }) {
      const fillerType = model.get('fillerType');
      const fillerValue = model.getData(commissionPropertyName);
      const selectedCommissionBaseCostMethod = this?.args?.tableViewOptions?.selectedCommissionBaseCostMethod || 'normalPercent';
      const isUsingAverage = this?.args?.tableViewOptions?.isUsingAverage || true;
      const itemKey = model.getData('itemKey');
      const filterResults = this?.args?.results || [];
      const modelIndex = R.findIndex(R.pathEq(['_data', 'itemKey'], itemKey))(filterResults);
      const isPresetTableView = this.args.isPresetTableView;
      const q = [];
      let commissionBaseCostNew;
      RA.mapIndexed((result, index) => {
        commissionBaseCostNew = result;
        if (isPresetTableView) {
          commissionBaseCostNew = result.commissionBaseCostNew;
        }
        if ((R.equals(fillerType, 'fillTop') && R.lt(index, modelIndex) || R.equals(fillerType, 'fillBottom') && R.gt(index, modelIndex) || R.equals(fillerType, 'fillTopAndBottom')) && RA.isNilOrEmpty(commissionBaseCostNew.getData(commissionPropertyName))) {
          commissionBaseCostNew.setData(commissionPropertyName, fillerValue);
          if (isPresetTableView) {
            q.push(this.args.tableViewOptions.saveCommissionBaseCostNewModelTask.perform({
              model: commissionBaseCostNew,
              campaignsDetailsModel: result,
              selectedCommissionBaseCostMethod,
              isUsingAverage
            }));
          }
        }
      })(filterResults);
      if (RA.isNilOrEmpty(q) && !isPresetTableView) {
        this.args.tableViewOptions.recalculateAdjustmentTable();
      } else {
        yield (0, _emberConcurrency.all)(q);
      }
      Ember.set(model, 'fillerType', '');
      Ember.set(model, 'fillerLabel', '');
    }
    get commissionPropertyName() {
      const adjustmentModel = this?.args?.tableViewOptions?.adjustmentModel;
      let selectedCommissionBaseCostMethod;
      if (adjustmentModel) {
        selectedCommissionBaseCostMethod = adjustmentModel.getData('selectedCommissionBaseCostMethod') || '';
      } else {
        selectedCommissionBaseCostMethod = this.args.tableViewOptions.selectedCommissionBaseCostMethod;
      }
      if (R.equals(selectedCommissionBaseCostMethod, 'normalPercent')) {
        return 'normalPercent';
      }
      if (R.equals(selectedCommissionBaseCostMethod, 'twPercent')) {
        return 'twPercent';
      }
      if (R.equals(selectedCommissionBaseCostMethod, 'byBaseCost')) {
        return 'byBaseCost';
      }
      if (R.equals(selectedCommissionBaseCostMethod, 'byAmount')) {
        return 'byAmount';
      }
      return '';
    }
    get totalPrice() {
      const model = this?.args?.model || {};
      const isPresetTableView = R.pathOr(false, ['args', 'isPresetTableView'])(this);
      const adjustedShippingFee = this.big.newBig(model?._data?.adjustedShippingFee || 0);
      let totalPriceProp;
      if (!isPresetTableView) {
        let qty = model?._data?.commissionQty || '';
        if (RA.isNilOrEmpty(qty)) {
          qty = model?._data?.qty || 0;
        }
        const qtyBig = this.big.newBig(qty);
        const useExclAmt = this.useExclAmt;
        let amountBig = this.big.newBig(0);
        if (useExclAmt) {
          let exclAmt = model?._data?.discExclPriceExclShipping || '';
          if (RA.isNilOrEmpty(exclAmt)) {
            exclAmt = model?._data?.discExclPrice || '';
          }
          if (RA.isNilOrEmpty(exclAmt)) {
            exclAmt = model?._data?.exclAmt || '';
          }
          amountBig = this.big.newBig(exclAmt);
        } else {
          let inclAmt = model?._data?.discInclPriceExclShipping || '';
          if (RA.isNilOrEmpty(inclAmt)) {
            inclAmt = model?._data?.discInclPrice || '';
          }
          if (RA.isNilOrEmpty(inclAmt)) {
            inclAmt = model?._data?.inclAmt || '';
          }
          amountBig = this.big.newBig(inclAmt);
        }
        amountBig = amountBig.minus(adjustedShippingFee);
        totalPriceProp = amountBig.times(qtyBig);
        return this.big.autoRound(0, totalPriceProp.toFixed());
      }
    }
    get useExclAmt() {
      let useExclAmt = this?.args?.tableViewOptions?.adjustmentModel?._data?.isCalculatedByExclAmt;
      if (RA.isNilOrEmpty(useExclAmt)) {
        useExclAmt = true;
      }
      return useExclAmt;
    }
    get isInputReadonly() {
      if (this?.args?.isChoices) {
        return true;
      }
      if (this?.args?.model?._data?.isSetWithChoices) {
        return true;
      }
      if (this?.args?.model?._data?.hasVariants) {
        return true;
      }

      // if (this?.args?.model?._data?.isSet && !this?.args?.model?._data?.isSetChild) {
      //   return true
      // }
    }
    loadItem(item, product) {
      item.setData('item', product.getData('name'));
    }
    unloadItem(item, product) {
      item.setData('item', '');
    }
    removeAdjustmentRow(item, onAfter) {
      const details = this?.args?.tableViewOptions?.details || [];
      if (RA.isNotNilOrEmpty(details)) {
        details.removeObject(item);
      }
      if (onAfter) {
        onAfter();
      }
    }
    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query?.trim?.() !== '') {
        let campaignKey;
        if (this.args.isPresetTableView) {
          campaignKey = this.args.campaignKey;
        } else {
          campaignKey = this.args.tableViewOptions.campaignKey;
        }
        const filters = this.productsFilters;
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          appendPath: `/campaigns/${campaignKey}`,
          filters
        }, query);
        return results;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "productList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fillerType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "autoFillCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "autoFillCommissionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAllCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setAllCommissionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadItem", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "loadItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadItem", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "unloadItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAdjustmentRow", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdjustmentRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), _class);
});