define("client/pods/components/dispatch/processes-item/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    dispatchProcessCount: Ember.computed('dispatchProcessesByCount._data.processes.[]', 'dispatchProcess._data.code', function () {
      const dispatchProcessesByCount = this.dispatchProcessesByCount;
      if (dispatchProcessesByCount) {
        const code = this.get('dispatchProcess._data.code');
        return R.pathOr(0, ['_data', code, 'count'])(dispatchProcessesByCount);
      }
      return 0;
    }),
    isEditable: Ember.computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
      if (this.get('dispatchProcess._data._key')) {
        return true;
      }
      return false;
    }),
    hasProcessCount: Ember.computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
      if (this.dispatchProcessCount > 0) {
        return true;
      }
      return false;
    }),
    mustShow: Ember.computed('dispatchProcess.isDeleted', 'dispatchProcess._data._key', 'dispatchProcessCount', 'hasProcessCount', function () {
      const isDeleted = R.pathEq(['dispatchProcess', 'isDeleted'], true)(this);
      if (isDeleted) {
        return true;
      }
      const hideIfEmpty = R.pathEq(['dispatchProcess', '_data', 'hideIfEmpty'], true)(this);
      if (hideIfEmpty && !this.hasProcessCount) {
        return true;
      }
      return false;
    })
  });
});