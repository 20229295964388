define("client/pods/notifications/manager/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/notifications/manager/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * notifications manager is a collection of all notifications by type.
   * notifcationsManger = {
   *   alerts: []
   * }
   * 
   * it is used as a receiver for broadcast messages of
   * _id='notifications/manager', action='arrayPush' and
   * propPath=['alerts']
   */
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: '_id',
    find() {
      const data = {
        _id: 'notifications/manager',
        alerts: [],
        changeLogs: []
      };
      return _nventor.default.resolve(data);
    }
  });
});