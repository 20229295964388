define("client/pods/components/websites/panels-display/sub-panels-pages/nav-menu-nested/nav-item/component", ["exports", "client/pods/tabs-manager/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    websitesService: Ember.inject.service('websites'),
    classNames: [],
    hasDropAboveContainer: true,
    hasDropBelowContainer: true,
    isDraggable: true,
    isViewable: true,
    item: null,
    labelKey: 'label',
    tagName: '',
    translate: true,
    init() {
      this._super(...arguments);
      if (this.get('item._data.isHome')) {
        this.set('droppable', false);
      }
      const newTab = _model.default.create();
      this.set('newTab', newTab);
    },
    label: Ember.computed('item', 'labelKey', 'item.synced', function () {
      return this.get('item.' + this.labelKey);
    }),
    draggable: Ember.computed('item._data.isHome', 'isDraggable', function () {
      if (this.get('item._data.isHome')) {
        return false;
      }
      return this.get('isDraggable');
    }),
    isSelected: Ember.computed('selectedPageModel._data._key', function () {
      if (!this?.selectedPageModel?._data?._key) {
        return false;
      }
      if (this?.selectedPageModel?._data?._key == this.item?._data?._key) {
        return true;
      }
      return false;
    }),
    _hasDropAboveContainer: Ember.computed('index', 'hasDropAboveContainer', function () {
      if (this.hasDropAboveContainer && this.index === 0) {
        return true;
      }
      return false;
    }),
    previewUrl: Ember.computed('item._data.url', 'website._data.{domain,domainVerified,subdomain}', function () {
      return this.websitesService.getPreviewLink({
        model: this.item,
        website: this.website
      });
    }),
    actions: {
      select(disabled, item) {
        if (!disabled) {
          return this.onSelect(item, false);
        }
      }

      // toggleQuickOptions (item) {
      //   return this.onSelect(item, true)
      // }
    }
  });
});