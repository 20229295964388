define("client/helpers/isEmptyProp", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params) {
      const obj = params[0];
      const prop = params[1];
      const value = obj.get(prop);
      return RA.isNilOrEmpty(value);
    }
  });
});