define("client/pods/components/recurring/schedules/editor/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RecurringSchedulesEditor = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class RecurringSchedulesEditor extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);
      _initializerDefineProperty(this, "model", _descriptor4, this);
      _defineProperty(this, "adapterName", this.args.customAdapterName || this.args.adapterName || 'recurring/schedules/options');
      this.crud.addLists(this, ['recurring/schedules/from', 'recurring/schedules/end']);
      const model = this.args.model || this.args.parentModel || {};
      const instanceId = model?.instanceId;
      if (!instanceId) {
        model.instanceId = R.pathOr('', ['args', 'parentModel', 'instanceId'])(this);
      }
      const tab = this.args.tab || {};
      if (this.args.isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model,
          tab
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          tab
        });
      }
    }
    willDestroy() {
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty: this.model,
        tab: this.args.tab
      });
    }

    // get isExactDate () {
    //   const model = this.model
    //   if (!model) {
    //     return false
    //   }

    //   if (model._data.end === this?.constants?.paymentTermsEnd?.exactDate) {
    //     return true
    //   }

    //   return false
    // }

    // isMinRequiredMet: computed('model._data.{from,end}', function () {
    //   const minRequired = ['from', 'end']
    //   const model = this.model
    //   let met = true

    //   minRequired.forEach(function (required) {
    //     if (isEmpty(model.getData(required))) {
    //       met = false
    //     }
    //   })

    //   return met
    // }),

    // _setDescription () {
    //   const model = this.model
    //   const intl = this.intl

    //   let description = ''
    //   const months = model.getDataInt('months')
    //   if (months > 0) {
    //     description = intl.t('months') + ': ' + months
    //   }

    //   const days = model.getDataInt('days')
    //   if (days > 0) {
    //     if (months > 0) {
    //       description = `${description}, `
    //     }

    //     description = description + intl.t('days') + ': ' + days
    //   }

    //   const fromList = R_.dotPath('crud.lists.paymentTerms-from')(this) || []
    //   const from = fromList.findBy('_data.value', model.getData('from'))

    //   const endList = R_.dotPath('crud.lists.paymentTerms-end')(this) || []
    //   const end = endList.findBy('_data.value', model.getData('end'))

    //   if (description) {
    //     description = description + '. '
    //   }

    //   description = description + intl.t('from') + ': ' + intl.t(from.getData('label')) + '. '

    //   description = description + intl.t('end') + ': ' + intl.t(end.getData('label')) + '.'

    //   model.setData('label', description)
    // },

    // // autoSetDescription: Ember.observer('model._data.months', 'model._data.days', 'model._data.from', function () {
    // //
    // //   this._setDescription();
    // // }),

    // beforeCreateRecord (model) {
    //   const months = model.getDataInt('months')
    //   model.setData('months', months)

    //   const days = model.getDataInt('days')
    //   model.setData('days', days)

    //   if (!model.getData('label')) {
    //     this._setDescription()
    //   }

    //   return model
    // },

    // afterCreateRecordTask (model) {
    //   this.onAddItem(model)
    //   this.onCloseDialogAction()
    // },

    // beforeReplaceRecord (model) {
    //   const months = model.getDataInt('months')
    //   model.setData('months', months)

    //   const days = model.getDataInt('days')
    //   model.setData('days', days)

    //   if (!model.getData('label')) {
    //     this._setDescription()
    //   }

    //   return model
    // },

    // afterReplaceRecordTask (model) {
    //   this.onUpdateItem(model)
    // },

    // afterRemoveRecordTask () {
    //   this.onRemoveItem(this.model)
    // },

    afterCreateRecordTask(model) {
      this.args.onAddItem?.(model);
      this.args.onCloseDialogAction?.();
    }
    afterUpdateRecordTask(model) {
      this.args.onUpdateItem?.(model);
      this.args.onCloseDialogAction?.();
    }
    afterReplaceRecordTask(model) {
      this.args.onUpdateItem?.(model);
      this.args.onCloseDialogAction?.();
    }
    afterRemoveRecordTask() {
      this.args.onRemoveItem?.(this.model);
    }
    cancel() {
      this.args.onCloseDialogAction?.();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterCreateRecordTask", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterUpdateRecordTask", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterUpdateRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterReplaceRecordTask", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "afterReplaceRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterRemoveRecordTask", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "afterRemoveRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
});