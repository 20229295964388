define("client/pods/components/extensions/apps/tw/hct/create-contact/component", ["exports", "client/mixins/crud", "client/mixins/search-contacts", "client/mixins/transporter-accounts", "ember-concurrency", "ramda-adjunct", "ramda"], function (_exports, _crud, _searchContacts, _transporterAccounts, _emberConcurrency, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporterAccounts.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    contactService: Ember.inject.service('contacts'),
    settingsService: Ember.inject.service('settings'),
    tagName: '',
    isLinkContact: false,
    contactsList: [],
    isDisable: Ember.computed('model._data.{hctUser,hctPassword}', function () {
      const hasUsername = R.pipe(R.path(['model', '_data', 'hctUser']), RA.isNotNilOrEmpty)(this);
      const hasPassword = R.pipe(R.path(['model', '_data', 'hctPassword']), RA.isNotNilOrEmpty)(this);
      if (hasPassword && hasUsername) {
        return false;
      }
      return true;
    }),
    allowCreate: Ember.computed('allowCreate', function () {
      const allowCreate = this.allowCreate;
      const isNilOrEmpty = RA.isNilOrEmpty(allowCreate);
      if (isNilOrEmpty) {
        return true;
      }
      return allowCreate;
    }),
    saveTask: (0, _emberConcurrency.task)(function* ({
      model,
      onCloseDialogAction
    }) {
      let saved;
      model.setData('isTransporter', this.get('constants.isTransporter.on'));
      model.setData('transporterExtension', 'hct');
      const isDirty = this.model.isDirty && this.model?._data?._key;
      if (this.isLinkContact) {
        const adapterName = 'contacts';
        const dirty = this.crud.setupDirty({
          adapterName,
          model
        });
        saved = yield this.crud.updateRecordTask.perform({
          adapterName,
          model: dirty
        });
      } else if (isDirty) {
        const transporterModel = yield this.crud.updateRecordTask.perform({
          adapterName: 'contacts',
          model
        });
        this.set('model', transporterModel);
      } else if (this.allowCreate) {
        model.setData('relationships', [this.get('constants.contactsRelationship.supplier')]);
        saved = yield this.crud.createRecordTask.perform({
          adapterName: 'contacts',
          model
        });
      } else {
        const data = this.model._data;
        this.model.set('_data', data);
      }
      onCloseDialogAction();
      if (this.onLoadTransporterContact) {
        this.onLoadTransporterContact(saved);
      }
    }).drop(),
    searchContactsTask: (0, _emberConcurrency.task)(function* (args = {
      relationship: 'supplier'
    }) {
      const contactsList = yield this.contactService.searchInputTask.perform(args);
      this.set('contactsList', contactsList);
    }),
    setup() {
      const hasExistingModel = RA.isNotNilOrEmpty(this.model);
      let model;
      if (hasExistingModel) {
        model = this.crud.setupDirty({
          adapterName: 'contacts',
          model: this.model
        });
      } else {
        model = this.crud.setupNewRecord({
          adapterName: 'contacts'
        });
      }
      this.set('model', model);
    },
    loadTransporter(model, transporter) {
      this.set('isLinkContact', true);
      this.set('transporter', transporter);
      model.setData('_id', transporter.getData('_id'));
      model.setData('_key', transporter.getData('_key'));
      model.setData('_rev', transporter.getData('_rev'));
      model.setData('code', transporter.getData('code'));
      model.setData('transporterAccounts', transporter.getData('transporterAccounts'));
      model.setData('defaultTransporterAccount', this.defaultTransporterAccountNumber);
      model.setData('hctUser', transporter.getData('hctUser'));
      model.setData('hctPassword', transporter.getData('hctPassword'));
    },
    unloadTransporter(model) {
      this.set('isLinkContact', false);
      this.set('transporter', '');
      model.setData('_id', '');
      model.setData('_key', '');
      model.setData('_rev', '');
      model.setData('code', '');
      model.setData('transporterAccounts', []);
      model.setData('defaultTransporterAccount', '');
      model.setData('hctUser', '');
      model.setData('hctPassword', '');
    },
    cancel(onCloseDialogAction) {
      onCloseDialogAction();
    },
    refreshModel(model) {
      const isDirty = R.pathEq(['model', 'isDirty'], true)(this);
      if (isDirty) {
        const dirtyModel = this.crud.setupDirty({
          adapterName: 'contacts/transporters',
          model
        });
        this.set('model', dirtyModel);
      } else {
        const proxyModel = model || R.propOr({}, 'model')(this);
        this.set('model', proxyModel);
      }
    },
    setDefaultPickupLocationPersonId(person = {}) {
      const updateData = {
        personId: person?.personId || '',
        senderName: person?.name || '',
        senderTelephone: person?.telephone || '',
        senderAddress: person?.address || '',
        defaultTransporterAccount: person?.transporterAccount || '',
        defaultTransporterNotes: person?.transporterNotes || '',
        defaultTransporterPackageTemperature: person?.transporterPackageTemperature || ''
      };
      for (const updateDataKey in updateData) {
        this.model.set(`_data.${updateDataKey}`, updateData[updateDataKey]);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "loadTransporter", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadTransporter"), _obj), _applyDecoratedDescriptor(_obj, "unloadTransporter", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unloadTransporter"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj), _applyDecoratedDescriptor(_obj, "refreshModel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "refreshModel"), _obj), _applyDecoratedDescriptor(_obj, "setDefaultPickupLocationPersonId", [_dec6], Object.getOwnPropertyDescriptor(_obj, "setDefaultPickupLocationPersonId"), _obj), _obj));
});