define("client/pods/dispatch/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      // Override old paymentData with payment record from payment collection
      this._super(data, attrs);
    },
    translateAccountName: Ember.computed('_data.transporterExtension', function () {
      // @TODO: this is difficult to maintain,
      // there are multiple versions
      return R.anyPass([R.pathEq(['model', '_data', 'transporterExtension'], 't-cat'), R.pathEq(['model', '_data', 'transporterExtension'], 'seven-eleven')])(this);
    }),
    waybill: Ember.computed('_data.waybill', function () {
      return R.pipe(R.pathOr([], ['model', '_data', 'waybill']), R.replace(/,/g, '<br>'))(this);
    })
  });
});