define("client/pods/components/ledgers/accounts/banks-editor/component", ["exports", "client/mixins/big", "client/mixins/crud"], function (_exports, _big, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // the only purpose the app-container serves is to keep track of isLoggedIn state
  var _default = _exports.default = Ember.Component.extend(_big.default, _crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'ledgers/accounts/lists/banks',
    init() {
      this.crud.addLists(this, ['ledgers/accounts/lists/banks']);
      this._super(...arguments);
      const isEditing = this.isEditing;
      if (isEditing) {
        const dirty = this.setupDirty();
        this.set('model', dirty);
      } else {
        const model = this.setupNewRecord();
        this.set('model', model);
      }
    },
    afterCreateRecordTask(model) {
      this.onAddItem(model);
      this.onCloseDialogAction();
    },
    afterReplaceRecordTask(model) {
      this.onUpdateItem(model);
      this.onCloseDialogAction?.();
    },
    afterRemoveRecordTask() {
      this.onRemoveItem(this.dirty);
    },
    actions: {
      cancel(model) {
        this.cancelEditing({
          dirty: model
        });
        this.onCloseDialogAction();
      },
      toggleIsDefaultDetail() {
        this.toggleProperty('model._data.isDefault');
      }
    }
  });
});