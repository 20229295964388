define("client/pods/components/modals/modal-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JxCOJTeK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasCustomBlock\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"hasModalOverlay\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"modals/modal-overlay\",null,[[\"closeOnOverlayClick\",\"toggle\"],[[24,[\"closeOnOverlayClick\"]],[24,[\"toggle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-container__outer-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-container__wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-container__content \",[22,\"modalContentClassNames\"]]]],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-container/template.hbs"
    }
  });
});