define("client/pods/blogs/filters/model", ["exports", "client/pods/filters/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        keys: [],
        tags: [],
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [],
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    },
    get resultsSortedByKeys() {
      if (!this._data.keys) {
        return this.results;
      }
      const keys = R.pathOr([], ['_data', 'keys'])(this);
      return R.pipe(R.propOr([], 'results'), R.sortBy(R.pipe(R.path(['_data', '_key']), R.indexOf(R.__, keys))))(this);
    },
    reIndexResults() {
      const keys = R.pathOr([], ['_data', 'keys'])(this);
      R.pipe(R.propOr([], 'results'), R.sortBy(R.pipe(R.path(['_data', '_key']), R.indexOf(R.__, keys))), R.mapObjIndexed((value, index) => {
        value.set('_data.index', parseInt(index));
        return value;
      }), R.values)(this);
    }
  });
});