define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/order-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X1h1K+I/",
    "block": "{\"symbols\":[\"@formInitData\",\"@automationModel\"],\"statements\":[[0,\"\\n\\t\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"automationCriteria\",[23,1,[]],\"criteria\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"readonly\",\"disabled\",\"translate\",\"inputPvtClassNames\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"value\",\"onSelect\"],[true,true,[24,[\"isReadonly\"]],true,\"table-form__input\",[23,0,[\"crud\",\"lists\",\"automations-criterias\"]],\"_data.value\",\"_data.label\",[24,[\"automationModel\",\"_data\",\"criteria\"]],[28,\"fn\",[[23,2,[\"setCumulative\"]],[23,2,[]]],null]]]],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"automationQty\",[23,1,[]],\"automation_qty\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"inputPvtClassNames\",\"value\",\"onInput\"],[[24,[\"isReadonly\"]],\"table-form__input\",[24,[\"automationModel\",\"_data\",\"value\"]],[28,\"fn\",[[23,2,[\"setCumulativeValue\"]],[23,2,[]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isBetweenAutomation\"]]],null,{\"statements\":[[0,\"\\t\\t\\t~\\n\\n\\t\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"inputPvtClassNames\",\"value\"],[[24,[\"isReadonly\"]],\"table-form__input\",[24,[\"automationModel\",\"_data\",\"valueMax\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/order-value/template.hbs"
    }
  });
});