define("client/pods/components/products/files/files-default-image/component", ["exports", "client/pods/components/products/files/mixins/products-files", "ramda-adjunct"], function (_exports, _productsFiles, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_productsFiles.default, (_dec = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    fetchUploads: true,
    fetchProduct: true,
    init() {
      this._super(...arguments);
      const isToggler = RA.defaultWhen(RA.isNilOrEmpty, false, this.isToggler);
      Ember.set(this, 'isToggler', isToggler);
    },
    toggle() {
      const resourceKey = this.resourceKey;
      if (!resourceKey) {
        return;
      }
      const parentComponent = this.parentComponent;
      const toggleProperty = this.toggleProperty;
      return this.applicationService.toggleComponentProperty(parentComponent, toggleProperty);
    }
  }, _applyDecoratedDescriptor(_obj, "toggle", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggle"), _obj), _obj));
});