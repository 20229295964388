define("client/pods/doc-list-by/service", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CrudService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _class = class CrudService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "date", _descriptor2, this);
    }
    *listByTask({
      adapterName,
      appendPath,
      contact,
      contactKey,
      date,
      dateEnd,
      dateStart,
      dispatchStatus,
      documentStatus,
      filters,
      isVoid,
      match,
      notDispatchStatus,
      query,
      resultsProperty,
      resultsToggle,
      sourceKey
    } = {}, onHideDropdown) {
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (isVoid) {
        filters.setData('isVoid', true);
      }
      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }
        filters.setData('documentStatus', documentStatus);
      }
      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }
        filters.setData('dispatchStatus', dispatchStatus);
      }
      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }
        filters.setData('notDispatchStatus', notDispatchStatus);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        filters.setData('query', date);
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath,
        filters,
        query,
        resultsProperty,
        resultsToggle
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }
    *listByDetailsTask({
      adapterName,
      contact,
      contactKey,
      date,
      dateEnd,
      dateStart,
      documentStatus,
      dispatchStatus,
      filters,
      itemKey,
      match,
      notDispatchStatus,
      query = '',
      resultsProperty,
      resultsToggle,
      sourceKey
    } = {}, onHideDropdown) {
      filters.setData('page', 1);
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (itemKey) {
        filters.setData('itemKey', itemKey);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (documentStatus) {
        if (!Ember.isArray(documentStatus)) {
          documentStatus = [documentStatus];
        }
        filters.setData('documentStatus', documentStatus);
      }
      if (dispatchStatus) {
        if (!Ember.isArray(dispatchStatus)) {
          dispatchStatus = [dispatchStatus];
        }
        filters.setData('dispatchStatus', dispatchStatus);
      }
      if (notDispatchStatus) {
        if (!Ember.isArray(notDispatchStatus)) {
          notDispatchStatus = [notDispatchStatus];
        }
        filters.setData('notDispatchStatus', notDispatchStatus);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath: '/details',
        filters,
        query,
        resultsProperty,
        resultsToggle
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }
    listByDate(onHideDropdown, dateZ, date) {
      return this.listByTask.perform({
        date,
        dateZ,
        resultsToggle: 'resultsToggle'
      }, onHideDropdown);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "listByTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "listByTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listByDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "listByDetailsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listByDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "listByDate"), _class.prototype), _class);
});