define("client/pods/comments/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CommentsService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _class = class CommentsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "queue", _descriptor2, this);
    }
    qFetch({
      data,
      master,
      related = [],
      type,
      metaData = {},
      excludeFromFilters = [],
      debug = false
    }, cb) {
      if (master) {
        data.master = master;
      }
      if (type) {
        data.type = type;
      }
      if (RA.isNotNilOrEmpty(metaData)) {
        data = R.mergeRight(data, metaData);
      }
      data = R.omit(excludeFromFilters)(data);
      data.related = related || [];
      this.queue.pushObject({
        data,
        cb
      });
      const count = this.queue.length;
      setTimeout(() => {
        if (count > 0 && count === this.queue.length) {
          return this._fetchAllCommentsTask.perform({
            batch: this.queue,
            count,
            debug
          });
        }
        console.log('---skipped----');
      }, 200);
    }
    *_fetchAllCommentsTask({
      batch,
      count,
      debug = false
    }) {
      console.log('---fetching----');
      const commentsCollection = yield this.crud.searchRecordsTask.perform({
        method: 'POST',
        adapterName: 'comments/collection',
        appendPath: '/batch/master',
        data: {
          batch: R.pluck('data')(batch)
        }
      });
      if (debug) {
        debugger; //eslint-disable-line
      }
      this.queue = R.drop(count)(this.queue);
      const cbs = R.pluck('cb')(batch);
      R.forEach(cb => {
        cb(commentsCollection);
      })(cbs);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "queue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_fetchAllCommentsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_fetchAllCommentsTask"), _class.prototype), _class);
});