define("client/pods/products/templates/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProductsTemplatesModel = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _class = class ProductsTemplatesModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
    }
    defaults() {
      return {
        template: '',
        header: this.intl.t('product information'),
        status: this.get('constants.status.active')
      };
    }
    replaceTemplate(template, templateImages = []) {
      this.setData('template', template);
      this.setData('templateImages', templateImages);
    }
    insertTemplate(template, templateImages = []) {
      const currentTemplate = this.getData('template');
      const currentTemplateImages = this.getData('templateImages') || [];
      let newTemplate = '';
      if (currentTemplate) {
        newTemplate = `${currentTemplate}<br>`;
      }
      newTemplate = `${newTemplate} ${template}`;
      const newTemplateImages = R.concat(currentTemplateImages, templateImages);
      this.setData('template', newTemplate);
      this.setData('templateImages', newTemplateImages);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "replaceTemplate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "replaceTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertTemplate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "insertTemplate"), _class.prototype), _class);
});