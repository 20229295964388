define("client/pods/channels/shop/campaigns/automations/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/campaigns/automations/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/shop/campaigns/automations',
    modelObj: _model.default,
    idParam: '_id',
    serialize(model) {
      const data = this._super(model);
      const detailsRequiredAttrs = ['itemKey'];
      const details = data.details || [];
      data.details = details.filter(detail => this.isNotEmptyDetail(detail, detailsRequiredAttrs));
      return data;
    }
  });
});