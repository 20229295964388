define("client/pods/components/products/panels-display/transactions-display/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/mixins/doc-list-by", "ember-concurrency"], function (_exports, _date, _settings, _crud, _docListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, _docListBy.default, _settings.default, {
    crud: Ember.inject.service(),
    adapterName: 'documents/data/products/transactions',
    init() {
      this._super(...arguments);
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const filters = this.search.setupFilters({
        adapterName: 'filters',
        defaultFiltersData: {
          sort: [{
            by: 'dateZ',
            direction: 'DESC'
          }, {
            by: 'docNo',
            direction: 'DESC'
          }]
        }
      });
      this.set('filters', filters);
    },
    async didInsertElement() {
      this._super(...arguments);

      // get by latest
      this.searchTask.perform();
    },
    searchTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd
    } = {}) {
      const model = this.model;
      const itemKey = model.getData('_key');
      const adapterName = this.adapterName;
      const appendPath = `/${itemKey}`;
      const filters = this.filters;
      filters.setData('dateStart', dateStart);
      filters.setData('dateEnd', dateEnd);
      const searchSettings = {
        adapterName,
        appendPath,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);
      this.set('results', results);
    }),
    actions: {
      async displayByMonth(period) {
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
        this.searchTask.perform({
          dateStart,
          dateEnd
        });
      },
      async displayByCustomMonth() {
        const dateEndString = this.customDateEndZ;
        const dateStartString = this.customDateStartZ;
        let dateEnd;
        let dateStart;
        if (dateEndString) {
          if (!dateStartString) {
            const dateEndObj = this.getMonthEnd(dateEndString);
            dateStart = this.formatDateForUrl(dateEndObj.startOf('month'));
            dateStart = this.getStartOfDayZ(dateStart);
            dateEnd = dateEndString;
          } else {
            if (dateStartString > dateEndString) {
              dateEnd = dateStartString;
              dateStart = dateEndString;
            } else {
              dateEnd = dateEndString;
              dateStart = dateStartString;
            }
          }

          // const model = this.get('model')
          // const itemKey = model.getData('_key')
          // const adapterName = this.get('adapterName')
          // const filters = this.get('filters')

          this.searchTask.perform({
            dateStart,
            dateEnd
          });
          // const results = await this.get('listByDetailsTask').perform({ filters, adapterName, itemKey, dateStart, dateEnd })
          // this.set('results', results)
        }
      }
    }
  });
});