define("client/pods/users/personal-filters/collection/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data = {}) {
      data.collection = this._transformCollection(data);
      this._super(...arguments);
    },
    _transformCollection(data) {
      return R.pipe(R.propOr([], 'collection'), R.map(menuData => {
        menuData.isPinned = true;
        return this.dataManager.setAsRecord({
          adapterName: 'users/personal-filters',
          data: menuData
        });
      }))(data);
    }
  });
});