define("client/pods/components/extensions/apps/tw/invoices/print-all-btn/component", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices-printer"], function (_exports, _invoicesPrinter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_invoicesPrinter.default, {
    tagName: '',
    getInvoiceCacheId(model) {
      if (typeof model === 'object') {
        return model.getData('_key');
      }

      // invoice is masterKey
      return `tw_invoices_${model}`;
    }
  });
});