define("client/pods/components/accordion-help-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mmZu4GxW",
    "block": "{\"symbols\":[\"documentationWrapper\",\"accordianContainer\",\"@tab\",\"@formInitData\",\"@documentationResource\",\"@context\",\"@hasToggleIcon\",\"@label\",\"&default\"],\"statements\":[[5,\"documentation/documentation\",[[12,\"class\",\"\"]],[[\"@tab\",\"@isHelp\",\"@documentationId\",\"@documentationResource\",\"@context\",\"@options\"],[[23,3,[]],true,[23,4,[\"documentationId\"]],[23,5,[]],[23,6,[]],[28,\"hash\",null,[[\"documentationId\",\"documentationResource\",\"context\"],[[23,4,[\"documentationId\"]],[23,5,[]],[23,6,[]]]]]]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/accordian-container\",[[12,\"class\",\"help-container\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/accordian-btn\",[],[[\"@hasToggleIcon\",\"@container\"],[[23,7,[]],[23,2,[]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"tt\",[[23,8,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"elements/accordian-dialog\",[],[[\"@container\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n      \"],[5,\"documentation/documentation-icon\",[],[[\"@isHelp\",\"@documentationResource\",\"@context\",\"@documentation\",\"@documentationId\",\"@updateDocumentation\",\"@documentationWrapper\",\"@tab\",\"@onlyShowForIsDocumentationsEditor\"],[true,[23,5,[]],[23,6,[]],[23,1,[\"documentation\"]],[23,1,[\"documentationId\"]],[23,1,[\"updateDocumentation\"]],[23,1,[]],[23,3,[]],true]]],[0,\"\\n\\n      \"],[5,\"documentation/documentation-desc\",[[12,\"class\",\"documentation-description-title\"]],[[\"@documentationWrapper\",\"@documentation\",\"@documentationId\",\"@documentationResource\",\"@context\"],[[23,1,[]],[23,1,[\"documentation\"]],[23,1,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,6,[]]]]],[0,\"\\n\\n      \"],[14,9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/accordion-help-section/template.hbs"
    }
  });
});