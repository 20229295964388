define("client/pods/components/websites/websites-editor/3rd-party-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vO3KNW8Q",
    "block": "{\"symbols\":[\"@isEditing\",\"@isReadonly\",\"@model\",\"@parentTab\",\"@tab\"],\"statements\":[[5,\"settings/defaults/3rd-party-editor\",[],[[\"@documentationResource\",\"@hideGuideMenu\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@hideShopDotCom\",\"@hideRss\"],[\"websites\",true,[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[28,\"if\",[[23,1,[]],[23,0,[\"crud\",\"lists\",\"websites-lists-editorSubPanels\"]],[23,0,[\"crud\",\"lists\",\"websites-lists-subPanels\"]]],null],[23,5,[]],true,false]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/3rd-party-editor/template.hbs"
    }
  });
});