define("client/pods/components/channels/shop/items/item-shipping-zones/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZAnQkziy",
    "block": "{\"symbols\":[\"child\",\"child\",\"@model\"],\"statements\":[[4,\"if\",[[23,3,[\"disallowedChildProducts\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tab-link__tooltip-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-container__outer-wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-container__wrapper\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-container__content\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"allowedChildProducts\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\",true],[10,\"class\",\"has-text-success\"],[8],[0,\"\\n              \"],[7,\"i\",true],[10,\"class\",\"fas fa-circle-check\"],[8],[9],[0,\" \\n              \"],[1,[23,2,[\"name\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"each\",[[23,3,[\"disallowedChildProducts\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\",true],[10,\"class\",\"has-text-danger\"],[8],[0,\"\\n              \"],[7,\"i\",true],[10,\"class\",\"fas fa-circle-xmark\"],[8],[9],[0,\" \\n              \"],[1,[23,1,[\"name\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/items/item-shipping-zones/template.hbs"
    }
  });
});