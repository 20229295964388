define("client/pods/components/websites/panels-display/sub-panels-news/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "npAQV+Oy",
    "block": "{\"symbols\":[\"@tab\",\"@model\"],\"statements\":[[5,\"news/tab-container\",[],[[\"@tab\",\"@master\"],[[23,1,[]],[23,2,[\"_data\",\"_key\"]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-news/template.hbs"
    }
  });
});