define("client/pods/components/users/panels-display/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    editAction: 'edit',
    updateAction: 'update',
    dashAction: 'dash',
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    init: function () {
      this.crud.addLists(this, ['users/lists/types', 'users/lists/roles/internal', 'users/lists/ip-addresses', 'statuses']);
      this._super(...arguments);
      this.setup();
    },
    setup() {
      const model = this.get('tab.model');

      // images is required to be an array
      if (!R.is(Array, model.getData('images'))) {
        model.setData('images', []);
      }
      this.set('model', model);
    },
    notAllowedEdit: Ember.computed('model._data._key', function () {
      const tokenData = this.storage.get('tokenData');
      const uid = tokenData.uid;
      const userKey = R.path(['model', '_data', '_key'])(this);
      if (uid === userKey) {
        return false;
      }
      const role = tokenData.role || '';
      if (R.toLower(role) === 'super') {
        return false;
      }
      return true;
    })
  });
});