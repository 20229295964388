define("client/pods/reports/commissions/data/adjustment/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend((_dec = Ember._action, _obj = {
    defaults() {
      return {
        selectedCommissionBaseCostMethod: 'normalPercent',
        isCalculatedByExclAmt: true,
        paymentTermsKey: ''
      };
    },
    populate(data = {}) {
      data = data || {};
      data = this.transformDetails(data);

      // populate docName info
      this.overwriteData(data);
      this._super(...arguments);
    },
    transformDetails(rawData) {
      // const self = this
      rawData.details = R.pipe(R.pathOr([], ['details']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'reports/commissions/data/adjustment/details',
          data
        });
      }))(rawData);
      return rawData;
    },
    addNewRow(data) {
      if (RA.isNilOrEmpty(data)) {
        data = {
          item: '',
          itemKey: '',
          manual: true
          // commissionBaseCost: '',
        };
      }
      const detailModel = this.dataManager.setAsRecord({
        adapterName: 'reports/commissions/data/adjustment/details',
        data
      });
      let details = this.getData('details') || [];
      details.pushObject(detailModel);
      return detailModel;
    }
  }, _applyDecoratedDescriptor(_obj, "addNewRow", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewRow"), _obj), _obj));
});