define("client/pods/extensions/apps/tw/e-invoices/collection/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    init() {
      this._super(...arguments);
      this.set('adapters', {
        invoices: 'extensions/apps/tw/e-invoices'
      });
    },
    populate(data, attrs) {
      data.invoices = this._transformInvoices(data.invoices);
      this._super(data, attrs);
    },
    _transformInvoices(invoices = []) {
      return invoices.map(invoice => {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.invoices'),
          data: invoice
        });
      });
    }
  });
});