define("client/pods/components/websites/search-results/links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y0FFxjPt",
    "block": "{\"symbols\":[\"showOnData\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"result\",\"_data\",\"url\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"url\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[[28,\"concat\",[\"website_type_\",[28,\"or\",[[24,[\"result\",\"_data\",\"type\"]],\"page\"],null]],null]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"result\",\"_data\",\"isDynamicPage\"]]],null,{\"statements\":[[0,\"\\t\\t\\t(\"],[1,[28,\"tt\",[\"system generated\"],null],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"result\",\"showOnLabels\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isOn\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"u-desc--mini\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"label\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/search-results/links/template.hbs"
    }
  });
});