define("client/pods/websites/lists/layout/menu-alignment/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "client/constants"], function (_exports, _adapter, _nventor, _adaptersNew, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: _constants.default.alignment.left,
        label: _constants.default.alignment.left
      }, {
        value: _constants.default.alignment.center,
        label: _constants.default.alignment.center
      }, {
        value: _constants.default.alignment.right,
        label: _constants.default.alignment.right
      }];
      return _nventor.default.resolve(data);
    }
  });
});