define("client/pods/settings/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';

  const filterTabs = (list, filterByLabel) => R.map(R.pipe(R.unless(R.propSatisfies(R.includes(R.__, filterByLabel), 'label'), R.set(R.lensProp('component'), null))))(list);
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const customData = {
        list: [{
          group: 'general',
          component: 'settings/settings-editor/basic-editor',
          label: 'basic',
          value: 'basic',
          resource: 'api/protected/settings/sub-panels-edit/basic',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/basic-editor',
            isEditing: 'settings/settings-editor/basic-editor',
            isNew: 'settings/settings-editor/basic-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'general',
          component: 'settings/settings-editor/invoices-editor',
          label: 'settings_invoices',
          value: 'invoices',
          resource: 'api/protected/settings/sub-panels-edit/invoices',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/invoices-editor',
            isEditing: 'settings/settings-editor/invoices-editor',
            isNew: 'settings/settings-editor/invoices-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'general',
          component: 'settings/settings-editor/documents-editor',
          label: 'settings_documents',
          value: 'documents',
          resource: 'api/protected/settings/sub-panels-edit/documents',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/documents-editor',
            isEditing: 'settings/settings-editor/documents-editor',
            isNew: 'settings/settings-editor/documents-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'general',
          component: 'settings/settings-editor/products-editor',
          label: 'products',
          value: 'products',
          resource: 'api/protected/settings/sub-panels-edit/products',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/products-editor',
            isEditing: 'settings/settings-editor/products-editor',
            isNew: 'settings/settings-editor/products-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'shop',
          component: 'settings/settings-editor/shop-editor',
          label: 'shop',
          value: 'shop',
          resource: 'api/protected/settings/sub-panels-edit/shop',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/shop-editor',
            isEditing: 'settings/settings-editor/shop-editor',
            isNew: 'settings/settings-editor/shop-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'shop',
          component: 'settings/settings-editor/payments-editor',
          label: 'settings_payments',
          value: 'payments',
          resource: 'api/protected/settings/sub-panels-edit/payments',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/payments-editor',
            isEditing: 'settings/settings-editor/payments-editor',
            isNew: 'settings/settings-editor/payments-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'shop',
          component: 'settings/settings-editor/shipping-editor',
          label: 'settings_shipping',
          value: 'shipping',
          resource: 'api/protected/settings/sub-panels-edit/shipping',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/shipping-editor',
            isEditing: 'settings/settings-editor/shipping-editor',
            isNew: 'settings/settings-editor/shipping-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'shop',
          component: 'settings/settings-editor/emails-editor',
          label: 'emails',
          value: 'emails',
          resource: 'api/protected/settings/sub-panels-edit/emails',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/emails-editor',
            isEditing: 'settings/settings-editor/emails-editor',
            isNew: 'settings/settings-editor/emails-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'shop',
          component: 'settings/settings-editor/social-editor',
          label: 'settings_social',
          value: 'social',
          resource: 'api/protected/settings/sub-panels-edit/social',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/social-editor',
            isEditing: 'settings/settings-editor/social-editor',
            isNew: 'settings/settings-editor/social-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'shop',
          component: 'settings/settings-editor/3rd-party-editor',
          label: '3rd-party',
          value: '3rd-party',
          resource: 'api/protected/settings/sub-panels-edit/3rd-party',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/3rd-party-editor',
            isEditing: 'settings/settings-editor/3rd-party-editor',
            isNew: 'settings/settings-editor/3rd-party-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          group: 'other',
          component: 'settings/settings-editor/beta-editor',
          label: 'beta',
          value: 'beta',
          resource: 'api/protected/settings/sub-panels-edit/beta',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/beta-editor',
            isEditing: 'settings/settings-editor/beta-editor',
            isNew: 'settings/settings-editor/beta-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }]
      };

      // customData.list = filterTabs(customData.list, ['company', 'shipping', 'shop', '3rd-party', 'beta'])

      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(res => {
        return res?.data || [];
      });
    }
  });
});