define("client/pods/components/extensions/apps/tw/invoices/panels-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      const self = this;
      self._super(...arguments);
      self.set('report', self.get('tab.report'));
    }
  });
});