define("client/pods/admin/members/lists/plans/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'free',
        label: 'free',
        fee: 0,
        feeType: 'amount'
      }, {
        value: 'paid1',
        label: 'paid 1',
        fee: '1980',
        feeType: 'amount'
      }, {
        value: 'paid2',
        label: 'paid 2',
        fee: '4990',
        feeType: 'amount'
      }, {
        value: 'virtual0',
        label: 'virtual 0',
        fee: '0',
        feeType: 'amount'
      }, {
        value: 'virtual1',
        label: 'virtual 1',
        fee: '300',
        feeType: 'amount'
      }, {
        value: 'commission',
        label: 'commission',
        fee: '1',
        feeType: 'rate'
      }];
      return _nventor.default.resolve(data);
    }
  });
});