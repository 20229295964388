define("client/pods/components/emails/email-editor/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    date: Ember.inject.service(),
    init() {
      this._super(...arguments);
      const isPaymentReminderEmail = this.isPaymentReminderEmail || false;
      if (isPaymentReminderEmail) {
        const paymentReminderEmails = this.settings.settingsModel.getData('paymentReminderEmails') || [];
        const paymentDueDays = this.paymentDueDays || false;
        if (paymentDueDays && paymentDueDays > 0 && RA.isNotNilOrEmpty(paymentReminderEmails)) {
          const matchedPaymentReminderEmail = R.find(R.pathEq(['_data', 'paymentReminderEmailDays'], paymentDueDays))(paymentReminderEmails);
          if (RA.isNotNilOrEmpty(matchedPaymentReminderEmail)) {
            this.model.set('_data.paymentReminderEmailDays', paymentDueDays);
            this.setPaymentReminderEmail(this.model, matchedPaymentReminderEmail);
          }
        }
      }
    },
    setPaymentReminderEmail(model, emailData = {}) {
      model.set('_data.subject', R.pathOr('', ['_data', 'paymentReminderEmailSubject'])(emailData));
      model.set('_data.message', R.pathOr('', ['_data', 'paymentReminderEmailMessage'])(emailData));
    },
    paymentDueDays: Ember.computed('docModel._data.{paymentDueDateZ,dateZ}', function () {
      const docModel = this.docModel;
      const paymentDueDateZ = docModel.getData('paymentDueDateZ') || docModel.getData('dateZ') || '';
      const todayZ = this.date.getStartOfDayZ();
      if (this.isPaymentReminderEmail) {
        const dateDiff = this.date.getDateDiff(paymentDueDateZ, todayZ);
        if (dateDiff > 0) {
          return dateDiff;
        }
      }
      return false;
    }),
    actions: {
      updateContent(template) {
        this.model.set('_data.template', template);
      },
      setPaymentReminderEmailAction(emailData = {}) {
        const model = this.model;
        this.setPaymentReminderEmail(model, emailData);
      }
    }
  });
});