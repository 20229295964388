define("client/mixins/templates", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const self = {
    replaceTemplate(template, templateImages = []) {
      this.setData('template', template);
      this.setData('templateImages', templateImages);
    },
    insertTemplate(template, templateImages = []) {
      const currentTemplate = this.getData('template');
      const currentTemplateImages = this.getData('templateImages') || [];
      let newTemplate = '';
      if (currentTemplate) {
        newTemplate = `${currentTemplate}<br>`;
      }
      newTemplate = `${newTemplate} ${template}`;
      const newTemplateImages = R.concat(currentTemplateImages, templateImages);
      this.setData('template', newTemplate);
      this.setData('templateImages', newTemplateImages);
    }
  };
  var _default = _exports.default = Ember.Mixin.create(self);
});