define("client/pods/components/store-location/files/mixins/store-location-files", ["exports", "ember-concurrency", "client/mixins/crud", "client/utils/nventor"], function (_exports, _emberConcurrency, _crud, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember/object'
  var _default = _exports.default = Ember.Mixin.create(_crud.default, {
    adapterNameForSetDefault: 'store-location',
    adapterName: 'store-location',
    // fetchUploadsTask: task(function * () {
    //   const resourceKey = this.get('resourceKey')
    //   if (resourceKey) {
    //     const adapterName = this.get('adapterName')
    //     const storeLocation = yield this.get('dataManager').findRecord(adapterName, `/${resourceKey}`)
    //     this.set('storeLocation', storeLocation)
    //   }
    // }),

    setAsDefaultImageTask: (0, _emberConcurrency.task)(function* (file) {
      if (this.allowSetDefault) {
        // no need to send entire record over, should only update record, not replace
        const adapterNameForSetDefault = this.adapterNameForSetDefault;
        const resourceKey = this.resourceKey;
        const storeLocation = yield this.dataManager.findRecord({
          adapterName: adapterNameForSetDefault,
          appendPath: `/${resourceKey}`
        });
        const dirty = this.dataManager.getDirtyRecord(adapterNameForSetDefault, storeLocation);
        dirty.setData('defaultImage', file._data.uuid);
        try {
          return yield this.dataManager.updateRecord({
            adapterName: adapterNameForSetDefault,
            dirty
          });
        } catch (err) {
          this.set('errors', err.data);
          _nventor.default.throw('setAsDefaultImageTask failed', err);
        }
      }
    }).drop()
  });
});