define("client/pods/ledgers/accounts/lists/account-types/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'assets',
        label: 'assets'
      }, {
        value: 'liabilities',
        label: 'liabilities'
      }, {
        value: 'equity',
        label: 'equity'
      }, {
        value: 'revenue',
        label: 'revenue'
      }, {
        value: 'expenses',
        label: 'expenses'
      }];
      return _nventor.default.resolve(data);
    }
  });
});