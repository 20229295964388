define("client/pods/components/users/ip-addresses-users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qO17aS/Z",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[23,0,[\"usersForIp\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"usersForIp\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[1,[23,1,[\"username\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/users/ip-addresses-users/template.hbs"
    }
  });
});