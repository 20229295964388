define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/bulk-discount-conflicts-item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "server", _descriptor2, this);
    }
    get currentBulkDiscountName() {
      const model = this.args.model;
      const bulkDiscounts = model.getData('bulkDiscounts') || [];
      const bulkDiscount = this.args.bulkDiscount || {};
      const isCurrent = bulkDiscount.getData('isCurrent') || false;
      const bulkDiscountName = bulkDiscount.getData('bulkDiscountName') || '';
      if (!isCurrent && bulkDiscountName) {
        return bulkDiscountName;
      }
      let bulkDiscountId = '';
      if (!isCurrent && !bulkDiscountName) {
        bulkDiscountId = bulkDiscount.getData('bulkDiscountId') || '';
      }
      if (isCurrent) {
        const conflictsList = this.args.conflict.getData('conflictsList') || [];
        bulkDiscountId = R.pipe(R.find(R.pathEq(['_data', 'isCurrent'], true)), R.pathOr('', ['_data', 'bulkDiscountId']))(conflictsList);
      }
      return R.pipe(R.find(R.pathEq(['_data', 'bulkDiscountId'], bulkDiscountId)), R.pathOr('', ['_data', 'name']))(bulkDiscounts);
    }
    get isSelected() {
      const bulkDiscount = this.args.bulkDiscount || {};
      const isCurrent = R.pathOr(false, ['_data', 'isCurrent'])(bulkDiscount);
      if (isCurrent) {
        return true;
      }
      return false;
    }
    *chooseBulkDiscountTask(bulkDiscountId) {
      const conflictModel = this.args.conflict;
      conflictModel.setData('bulkDiscountId', bulkDiscountId);
      this.args.updateCampaignDetailBulkDiscountTask.perform({
        model: conflictModel
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chooseBulkDiscountTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "chooseBulkDiscountTask"), _class.prototype), _class);
});