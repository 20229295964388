define("client/helpers/strip-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute(params = '') {
      var tmp = document.implementation.createHTMLDocument('New').body;
      tmp.innerHTML = params;
      return tmp.textContent || tmp.innerText || '';
    }
  });
});