define("client/pods/components/channels/shop/campaigns/campaigns-editor/products-limits-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/sortable-actions", "ember-concurrency"], function (_exports, R, RA, _sortableActions, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_sortableActions.default, {
    crud: Ember.inject.service(),
    server: Ember.inject.service(),
    modelProp: 'model',
    indexProp: '_data.index',
    adapterName: 'channels/shop/campaigns/details/limits',
    filtersAdapterName: 'channels/shop/campaigns/details/limits/filters',
    selectedToggleUseInventory: '',
    init() {
      this._super(...arguments);
      let limitAdjustType = 'adjustTotalQty';
      const adjustCampaignsDetailsLimitQtyBy = this.settings.getProp('adjustCampaignsDetailsLimitQtyBy');
      if (RA.isNotNilOrEmpty(adjustCampaignsDetailsLimitQtyBy)) {
        limitAdjustType = adjustCampaignsDetailsLimitQtyBy;
      }
      this.set('limitAdjustType', limitAdjustType);
    },
    toggleAllOverrideUseInventoryAsLimitQtyTask: (0, _emberConcurrency.task)(function* () {
      const campaignKey = this?.campaignModel?._data?._key || '';
      yield this.server.call('PATCH', 'api/protected/channels/shop/campaigns/details/limits/overrideUseInventoryAsLimitQtyBatch', {
        campaignKey
      });
      yield this.fetchCampaignDetailsLimitsTask.perform();
      this.set('selectedToggleUseInventory', '');
    }),
    actions: {
      resetDetailsLimitsUpdated() {
        this.model.set('detailsLimitsUpdated', false);
      }
    }
  });
});