define("client/pods/components/documents/editor-main/editor-inventory-notification/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let DocumentsEditorMainEditorInventoryNotification = _exports.default = (_dec = Ember._action, _class = class DocumentsEditorMainEditorInventoryNotification extends _component.default {
    get modalDialogIsVisible() {
      const model = this.args.model;
      if (model) {
        const detailsInventoryData = model.get('detailsInventoryData') || [];
        if (_ramdaAdjunct.default.isNotNilOrEmpty(detailsInventoryData)) {
          const docType = model?._data?.docType;
          const docName = model?._data?.docName;
          if (docType !== 'purchases') {
            return false;
          }
          if (docName === 'consignments') {
            return true;
          }
          const paymentMethod = model?._data?.paymentMethodKey;
          const hasNotModified = _ramdaAdjunct.default.isNilOrEmpty(model?._data?.modified);
          if (hasNotModified && docName === 'invoices' && paymentMethod !== 'consignment') {
            // only show when no key. ie when saving for the first time only
            return true;
          }
          return false;
        }
      }
      return false;
    }
    onCloseDetailsInventoryDataDialog(onCloseDialogAction) {
      const model = this.args.model || {};
      if (_ramdaAdjunct.default.isNotNilOrEmpty(model)) {
        const detailsInventoryData = model.get('detailsInventoryData') || [];
        if (_ramdaAdjunct.default.isNotNilOrEmpty(detailsInventoryData)) {
          model.set('detailsInventoryData', []);
        }
      }
      if (this.args.onDone) {
        this.args.onDone();
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onCloseDetailsInventoryDataDialog", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDetailsInventoryDataDialog"), _class.prototype), _class);
});