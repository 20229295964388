define("client/pods/components/guests/campaign-login/component", ["exports", "ramda", "ember-concurrency", "client/config/environment"], function (_exports, R, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    url: '',
    password: '',
    guests: Ember.inject.service(),
    router: Ember.inject.service(),
    auth: Ember.inject.service(),
    init() {
      this.crud.addLists(this, ['languages']);
      this._super(...arguments);
    },
    redirectToNewWay() {
      this.guests.redirectToNewWay();
    },
    loginAsGuestTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('errorMsg', '');
        const guestResource = this.guestResource;
        const credentials = {
          resource: guestResource,
          password: this.password
        };
        const data = yield this.guests.call('POST', 'api/guests/login', credentials);
        const redirectUrl = R.path(['data', 'redirect'], data);
        if (redirectUrl) {
          window.open(redirectUrl, '_self');
        }
      } catch (err) {
        this.set('report', false);
        if (err.data === 'not connected') {
          window.location.reload();
        }
        this.set('errorMsg', 'invalid password');
      }
    }).drop(),
    version: Ember.computed('env.version', function () {
      return _environment.default.version || '';
    }),
    actions: {
      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
      }
    }
  }, _applyDecoratedDescriptor(_obj, "redirectToNewWay", [_dec], Object.getOwnPropertyDescriptor(_obj, "redirectToNewWay"), _obj), _obj));
});