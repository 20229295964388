define("client/pods/components/elements/date-input/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ElementsDateInputComponent = _exports.default = (_dec = Ember.inject.service('date'), _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _class = class ElementsDateInputComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "dateService", _descriptor, this);
      _initializerDefineProperty(this, "dateValue", _descriptor2, this);
      _defineProperty(this, "DEBOUNCE_MS", 450);
    }
    get dateMin() {
      const dateConstraint = this.args?.dateConstraint;
      if (dateConstraint?.min) {
        return this.dateService.formatDateForUrl(this.dateService.getMoment(dateConstraint?.min));
      }
      return '';
    }
    get dateMax() {
      const dateConstraint = this.args?.dateConstraint;
      if (dateConstraint?.max) {
        return this.dateService.formatDateForUrl(this.dateService.getMoment(dateConstraint?.max));
      }
      return '';
    }
    get dateZ() {
      const dateValue = this.dateValue;
      if (dateValue) {
        const dateZ = this.dateService.getMoment?.(dateValue)?.toISOString();
        return dateZ;
      }
      return '';
    }
    get initDate() {
      const dateInputType = this.dateInputType || 'date';
      const date = this?.args?.date;
      if (dateInputType === 'date' && date) {
        return this.dateService.formatDate(date, 'YYYY-MM-DD');
      }
      if (dateInputType === 'datetime-local' && date) {
        return this.dateService.formatDate(date, 'YYYY-MM-DDTHH:mm');
      }
      return '';
    }

    // @DO NOT REMOVE THIS SETTER
    // Ember built-in Input tag would try to set 'value', which is this.initDate
    // if there's only getter, it cannot be set by ```obj[key] = ...```, which would throw an error
    set initDate(value) {
      return value;
    }
    selectDate(element) {
      this.value = element?.target?.value || '';
      const value = this.value;

      // should also perform when value = '' (clear date)
      this.debounceTask.perform(value);
      const model = this.args?.model;
      const target = this.args?.target;
      if (model && target) {
        let dateZ = '';
        if (value) {
          dateZ = this.dateService.getMoment(value).toISOString();
        }

        // should use target without _data
        Ember.set(model, target, dateZ);
        if (this.args.onSetDate) {
          this.args.onSetDate(dateZ);
        }
      }
    }
    focusOut(element) {
      if (R.is(Function)(this.args.onFocusOut)) {
        this.value = element?.target?.value || '';
        const value = this.value;
        const target = this.args?.target;
        if (target) {
          let dateZ = '';
          if (value) {
            dateZ = this.dateService.getMoment(value).toISOString();
          }

          // should use target without _data
          this.args.onFocusOut(dateZ);
        }
      }
    }
    *debounceTask(value) {
      yield (0, _emberConcurrency.timeout)(this.DEBOUNCE_MS);
      const instruct = this.args?.instruct;
      const onSelectDate = this.args?.onSelectDate;

      // do not assign dateZ to a variable here
      // since it is a getter and will vary with dateValue

      if (instruct === 'start' && onSelectDate) {
        this.dateValue = value;
        onSelectDate({
          dateStartString: this.dateZ
        });
      } else if (instruct === 'end' && onSelectDate) {
        this.dateValue = value;
        onSelectDate({
          dateEndString: this.dateZ
        });
      } else if (onSelectDate) {
        const filters = this.args?.filters;
        this.dateValue = value;
        onSelectDate(this.dateZ, {
          filters
        });
      }
    }
    get dateInputType() {
      return this.args?.dateInputType || 'date';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusOut", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "focusOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "debounceTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "debounceTask"), _class.prototype), _class);
});