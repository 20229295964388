define("client/pods/components/contacts/results/contact-persons-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TmXM9DFA",
    "block": "{\"symbols\":[\"option\",\"doc\"],\"statements\":[[4,\"if\",[[24,[\"legend\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"legend\",true],[11,\"class\",[22,\"legendClassNames\"]],[8],[1,[28,\"tt\",[[24,[\"legend\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"list\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"lists/list-option\",null,[[\"translate\",\"rememberSelected\",\"optionValueKey\",\"optionLabelKey\",\"classNames\",\"option\",\"onToggle\"],[[24,[\"translate\"]],false,[24,[\"optionValueKey\"]],[24,[\"optionLabelKey\"]],[24,[\"itemClassNames\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleOption\"],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"contacts/icons/detail-icon\",null,[[\"detail\"],[[23,1,[]]]]],false],[0,\" \"],[1,[23,1,[\"_data\",\"name\"]],false],[0,\"\\n\\t\\t\\t\\n\"],[4,\"contacts/icons/default-icon\",null,[[\"detail\"],[[23,1,[]]]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"tt\",[\"default for documents\"],null],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"defaultForDocs\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[28,\"tt\",[[23,2,[]]],null],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,1,[\"context\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--mini u-desc--italics\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,1,[\"context\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/results/contact-persons-results/template.hbs"
    }
  });
});