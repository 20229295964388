define("client/pods/components/color-themes/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ColorThemesComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _class = class ColorThemesComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "colorsResults", _descriptor3, this);
      if (!this.args.displaySelected) {
        this.colorsFilters = this.search.setupFilters({
          adapterName: 'websites/filters',
          defaultFiltersData: {
            resultsProperty: 'colorsResults',
            match: ['all']
          }
        });
        this.searchTask.perform();
      }
    }
    *searchTask() {
      const q = [];
      const query = this.query;
      q.push(this.searchColorTemplatesTask.perform({
        query,
        filters: this.colorsFilters
      }));
      yield (0, _emberConcurrency.all)(q);
      if (this.args.autoSelect) {
        this.selectColorTheme(this.colorsResults[0]);
      }
    }
    *searchColorTemplatesTask({
      adapterName = 'websites',
      query,
      filters
    }) {
      const resultsProperty = R.pathOr('', ['_data', 'resultsProperty'])(filters);
      this[resultsProperty] = yield this.crud.searchRecordsConcurrentlyTask.unlinked().perform({
        adapterName,
        appendPath: 'sharedColorThemes',
        filters,
        query
      });
      return this[resultsProperty];
    }
    displayColorTemplateModal() {
      this.args.toggleColorTemplates();
    }
    selectColorTheme(selectedTheme) {
      if (this.args.isReadonly) {
        return;
      }
      if (this.args.displaySelected) {
        return this.displayColorTemplateModal(...arguments);
      }
      const themeName = R.pathOr('', ['_data', 'name'])(selectedTheme);
      R.pipe(R.pathOr({}, ['_data']), R.toPairs, R.filter(R.pipe(R.head, R.test(/^theme/))), R.fromPairs, R.mapObjIndexed((value, key) => {
        this.args.model.setData(key, value);
      }))(selectedTheme);
      this.args.model.setData('themeName', themeName);
      const onSelect = this.args.onSelect;
      if (onSelect) {
        onSelect(selectedTheme);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "colorsResults", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchColorTemplatesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchColorTemplatesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayColorTemplateModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "displayColorTemplateModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectColorTheme", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectColorTheme"), _class.prototype), _class);
});