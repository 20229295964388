define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FUGy7Pk6",
    "block": "{\"symbols\":[\"formInitData\",\"bulkDiscount\",\"@model\",\"@originalModel\",\"@tab\",\"@onUpdateBulkDiscountTask\",\"@wizardContainerComponent\",\"@resourceList\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@tab\",\"@formInitData\"],[\"bulk discounts\",[23,5,[]],[28,\"hash\",null,[[\"documentationResource\",\"resourceList\",\"tab\",\"wizardContainerComponent\"],[\"campaigns\",[23,8,[]],[23,5,[]],[23,7,[]]]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,3,[\"_data\",\"bulkDiscounts\"]]],null,{\"statements\":[[4,\"each\",[[23,3,[\"_data\",\"bulkDiscounts\"]]],null,{\"statements\":[[0,\"        \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor\",[],[[\"@bulkDiscount\",\"@formInitData\",\"@model\",\"@originalModel\",\"@tab\",\"@onUpdateBulkDiscountTask\"],[[23,2,[]],[23,1,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"u-container bulk-discounts__u-container\"],[8],[0,\"\\n        \"],[1,[28,\"tt\",[\"no data\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog\",[],[[\"@model\"],[[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/template.hbs"
    }
  });
});