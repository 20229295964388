define("client/mixins/e-invoices-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_crud.default, _date.default, {
    listByTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      appendPath,
      match,
      abandoned,
      status,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (abandoned) {
        filters.setData('abandoned', abandoned);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (status) {
        if (!Ember.isArray(status)) {
          status = [status];
        }
        filters.setData('status', status);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.listByTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }
    }
  });
});