define("client/pods/components/websites/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6CDCqk7S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"domain\"]],[24,[\"query\"]]],null],false],[0,\" \"],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"result\",\"_data\",\"domainVerified\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"subdomain\"]],[24,[\"query\"]]],null],false],[0,\" \"],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\"],[1,[28,\"channels/shop/shop-status\",null,[[\"model\"],[[24,[\"result\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"result\",\"_data\",\"allowAllCampaigns\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[\"websiteForAllCampaigns\"],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/search-results/template.hbs"
    }
  });
});