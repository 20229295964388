define("client/pods/components/channels/shop/promo-codes/promo-codes-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6; // import Component from '@ember/component'
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopPromoCodesPromoCodesEditorComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _class = class ChannelsShopPromoCodesPromoCodesEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor5, this);
      _initializerDefineProperty(this, "isSetupPreset", _descriptor6, this);
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "translate", true);
      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/filters');
      this.crud.addLists(this, ['discounts/types', 'statuses', 'date/lists/unit-of-time']);
      const campaignFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.campaignFilters = campaignFilters;
    }
    get isReadonly() {
      if (this.args.isNew) {
        return false;
      }
      if (this.args.isEditing) {
        return false;
      }
      return true;
    }
    loadShopSource(model, shop) {
      model.setData('allowedShopKey', shop.getData('_key'));
    }
    unloadShopSource(model) {
      model.setData('allowedShopKey', '');
    }
    editPanel() {
      this.args.onEdit(this.args.model);
    }
    resetDate() {
      const model = this?.args?.model || {};
      if (RA.isNotNilOrEmpty(model)) {
        const useExpiryDate = R.pathOr(false, ['_data', 'useExpiryDate'])(model);
        if (useExpiryDate) {
          model.setData('dateStartZ', '');
          model.setData('dateEndZ', '');
        } else {
          model.setData('expiryDateStart', '');
          model.setData('expiryDateStartUnit', 'days');
          model.setData('expiryDateEnd', '');
          model.setData('expiryDateEndUnit', 'days');
        }
      }
    }
    get allowedProductTagLists() {
      const model = this?.args?.model || {};
      if (RA.isNilOrEmpty(model)) {
        return [];
      }
      const allProductTags = this.crud.lists['products-lists-tags'] || [];
      const deniedProductTags = this?._data?.denyProductTags || [];
      return R.reject(productTag => {
        const productTagKey = productTag?._data?._key || '';
        return R.includes(productTagKey, deniedProductTags);
      })(allProductTags);
    }
    get deniedProductTagLists() {
      const model = this?.args?.model || {};
      if (RA.isNilOrEmpty(model)) {
        return [];
      }
      const allProductTags = this.crud.lists['products-lists-tags'] || [];
      const allowedProductTags = this?._data?.productTags || [];
      return R.reject(productTag => {
        const productTagKey = productTag.getData('_key') || '';
        return R.includes(productTagKey, allowedProductTags);
      })(allProductTags);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isSetupPreset", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.isSetupPreset || false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadShopSource", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadShopSource", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editPanel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "editPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "resetDate"), _class.prototype), _class);
});