define("client/mixins/emails-dispatched", ["exports", "ramda", "ember-concurrency", "client/utils/nventor", "ramda-adjunct"], function (_exports, R, _emberConcurrency, _nventor, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    getToSendBatchEmailsTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        const response = yield this.server.call('GET', 'api/protected/documents/emails/process', {
          dispatchDateZ,
          dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
          dispatchProcess: dispatchProcess.getData('code')
        });
        return R.propOr([], 'data')(response);
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('getToSendBatchEmailsTask failed', err);
        return false;
      }
    }),
    sendBatchEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      subject,
      message,
      dispatchProcess,
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        const resp = yield this.server.call('PUT', 'api/protected/documents/emails/process', {
          subject,
          message,
          dispatchDateZ,
          dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
          dispatchProcess: dispatchProcess.getData('code')
        });
        let sent = 0;
        if (resp.data) {
          sent = resp.data.length;
        }
        onCloseDialogAction();
        window.alert('sent: ' + sent + ' emails.');
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('getToSendBatchEmailsTask failed', err);
        return false;
      }
    }),
    getToSendAllDispatchedEmailsTask: (0, _emberConcurrency.task)(function* ({
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        const response = yield this.server.call('GET', 'api/protected/documents/emails/dispatched', {
          dispatchDateZ
        });
        return R.propOr([], 'data')(response);
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('getToSendBatchEmailsTask failed', err);
        return false;
      }
    }),
    sendAllDispatchedEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      useDefaultDispatchedEmailMessage,
      subject,
      message,
      dispatchDateZ,
      useDefaultsDispatchedEmailEstimatedTransporterArrivalDate = true,
      dispatchedEmailHasEstimateTransporterArrivalDate = true,
      dispatchedEmailEstimateTransporterArrivalDaysFromDispatch = 1,
      dispatchedEmailTransporterArrivalDateSkipWeekends = false,
      errorsProp = 'errors'
    }) {
      try {
        this.clearValidationErrors(errorsProp);
        dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
        if (useDefaultDispatchedEmailMessage) {
          subject = '';
          message = '';
        }
        const resp = yield this.server.call('PUT', 'api/protected/documents/emails/dispatched', {
          subject,
          message,
          dispatchDateZ,
          useDefaultsDispatchedEmailEstimatedTransporterArrivalDate,
          dispatchedEmailHasEstimateTransporterArrivalDate,
          dispatchedEmailEstimateTransporterArrivalDaysFromDispatch,
          dispatchedEmailTransporterArrivalDateSkipWeekends,
          useDefaultDispatchedEmailMessage
        });
        let sent = 0;
        if (resp.data) {
          sent = resp.data.length;
        }
        onCloseDialogAction();
        window.alert('sent: ' + sent + ' emails.');
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('getToSendBatchEmailsTask failed', err);
        return false;
      }
    }),
    getToSendDispatchedEmailsTask: (0, _emberConcurrency.task)(function* ({
      dispatchDateZ,
      errorsProp = 'errors'
    }) {
      const docKey = this.get('model._data._key');
      try {
        this.clearValidationErrors(errorsProp);
        const docType = this.get('model._data.docType');
        const docName = this.get('model._data.docName');
        const groupId = this.get('model._data.groupId');
        const response = yield this.server.call('GET', `api/protected/documents/emails/dispatched/${docKey}`, {
          docType,
          docName,
          groupId
        });
        return R.propOr([], 'data')(response);
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('getToSendBatchEmailsTask failed', err);
        return false;
      }
    }),
    sendDispatchedEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      useDefaultDispatchedEmailMessage,
      message,
      dispatchDateZ,
      useDefaultsDispatchedEmailEstimatedTransporterArrivalDate = true,
      dispatchedEmailHasEstimateTransporterArrivalDate = true,
      dispatchedEmailEstimateTransporterArrivalDaysFromDispatch = 1,
      dispatchedEmailTransporterArrivalDateSkipWeekends = false,
      docKey,
      docType,
      docName
    }) {
      dispatchDateZ = dispatchDateZ || this.get('period.dateZ');
      if (useDefaultDispatchedEmailMessage) {
        message = '';
      }
      const groupId = this.get('model._data.groupId');
      const resp = yield this.server.call('PUT', `api/protected/documents/emails/dispatched/${docKey}`, {
        message,
        dispatchDateZ,
        docType,
        docName,
        groupId,
        useDefaultsDispatchedEmailEstimatedTransporterArrivalDate,
        dispatchedEmailHasEstimateTransporterArrivalDate,
        dispatchedEmailEstimateTransporterArrivalDaysFromDispatch,
        dispatchedEmailTransporterArrivalDateSkipWeekends
      });
      const data = resp?.data || [];
      const failedEmails = R.filter(R.equals(false))(data);
      const sentEmails = R.reject(R.equals(false))(data);
      let msg = '';
      if (_ramdaAdjunct.default.isNotNilOrEmpty(failedEmails)) {
        msg += `${this.intl.t('email not sent')}: ${R.length(failedEmails)}`;
        if (_ramdaAdjunct.default.isNotNilOrEmpty(sentEmails)) {
          msg += '\\n';
        }
      }
      if (_ramdaAdjunct.default.isNotNilOrEmpty(sentEmails)) {
        const emails = R.pipe(R.pluck('email'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), R.uniq, R.join(', '))(sentEmails);
        msg += this.intl.t('email sent to') + ': ' + emails;
        onCloseDialogAction();
      }
      window.alert(msg);
    }),
    sendPaymentReminderEmailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, {
      email,
      message,
      dispatchDateZ,
      docKey,
      docType,
      docName
    }) {
      const payload = email._data || {};
      payload.docType = docType;
      payload.docName = docName;
      const resp = yield this.server.call('POST', `api/protected/documents/emails/payment-reminder/${docKey}`, payload);
      const emailSent = R.path(['data', 'email'], resp);
      let msg = this.intl.t('email not sent');
      if (emailSent) {
        msg = this.intl.t('email sent to') + ': ' + emailSent;
        onCloseDialogAction();
      }
      window.alert(msg);
    })
  });
});