define("client/pods/components/users/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ayvUeNMd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"users/users-editor\",null,[[\"model\",\"isNew\",\"isEditing\",\"errors\",\"onEdit\"],[[24,[\"model\"]],false,false,[24,[\"errors\"]],[28,\"action\",[[23,0,[]],[24,[\"onEdit\"]],[24,[\"model\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/users/panels-display/template.hbs"
    }
  });
});