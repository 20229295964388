define("client/pods/components/documents/document-print/footer-purchases-terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5MTT7qik",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"hasPrintTerms\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"documents-print__terms documents-print__section has-text-left\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--bold documents-print__label\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"terms\"],null],false],[0,\": \\n      \"],[7,\"span\",true],[10,\"class\",\"u-no-print\"],[8],[0,\"\\n        \"],[5,\"elements/element-checkbox\",[],[[\"@value\",\"@onToggleProperty\"],[[22,\"isTermsVisible\"],[28,\"fn\",[[28,\"mut\",[[24,[\"isTermsVisible\"]]],null]],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"helpers/pre-line\",null,[[\"value\"],[[24,[\"model\",\"_data\",\"terms\"]]]]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"u-section u-no-print has-text-left\"],[8],[0,\"\\n    \"],[1,[28,\"tt\",[\"terms\"],null],false],[0,\": \\n\\n    \"],[5,\"elements/element-checkbox\",[],[[\"@value\",\"@onToggleProperty\"],[[22,\"isTermsVisible\"],[28,\"fn\",[[28,\"mut\",[[24,[\"isTermsVisible\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/footer-purchases-terms/template.hbs"
    }
  });
});