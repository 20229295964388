define("client/pods/components/calendar/calendar-month/component", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    firstWeekday: 'sunday',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['periods/months', 'periods/years']);
      const period = this.period;
      this.setCalendarMonth(period);
    },
    setCalendarMonth(period) {
      const today = this.getPeriod();
      const current = period || today;
      const daysByWeeks = this.getDaysOfMonthForCalendar(current.dateZ);
      const mm = current.mm;
      this.set('daysByWeeks', daysByWeeks);
      this.set('dateZ', current.dateZ);
      this.set('mm', mm);
      this.set('yyyy', current.yyyy);
      this.set('todayZ', today.dateZ);
      this.set('current', current);
    },
    daysOfWeek: Ember.computed('firstWeekday', 'dateZ', function () {
      return this.getWeekdays(this.firstWeekday);
    }),
    daysByWeeks: Ember.computed('firstWeekday', 'dateZ', function () {
      return this.getWeekdays(this.firstWeekday);
    }),
    actions: {
      previousMonth(current) {
        const previous = this.getPreviousPeriod(current.dateZ);
        this.setCalendarMonth(previous);
        return this.onPreviousMonth(previous);
      },
      nextMonth(current) {
        const next = this.getNextPeriod(current.dateZ);
        this.setCalendarMonth(next);
        return this.onNextMonth(next);
      },
      displayByCustom(onCloseDialogAction) {
        const yyyy = this.yyyy;
        const mm = this.mm;
        const selected = this.getMonthStart(yyyy, mm);
        const toPeriod = this.getPeriod(selected);
        this.setCalendarMonth(toPeriod);
        try {
          if (onCloseDialogAction) {
            onCloseDialogAction();
          }
        } catch (e) {
          console.error('could not close dialog. no dialog action');
        }
        return this.onNextMonth(toPeriod);
      }
    }
  });
});