define("client/pods/components/contacts/pickup/editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactsPickupEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('constants'), _dec3 = Ember.inject.service('settings'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class ContactsPickupEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "constantsService", _descriptor2, this);
      _initializerDefineProperty(this, "settingsService", _descriptor3, this);
      _initializerDefineProperty(this, "model", _descriptor4, this);
      _initializerDefineProperty(this, "personModel", _descriptor5, this);
      _initializerDefineProperty(this, "detailAddressModel", _descriptor6, this);
      _initializerDefineProperty(this, "detailTelephoneModel", _descriptor7, this);
      _initializerDefineProperty(this, "errors", _descriptor8, this);
      _initializerDefineProperty(this, "personDetails", _descriptor9, this);
      _defineProperty(this, "adapterName", 'contacts');
      this.crud.addLists(this, ['transporter/lists/pickup-editor-extension']);
      this.setupDirty();
    }
    get outerModel() {
      let model = this?.args?.model;
      const addItemOptions = this.args?.addItemOptions || {};
      const isListSelector = addItemOptions.isListSelector === true;
      if (isListSelector) {
        model = this?.args?.parentModel;
      }
      const transporterModel = addItemOptions.transporterModel;
      if (transporterModel) {
        model = transporterModel;
      }
      return model;
    }
    get tCatPersonDetails() {
      return [{
        customLabel: 'preferred delivery time',
        icon: 'fas fa-clock',
        type: 'deliveryTime',
        value: 'anytime',
        translate: true
      }, {
        customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        type: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true
      }, {
        customLabel: 'package size',
        icon: 'fas fa-ruler-combined',
        type: 'packageSize',
        value: '60cm'
      }, {
        customLabel: 'product type',
        icon: 'fas fa-cube',
        type: 'packageType',
        value: 'specialty and local good',
        translate: true
      }];
    }
    get hctPersonDetails() {
      return [{
        customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        type: 'transporterPackageTemperature',
        value: 'unrefrigerated',
        translate: true
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-user-circle',
        type: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true
      }];
    }
    get sevenElevenPersonDetails() {
      return [{
        customLabel: 'return storeId',
        icon: 'fas fa-user-circle',
        type: 'storeId',
        value: ''
      }, {
        customLabel: 'return storeName',
        icon: 'fas fa-user-circle',
        type: 'storeName',
        value: ''
      }, {
        customLabel: 'return storeAddress',
        icon: 'fas fa-user-circle',
        type: 'storeAddress',
        value: ''
      }, {
        customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        type: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true
      }];
    }
    get isNewPerson() {
      const personId = this.personModel._data.personId;
      const transporterPickupLocations = this.outerModel?._data?.persons || [];
      const personInTransporter = transporterPickupLocations.find(person => person._data.personId === personId);
      return RA.isNilOrEmpty(personInTransporter);
    }
    get transporterExtension() {
      const model = this.outerModel;
      return model?._data?.transporterExtension || '';
    }
    get transporterDefaultTypes() {
      if (this.transporterExtension === this.constantsService.transporterExtensions.tCat) {
        return this.tCatPersonDetails;
      } else if (this.transporterExtension === this.constantsService.transporterExtensions.hct) {
        return this.hctPersonDetails;
      } else if (this.transporterExtension === this.constantsService.transporterExtensions.sevenEleven) {
        return this.sevenElevenPersonDetails;
      }
    }
    get transporterEditorExtensionComponent() {
      const transporterListsCredentialsModal = this.crud.lists['transporter-lists-pickup-editor-extension'] || [];
      const transporterCredentialsModal = transporterListsCredentialsModal.find(item => item._data.value === this.transporterExtension);
      return transporterCredentialsModal?._data?.component;
    }
    getDetailByType(type) {
      return R.pipe(R.pathOr([], ['personModel', '_data', 'details']), R.find(R.pathEq(['_data', 'subType'], type)))(this);
    }
    setupDirty() {
      const model = this.outerModel;
      const isListSelector = this?.args?.addItemOptions?.isListSelector === true;
      if (model._data._key) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model
        });
      } else {
        this.model = model;
      }
      let person = this?.args?.person;
      const isEditing = this.args.isEditing === true;
      if (isListSelector && isEditing && !person) {
        person = this?.args?.model?.model;
      }
      if (person) {
        this.personModel = R.pipe(R.pathOr([], ['model', '_data', 'persons']), R.find(R.pathEq(['_data', 'personId'], person?._data?.personId)))(this);
        this.detailAddressModel = this.getDetailByType('address');
        this.detailTelephoneModel = this.getDetailByType('telephone');
        this.setupPersonDetails(person);
      } else {
        this.personModel = this.model.addTransporterPerson({
          type: 'pickupLocation',
          name: this.settingsService.settingsModel._data.company
        });
        if (!this.args?.model?.isSevenEleven) {
          this.detailAddressModel = this.personModel.addDetail({
            type: 'pickupLocation',
            subType: 'address',
            isDefault: true,
            value: this.settingsService.settingsModel._data.address
          });
        }
        this.detailTelephoneModel = this.personModel.addDetail({
          type: 'pickupLocation',
          subType: 'telephone',
          isDefault: true,
          value: this.settingsService.settingsModel._data.companyTel
        });
        this.setupPersonDetails();
        // this.mock()
      }
    }

    // NOTE: THIS FN SHOULD ONLY BE CALLED WHEN TRANSPORTER KEY IS T-CAT
    setupPersonDetails(person) {
      this.personDetails = {};
      const types = this.transporterDefaultTypes;
      types.forEach(({
        type = '',
        value = '',
        customLabel = '',
        icon = '',
        translate = false
      } = {}) => {
        if (person) {
          this.personDetails[type] = this.getDetailByType(type);
        } else {
          this.personDetails[type] = this.personModel.addDetail({
            customLabel,
            icon,
            type: 'pickupLocation',
            subType: type,
            isDefault: true,
            value,
            translate
          });
        }
      });
      this.personDetails = R.prop('personDetails')(this);
    }

    /**
     * @description this is only for development. Set sender name, address and telephone
     */
    mock() {
      const timestamp = new Date().getTime().toString().substring(10);
      this.personModel.set('_data.name', `Mr Mike_${timestamp}`);
      this.detailAddressModel.set('_data.value', `406台中市北屯區芳庭路${timestamp}號`);
      const telephone = `0${Math.floor(Math.random() * (999999999 - 900000000 + 1) + 900000000)}`;
      this.detailTelephoneModel.set('_data.value', telephone.slice(0, 7) + timestamp);
    }
    *updateTask(onCloseDialogAction, {
      dirty
    } = {}) {
      const fn = args => args;
      const addItemOptions = this.args?.addItemOptions || {};
      const refreshModel = addItemOptions.refreshModel || fn;
      const refreshTransporterModel = addItemOptions.refreshTransporterModel || fn;
      const pickupLocations = R.pathOr([], ['model', '_data', 'pickupLocations'])(this);
      // this.personModel.set('_data.details', [this.detailAddressModel, this.detailTelephoneModel])
      pickupLocations.push(this.personModel);
      this.model.set('_data.pickupLocations', pickupLocations);
      dirty = dirty || this.model;
      if (!dirty?._data?._key) {
        const model = this.outerModel;
        model.set('_data.defaultPickupLocationPersonId', this?.personModel?._data?.personId);
        refreshModel(model);
        return onCloseDialogAction();
      }
      if (this.args.updateContactTask) {
        return this.args.updateContactTask.perform(dirty, {
          onCloseDialogAction
        });
      } else {
        const updatedModel = yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterName,
          model: dirty,
          component: this
        });
        if (!updatedModel) {
          return;
        }
        refreshModel(updatedModel);
        refreshTransporterModel(updatedModel);
      }
      onCloseDialogAction();
    }
    *deleteTask(person, model, onCloseDialogAction = fn => fn) {
      const pickupLocations = model?._data?.persons || this?.model?._data?.persons || [];
      const updatedPickupLocations = pickupLocations.filter(personModel => {
        return personModel._data.personId !== person._data.personId;
      });
      let dirty = model;
      if (!model.isDirty) {
        dirty = this.crud.setupDirty({
          adapterName: this.adapterName,
          model
        });
      }
      dirty.set('_data.persons', updatedPickupLocations);
      return this.updateTask.perform(onCloseDialogAction, {
        dirty
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constantsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "personModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "detailAddressModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "detailTelephoneModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "personDetails", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupDirty", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupPersonDetails", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setupPersonDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mock", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "mock"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTask"), _class.prototype), _class);
});