define("client/pods/components/documents/pos/barcode-scanner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tmLMRbWp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"elements/element-input\",[],[[\"@readonly\",\"@leftIcon\",\"@value\",\"@onSubmit\",\"@isRunning\",\"@autofocus\"],[[23,0,[\"isRunning\"]],\"fas fa-barcode\",[23,0,[\"barcode\"]],[28,\"perform\",[[23,0,[\"onInputTask\"]],[23,0,[\"barcode\"]]],null],[23,0,[\"isRunning\"]],true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/pos/barcode-scanner/template.hbs"
    }
  });
});