define("client/pods/components/guests/campaign-main/campaign-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BkyB4lfx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__header-title\"],[8],[0,\"\\n\\n\\t\\t\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"placeholder\",\"autofocus\",\"value\",\"isRunning\",\"isSearchBar\",\"onSubmit\"],[\"activity\",true,[24,[\"filters\",\"_data\",\"query\"]],[23,0,[\"searchCampaignTask\",\"isRunning\"]],true,[28,\"perform\",[[23,0,[\"searchCampaignTask\"]]],null]]]],false],[0,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[28,\"or\",[[23,0,[\"searchCampaignTask\",\"isRunning\"]],[23,0,[\"fetchDocumentsTask\",\"isRunning\"]]],null]]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"documents/activity-list\",null,[[\"tab\",\"dispatchProcessesList\",\"hasTabLinks\",\"hideActions\",\"hideExcl\",\"showDetails\",\"isGuest\",\"filters\",\"results\",\"showOrderDate\",\"isRunning\"],[[24,[\"tab\"]],[23,0,[\"crud\",\"lists\",\"guests-dispatch-lists-processes\"]],false,true,true,true,true,[24,[\"filters\"]],[23,0,[\"results\"]],true,[24,[\"fetchDocumentsTask\",\"isRunning\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"helpers/pagination-controls\",null,[[\"filters\",\"list\",\"onPaginationTask\"],[[24,[\"filters\"]],[24,[\"results\"]],[23,0,[\"customPaginationTask\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/campaign-main/campaign-activity/template.hbs"
    }
  });
});