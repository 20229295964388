define("client/pods/components/emails/table-view/detail-row/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    tagName: '',
    showEmail: false,
    init() {
      this._super(...arguments);
      if (this.isFilters) {
        const campaignFilters = this.search.setupFilters({
          adapterName: 'channels/shop/campaigns/filters'
        });
        this.set('campaignFilters', campaignFilters);
      }
      const emailsCampaignsFilters = this.search.setupFilters({
        adapterName: 'emails/campaigns/filters'
      });
      this.set('emailsCampaignsFilters', emailsCampaignsFilters);
    },
    loadEmailsCampaigns(onSearchTask, emailsCampaigns) {
      const filters = this.filters;
      filters.setData('emailsCampaignsKey', emailsCampaigns.getData('_key'));
      onSearchTask.perform({
        filters
      });
    },
    unloadEmailsCampaigns(onSearchTask) {
      const filters = this.filters;
      filters.setData('emailsCampaignsKey', '');
      onSearchTask.perform({
        filters
      });
    },
    actions: {
      loadShopSource(onSearchTask, shop) {
        const filters = this.filters;
        filters.setData('sourceKey', shop.getData('_key'));
        onSearchTask.perform({
          filters
        });
      },
      unloadShopSource(onSearchTask) {
        const filters = this.filters;
        filters.setData('sourceKey', '');
        onSearchTask.perform({
          filters
        });
      },
      setupResendEmail(email) {
        const dirty = this.setupDirty({
          adapterName: 'emails',
          model: email
        });
        this.set('dirty', dirty);
      },
      clearResendEmail() {
        this.set('dirty', null);
      },
      async resendEmail(dirty, onCloseDialogAction) {
        await this.createRecordTask.perform({
          adapterName: 'emails',
          appendPath: '/resend',
          model: dirty
        });
        onCloseDialogAction();
        const filters = this.filters;
        this.onReloadSearchRecordsTask.perform({
          filters
        });
      },
      cancelResendEmail(onCloseDialogAction) {
        onCloseDialogAction();
        this.set('dirty', null);
      },
      showEmail() {
        this.set('showEmail', true);
      },
      closeEmail() {
        this.set('showEmail', false);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "loadEmailsCampaigns", [_dec], Object.getOwnPropertyDescriptor(_obj, "loadEmailsCampaigns"), _obj), _applyDecoratedDescriptor(_obj, "unloadEmailsCampaigns", [_dec2], Object.getOwnPropertyDescriptor(_obj, "unloadEmailsCampaigns"), _obj), _obj));
});