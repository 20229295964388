define("client/router", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('protected', {
      path: 'p'
    }, function () {
      this.route('companies', {
        path: '/:user_name/:user_id'
      });
      this.route('company', {
        path: '/c/:user_name/:user_id/:company_name'
      });
    });
    this.route('guests', function () {
      this.route('shop', function () {
        this.route('login', {
          path: '/login/:guest_resource'
        });
        this.route('report', {
          path: '/report/:guest_resource'
        });
      });
    });
    this.route('payments', function () {
      this.route('card', function () {
        this.route('lists', function () {
          this.route('panels');
        });
      });
    });
    this.route('reset');
  });
});