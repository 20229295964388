define("client/pods/components/channels/shop/items-by-payment-status-export-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-extension", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsItemsByPaymentStatusExportBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('date'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _class = class ChannelsShopCampaignsItemsByPaymentStatusExportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "excel", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "export", _descriptor4, this);
      _initializerDefineProperty(this, "dateService", _descriptor5, this);
      _initializerDefineProperty(this, "fileName", _descriptor6, this);
      _initializerDefineProperty(this, "exportData", _descriptor7, this);
      _initializerDefineProperty(this, "deconstructSet", _descriptor8, this);
      _initializerDefineProperty(this, "onlyHasActivity", _descriptor9, this);
      _initializerDefineProperty(this, "today", _descriptor10, this);
      this.today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD');
      this.fileName = this.getDefaultFilename();
    }
    getDefaultFilename() {
      const name = R.path(['args', 'model', '_data', 'name'])(this) || '';
      return `${name}-${this.today}`;
    }
    *fetchDataTask(onCloseDialogAction) {
      const model = this.args.model;
      const params = {
        deconstructSet: this.deconstructSet,
        onlyHasActivity: this.onlyHasActivity
      };
      // fetch data
      const exportData = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/export/items-by-payment-status',
        appendPath: model?._data?._key,
        params
      });
      const specsForSummary = [{
        column: this.intl.t('product'),
        prop: 'item'
      }, {
        column: this.intl.t('product code'),
        prop: 'itemCode'
      }, {
        column: this.intl.t('export_paid-available'),
        color: '',
        prop: 'available.paid',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_unpaid-available'),
        color: '',
        prop: 'available.unpaid',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_total-available'),
        color: '',
        prop: 'available.total',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_paid-preOrder'),
        color: '',
        prop: 'preOrder.paid',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_unpaid-preOrder'),
        color: '',
        prop: 'preOrder.unpaid',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_total-preOrder'),
        color: '',
        prop: 'preOrder.total',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_paid-total'),
        color: '',
        prop: 'total.paid',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_unpaid-total'),
        color: '',
        prop: 'total.unpaid',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_total-total'),
        color: '',
        prop: 'total.total',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_salesLimitedQty'),
        color: '',
        prop: 'salesLimitedQty',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_salesLimitedQty_remaining'),
        color: '',
        prop: 'salesLimitedQtyRemaining',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_preOrderLimitedQty'),
        color: '',
        prop: 'salesPreOrderLimitedQty',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('export_preOrderLimitedQty_remaining'),
        color: '',
        prop: 'salesPreOrderLimitedQtyRemaining',
        transform(value, row, spec) {
          if (row.hasSalesPreOrderLimitedQty) {
            return parseInt(value) || 0;
          }
          return '';
        }
      }];
      const worksheetData = R.pipe(R.pathOr([], ['_data', 'itemsByPaymentStatus']), R.map(row => {
        return R.map(spec => {
          let value;
          const path = spec.prop;
          if (!path) {
            value = '';
          }
          value = R_.dotPath(path)(row);
          if (spec.transform) {
            value = spec.transform(value, row, spec);
          }
          return value;
        })(specsForSummary);
      }))(exportData);
      const headers = R.pluck('column')(specsForSummary);
      this.exportData = {
        headers,
        data: worksheetData
      };
    }
    *exportTask(onCloseDialogAction) {
      const exportData = this.exportData;
      const headers = exportData.headers || [];
      const worksheetData = exportData.data || [];
      const sheetWithHeaders = R.prepend(headers)(worksheetData);
      const worksheets = [];
      worksheets.push({
        sheetName: this.today,
        data: sheetWithHeaders
      });
      yield this.excel.exportFile({
        worksheets,
        title: this.fileName
      });
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "exportData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deconstructSet", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onlyHasActivity", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "today", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype), _class);
});