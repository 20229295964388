define("client/helpers/isGreaterThan", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    compute(params) {
      const a = parseInt(params[0]);
      const b = parseInt(params[1]);
      return a > b;
    }
  });
});