define("client/pods/components/channels/shop/qty-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "k1zTXlav",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@model\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[28,\"number\",[[23,2,[\"_data\",\"calculatedTempQty\"]]],null],0],null]],null,{\"statements\":[[0,\"  \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"tag has-text-primary\"],[8],[0,\"\\n      \"],[1,[28,\"number\",[[23,2,[\"_data\",\"salesQty\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\"],[[23,1,[]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"tt\",[\"real qty sold\"],null],false],[0,\": \"],[1,[28,\"calculate\",[[23,2,[\"_data\",\"salesQty\"]],\"-\",[23,2,[\"_data\",\"calculatedTempQty\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"tt\",[\"on hold qty (checkout in progress)\"],null],false],[0,\": \"],[1,[23,2,[\"_data\",\"calculatedTempQty\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"u-desc u-desc--bold\"],[8],[0,\"\\n    \"],[1,[28,\"number\",[[23,2,[\"_data\",\"salesQty\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/qty-status/template.hbs"
    }
  });
});