define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ae6rcPGV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"item\",\"isDeleted\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"detailsRowComponent\",\"paginationIsFooter\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"tableViewOptions\"],[[24,[\"tab\"]],\"channels/shop/campaigns/items/table-view/detail-row\",false,[24,[\"sortedDetails\"]],[24,[\"filters\"]],[24,[\"onSearchTask\"]],[24,[\"onReloadSearchRecordsTask\"]],[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onDisplay\"]],[28,\"hash\",null,[[\"adapterName\",\"campaign\",\"campaignDetails\",\"isAutomations\"],[\"channels/shop/campaigns/automations/details\",[24,[\"model\"]],[24,[\"campaignDetails\"]],true]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-row/template.hbs"
    }
  });
});