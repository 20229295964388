define("client/pods/websites/pages/collection/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/sortable-details", "client/utils/nventor"], function (_exports, R, _model, _sortableDetails, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const WebsitesPagesCollectionBaseModel = _model.default.extend(_sortableDetails.default, {
    toSortProp: '_data.pages',
    indexProp: '_data.posIndex',
    init() {
      this._super(...arguments);
      this.set('adapters', {
        pages: 'websites/pages'
      });
    },
    populate(data, attrs) {
      data.pages = this._transformPages(data.pages);
      this._super(data, attrs);
    },
    _transformPages(pages = []) {
      return pages.map(page => {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.pages'),
          data: page,
          attrs: {
            sub: []
          }
        });
      });
    },
    getCurrentPage(page) {
      const key = page.getData('_key');
      const pages = this.getData('pages') || [];
      return R.find(R.pathEq(['_data', '_key'], key))(pages);
    },
    nextPosIndex: Ember.computed('_data.pages.[]', 'synced', function () {
      let pages = this.get('_data.pages') || [];
      pages = R.sortWith([R.ascend(R.path(['_data', 'posIndex']))])(pages);
      const lastPage = pages.get('lastObject');
      let lastPosIndex = pages.length;
      if (lastPage) {
        lastPosIndex = parseInt(lastPage.getData('posIndex')) || pages.length;
      }
      return lastPosIndex + 1;
    }),
    toSortArray: Ember.computed('_data.pages.[]', 'synced', function () {
      return this.get('_data.pages');
    }),
    menu: Ember.computed('_data.sorted.{[],@each.synced}', '_data.pages.@each.synced', function () {
      let sorted = this.sorted || [];
      const byDepths = R.pipe(R.sortWith([R.ascend(R.path(['_data', 'depth']))]), R.groupBy(R.path(['_data', 'depth'])), R.values)(sorted);
      const childrenByParent = R.pipe(R.drop(1), R.flatten, R.groupBy(R.path(['_data', 'parent'])))(byDepths);
      const findChildrenByParent = (parentKey, childrenByParent) => {
        return R.pipe(R.mapObjIndexed((childrenPages, key) => {
          const hasMatchingLastSegment = R.pipe(R.split('/'), _nventor.default.safeLast, R.equals(parentKey))(key);
          if (hasMatchingLastSegment) {
            return childrenPages;
          }
          return false;
        }), R.values, R.reject(R.equals(false)), R.flatten)(childrenByParent);
      };
      return R.pipe(R.map(depthData => {
        return R.map(item => {
          const sub = findChildrenByParent(item._data._key, childrenByParent);
          item.set('sub', sub);
          return item;
        })(depthData);
      }), _nventor.default.safeHeadOr([]))(byDepths);
    }),
    menuExcludingHome: Ember.computed('menu.@each.synced', 'synced', function () {
      const menu = this.menu || [];
      return R.reject(R.pathEq(['_data', 'isHome'], true))(menu);
    }),
    reorderPages(droppedOn, position, draggedData) {
      // const menu = this.get('menu') || [] //required to get posIndex?
      const pages = this.get('_data.pages') || [];
      // let details = menu || []
      const dragged = R.find(R.pathEq(['_data', '_key'], draggedData._key))(pages);
      const droppedParentKey = droppedOn.getData('parent');
      let droppedOnPosIndex = droppedOn.getData('posIndex');
      const draggedPosIndex = dragged.getData('posIndex');
      const draggedKey = dragged.getData('_key');
      if (draggedPosIndex === droppedOnPosIndex || draggedKey === droppedParentKey) {
        return pages;
      }
      const droppedDepth = droppedOn.getData('depth') || 0;
      dragged.set('_data.parent', droppedParentKey);
      dragged.set('_data.depth', droppedDepth);
      if (position === 'into') {
        // not yet implemented
      } else {
        if (draggedPosIndex > droppedOnPosIndex && position === 'below') {
          droppedOnPosIndex = droppedOnPosIndex + 1;
        }
        dragged.set('moveToIndex', droppedOnPosIndex);
      }
      if (draggedPosIndex < droppedOnPosIndex && position === 'below') {
        // move to below onOverItemIndex
        pages.map(detail => {
          this.moveDetailBelow(detail, draggedPosIndex, droppedOnPosIndex);
        });
      } else {
        // move to above onOverItemIndex
        pages.map(detail => {
          this.moveDetailAbove(detail, draggedPosIndex, droppedOnPosIndex);
        });
      }

      // reset index because it's messy.
      // filtering isHome because should always be on top
      // maybe need to filter isDynamicPage (system generated page) too?
      R.pipe(R.reject(R.pathEq(['_data', 'isHome'], true)), R.sortWith([R.ascend(R.path(['_data', 'posIndex']))]), R.forEachObjIndexed((page, index) => {
        const oldIndex = page.getData('posIndex');
        if (Number(oldIndex) !== Number(index)) {
          page.setData('posIndex', parseInt(index));
          page.set('posChanged', true);
        }
      }))(pages);
      this.updateSynced();
      return R.filter(R.pathEq(['posChanged'], true))(pages);
    },
    moveDetailAbove(detail, fromIndex, toIndex) {
      const moveToIndex = detail.get('moveToIndex');
      if (moveToIndex) {
        detail.set('_data.posIndex', moveToIndex);
        detail.set('moveToIndex', false);
        detail.set('posChanged', true);
        return detail;
      }
      const currentIndex = detail._data.posIndex;
      if (currentIndex >= toIndex && currentIndex < fromIndex) {
        detail.set('_data.posIndex', currentIndex + 1);
        detail.set('posChanged', true);
      }
      return detail;
    },
    moveDetailBelow(detail, fromIndex, toIndex) {
      const moveToIndex = detail.get('moveToIndex');
      if (moveToIndex) {
        detail.set('_data.posIndex', moveToIndex);
        detail.set('moveToIndex', false);
        detail.set('posChanged', true);
        return detail;
      }
      const currentIndex = detail._data.posIndex;
      if (currentIndex > fromIndex && currentIndex <= toIndex) {
        detail.set('_data.posIndex', currentIndex - 1);
        detail.set('posChanged', true);
      }
      return detail;
    }
  });
  let WebsitesPagesCollectionModel = _exports.default = (_dec = Ember._tracked, _class = class WebsitesPagesCollectionModel extends WebsitesPagesCollectionBaseModel {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});