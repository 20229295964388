define("client/pods/admin/service", ["exports", "ember-concurrency", "client/mixins/date", "ramda", "ramda-extension"], function (_exports, _emberConcurrency, _date, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(_date.default, {
    dataManager: Ember.inject.service(),
    server: Ember.inject.service(),
    intl: Ember.inject.service(),
    activePods: null,
    connectAsAdminTask: (0, _emberConcurrency.task)(function* () {
      const model = yield this.dataManager.findRecord({
        adapterName: 'admin'
      });
      yield this.server.call('GET', 'api/protected/admin/connections');
      return model;
    }),
    getAllActivePodsTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.server.call('GET', 'api/protected/admin/pods', {});
      if (this.activePods) {
        return this.activePods;
      }
      const activePods = R.pipe(R_.dotPathOr([], 'data'), R.map(data => {
        if (!data.relatedPods) {
          data.relatedPods = [];
        }
        return Ember.Object.create({
          _data: data
        });
      }))(results);
      this.set('activePods', activePods);
      return activePods;
    }).drop()
  });
});