define("client/pods/components/contacts/panels-display/sub-panels-activity/sub-panels-activity-doc/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/mixins/doc-list-by", "ember-concurrency"], function (_exports, _date, _settings, _crud, _docListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, _docListBy.default, _settings.default, {
    crud: Ember.inject.service(),
    filtersAdapterName: 'contacts/activity/filters',
    init() {
      this._super(...arguments);
      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');
      this.set('docType', docType);
      this.set('docName', docName);
      this.set('adapterName', `documents/${docType}/${docName}`);
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const count = this.count;
      this.set('count', count);
      const model = this.model;
      const contactKey = model.getData('_key');
      const contact = model.getData('name');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.fetchDataTask.perform({
        contactKey,
        contact
      });
    },
    fetchDataTask: (0, _emberConcurrency.task)(function* ({
      contactKey,
      contact,
      dateStart,
      dateEnd
    }) {
      const filters = this.filters;
      const results = yield this.listByTask.perform({
        adapterName: this.adapterName,
        filters,
        contactKey,
        contact,
        dateStart,
        dateEnd
      });
      this.set('results', results);
    }),
    actions: {
      displayByMonth(period) {
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
        const model = this.model;
        const contactKey = model.getData('_key');
        const contact = model.getData('name');
        this.fetchDataTask.perform({
          contactKey,
          contact,
          dateStart,
          dateEnd
        });
      },
      displayByCustomMonth() {
        const dateEndString = this.customDateEndZ;
        const dateStartString = this.customDateStartZ;
        let dateEnd;
        let dateStart;
        if (dateEndString) {
          if (!dateStartString) {
            const dateEndObj = this.getMonthEnd(dateEndString);
            dateStart = this.formatDateForUrl(dateEndObj.startOf('month'));
            dateStart = this.getStartOfDayZ(dateStart);
            dateEnd = dateEndString;
          } else {
            if (dateStartString > dateEndString) {
              dateEnd = dateStartString;
              dateStart = dateEndString;
            } else {
              dateEnd = dateEndString;
              dateStart = dateStartString;
            }
          }
          const model = this.model;
          const contactKey = model.getData('_key');
          const contact = model.getData('name');
          this.fetchDataTask.perform({
            contactKey,
            contact,
            dateStart,
            dateEnd
          });
        }
      }
    }
  });
});