define("client/pods/channels/shop/shipping-zones/free-shipping-rule/rule/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        when: 'qty',
        criteria: 'greaterThanOrEqualTo',
        value: '',
        valueMax: '',
        productTags: []
      };
    },
    populate(data, attrs) {
      this._super(data, attrs);
    }
  });
});