define("client/pods/components/workflows/items/mark-as-closed/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WorkflowsItemsEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('workflows'), _dec3 = Ember.inject.service('date'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _class = class WorkflowsItemsEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "workflowsService", _descriptor2, this);
      _initializerDefineProperty(this, "dateService", _descriptor3, this);
      _initializerDefineProperty(this, "errors", _descriptor4, this);
      _initializerDefineProperty(this, "dirty", _descriptor5, this);
    }
    setupDirty() {
      const dirty = this.crud.setupDirty({
        adapterName: 'workflows/items',
        component: this,
        model: this.args.model,
        resultsProperty: 'dirty'
      });
      const isSuccessful = this.args.isSuccessful;
      dirty.setData('isSuccessful', isSuccessful);
      dirty.setData('isClosed', true);
      const nowZ = this.dateService.getNowZ();
      dirty.setData('dateZClosed', nowZ);
      if (isSuccessful) {
        dirty.setData('status', 'successful');
      } else {
        dirty.setData('status', 'unsuccessful');
      }
      if (this.args.isSuccessful && this.args.workflow._data?.nextWorkflowKey) {
        const nextWorkflowData = this.workflowsService.getNextWorkflow({
          dirty: this.dirty,
          workflows: this.args.workflows || [],
          nextWorkflowKey: this.args.workflow._data?.nextWorkflowKey
        });
        if (RA.isNotNilOrEmpty(nextWorkflowData.workflow)) {
          dirty.setData('workflowKey', nextWorkflowData.workflow.getData('_key'));
          if (RA.isNotNilOrEmpty(nextWorkflowData.stage)) {
            dirty.setData('stageId', nextWorkflowData.stage.getData('stageId'));
            dirty.set('stage', nextWorkflowData.stage.getData('name'));
          }

          // reset status when has next status
          dirty.setData('status', 'open');
          dirty.setData('isSuccessful', '');
          dirty.setData('isClosed', false);
          dirty.setData('dateZClosed', '');
        }
      }
      this.dirty = dirty;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupDirty", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), _class);
});