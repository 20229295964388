define("client/pods/uploadcare/service", ["exports", "ramda", "client/config/environment", "ember-concurrency"], function (_exports, R, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals uploadcare */
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  // @TODO: USE TASKS
  var _default = _exports.default = Ember.Service.extend({
    server: Ember.inject.service(),
    intl: Ember.inject.service(),
    secureSignature: '',
    secureExpire: '',
    init() {
      this._super(...arguments);
      const uploadcareConfig = _environment.default.uploadcare;
      const environment = _environment.default.environment;
      this.set('publicKey', uploadcareConfig[environment].public.publicKey);
      this.set('cdnBase', uploadcareConfig[environment].public.cdnBase);
      const locale = this.intl.get('locale')[0];
      this.setup(locale);
    },
    removeStartingAndEndingSlashes(segment) {
      return R.pipe(R.when(R.startsWith('/'), R.drop(1)), R.when(R.endsWith('/'), R.dropLast(1)))(segment);
    },
    /**
     * generate url with transforms
     *
     * @param {string} uuid - file uuid
     * @param {string} transforms - eg. '/-/resize/200x300'
     * @returns
     */
    getFileUrl(uuid, transforms = '') {
      const cdnBase = this.removeStartingAndEndingSlashes(this.cdnBase);
      let url = `${cdnBase}/${uuid}/`;
      if (transforms) {
        url = this.removeStartingAndEndingSlashes(url);
        transforms = this.removeStartingAndEndingSlashes(transforms);
        url = `${url}/${transforms}/`;
      }
      return url;
    },
    getUc() {
      return uploadcare;
    },
    setup(locale, options) {
      const localesMap = {
        'zh-tw': 'zhTW'
      };
      const localeTranslations = {
        // messages for widget
        errors: {
          fileMinimalSize: this.intl.t('file is too small'),
          fileMaximumSize: this.intl.t('file is too large'),
          maxDimensions: this.intl.t('this image exceeds max dimensions'),
          maxWidth: 'This image exceeds max width.',
          maxHeight: 'This image exceeds max height.'
        },
        // messages for dialog’s error page
        dialog: {
          tabs: {
            preview: {
              error: {
                fileMinimalSize: {
                  title: 'Title.',
                  text: 'Text.',
                  back: 'Back'
                },
                fileMaximumSize: {
                  title: 'Title.',
                  text: 'Text.',
                  back: 'Back'
                },
                maxDimensions: {
                  title: 'Title.',
                  text: 'Text.',
                  back: 'Back'
                },
                maxWidth: {
                  title: 'Title.',
                  text: 'Text.',
                  back: 'Back'
                },
                maxHeight: {
                  title: 'Title.',
                  text: 'Text.',
                  back: 'Back'
                }
              }
            }
          }
        }
      };
      locale = localesMap[locale] || 'en';
      options = options || {};
      options.publicKey = this.publicKey;
      options.locale = locale;
      options.localeTranslations = localeTranslations;
      options.cdnBase = this.cdnBase;
      this.getUc().start(options);
    },
    getSecureTokenTask: (0, _emberConcurrency.task)(function* () {
      // fetch secure token
      const self = this;
      return self.get('server').call('GET', 'api/protected/uploads/uploadcare/secure').then(response => {
        const token = response?.data || {};
        this.set('secureSignature', token?.secureSignature);
        this.set('secureExpire', token?.secureExpire);
        return token;
      });
    }),
    uploadFile(file, settings) {
      const self = this;
      settings = settings || {};
      return self.getSecureTokenTask.perform().then(function (tokenData) {
        settings.secureSignature = tokenData.secureSignature;
        settings.secureExpire = tokenData.secureExpire;
        const upload = self.getUc().fileFrom('object', file, settings);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          upload.done(function (fileInfo) {
            resolve(fileInfo);
            // Upload has successfully completed and a file is ready.
          }).fail(function (error /* , fileInfo */) {
            // console.log('---err---')
            // console.log(error)
            // console.log(fileInfo)
            window.alert(self.get('intl').t('error occurred'));
            reject(error);
            // reject(error)
            // Upload failed, or something else went wrong, a file is not ready.
          });
        });
      });
    },
    updateFileToServer(resource, resourceKey, info) {
      // @TODO: handle single uploads

      const self = this;
      const path = 'api/protected/uploads/uploadcare/file/store';
      info.resource = resource;
      info.resourceKey = resourceKey;
      return self.get('server').call('POST', path, info);
    },
    updateFileGroupToServer(resource, resourceKey, info) {
      // @TODO: handle single uploads

      const self = this;
      const path = 'api/protected/uploads/uploadcare/groups/store';
      info.resource = resource;
      info.resourceKey = resourceKey;
      return self.get('server').call('POST', path, info);
    },
    containsUuid: R.curry((src, image) => {
      const contains = R.includes(R.path(['_data', 'uuid'], image), src);
      if (contains) {
        return contains;
      }
      return R.includes(R.path(['uuid'], image), src);
    })
  });
});