define("client/helpers/isExcluded", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params) {
      const model = params[0];
      const excludePath = params[1];
      if (!excludePath) {
        return false;
      }
      const excludeWhen = params[2];
      const value = model.get(excludePath);
      if (value === excludeWhen) {
        return true;
      }
      return false;
    }
  });
});