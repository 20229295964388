define("client/pods/components/lists/list-btn/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // @TODO: rename option to item
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    intl: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    toggleOnIconRightClassName: 'fas fa-caret-up',
    toggleOffIconRightClassName: 'fas fa-caret-down',
    tagName: 'span',
    itemValueKey: '_data.value',
    itemLabelKey: '_data.label',
    classNames: ['list-btn', 'u-tappable'],
    classNameBindings: ['getClassNames', 'classNames'],
    getClassNames: Ember.computed('hasCurrentError', function () {
      if (this?.hasCurrentError) {
        return 'has-error';
      }
      return '';
    }),
    selectedOptionValueKey: '_data.label',
    // input value to set using option.key when an option is selected
    hasDropdown: true,
    showDropdown: false,
    autoPosition: true,
    onSubmit: 'defaultToggleDropdown',
    // can supply custom function to search on onSubmit action
    readonly: false,
    translate: true,
    tooltipTranslate: true,
    value: '',
    isToggler: true,
    hasToggleIcon: false,
    hasToggleIconRight: true,
    list: [],
    resultsComponent: 'lists/list-results',
    // component to display results (defaults to list-results)

    didInsertElement() {
      this._super(...arguments);
      const tab = this.tab;
      const model = this.model;
      this.validationService.setErrorsTracker({
        tab,
        model,
        context: this.context
      });
      if (this.onDidInsert) {
        this.onDidInsert(this);
      }
    },
    currentErrors: Ember.computed('tab', 'tab.errors.errors.[]', function () {
      this.toggleProperty('has-input-error');
      return this.tab?.errors?.errors || [];
    }),
    hasCurrentError: Ember.computed('currentErrors.[]', 'context', function () {
      const currentErrors = this?.currentErrors || [];
      const context = this?.context;
      return R.pipe(R.find(R.anyPass([R.pathEq(['context', 'key'], context), R.pathEq(['context', 'label'], context), R.pathEq(['context', 'keyWithIndex'], context)])), RA.isNotNilOrEmpty)(currentErrors);
    }),
    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list
     */
    isDropdownVisible: Ember.computed('showDropdown', 'list', function () {
      // const list = this.get('list') || []
      if (this.readonly === false && this.showDropdown) {
        // if (this.get('readonly') === false && this.get('showDropdown') && list.length > 0) {
        return true;
      }
      return false;
    }),
    toggleOnDropdown() {
      this.set('showDropdown', true);
    },
    /**
       * get selected dropdown value
       * @param {object} item model
       * @return {string} selected item value
       */
    getSelectedLabel: function (item) {
      if (!item) {
        const value = this.value;
        const list = this.list;
        if (value != null && !R.isEmpty(list)) {
          if (!item) {
            item = this.getSelectedItem(value);
          }
        }
      }
      if (item) {
        const itemLabelKey = this.itemLabelKey;
        return item.get(itemLabelKey);
      }
      if (!item) {
        return this.label || '';
      }
    },
    getSelectedItem(value) {
      const list = this.list;
      const itemValueKey = this.itemValueKey;
      let i = 0;
      let item;
      if (R.is(Array, list)) {
        while (list[i]) {
          if (R.equals(list.get(`${i}.${itemValueKey}`), value)) {
            item = list[i];
            i = list.length + 1;
          }
          i++;
        }
      }
      return item;
    },
    selectedLabel: Ember.computed('translate', 'defaultLabel', 'value', 'list.[]', 'selected', 'selected.{_data.translate,synced}', 'label', 'updateLabel', function () {
      const value = this.value;
      const label = this.getSelectedLabel() || this.label;
      const selected = this.getSelectedItem(value);
      if (!selected) {
        const defaultLabel = this.label;
        if (defaultLabel) {
          if (this.translate) {
            return this.intl.t(defaultLabel);
          }
          return defaultLabel;
        }
      }
      let itemTranslate;
      if (selected) {
        itemTranslate = selected.get('_data.translate');
        const shouldTranslateLabel = this.settings.shouldTranslateLabel(value, this.translate, itemTranslate);
        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      }
      if (label && this.translate && this.list && value != null && value !== '') {
        const shouldTranslateLabel = this.settings.shouldTranslateLabel(value, this.translate, itemTranslate);
        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      }

      // if (value != null && value != '' && this.get('list')) {

      // }

      return label;
    }),
    onToggleModalDialog() {
      this.set('showDropdown', false);
    },
    submit(e) {
      if (R.is(Function, this.onSubmit)) {
        this.onSubmit(this.toggleOnDropdown, e);
      }
    },
    actions: {
      defaultToggleDropdown() {
        // @TODO: should remove this

        // this.set('showDropdown', true);
      },
      hideDropdown() {
        this.set('showDropdown', false);
      },
      selectDropdownItem(option /* , listSelectorComponent */) {
        // @TODO: no need to keep selectedOptionValueKey
        // @TODO: no need to set value

        // set value of input to option.selectedValueKey
        const selectedOptionValueKey = this.selectedOptionValueKey;
        if (selectedOptionValueKey) {
          this.set('value', option.get(selectedOptionValueKey));
        }

        // select dropdown option, set component value
        this.set('showDropdown', false);
        if (this.onSelect) {
          this.onSelect(option, this);
        }
      },
      deselectDropdownItem() {
        this.onDeselect();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "toggleOnDropdown", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleOnDropdown"), _obj), _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj), _applyDecoratedDescriptor(_obj, "submit", [_dec3], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj), _obj));
});