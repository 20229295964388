define("client/pods/extensions/service", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import AjaxMixin from '../../mixins/ajax'
  /**
   * performs and manages connections to servers
   */
  var _default = _exports.default = Ember.Service.extend({
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    host: _environment.default.APP.serverUrl
  });
});