define("client/pods/components/users/assign-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['list-selector'],
    itemClassNames: ['list-selector__item'],
    legendClassNames: ['list-selector__legend'],
    optionLabelKey: '_data.username',
    // key that points to option's label
    optionValueKey: '_data._key',
    // key that points to option's value
    value: '',
    /**
     * trigger onSelect action
     * @param {object} option - selected object
     * @param {object} optionComponent - the component sending the toggle action,
     *                                    used to keep track of selected state
     */
    selectOption(option /* ,  optionComponent */) {
      // send action to onSelect event..only used for when list is in a dropdown
      if (this.onSelect) {
        this.onSelect(option, this);
      }
    },
    // getOptionValue(option) {
    //   return option.get(this.get('itemValueKey'));
    // },
    //
    // /**
    //  * return model's selected value
    //  */
    // getModelValue() {
    //
    //   return this.get('model').get(this.get('valueKey'));
    // },
    // /**
    //  * set model's selected value
    //  */
    // setModelValue(value) {
    //
    //   return this.get('model').set(this.get('valueKey'), value);
    // },

    actions: {
      /**
       * listen for toggle event sent from options component
       */
      toggleOption(option, optionComponent) {
        // list results only allows select (no deselect)
        // handle select event
        this.selectOption(option, optionComponent);
      }
    }
  });
});