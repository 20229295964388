define("client/pods/components/ledgers/journals/cashbook/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hhYPe40J",
    "block": "{\"symbols\":[\"@tab\",\"@accountKey\",\"@account\",\"@filters\",\"@isAllBanks\"],\"statements\":[[5,\"ledgers/journals/cashbook/cashbook-editor\",[],[[\"@tab\",\"@accountKey\",\"@account\",\"@filters\",\"@isAllBanks\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/journals/cashbook/panels-display/template.hbs"
    }
  });
});