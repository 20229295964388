define("client/helpers/dateTime", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_date.default, {
    intl: Ember.inject.service(),
    compute(params /* ,  hash */) {
      const dateZ = params[0];
      const format = params[1] || 'YYYY-MM-DD HH:mm:ss';
      return this.formatDate(dateZ, format);
    }
  });
});