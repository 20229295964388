define("client/pods/components/menus/nav-tree/nav-nested/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    settings: Ember.inject.service(),
    classNames: ['nav-nested'],
    itemClassNames: [],
    translate: true,
    item: null,
    isExpanded: false,
    sub: Ember.computed('item._data.sub.[]', function () {
      return R.pipe(R.pathOr([], ['item', '_data', 'sub']), R.filter(item => {
        return this.settings.isBetaAllowed(item?.beta);
      }))(this);
    }),
    groups: Ember.computed('item._data.groups.[]', function () {
      return R.pipe(R.pathOr([], ['item', '_data', 'groups']), R.map(group => {
        return R.filter(item => {
          return this.settings.isBetaAllowed(item?.beta);
        })(group);
      }), R.reject(RA.isNilOrEmpty))(this);
    })
  });
});