define("client/helpers/replace", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params /* , hash */) {
      const pattern = params[0];
      const replaceWith = params[1];
      const str = params[2];
      return R.replace(pattern, replaceWith)(str);
    }
  });
});