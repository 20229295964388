define("client/pods/components/admin/payments/card/panels-payments/panels-statements/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PrRmCvDO",
    "block": "{\"symbols\":[\"statement\",\"index\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__header-title u-header-1\"],[8],[0,\"\\n    \"],[1,[24,[\"period\",\"dateStart\"]],false],[0,\" ~ \"],[1,[24,[\"period\",\"dateEnd\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchStatementsTask\",\"isRunning\"]]]],{\"statements\":[[0,\"    \"],[7,\"table\",true],[10,\"class\",\"table\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"statements\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],0],null]],null,{\"statements\":[[0,\"          \"],[1,[28,\"admin/payments/card/panels-payments/panels-statements/statement-row\",null,[[\"isHeader\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[28,\"admin/payments/card/panels-payments/panels-statements/statement-row\",null,[[\"index\",\"period\",\"filters\",\"onFetchStatementDetailsTask\",\"onPaginateStatementDetailsTask\",\"statement\"],[[23,2,[]],[24,[\"period\"]],[24,[\"filters\"]],[24,[\"fetchStatementDetailsTask\"]],[24,[\"paginateStatementDetailsTask\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/admin/payments/card/panels-payments/panels-statements/template.hbs"
    }
  });
});