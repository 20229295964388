define("client/pods/components/products/search-bar-container/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eCtdPx3D",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"placeholder\",\"value\",\"isRunning\",\"isSearchBar\",\"onSubmit\"],[[24,[\"autofocus\"]],\"products\",[24,[\"query\"]],[28,\"or\",[[24,[\"searchInputTask\",\"isRunning\"]],[24,[\"listByTask\",\"isRunning\"]]],null],true,[28,\"perform\",[[24,[\"searchInputTask\"]],[28,\"hash\",null,[[\"query\",\"resultsToggle\",\"filters\"],[[24,[\"query\"]],\"resultsToggle\",[24,[\"filters\"]]]]],[24,[\"query\"]]],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"products/search-filters\",null,[[\"adapterName\",\"filters\",\"results\"],[[24,[\"adapterName\"]],[24,[\"filters\"]],[24,[\"results\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/search-bar-container/search-bar/template.hbs"
    }
  });
});