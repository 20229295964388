define("client/mixins/transporter-accounts", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    transporterAccounts: Ember.computed('transporter', function () {
      return this.get('transporter._data.transporterAccounts') || [];
    }),
    defaultTransporterAccount: Ember.computed('transporterAccounts.[]', 'transporterAccounts.@each.synced', function () {
      const defaultAccount = this.transporterAccounts.find(transporterAccount => transporterAccount?._data?.isDefault);
      if (!defaultAccount) {
        return _nventor.default.safeHeadOr({})(this.transporterAccounts);
      }
      return defaultAccount;
    }),
    defaultTransporterAccountNumber: Ember.computed('defaultTransporterAccount', function () {
      return this.get('defaultTransporterAccount._data.account') || '';
    })
  });
});