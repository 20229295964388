define("client/pods/components/lists/list-searcher/list-searcher-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8hdRbNAZ",
    "block": "{\"symbols\":[\"@value\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"icon\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"prependHasSelected\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"prependHasSelected\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"helpers/multiline-truncate\",[],[[\"@content\",\"@modalPos\"],[[23,1,[]],\"top\"]]],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/lists/list-searcher/list-searcher-tag/template.hbs"
    }
  });
});