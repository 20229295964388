define("client/pods/contacts/details/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    componentPath: 'contacts/details/editor-details/editor-',
    component: Ember.computed('_data.type', function () {
      return this.componentPath + this.get('_data.type');
    }),
    isAddress: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === 'address') {
        return true;
      }
      return false;
    }),
    label: Ember.computed('_data.{subType,customLabel}', function () {
      if (this._data?.customLabel) {
        return this._data.customLabel;
      }
      return this._data.subType;
    })
  });
});