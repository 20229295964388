define("client/pods/components/operator/change-logs/panels-new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    adapterName: 'operator/change-logs',
    init: function () {
      this._super(...arguments);
      this.setup();
      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    },
    setup() {
      if (!this.model) {
        const data = {};

        // setup new products model
        const model = this.crud.setupNewRecord?.({
          adapterName: this.adapterName,
          data
        });
        this.set('model', model);
      }
    }
  });
});