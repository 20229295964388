define("client/pods/components/elements/element-label-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "S41msOfu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\n\\t\"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"defaultLabel\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"tt\",[[24,[\"defaultLabel\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\t\"],[1,[28,\"elements/element-icon\",null,[[\"iconClassName\"],[[24,[\"toggleIconClassName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-label-dropdown/template.hbs"
    }
  });
});