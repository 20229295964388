define("client/pods/payments/linepay/transactions/model", ["exports", "client/pods/documents/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PaymentsCardTransactionsModel = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember._tracked, _class = class PaymentsCardTransactionsModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "constants", _descriptor, this);
      _initializerDefineProperty(this, "dateService", _descriptor2, this);
      /** @type {ccTemp} */
      _initializerDefineProperty(this, "_data", _descriptor3, this);
      _defineProperty(this, "overDueAfter", 20);
    }
    // minutes

    get ccNumber() {
      const partialNumber = R.pathOr(null, ['_data', 'paymentCreditCard', 'partial_card_number'])(this);
      if (partialNumber) {
        return partialNumber;
      }
      const paymentCreditCardLastFourDigits = R.pathOr(null, ['_data', 'paymentCreditCardLastFourDigits'])(this);
      if (paymentCreditCardLastFourDigits) {
        return paymentCreditCardLastFourDigits;
      }
      const tapPayCardInfo = R.pathOr(null, ['_data', 'firstPaymentResponse', 'card_info'])(this);
      const lastFour = R.propOr(null, 'last_four')(tapPayCardInfo);
      if (lastFour) {
        return lastFour;
      }
      return '';
    }
    get isTapPay() {
      const provider = R.pathOr('', ['_data', 'paymentCreditCardProvider'])(this);
      return provider === 'tapPay';
    }
    get isSuccessfulTransaction() {
      const hasTransactionCode = R.pipe(R.pathOr('', ['_data', 'paymentCreditCardTransactionCode']), RA.isNotNilOrEmpty)(this);
      const hasSuccessfulMessage = R.pipe(R.pathOr('', ['_data', 'paymentResponse', 'msg']), R.toLower, R.equals('success'))(this);
      return hasTransactionCode && hasSuccessfulMessage;
    }
    get isUnsuccessfulTransaction() {
      const isUnsuccessful = R.pipe(R.pathOr('', ['_data', 'paymentsResponses', 'msg']), R.toLower, R.equals('success'), R.not)(this);
      if (isUnsuccessful && !this.isAwaitingPayment) {
        return true;
      }

      // const isPending = this._data.paymentCreditCardStatus === this.constants.paymentCreditCardStatus.pending
      // const isNotSuccessful = this._data.isSuccessful === false
      // return isPending && isNotSuccessful

      return false;
    }
    get isAwaitingPayment() {
      const isPending = this._data.paymentCreditCardStatus === this.constants.paymentCreditCardStatus.pending;
      const isNotSuccessful = this._data.isSuccessful === false;
      return isPending && isNotSuccessful && !this.paymentTransactionOverDue;
    }
    get paymentTransactionOverDue() {
      const has3ds = R.pathOr(false, ['_data', 'paymentCreditCardThreeDomainSecure'])(this);
      if (!has3ds) {
        return false;
      }
      const now = this.dateService.getMoment().subtract(this.overDueAfter, 'm');
      const paymentCreditCardTransactionTimestampZ = R.pipe(R.pathOr('', ['_data', 'timestampZ']), time => this.dateService.getMoment(time))(this);
      return now.isAfter(paymentCreditCardTransactionTimestampZ);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  /**
   * @typedef ccTemp
   * @type {Object}
   * @property {string} paymentCreditCardProvider
   * @property {string} paymentCreditCardStatus
   * @property {string} paymentsResponses
   * @property {boolean} isSuccessful
   */
});