define("client/pods/components/workflows/create-dynamic/workflow-editor-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "w7DoahEs",
    "block": "{\"symbols\":[\"@addItemOptions\",\"@isNew\",\"@isEditing\"],\"statements\":[[5,\"workflows/workflow-editor\",[],[[\"@tab\",\"@type\",\"@adapterName\",\"@onCloseDialogAction\",\"@isNew\",\"@isEditing\",\"@model\",\"@onCancel\"],[[23,1,[\"tab\"]],\"workflows\",\"workflows\",[23,0,[\"onClose\"]],[23,2,[]],[23,3,[]],[23,0,[\"model\"]],[23,0,[\"onClose\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/create-dynamic/workflow-editor-wrapper/template.hbs"
    }
  });
});