define("client/pods/components/notifications/container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ua3X5UCb",
    "block": "{\"symbols\":[\"changeLogs\",\"alert\"],\"statements\":[[5,\"updates/update-btn\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",\"u-section\"],[3,\"will-destroy\",[[28,\"fn\",[[23,0,[\"notificationsService\",\"notificationsManager\",\"updateSynced\"]]],null]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"notificationsService\",\"notificationsManager\",\"_data\",\"alerts\"]]],null,{\"statements\":[[0,\"    \"],[5,\"notifications/alert\",[],[[\"@alert\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"crud\",\"lists\",\"application-change-logs\"]]],null,{\"statements\":[[0,\"  \"],[5,\"notifications/display\",[],[[\"@resource\",\"@notification\"],[\"changeLogs\",[23,1,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"u-desc\"],[8],[0,\"\\n        \"],[5,\"elements/status-badge\",[],[[\"@list\",\"@status\"],[[23,0,[\"crud\",\"lists\",\"notifications-statuses\"]],[23,1,[\"_data\",\"notificationStatus\"]]]]],[0,\"\\n\\n        \"],[1,[28,\"tt\",[\"version\"],null],false],[0,\": \"],[1,[23,1,[\"_data\",\"version\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n        \"],[1,[28,\"date\",[[23,1,[\"_data\",\"dateZ\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"froala\",[],[[\"@isReadonly\",\"@content\"],[true,[23,1,[\"_data\",\"publicReleaseNotes\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/notifications/container/template.hbs"
    }
  });
});