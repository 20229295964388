define("client/pods/flows/steps/actions/games/model", ["exports", "client/pods/base/model", "ramda", "client/utils/nventor"], function (_exports, _model, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    defaults() {
      return {
        actionType: 'games',
        unsuccessfulMessage: '',
        numberOfPlays: 1,
        prizes: []
      };
    },
    populate(data, attrs) {
      data.prizes = this._transformPrizes(data);
      this._super(data, attrs);
    },
    _transformPrizes(data) {
      return R.pipe(R.propOr([], 'prizes'), R.map(prizeData => {
        return this.dataManager.setAsRecord({
          adapterName: 'flows/steps/actions/games/prizes',
          data: prizeData
        });
      }))(data);
    },
    newPrize(data = {}) {
      const prizeModel = this.addPrize();
      const prizeType = prizeModel?._data?.prizeType;
      if (prizeType === 'generatePromoCode') {
        const shopKey = '';
        const shop = '';
        const promoCodeModel = prizeModel.getPromoCodeModel({
          shopKey,
          shop
        });
        prizeModel.setData('defaults', promoCodeModel);
      }
      return prizeModel;
    },
    addPrize(data = {}) {
      data.subStepId = _nventor.default.random.alphaNum(17);
      const prizeModel = this.dataManager.setAsRecord({
        adapterName: 'flows/steps/actions/games/prizes',
        data
      });
      const prizes = this.getData('prizes') || [];
      prizes.pushObject(prizeModel);
      this.setData('prizes', prizes);
      return prizeModel;
    },
    removePrize(prizeModel) {
      const prizes = this.getData('prizes') || [];
      prizes.removeObject(prizeModel);
      return prizes;
    }
  }, _applyDecoratedDescriptor(_obj, "newPrize", [_dec], Object.getOwnPropertyDescriptor(_obj, "newPrize"), _obj), _applyDecoratedDescriptor(_obj, "addPrize", [_dec2], Object.getOwnPropertyDescriptor(_obj, "addPrize"), _obj), _applyDecoratedDescriptor(_obj, "removePrize", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removePrize"), _obj), _obj));
});