define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/has-variants/variants-detail/component", ["exports", "client/mixins/crud", "ramda", "ramda-adjunct"], function (_exports, _crud, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    tagName: '',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/commission-filler']);
    },
    setAllChild({
      child,
      commissionPropertyName,
      childIndex
    }) {
      const fillerType = child.get('fillerType');
      const fillerValue = child.getData(commissionPropertyName);
      const itemModel = this?.item || {};
      R.pipe(R.pathOr([], ['_data', 'variantChildren']), RA.mapIndexed((variantChild, index) => {
        if (R.equals(fillerType, 'fillTop') && R.lt(index, childIndex) || R.equals(fillerType, 'fillBottom') && R.gt(index, childIndex) || R.equals(fillerType, 'fillTopAndBottom')) {
          variantChild.setData(commissionPropertyName, fillerValue);
        }
      }))(itemModel);
      if (this.onRecalculatePrice) {
        this.onRecalculatePrice();
      }
      Ember.set(child, 'fillerType', '');
      Ember.set(child, 'fillerLabel', '');
    }
  }, _applyDecoratedDescriptor(_obj, "setAllChild", [_dec], Object.getOwnPropertyDescriptor(_obj, "setAllChild"), _obj), _obj));
});