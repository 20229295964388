define("client/pods/documents/lists/types/sales/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import nventor from '../../../../../utils/nventor'
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: 'value',
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const customData = {
        list: [{
          resource: '/api/protected/documents/sales/quotes',
          label: 'sales-quotes',
          value: 'sales-quotes',
          docType: 'sales',
          docName: 'quotes'
        }, {
          resource: '/api/protected/documents/sales/orders',
          label: 'sales-orders',
          value: 'sales-orders',
          docType: 'sales',
          docName: 'orders'
        }, {
          resource: '/api/protected/documents/sales/consignments',
          label: 'sales-consignments',
          value: 'sales-consignments',
          docType: 'sales',
          docName: 'consignments'
        }, {
          resource: '/api/protected/documents/sales/consignments-returns',
          label: 'sales-consignments-returns',
          value: 'sales-consignments-returns',
          docType: 'sales',
          docName: 'consignments-returns'
        }, {
          resource: '/api/protected/documents/sales/invoices',
          label: 'sales-invoices',
          value: 'sales-invoices',
          docType: 'sales',
          docName: 'invoices'
        }, {
          resource: '/api/protected/documents/sales/returns',
          label: 'sales-returns',
          value: 'sales-returns',
          docType: 'sales',
          docName: 'returns'
        }]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});