define("client/pods/components/messages/tab-container/messages-window/message-bubble/image-type/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    isShowPreview: false,
    didInsertElement() {
      this._super(...arguments);
      const messageBubble = this.messageBubble;
      if (this.isNotText(messageBubble)) {
        this.set('messageType', messageBubble.type);
        const url = R.ifElse(R.hasPath(['payload', 'url']), R.path(['payload', 'url']), R.path(['url']))(messageBubble);
        this.set('messageUrl', url);
      }
    },
    isNotText: R.is(Object),
    actions: {
      showPreview() {
        this.set('isShowPreview', true);
      }
    }
  });
});