define("client/pods/payments/virtual-accounts/specs/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import * as R from 'ramda'
  // import EmberObject, { computed } from '@ember/object'
  var _default = _exports.default = _model.default.extend({
    // populate (data, attrs) {
    //   if (!data.label) {
    //     data.label = ''
    //     if (data.fn) {
    //       data.label = attrs.intl.t(data.fn)
    //     }
    //     if (data.type) {
    //       data.label = attrs.intl.t(data.type)
    //     }
    //   }
    //   if (!data.value) {
    //     data.value = ''
    //     if (data.fn) {
    //       data.value = data.fn
    //     }
    //     if (data.type) {
    //       data.value = data.type
    //     }
    //   }

    //   this._super(...arguments)
    // }
  });
});