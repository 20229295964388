define("client/pods/channels/shop/rewards/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/rewards/model", "ramda", "ramda-adjunct", "client/constants"], function (_exports, _adapter, _adaptersNew, _model, R, RA, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/shop/rewards',
    idParam: '_id',
    modelObj: _model.default,
    serialize(model) {
      const memberLevelsData = R.pathOr([], ['_data', 'memberLevels'])(model);
      if (RA.isNotNilOrEmpty(memberLevelsData)) {
        R.forEach(memberLevel => {
          const rewardsEventsData = R.pathOr([], ['_data', 'rewardsEvents'])(memberLevel);
          if (RA.isNotNilOrEmpty(rewardsEventsData)) {
            R.forEach(rewardEvent => {
              if (rewardEvent._data.isNeverExpire) {
                rewardEvent.set('_data.expiryTime', '');
              }
              if (rewardEvent._data.earnType === _constants.default.rewardEarningTypes.amount) {
                rewardEvent.set('_data.earnRate', '');
              } else {
                rewardEvent.set('_data.earnAmount', '');
              }
              if (rewardEvent._data.spendMaximumType === _constants.default.rewardMaxDeductionType.amount) {
                rewardEvent.set('_data.spendMaximumRate', '');
              } else {
                rewardEvent.set('_data.spendMaximumAmount', '');
              }
              if (rewardEvent._data.earnRequirementValue) {
                rewardEvent.setData('earnRequirementValue', Number(rewardEvent.getData('earnRequirementValue') || 0));
              }
              if (rewardEvent._data.earnAmount) {
                rewardEvent.setData('earnAmount', Number(rewardEvent.getData('earnAmount') || 0));
              }
            })(rewardsEventsData);
          }
        })(memberLevelsData);
      }
      return this._serialize(model);
    }
  });
});