define("client/pods/components/websites/panels-display/sub-panels-pages/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/crud", "ember-concurrency"], function (_exports, R, RA, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    adapterName: 'websites/pages',
    applicationService: Ember.inject.service('application'),
    search: Ember.inject.service(),
    showWebsitePagesNav: false,
    isMenuExpanded: false,
    didInsertElement() {
      this._super(...arguments);
      const model = this.get('model');
      const filters = this.search.setupFilters({
        adapterName: 'websites/pages/filters',
        defaultFiltersData: {
          master: model.getData('_key')
        }
      });
      this.set('filters', filters);
      this.fetchPagesCollectionTask.perform();
    },
    fetchPagesCollectionTask: (0, _emberConcurrency.task)(function* () {
      const model = this.get('model');
      const collectionModel = yield this.findTask.perform({
        adapterName: 'websites/pages/collection',
        appendPath: model.getData('_key')
      });
      this.set('pagesCollection', collectionModel);
      const pages = collectionModel.get('sorted') || [];
      collectionModel.setData('pages', pages);
      const initialPage = this.tabsManager.getLoadInstructions(this.tab) || pages.get('firstObject');
      if (initialPage) {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-display', initialPage);
      } else {
        this.newPanel(model);
      }
    }),
    isSearchResults: Ember.computed('filters.results.[]', 'filters._data.query', 'pagesCollection.{synced,menu.[]}', function () {
      const filters = this.get('filters');
      const query = filters?._data?.query;
      const results = filters?.results || [];
      if (query && RA.isNotNilOrEmpty(results)) {
        return true;
      }
      return false;
    }),
    filteredPages: Ember.computed('filters.results.[]', 'filters._data.query', 'pagesCollection.{synced,menu.[]}', function () {
      const filters = this.get('filters');
      const query = filters?._data?.query;
      const results = filters?.results || [];
      const pagesCollection = this.get('pagesCollection');
      if (query && RA.isNotNilOrEmpty(results)) {
        return results;
      }
      return pagesCollection?.menu || [];
    }),
    displaySubPanel(componentName, pageModel) {
      if (!pageModel?.isModel) {
        const collectionModel = this.pagesCollection;
        const pages = collectionModel.get('sorted') || [];
        pageModel = this.tabsManager.getLoadInstructions(this.tab) || pages.get('firstObject');
      }
      if (pageModel?.isModel) {
        const subTabOptions = {
          component: componentName,
          id: pageModel.getData('name'),
          model: pageModel
        };
        const tab = this.tab;
        tab.resetTabs();
        this.tabsManager.replaceSubTab(tab, subTabOptions);
        this.set('selectedPageModel', pageModel);
      } else {
        const model = this.model;
        this.newPanel(model);
      }
      this.set('showWebsitePagesNav', false);
    },
    newPanel(website, parentModel, type) {
      const collectionModel = this.pagesCollection;
      const data = {};
      const pages = collectionModel.get('sorted') || [];
      if (pages.length < 1) {
        data.isHome = true;
        data.name = this.intl.t('home page');
      } else {
        data.isHome = false;
      }
      data.master = website.getData('_key');
      data.status = this.get('constants.status.active');
      let depth = 0;
      const posIndex = collectionModel.get('nextPosIndex');
      if (R.path(['_data', 'name'], parentModel)) {
        // index = collectionModel.getNextSubIndex(parentModel)
        const parentsArray = [];
        const grandParent = parentModel.getData('parent');
        if (grandParent) {
          parentsArray.pushObject(grandParent);
        }
        parentsArray.pushObject(parentModel.getData('_key'));
        const currentParent = R.join('/', parentsArray);
        depth = R.pipe(R.split('/'), R.length)(currentParent);
        data.parent = currentParent;
      }
      data.posIndex = posIndex;
      data.depth = depth;
      data.type = type;
      data.hasTemplate = false;
      if (type === 'page') {
        data.hasTemplate = true;
      }
      const pageModel = this.setupNewRecord({
        adapterName: 'websites/pages',
        data
      });
      pageModel.onSelectType();
      const subTabOptions = {
        component: 'websites/panels-display/sub-panels-pages/pages-new',
        value: this.intl.t('new'),
        model: pageModel
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
      this.set('showWebsitePagesNav', false);
    },
    actions: {
      toggleShowWebsitePagesNav() {
        this.set('showWebsitePagesNav', !this.showWebsitePagesNav);
      },
      newPage(onHideDropdown, type, website = this.model, parent) {
        this.newPanel(website, parent, type);
        if (onHideDropdown) {
          onHideDropdown();
        }
      },
      displaySubPanel(model) {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-display', model);
      },
      afterRemovePage() {
        this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-display');
      },
      editPage(model) {
        return this.displaySubPanel('websites/panels-display/sub-panels-pages/pages-edit', model);
      },
      reorderPages(droppedOn, position, dragged) {
        const pagesCollection = this.pagesCollection;
        let pages = pagesCollection.reorderPages(droppedOn, position, dragged);
        pages = R.map(page => {
          page.set('posChanged', false);
          return {
            _key: page.getData('_key'),
            posIndex: page.getData('posIndex'),
            depth: page.getData('depth'),
            parent: page.getData('parent')
          };
        })(pages);
        if (!R.isEmpty(pages)) {
          const adapterName = 'websites/pages/collection';
          const dirty = this.setupDirty({
            adapterName,
            model: pagesCollection
          });
          dirty.setData('pages', pages);
          const master = this.get('model._data._key');
          const appendPath = `/indexes/${master}`;
          this.updateRecordTask.perform({
            adapterName,
            appendPath,
            model: dirty
          });
        }
      }
    }
  });
});