define("client/pods/components/transporters/waybill-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uebhvSqG",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@model\",\"@tab\"],\"statements\":[[4,\"if\",[[23,2,[\"_data\",\"waybill\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dispatch-item__line\"],[8],[0,\"\\n    \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"is-active\"],[10,\"href\",\"#\"],[10,\"aria-current\",\"page\"],[8],[0,\"\\n        \"],[1,[23,2,[\"_data\",\"waybill\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n        \"],[1,[23,2,[\"latestWaybillStatusMessage\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[5,\"helpers/info-tooltip-content\",[],[[\"@isViewable\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"transporters/waybill-display/list\",[],[[\"@model\",\"@tab\",\"@isPickingList\"],[[23,2,[]],[23,3,[]],[23,0,[\"isPickingList\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/waybill-display/template.hbs"
    }
  });
});