define("client/pods/components/emails/status-tags/status-tag/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    statusClass: Ember.computed('data', function () {
      const event = this.get('data.last.event');
      if (event === 'delivered') {
        return 'is-info';
      }
      if (event === 'opened') {
        return 'is-success';
      }
      return 'is-warning';
    })
  });
});