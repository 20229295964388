define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/free-shipping-rules-editor/rule-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopShippingZonesShippingZonesEditorDetailsEditorFreeShippingRulesEditorRuleEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed('args.{model._data.productTags.[],model._data.denyProductTags.[],rule._data.productTags.[]}'), _dec4 = Ember._action, _dec5 = Ember._action, _class = class ChannelsShopShippingZonesShippingZonesEditorDetailsEditorFreeShippingRulesEditorRuleEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "allowedFreeShippingProductTags", _descriptor2, this);
      this.crud.addLists(this, ['products/lists/tags']);
    }
    get matchRule() {
      const freeShippingRule = this.args?.freeShippingRule;
      return freeShippingRule?._data?.matchRule || 'any';
    }
    get availableProductTags() {
      return this.args?.productTags || [];
    }
    get hasTagsOutsideOfZone() {
      const ruleProductTags = this.args?.rule?._data?.productTags;
      if (RA.isNilOrEmpty(ruleProductTags)) {
        return false;
      }
      const model = this.args?.model;
      const allowedProductTags = model?._data?.productTags || [];
      const deniedProductTags = model?._data?.denyProductTags || [];
      if (RA.isNotNilOrEmpty(allowedProductTags)) {
        return !R.equals(allowedProductTags, ruleProductTags);
      }
      if (RA.isNotNilOrEmpty(deniedProductTags)) {
        return R.pipe(R.filter(tagKey => {
          return R.includes(tagKey, ruleProductTags);
        }), RA.isNotNilOrEmpty)(deniedProductTags);
      }
      return false;
    }
    removeRule(freeShippingRule, rule) {
      const rules = freeShippingRule?._data?.rules || [];
      rules.removeObject(rule);
    }
    getAllowedFreeShippingProductTags() {
      const freeShippingRule = this.args?.freeShippingRule;
      const matchRule = freeShippingRule?._data?.matchRule || 'any';
      const allowedProductTags = this.crud.lists['products-lists-tags'] || [];
      const rule = this.args?.rule;
      const currentRuleTags = rule?._data?.productTags || [];
      let allowedFreeShippingProductTags = allowedProductTags || [];
      if (matchRule === 'only') {
        const freeShippingRule = this.args?.freeShippingRule;
        let usedProductTags = R.pipe(R.pathOr([], ['_data', 'rules']), R.map(rule => {
          return rule?._data?.productTags || [];
        }), R.flatten, R.uniq, R.reject(RA.isNilOrEmpty))(freeShippingRule);
        usedProductTags = R.difference(usedProductTags, currentRuleTags);
        allowedFreeShippingProductTags = R.reject(productTag => {
          const value = productTag?._data?._key;
          return R.includes(value, usedProductTags);
        })(allowedFreeShippingProductTags);
      }
      Ember.set(this, 'allowedFreeShippingProductTags', allowedFreeShippingProductTags);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allowedFreeShippingProductTags", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasTagsOutsideOfZone", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasTagsOutsideOfZone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAllowedFreeShippingProductTags", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getAllowedFreeShippingProductTags"), _class.prototype), _class);
});