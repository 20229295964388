define("client/pods/payments/virtual-accounts/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      data.doc = this._transformDoc(data.doc);
      this._super(data, attrs);
    },
    _transformDoc(data) {
      return this.dataManager.setPartialRecord({
        adapterName: 'documents/sales/invoices',
        data
      });

      // not sure what else gets returned with documents so return a partial generic model that is not cached..
      // return this.dataManager.setAsRecord({
      //   adapterName: 'partial',
      //   data
      // })
    }
  });
});