define("client/pods/components/products/table-view/detail-row/component", ["exports", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    productsSerivce: Ember.inject.service('products'),
    shortcuts: Ember.inject.service(),
    tagName: '',
    showUploadsRow: false,
    hasShortcuts: true,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['statuses', 'products/lists/tags']);
      this.set('shortcutsMap', [{
        keys: [['Meta', 'Enter'], ['Control', 'Enter']],
        handler: this.quickSaveTask
      }, {
        keys: ['Alt', 'Enter'],
        handler: this.showWizardModal
      }]);
    },
    isSelected: Ember.computed('tableViewOptions.selectedItems.[]', function () {
      const selectedItems = this?.tableViewOptions?.selectedItems || [];
      if (selectedItems.indexOf(this.model) > -1) {
        return true;
      }
      return false;
    }),
    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isEditing', 'isNew', 'isSingleOrBulkEditing', function () {
      if (this.isEditing || this.isNew || this.isSingleOrBulkEditing) {
        return false;
      }
      return true;
    }),
    isFiltersReadonly: Ember.computed('isEditing', 'isNew', function () {
      if (this.isEditing || this.isNew) {
        return true;
      }
      return false;
    }),
    isProductSetsReadonly: Ember.computed('isNew', 'isEditing', 'isReadonly', function () {
      if (this.isNew) {
        return false;
      }
      let isProductSetsReadonly = false;

      // option cannot be edited after saving
      if (this.isEditing) {
        isProductSetsReadonly = true;
      }
      if (!isProductSetsReadonly) {
        isProductSetsReadonly = this.isReadonly;
      }
      return isProductSetsReadonly;
    }),
    quickSaveTask: (0, _emberConcurrency.task)(function* () {
      return yield this.crud.createRecordTask.perform({
        adapterName: 'products',
        model: this.model,
        onAfter: this.tableViewOptions?.onAfterQuickAddNew,
        component: this
      });
    }),
    updateBulkEditingTask: (0, _emberConcurrency.task)(function* ({
      appendPath = '',
      model
    }) {
      if (RA.isNilOrEmpty(appendPath)) {
        appendPath = '';
      }
      if (this.tableViewOptions?.isBulkEditing) {
        yield this.updateTask.perform({
          appendPath,
          model
        });
      }
    }),
    updateTask: (0, _emberConcurrency.task)(function* ({
      appendPath,
      model
    }) {
      model = model || this.model;
      let dirty = model;
      if (!model.isDirty) {
        dirty = this.crud.setupDirty({
          adapterName: 'products',
          model
        });
      }
      const product = model.product;
      const updated = yield this.crud.replaceRecordTask.perform({
        adapterName: 'products',
        appendPath,
        model: dirty,
        component: this
      });
      if (updated && !this.tableViewOptions?.isBulkEditing) {
        this.set('model', updated);
        this.set('original', false);
        this.set('isSingleEditing', false);
      }
      if (updated) {
        this.set('errors', false);
      }
    }),
    showWizardModal(currentStep = 0) {
      const model = this.model;
      this.set('currentStep', 0);
      const setType = model.getData('setType');
      if (!setType) {
        return this.showStepTemplate();
      }

      // if (this.currentStep === 0) {
      return this.showStepSet();
      // }

      // return this.showStepTemplate()
    },
    showStepSet() {
      this.set('currentStep', 0);
      const model = this.model;
      const setType = model.getData('setType');
      const panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const panel = R.find(R.pathEq(['_data', 'value'], setType))(panels);
      if (panel) {
        this.tabsManager.displaySubPanel(this, panel);
        this.set('isSetTypeModalVisible', true);
      }
    },
    showStepTemplate() {
      this.set('currentStep', 1);
      const panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const panel = R.find(R.pathEq(['_data', 'value'], 'webpages'))(panels);
      if (panel) {
        this.tabsManager.displaySubPanel(this, panel);
        this.set('isSetTypeModalVisible', true);
      }
    },
    actions: {
      clickRow() {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },
      /**
       * sales tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      salesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const purchasesTaxKey = model.getData('purchasesTaxRateKey');

        // manually set tax rate
        model.setData('salesTaxRate', taxRate);
        if (purchasesTaxKey == null || purchasesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('purchasesTaxRateLabel', taxModel.getData('label'));
          model.setData('purchasesTaxRateKey', taxModel.getData('_key'));
          // model.setData('purchasesTaxRate', taxRate);
        }
      },
      /**
       * purchases tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      purchasesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const salesTaxKey = model.getData('salesTaxRateKey');

        // manually set tax rate
        model.setData('purchasesTaxRate', taxRate);
        if (salesTaxKey == null || salesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('salesTaxRateLabel', taxModel.getData('label'));
          model.setData('salesTaxRateKey', taxModel.getData('_key'));
          // model.setData('salesTaxRate', taxRate);
        }
      },
      setSetType(setType) {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
        const selectedSetType = setType.getData('value');
        if (selectedSetType) {
          model.setData(selectedSetType, true);
        }
        // this.setEditorSubPanels()
      },
      deselectSetType() {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
      },
      updateVariantChildrenList({
        originalModel
      }) {
        const model = this.model;
        return model.updateVariantChildrenList({
          originalModel,
          setInitialValue: this.isEditing
        });
      },
      showWizardModal() {
        this.showWizardModal();
      },
      closeWizardModal() {
        this.set('isSetTypeModalVisible', false);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "showWizardModal", [_dec], Object.getOwnPropertyDescriptor(_obj, "showWizardModal"), _obj), _applyDecoratedDescriptor(_obj, "showStepSet", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showStepSet"), _obj), _applyDecoratedDescriptor(_obj, "showStepTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showStepTemplate"), _obj), _obj));
});