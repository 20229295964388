define("client/pods/components/messages/tab-container/messages-window/message-bubble/sticker-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/uzwc/f1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[11,\"src\",[22,\"messageUrl\"]],[10,\"class\",\"message-image\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/messages-window/message-bubble/sticker-type/template.hbs"
    }
  });
});