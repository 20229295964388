define("client/pods/contacts/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactsService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class ContactsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "search", _descriptor, this);
      _initializerDefineProperty(this, "dateService", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "constants", _descriptor4, this);
      _initializerDefineProperty(this, "all", _descriptor5, this);
      _initializerDefineProperty(this, "customer", _descriptor6, this);
      _initializerDefineProperty(this, "supplier", _descriptor7, this);
      _initializerDefineProperty(this, "dataGroupsFilters", _descriptor8, this);
    }
    // @restartableTask
    // * findTask (contactKey) {
    //   // const filters = this.setupFilters()
    //   const results = yield this.crud.findTask.perform(
    //     {
    //       adapterName: 'contacts',
    //       appendPath: `/${contactKey}`
    //     }
    //   )
    //   return results
    // }

    *searchContactsByGroupsTask({
      filters,
      relationship = 'all',
      query = '',
      group,
      groupKey,
      hasEmailOnly,
      resultsProperty = '',
      component,
      resultsToggle = false,
      global = true,
      match = [],
      onAfter
    }) {
      // if (RA.isNilOrEmpty(filters)) {
      //   filters = this.dataGroupsFilters
      // }

      filters.setData('group', group);
      filters.setData('groupKey', groupKey);
      filters.setData('hasEmailOnly', hasEmailOnly);
      const results = yield this.crud.searchInputTask.perform({
        adapterName: 'contacts/data/groups',
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle,
        allowEmptyQuery: true
      });
      if (global && resultsProperty) {
        this[resultsProperty] = results;
      }
      if (onAfter) {
        return onAfter(results);
      }
      return results;
    }
    setupFilters(defaultFiltersData = {}) {
      defaultFiltersData = RA.isNotNilOrEmpty(defaultFiltersData) ? defaultFiltersData : {
        sort: [{
          by: 'name',
          direction: 'ASC'
        }],
        status: [this.constants.status.active]
      };
      return this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData
      });
    }
    *searchInputTask({
      relationship = 'all',
      query = '',
      filters,
      resultsProperty = '',
      component,
      resultsToggle = false,
      global = true,
      match = []
    }) {
      const relationships = [];
      if (relationship === 'all') {
        relationships.push('customer');
        relationships.push('supplier');
      } else {
        relationships.push(relationship);
      }
      if (RA.isNilOrEmpty(filters)) {
        filters = this.setupFilters();
      }
      filters.setData('relationships', relationships);
      filters.setData('query', query);
      filters.setData('match', match);
      const results = yield this.crud.searchInputTask.perform({
        adapterName: 'contacts',
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle,
        allowEmptyQuery: true
      });
      // if (global && resultsProperty) {
      //   this[resultsProperty] = results
      // }

      // if (component) {
      //   component.set('contactsFilters', filters)
      // }

      return results;
    }
    loadContactProps({
      props,
      model
    }, selected) {
      R.mapObjIndexed((toProp, fromProp) => {
        const fromValue = selected.getData(fromProp);
        model.set(toProp, fromValue);
      })(props);
    }
    unloadContactProps({
      props,
      model
    }, selected) {
      R.mapObjIndexed((toProp, fromProp) => {
        model.set(toProp, '');
      })(props);
    }
    *loadContactTask(filters) {
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts',
        filters: filters
      });
      return results;
    }
    get birthdayPeriodYears() {
      const pastYears = this.dateService.getPastYears({
        number: 130
      });
      const data = [];
      pastYears.forEach(period => {
        const item = {
          value: period.yyyy,
          label: period.yyyy
        };
        data.pushObject(item);
      });
      return data;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "all", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "supplier", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dataGroupsFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchContactsByGroupsTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchContactsByGroupsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupFilters", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setupFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadContactProps", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "loadContactProps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadContactProps", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "unloadContactProps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadContactTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadContactTask"), _class.prototype), _class);
});