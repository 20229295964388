define("client/pods/extensions/apps/tw/e-invoices/tracks/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        mm: '',
        yyyy: '',
        prefix: '',
        query: '',
        match: ['all'],
        statuses: [],
        sort: [{
          by: 'index',
          direction: 'ASC'
        }]
      };
    }
  });
});