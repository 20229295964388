define("client/pods/components/settings/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F8ddwnFT",
    "block": "{\"symbols\":[\"@documentationResource\",\"@isWizard\",\"@tab\",\"@onEdit\",\"@wizardData\"],\"statements\":[[5,\"settings/settings-editor\",[],[[\"@documentationResource\",\"@isEditing\",\"@isWizard\",\"@model\",\"@onCancel\",\"@onEdit\",\"@onSaveTask\",\"@wizardData\",\"@tab\"],[[23,1,[]],true,[23,2,[]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"crud\",\"cancelEditing\"]],[28,\"hash\",null,[[\"adapterName\",\"dirty\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"displaySubPanel\"]]],null],[28,\"hash\",null,[[\"tab\",\"tabOptions\"],[[23,3,[]],[24,[\"tabOptions\"]]]]],[23,3,[]]]]]],null],[23,4,[]],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"displaySubPanel\"]]],null],[28,\"hash\",null,[[\"tab\",\"tabOptions\"],[[23,3,[]],[24,[\"tabOptions\"]]]]],[23,3,[]]]]]],null],[23,5,[]],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/panels-edit/template.hbs"
    }
  });
});