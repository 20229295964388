define("client/pods/components/websites/panels-display/sub-panels-pages/pages-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IjYmiAol",
    "block": "{\"symbols\":[\"@website\",\"@tab\",\"@pagesCollection\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,1,[\"_data\",\"isSimpleSite\"]],[23,0,[\"dirty\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"simple-editor\",[],[[\"@website\",\"@page\",\"@tab\",\"@pagesCollection\",\"@onSaveTask\"],[[23,1,[]],[23,0,[\"dirty\"]],[23,2,[]],[23,3,[]],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\"],[\"websites/pages\",[23,0,[\"dirty\"]]]]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"websites/websites-editor/pages-editor\",null,[[\"tab\",\"isEditing\",\"isNew\",\"model\",\"website\",\"pagesCollection\",\"onEdit\",\"toggleShowWebsitePagesNav\",\"onNew\",\"onAfterRemove\",\"onRemove\"],[[24,[\"tab\"]],false,false,[24,[\"model\"]],[24,[\"website\"]],[24,[\"pagesCollection\"]],[24,[\"onEdit\"]],[24,[\"toggleShowWebsitePagesNav\"]],[24,[\"onNew\"]],[24,[\"onAfterRemove\"]],[28,\"perform\",[[23,0,[\"crud\",\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\"],[\"websites/pages\",[24,[\"model\"]],[24,[\"onAfterRemove\"]]]]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-pages/pages-display/template.hbs"
    }
  });
});