define("client/pods/components/channels/shop/campaigns/campaigns-editor/component", ["exports", "client/config/environment", "client/mixins/transporter", "ramda", "ramda-extension", "ramda-adjunct", "client/utils/nventor"], function (_exports, _environment, _transporter, R, R_, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_transporter.default, {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    translate: true,
    initialPanel: null,
    headerBottom: null,
    // Only used for guide menu nav

    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['users', 'statuses', 'availability', 'toggles/auto', 'toggles/onOff', 'paymentMethods/shop', 'channels/shop/campaigns/statuses', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/subPanels', 'channels/shop/campaigns/lists/editorSubPanels', 'channels/shop/campaigns/lists/noOfLines', 'channels/shop/campaigns/lists/statuses', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'extensions/apps/tw/invoicingAuto', 'channels/shop/campaigns/lists/locations', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'channels/shop/campaigns/lists/productsNameFontSize', 'products/lists/onSaleTagDisplayPosition', 'products/lists/onSaleTagDisplayStyle', 'channels/shop/campaigns/lists/productsNameLineCount', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'shipping/feeType', 'commissions/methods']);
      this.set('productsList', []);
      this.set('config', _environment.default);
    },
    editingPanels: Ember.computed('listsIsLoaded', 'initialPanel._data.component', 'crud.lists.channels-shop-campaigns-lists-editorSubPanels.[]', function () {
      if (this.isDisplay) {
        return [];
      }
      const panels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-editorSubPanels')(this) || [];
      if (this.initialPanel?._data?.component === 'websites/websites-editor/pages-editor') {
        return R.filter(panel => {
          return panel._data?.component === 'websites/websites-editor/pages-editor';
        })(panels);
      }
      return panels;
    }),
    resourceList: Ember.computed('listsIsLoaded', 'isNew', 'isEditing', 'crud.lists.channels-shop-campaigns-lists-subPanels.[]', 'editingPanels.[]', function () {
      if (!this.isNew) {
        return this.crud?.lists?.['channels-shop-campaigns-lists-subPanels'] || [];
      }
      if (!this.isEditing) {
        return this.crud?.lists?.['channels-shop-campaigns-lists-subPanels'] || [];
      }
      return this.crud?.lists?.['channels-shop-campaigns-lists-editorSubPanels'] || [];
    }),
    isReadonly: Ember.computed('isEditing', 'saveTask.isRunning', function () {
      if (this.get('saveTask.isRunning')) {
        return true;
      }
      if (this.isEditing === true) {
        return false;
      }
      return true;
    }),
    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');
      if (Ember.isEmpty(salesPersons)) {
        return false;
      }
      return salesPersons;
    }),
    actions: {
      assignSalesPerson(person) {
        const model = this.model;
        model.addSalesPerson(person);

        // set commission default to
        model.setData('commissionMethod', this.get('constants.commissionMethod.percentagePrice'));
      },
      unassignSalesPerson(person) {
        const model = this.model;
        model.removeSalesPerson(person);
      },
      toggleCommissionMethod() {
        const model = this.model;
        model.setData('commissionFlat', '');
        model.setData('commissionPercentage', '');
      }
    }
  });
});