define("client/pods/workflows/lists/items/statuses/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "client/constants/index"], function (_exports, _adapter, _adaptersNew, _model, _nventor, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: _index.default.workflowsItemsStatuses.open,
        label: 'workflows_items_status_open'
      }, {
        value: _index.default.workflowsItemsStatuses.successful,
        label: 'workflows_items_status_successful'
      }, {
        value: _index.default.workflowsItemsStatuses.unsuccessful,
        label: 'workflows_items_status_unsuccessful'
      }];
      return _nventor.default.resolve(data);
    }
  });
});