define("client/pods/components/transporters/sender-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pCxQA42J",
    "block": "{\"symbols\":[\"detailModel\",\"@model\",\"@formInitData\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@required\",\"@isRequired\",\"@errors\",\"@context\",\"@formInitData\"],[\"transporter pickup location name\",true,true,[23,0,[\"errors\"]],\"label\",[23,3,[]]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[23,2,[\"transporterPersonModel\"]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@value\",\"@isRequired\"],[[28,\"tt\",[\"required\"],null],[23,2,[\"transporterPersonModel\",\"_data\",\"label\"]],true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[\"transporterPersonModel\"]]],null,{\"statements\":[[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@required\",\"@isRequired\",\"@errors\",\"@context\"],[\"sender name\",true,true,[23,0,[\"errors\"]],\"name\"]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@isRequired\",\"@value\"],[[28,\"tt\",[\"required\"],null],true,[23,2,[\"transporterPersonModel\",\"_data\",\"name\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[\"transporterEditorExtensionDynamicComponent\",\"shouldLoop\"]]],null,{\"statements\":[[4,\"each\",[[23,2,[\"transporterPersonModel\",\"_data\",\"details\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[23,2,[\"transporterEditorExtensionDynamicComponent\",\"component\"]]],[[\"model\",\"transporterModel\"],[[23,1,[]],[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,2,[\"transporterEditorExtensionDynamicComponent\",\"component\"]]],[[\"model\",\"transporterModel\"],[[23,2,[\"transporterPersonModel\"]],[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/sender-details/template.hbs"
    }
  });
});