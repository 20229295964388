define("client/pods/components/extensions/apps/tw/invoices/invoices-display/component", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices-cancel"], function (_exports, _invoicesCancel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_invoicesCancel.default, {
    tagName: '',
    isEditing: false
  });
});