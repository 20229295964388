define("client/pods/components/channels/shop/campaigns/price-filter/component", ["exports", "ramda-adjunct", "@glimmer/component"], function (_exports, RA, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsPriceFilterComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _class = class ChannelsShopCampaignsPriceFilterComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dataManager", _descriptor, this);
      this.updateMinRanges();
    }
    newRange(rangeData = {}, max = null) {
      let min = rangeData.min;
      if (max) {
        min = parseInt(max) + 1;
      }
      const rangeModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/price-filter',
        data: {
          min
        }
      });
      this.addRange(rangeModel);
    }
    addRange(range) {
      const model = this.args.model;
      const shopPriceFilters = model._data.shopPriceFilters || [];
      shopPriceFilters.pushObject(range);
    }
    newBlankRange(range) {
      this.updateMinRanges();
      const isLast = this.isLastRangeIndex(range);
      if (!isLast) {
        return;
      }
      const max = parseInt(range._data.max) || 0;
      if (max !== 0) {
        this.newRange(range, max);
      }
    }
    isLastRangeIndex(range) {
      const model = this.args.model;
      const shopPriceFilters = model._data?.shopPriceFilters || [];
      const rangeIndex = shopPriceFilters.indexOf(range);
      if (rangeIndex === shopPriceFilters.length - 1) {
        return true;
      }
      return false;
    }
    removeRange(range) {
      const isLast = this.isLastRangeIndex(range);
      const model = this.args.model;
      const shopPriceFilters = model._data?.shopPriceFilters || [];
      shopPriceFilters.removeObject(range);
      if (isLast) {
        this.newRange();
      } else {
        model.setData('shopPriceFilters', shopPriceFilters);
      }
      this.updateMinRanges();
    }
    updateMinRanges() {
      const model = this.args.model || {};
      const shopPriceFilters = model._data?.shopPriceFilters || [];
      if (shopPriceFilters.length === 0) {
        this.newRange({
          min: 0
        });
      }
      let previousRange;
      const newRanges = RA.mapIndexed((range, index) => {
        let previousMax = 0;
        if (previousRange) {
          previousMax = previousRange._data.max;
        }
        let currentMin = previousMax;
        const initialMin = range._data?.min || 0;
        if (index === 0 && RA.isNotNilOrEmpty(initialMin)) {
          currentMin = initialMin;
        }
        if (index > 0) {
          currentMin = previousMax + 1;
        }
        Ember.set(range, '_data.min', currentMin);
        let currentMax = parseFloat(range._data?.max) || 0;
        if (currentMax > 0) {
          if (currentMax < currentMin) {
            currentMax = 0;
          }
          Ember.set(range, '_data.max', currentMax);
        }
        previousRange = range;
        return range;
      })(shopPriceFilters);
      Ember.set(model, '_data.shopPriceFilters', newRanges);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newBlankRange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "newBlankRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMinRanges", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateMinRanges"), _class.prototype), _class);
});