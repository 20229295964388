define("client/pods/components/contacts/details/editor-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    personId: Ember.computed('parent._data.personId', function () {
      return this.get('parent._data.personId');
    }),
    departmentId: Ember.computed('parent._data.departmentId', function () {
      return this.get('parent._data.departmentId');
    }),
    actions: {
      checkForDefaults() {
        const parent = this.parent;
        const detail = this.detail;
        parent.setIfNotHasDefault(detail);
      }
    }
  });
});