define("client/pods/components/status-schedules/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StatusSchedulesComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _class = class StatusSchedulesComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isDateOnly", _descriptor, this);
      _initializerDefineProperty(this, "isSaving", _descriptor2, this);
    }
    willDestroy() {
      if (this.isDestroyed) {
        return;
      }
      if (this?.isSaving) {
        return;
      }
      const model = this.args.model;
      const originalModel = model.originalModel;
      if (RA.isNotNilOrEmpty(originalModel)) {
        const scheduleProp = this.args?.type;
        const hasScheduleProp = R.pipe(R.concat('has'), R.adjust(3, R.toUpper), R.join(''))(scheduleProp);
        const originalHasSchedules = R.pathOr(false, ['_data', hasScheduleProp])(originalModel);
        const originalSchedules = R.pathOr([], ['_data', scheduleProp])(originalModel);
        model.setData(hasScheduleProp, originalHasSchedules);
        model.setData(scheduleProp, originalSchedules);
      }
    }
    setupInitSchedule() {
      const hasSchedules = this.args?.hasSchedules || false;
      const schedules = this.args?.schedules || [];
      const model = this.args?.model || {};
      if (hasSchedules && RA.isNilOrEmpty(schedules)) {
        model.addSchedule({
          schedulesProp: this.args?.type
        });
      }
    }
    onSubmitAddNewSchedule() {
      const model = this.args?.model;
      model.addSchedule({
        schedulesProp: this.args?.type
      });
    }
    saveSchedules() {
      this.isSaving = true;
      if (this.args.onAfterSave) {
        this.args.onAfterSave();
      }
      this.args.onCloseDialogAction();
    }
    onToggleSetSchedule() {
      this.setupInitSchedule();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isDateOnly", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args?.isDateOnly || false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSaving", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddNewSchedule", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddNewSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSchedules", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveSchedules"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSetSchedule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetSchedule"), _class.prototype), _class);
});