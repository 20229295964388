define("client/pods/components/layouts/tabs/grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3zv+sPTQ",
    "block": "{\"symbols\":[\"@parentComponent\",\"@tab\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n  \"],[14,3],[0,\"\\n\\n  \"],[5,\"layouts/sidebar\",[],[[\"@isRight\"],[true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"sidebarRight\",\"component\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"layouts__close-btn-container\"],[8],[0,\"\\n        \"],[5,\"elements/element-pushbtn\",[],[[\"@classNames\",\"@tabindex\",\"@onSubmit\"],[\"delete\",-1,[28,\"fn\",[[23,0,[\"applicationService\",\"closeSidebarRight\"]],[23,1,[]]],null]]]],[0,\"\\n      \"],[9],[0,\"\\n\\t\\t\\t\"],[5,\"layouts/sidebar/content\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"sidebarRight\",\"component\"]]],[[\"tab\",\"sidebarOptions\"],[[23,2,[]],[23,1,[\"sidebarRight\"]]]]],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/tabs/grid/template.hbs"
    }
  });
});