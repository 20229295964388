define("client/pods/components/helpers/lazy-loader/component", ["exports", "ember-in-viewport", "ember-concurrency"], function (_exports, _emberInViewport, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember/object'
  var _default = _exports.default = Ember.Component.extend(_emberInViewport.default, {
    tagName: 'div',
    isLoading: false,
    showWhenLoading: true,
    /**
     * TODO: IMPLEMENT FROM messages-window/component
     * Calculate weather or not has more results. When resultsArray is smaller than the filterCount there is not more
     * results. Otherwise there is more to fetch.
     * @param {Array} resultsArray - Is an array of fetch data
     * @param {Number} filterCount -  Is the count on the adapter filter
     * @example
     * const resultsArray = [obj1,obj2,obj3], filterCount = 3
     * hasMoreResults = resultsArray === 3  - TRUE
     * */
    // hasMoreResults: computed('resultsArray.[]', 'filterCount', function () {
    //   const count = parseInt(this.get('filterCount')) || 0
    //   const results = this.get('resultsArray') || []
    //
    //   if (count === 0 && results.length === 0) {
    //     return false
    //   } else if (results.length < count) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }),

    autoLazyLoadTask: (0, _emberConcurrency.task)(function* () {
      const isLoading = this.isLoading;
      if (!isLoading) {
        this.set('isLoading', true);
        yield (0, _emberConcurrency.timeout)(500);
        yield this.onLazyLoadTask();
        yield (0, _emberConcurrency.timeout)(1000);
        this.set('isLoading', false);
      }
    })
  });
});