define("client/pods/components/layouts/tabs/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UEPvUu4S",
    "block": "{\"symbols\":[\"&default\",\"@hasSpacer\"],\"statements\":[[4,\"if\",[[23,0,[\"isFloating\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"tab-grid__header \",[28,\"if\",[[23,0,[\"hasOverflowX\"]],\"has-overflow\",\"\"],null]]]],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"didInsert\"]]],null]]],[3,\"will-destroy\",[[28,\"fn\",[[23,0,[\"willDestroyElement\"]]],null]]],[8],[0,\"\\n    \"],[14,1,[[23,0,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"showSub\"]],[23,0,[\"hasOverflowX\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"modals/modal-container-new\",[[12,\"class\",\"overflow-menu\"]],[[\"@closeOnOverlayClick\",\"@onToggleModalDialog\",\"@parentElement\"],[true,[28,\"fn\",[[23,0,[\"toggleSub\"]]],null],[23,0,[\"element\"]]]],{\"statements\":[[0,\"\\n    \"],[14,1,[[23,0,[]],true,[28,\"fn\",[[23,0,[\"toggleSub\"]]],null]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tab-grid__header-spacer\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/tabs/header/template.hbs"
    }
  });
});