define("client/pods/extensions/apps/tw/e-invoices/tracks/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      data.currentNumbers = this._transformCurrentNumbers(data);
      data = this.transformTrackNumbers(data);
      this._super(data, attrs);
      return data;
    },
    _transformCurrentNumbers(data) {
      return R.pipe(R.propOr([], 'currentNumbers'), R.map(currentNumberData => {
        return this.dataManager.setAsRecord({
          adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/current-number',
          data: currentNumberData
        });
      }))(data);
    },
    transformTrackNumbers(data) {
      data.trackNumbers = R.pipe(R.propOr([], 'trackNumbers'), R.map(trackNumber => {
        trackNumber = this.transformAllocatedNumbers(trackNumber, data);
        return this.dataManager.setAsRecord({
          adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers',
          data: trackNumber
        });
      }))(data);
      return data;
    },
    transformAllocatedNumbers(data, {
      currentNumbers
    }) {
      data.allocatedNumbers = R.pipe(R.propOr([], 'allocatedNumbers'), R.map(allocatedNumberData => {
        const allocatedNumber = this.dataManager.setAsRecord({
          adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
          data: allocatedNumberData
        });
        allocatedNumber.set('currentNumbers', currentNumbers);
        return allocatedNumber;
      }))(data);
      return data;
    },
    // current: computed('_data.current', function () {
    //   return this._data.current
    // }),

    index: Ember.computed('_data.index', function () {
      return this._data.index;
    }),
    allocatedNumbersGroupedByEInvoicePrinterTerminals: Ember.computed('_data.trackNumbers.{[],synced}', function () {
      return R.pipe(R.pathOr([], ['_data', 'trackNumbers']), R.map(R.pathOr([], ['_data', 'allocatedNumbers'])), R.flatten, R.filter(R.path(['_data', 'userKey'])), R.reject(RA.isNilOrEmpty), R.groupBy(R.path(['_data', 'userKey'])))(this);
    }),
    allocatedNumberForAny: Ember.computed('_data.trackNumbers.{[],synced}', function () {
      return R.pipe(R.pathOr([], ['_data', 'trackNumbers']), R.map(R.pathOr([], ['_data', 'allocatedNumbers'])), R.flatten, R.reject(allocated => {
        if (allocated?._data?.userKey) {
          return true;
        }
        if (!allocated?._data?.isAllowed) {
          return true;
        }
        return false;
      }), R.reject(RA.isNilOrEmpty)
      // R.groupBy(() => {
      //   return 'tw_eInvoices_available_any'
      // })
      )(this);
    }),
    allocatedNumberForDisallowed: Ember.computed('_data.trackNumbers.{[],synced}', function () {
      return R.pipe(R.pathOr([], ['_data', 'trackNumbers']), R.map(R.pathOr([], ['_data', 'allocatedNumbers'])), R.flatten, R.filter(R.pathEq(['_data', 'isAllowed'], false))
      // R.reject(RA.isNilOrEmpty),
      // R.groupBy(() => {
      //   return 'tw_eInvoices_disallowed'
      // })
      )(this);
    }),
    getAvailableCountToIssue({
      twEInvoiceAllowPrintingFrom,
      userKey
    }) {
      const trackData = this?._data || {};
      if (twEInvoiceAllowPrintingFrom === this.constants.twEInvoicePrinter.allowPrintingFrom.anyNextAvailableNumbers) {
        return Number(trackData?.availableCountForAnyAvailable) || 0;
      }
      return Number(R.pathOr(0, ['availableCountPerUserKey', userKey])(trackData)) || 0;
    }
  });
});