define("client/pods/contacts/statements/model", ["exports", "client/pods/base/model", "ramda-adjunct", "ramda"], function (_exports, _model, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = _exports.default = _model.default.extend({
    dataManager: Ember.inject.service(),
    populate(data, attrs) {
      data.transactions = this._transformTransactions(data);
      this._super(data, attrs);
    },
    _transformTransactions(transactions) {
      return R.pipe(R.propOr([], 'transactions'), R.map(transaction => {
        const docType = transaction.docType || '';
        const docName = transaction.docName || '';
        if (RA.isNotNilOrEmpty(docName) && RA.isNotNilOrEmpty(docType)) {
          return this.dataManager.setAsRecord({
            adapterName: `documents/${docType}/${docName}`,
            data: transaction
          });
        } else {
          return this.dataManager.setAsRecord({
            adapterName: 'ledgers/journals/cashbook',
            data: transaction
          });
        }
      }))(transactions);
    }
  });
});