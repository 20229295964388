define("client/pods/components/recurring/schedules/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KdxomnO3",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"legend\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"legend\",true],[11,\"class\",[22,\"legendClassNames\"]],[8],[1,[28,\"tt\",[[24,[\"legend\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"list\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"lists/list-option\",null,[[\"translate\",\"rememberSelected\",\"optionValueKey\",\"optionLabelKey\",\"classNames\",\"option\",\"onToggle\"],[[24,[\"translate\"]],false,[24,[\"optionValueKey\"]],[24,[\"optionLabelKey\"]],[24,[\"itemClassNames\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleOption\"],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"_data\",\"description\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/recurring/schedules/search-results/template.hbs"
    }
  });
});