define("client/pods/components/dispatch/processes-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BsXnL0o2",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"unless\",[[23,0,[\"mustShow\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"translate\",\"classNames\",\"hasBadge\",\"hasWarning\",\"badge\",\"onSubmit\",\"isRunning\"],[[24,[\"dispatchProcess\",\"_data\",\"name\"]],[24,[\"dispatchProcess\",\"_data\",\"translate\"]],[24,[\"btnClassNames\"]],[24,[\"hasProcessCount\"]],[24,[\"dispatchProcess\",\"_data\",\"isDangling\"]],[24,[\"dispatchProcessCount\"]],[24,[\"onSubmit\"]],[24,[\"isRunning\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isEditable\"]]],null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"icon\",\"closeOnOverlayClick\"],[\"fas fa-pen fa-xs\",false]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"dispatch/processes-editor\",null,[[\"adapterName\",\"model\",\"hasProcessCount\",\"onCloseDialogAction\",\"isEditing\",\"onRefresh\"],[\"dispatch/lists/processes\",[24,[\"dispatchProcess\"]],[24,[\"hasProcessCount\"]],[23,1,[]],true,[24,[\"onRefresh\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/processes-item/template.hbs"
    }
  });
});