define("client/pods/products/inventory/adjustments/model", ["exports", "ramda", "client/pods/base/model"], function (_exports, R, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    loadProduct(product, historyData) {
      this.setData('item', product.getData('name'));
      this.setData('roundingInventoryQty', product.getData('roundingInventoryQty', this.settings.getProp('roundingInventoryQty')));
      this.setData('roundingInventoryPrice', product.getData('roundingInventoryPrice', this.settings.getProp('roundingInventoryPrice')));
      this.setData('unit', product.getData('unit'));
      this.setData('unitKey', product.getData('unitKey'));
      this.setPrice(historyData);
    },
    setPrice(historyData) {
      let price;
      price = historyData.exclPrice;
      if (!price) {
        price = R.propOr('', 'salesExclPrice', historyData);
      }
      this.setData('exclPrice', price);
    }
  });
});