define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "acCcIxcO",
    "block": "{\"symbols\":[\"@formInitData\",\"@isReadonly\",\"@automationModel\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"automationThen\",[23,1,[]],\"automation_then\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@classNames\",\"@disabled\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@readonly\",\"@translate\",\"@value\"],[\"table-form__input\",[28,\"or\",[[23,2,[]],[23,3,[\"hasDetails\"]]],null],true,\"_data.label\",\"_data.value\",[23,0,[\"crud\",\"lists\",\"automations-thenActions\"]],true,true,[23,3,[\"_data\",\"thenAction\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,3,[\"isAutoAdd\"]]],null,{\"statements\":[[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"automationSelectionType\",[23,1,[]],\"automation_selection_type\"]],{\"statements\":[[0,\"\\n    \"],[5,\"lists/list-dropdown\",[],[[\"@classNames\",\"@disabled\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@readonly\",\"@translate\",\"@value\",\"@onSelect\"],[\"table-form__input\",[23,2,[]],true,\"_data.label\",\"_data.value\",[23,0,[\"crud\",\"lists\",\"automations-thenSelectionTypes\"]],true,true,[23,3,[\"_data\",\"thenSelectionType\"]],[28,\"fn\",[[23,0,[\"resetThenSelectionQty\"]]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,3,[\"isChooseBetween\"]]],null,{\"statements\":[[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"automationNumberOfSelections\",[23,1,[]],\"number of selections\"]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-input\",[],[[\"@classNames\",\"@readonly\",\"@value\"],[\"table-form__input\",[23,2,[]],[23,3,[\"_data\",\"thenSelectionQty\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor/template.hbs"
    }
  });
});