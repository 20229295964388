define("client/pods/components/blogs/files/files-default-image/component", ["exports", "client/pods/components/blogs/files/mixins/blogs-files"], function (_exports, _blogsFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_blogsFiles.default, {
    fetchUploads: true
  });
});