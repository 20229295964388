define("client/pods/components/forms/form-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lwym93Wn",
    "block": "{\"symbols\":[\"@betaPath\",\"@allowedForFeatures\",\"@disallowedForFeatures\",\"&attrs\",\"&default\"],\"statements\":[[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\",\"@allowedForFeatures\",\"@disallowedForFeatures\"],[[23,1,[]],[23,2,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"form-container__column\"],[13,4],[8],[0,\"\\n    \"],[14,5],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-column/template.hbs"
    }
  });
});