define("client/pods/simple-editor/lists/page-blocks/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "client/constants/index", "ramda"], function (_exports, _adapter, _adaptersNew, _model, _nventor, _index, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: `pageBlocks_${_index.default.pageBlockTypes.buttons}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.buttons}`,
        type: _index.default.pageBlockTypes.buttons,
        isTemplate: true,
        group: 'pageBlocks_group_basic'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.text}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.text}`,
        type: _index.default.pageBlockTypes.text,
        isTemplate: true,
        group: 'pageBlocks_group_basic'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.bio}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.bio}`,
        type: _index.default.pageBlockTypes.bio,
        isTemplate: true,
        group: 'pageBlocks_group_basic'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.images}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.images}`,
        type: _index.default.pageBlockTypes.images,
        isTemplate: true,
        group: 'pageBlocks_group_basic'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.products}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.products}`,
        type: _index.default.pageBlockTypes.products,
        isTemplate: true,
        group: 'pageBlocks_group_money'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.tips}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.tips}`,
        type: _index.default.pageBlockTypes.tips,
        isTemplate: true,
        group: 'pageBlocks_group_money'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.mailingList}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.mailingList}`,
        type: _index.default.pageBlockTypes.mailingList,
        isTemplate: true,
        group: 'pageBlocks_group_audience'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.flow}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.flow}`,
        type: _index.default.pageBlockTypes.flow,
        isTemplate: true,
        group: 'pageBlocks_group_audience'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.socialLinks}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.sociallinks}`,
        type: _index.default.pageBlockTypes.sociallinks,
        isTemplate: true,
        group: 'pageBlocks_group_social'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.instagramPosts}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.instagramPosts}`,
        type: _index.default.pageBlockTypes.instagramPosts,
        isTemplate: true,
        group: 'pageBlocks_group_social'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.facebookPosts}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.facebookPosts}`,
        type: _index.default.pageBlockTypes.facebookPosts,
        isTemplate: true,
        group: 'pageBlocks_group_social'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.youtubeVideo}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.youtubeVideo}`,
        type: _index.default.pageBlockTypes.youtubeVideo,
        isTemplate: true,
        group: 'pageBlocks_group_social'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.tikTokPosts}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.tikTokPosts}`,
        type: _index.default.pageBlockTypes.tikTokPosts,
        isTemplate: true,
        group: 'pageBlocks_group_social'
      }, {
        label: `pageBlocks_${_index.default.pageBlockTypes.threadsPosts}`,
        component: `simple-editor/page-block/${_index.default.pageBlockTypes.threadsPosts}`,
        type: _index.default.pageBlockTypes.threadsPosts,
        isTemplate: true,
        group: 'pageBlocks_group_social'
      }];
      return _nventor.default.resolve(data);
    }
  });
});