define("client/pods/components/elements/element-dropdown-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: 'span',
    classNames: ['list-btn'],
    hasDropdown: true,
    showDropdown: false,
    onSubmit: 'defaultToggleDropdown',
    // can supply custom function to search on onSubmit action
    readonly: false,
    translate: false,
    value: '',
    // list: [],
    resultsComponent: 'lists/list-results',
    // component to display results (defaults to list-results)

    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list
     */
    isDropdownVisible: Ember.computed('showDropdown', function () {
      if (this.readonly === false && this.showDropdown) {
        return true;
      }
      return false;
    }),
    actions: {
      defaultToggleDropdown() {

        // @TODO: should remove this

        // this.set('showDropdown', true);
      },
      hideDropdown() {
        this.set('showDropdown', false);
      },
      showDropdown() {
        this.set('showDropdown', true);
      }
    },
    onToggleModalDialog() {
      this.set('showDropdown', false);
    }
  }, _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj), _obj));
});