define("client/mixins/search-contacts", ["exports", "ember-concurrency", "client/mixins/crud"], function (_exports, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_crud.default, {
    filtersAdapterName: 'contacts/filters',
    /**
     * Deprecated fn, should use contacts service instead
     * @deprecated
     */
    searchContactsTask: (0, _emberConcurrency.task)(function* ({
      relationship,
      query,
      resultsProperty,
      resultsToggle = false,
      disallowForFlatFees = false
    }) {
      if (query?.trim?.() !== '') {
        const relationships = [];
        if (relationship === 'all') {
          relationships.push('customer');
          relationships.push('supplier');
        } else {
          relationships.push(relationship);
        }
        const filters = this.search.setupFilters({
          adapterName: this.filtersAdapterName,
          defaultFiltersData: {
            relationships,
            query,
            match: ['name', 'code', 'taxNumber'],
            sort: [{
              by: 'name',
              direction: 'ASC'
            }],
            status: [this.get('constants.status.active')],
            disallowForFlatFees
          }
        });
        const adapterName = 'contacts';
        const results = yield this.searchInputTask.perform({
          adapterName,
          filters,
          query,
          resultsProperty,
          resultsToggle
        });
        return results;
      }
      return [];
    })
  });
});