define("client/pods/documents/mixins/contacts", ["exports", "client/pods/components/documents/docConfig", "ramda-adjunct", "ramda"], function (_exports, _docConfig, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    loadContact({
      docType,
      contact,
      paymentMethodsList,
      paymentTermsList
    }) {
      const key = contact.getData('_key');
      const salesPersons = contact.getData('salesPersons', []);
      const config = _docConfig.default.getFor({
        docType,
        docName: this._data.docName
      });
      this.set('selectedContact', contact);
      this.setData('contactKey', key);
      this.setData('salesPersons', salesPersons);
      this.setData('birthday', contact.getData('birthday'));
      const toLoadName = contact?._data?.name || '';
      if (toLoadName && toLoadName != this.getData('contact')) {
        this.setData('contact', toLoadName);
      }

      // When load contact and is pos, only override contact keys.
      const isPos = R.pathEq(['_data', 'isPos'], true)(this);
      if (isPos) {
        this.loadContactPerson();
        // When final do not override
        const isFinal = R.pathEq(['_data', 'status'], 'final')(this);
        if (isFinal) {
          return;
        }
      }
      if (config.hasTaxNumber) {
        this.setData('taxNumber', contact.getData('taxNumber'));
        this.setData('companyName', contact.getData('companyName'));
        this.setData('twEInvoiceCarrierId', contact.getData('twEInvoiceCarrierId'));
        this.setData('twEInvoiceCarrierType', contact.getData('twEInvoiceCarrierType'));
      }
      const settingsModel = this.settings.getModel();
      let autoPrintInvoice = contact.getData('autoPrintInvoices');
      if (autoPrintInvoice === this.constants.invoicing.auto || !autoPrintInvoice) {
        autoPrintInvoice = settingsModel.getData('autoPrintInvoice');
      }
      this.setData('autoPrintInvoices', autoPrintInvoice);
      this.setData('twEInvoicePrint', false); // @TODO: what does this do?
      this.setData('roundingCurrency', contact.getData('roundingCurrency', settingsModel.getData('roundingCurrency')));
      this.setData('unroundedCurrency', contact.getData('unroundedCurrency', settingsModel.getData('unroundedCurrency')));
      this.setData('roundingInventoryQty', contact.getData('roundingInventoryQty', settingsModel.getData('roundingInventoryQty')));
      this.setData('roundingInventoryPrice', contact.getData('roundingInventoryPrice', settingsModel.getData('roundingInventoryPrice')));
      let contactRelationship = this?._data?.contactRelationship || '';
      if (RA.isNilOrEmpty(contactRelationship)) {
        if (R.equals(docType, 'sales')) {
          contactRelationship = 'customer';
        }
        if (R.equals(docType, 'purchases')) {
          contactRelationship = 'supplier';
        }
      }
      let relevantDocType = docType;
      if (R.includes(this._data.docName, ['invoices', 'returns'])) {
        const contactRelationships = contact.getData('relationships') || [];
        if (R.equals(R.length(contactRelationships), 1)) {
          contactRelationship = R.head(contactRelationships);
        }
      }

      // loading contact payment method using whatever the relevant docType is
      relevantDocType = 'purchases';
      if (R.equals(contactRelationship, 'customer')) {
        relevantDocType = 'sales';
      }
      this._loadContactPaymentMethod({
        docType: relevantDocType,
        docName: this._data.docName,
        contact,
        paymentMethodsList,
        paymentTermsList
      });
      this.setData('contactRelationship', contactRelationship);

      // if taxtMethod is on auto, do not change taxMethod as it is already set by panel defaults
      const contactTaxMethodKey = contact.getData(`${docType}TaxMethodKey`);
      if (contactTaxMethodKey !== 'auto' && contactTaxMethodKey !== '' && contactTaxMethodKey != null) {
        // not auto
        this.setData('taxMethodKey', contactTaxMethodKey);
      }
      this.loadContactPerson();
    },
    unloadContact() {
      const empty = '';
      const emptyArray = [];
      this.set('selectedContact', empty);
      this.setData('contactRelationship', empty);
      this.setData('contact', empty);
      this.setData('contactKey', empty);
      this.setData('birthday', empty);
      this.setData('salesPersons', emptyArray);
      this.setData('address', empty);
      this.setData('email', empty);
      this.setData('telephone', empty);
      this.setData('twEInvoicePrint', false);
    },
    _loadContactPaymentMethod({
      docType,
      docName,
      contact,
      paymentMethodsList,
      paymentTermsList
    }) {
      // load payment method when it's not a consignment doc
      // if it's consignment doc, check if the contact payment method is consignment too or not
      const salesPaymentMethodKey = contact.getData('salesPaymentMethodKey');
      const purchasesPaymentMethodKey = contact.getData('purchasesPaymentMethodKey');
      const isNotConsignmentsDoc = !R.equals(docName, 'consignments') && !R.equals(docName, 'consignments-returns');
      const contactsPaymentMethodIsConsignments = R.equals(docType, 'sales') && R.equals(salesPaymentMethodKey, 'consignment') || R.equals(docType, 'purchases') && R.equals(purchasesPaymentMethodKey, 'consignment');
      if (isNotConsignmentsDoc || contactsPaymentMethodIsConsignments) {
        const paymentMethodKey = contact.getData(`${docType}PaymentMethodKey`);
        const paymentMethodCustomLabel = contact.getData(`${docType}PaymentMethodCustomLabel`);
        if (paymentMethodKey !== 'auto' && paymentMethodKey !== '' && paymentMethodKey != null) {
          let paymentMethod = paymentMethodsList.findBy('_data.value', paymentMethodKey);
          if (RA.isNotNilOrEmpty(paymentMethodCustomLabel)) {
            paymentMethod = paymentMethodsList.findBy('_data.customLabel', paymentMethodCustomLabel);
          }
          this.loadPaymentMethod({
            paymentMethod,
            paymentMethodKey
          });
        }
        const paymentTermsKey = contact.getData(`${docType}PaymentTermsKey`);
        if (paymentTermsKey !== 'auto' && paymentTermsKey !== '' && paymentTermsKey != null) {
          const paymentTerms = paymentTermsList.findBy('_data._key', paymentTermsKey);
          this.setData('paymentTermsKey', paymentTermsKey);
          this.loadPaymentTerms({
            paymentTerms,
            paymentTermsKey
          });
        }
      }
    },
    // loadDefaultContactDetails () {
    //   const personOrDepartment = this.loadContactPerson()
    //   this.loadContactTelephone(personOrDepartment)
    // },

    loadContactPerson(personOrDepartment) {
      const selectedContact = this.selectedContact;
      if (selectedContact) {
        if (!personOrDepartment && selectedContact.getDefaultPersonOrDepartment) {
          personOrDepartment = selectedContact.getDefaultPersonOrDepartment(this.getData('docType'), this.getData('docName'));
        }
        let parent = selectedContact;
        if (personOrDepartment) {
          this.setData('person', personOrDepartment.getData('name'));
          parent = personOrDepartment;
        }
        this.loadDetail(selectedContact, parent, 'telephone', 'phone');
        this.loadDetail(selectedContact, parent, 'email', 'email');
        this.loadDetail(selectedContact, parent, 'address', 'address');
        return personOrDepartment;
      }
    },
    loadDetail(selectedContact, personOrDepartment, setProp, type) {
      const defaultDetail = personOrDepartment.getDefaultDetail({
        contact: selectedContact,
        type
      });
      let value = '';
      if (defaultDetail) {
        value = defaultDetail.getData('value');
      }
      if (value) {
        this.setData(setProp, value);
      }
      return defaultDetail;
    }
  });
});