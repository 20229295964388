define("client/pods/websites/lists/layout/btns-border-radius/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        label: this.intl.t('default'),
        value: ''
      }, {
        label: '0px',
        value: '0px'
      }, {
        label: '1px',
        value: '1px'
      }, {
        label: '2px',
        value: '2px'
      }, {
        label: '3px',
        value: '3px'
      }, {
        label: '4px',
        value: '4px'
      }, {
        label: '5px',
        value: '5px'
      }, {
        label: '6px',
        value: '6px'
      }, {
        label: '7px',
        value: '7px'
      }, {
        label: '8px',
        value: '8px'
      }, {
        label: '9px',
        value: '9px'
      }, {
        label: '10px',
        value: '10px'
      }, {
        label: '11px',
        value: '11px'
      }, {
        label: '12px',
        value: '12px'
      }, {
        label: '13px',
        value: '13px'
      }, {
        label: '14px',
        value: '14px'
      }, {
        label: '15px',
        value: '15px'
      }, {
        label: '16px',
        value: '16px'
      }, {
        label: '17px',
        value: '17px'
      }, {
        label: '18px',
        value: '18px'
      }, {
        label: '19px',
        value: '19px'
      }, {
        label: '20px',
        value: '20px'
      }];
      return _nventor.default.resolve(data);
    }
  });
});