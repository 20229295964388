define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRow = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _class = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRow extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "big", _descriptor3, this);
      _initializerDefineProperty(this, "campaignsProducts", _descriptor4, this);
      _initializerDefineProperty(this, "productList", _descriptor5, this);
      _initializerDefineProperty(this, "commissionBaseCostNew", _descriptor6, this);
      _initializerDefineProperty(this, "campaignsDetailsModel", _descriptor7, this);
    }
    getCommissionBaseCostNew() {
      const model = this.args.model;
      const commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew', model);
      this.commissionBaseCostNew = commissionBaseCostNew;
      this.campaignsDetailsModel = model;
    }
    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query?.trim?.() !== '') {
        const campaignKey = this.args.campaignKey;
        const filters = this.productsFilters;
        const results = yield this.crud.searchInputTask.perform({
          adapterName,
          appendPath: `/campaigns/${campaignKey}`,
          filters
        }, query);
        return results;
      }
    }
    *setCommissionBaseCostFromHistoryTask({
      model,
      campaignsDetailsModel,
      selectedCommissionBaseCostMethod,
      type,
      history,
      onHideDropdown
    }) {
      // selectedCommissionBaseCostMethod = selectedCommissionBaseCostMethod || this?.args?.tableViewOptions?.selectedCommissionBaseCostMethod || ''
      const isUsingAverage = this?.args?.tableViewOptions?.isUsingAverage;
      if (RA.isNotNilOrEmpty(selectedCommissionBaseCostMethod)) {
        const commissionBaseCosts = ['normalPercent', 'twPercent', 'byBaseCost', 'byAmount'];
        R.forEach(commissionBaseCost => {
          let commissionHistory = '';
          if (R.equals(selectedCommissionBaseCostMethod, 'normalPercent') && R.equals(commissionBaseCost, 'normalPercent')) {
            commissionHistory = history.getData(commissionBaseCost);
          }
          if (R.equals(selectedCommissionBaseCostMethod, 'twPercent') && R.equals(commissionBaseCost, 'twPercent')) {
            commissionHistory = history.getData(commissionBaseCost);
          }
          if (R.equals(selectedCommissionBaseCostMethod, 'byBaseCost') && R.equals(commissionBaseCost, 'byBaseCost')) {
            commissionHistory = history.getData(commissionBaseCost);
          }
          if (R.equals(selectedCommissionBaseCostMethod, 'byAmount') && R.equals(commissionBaseCost, 'byAmount')) {
            commissionHistory = history.getData(commissionBaseCost);
          }
          model.setData(commissionBaseCost, commissionHistory);
        })(commissionBaseCosts);
      }
      if (RA.isNotNilOrEmpty(type) && R.equals(type, 'adjustedShippingFee')) {
        const adjustmentShippingFeeHistory = history.getData('adjustedShippingFee') || '';
        model.setData('adjustedShippingFee', adjustmentShippingFeeHistory);
      }
      yield this.args.tableViewOptions.saveCommissionBaseCostNewModelTask.perform({
        model,
        campaignsDetailsModel,
        selectedCommissionBaseCostMethod,
        isUsingAverage
      });
      if (onHideDropdown) {
        onHideDropdown();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "productList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostNew", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "campaignsDetailsModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getCommissionBaseCostNew", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "getCommissionBaseCostNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCommissionBaseCostFromHistoryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setCommissionBaseCostFromHistoryTask"), _class.prototype), _class);
});