define("client/pods/components/blogs/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "E8I/PLYU",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"blogs/blogs-editor\",null,[[\"model\",\"errors\",\"saveTask\",\"isNew\",\"tab\",\"isDuplicate\",\"onCancel\"],[[24,[\"model\"]],[24,[\"errors\"]],[24,[\"createRecordTask\"]],true,[24,[\"tab\"]],[24,[\"isDuplicate\"]],[24,[\"onCancel\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/panels-new/template.hbs"
    }
  });
});