define("client/pods/products/inventory/model", ["exports", "client/pods/base/model", "client/mixins/big"], function (_exports, _model, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend(_big.default, {
    isLessThanEqualQty(cutOff, qty) {
      const qtyBig = this.newBig(qty || 0);
      if (qtyBig.lte(cutOff)) {
        return true;
      }
      return false;
    },
    onBookQtyIsEmpty: Ember.computed('onBookQty', function () {
      return this.isLessThanEqualQty(0, this.getData('onBookQty'));
    }),
    onHandQtyIsEmpty: Ember.computed('onHandQty', function () {
      return this.isLessThanEqualQty(0, this.getData('onHandQty'));
    }),
    availableQtyIsEmpty: Ember.computed('availableQty', function () {
      return this.isLessThanEqualQty(0, this.getData('availableQty'));
    })

    // totalSalesPendingAndUndispatchedQtyIsEmpty: computed('totalSalesPendingAndUndispatchedQty', function () {
    //   return this.isLessThanEqualQty(0, this.getData('totalSalesPendingAndUndispatchedQty'))
    // }),

    // totalSalesPendingAndUndispatchedQty: computed('_data.{salesInvoicesUndispatchedQty,salesConsignmentsUndispatchedQty,salesOrdersPendingQty,salesConsignmentsPendingQty}', function () {
    //   const salesInvoicesUndispatchedQty = this.get('_data.salesInvoicesUndispatchedQty')
    //   const salesConsignmentsUndispatchedQty = this.get('_data.salesConsignmentsUndispatchedQty')
    //   const salesOrdersPendingQty = this.get('_data.salesOrdersPendingQty')

    //   const roundingInventoryQty = this.getData('roundingInventoryQty', this.get('settings').getProp('roundingInventoryQty'))
    //   const total = this.autoRound(
    //     roundingInventoryQty,
    //     this.newBig(salesOrdersPendingQty)
    //       .plus(salesInvoicesUndispatchedQty)
    //       .plus(salesConsignmentsUndispatchedQty)
    //   )
    //   return total
    // })
  });
});