define("client/pods/components/comments/search-results-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wy1vm5Bk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-container-row u-results__text\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--bold\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"emailToName\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"emailToAddress\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\\t#\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"docNo\"]],[24,[\"query\"]]],null],false],[0,\" - \"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"ref\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"dateTime\",[[24,[\"result\",\"_data\",\"timestampZ_latest\"]]],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"u-margin-left--auto u-padding-left-5\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"result\",\"_data\",\"status\"]],\"resolved\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"tt\",[\"resolved\"],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"result\",\"_data\",\"readBadge\"]],0],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"u-badge\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[24,[\"result\",\"_data\",\"readBadge\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/comments/search-results-item/template.hbs"
    }
  });
});