define("client/helpers/boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params /* , hash */) {
      const value = params[0];
      if (value == null) {
        return false;
      }
      return value;
    }
  });
});