define("client/pods/components/channels/shop/rewards/panels-display/sub-panels-member-levels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Lbn0dgXD",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"channels/shop/rewards/rewards-editor/member-levels-editor\",[],[[\"@model\",\"@isReadonly\"],[[23,1,[]],true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/panels-display/sub-panels-member-levels/template.hbs"
    }
  });
});