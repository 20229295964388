define("client/pods/websites/lists/layout/menu-font-size/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: '8px',
        label: '8px'
      }, {
        value: '9px',
        label: '9px'
      }, {
        value: '10px',
        label: '10px'
      }, {
        value: '11px',
        label: '11px'
      }, {
        value: '12px',
        label: '12px'
      }, {
        value: '14px',
        label: '14px'
      }, {
        value: '18px',
        label: '18px'
      }, {
        value: '24px',
        label: '24px'
      }, {
        value: '30px',
        label: '30px'
      }, {
        value: '36px',
        label: '36px'
      }, {
        value: '48px',
        label: '48px'
      }, {
        value: '60px',
        label: '60px'
      }, {
        value: '72px',
        label: '72px'
      }, {
        value: '96px',
        label: '96px'
      }];
      return _nventor.default.resolve(data);
    }
  });
});