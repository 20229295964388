define("client/pods/components/contacts/statement-aging/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5s8ZQTXW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"u-table u-table-layout-fixed\"],[8],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"total\"],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"120+ days\"],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"90 days\"],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"60 days\"],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"30 days\"],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"current\"],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"number\",[[24,[\"statement\",\"_data\",\"total\"]]],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"number\",[[24,[\"statement\",\"_data\",\"aging\",\"4\",\"total\"]]],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"number\",[[24,[\"statement\",\"_data\",\"aging\",\"3\",\"total\"]]],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"number\",[[24,[\"statement\",\"_data\",\"aging\",\"2\",\"total\"]]],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"number\",[[24,[\"statement\",\"_data\",\"aging\",\"1\",\"total\"]]],null],false],[9],[0,\"\\n\\n\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"number\",[[24,[\"statement\",\"_data\",\"aging\",\"0\",\"total\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/statement-aging/template.hbs"
    }
  });
});