define("client/pods/components/dispatch/panels-display/display-doc-row/component", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    allowCheckbox: Ember.computed('model._data.{status,dispatchProcess}', 'dispatchProcess', function () {
      const model = this.model;
      const currentDispatchProcess = this.get('dispatchProcess._data.code');
      const dispatchProcess = model.getData('dispatchProcess') || 'unprepared';
      if (dispatchProcess !== currentDispatchProcess && currentDispatchProcess !== 'all') {
        return false;
      }
      if (model.getData('status') === this.get('constants.documentsStatus.final')) {
        return true;
      }
      return false;
    }),
    hasDuplicateWaybill: Ember.computed('model._data.waybill', 'duplicateWaybillsDocNos.@each.selected', function () {
      const modelValue = this.get('model._data.waybill');
      const duplicates = this.duplicateWaybillsDocNos || [];
      const found = R.find(R.propEq('value', modelValue), duplicates);
      if (found) {
        return true;
      }
      return false;
    }),
    hasDuplicateContact: Ember.computed('model._data.address', 'duplicateAddressesDocNos.[]', function () {
      const model = this.model;
      const modelValue = this.get('model._data.address');
      const duplicates = this.duplicateAddressesDocNos || [];
      const found = R.filter(R.allPass([R.propEq('value', modelValue), _nventor.default.allEqProps(['docType', 'docName'], model._data)]))(duplicates);
      if (!R.isEmpty(found)) {
        return true;
      }
      return false;
    })
  });
});