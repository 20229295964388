define("client/pods/components/app-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ksC198Bw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"auth\",\"isLoggedIn\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"app-logged-in\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/app-container/template.hbs"
    }
  });
});