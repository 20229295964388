define("client/pods/reports/commissions/data/all/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data = {}) {
      data = data || {};
      data = this.transformPayouts(data);

      // populate docName info
      this.overwriteData(data);
    },
    transformPayouts(rawData) {
      // const self = this
      rawData.commissionsDueData.payoutsThisMonth = R.pipe(R.pathOr([], ['commissionsDueData', 'payoutsThisMonth']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'ledgers/journals/cashbook',
          data
        });
      }))(rawData);
      return rawData;
    }
  });
});