define("client/pods/components/layouts/panels/panel-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xwHPjHhm",
    "block": "{\"symbols\":[\"&default\",\"@classNames\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid__footer-spacer\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",[29,[\"panel-grid__footer \",[23,2,[]],\" \",[28,\"if\",[[23,0,[\"hasOverflowX\"]],\"has-overflow\",\"\"],null]]]],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"didInsert\"]]],null]]],[3,\"will-destroy\",[[28,\"fn\",[[23,0,[\"willDestroyElement\"]]],null]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__footer-center\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"hasOverflowX\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[5,\"elements/element-btn\",[],[[\"@isToggler\",\"@hasToggleIconRight\",\"@toggleIsOn\"],[true,true,[23,0,[\"showSub\"]]]]],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"showSub\"]],[23,0,[\"hasOverflowX\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[5,\"modals/modal-container-new\",[[12,\"class\",\"overflow-menu\"]],[[\"@closeOnOverlayClick\",\"@onToggleModalDialog\",\"@parentElement\"],[true,[28,\"fn\",[[23,0,[\"toggleSub\"]]],null],[23,0,[\"element\"]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[14,1,[[23,0,[]],true,[28,\"fn\",[[23,0,[\"toggleSub\"]]],null]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/panels/panel-footer/template.hbs"
    }
  });
});