define("client/pods/products/inventory/lists/qty-types/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'onHandQty',
        label: 'on hand qty'
      }, {
        value: 'bookQty',
        label: 'book qty'
      }, {
        value: 'reOrderWhenQty',
        label: 're-order qty'
      }];
      return _nventor.default.resolve(data);
    }
  });
});