define("client/pods/components/extensions/apps/tw/e-invoices/tab-container/component", ["exports", "client/mixins/date", "client/mixins/big", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices", "client/mixins/crud", "client/mixins/e-invoices-list-by", "ramda", "ramda-extension"], function (_exports, _date, _big, _invoices, _crud, _eInvoicesListBy, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _eInvoicesListBy.default, _invoices.default, _big.default, _date.default, (_dec = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    settingsService: Ember.inject.service('settings'),
    model: null,
    errors: null,
    customDateZ: '',
    adapterName: 'extensions/apps/tw/e-invoices',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    hasManagementBtns: true,
    selectedProvider: 'bigOrdr',
    selectedTableView: 'invoice',
    init() {
      this.crud.addLists(this, ['dispatch/lists/processes', 'periods/months', 'periods/years', 'periods/date-options', 'extensions/apps/tw/e-invoices/lists/providers', 'printer/lists/allow-printing-from', 'extensions/apps/tw/view']);
      this._super(...arguments);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.set('showResults', true);
      this.set('filters', filters);
      const pastDays = this.dateService.getPastDays(14);
      this.set('pastDays', pastDays);
      const pastMonths = this.dateService.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const defaultEInvoiceProvider = this.settingsService.getDefaultEInvoiceProvider();
      this.set('selectedProvider', defaultEInvoiceProvider);
      const defaultInvoicesView = this.settingsService.getProp('defaultInvoicesView', '');
      this.set('selectedTableView', defaultInvoicesView);
      this.set('hasDate', false);
      const period = this.dateService.getPeriod();
      this.set('period', period);
    },
    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    afterSearchInputTask() {
      const subTabOptions = {
        component: 'extensions/apps/tw/e-invoices/panels-display'
      };
      if (!this.hasDate) {
        this.set('hasStatement', false);
      }
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    displaySummaryPanel({
      dateStart,
      dateEnd
    }) {
      const period = this.dateService.getPeriod(dateEnd);
      const currentInvoiceMonth = this.dateService.getEvenInvoiceMonth(period.mm);
      const currentInvoicePeriod = this.dateService.getPeriod(`${period.yyyy}-${currentInvoiceMonth}-01`);
      currentInvoicePeriod.dateStart = dateStart;
      currentInvoicePeriod.dateEnd = dateEnd;
      const subTabOptions = {
        component: 'extensions/apps/tw/e-invoices/panels-display',
        period: currentInvoicePeriod
      };
      this.set('period', currentInvoicePeriod);
      this.set('hasStatement', true);
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    actions: {
      /**
       * display dispatched list for given date
       * @param  {string} date (YYYY-MM-DD)
       */
      displayByMonth(period) {
        // cleanse query before displaying by month
        const filters = this.filters;
        filters.setData('query', '');
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
        // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })
        this.set('hasDate', true);
        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
        this.set('mm', period.mm);
        this.set('yyyy', period.yyyy);
      },
      displayByCustomMonth(mm, yyyy) {
        // cleanse query before displaying by month
        const filters = this.filters;
        filters.setData('query', '');
        const dateStart = this.formatDateForUrl(`${yyyy}-${mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(yyyy, mm));
        // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })
        this.set('hasDate', true);
        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
      },
      refreshDisplay() {
        const hasDate = this?.hasDate;
        if (!hasDate) {
          return;
        }
        const filters = this.filters;
        filters.setData('query', '');
        const mm = this?.mm || '';
        const yyyy = this?.yyyy || '';
        const dateStart = this.formatDateForUrl(`${yyyy}-${mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(yyyy, mm));
        this.displaySummaryPanel({
          dateStart,
          dateEnd
        });
      }
    }
  }, _applyDecoratedDescriptor(_obj, "afterSearchInputTask", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterSearchInputTask"), _obj), _obj));
});