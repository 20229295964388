define("client/pods/components/lists/mixins/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * documents editor shared functions
   */
  var _default = _exports.default = Ember.Mixin.create({
    // focusOut () {
    //   const showDropdown = this.get('showDropdown')
    //   const list = this.get('list') || []
    //   const readonly = this.get('readonly')

    //   if (readonly === false && showDropdown && list.length > 0) {

    //   } else {
    //     this.set('showDropdown', false)
    //   }
    // },

    keyDown(e) {
      this._super(e);

      // if tab or down is pressed
      const keyCode = e.keyCode || e.which;
      const hasFocus = this.$().find('.list-results__item:focus');
      const list = this.$().find('.list-results__item');
      const showEditor = this.showEditor;
      if (!showEditor) {
        if (list.length > 0) {
          if (keyCode === 27) {
            // esc

            this.send('hideDropdown');
          }
          if (keyCode === 13) {
            // enter
            this.set('input', this.value);
            if (hasFocus.length > 0) {
              hasFocus.click();
            } else {
              const firstObject = this.list.get('firstObject');
              if (firstObject && this.value === this.lastValue) {
                this.send('selectDropdownItem', firstObject);
              } else {
                if (this.onSearchAction) {
                  this.send('onSearchAction');
                }
              }
            }
            this.set('lastValue', this.input);
          }
          let hasNext;
          if (keyCode === 38) {
            // up
            e.preventDefault();
            e.stopPropagation();
            hasNext = hasFocus.prev();
            if (hasFocus.length > 0 && hasNext.length === 0) {
              // go to bottom
              list[list.length - 1].focus();
            } else {
              if (hasFocus.length === 0) {
                // nothing focused, go to first
                this.$().find('.list-results__item')[list.length - 1].focus();
              } else {
                // go to next
                hasNext.focus();
              }
            }
          }
          if (keyCode === 40) {
            // down

            e.preventDefault();
            e.stopPropagation();
            hasNext = hasFocus.next();
            if (hasFocus.length > 0 && hasNext.length === 0) {
              // go to top
              this.$().find('.list-results__item')[0].focus();
            } else {
              if (hasFocus.length === 0) {
                // nothing focused, go to first
                this.$().find('.list-results__item')[0].focus();
              } else {
                // go to next
                hasNext.focus();
              }
            }
          }
          if (keyCode === 9) {
            this.send('hideDropdown');
          }
        }
      }
    }
  });
});