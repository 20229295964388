define("client/pods/components/forms/form-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    error: null,
    customBlock: false,
    // complete custom block template
    translate: true,
    hideIsEmpty: false,
    hasErrorClassNames: Ember.computed('error', function () {
      if (this.error) {
        return 'has-error';
      }
      return '';
    }),
    show: Ember.computed('hideIsEmpty', 'value', function () {
      const value = this.value;
      const hideIsEmpty = this.hideIsEmpty;
      if ((value == null || value === '') && hideIsEmpty) {
        return false;
      }
      return true;
    })
  });
});