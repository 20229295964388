define("client/pods/components/files/images-uploader/component", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    label: 'upload',
    classNames: ['images-uploader'],
    url: 'api/protected/uploads/images',
    hasErrors: false,
    invalidImageError: 'invalid image type',
    generalError: 'error occurred please try again later',
    error: '',
    showProgress: false,
    // set to true to show progress bar
    // files: [], // successfully uploaded file names
    // uploads: [], // files to upload
    onUploaded: null,
    // external action to handle uploading
    percentage: 0,
    subResourcePath: '',
    // firebase: service(),

    init() {
      this._super(...arguments);

      // if files is not an array throw an error
      if (!R.is(Array, this.files)) {
        this.set('files', []);
        // return nventor.throw('Images-uploader.files is not an array');
      }
    },
    /**
     * keep track of upload progress percentage
     */
    progress: Ember.computed('percentage', function () {
      const percentage = this.percentage;
      return percentage + '%';
    }),
    getUploadPath(fileName) {
      const self = this;
      const tokenData = self.get('storage').get('tokenData');
      const podKey = tokenData.pod;
      const random = _nventor.default.random.alphaNum(10);
      fileName = `${random}-${fileName}`;
      const subResourcePath = this.subResourcePath;
      if (subResourcePath) {
        return `pods/${podKey}/${subResourcePath}/${fileName}`;
      }
      return `pods/${podKey}/${fileName}`;
    },
    actions: {
      /**
       * handle file changes, when user finished selecting files
       */
      uploadsChanged(e, uploads) {
        if (uploads) {
          const file = uploads[0];
          if (file) {
            const formData = new FormData();
            formData.append('file', file);
            this.server.postFormData('api/protected/websites/pages/upload', formData);
          }
        }
      },
      /**
       * triggers hidden images uploader input when clicking on upload btn
       */
      selectUploads() {
        // trigger hidden upload input
        this.$('.files-uploader__input').click();
      }
    }
  });
});