define("client/pods/components/products/panels-dash/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _obj = {
    search: Ember.inject.service(),
    crud: Ember.inject.service(),
    isGuest: false,
    selectedStatusKey: null,
    selectedTagKeys: null,
    untagSelectedTagKeys: false,
    adapterName: 'products',
    filtersAdapterName: 'products/filters',
    productsProfitPathName: 'products/profit',
    init() {
      this._super(...arguments);
      this.tabsManager.setHeader(this.tabParent, '');
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'products/brands', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypes', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'products/lists/editorSubPanels', 'settings/roundingAuto', 'units', 'units/dimensions', 'units/weight', 'statuses']);
      const toPrintFilters = this.search.setupFilters({
        adapterName: 'products/filters/inventory-report',
        altStorageProp: 'toPrintResults',
        defaultFiltersData: {
          count: 10,
          page: 1,
          query: '',
          setTypes: [],
          units: '',
          name: '',
          code: '',
          barcode: '',
          resultsProperty: 'results',
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }, {
            key: 'setType',
            value: 'isSet'
          }],
          types: ['inventory'],
          includeInventoryReport: true
        }
      });
      this.set('toPrintFilters', toPrintFilters);
      this.set('selectedItems', []);
      this.set('selectedStatusKey', null);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.setupTask.perform();
    },
    setupTask: (0, _emberConcurrency.task)(function* () {
      const recurringFilters = yield this.productsService.getRecurringFiltersTask.perform();
      this.set('recurringFilters', recurringFilters);
    }),
    loadPrintDataTask: (0, _emberConcurrency.task)(function* () {
      const toPrintFilters = this.toPrintFilters;
      const lastSearchSettingsProperty = this.filters.get('lastSearchSettingsProperty');
      const lastSearchSettings = this.filters.get(lastSearchSettingsProperty);
      toPrintFilters.set(lastSearchSettingsProperty, lastSearchSettings);
      yield this.crud.reloadSearchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: toPrintFilters
      });
    }),
    selectItem(item, isSelected) {
      const selected = this.get('selectedItems') || [];
      if (isSelected === true) {
        selected.pushObject(item);
      } else {
        selected.removeObject(item);
      }
      this.set('selectedItems', selected);
    },
    toggleSelectAllItems() {
      const results = this.get('filters.results') || [];
      const selectedItems = this.get('selectedItems') || [];
      if (selectedItems === results) {
        this.set('selectedItems', []);
      } else {
        this.set('selectedItems', results);
      }
    },
    resetSelectedItems() {
      this.set('selectedItems', []);
    },
    resetSelectedStatusKeyAndSelectedItems() {
      this.set('selectedStatusKey', null);
      this.set('selectedItems', []);
    },
    resetSelectedTagKeys() {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
    },
    resetSelectedTagKeysAndSelectedItems() {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.set('selectedItems', []);
    },
    resetSelectedTagKeysAndStatusKeyAndSelectedItems() {
      this.set('selectedStatusKey', null);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.set('selectedItems', []);
    }
  }, _applyDecoratedDescriptor(_obj, "selectItem", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectItem"), _obj), _applyDecoratedDescriptor(_obj, "toggleSelectAllItems", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleSelectAllItems"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedItems", [_dec3], Object.getOwnPropertyDescriptor(_obj, "resetSelectedItems"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedStatusKeyAndSelectedItems", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resetSelectedStatusKeyAndSelectedItems"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeys", [_dec5], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeys"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeysAndSelectedItems", [_dec6], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeysAndSelectedItems"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeysAndStatusKeyAndSelectedItems", [_dec7], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeysAndStatusKeyAndSelectedItems"), _obj), _obj));
});