define("client/pods/grapesjs/plugins/traits/link", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: link
   * @author Nafis Rubio
   * @since 11/05/2022
   * @copyright (C) 2022 BigOrdr
   * @description: link trait
   */
  var _default = editor => {
    const {
      TraitManager
    } = editor;
    const traitName = 'bigordr-link-trait';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', traitName])(editor);
    const {
      intl,
      openLinksModal
    } = options;
    TraitManager.addType(traitName, {
      // noLabel: false,

      createLabel(ref = {}) {
        const label = R.prop('label')(ref) || intl.t('href');
        return `<div>
      ${label}
    </div>`;
      },
      // Return a simple HTML string or an HTML element
      createInput({
        trait
      }) {
        const _options = [{
          id: '_self',
          name: intl.t('this window') || 'This window'
        }, {
          id: '_blank',
          name: intl.t('new window') || 'New window'
        }];

        // Create a new element container add some content
        const el = document.createElement('div');
        el.innerHTML = `
      <style>
      .bigordr-link-trait {
        border: 1px solid #838385 !important;
        margin: 0;
      }
      </style>
      <div class="bigordr-link-trait-container u-tappable">
        <button class='button'>${intl.t('href')}</button>
      </div>
      `;
        const container = el.querySelector('.bigordr-link-trait-container');
        container.addEventListener('click', function () {
          openLinksModal();
        });
        return el;
      }
    });
  };
  _exports.default = _default;
});