define("client/pods/components/forms/form-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    translate: true,
    classNames: ['form-grid__item', 'form-label']
  });
});