define("client/pods/components/transporters/t-cat/pickup-location/editor-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iXw/Uql5",
    "block": "{\"symbols\":[\"@model\",\"@isReadonly\",\"@formInitData\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@required\",\"@isRequired\",\"@context\",\"@formInitData\"],[[23,1,[\"label\"]],true,true,[23,1,[\"_data\",\"subType\"]],[23,3,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"isDropdown\"]]],null,{\"statements\":[[0,\"    \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@translate\",\"@disabled\",\"@list\",\"@value\"],[true,true,[23,2,[]],[23,0,[\"list\"]],[23,1,[\"_data\",\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@isRequired\",\"@value\"],[[28,\"tt\",[\"required\"],null],true,[23,1,[\"_data\",\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/t-cat/pickup-location/editor-extension/template.hbs"
    }
  });
});