define("client/pods/payment-methods/shop/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _nventor, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      let data = [{
        value: 'creditCard',
        hasPaymentTerms: false,
        label: 'credit card',
        index: 1
      }, {
        value: 'cash',
        hasPaymentTerms: false,
        label: 'cash',
        index: 2
      }, {
        value: 'cashBeforeDelivery',
        hasPaymentTerms: false,
        label: 'cash before delivery',
        index: 3
      }, {
        value: 'linePay',
        hasPaymentTerms: false,
        label: 'line pay',
        index: 4
      }];

      // Filter out line pay if it's not enabled
      const settingsModel = R.path(['settings', 'settingsModel'])(this) || {};
      if (settingsModel.linePayEnabled !== true) {
        data = R.reject(method => method.value === 'linePay')(data);
      }
      return _nventor.default.resolve(data);
    }
  });
});