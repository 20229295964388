define("client/pods/documents/mixins/details/adapters", ["exports", "client/mixins/adapters-new"], function (_exports, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_adaptersNew.default, {
    beforepopulate(data = {}) {
      data._key = data.detail._key;
      return data;
    }
  });
});