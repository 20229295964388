define("client/pods/components/elements/date-picker/component", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import IsDirtyMixin from '../../../../mixins/is-dirty'
  // import HasDropdownMixin from '../../../../mixins/has-dropdown'
  // import HasDatePickerMixin from '../mixins/has-date-picker'
  // import TranslateMixin from '../../../../mixins/translate'
  var _default = _exports.default = Ember.Component.extend(_date.default, {
    tagName: 'div',
    classNames: ['u-datepicker-container'],
    isFirstTime: true,
    init() {
      this._super(...arguments);
      const self = this;

      // sets up pikaday, will only run after it has been rendered
      // if (this.get('isDatePicker')) {
      // setup pikaday with options
      const options = {
        // bound: false => do not bind pikaday to input focus, handle it manually
        bound: false,
        keyboardInput: false,
        position: '',
        firstDay: 1,
        onSelect: function /* date */
        () {
          // if (!) {
          self._onSelectDate(self.get('isFirstTime'));
          // } else {}
        }
      };
      // create new pikaday instance
      const pikaday = this.pikaday.setup(options);
      pikaday.show();

      // set pikaday instance to datePicker property
      this.set('datePicker', pikaday);

      // this.setupLocalDateValue()
    },
    didInsertElement() {
      this._super(...arguments);
      this.setupDatePicker();
    },
    // didRender () {
    //   this._super(...arguments)

    //   // this.setPickerDate()

    //   // const datePicker = this.get('datePicker')
    //   // datePicker.show()

    //   const dropdownContainer = this.$()
    //   const height = dropdownContainer.outerHeight()
    //   const width = dropdownContainer.outerWidth()
    //   const top = dropdownContainer.offset().top
    //   const right = dropdownContainer.offset().left + width

    //   const bottom = top + height
    //   const viewportTop = this.$(window).scrollTop()
    //   const viewportRight = this.$(window).width()
    //   const viewportBottom = viewportTop + this.$(window).height() - 35

    //   if (bottom > viewportBottom) {
    //     const topDifference = bottom - viewportBottom + 40
    //     dropdownContainer.css('marginTop', `-=${topDifference}px`)
    //   }

    //   if (right > viewportRight) {
    //     const rightDifference = right - viewportRight + 40
    //     dropdownContainer.css('marginLeft', `-=${rightDifference}px`)
    //   }
    // },

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        // tear down pikaday
        this._super(...arguments);
        // this.setPickerDate()
        this.datePicker.destroy();
      }
    },
    getLocalDateValue() {
      const dateZ = this.dateZ;
      if (dateZ) {
        const localDate = this.formatDate(dateZ);
        this.set('localDate', localDate);
        return localDate;
      }
      return '';
    },
    setupDatePicker() {
      const pikaday = this.datePicker;
      const picker = this.$(pikaday.el);
      const container = this.$('.u-datepicker-element');
      container.append(picker);

      // this.setPickerDate()
      const dateZ = this.dateZ;
      pikaday.setDate(dateZ);
      this.set('isFirstTime', false);
      pikaday.show();
    },
    _onSelectDate(isFirstTime) {
      // format date on select

      // @TODO: test

      // const input = this.$()[0];
      const datePicker = this.datePicker;

      // pikaday uses iso8601 YYYY/MM/DD as default
      const dateString = datePicker.toString();

      // date at local time zone
      const localDate = this.formatDate(dateString);

      // date at Zulu time zone
      const dateZ = datePicker.getDate().toISOString();

      // const previousDateZ = this.get('dateZ')
      this.set('dateZ', dateZ);

      // hide pikaday on select
      // this.get('datePicker').hide()
      // this.setupLocalDateValue()
      if (!isFirstTime) {
        if (this.onSelectDate) {
          // if (dateZ !== previousDateZ) {
          // trigger onSelectDate action if exists
          // }
          this.onSelectDate(dateZ, localDate);
        }
      }
    }

    // setPickerDate () {
    //   // localDate = localDate || this.getLocalDateValue()

    //   // const dateZ = this.getStartOfDayZ(localDate, this.get('dateFormat'))
    //   const dateZ = this.get('dateZ')
    //   const datePicker = this.get('datePicker')
    //   datePicker.setDate(dateZ)
    //   this.set('isFirstTime', false)
    // }
  });
});