define("client/pods/channels/shop/campaigns/bulk-discounts/rules/member-levels/model", ["exports", "ramda", "client/pods/base/model"], function (_exports, R, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        memberLevelId: '',
        discountType: this.constants.discountTypes.rate,
        discountAmount: '',
        discountRate: '',
        description: '',
        isDefaultDescription: true
      };
    },
    populate(data, attrs) {
      const discountType = R.propOr(this.constants.discountTypes.rate, 'discountType')(data);
      if (discountType === this.constants.discountTypes.rate) {
        data.discountAmount = '';
      }
      if (discountType === this.constants.discountTypes.amount) {
        data.discountRate = '';
      }
      this._super(data, attrs);
    }
  });
});