define("client/pods/components/products/price-selectors/price-selector/component", ["exports", "ramda", "ember-concurrency"], function (_exports, R, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    classNames: [''],
    isReadonly: false,
    fetchHistoryOnInitTask: (0, _emberConcurrency.task)(function* () {
      const product = this.product;
      const itemKey = this.itemKey;
      const onFetchHistoryTask = this.onFetchHistoryTask;
      const {
        priceHistoryData
      } = yield onFetchHistoryTask(product, itemKey);
      this.set('priceHistoryData', priceHistoryData);
    }),
    isDisabled: Ember.computed('isReadonly', 'itemKey', function () {
      if (!this.itemKey) {
        return true;
      }
      return this.isReadonly;
    }),
    productExclPrice: Ember.computed('docType', 'product', function () {
      const type = this.docType;
      const product = this.product;
      return product.getData(`${type}ExclPrice`);
    }),
    productInclPrice: Ember.computed('docType', 'product', function () {
      const type = this.docType;
      const product = this.product;
      return product.getData(`${type}InclPrice`);
    }),
    forContactLatest: Ember.computed('priceHistoryData.history.forContact.[]', function () {
      const forContact = this.productsService.getFor('contact', this.priceHistoryData);
      if (!R.isEmpty(forContact)) {
        return R.take(1, forContact);
      }
      return [];
    }),
    latestByDate: Ember.computed('priceHistoryData.history.{forContact.[],forOthers.[]}', function () {
      const forContact = this.productsService.getFor('contact', this.priceHistoryData);
      const forOthers = this.productsService.getFor('others', this.priceHistoryData);
      return R.concat(forContact, forOthers);
    }),
    sortedPriceHistoryData: Ember.computed('latestByDate.[]', function () {
      return this.latestByDate;
    }),
    actions: {
      select(history, onHideDropdown) {
        this.onSelect(history, onHideDropdown);
      }
    }
  });
});