define("client/pods/components/products/shop/filters-advanced/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProductsFiltersAdvancedComponent = _exports.default = (_dec = Ember.inject.service('products'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _class = class ProductsFiltersAdvancedComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "productsService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "recurringFilters", _descriptor3, this);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/products-filters']);
      this.setupTask.perform();
    }
    *setupTask() {
      const recurringFilters = yield this.productsService.getRecurringFiltersTask.perform() || [];
      this.recurringFilters = recurringFilters;
    }
    selectItem(item, isSelected) {
      if (this?.args?.onSearchTask) {
        this.args.onSearchTask.perform();
      }
    }
    get showStatusTags() {
      // @TODO: move to filter model
      const statuses = this?.crud?.lists?.['channels-shop-campaigns-statuses'] || [];
      const selectedStatuses = this.args.filters?._data?.statuses || [];
      if (selectedStatuses.length === 0) {
        return false;
      }
      if (selectedStatuses.length === statuses.length) {
        return false;
      }
      return true;
    }
    get showResetFilter() {
      // @TODO: move to filter model
      const filtersData = this?.args?.filters?._data;
      if (RA.isNilOrEmpty(filtersData)) {
        return false;
      }
      const statuses = filtersData.statuses;
      const hasImages = filtersData.hasImages;
      const item = filtersData.item;
      const itemCode = filtersData.itemCode;
      const price = filtersData.price;
      const setTypes = filtersData.setTypes;
      const tags = filtersData.tags;
      if (statuses.length !== 5 || tags.length !== 0 || setTypes.length !== 0 || RA.isNilOrEmpty(hasImages) !== true || item !== '' || itemCode !== '' || price !== '') {
        return true;
      }
      return false;
    }
    *updateFiltersTask() {
      // @TODO: how can this be improved?
      const filters = this.args.filters || {};
      Ember.set(filters, '_data', filters?._data || {}); // reset data

      return yield this.args.onSearchTask.perform(...arguments);
    }
    *clearSelectedItemsTask() {
      if (this?.crud?.reloadSearchRecordsTask) {
        yield this?.crud?.reloadSearchRecordsTask.perform({
          filters: this.args.filters,
          reset: true
        });
        const filters = this.args.filters || {};
        Ember.set(filters, '_data', filters?._data || {}); // reset data
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recurringFilters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSelectedItemsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelectedItemsTask"), _class.prototype), _class);
});