define("client/pods/components/settings/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X39i0Nt+",
    "block": "{\"symbols\":[\"@documentationResource\",\"@model\",\"@onEdit\",\"@tab\"],\"statements\":[[5,\"settings/settings-editor\",[],[[\"@documentationResource\",\"@isEditing\",\"@model\",\"@onEdit\",\"@tab\"],[[23,1,[]],false,[23,2,[]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/panels-display/template.hbs"
    }
  });
});