define("client/pods/components/channels/shop/promo-codes/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oAkwF47L",
    "block": "{\"symbols\":[\"tabsHeaderComponent\",\"isOverflowMenu\",\"onToggleOverflowMenu\",\"@onDisplay\",\"@filters\",\"@onPaginationTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container flex-container-row flex-container--wrap\"],[8],[0,\"\\n    \"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"tableClassNames\",\"detailsRowComponent\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onEdit\",\"onPaginationTask\",\"onDisplay\"],[[24,[\"tab\"]],\"promo-codes-dash\",\"channels/shop/promo-codes/table-view/detail-row\",[24,[\"filters\",\"results\"]],[24,[\"filters\"]],[24,[\"onSearchTask\"]],[24,[\"onReloadSearchRecordsTask\"]],[24,[\"onEdit\"]],[24,[\"onPaginationTask\"]],[23,4,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n    \\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[[12,\"class\",\"show-when-overflow\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPageNumbers\"],[[23,5,[]],[23,5,[\"results\"]],[23,6,[]],true]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1,2,3]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/promo-codes/panels-dash/template.hbs"
    }
  });
});