define("client/pods/components/user-login/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    auth: Ember.inject.service(),
    updates: Ember.inject.service(),
    username: '',
    password: '',
    code: '',
    step: 0,
    resentCode: false,
    twoFactorAuthTries: 0,
    init() {
      this.crud.addLists(this, ['languages']);
      this._super(...arguments);
    },
    actions: {
      async login() {
        this.set('resentCode', false);
        this.set('code', '');
        this.set('step', 0);
        this.set('twoFactorAuthTries', 0);
        const credentials = {
          username: this.username,
          password: this.password
        };
        const loginResponse = await this.auth.get('loginTask').perform(credentials);
        if (loginResponse?.isTwoFactorAuth) {
          this.set('step', 1);
        }
      },
      async loginTwoFactor() {
        const code = this.code || '';
        if (!code) {
          // set error
          return;
        }
        const loginSuccessfully = await this.auth.get('loginTwoFactorTask').perform(code);
        this.twoFactorAuthTries = Number(this.twoFactorAuthTries || 0) + 1;
        if (!loginSuccessfully) {
          this.set('code', '');
          if (this.twoFactorAuthTries >= 3) {
            this.set('step', 0);
          }
        }
      },
      async resendTwoFactorCode() {
        const hasSentSuccessfully = await this.auth.get('resendTwoFactorCodeTask').perform();
        if (hasSentSuccessfully) {
          this.set('resentCode', true);
        } else {
          this.set('resentCode', false);
          this.set('step', 0);
        }
      },
      async cancelTwoFactorAuth() {
        this.set('code', '');
        this.set('resentCode', false);
        this.set('step', 0);
        this.set('twoFactorAuthTries', 0);
        this.auth.resetLogin();
      },
      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
      }
    }
  });
});