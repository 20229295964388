define("client/pods/extensions/apps/tw/e-invoices/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    isSaved: Ember.computed('isDeleted', '_data.status', function () {
      if (this.isDeleted) {
        return false;
      }
      if (this.get('_data.status') === 'printed' || this.get('_data.status') === 'pending') {
        return true;
      }
      return false;
    }),
    isPending: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');
      if (status === 'pending' || status === 'pendingCancellation') {
        return true;
      }
      return false;
    }),
    isSuccessful: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');
      if (status === 'successful') {
        return true;
      }
      return false;
    }),
    isSuccessfulOrCancelled: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');
      if (status === 'successful' || status === 'cancelled') {
        return true;
      }
      return false;
    }),
    isCancelledOrUnsuccessful: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');
      if (status === 'unsuccessful' || status === 'cancelled') {
        return true;
      }
      return false;
    }),
    isAttentionRequired: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');
      if (status === 'attentionRequired') {
        return true;
      }
      return false;
    }),
    /**
     * Allow for a reprint whenever the original hasn't been printed successfully.
     */
    isAllowReprint: Ember.computed('_data.timestampZ', 'isSuccessful', function () {
      if (!this?._data?.isSuccessful) {
        return false;
      }

      //    const timestampZ = R.pathOr('', ['_data', 'timestampZ'])(this)
      //    if (!timestampZ) {
      //      return false
      //    }
      //
      //    const aDayAgoZ = this.dateService.getMoment().subtract(1, 'day')
      //
      //    const hasPassedADay = aDayAgoZ.isAfter(timestampZ)
      //    if (hasPassedADay) {
      //      return false
      //    }

      return true;
    }),
    statusClassName: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');
      if (status === 'successful') {
        return 'is-success';
      }
      if (status === 'attentionRequired') {
        return 'is-danger';
      }
      if (status === 'cancelled') {
        return 'is-danger';
      }
      if (status === 'unsuccessful') {
        return 'is-black';
      }
      return '';
    }),
    printStatusClassName: Ember.computed('_data.printed.printStatus', function () {
      const status = this.get('_data.printStatus');
      if (status === 'successful') {
        return 'is-success';
      }
      return 'is-warning';
    }),
    allowResending: Ember.computed('_data.provider', function () {
      if (this._data.provider === 'ecPay') {
        return true;
      }
      if (this._data.provider === '') {
        return true;
      }
      return false;
    }),
    errorMessage: Ember.computed('_data.errorMessage', '_data.response.RtnMsg', function () {
      const errorMessage = R.pipe(R.pathOr('', ['_data', 'errorMessage']), R.trim)(this);
      if (errorMessage) {
        return errorMessage;
      }

      // const rtnMessage = R.pipe(
      //   R.pathOr('', ['_data', 'response', 'RtnMsg']),
      //   R.trim
      // )(this)
      //
      // if (rtnMessage) {
      //   return rtnMessage
      // }

      return '';
    })
  });
});