// import * as R from 'ramda'

// import Component from '@ember/component'
// import CrudMixin from '../../../../../../../../mixins/crud'
// import { task } from 'ember-concurrency'

// export default Component.extend(CrudMixin, {
//   tagName: '',
//   isReadonly: false,
//   init () {
//     this._super(...arguments)

//     const model = this.get('model')
//     const docType = model.getData('docType')
//     const docName = model.getData('docName')
//     const adapterName = `documents/${docType}/${docName}`

//     this.set('adapterName', adapterName)
//     const dirty = this.setupDirty({ adapterName, model})
//     this.set('dirty', dirty)
//   },

//   updateTask: task(function * (onCloseDialog) {
//     const dirty = this.get('dirty')
//     const adapterName = this.get('adapterName')
//     const appendPath = 'info'
//     let saved

//     saved = yield this.get('updateRecordTask').perform({ adapterName, appendPath, model: dirty })

//     if (saved) {
//       this.set('dirty', '')
//       onCloseDialog()
//     }
//     return saved
//   })
// })
define("client/pods/components/extensions/apps/tw/e-invoices/print-all-btn/edit-btn/component", [], function () {
  "use strict";
});