define("client/pods/components/simple-editor/page-block/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bHaSFG1S",
    "block": "{\"symbols\":[\"@pageBlock\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"simple-editor__block-container\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"label\"]]],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/simple-editor/page-block/text/template.hbs"
    }
  });
});