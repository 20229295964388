define("client/pods/components/channels/shop/campaigns/campaigns-editor/products-editor/component", ["exports", "ramda", "ramda-extension", "ember-concurrency", "client/mixins/sortable-actions"], function (_exports, R, R_, _emberConcurrency, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @deprecated
   * can delete
   */
  var _default = _exports.default = Ember.Component.extend(_sortableActions.default, {
    crud: Ember.inject.service(),
    modelProp: 'model',
    indexProp: '_data.index',
    adapterName: 'channels/shop/campaigns/details',
    filtersAdapterName: 'channels/shop/campaigns/details/filters',
    isSearchDetailsWithoutShippingZones: false,
    itemModel: null,
    isEditing: false,
    mode: 'overview',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/noOfLines', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'channels/shop/campaigns/lists/productsNameFontSize', 'products/lists/onSaleTagDisplayPosition', 'products/lists/onSaleTagDisplayStyle', 'channels/shop/campaigns/lists/productsNameLineCount', 'channels/shop/campaigns/lists/products-tags-display-style', 'products/lists/bigordr-products-slider-items', 'products/lists/bigordr-products-per-row']);
      if (this.model._data.allowShipping === true && this.model._data.shippingFeeType === this.get('constants.shippingFeeType.zones')) {
        this.getTotalDetailsWithoutShippingZones.perform();
      }
    },
    didInsertElement() {
      this._super(...arguments);
      const isEditing = this.get('isEditing');
      if (!isEditing) {
        this.fetchCampaignDetailsTask.perform();
      }
    },
    toDisplayResults: Ember.computed('_dirtyDetails.@each.synced', 'model.synced', function () {
      return this.get('_dirtyDetails') || [];
    }),
    _getSortedDetails() {
      const details = this.get('filters.results') || [];
      let results = details;
      const shopProductsSort = this.filters?.getData('shopProductsSort') || 'recommended';
      const existingSortBy = [{
        label: 'recommended',
        by: 'index',
        direction: 'ASC',
        toNumber: true
      }, {
        label: 'newestFirst',
        by: 'index',
        direction: 'DESC',
        toNumber: true
      }, {
        label: 'priceAsc',
        by: 'priceFinal',
        direction: 'ASC',
        toNumber: true
      }, {
        label: 'priceDesc',
        by: 'priceFinal',
        direction: 'DESC',
        toNumber: true
      }];
      const sortArray = R.filter(R.propEq('label', shopProductsSort))(existingSortBy);
      const sortBy = R.pathOr('', ['firstObject', 'by'])(sortArray);
      if (sortBy && sortArray.length === 1) {
        const sortDirection = R.pathOr('', ['firstObject', 'direction'])(sortArray);
        const sortToNumber = R.pathOr(false, ['firstObject', 'toNumber'])(sortArray);
        if (sortDirection === 'ASC') {
          results = R.pipe(R.reject(R.pathEq(['isDeleted'], true)), R.sortWith([sortToNumber ? R.ascend(data => Number(R.path(['_data', sortBy])(data))) : R.ascend(R.path(['_data', sortBy]))]))(details);
        }
        if (sortDirection === 'DESC') {
          results = R.pipe(R.reject(R.pathEq(['isDeleted'], true)), R.sortWith([sortToNumber ? R.descend(data => Number(R.path(['_data', sortBy])(data))) : R.descend(R.path(['_data', sortBy]))]))(details);
        }
      }
      return results;
    },
    _dirtyDetails: Ember.computed('filters.results.[]', 'filters.synced', 'model.synced', function () {
      return this._getSortedDetails();
    }),
    showCustomLimitedQtyPerPersonTextSettings: Ember.computed('dirty._data.{useDefaultsProductsDisplaySettings,hasCustomLimitedQtyPerPersonText}', 'settings.settingsModel._data.hasCustomLimitedQtyPerPersonText', function () {
      const dirty = this.dirty;
      const useDefaultsProductsDisplaySettings = R.pathOr(true, ['_data', 'useDefaultsProductsDisplaySettings'])(dirty);
      if (useDefaultsProductsDisplaySettings) {
        return this.settings.getProp('hasCustomLimitedQtyPerPersonText');
      }
      return R.pathOr(false, ['_data', 'hasCustomLimitedQtyPerPersonText'])(dirty);
    }),
    showOnSaleTagSettings: Ember.computed('dirty._data.{useDefaultsProductsDisplaySettings,showOnSaleTag}', 'settings.settingsModel._data.showOnSaleTag', function () {
      const dirty = this.dirty;
      const useDefaultsProductsDisplaySettings = R.pathOr(true, ['_data', 'useDefaultsProductsDisplaySettings'])(dirty);
      if (useDefaultsProductsDisplaySettings) {
        return this.settings.getProp('showOnSaleTag');
      }
      return R.pathOr(true, ['_data', 'showOnSaleTag'])(dirty);
    }),
    reorderAndSaveDetailsTask: (0, _emberConcurrency.task)(function* (details) {
      if (!R.isEmpty(details) && !this.isGuest) {
        const adapterName = 'channels/shop/campaigns/details/batch';
        const master = this.get('model._data._key');
        const appendPath = `/${master}/indexes`;
        return yield this.crud.adapterCallTask.perform('updateIndexes', {
          adapterName,
          appendPath,
          model: details
        });
      }
      return false;
    }).drop(),
    fixIndexesTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      if (!this.isGuest) {
        const master = this.get('model._data._key');
        const msg = this.intl.t('are you sure you want to re-index all products? this may take some time.');
        if (window.confirm(msg)) {
          const resp = yield this.server.call('PATCH', `api/protected/channels/shop/campaigns/details/master/${master}/fix-indexes`);
          if (onCloseDialogAction) {
            onCloseDialogAction();
          }
          return resp;
        }
      }
      return false;
    }).drop(),
    saveProductSettingsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      yield this.crud.replaceRecordTask.perform({
        adapterName: 'channels/shop/campaigns',
        model: this.dirty
      });
      onCloseDialogAction();
    }).drop(),
    // drafts: computed('results.@each.synced', function () {
    //   const campaignDetails = this.results || []
    //   return R.filter(R.pathEq(['_data', 'status'], 'draft'))(campaignDetails)
    // }),

    getTotalDetailsWithoutShippingZones: (0, _emberConcurrency.task)(function* () {
      const master = this?.model?._data?._key;
      const result = yield this.server.call('GET', `api/protected/channels/shop/campaigns/details/master/${master}/details-without-shipping-zones`);
      const totalDetailsWithoutShippingZones = result?.data || 0;
      this.set('totalDetailsWithoutShippingZones', totalDetailsWithoutShippingZones);
    }),
    searchDetailsWithoutShippingZones: (0, _emberConcurrency.task)(function* (isReset = false) {
      const filters = this?.filters || {};
      let hasNoShippingZones = false;
      if (!isReset) {
        hasNoShippingZones = true;
      }
      filters.setData('hasNoShippingZones', hasNoShippingZones);
      yield this.crud.searchAdvancedTask.perform({
        filters
      });
      if (isReset) {
        this.set('isSearchDetailsWithoutShippingZones', false);
      } else {
        this.set('isSearchDetailsWithoutShippingZones', true);
      }
    }),
    actions: {
      resetDetailsUpdated() {
        this.model.set('detailsUpdated', []);
      },
      reorderAndSaveDetails(detail) {
        const model = this.model;
        const dragItem = this.dragDetail;
        const details = this.filters?.results || [];
        const sortedDetails = model.reorderDetails(details, detail, dragItem);
        this.reorderAndSaveDetailsTask.perform(sortedDetails);
      },
      editProductSettings() {
        const dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns',
          model: this.model
        });
        this.set('dirty', dirty);
      },
      cancelProductSettingsEditing(onCloseDialogAction) {
        this.crud.cancelEditing({
          adapterName: 'channels/shop/campaigns',
          dirty: this.dirty
        });
        this.set('dirty', false);
        onCloseDialogAction();
      }
    }
  });
});