define("client/pods/components/documents/document-print/footer-purchases-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WB1i2JxH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"documents/document-print/footer-purchases-signature\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"documents/document-print/footer-purchases-terms\",null,[[\"model\",\"selectedTerms\"],[[24,[\"model\"]],[24,[\"selectedTerms\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/footer-purchases-orders/template.hbs"
    }
  });
});