define("client/pods/components/channels/shop/campaigns/campaigns-report/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    guests: Ember.inject.service(),
    router: Ember.inject.service(),
    revenueBy: 'ordered',
    productsBySetsWithChoices: false,
    showReportBy: 'month',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['channels/shop/campaigns/reports/filters', 'reports/lists/show-reports-by', 'taxMethods/filters', 'periods/months', 'periods/years']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: this.revenueBy,
        taxMethods: 'inclusive'
      }));
    },
    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');
      if (revenueBy === 'ordered') {
        return 'all';
      }
      return this.get('constants.dispatchStatus.dispatched');
    }),
    didInsertElement() {
      this._super(...arguments);
      let sourceKey = '';
      if (!this.isGuest) {
        const model = this.model;
        sourceKey = model.getData('_key');
        const hasPos = model.getData('hasPos');
        if (hasPos) {
          this.set('showReportBy', 'day');
        }
        if (!model?._data?.dateEndZ) {
          this._setCurrentPeriod();
        }
      } else {
        const tokenData = this.tokenData || {};
        if (tokenData?.version) {
          if (!tokenData?.campaignDateEndZ) {
            this._setCurrentPeriod();
          }
        }
      }
      this.fetchRevenueReportTask.perform({
        sourceKey
      });
    },
    _setCurrentPeriod() {
      const period = this.dateService.getPeriod();
      const todayZ = this.dateService.getStartOfDayZ();
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
      this.set('customDateStartZ', todayZ);
    },
    resetPeriodTask: (0, _emberConcurrency.task)(function* ({
      sourceKey,
      onAfter
    }) {
      this.set('dateStart', '');
      this.set('dateEnd', '');
      this.set('mm', '');
      this.set('yyyy', '');
      yield this.fetchRevenueReportTask.perform({
        sourceKey,
        onAfter
      });
    }),
    fetchRevenueReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey,
      onAfter
    }) {
      const campaignsRevenue = yield this._fetchReportTask.perform('campaigns-revenue', {
        sourceKey,
        segments: ['activity', 'orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'contact', 'item', 'itemWithGroupedSetWithChoices', 'trackingCodes', 'paymentMethodKey', 'status']
      });
      this.set('campaignsRevenue', campaignsRevenue);
      this.set('activityReport', []);
      this.set('itemReport', []);
      this.set('paymentMethodKeyReport', []);
      this.set('contactReport', []);
      if (onAfter) {
        onAfter();
      }
    }),
    displayActivityReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const activityReport = yield this._fetchReportTask.perform('campaigns-activity', {
        sourceKey,
        segments: ['activity', 'itemWithGroupedSetWithChoices', 'trackingCodes']
      });
      this.set('activityReport', activityReport);
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const itemReport = yield this._fetchReportTask.perform('campaigns-activity', {
        sourceKey,
        segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'item', 'itemWithGroupedSetWithChoices', 'trackingCodes', 'status']
      });
      this.set('itemReport', itemReport);
    }),
    displayPaymentMethodKeyReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const paymentMethodKeyReport = yield this._fetchReportTask.perform('campaigns-activity', {
        sourceKey,
        segments: ['paymentMethodKey']
      });
      this.set('paymentMethodKeyReport', paymentMethodKeyReport);
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function* ({
      sourceKey
    }) {
      const isGuest = this?.isGuest;
      let segments = ['contact', 'customerType', 'memberType', 'gender', 'age'];
      if (!isGuest) {
        segments = R.append('contactTags')(segments);
      }
      const contactReport = yield this._fetchReportTask.perform('campaigns-activity', {
        sourceKey,
        segments
      });
      this.set('contactReport', contactReport);
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function* (reportName, {
      sourceKey,
      segments = []
    }) {
      const params = {
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        // segments: [
        //   'activity',
        //   'orderDateZ',
        //   'expectedDispatchDateZ',
        //   'dispatchDateZ',
        //   'contact',
        //   'item',
        //   'itemWithGroupedSetWithChoices',
        //   'trackingCodes',
        //   'paymentMethodKey',
        //   'status',
        //   'customerType',
        //   'memberType',
        //   'gender',
        //   'age'
        // ]
        segments
      };
      let dateStart = '';
      let dateEnd = '';
      const showReportBy = this.showReportBy;
      if (R.equals(showReportBy, 'month')) {
        const mm = this.mm;
        const yyyy = this.yyyy;
        if (mm && yyyy) {
          const monthEnd = this.dateService.getMonthEnd(yyyy, mm).format('YYYY-MM-DD');
          dateStart = `${yyyy}-${mm}-01`;
          dateEnd = monthEnd;
        }
      }
      if (R.equals(showReportBy, 'day')) {
        const customDateStartZ = R.clone(this?.customDateStartZ || '');
        if (customDateStartZ) {
          dateStart = this.dateService.getMoment(customDateStartZ).format('YYYY-MM-DD');
          dateEnd = R.clone(dateStart);
        }
      }
      const revenueBy = this.showCampaignReportBy?.revenueBy;
      if (revenueBy === 'dispatched') {
        params.dispatchDateStart = dateStart;
        params.dispatchDateEnd = dateEnd;
      } else {
        params.dateStart = dateStart;
        params.dateEnd = dateEnd;
      }
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      let report;
      if (this.isGuest) {
        params.report = reportName;
        const tokenData = this.tokenData || {};
        params.showCampaignPeriodDataOnly = R.pathOr(false, ['showCampaignPeriodDataOnly'])(tokenData);
        params.campaignPeriodDateStart = R.pathOr('', ['campaignDateStartZ'])(tokenData);
        params.campaignPeriodDateEnd = R.pathOr('', ['campaignDateEndZ'])(tokenData);
        try {
          report = yield this.guests.call('GET', 'api/guests/channels/shop/campaigns/report', params);
        } catch (e) {
          return this.guests.logout();
        }
      } else {
        const model = this.model;
        params.showCampaignPeriodDataOnly = R.pathOr(false, ['_data', 'showCampaignPeriodDataOnly'])(model);
        params.campaignPeriodDateStart = R.pathOr('', ['_data', 'dateStartZ'])(model);
        params.campaignPeriodDateEnd = R.pathOr('', ['_data', 'dateEndZ'])(model);
        params.sourceKey = sourceKey;
        report = yield this.server.call('GET', `api/protected/reports/${reportName}`, params);
      }
      return report.data;
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }
      return false;
    }),
    showCampaignPeriodDataOnly: Ember.computed('isGuest', 'tokenData.showCampaignPeriodDataOnly', 'model._data.showCampaignPeriodDataOnly', function () {
      let showCampaignPeriodDataOnly;
      if (this.isGuest) {
        showCampaignPeriodDataOnly = R.pathOr(false, ['tokenData', 'showCampaignPeriodDataOnly'])(this);
      } else {
        showCampaignPeriodDataOnly = R.pathOr(false, ['model', '_data', 'showCampaignPeriodDataOnly'])(this);
      }
      return showCampaignPeriodDataOnly;
    }),
    campaignsReportsFilters: Ember.computed('showCampaignPeriodDataOnly', 'crud.lists.channels-shop-campaigns-reports-filters.[]', function () {
      const campaignsReportsFilters = R.pathOr([], ['crud', 'lists', 'channels-shop-campaigns-reports-filters'])(this);
      const showCampaignPeriodDataOnly = this.showCampaignPeriodDataOnly;
      if (showCampaignPeriodDataOnly) {
        return R.reject(R.pathEq(['_data', 'value'], 'dispatched'))(campaignsReportsFilters);
      }
      return campaignsReportsFilters;
    }),
    customMinDateZ: Ember.computed('showCampaignPeriodDataOnly', 'model._data.dateStartZ', function () {
      const showCampaignPeriodDataOnly = this.showCampaignPeriodDataOnly;
      if (showCampaignPeriodDataOnly) {
        const dateStartZ = R.pathOr('', ['model', '_data', 'dateStartZ'])(this);
        return dateStartZ;
      }
      return '';
    }),
    customMaxDateZ: Ember.computed('showCampaignPeriodDataOnly', 'model._data.dateEndZ', function () {
      const showCampaignPeriodDataOnly = this.showCampaignPeriodDataOnly;
      if (showCampaignPeriodDataOnly) {
        const dateEndZ = R.pathOr('', ['model', '_data', 'dateEndZ'])(this);
        return dateEndZ;
      }
      return '';
    })
  });
});