define("client/pods/components/home/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XMVnTQYJ",
    "block": "{\"symbols\":[\"@tab\"],\"statements\":[[5,\"layouts/tabs/grid\",[],[[\"@tab\",\"@parentComponent\"],[[23,1,[]],[23,0,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/tabs/grid/content\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"home/wizard-container\",[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/home/tab-container/template.hbs"
    }
  });
});