define("client/pods/parser/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const parser = new exprEval.Parser();
  var _default = _exports.default = Ember.Service.extend({
    evaluate(expr, subs = {}) {
      return parser.parse(expr).evaluate(subs);
    }
  });
});