define("client/pods/components/simple-editor/page-block-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mNIWHeyO",
    "block": "{\"symbols\":[\"formInitData\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\",\"@isReadonly\",\"@website\",\"@pageBlock\",\"@saveTask\",\"@onCancel\",\"@onCloseDialogAction\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@hasColumns\",\"@isUserAllowed\",\"@formInitData\",\"@tab\"],[\"simpleEditor-blockEditor\",false,true,[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"simpleEditor-blocks\",[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]]]]],[23,4,[]]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"component\",[[28,\"concat\",[\"simple-editor/page-block-editor/\",[23,8,[\"_data\",\"type\"]]],null]],[[\"pageBlock\",\"website\",\"formInitData\",\"isReadonly\",\"tab\"],[[23,8,[]],[23,7,[]],[23,1,[]],[23,6,[]],[23,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/btn-cancel\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,9,[\"isRunning\"]],[23,10,[]]]]],[0,\"\\n\\n    \"],[5,\"elements/btn-save\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,9,[\"isRunning\"]],[28,\"perform\",[[23,9,[]],[23,11,[]]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/simple-editor/page-block-editor/template.hbs"
    }
  });
});