define("client/pods/grapesjs/plugins/sessionStorage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = editor => {
    const {
      Storage
    } = editor;
    const storageConfig = Storage.getConfig();
    const options = storageConfig.options || {};
    const indexedDBKey = options.indexedDB.key || 'gjs-editor';
    const parseKey = (obj, key) => {
      try {
        return JSON.parse(obj?.[key]);
      } catch (error) {
        return obj?.[key];
      }
    };
    Storage.add('indexedDB', {
      async load(options = {}) {
        const data = await idbKeyval.get(indexedDBKey);
        return JSON.parse(data);
      },
      async store(data, options = {}) {
        const updatedData = {
          assets: parseKey(data, 'assets'),
          components: parseKey(data, 'components'),
          css: parseKey(data, 'css'),
          html: parseKey(data, 'html'),
          styles: parseKey(data, 'styles')
        };
        await idbKeyval.set(indexedDBKey, JSON.stringify(updatedData));
      }
    });
  };
  _exports.default = _default;
});