define("client/pods/components/menus/nav-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Dzp7xfCL",
    "block": "{\"symbols\":[\"item\",\"@errors\",\"@menu\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"nav-menu\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"menus/nav-menu/nav-item\",[],[[\"@item\",\"@selected\",\"@translate\",\"@errors\",\"@onSelect\"],[[23,1,[]],[23,0,[\"selected\"]],[23,0,[\"translate\"]],[23,2,[]],[28,\"fn\",[[23,0,[\"selectItem\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-menu/template.hbs"
    }
  });
});