define("client/pods/components/extensions/apps/tw/t-cat/sender-list-btn/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwTCatSenderListBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('dispatch'), _dec4 = Ember.inject.service('constants'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _class = class ExtensionsAppsTwTCatSenderListBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "dispatchService", _descriptor3, this);
      _initializerDefineProperty(this, "constantsService", _descriptor4, this);
      _initializerDefineProperty(this, "selectedSenderName", _descriptor5, this);
      _initializerDefineProperty(this, "selectedPersonId", _descriptor6, this);
      _initializerDefineProperty(this, "transporterModel", _descriptor7, this);
      _initializerDefineProperty(this, "useDefaults", _descriptor8, this);
      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time']);
      this.transporterModel = this.args?.transporterModel;
    }
    get isLoading() {
      const model = this.args?.model || false;
      const transporterModel = this.args?.transporterModel || this.transporterModel || false;
      if (!model || !transporterModel) {
        return true;
      }
      return false;
    }
    get listSelectorValueKey() {
      const listSelectorValueKey = this.args.listSelectorValueKey;
      if (listSelectorValueKey) {
        return listSelectorValueKey;
      }
      return '_data.defaultPickupLocationPersonId';
    }

    /**
     * Return a list of pickup locations
     * @return {Array<{
     * transporterSenderName: string,
     * transporterSenderTelephone: string,
     * transporterSenderAddress: string,
     * transporterDeliveryTime: string,
     * transporterPackageTemperature: string,
     * transporterPackageSize: string,
     * transporterPackageType: string,
     * transporterNotes: string,
     * personId: string,
     * name: string,
     * telephone: string,
     * address: string,
     * label: string,
     * model: Object
     * }>}
     */
    get pickupLocationsList() {
      const persons = this.transporterModel?._data?.persons || [];
      const pickupLocations = R.filter(R.pathEq(['_data', 'type'], 'pickupLocation'))(persons);
      const findDetailByType = (details, type) => {
        const detailType = details.find(detail => detail?._data?.subType === type) || {};
        return detailType?._data?.value;
      };
      return R.map(pickupLocation => {
        const name = pickupLocation?._data?.name || '';
        const personId = pickupLocation?._data?.personId || '';
        const details = pickupLocation?._data?.details || [];
        const telephone = findDetailByType(details, 'telephone');
        const address = findDetailByType(details, 'address');
        const transporterSenderName = name;
        const transporterSenderTelephone = findDetailByType(details, 'telephone');
        const transporterSenderAddress = findDetailByType(details, 'address');
        const transporterDeliveryTime = findDetailByType(details, 'deliveryTime');
        const transporterPackageTemperature = findDetailByType(details, 'transporterAccount');
        const transporterPackageSize = findDetailByType(details, 'packageSize');
        const transporterPackageType = findDetailByType(details, 'packageType');
        const transporterNotes = findDetailByType(details, 'note');
        return {
          transporterSenderName,
          transporterSenderTelephone,
          transporterSenderAddress,
          transporterDeliveryTime,
          transporterPackageTemperature,
          transporterPackageSize,
          transporterPackageType,
          transporterNotes,
          personId,
          name,
          telephone,
          address,
          label: `<span>${this.intl.t('name')} : ${name} <br> ${this.intl.t('telephone')}: ${telephone} <br> ${this.intl.t('address')} : ${address}</span>`,
          model: pickupLocation
        };
      })(pickupLocations);
    }
    get defaultPickupLocation() {
      const selectedPersonId = this.selectedPersonId;
      const useDefaults = this.args?.model?._data?.transporterUseDefaultPickupLocation;
      if (useDefaults) {
        const defaultPersonId = this.transporterModel?._data?.defaultPickupLocationPersonId;
        if (defaultPersonId) {
          return this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === defaultPersonId);
        }
        return this?.transporterModel?.transporterData;
      }
      const pathArray = R.split('.')(this.listSelectorValueKey);
      let defaultPersonId = R.path(pathArray)(this.args?.model) || selectedPersonId || this.selectedSenderName;
      if (!defaultPersonId) {
        defaultPersonId = this?.transporterModel?._data?.defaultPickupLocationPersonId;
      }
      if (defaultPersonId) {
        return this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === defaultPersonId);
      }
      return this?.transporterModel?.transporterData;
    }
    *fetchTransporterTask() {
      const transporterModel = this.args?.transporterModel;
      this.transporterModel = transporterModel;
      if (!transporterModel) {
        const isTransporter = this.args.model._data.transporterExtension === this.constantsService.transporterExtensions.tCat;
        const transporterKey = this.args.model._data.transporterKey;
        if (isTransporter && !transporterKey) {
          this.transporterModel = this.args.model;
        } else {
          this.transporterModel = yield this.dispatchService.tCatGetModel.perform({
            transporterKey
          });
        }
      }
      this.setTransporterSenderDefaults();
    }
    afterLoadLists() {
      if (this.args.fetchTransporter !== false) {
        this.fetchTransporterTask.perform();
      }
    }
    setTransporterSenderDefaults(model = {}) {
      let personId = this.transporterModel?._data?.defaultPickupLocationPersonId || '';
      const defaultPersonId = this.args.defaultPersonId || '';
      if (!this.selectedSenderName && defaultPersonId) {
        personId = defaultPersonId;
      }
      let sender = this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === personId);
      if (this.selectedSenderName !== sender?.name && defaultPersonId) {
        sender = this.pickupLocationsList.find(pickupLocation => pickupLocation.personId === defaultPersonId);
      }
      if (!sender?.personId) {
        this.selectedSenderName = '';
        return;
      }
      this.onSelectAction(sender);
    }
    onSelectAction(sender) {
      this.selectedPersonId = sender?.personId;
      this.selectedSenderName = sender?.name;
      if (this.args?.isReadonly) {
        return;
      }
      const onSelect = this.args.onSelect;
      if (onSelect) {
        this.args.onSelect(sender);
      }
    }
    autoSelectOnFirstPerson(model = {}, onCloseDialogAction = fn => fn) {
      if (this.pickupLocationsList.length === 1) {
        const defaultPickupLocationPersonId = this.pickupLocationsList[0].personId;
        model.set('_data.defaultPickupLocationPersonId', defaultPickupLocationPersonId);
        model.set('_data.transporterDefaultPickupLocationPersonId', defaultPickupLocationPersonId);
        this.transporterModel = model;
        this.setTransporterSenderDefaults();
        this.args.onRefreshModel(model);
        onCloseDialogAction();
      }
    }
    refreshTransporterModel(model) {
      this.transporterModel = model;
    }
    setDefaults(toggle) {
      this.useDefaults = toggle;
      if (toggle) {
        this.selectedPersonId = this.transporterModel?._data?.defaultPickupLocationPersonId;
      } else {
        this.selectedSenderName = '';
        this.setTransporterSenderDefaults(this.args.model);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constantsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedSenderName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedPersonId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "useDefaults", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTransporterSenderDefaults", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setTransporterSenderDefaults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectAction", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoSelectOnFirstPerson", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "autoSelectOnFirstPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshTransporterModel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "refreshTransporterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaults", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaults"), _class.prototype), _class);
});