define("client/pods/grapesjs/plugins/slider-native/components/NavNext", ["exports", "client/pods/grapesjs/plugins/slider-native/constants", "client/pods/grapesjs/plugins/slider-native/utils"], function (_exports, _constants, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (dc, config = {}) => {
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const {
      nextName,
      nextId,
      sliderSelector
    } = _constants.default;
    const classId = config.classNext;
    const type = nextName;
    const {
      intl
    } = config;
    dc.addType(type, {
      model: defaultModel.extend({
        defaults: {
          ...defaultModel.prototype.defaults,
          name: intl.t('nav next'),
          copyable: 0,
          draggable: sliderSelector,
          selectable: false,
          droppable: false,
          layerable: false,
          classes: ['lory-controls-arrows', 'lory-controls-arrows-right', 'lory-controls-arrows-color', 'size-small'],
          style: {
            // position: 'absolute',
            // display: 'block',
            // cursor: 'pointer',
            // top: '50%',
            // right: 0,
            // 'margin-top': '-25px'
          },
          ...config.nextProps
        },
        init() {
          this.get('classes').pluck('name').indexOf(classId) < 0 && this.addClass(classId);
        }
      }, {
        isComponent(el) {
          if ((0, _utils.elHasClass)(el, classId)) return {
            type
          };
        }
      }),
      view: {
        ...defaultType.view,
        // Add easily component specific listeners with `events`
        // Being component specific (eg. you can't attach here listeners to window)
        // you don't need to care about removing them when the component is removed,
        // they will be managed automatically by the editor
        events: {
          click: 'selectSlider'
        },
        selectSlider(event) {
          try {
            event.stopPropagation();
            const slider = this.model.parent();
            const editor = this.model.em;
            editor.setSelected(slider);
          } catch (error) {
            console.error('ERROR WHILE SELECTING SLIDER', error);
          }
        }
      }
    });
  };
  _exports.default = _default;
});