define("client/pods/components/helpers/drag-and-drop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M/h8Zk1j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/drag-and-drop/template.hbs"
    }
  });
});