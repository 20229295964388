define("client/pods/documents/data/pending/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    idParam: false,
    modelObj: _model.default,
    findAll({
      data = {},
      appendPath,
      options,
      serverType
    } = {}) {
      const docType = options.docType;
      const docName = options.docName;
      const customData = {
        list: [{
          component: 'documents/pending/panel-display-by-products',
          resource: `/api/protected/documents/${docType}/${docName}`,
          icon: 'fas fa-boxes',
          label: 'by products',
          type: 'byProducts',
          combined: true,
          action: 'display',
          className: 'hide-when-overflow'
        }, {
          component: 'documents/pending/panel-display-by-orders',
          resource: `/api/protected/documents/${docType}/${docName}`,
          icon: 'far fa-file-alt',
          label: 'by orders',
          type: 'byOrders',
          combined: false,
          action: 'display',
          className: 'hide-when-overflow'
        }]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});