define("client/pods/components/layouts/left-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hRTXmCPU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isShowing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layouts__left-bar\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layouts__left-bar-contents\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"modals/modal-overlay\",null,[[\"toggle\",\"modalClassNames\"],[[24,[\"isShowing\"]],\"layouts__left-bar-modal-overlay\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/left-bar/template.hbs"
    }
  });
});