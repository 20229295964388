define("client/pods/workflows/items/activities/collection/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/constants/index"], function (_exports, _model, R, RA, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data = {}) {
      data.collection = this._transformCollection(data);
      this._super(...arguments);
    },
    allActivitiesCompletes: Ember.computed('synced', '_data.{collection.@each.synced}', function () {
      const itemsActivities = R.pathOr([], ['_data', 'collection'])(this);
      const allActivitiesCompleted = R.pipe(R.map(R.pathOr(false, ['_data', 'isComplete'])), R.all(R.equals(true)))(itemsActivities);
      return allActivitiesCompleted;
    }),
    hasAuthorizationType: Ember.computed('synced', '_data.{collection.@each.synced}', function () {
      const itemsActivities = R.pathOr([], ['_data', 'collection'])(this);
      const allActivitiesCompleted = R.pipe(R.map(R.pathOr('', ['_data', 'type'])), R.any(R.equals(_index.default.workflowsItemsActivitiesTypes.authorization)))(itemsActivities);
      return allActivitiesCompleted;
    }),
    _transformCollection(data) {
      return R.pipe(R.pathOr([], ['collection']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/items/activities',
          data
        });
      }))(data);
    },
    byStatus: Ember.computed('synced', '_data.{collection.@each.synced}', function () {
      const collection = this._data?.collection || [];
      const statuses = ['isPinned', 'isIncomplete', 'isComplete'];
      const groupingFn = activity => {
        if (activity._data?.isPinned) {
          return 'isPinned';
        }
        if (activity._data?.type === 'notes') {
          return 'isComplete';
        }
        if (activity._data?.isComplete) {
          return 'isComplete';
        }
        return 'isIncomplete';
      };
      const byStatus = R.groupBy(groupingFn)(collection);
      return R.pipe(R.map(status => {
        const activities = R.prop(status)(byStatus);
        if (activities) {
          return {
            status,
            activities
          };
        }
        return [];
      }), R.reject(RA.isNilOrEmpty))(statuses);
    })
  });
});