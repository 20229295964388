define("client/pods/channels/shop/campaigns/automations/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/sortable-details"], function (_exports, R, _model, _sortableDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend(_sortableDetails.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    toSortProp: '_data.details',
    init() {
      this._super(...arguments);
      this.set('adapters', {
        products: 'products',
        details: 'channels/shop/campaigns/automations/details'
      });
      this.crud.addLists(this, ['automations/criterias']);
    },
    defaults() {
      return {
        when: this.get('constants.automationsWhen.orderValue'),
        whenItemQtyType: this.get('constants.automationsWhenItemQtyType.products'),
        whenItemQtyTags: [],
        criteria: this.get('constants.automationsCriterias.greaterThanOrEqualTo'),
        thenAction: this.get('constants.automationsThenActions.mustAdd'),
        thenSelectionType: this.get('constants.automationsThenSelectionTypes.all'),
        thenSelectionQty: 1,
        status: this.get('constants.status.active'),
        isCumulative: false,
        automationsShippingBehavior: this.settings.getProp('automationsShippingBehavior')
      };
    },
    populate(data, attrs) {
      data.details = this._transformDetails(data.details, attrs);
      this._super(data, attrs);
    },
    _transformDetails(details = [], attrs = {}) {
      return details.map(detail => {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.details'),
          data: detail,
          attrs
        });
      });
    },
    hasDetails: Ember.computed('_data.details.@each.synced', function () {
      let details = this.get('_data.details') || [];
      details = R.reject(R.propEq('isDeleted', true))(details);
      if (R.isEmpty(details)) {
        return false;
      }
      return true;
    }),
    isAutoAdd: Ember.computed('_data.thenAction', function () {
      return this.get('_data.thenAction') === this.get('constants.automationsThenActions.mustAdd');
    }),
    isMayAdd: Ember.computed('_data.thenAction', function () {
      return this.get('_data.thenAction') === this.get('constants.automationsThenActions.mayAdd');
    }),
    isChooseBetween: Ember.computed('_data.thenSelectionType', function () {
      return this.get('_data.thenSelectionType') === this.get('constants.automationsThenSelectionTypes.between');
    }),
    isItemQtyProducts: Ember.computed('_data.{when,whenItemQtyType}', function () {
      const when = this?._data?.when || '';
      const whenItemQtyType = this?._data?.whenItemQtyType || '';
      return when === 'itemQty' && whenItemQtyType === 'products';
    }),
    isItemQtyTags: Ember.computed('_data.{when,whenItemQtyType}', function () {
      const when = this?._data?.when || '';
      const whenItemQtyType = this?._data?.whenItemQtyType || '';
      return when === 'itemQty' && whenItemQtyType === 'tags';
    }),
    isCriteriaBetween: Ember.computed('_data.criteria', function () {
      const criteria = this?._data?.criteria || '';
      return criteria === 'isBetween';
    }),
    isAllowCumulative: Ember.computed('isAutoAdd', '_data.criteria', function () {
      const isAutoAdd = this?.isAutoAdd || false;
      const criteria = this?._data?.criteria;
      const criterias = this.crud.lists['automations-criterias'] || [];
      const selectedCriteria = R.find(R.pathEq(['_data', 'value'], criteria))(criterias);
      const isAllowCumulative = selectedCriteria?._data?.isAllowCumulative || false;
      if (isAutoAdd && isAllowCumulative) {
        return true;
      }
      return false;
    }),
    setCumulative(model, selected) {
      const isAllowCumulative = selected?._data?.isAllowCumulative || false;
      if (!isAllowCumulative) {
        model.set('_data.isCumulative', false);
        model.set('_data.cumulativeValue', '');
      }
    },
    setCumulativeValue(model, value) {
      const isAllowCumulative = this.isAllowCumulative;
      if (isAllowCumulative) {
        model.set('_data.cumulativeValue', value);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "setCumulative", [_dec], Object.getOwnPropertyDescriptor(_obj, "setCumulative"), _obj), _applyDecoratedDescriptor(_obj, "setCumulativeValue", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setCumulativeValue"), _obj), _obj));
});