define("client/pods/components/contacts/contacts-info-accordian/component", ["exports", "client/mixins/search-contacts", "client/mixins/editor", "client/mixins/transporter", "ramda", "ramda-adjunct"], function (_exports, _searchContacts, _editor, _transporter, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_searchContacts.default, _editor.default, _transporter.default, {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    documentsService: Ember.inject.service('documents'),
    tagName: '',
    // isValidating=true when an input is busy validating
    isValidating: false,
    disableOnProcessing: false,
    disabled: false,
    translate: true,
    isEditing: false,
    disable: false,
    isRendered: false,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['statuses', 'contacts/lists/taxMethods', 'contacts/lists/relationships', 'contacts/lists/detailTypes', 'extensions/apps/tw/invoicingAuto', 'paymentMethods', 'contacts/transporters', 'paymentTerms', 'users', 'transporter', 'extensions/apps/tw/e-invoices/carrier-types']);
    },
    didRender() {
      this._super(...arguments);
      this.set('isRendered', true);
    },
    /**
     * only focus child components when component has finished rendering.
     * eg. when creating new contact it will autofocus on first input,
     * when editing, child components with autofocus
     * should not override first inputs autofocus
     */
    autofocusComponents: Ember.computed('isRendered', function () {
      if (this.isRendered) {
        return true;
      }
      return false;
    }),
    // hasSales: computed('model._data.relationships.[]', function () {
    //   const model = this.get('model')
    //   const relationships = model.getData('relationships') || []
    //   if (relationships.indexOf('customer') > -1) {
    //     return true
    //   }

    //   return false
    // }),

    // hasPurchases: computed('model._data.relationships.[]', function () {
    //   const model = this.get('model')
    //   const relationships = model.getData('relationships') || []

    //   if (relationships.indexOf('supplier') > -1) {
    //     return true
    //   }

    //   return false
    // }),

    isTransporter: Ember.computed('model._data.isTransporter', function () {
      if (this.get('model._data.isTransporter') === this.get('constants.isTransporter.on')) {
        return true;
      }
      return false;
    }),
    salesPaymentMethodLabelPrefix: Ember.computed('model._data.salesPaymentMethodCustomLabel', function () {
      const salesPaymentMethodCustomLabel = R.pathOr('', ['model', '_data', 'salesPaymentMethodCustomLabel'])(this);
      if (RA.isNotNilOrEmpty(salesPaymentMethodCustomLabel)) {
        return `${salesPaymentMethodCustomLabel} (`;
      }
      return '';
    }),
    salesPaymentMethodLabelSuffix: Ember.computed('model._data.salesPaymentMethodCustomLabel', function () {
      const salesPaymentMethodCustomLabel = R.pathOr('', ['model', '_data', 'salesPaymentMethodCustomLabel'])(this);
      if (RA.isNotNilOrEmpty(salesPaymentMethodCustomLabel)) {
        return ')';
      }
      return '';
    }),
    purchasesPaymentMethodLabelPrefix: Ember.computed('model._data.purchasesPaymentMethodCustomLabel', function () {
      const purchasesPaymentMethodCustomLabel = R.pathOr('', ['model', '_data', 'purchasesPaymentMethodCustomLabel'])(this);
      if (RA.isNotNilOrEmpty(purchasesPaymentMethodCustomLabel)) {
        return `${purchasesPaymentMethodCustomLabel} (`;
      }
      return '';
    }),
    purchasesPaymentMethodLabelSuffix: Ember.computed('model._data.purchasesPaymentMethodCustomLabel', function () {
      const purchasesPaymentMethodCustomLabel = R.pathOr('', ['model', '_data', 'purchasesPaymentMethodCustomLabel'])(this);
      if (RA.isNotNilOrEmpty(purchasesPaymentMethodCustomLabel)) {
        return ')';
      }
      return '';
    }),
    accountsEditorComponent: Ember.computed('model._data.transporterExtension', function () {
      const transporterExtension = this.model?._data?.transporterExtension || '';
      if (transporterExtension === this.constants.transporterExtensions.sevenEleven) {
        return 'extensions/apps/tw/seven-eleven/accounts-editor';
      }
      return 'contacts/transporters-accounts-editor';
    }),
    // determines if min form requirements are met
    isMinRequiredMet: Ember.computed('model._data.name', function () {
      const minRequired = ['name'];
      const model = this.model;
      let met = true;
      minRequired.forEach(function (required) {
        if (Ember.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }),
    /**
     * assign sales rep to contact
     */
    assignSalesPerson(person) {
      const model = this.model;
      model.addSalesPerson(person);
    },
    /**
     * assign sales rep to contact
     */
    unassignSalesPerson(person) {
      const model = this.model;
      model.removeSalesPerson(person);
    },
    unloadPaymentTerm(type) {
      this.model.setData(`${type}PaymentTerms`, '');
      this.model.setData(`${type}PaymentTermsKey`, '');
    },
    actions: {
      loadPaymentMethod(type, paymentMethod) {
        if (paymentMethod) {
          // check paymentMethod selection to determine if has payment terms
          const hasPaymentTerms = paymentMethod.getData('hasPaymentTerms');
          this.model.setData(`${type}HasPaymentTerms`, hasPaymentTerms);
          const paymentMethodIsNotDefault = R.pipe(R.hasPath(['_data', 'isNotEditable']), R.not)(paymentMethod);
          if (paymentMethodIsNotDefault) {
            const paymentMethodCustomLabel = paymentMethod.getData('customLabel');
            this.model.setData(`${type}PaymentMethodCustomLabel`, paymentMethodCustomLabel);
          } else {
            this.model.setData(`${type}PaymentMethodCustomLabel`, '');
            delete this.model._data[`${type}PaymentMethodCustomLabel`];
            this.model.updateSynced();
          }
          if (!hasPaymentTerms) {
            this.unloadPaymentTerm(type);
          }
        }
      },
      loadPaymentTerm(type, paymentTerm) {
        this.model.setData(`${type}PaymentTerms`, paymentTerm.getData('label'));
      }

      // addTransporterAccount (transporterAccount) {
      //   const model = this.get('model')
      //   const transporterAccounts = model.getData('transporterAccounts') || []
      //   transporterAccounts.pushObject(transporterAccount)
      //   model.setData('transporterAccounts', transporterAccounts)
      // },
      // updateTransporterAccount () {

      // }

      // loadAccount (type, contact) {
      //   const key = contact.getData('_key')
      //   const model = this.get('model')
      //   model.setData(`${type}AccountKey`, key)

      //   model.setData(`${type}PaymentMethodKey`, contact.getData(`${type}PaymentMethodKey`))
      //   model.setData(`${type}HasPaymentTerms`, contact.getData(`${type}HasPaymentTerms`))
      //   model.setData(`${type}PaymentTermsKey`, contact.getData(`${type}PaymentTermsKey`))
      //   model.setData(`${type}PaymentTerms`, contact.getData(`${type}paymentTerms`))
      // },

      // unloadAccount (type) {
      //   const model = this.get('model')
      //   model.setData(`${type}AccountKey`, '')
      // }

      // loadTransporter(contact) {
      //   // @TODO TEST
      //
      //   console.log('---> hey im here');
      //   const key = contact.getData('_key');
      //   const  model = this.get('model');
      //   model.setData('transporterKey', key);
      // },
      //
      // unloadTransporter() {
      //
      //   const model = this.get('model');
      //   model.setData('transporterKey', '');
      // }
    }
  });
});