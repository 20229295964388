define("client/pods/components/comments/comments-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bZt3xxXJ",
    "block": "{\"symbols\":[\"@type\",\"@tab\",\"@subType\",\"@metaData\",\"@master\",\"@hasCommentEditorFooter\",\"@isPublic\",\"@contactKey\",\"@contact\",\"@btnLabel\",\"@btnIcon\",\"@componentName\"],\"statements\":[[4,\"if\",[[23,0,[\"isAllowed\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,12,[]]],[[\"commentsManager\",\"options\"],[[23,0,[]],[28,\"hash\",null,[[\"adapterName\",\"btnIcon\",\"btnLabel\",\"btnTooltipLabel\",\"contact\",\"contactKey\",\"isPublic\",\"hasCommentEditorFooter\",\"master\",\"metaData\",\"subType\",\"tab\",\"type\"],[[23,0,[\"adapterName\"]],[23,11,[]],[23,10,[]],[23,0,[\"_btnTooltipLabel\"]],[23,9,[]],[23,8,[]],[23,7,[]],[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/comments/comments-manager/template.hbs"
    }
  });
});