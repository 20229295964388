define("client/helpers/badgeCount", ["exports", "client/mixins/big", "ramda", "ramda-adjunct"], function (_exports, _big, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    intl: Ember.inject.service(),
    compute(params, hash) {
      const array = params[0] || [];
      return R.pipe(R.reject(RA.isNilOrEmpty), R.length)(array);
    }
  });
});