define("client/pods/components/importer/import-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ImporterDataImportComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class ImporterDataImportComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dataManager", _descriptor, this);
      _initializerDefineProperty(this, "adapterName", _descriptor2, this);
      _initializerDefineProperty(this, "batchString", _descriptor3, this);
      _initializerDefineProperty(this, "showImportModal", _descriptor4, this);
      _initializerDefineProperty(this, "rowsData", _descriptor5, this);
      _initializerDefineProperty(this, "response", _descriptor6, this);
      this.adapterName = this.args.adapterName;
      this.columns = this.args.columns;
      this.response = false;
    }
    toggleImportModal() {
      this.showImportModal = !this.showImportModal;
    }
    next(onCloseDialogAction) {
      this.response = false;
      const batchDataString = this.batchString || '';
      // let batchData = {}
      // try {
      //   // data is json
      //   batchData = JSON.parse(batchDataString)
      // } catch (err) {
      // convert lines to json

      const byRows = R.pipe(R.trim, R.split(/\n/g), R.reject(RA.isNilOrEmpty), R.map(R.trim), R.map(R.split(/\t/g)))(batchDataString);
      const possibleColumns = _nventor.default.safeHeadOr([])(byRows);
      if (RA.isNilOrEmpty(possibleColumns)) {
        return {};
      }
      this.rowsData = byRows;
      // }
      onCloseDialogAction();
      this.showImportModal = true;
    }
    cancel() {
      this.batchString = '';
    }
    *importTask() {
      const rowsData = this.rowsData;
      const columnsString = this.columns || '';
      const columns = R.split(' ')(columnsString);
      const batchData = R.map(row => {
        return R.zipObj(columns)(row);
      })(rowsData);
      const self = this;
      return this.dataManager.batchImport({
        adapterName: this.adapterName,
        batchData
      }).then(response => {
        this.response = response;
        this.batchString = '';
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adapterName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "batchString", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showImportModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rowsData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "response", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleImportModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleImportModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "importTask"), _class.prototype), _class);
});