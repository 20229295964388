define("client/pods/components/store-location/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CVfwAdKZ",
    "block": "{\"symbols\":[\"@tab\",\"@errors\",\"@onCancel\",\"@onDash\",\"@onDisplay\"],\"statements\":[[5,\"store-location/store-location-editor\",[],[[\"@tab\",\"@errors\",\"@isDisabled\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@model\",\"@onCancel\",\"@onDash\",\"@onDisplay\",\"@saveTask\"],[[23,1,[]],[23,2,[]],false,true,false,false,[23,0,[\"dirty\"]],[23,3,[]],[23,4,[]],[23,5,[]],[23,0,[\"crud\",\"updateRecordTask\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/store-location/panels-edit/template.hbs"
    }
  });
});