define("client/pods/components/menus/nav-menu/nav-item/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MenusNavMenuNavItem = _exports.default = (_dec = Ember._action, _class = class MenusNavMenuNavItem extends _component.default {
    get isDisabled() {
      const enable = this.args?.item?._data?.enable || true;
      return !enable;
    }
    get isActive() {
      return this.args.selected === this.args.item;
    }
    select(item) {
      this.args.onSelect(item);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "select", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
});