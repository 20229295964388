define("client/pods/products/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/mixins/sortable-details", "client/mixins/products-children", "client/mixins/uploads", "client/mixins/templates", "client/pods/faqs/model", "client/utils/nventor"], function (_exports, R, RA, _model, _sortableDetails, _productsChildren, _uploads, _templates, _model2, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend(_productsChildren.default, _sortableDetails.default, _templates.default, _uploads.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _obj = {
    toSortProp: '_data.setDetails',
    init() {
      this._super(...arguments);
      this.set('importParams', [{
        label: 'name',
        value: 'name'
      }]);
      this.set('setDetailsRequiredAttrs', ['item', 'itemKey', 'qty', 'status']);
      this.set('childAdapters', {
        choices: 'products/choices',
        'choices-details': 'products/choices/details',
        'sets-details': 'products/sets/details',
        variants: 'products/variants',
        'variants-options': 'products/variants/options',
        'variants-children': 'products/variants/children',
        'customizations': 'products/customizations',
        'customizations-options': 'products/customizations/options'
      });
    },
    defaults() {
      const settings = this.settings;
      const resourceKeyTemp = _nventor.default.random.alphaNum(8);
      const defaultData = {
        type: settings.getProp('inventoryType', this.get('constants.productTypes.inventory')),
        setType: '',
        tracking: settings.getProp('inventoryTracking', this.get('constants.productTracking.on')),
        status: this.get('constants.status.active'),
        salesTaxRateKey: this.get('constants.taxes.auto'),
        purchasesTaxRateKey: this.get('constants.taxes.auto'),
        weightUnit: settings.getProp('weightUnit', 'g'),
        packagingDimensionsUnit: settings.getProp('packagingDimensionsUnit', 'cm'),
        recurringSchedulesEnabled: false,
        recurringSchedulesOnly: false,
        recurringSchedules: [],
        setDetails: [],
        choices: [],
        variants: [],
        faqs: [],
        images: [],
        templates: [],
        productDisplayTags: [],
        seoKeywords: '',
        seoDescription: '',
        resourceKeyTemp,
        hasReminderLimitsQty: false,
        reminderLimitsQty: 1,
        barcodes: [{
          barcodeType: 'EAN13',
          barcode: ''
        }
        // {
        //   barcodeType: 'EAN8',
        //   barcode: ''
        // },
        // {
        //   barcodeType: 'EAN5',
        //   barcode: ''
        // },
        // {
        //   barcodeType: 'EAN2',
        //   barcode: ''
        // },
        // {
        //   barcodeType: 'CODE128',
        //   barcode: ''
        // },
        // {
        //   barcodeType: 'CODE39',
        //   barcode: ''
        // },
        ],
        specs: [],
        descriptionAttachments: [],
        virtualContentAttachments: [],
        tags: []
      };
      if (this.settings.isFeatureAllowed('features.isVirtualOnly')) {
        defaultData.type = settings.getProp('inventoryType', this.get('constants.productTypes.virtual'));
        defaultData.tracking = settings.getProp('inventoryTracking', this.get('constants.productTracking.off'));
      }
      return defaultData;
    },
    toSortArray: Ember.computed('_data.setDetails.[]', 'synced', function () {
      return this.get('_data.setDetails');
    }),
    isDeletedOrInactive: Ember.computed('isDeleted', '_data.status', function () {
      return this.isDeleted || R.equals(this?._data?.status, 'inactive');
    }),
    hasSetType: Ember.computed('_data.{type,isVariantChild}', function () {
      const type = this?._data?.type;
      if (type === this.constants.productTypes.virtual) {
        return false;
      }
      const isVariantChild = this?._data?.isVariantChild;
      if (isVariantChild) {
        return false;
      }
      return true;
    }),
    populate(data = {}) {
      data = this.setupWithDefaults(data);
      if (data.tracking === this.get('constants.togglesOnOff.on') && RA.isNotNilOrEmpty(data.inventory)) {
        const inventory = this.transformInventory(data);
        this.set('inventory', inventory);
      }
      if (RA.isNotNilOrEmpty(data.inventoryReport)) {
        const inventoryReport = this.transformInventoryReport(data);
        this.set('inventoryReport', inventoryReport);
      }
      data.recurringSchedules = this._transformRecurringSchedules(data);
      data.campaignDetails = this._transformCampaignDetails(data);
      data.customizations = this._transformCustomizations(data);
      data.barcodes = this._transformBarcodes(data);
      data.faqs = this._transformFaqs(data);
      data.templates = this._transformTemplates(data);
      if (RA.isNilOrEmpty(data.templates)) {
        data.templates.pushObject(this.dataManager.setAsRecord({
          adapterName: 'products/templates',
          data: this.getTemplateHash('')
        }));
      }
      data.productDisplayTags = this._transformProductDisplayTags(data);
      data.descriptionAttachments = this._transformAttachments('descriptionAttachments', data);
      data.virtualContentAttachments = this._transformAttachments('virtualContentAttachments', data);
      data.specs = this._transformSpecs(data);
      this._super(...arguments);
    },
    _transformSpecs(data) {
      let index = 0;
      return R.pipe(R.propOr([], 'specs'), R.map(spec => {
        spec.index = index;
        index = index + 1;
        return this._newAttachmentModel(spec);
      }))(data);
    },
    _transformAttachments(prop, data) {
      let index = 0;
      return R.pipe(R.propOr([], prop), R.map(attachment => {
        attachment.index = index;
        index = index + 1;
        return this._newAttachmentModel(attachment);
      }))(data);
    },
    _transformBarcodes(data) {
      return R.pipe(R.propOr([], 'barcodes'), R.map(barcodeData => {
        return this.dataManager.setAsRecord({
          adapterName: 'products/barcodes',
          data: barcodeData
        });
      }))(data);
    },
    _transformRecurringSchedules(data) {
      return R.pipe(R.propOr([], 'recurringSchedules'), R.map(schedule => this.dataManager.setAsRecord({
        adapterName: 'recurring/schedules',
        data: schedule
      })))(data);
    },
    _transformCampaignDetails(data) {
      const campaignDetails = data?.campaignDetails || [];
      if (RA.isNotNilOrEmpty(campaignDetails)) {
        const campaignDetailsArr = R.map(campaignDetail => {
          return this.dataManager.newRecord({
            adapterName: 'products/lists/campaigns',
            data: campaignDetail
          });
        })(campaignDetails);
        this.set('campaignDetailsArr', campaignDetailsArr);
        delete data.campaignDetails;
      }
    },
    /**
     * Set correct indexes on load
     * @param data
     * @returns {any[]}
     * @private
     */
    _transformCustomizations(data) {
      const customizations = data?.customizations || [];
      if (RA.isNilOrEmpty(customizations)) {
        return customizations;
      }
      return RA.mapIndexed((customization, index) => {
        customization.index = ++index;
        return customization;
      })(customizations);
    },
    _transformTemplates(data) {
      return R.pipe(R.pathOr([], ['templates']), R.map(template => this.dataManager.setAsRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash(template)
      })))(data);
    },
    getTemplateHash(args = {}) {
      const {
        template,
        header,
        status
      } = args;
      return {
        component: 'products/products-editor/template',
        get label() {
          return this.header;
        },
        action: 'new',
        tabType: 'reuseSubTab',
        header,
        template,
        status
      };
    },
    removeBarcode(selectedBarcodeData) {
      const barcodes = this?._data?.barcodes || [];
      barcodes.removeObject(selectedBarcodeData);
    },
    addNewBarcode(onHideDropdown, selectedBarcodeData) {
      const barcodeType = selectedBarcodeData?._data?.value;
      if (!barcodeType) {
        onHideDropdown();
        return;
      }
      const barcodeData = {
        barcodeType,
        barcode: ''
      };
      const barcodeModel = this.dataManager.newRecord({
        adapterName: 'products/barcodes',
        data: barcodeData
      });
      const barcodes = this?._data?.barcodes || [];
      barcodes.pushObject(barcodeModel);
      onHideDropdown();
      return barcodeModel;
    },
    addNewTemplate() {
      const newTemplate = this.dataManager.newRecord({
        adapterName: 'products/templates',
        data: this.getTemplateHash()
      });
      if (!newTemplate?.instanceId) {
        newTemplate.instanceId = this?.instanceId;
      }
      this?._data?.templates?.pushObject(newTemplate);
      return newTemplate;
    },
    removeTemplate(template) {
      if (template._data) {
        this?._data?.templates?.removeObject(template);
      } else {
        const header = R.propOr('', 'header')(template);
        const templateModel = R.pipe(R.pathOr([], ['_data', 'templates']), R.find(R.pathEq(['_data', 'header'], header)))(this);
        this?._data?.templates?.removeObject(templateModel);
      }
    },
    _transformProductDisplayTags(data) {
      const productDisplayTags = R.propOr([], 'productDisplayTags')(data);
      return R.map(productDisplayTag => {
        return this.dataManager.setAsRecord({
          adapterName: 'products/product-display-tags',
          data: productDisplayTag
        });
      })(productDisplayTags);
    },
    addProductDisplayTags(productDisplayTag = {}) {
      const productDisplayTags = this.getData('productDisplayTags') || [];
      const productDisplayTagModel = this.dataManager.setAsRecord({
        adapterName: 'products/product-display-tags',
        data: productDisplayTag
      });
      productDisplayTags.pushObject(productDisplayTagModel);
      this.setData('productDisplayTags', productDisplayTags);
    },
    _transformFaqs(data) {
      return R.pipe(R.propOr([], 'faqs'), R.reject(_nventor.default.isNilOrEmpty), R.map(faq => _nventor.default.createModel(_model2.default, faq)))(data);
    },
    hasDuplicateSetDetailsProduct(product) {
      const details = this.get('_data.setDetails') || [];
      const found = details.filterBy('_data.itemKey', product.getData('_key'));
      if (found.length > 1) {
        return true;
      }
      const itself = details.filterBy('_data.itemKey', this.getData('_key'));
      if (itself.length > 0) {
        return true;
      }
      return false;
    },
    hasDuplicateSetWithChoicesDetailsProduct(product) {
      const details = R.pipe(R.pathOr([], ['_data', 'choices']), R.pluck('_data'), R.pluck('details'), R.flatten)(this);
      const found = details.filterBy('_data.itemKey', product.getData('_key'));
      if (found.length > 1) {
        return true;
      }
      const itself = details.filterBy('_data.itemKey', this.getData('_key'));
      if (itself.length > 0) {
        return true;
      }
      return false;
    },
    loadChildProduct(detail, product) {
      const productData = product.serialize({
        exclude: ['_key', '_rev', '_id']
      });
      productData.variantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(child => {
        child.item = child.name;
        child.itemKey = child._key;
        return child;
      }))(productData);
      productData.setDetails = R.propOr([], 'setDetails')(productData);
      const itemData = detail.serialize();
      itemData.item = productData.name;
      itemData.productTags = productData.tags;
      itemData.roundingInventoryQty = productData.roundingInventoryQty || this.settings.getProp('roundingInventoryQty');
      itemData.roundingInventoryPrice = productData.roundingInventoryPrice || this.settings.getProp('roundingInventoryPrice');
      productData.salesInclPrice = '';
      productData.salesExclPrice = '';
      productData.purchasesInclPrice = '';
      productData.purchasesExclPrice = '';
      const newItemData = R.mergeRight(itemData, productData);
      detail.populate(newItemData);
    },
    hasInventory: Ember.computed('_data.{tracking,type}', function () {
      const type = this.get('_data.type');
      if (type === this.get('constants.productTypes.service')) {
        return false;
      }
      const tracking = this.get('_data.tracking');
      if (tracking === this.get('constants.productTracking.on')) {
        return true;
      }
      return false;
    }),
    // campaignDetailsArr: computed('_data.campaignDetails', function () {
    //   return R.pathOr([], ['_data', 'campaignDetails'])(this)
    // }),

    hasManyCampaignDetails: Ember.computed('campaignDetailsArr.[]', function () {
      return R.pipe(R.pathOr([], ['campaignDetailsArr']), R.propSatisfies(R.gte(R.__, 3), 'length'))(this);
    }),
    transformInventory(rawData) {
      if (rawData.inventory) {
        const inventoryData = rawData.inventory;
        delete rawData.inventory;
        return this.dataManager.setAsRecord({
          adapterName: 'products/inventory',
          data: inventoryData
        });
      }
      return false;
    },
    transformInventoryReport(rawData) {
      if (rawData.inventoryReport) {
        const inventoryReportData = rawData.inventoryReport;
        delete rawData.inventoryReport;
        return this.dataManager.setAsRecord({
          adapterName: 'products/inventory/report',
          data: inventoryReportData
        });
      }
      return false;
    },
    newBlankSetDetail(newData) {
      const details = this.getOrSetData('setDetails', []);
      const lastDetail = details.get('lastObject');
      let mustAddDetail = true;
      if (lastDetail) {
        if (!lastDetail.getData('item') && !lastDetail.getData('itemCode')) {
          mustAddDetail = false;
        }
      }
      if (mustAddDetail) {
        return this._addSetDetail(newData);
      }
      return false;
    },
    /**
     * creates new detail row
     * @param {object} data (optional) - detail data
     * @return {object} detail object
     */
    _addSetDetail(data) {
      data = data || {};

      // create details array if it doesnt exist
      let details = this.getData('setDetails');
      if (!details) {
        details = [];
        this.setData('setDetails', details);
      }

      // only add index if not exists
      if (data.index == null) {
        const index = details.length + 1;
        data.index = index;
      }
      const detailModel = this.dataManager.setAsRecord({
        adapterName: this.get('childAdapters.sets-details'),
        data
      });
      // detailModel.set('requiredAttrs', this.get('setDetailsRequiredAttrs'))

      this.getData('setDetails').pushObject(detailModel);
      this.resetSortOrder();
      return detailModel;
    },
    removeSetDetail(detail) {
      const details = this.getData('setDetails');

      // only remove detail if current line is not last line
      if (details.get('lastObject').getData('index') === detail.getData('index')) {
        // do not remove detail, clear detail only
        this.resetSetDetail(detail);
      } else {
        details.removeObject(detail);
        this.resetIndexes(details);
      }
      return details;
    },
    removeAllSetDetails() {
      this.setData('setDetails', []);
      this.resetSortOrder();
    },
    /**
     * resets detail line to blank state
     */
    resetSetDetail(detail) {
      // @TODO move this to detail model!?

      const data = detail.get('_data');
      const keys = Object.keys(data);
      keys.forEach(function (key) {
        let allowReset = true;

        // @TODO: could set a disallowResetAttrs array,
        // @TODO: eg. disallowResetAttrs: ['index', 'deliveryDateZ']
        // @TODO: then it will not allow resetting those values
        const allowResetKey = R.anyPass([R.equals('index'), R.equals('status')])(key);
        if (allowResetKey) {
          allowReset = false;
        }
        if (allowReset) {
          detail.setData(key, '');
        }
      });
    },
    addVariant(currentVariant, data) {
      const variants = this.getData('variants') || [];
      const lastVariant = variants.get('lastObject');
      const lastIndex = R.path(['_data', 'index'], lastVariant);
      let addVariant = false;
      if (!currentVariant) {
        addVariant = true;
      } else {
        if (R.path(['_data', 'index'], currentVariant) === lastIndex) {
          addVariant = true;
        }
      }
      if (addVariant) {
        let index = 1;
        if (lastIndex) {
          index = parseInt(lastIndex) + 1;
        }
        const defaultData = {
          index,
          name: '',
          status: this.get('constants.status.active'),
          options: []
        };
        data = data || defaultData;
        const variantModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants'),
          data
        });
        variants.pushObject(variantModel);
        this.setData('variants', variants);
      }
      return data;
    },
    removeVariant(variant) {
      const variants = this.getData('variants') || [];
      variants.removeObject(variant);
      this.resetIndexes(variants);
      return variant;
    },
    addVariantOption(variant, currentOption, data) {
      const options = variant._data.options || [];
      const lastOption = options.get('lastObject');
      let addOption = false;
      if (!currentOption) {
        addOption = true;
      } else {
        if (R.path(['_data', 'index'], currentOption) === R.path(['_data', 'index'], lastOption)) {
          addOption = true;
        }
      }
      if (addOption) {
        let index = 1;
        if (lastOption) {
          const lastIndex = parseInt(lastOption._data.index) || 0;
          index = lastIndex + 1;
        }
        const defaultData = {
          index,
          name: '',
          status: this.get('constants.status.active')
        };
        data = data || defaultData;
        data.optionUid = _nventor.default.random.alphaNum(10);
        const detailModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants-options'),
          data
        });
        options.pushObject(detailModel);
        variant.setData('options', options);
        variant.resetIndexes();
        return data;
      }
      return false;
    },
    removeVariantOption(variant, option) {
      const options = variant._data.options || [];
      if (options.get('lastObject._data.index') === option._data.index) {
        // do not remove detail, clear detail only
        this.resetVariantOption(option);
      } else {
        options.removeObject(option);
        this.resetIndexes(options);
      }
      variant.resetIndexes();
      return variant;
    },
    resetVariantOption(option) {
      option.name = '';
      return option;
    },
    addCustomization(currentCustomizationOptions, data) {
      const customizations = this.getData('customizations') || [];
      const lastCustomization = customizations.get('lastObject');
      const lastIndex = R.path(['_data', 'index'], lastCustomization);
      let addCustomization = false;
      if (!currentCustomizationOptions) {
        addCustomization = true;
      } else {
        if (R.path(['_data', 'index'], currentCustomizationOptions) === lastIndex) {
          addCustomization = true;
        }
      }
      if (addCustomization) {
        let index = 1;
        if (lastIndex) {
          index = parseInt(lastIndex) + 1;
        }
        const defaultData = {
          index,
          name: '',
          status: this.get('constants.status.active'),
          options: []
        };
        data = data || defaultData;
        const customizationModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.customizations'),
          data
        });
        customizations.pushObject(customizationModel);
        this.setData('customizations', customizations);
      }
      return data;
    },
    removeCustomization(customization) {
      const customizations = this.getData('customizations') || [];
      customizations.removeObject(customization);
      this.resetIndexes(customizations);
      return customization;
    },
    addCustomizationOption(customization, currentOption, data) {
      const options = customization._data.options || [];
      const lastOption = options.get('lastObject');
      let addOption = false;
      if (!currentOption) {
        addOption = true;
      } else {
        if (R.path(['_data', 'index'], currentOption) === R.path(['_data', 'index'], lastOption)) {
          addOption = true;
        }
      }
      if (addOption) {
        let index = 1;
        if (lastOption) {
          const lastIndex = parseInt(lastOption._data.index) || 0;
          index = lastIndex + 1;
        }
        const defaultData = {
          index,
          name: '',
          status: this.get('constants.status.active')
        };
        data = data || defaultData;
        data.optionUid = _nventor.default.random.alphaNum(10);
        const optionModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.customizations-options'),
          data
        });
        options.pushObject(optionModel);
        customization.setData('options', options);
        customization.resetIndexes();
        return data;
      }
      return false;
    },
    removeCustomizationOption(customization, option) {
      const options = customization._data.options || [];
      if (options.get('lastObject._data.index') === option._data.index) {
        // do not remove detail, clear detail only
        this.resetCustomizationOption(option);
      } else {
        options.removeObject(option);
        this.resetIndexes(options);
      }
      customization.resetIndexes();
      return customization;
    },
    resetCustomizationOption(option) {
      option.name = '';
      return option;
    },
    filterEmptyCustomizations() {
      const filteredCustomizations = R.pipe(R.pathOr([], ['_data', 'customizations']), R.reduce((acc, customization) => {
        if (!customization?._data?.name) {
          return acc;
        }
        const filteredOptions = R.pipe(R.pathOr([], ['_data', 'options']), R.reduce((acc, customizationOption) => {
          if (!customizationOption?._data?.name) {
            return acc;
          }
          return acc.concat(customizationOption);
        }, []))(customization);
        const index = acc.length + 1;
        customization.setData('index', index);
        customization.setData('options', filteredOptions);
        return acc.concat(customization);
      }, []))(this);
      this.setData('customizations', filteredCustomizations);
    },
    addVariantChild(data, existingVariantChildren) {
      let found = R.find(R.pathEq(['_data', 'variantChildId'], data.variantChildId), existingVariantChildren);
      if (!found) {
        found = R.find(R.pathEq(['_data', 'variantChildUid'], data.variantChildUid), existingVariantChildren);
      }
      if (found) {
        const overwriteProps = ['salesExclPrice', 'salesInclPrice', 'purchasesExclPrice', 'purchasesInclPrice'];
        R.forEach(prop => {
          this.overwriteExisintVariantChildProp(found, prop, data[prop]);
        }, overwriteProps);
        const toSetProps = R.pipe(R.omit(overwriteProps), R.keys)(data);
        R.forEach(key => {
          const value = R.prop(key)(data);
          found.setData(key, value);
        })(toSetProps);
        return found;
      } else {
        const childModel = this.dataManager.setAsRecord({
          adapterName: this.get('childAdapters.variants-children'),
          data
        });
        existingVariantChildren.pushObject(childModel);
        this.setData('_createVariantChildren', existingVariantChildren);
        return childModel;
      }
    },
    overwriteExisintVariantChildProp: R.curry((existingChildModel, prop, value) => {
      if (!existingChildModel.getData(prop)) {
        existingChildModel.setData(prop, value);
      }
      return existingChildModel;
    }),
    filterEmptyVariants() {
      const variants = this.getData('variants') || [];
      R.pipe(R.map(variant => {
        // remove empty options
        R.pipe(R.pathOr([], ['_data', 'options']), R.reject(R.last), R.reject(option => {
          const optionName = option?._data?.name || '';
          if (RA.isNilOrEmpty(optionName)) {
            return false;
          }
          return true;
        }), R.forEach(option => {
          this.removeVariantOption(variant, option);
        }))(variant);
        const options = variant?._data?.options || [];
        if (RA.isNilOrEmpty(options)) {
          return variant;
        }
        const variantName = variant?._data?.name || '';
        if (RA.isNilOrEmpty(variantName)) {
          return variant;
        }
        return false;
      }), R.reject(R.equals(false)), R.forEach(variant => {
        this.removeVariant(variant);
      }))(variants);
    },
    updateVariantChildrenList({
      originalModel,
      autoFill = '',
      autoFillCommand = '',
      isNew = false,
      setInitialValue = false,
      onAfter
    }) {
      // when first calling this, originalModel must be supplied,
      // then on input, originalModel must not be supplied
      const variants = this.getData('variants') || [];
      const existingVariantChildren = this.getData('_createVariantChildren') || [];
      let originalVariantChildren;
      if (RA.isNotNilOrEmpty(originalModel)) {
        originalVariantChildren = R.pipe(R.pathOr([], ['_data', 'variantChildren'])
        // R.filter(R.pathEq(['_data', 'status'], 'active')),
        )(originalModel);
      }
      const productName = this.getData('name');
      const productCode = this.getData('code');
      const allOptions = R.map(variant => {
        const variantType = R.trim(variant.getData('name')) || '';
        const variantIndex = variant.getData('index');
        let options = R.pipe(R.pathOr([], ['_data', 'options']), R.sortWith([R.ascend(R.pathOr('', ['_data', 'index']))]))(variant);
        options = R.pipe(R.map(option => {
          if (option.getData('name')) {
            const status = option.getData('status');
            const optionName = R.trim(option.getData('name')) || '';
            const optionUid = option.getData('optionUid');
            return {
              variant: variantType,
              variantIndex,
              name: optionName,
              code: option.getData('code'),
              optionId: `${variantType}:${optionName}`,
              optionUid,
              status
            };
          }
          return false;
        }), R.reject(R.equals(false)))(options);
        return options;
      })(variants);
      const combinations = R.pipe(R.reduce((acc, optionsData) => {
        if (R.isEmpty(acc)) {
          return R.map(optionData => [optionData])(optionsData);
        }
        if (R.isEmpty(optionsData)) {
          return acc;
        }
        return R.xprod(acc)(optionsData);
      }, []), R.map(R.flatten), _nventor.default.mapIndexed((optionData, index) => {
        const variantChildId = R.pipe(R.pluck('optionId'), _nventor.default.sortTextAscending, R.join(':'))(optionData);

        // the new prefered way to create variant child ids
        const variantChildUid = R.pipe(R.pluck('optionUid'), _nventor.default.sortTextAscending, R.join(':'))(optionData);
        let existingVariantChild = R.find(R.pathEq(['_data', 'variantChildUid'], variantChildUid))(existingVariantChildren);
        // second try match by option choices (old way: legacy purposes only)
        if (!existingVariantChild) {
          existingVariantChild = R.find(R.pathEq(['_data', 'variantChildId'], variantChildId))(existingVariantChildren);
        }
        const statuses = R.pluck('status')(optionData);
        let status = this.get('constants.status.active');
        if (R.includes(this.get('constants.status.inactive'), statuses)) {
          status = this.get('constants.status.inactive');
        }
        const existingStatus = R.pathOr('', ['_data', 'status'])(existingVariantChild);
        if (RA.isNotNilOrEmpty(existingStatus)) {
          status = existingStatus;
        }
        let name = R.pipe(R.sortWith([R.ascend(R.prop('variantIndex'))]), R.pluck('name'), R.reject(_nventor.default.isNilOrEmpty), R.join('-'))(optionData);
        const existingName = R.pathOr('', ['_data', 'name'])(existingVariantChild);
        if (existingName && !isNew) {
          name = existingName;
        } else if (name && productName && R.equals(autoFill, 'name') && R.equals(autoFillCommand, 'autoFill')) {
          name = `${productName}-${name}`;
        } else if (name && productName && isNew && RA.isNilOrEmpty(autoFillCommand)) {
          name = `${productName}-${name}`;
        } else {
          name = '';
        }
        let code = R.pipe(R.sortWith([R.ascend(R.prop('variantIndex'))]), data => {
          // use variantOptions if no codes
          const codes = R.pluck('code')(data);
          return RA.mapIndexed((code, index) => {
            if (RA.isNotNilOrEmpty(code)) {
              return code;
            }
            return R.pathOr('', [index, 'name'])(data);
          })(codes);
        }, R.join('-'))(optionData);
        const codePlaceholder = R.clone(code);
        const existingCode = R.pathOr('', ['_data', 'code'])(existingVariantChild);
        if (existingCode && !isNew) {
          code = existingCode;
        } else if (code && productCode && R.equals(autoFill, 'code') && R.equals(autoFillCommand, 'autoFill')) {
          code = `${productCode}-${code}`;
        } else if (code && productCode && isNew && RA.isNilOrEmpty(autoFillCommand)) {
          code = `${productCode}-${code}`;
        } else {
          code = '';
        }
        const existingKey = R.pathOr('', ['_data', '_key'])(existingVariantChild);
        let key = '';
        const existingId = existingVariantChild?._id || '';
        let id = existingId;
        if (existingKey) {
          key = existingKey;
        }
        let salesExclPrice = this.getData('salesExclPrice');
        let salesInclPrice = this.getData('salesInclPrice');
        let purchasesExclPrice = this.getData('purchasesExclPrice');
        let purchasesInclPrice = this.getData('purchasesInclPrice');
        if (!_nventor.default.isNilOrEmpty(originalVariantChildren)) {
          // first try match by variant uid
          let originalVariantChild = R.find(R.pathEq(['_data', 'variantChildUid'], variantChildUid))(originalVariantChildren);

          // second try match by option choices (old way: legacy purposes only)
          if (!originalVariantChild) {
            originalVariantChild = R.find(R.pathEq(['_data', 'variantChildId'], variantChildId))(originalVariantChildren);
          }
          if (originalVariantChild) {
            salesExclPrice = originalVariantChild.getData('salesExclPrice');
            salesInclPrice = originalVariantChild.getData('salesInclPrice');
            purchasesExclPrice = originalVariantChild.getData('purchasesExclPrice');
            purchasesInclPrice = originalVariantChild.getData('purchasesInclPrice');

            // will always use original name & code'
            // only use original name and code for when initial?
            if (!R.equals(autoFill, 'name') & !R.equals(autoFill, 'code') && setInitialValue) {
              status = originalVariantChild.getData('status');
              name = originalVariantChild.getData('name');
              code = originalVariantChild.getData('code');
              key = originalVariantChild.getData('_key');
              id = originalVariantChild.getData('_id');
            }
          } else {
            if (!R.equals(autoFill, 'name') & !R.equals(autoFill, 'code') && setInitialValue) {
              status = 'active';
            }
          }
        }
        if (R.equals(autoFill, 'name') && R.equals(autoFillCommand, 'clear')) {
          name = '';
          key = '';
          id = '';
        }
        if (R.equals(autoFill, 'code') && R.equals(autoFillCommand, 'clear')) {
          code = '';
        }
        if (R.equals(existingStatus, 'inactive')) {
          status = existingStatus;
          key = existingKey;
          id = existingId;
          code = existingCode;
          name = existingName;
          salesExclPrice = R.pathOr('', ['_data', 'salesExclPrice'])(existingVariantChild);
          salesInclPrice = R.pathOr('', ['_data', 'salesInclPrice'])(existingVariantChild);
          purchasesExclPrice = R.pathOr('', ['_data', 'purchasesExclPrice'])(existingVariantChild);
          purchasesInclPrice = R.pathOr('', ['_data', 'purchasesInclPrice'])(existingVariantChild);
        }
        const variantChildData = {
          _key: key,
          _id: id,
          variantChildIndex: index + 1,
          variantChildId,
          variantChildUid,
          code,
          codePlaceholder,
          name,
          salesExclPrice,
          salesInclPrice,
          purchasesExclPrice,
          purchasesInclPrice,
          status
        };
        return this.addVariantChild(variantChildData, existingVariantChildren);
      }))(allOptions);
      this.removeUnusedVariantChildren(combinations);
      if (RA.isNotNilOrEmpty(onAfter) && R.is(Function, onAfter)) {
        onAfter();
      }
    },
    removeUnusedVariantChildren(newChildren) {
      const newChildrenId = R.map(variantChild => variantChild._data.variantChildId)(newChildren);
      const newChildrenUId = R.map(variantChild => variantChild._data.variantChildUid)(newChildren);
      const variantChildren = this.getData('variantChildren') || [];
      R.pipe(R.pathOr([], ['_data', '_createVariantChildren']), R.reject(R.pathEq(['_data', 'permanentlyUnavailable'], true)), R.reject(variantChild => {
        const variantChildKey = variantChild?._data?._key || '';
        const variantChildId = variantChild?._data?.variantChildId || '';
        const variantChildUid = variantChild?._data?.variantChildUid || '';
        if (!variantChildKey) {
          return true;
        }
        if (R.includes(variantChildId, newChildrenId) || R.includes(variantChildUid, newChildrenUId)) {
          return true;
        }
        return false;
      }), R.forEach(variantChild => {
        const variantChildCopy = this.dataManager.copyRecord(this.get('childAdapters.variants-children'), variantChild);
        variantChildCopy.setData('status', 'inactive');
        variantChildCopy.setData('permanentlyUnavailable', true);
        variantChildren.pushObject(variantChildCopy);
      }))(this);
      this.setData('_createVariantChildren', newChildren);
    },
    addChoice(currentChoice, data) {
      const choices = this.getData('choices') || [];
      const lastChoice = choices.get('lastObject');
      const lastIndex = R.path(['_data', 'index'], lastChoice);
      let index = 1;
      if (lastIndex) {
        index = parseInt(lastIndex) + 1;
      }
      const defaultData = {
        index,
        name: '',
        choicesQty: '',
        status: this.get('constants.status.active'),
        options: []
      };
      data = data || defaultData;
      const choiceModel = this.dataManager.setAsRecord({
        adapterName: this.get('childAdapters.choices'),
        data
      });
      choices.pushObject(choiceModel);
      this.setData('choices', choices);
    },
    removeChoice(choice) {
      const choices = this.get('_data.choices') || [];
      choices.removeObject(choice);
      this.resetIndexes(choices);
      return choice;
    },
    addFaq(faq) {
      this.getData('faqs').pushObject(faq);
    },
    _blankAttachment() {
      return {
        type: this.constants.productAttachmentsTypes.video,
        source: this.constants.videoSources.youtube,
        url: ''
      };
    },
    _newAttachmentModel(data) {
      if (RA.isNilOrEmpty(data)) {
        data = this._blankAttachment();
      }
      return this.dataManager.setAsRecord({
        adapterName: 'products/attachments',
        data
      });
    },
    addDescriptionAttachment() {
      const attachments = this.getData('descriptionAttachments') || [];
      if (attachments.length >= 5) {
        alert(this.intl.t('over limit'));
        return;
      }
      const model = this._newAttachmentModel();
      const newIndex = attachments.length - 1;
      model.setData('index', newIndex);
      attachments.pushObject(model);
      this.setData('descriptionAttachments', attachments);
    },
    addVirtualContentsAttachment() {
      const attachments = this.getData('virtualContentAttachments') || [];
      if (attachments.length >= 5) {
        alert(this.intl.t('over limit'));
        return;
      }
      const model = this._newAttachmentModel();
      const newIndex = attachments.length - 1;
      model.setData('index', newIndex);
      attachments.pushObject(model);
      this.setData('virtualContentAttachments', attachments);
    },
    _blankSpec() {
      return {
        label: '',
        value: ''
      };
    },
    _newSpecModel(data) {
      if (RA.isNilOrEmpty(data)) {
        data = this._blankSpec();
      }
      return this.dataManager.setAsRecord({
        adapterName: 'products/specs',
        data
      });
    },
    addSpec() {
      const specs = this.getData('specs') || [];
      if (specs.length >= 20) {
        alert(this.intl.t('over limit'));
        return;
      }
      const newIndex = specs.length - 1;
      const model = this._newSpecModel();
      model.setData('index', newIndex);
      specs.pushObject(model);
      this.setData('specs', specs);
    }
  }, _applyDecoratedDescriptor(_obj, "defaults", [_dec], Object.getOwnPropertyDescriptor(_obj, "defaults"), _obj), _applyDecoratedDescriptor(_obj, "_transformSpecs", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_transformSpecs"), _obj), _applyDecoratedDescriptor(_obj, "_transformAttachments", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_transformAttachments"), _obj), _applyDecoratedDescriptor(_obj, "removeBarcode", [_dec4], Object.getOwnPropertyDescriptor(_obj, "removeBarcode"), _obj), _applyDecoratedDescriptor(_obj, "addNewBarcode", [_dec5], Object.getOwnPropertyDescriptor(_obj, "addNewBarcode"), _obj), _applyDecoratedDescriptor(_obj, "addNewTemplate", [_dec6], Object.getOwnPropertyDescriptor(_obj, "addNewTemplate"), _obj), _applyDecoratedDescriptor(_obj, "removeTemplate", [_dec7], Object.getOwnPropertyDescriptor(_obj, "removeTemplate"), _obj), _applyDecoratedDescriptor(_obj, "addProductDisplayTags", [_dec8], Object.getOwnPropertyDescriptor(_obj, "addProductDisplayTags"), _obj), _applyDecoratedDescriptor(_obj, "_blankAttachment", [_dec9], Object.getOwnPropertyDescriptor(_obj, "_blankAttachment"), _obj), _applyDecoratedDescriptor(_obj, "_newAttachmentModel", [_dec10], Object.getOwnPropertyDescriptor(_obj, "_newAttachmentModel"), _obj), _applyDecoratedDescriptor(_obj, "addDescriptionAttachment", [_dec11], Object.getOwnPropertyDescriptor(_obj, "addDescriptionAttachment"), _obj), _applyDecoratedDescriptor(_obj, "addVirtualContentsAttachment", [_dec12], Object.getOwnPropertyDescriptor(_obj, "addVirtualContentsAttachment"), _obj), _applyDecoratedDescriptor(_obj, "_blankSpec", [_dec13], Object.getOwnPropertyDescriptor(_obj, "_blankSpec"), _obj), _applyDecoratedDescriptor(_obj, "_newSpecModel", [_dec14], Object.getOwnPropertyDescriptor(_obj, "_newSpecModel"), _obj), _applyDecoratedDescriptor(_obj, "addSpec", [_dec15], Object.getOwnPropertyDescriptor(_obj, "addSpec"), _obj), _obj));
});