define("client/pods/components/open-graph/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let OpenGraphComponent = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _class = class OpenGraphComponent extends _component.default {
    removeOpenGraphImage() {
      this.args.model.set('_data.openGraph.imageUuid', '');
    }
    onOpenGraphImageChange(onCloseDialogAction, options, uploadsModel) {
      const uuid = R.pathOr('', ['_data', 'uuid'])(uploadsModel);
      const openGraph = R.pipe(R.pathOr({}, ['args', 'model', '_data', 'openGraph']), R.mergeLeft({
        imageUuid: uuid
      }))(this);
      this.args.model.setData('openGraph', openGraph);
      onCloseDialogAction();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "removeOpenGraphImage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "removeOpenGraphImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpenGraphImageChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onOpenGraphImageChange"), _class.prototype), _class);
});