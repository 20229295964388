define("client/pods/components/payments/virtual-accounts/panels-display/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    exportService: Ember.inject.service('export'),
    excel: Ember.inject.service(),
    intl: Ember.inject.service(),
    adapterName: 'payments/virtualAccounts',
    isExporting: false,
    init() {
      this._super(...arguments);
      const period = this.get('tab.period');
      this.set('period', period);
      const filters = this.filters;
      const {
        dateStart,
        dateEnd
      } = period;
      const paymentVirtualAccountStatus = [this.get('constants.paymentVirtualAccountStatus.approved'), this.get('constants.paymentVirtualAccountStatus.pending'), this.get('constants.paymentVirtualAccountStatus.expired'), this.get('constants.paymentVirtualAccountStatus.attentionRequired')];
      this.listByTask.perform({
        adapterName: 'payments/virtualAccounts',
        filters,
        dateStart,
        dateEnd,
        paymentVirtualAccountStatus
      });
      if (!this.query) {
        this.fetchStatementTask.perform(period);
      }

      // const self = this

      // const paymentSpecs = {
      //   description: 'cc_payment',
      //   labelValue: 'CreditCardPayment',
      //   method: 'byDetails',
      //   index: 0,
      //   subMethod: '',
      //   paymentMethod: 'all',
      //   specs: [
      //     [
      //       {
      //         value: 'creator.md',
      //         prop: 'creator.md',
      //         label: this.intl.t('date'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'contactKey',
      //         prop: 'contactKey',
      //         label: this.intl.t('contact'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'email',
      //         prop: 'email',
      //         label: this.intl.t('email'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'docNo',
      //         prop: 'docNo',
      //         label: '#',
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'ref',
      //         prop: 'ref',
      //         label: this.intl.t('ref'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'externalId',
      //         prop: 'externalId',
      //         label: this.intl.t('cc_ref'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'inclTotal',
      //         prop: 'inclTotal',
      //         label: this.intl.t('subtotal'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'refundRequestedInclTotal',
      //         prop: 'refundRequestedInclTotal',
      //         label: this.intl.t('cc_refundRequested'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'refundedInclTotal',
      //         prop: 'refundedInclTotal',
      //         label: this.intl.t('cc_refunded'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'netInclTotal',
      //         prop: 'netInclTotal',
      //         label: this.intl.t('inclusive total'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'paymentCreditCardProcessingFee',
      //         prop: 'paymentCreditCardProcessingFee',
      //         label: this.intl.t('fees'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'campaign.name',
      //         prop: 'campaign.name',
      //         label: this.intl.t('shop'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'paymentCreditCardProvider',
      //         prop: 'paymentCreditCardProvider',
      //         label: '',
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'paymentCreditCard.partial_card_number',
      //         prop: 'paymentCreditCard.partial_card_number',
      //         label: this.intl.t('cc_partial_card_number'),
      //         transforms: [],
      //         hideDefault: false,
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }, {
      //         value: 'dispatchProcessLabel',
      //         prop: 'dispatchProcessLabel',
      //         label: this.intl.t('dispatch status'),
      //         transforms: [],
      //         hideDefault: false,
      //         translateFn (propValue, eachDocData) {
      //           const status = R_.dotPath('status')(eachDocData)
      //           if (status !== 'final') {
      //             return self.intl.t(status)
      //           }

      //           let translate = true
      //           const translateProp = 'dispatchProcessLabelTranslate'
      //           if (translateProp) {
      //             translate = R_.dotPath(translateProp)(eachDocData)
      //           }

      //           if (translate) {
      //             return self.intl.t(propValue)
      //           }
      //         },
      //         type: '',
      //         item: '',
      //         itemKey: '',
      //         isDetail: ''
      //       }
      //     ]
      //   ]
      // }

      // const specsModel = this.crud.setupNewRecord({
      //   adapterName: 'payments/virtualAccounts/specs',
      //   data: paymentSpecs
      // })

      // this.set('specs', specsModel)
    },
    listByTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      appendPath,
      match,
      abandoned,
      status,
      paymentVirtualAccountStatus,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (abandoned) {
        filters.setData('abandoned', abandoned);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (status) {
        if (!R.is(Array, status)) {
          status = [status];
        }
        filters.setData('status', status);
      }
      if (paymentVirtualAccountStatus) {
        if (!R.is(Array, paymentVirtualAccountStatus)) {
          paymentVirtualAccountStatus = [paymentVirtualAccountStatus];
        }
        filters.setData('paymentVirtualAccountStatus', paymentVirtualAccountStatus);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters,
        component: this
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }),
    fetchStatementTask: (0, _emberConcurrency.task)(function* (period) {
      const appendPath = this.selectedProvider;
      const response = yield this.server.call('GET', `api/protected/payments/virtual-accounts/statement/${appendPath}`, period);
      this.set('statement', response.data);
      return response.data;
    }),
    // requestCancellationTask: task(function * (model) {
    //   const msg = this.intl.t('are you sure you want to cancel?')
    //   if (window.confirm(msg)) {
    //     const updated = yield this.updateRecordTask.perform({
    //       adapterName: this.adapterName,
    //       appendPath: '/refund/request',
    //       model
    //     })
    //     const period = this.period
    //     this.fetchStatementTask.perform(period)
    //     return updated
    //   }
    // }),

    // undoCancellationTask: task(function * (model) {
    //   const msg = this.intl.t('are you sure you want to undo cancel?')
    //   if (window.confirm(msg)) {
    //     const updated = yield this.updateRecordTask.perform({
    //       adapterName: this.adapterName,
    //       appendPath: '/refund/undo',
    //       model
    //     })

    //     const period = this.period
    //     this.fetchStatementTask.perform(period)
    //     return updated
    //   }
    // }),

    exportTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction /* filter */) {
      this.set('isExporting', true);
      let filename = this.filename;
      if (!filename) {
        filename = 'untitled';
      }
      const newSerializedCustomSpecs = [this?.specs?._data] || [];
      const worksheets = [];
      const filters = this.exportFilters;
      const {
        dateStart,
        dateEnd
      } = this.period;
      const paymentVirtualAccountStatus = [this.get('constants.paymentVirtualAccountStatus.approved'), this.get('constants.paymentVirtualAccountStatus.refundRequested'), this.get('constants.paymentVirtualAccountStatus.refunded'), this.get('constants.paymentVirtualAccountStatus.paidOut')];
      const results = yield this.listByTask.perform({
        adapterName: 'payments/virtualAccounts',
        appendPath: '/export',
        filters,
        dateStart,
        dateEnd,
        paymentVirtualAccountStatus
      });
      const serialized = R.pluck('_data', results) || [];
      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);
        if (method) {
          const customSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized
          });
          worksheets.push(customSheetToPush);
        }
      })(newSerializedCustomSpecs);
      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename
        });
        this.set('isExporting', false);
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
      onCloseDialogAction();
    }).drop(),
    actions: {
      setupFilename() {
        const period = this.period;
        const {
          dateStart,
          dateEnd
        } = period;
        const filename = `${this.intl.t('credit card transactions')} ${dateStart}-${dateEnd}`;
        this.set('filename', filename);
      }
    }
  });
});