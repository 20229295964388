define("client/pods/components/wizards/wizard-btns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "t9T5RRck",
    "block": "{\"symbols\":[\"&default\",\"@wizardContainerComponent\",\"@tab\",\"@isWizard\"],\"statements\":[[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \\n\"],[4,\"if\",[[23,2,[\"previousStep\"]]],null,{\"statements\":[[0,\"        \"],[5,\"elements/element-btn\",[],[[\"@classNames\",\"@icon\",\"@isRunning\",\"@label\",\"@onSubmit\"],[\"u-btn\",\"fas fa-arrow-left\",[23,2,[\"isRunning\"]],\"previous step\",[23,2,[\"previous\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[5,\"elements/btn-cancel\",[],[[\"@classNames\",\"@isRunning\",\"@label\",\"@onSubmit\",\"@translate\"],[\"u-btn--cancel\",[23,2,[\"isRunning\"]],[28,\"concat\",[[28,\"tt\",[\"go to\"],null],[28,\"tt\",[[23,3,[\"title\"]]],null]],null],[23,2,[\"cancel\"]],false]]],[0,\"\\n\\n      \"],[5,\"elements/btn-save\",[],[[\"@icon\",\"@isRunning\",\"@label\",\"@onSubmit\"],[[28,\"if\",[[23,2,[\"nextStep\"]],\"fas fa-arrow-right\",\"fas fa-save\"],null],[23,2,[\"isRunning\"]],[28,\"if\",[[23,2,[\"nextStep\"]],\"save and go next\",\"save\"],null],[23,2,[\"next\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/wizards/wizard-btns/template.hbs"
    }
  });
});