define("client/pods/extensions/apps/tw/e-invoices/docs-for-display/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      data.eInvoices = this._transformEInvoices(data.eInvoices, attrs);
      this._super(data, attrs);
    },
    _transformEInvoices(eInvoices = [], attrs = {}) {
      return R.map(eInvoice => {
        return this.dataManager.setAsRecord({
          adapterName: 'extensions/apps/tw/e-invoices',
          data: eInvoice,
          attrs
        });
      })(eInvoices);
    }
  });
});