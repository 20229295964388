define("client/pods/channels/shop/campaigns/details/commission-base-cost/history/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import * as R from 'ramda'
  // import * as RA from 'ramda-adjunct'
  var _default = _exports.default = _model.default.extend({});
});