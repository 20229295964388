define("client/pods/components/products/panels-display/details-display/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    products: Ember.inject.service(),
    crud: Ember.inject.service(),
    hideOptionSales: false,
    hideOptionPurchases: false,
    formInitData: null,
    init() {
      this._super(...arguments);
      this.formInitData = {
        documentationResource: 'products',
        documentationIsMinimized: true,
        menuId: 'productsDetailsMenu',
        resourceList: this.resourceList,
        wizardContainerComponent: this.wizardContainerComponent
      };
      this.fetchSalesPriceHistoryTask.perform();
      this.fetchPurchasesPriceHistoryTask.perform();
    },
    fetchSalesPriceHistoryTask: (0, _emberConcurrency.task)(function* () {
      const itemKey = this.model._data._key;
      if (itemKey) {
        const salesPriceHistoryData = yield this.products.getHistoryTask.perform({
          docType: 'sales',
          docName: 'invoices',
          itemKey: itemKey
        });
        this.set('salesPriceHistoryData', salesPriceHistoryData);
      }
    }),
    fetchPurchasesPriceHistoryTask: (0, _emberConcurrency.task)(function* () {
      const itemKey = this.model._data._key;
      if (itemKey) {
        const purchasesPriceHistoryData = yield this.products.getHistoryTask.perform({
          docType: 'purchases',
          docName: 'invoices',
          itemKey: itemKey
        });
        this.set('purchasesPriceHistoryData', purchasesPriceHistoryData);
      }
    }),
    salesLatestByDate: Ember.computed('salesPriceHistoryData.history.{forContact.[],forOthers.[]}', function () {
      const forContact = this.productsService.getFor('contact', this.salesPriceHistoryData);
      const forOthers = this.productsService.getFor('others', this.salesPriceHistoryData);
      return _ramda.default.concat(forContact, forOthers);
    }),
    purchasesLatestByDate: Ember.computed('purchasesPriceHistoryData.history.{forContact.[],forOthers.[]}', function () {
      const forContact = this.productsService.getFor('contact', this.purchasesPriceHistoryData);
      const forOthers = this.productsService.getFor('others', this.purchasesPriceHistoryData);
      return _ramda.default.concat(forContact, forOthers);
    }),
    sortedSalesPriceHistoryData: Ember.computed('salesLatestByDate.[]', function () {
      return this.salesLatestByDate;
    }),
    sortedPurchasesPriceHistoryData: Ember.computed('purchasesLatestByDate.[]', function () {
      return this.purchasesLatestByDate;
    }),
    productSalesExclPrice: Ember.computed('model', function () {
      const type = 'sales';
      const product = this.model;
      return product.getData(`${type}ExclPrice`);
    }),
    productPurchaseExclPrice: Ember.computed('model', function () {
      const type = 'purchase';
      const product = this.model;
      return product.getData(`${type}ExclPrice`);
    }),
    productSalesInclPrice: Ember.computed('model', function () {
      const type = 'sales';
      const product = this.model;
      return product.getData(`${type}InclPrice`);
    }),
    productPurchaseInclPrice: Ember.computed('model', function () {
      const type = 'purchase';
      const product = this.model;
      return product.getData(`${type}InclPrice`);
    }),
    isProductReadonly: Ember.computed('isReadonly', function () {
      return _ramda.default.propOr(true, 'isReadonly')(this);
    })
  });
});