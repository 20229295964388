define("client/pods/components/updates/update-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ivnkKLHI",
    "block": "{\"symbols\":[\"@isBtnOnly\"],\"statements\":[[4,\"if\",[[23,0,[\"updates\",\"hasNewVersion\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/btn-save\",[],[[\"@tooltipLabel\",\"@classNames\",\"@label\",\"@icon\",\"@onSubmit\"],[\"\",\"has-text-danger\",\"update now\",\"fas fa-sync-alt\",[28,\"fn\",[[23,0,[\"updates\",\"reload\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\"],[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"tt\",[\"update required, please reload\"],null],false],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n          \"],[5,\"elements/btn-save\",[],[[\"@tooltipLabel\",\"@classNames\",\"@label\",\"@icon\",\"@onSubmit\"],[\"\",\"has-text-danger\",\"update now\",\"fas fa-sync-alt\",[28,\"fn\",[[23,0,[\"updates\",\"reload\"]]],null]]]],[0,\"\\n        \"],[9],[0,\"\\n\"],[0,\"    \"],[9],[0,\"    \\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/updates/update-btn/template.hbs"
    }
  });
});