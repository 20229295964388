define("client/pods/components/channels/shop/items/item-price/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    hasStrikeThrough: true,
    isOnSale: Ember.computed('item.{isOnSale,activeOnSaleSchedule,isStandardProduct}', function () {
      const item = this?.item || {};
      const isOnSale = item?.isOnSale || false;
      const isStandardProduct = item?.isStandardProduct;
      const activeOnSaleSchedule = item?.activeOnSaleSchedule || {};
      let hasActiveOnSaleScheduleForNotStandardProduct = false;
      if (!isStandardProduct && activeOnSaleSchedule?._data?.isOnSale) {
        hasActiveOnSaleScheduleForNotStandardProduct = true;
      }
      return isOnSale || hasActiveOnSaleScheduleForNotStandardProduct;
    })
  });
});