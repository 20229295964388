define("client/pods/ledgers/journals/cashbook/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        query: '',
        contraTypes: [],
        dateZ: '',
        date: '',
        relationships: [],
        accountCode: '',
        description: '',
        amount: '',
        discount: '',
        total: '',
        comments: '',
        match: ['all'],
        contra: '',
        accountWhenIsBank: '',
        sort: [
        // doesn't work
        {
          by: 'dateZ',
          direction: 'DESC'
        }, {
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    },
    contraTypesCount: Ember.computed('_data.contraTypes.[]', function () {
      return this.getPropLength('_data.contraTypes');
    }),
    relationshipCount: Ember.computed('_data.relationships.[]', function () {
      return this.getPropLength('_data.relationships');
    })
  });
});