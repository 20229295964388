define("client/pods/components/transporters/hct-waybill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0G102d9I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"hct-waybill__container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"waybill\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"hct-waybill__image\"],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"fetchWaybillRecord\"]],[24,[\"model\"]]],null]]],[8],[0,\"\\n      \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"fetchWaybillRecord\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[11,\"src\",[29,[\"data:image/png;base64,\",[23,0,[\"transporterWaybillBase64Image\"]]]]],[11,\"alt\",[29,[[24,[\"model\",\"_data\",\"waybill\"]]]]],[11,\"style\",[23,0,[\"waybillStyle\"]]],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"u-no-print has-text-centered u-section has-text-info\"],[8],[0,\"\\n      \"],[1,[28,\"elements/element-btn\",null,[[\"icon\",\"onSubmit\"],[\"fas fa-eye-slash\",[24,[\"onTogglePrintWaybill\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"tt\",[\"not yet generated\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/hct-waybill/template.hbs"
    }
  });
});