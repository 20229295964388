define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    campaignsProducts: Ember.inject.service(),
    tagName: ''
  });
});