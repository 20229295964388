define("client/pods/video-sources/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    constants: Ember.inject.service(),
    modelObj: _model.default,
    idParam: false,
    findAll() {
      let data = [{
        value: this.constants.videoSources.youtube,
        label: this.constants.videoSources.youtube,
        translate: true
        // }, {
        //   value: 'service',
        //   label: 'product_type_service',
        //   translate: true
      }];
      // const isVirtualOnly = this.settings?.isFeatureAllowed('features.isVirtualOnly')
      // if (isVirtualOnly) {
      //   // only allow virtual for now
      //   data = [{
      //     value: 'virtual',
      //     label: 'product_type_virtual',
      //     translate: true
      //   }]
      // }
      return _nventor.default.resolve(data);
    }
  });
});