define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "huePtoIW",
    "block": "{\"symbols\":[\"@master\",\"@tab\",\"@onCloseDialogAction\",\"@onUpdateBulkDiscountTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"panel-grid__content\"],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"newBulkDiscountModel\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"bulkDiscountModel\"]]],null,{\"statements\":[[0,\"      \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor\",[],[[\"@bulkDiscount\",\"@model\",\"@isNew\",\"@tab\"],[[23,0,[\"bulkDiscountModel\"]],[23,1,[]],true,[23,2,[]]]]],[0,\"\\n\\n      \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"elements/btn-cancel\",[],[[\"@onSubmit\",\"@isRunning\"],[[23,3,[]],[23,4,[\"isRunning\"]]]]],[0,\"\\n\\n          \"],[5,\"elements/btn-save\",[],[[\"@disabled\",\"@onSubmit\",\"@isRunning\"],[[23,0,[\"bulkDiscountModel\",\"hasBulkDiscountOverlap\"]],[28,\"perform\",[[23,0,[\"addBulkDiscount\"]]],null],[23,4,[\"isRunning\"]]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/panels-new/template.hbs"
    }
  });
});