define("client/pods/components/documents/table-view/doc-row/detail-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    dispatch: Ember.inject.service(),
    tagName: '',
    // hasTopEmptyRow: computed('detailIndex', 'detail.isFirstSetChildItem', 'detail.isFirstSetChoiceItem', function () {
    //   const detailIndex = this.detailIndex
    //   if (detailIndex === 0) {
    //     return false
    //   }

    //   const detail = this.detail
    //   if (detail?.isFirstSetChildItem) {
    //     return true
    //   }

    //   if (detail?.isFirstSetChoiceItem) {
    //     return true
    //   }

    //   return false
    // }),

    // hasBottomEmptyRow: computed('model.sortedDetailsWithoutSets.@each.synced', 'detailIndex', 'detail.isFirstSetChildItem', 'detail.isFirstSetChoiceItem', function () {
    //   const sortedDetailsWithoutSets = this.model?.sortedDetailsWithoutSets || 0
    //   const lastIndex = sortedDetailsWithoutSets?.length - 1
    //   const detailIndex = this.detailIndex
    //   if (detailIndex === lastIndex) {
    //     return false
    //   }

    //   const detail = this.detail
    //   if (detail?.isLastSetChildItem) {
    //     return true
    //   }

    //   if (detail?.isLastSetChoiceItem) {
    //     return true
    //   }

    //   return false
    // }),

    isStrikedOut: Ember.computed('tableViewOptions.isDispatchTab', 'isReturnsDetail', 'detail._data.netReturnsQty', function () {
      if (!this.tableViewOptions?.isDispatchTab) {
        return false;
      }
      if (this.isReturnsDetail) {
        return false;
      }
      if (this.detail?._data?.netReturnsQty < 1) {
        return true;
      }
      return false;
    })
  });
});