define("client/pods/components/products/page-import-btn/component", ["exports", "ramda", "client/utils/nventor", "ember-concurrency", "ramda-extension"], function (_exports, R, _nventor, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    tagName: 'span',
    init() {
      this._super(...arguments);
      const productsFilter = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList'
        }
      });
      const campaignsFilter = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          resultsProperty: 'campaignsDetailsList'
        }
      });
      this.set('productsFilter', productsFilter);
      this.set('campaignsFilter', campaignsFilter);
    },
    setupPanels() {
      this.searchTask.perform();
    },
    pageImportBtnSubPanels: Ember.computed('crud.lists.products-lists-pageImportBtnSubPanels.[]', 'productsList.[]', 'campaignsDetailsList.[]', function () {
      const subPanels = R_.dotPath('crud.lists.products-lists-pageImportBtnSubPanels')(this) || [];
      if (!R.isEmpty(subPanels)) {
        const productsList = this.productsList || [];
        const campaignsDetailsList = this.campaignsDetailsList || [];
        return R.map(panel => {
          // if (panel._data.value === 'products') {
          //   panel.setData('badge', productsList.length)
          // }
          // if (panel._data.value === 'campaigns') {
          //   panel.setData('badge', campaignsDetailsList.length)
          // }
          return panel;
        }, subPanels);
      }
      return subPanels;
    }),
    searchTask: (0, _emberConcurrency.task)(function* () {
      const q = [];
      const query = R.propOr('', 'query')(this);
      this.productsFilter.set('_data.query', query);
      this.campaignsFilter.set('_data.query', query);

      // FILTER OUT EMPTY TEMPLATES
      this.productsFilter.set('_data.ignoreEmptyTemplates', true);
      this.campaignsFilter.set('_data.ignoreEmptyTemplates', true);
      q.push(this._searchTask.perform({
        adapterName: 'products',
        resultsProperty: 'productsList',
        query,
        filters: this.productsFilter
      }));
      q.push(this._searchTask.perform({
        adapterName: 'channels/shop/campaigns/details/templates',
        resultsProperty: 'campaignsDetailsList',
        query,
        filters: this.campaignsFilter
      }));
      yield (0, _emberConcurrency.all)(q);
    }),
    _searchTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      query,
      filters,
      resultsProperty
    }) {
      const results = yield this.crud.searchRecordsConcurrentlyTask.perform({
        adapterName,
        query,
        filters
      });
      this.set(resultsProperty, results);
      return results;
    }).enqueue(),
    actions: {
      replaceTemplate(onCloseDialogAction, template, templateImages = []) {
        const model = this.model;
        model.replaceTemplate(template, templateImages);
        this.onUpdateContent(model.getData('template'));
        onCloseDialogAction();
      },
      insertTemplate(onCloseDialogAction, template, templateImages = []) {
        const model = this.model;
        model.insertTemplate(template, templateImages);
        this.onUpdateContent(model.getData('template'));
        onCloseDialogAction();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "setupPanels", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupPanels"), _obj), _obj));
});