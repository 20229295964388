define("client/pods/components/ledgers/accounts/aging/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _date, _crud, _emberConcurrency, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    date: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),
    adapterName: 'contacts/aging',
    filtersAdapterName: 'contacts/aging/filters',
    currentPeriod: '',
    paymentDueDateZ: '',
    yyyy: '',
    mm: '',
    init() {
      this._super(...arguments);
      const tab = this.tab;
      const docType = tab.get('docType');
      this.set('docType', docType);
      const pastMonths = this.getPastMonths(12);
      this.set('pastMonths', pastMonths);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.set('showResults', true);
      this.filters = filters;
      this.crud.addLists(this, ['contacts/lists/allowed-payment-method', 'filters/count']);
      const period = this.date.getPeriod();
      Ember.set(this, 'yyyy', period.yyyy);
    },
    afterLoadLists() {
      const thisMonth = this.date.getPeriod();
      this.displayByMonthTask.perform(thisMonth);
      this.display(thisMonth, this.filters);
    },
    display(period, filters) {
      const subTabOptions = {
        component: 'ledgers/accounts/aging/panels-display',
        period: period,
        filters,
        docType: this?.tab?.docType || ''
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    displayAgingTask: (0, _emberConcurrency.task)(function* (period) {
      const docType = this.docType;
      const yyyy = period?.yyyy || '';
      const mm = period?.mm || '';
      const allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);
      const filters = this.filters;
      filters.setData('docType', docType);
      filters.setData('yyyy', yyyy);
      filters.setData('mm', mm);
      filters.setData('allowedPaymentMethodForStatement', allowedPaymentMethodForStatement);
      filters.setData('paymentDueDateZ', this?.paymentDueDateZ || '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: `contacts/aging/${docType}`,
        filters: filters
      });
    }),
    reloadStatementOnDueDateChangeTask: (0, _emberConcurrency.task)(function* () {
      const currentPeriod = this?.currentPeriod || {};
      if (RA.isNotNilOrEmpty(currentPeriod)) {
        yield this.displayAgingTask.perform(currentPeriod);
      }
    }),
    displayByMonthTask: (0, _emberConcurrency.task)(function* (period) {
      const dateEnd = this.date.formatDateForUrl(this.date.getMonthEnd(period.yyyy, period.mm));
      this.set('paymentDueDateZ', this.date.getMoment(dateEnd).toISOString());
      this.set('currentPeriod', period);
      yield this.displayAgingTask.perform(period);
    }),
    displayByCustomMonthTask: (0, _emberConcurrency.task)(function* (mm, yyyy) {
      const dateStart = this.date.formatDateForUrl(`${yyyy}-${mm}-01`);
      const period = this.date.getPeriod(dateStart);
      yield this.displayByMonthTask.perform(period);
    }),
    toggleResults(results) {
      const subTab = this.tabsManager.getActiveSubTab(this.tab);
      if (subTab?.component === 'ledgers/accounts/aging/panels-display') {
        return results;
      }
      this.filters.set('showResults', true);
      return results;
    }
  }, _applyDecoratedDescriptor(_obj, "toggleResults", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleResults"), _obj), _obj));
});