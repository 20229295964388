define("client/pods/components/forms/form-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bVOwQmjZ",
    "block": "{\"symbols\":[\"&attrs\",\"@isRunning\",\"&default\",\"@betaPath\",\"@allowedForFeatures\",\"@disallowedForFeatures\"],\"statements\":[[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\",\"@allowedForFeatures\",\"@disallowedForFeatures\"],[[23,4,[]],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"isWizard\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"wizardAllowed\"]]],null,{\"statements\":[[0,\"      \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[[23,0,[\"outerContainerClassName\"]]]]],[13,1],[8],[0,\"\\n      \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,2,[]]]],{\"statements\":[[0,\"         \\n        \"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n          \"],[14,3],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-table/template.hbs"
    }
  });
});