define("client/pods/components/dispatch/import-btn/import-dispatched/manual/component", ["exports", "@glimmer/component", "client/utils/nventor", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _nventor, _emberConcurrencyDecorators, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DispatchImportBtnImportDispatchedManual = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _class = class DispatchImportBtnImportDispatchedManual extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "excel", _descriptor2, this);
      _initializerDefineProperty(this, "date", _descriptor3, this);
      _initializerDefineProperty(this, "server", _descriptor4, this);
      _defineProperty(this, "tagName", 'span');
      _initializerDefineProperty(this, "showImportModal", _descriptor5, this);
      _initializerDefineProperty(this, "isImported", _descriptor6, this);
      _initializerDefineProperty(this, "successful", _descriptor7, this);
      _initializerDefineProperty(this, "unsuccessful", _descriptor8, this);
      _initializerDefineProperty(this, "unsuccessfulImportData", _descriptor9, this);
      _initializerDefineProperty(this, "nextDispatchProcessCode", _descriptor10, this);
      _initializerDefineProperty(this, "nextDispatchProcessLabel", _descriptor11, this);
      _initializerDefineProperty(this, "nextDispatchProcessLabelTranslate", _descriptor12, this);
      _initializerDefineProperty(this, "isDispatched", _descriptor13, this);
      _initializerDefineProperty(this, "importDispatchDateZ", _descriptor14, this);
      _initializerDefineProperty(this, "autoShowPrintAllDialog", _descriptor15, this);
      _initializerDefineProperty(this, "selectedTransporter", _descriptor16, this);
      this.crud.addLists(this, ['documents/lists/import/dispatched/specs']);
      this.importDispatchDateZ = this.date.getStartOfDayZ();
      this.resetImportData();
    }
    get specsForImportDataHeaders() {
      const specsForImportData = R_.dotPath('crud.lists.documents-lists-import-dispatched-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('headerValue'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }
    get specsForImportDataValues() {
      const specsForImportData = R_.dotPath('crud.lists.documents-lists-import-dispatched-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('value'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }
    *importDataTask() {
      const importTransporter = this.importTransporter;
      const importTransporterAccountNumber = this.importTransporterAccountNumber;
      let importDispatchDateZ = this.importDispatchDateZ;
      importDispatchDateZ = this.date.getStartOfDayZ(importDispatchDateZ);
      const importData = R.pipe(R.propOr('', 'bulkRefInsert'), R.split(/\n/), R.trim, R.reject(RA.isNilOrEmpty), R.map(ref => {
        return {
          ref
        };
      }))(this);
      const transporterKey = this.selectedTransporter?._data?._key;
      const transporterExtension = this.selectedTransporter?._data?.transporterExtension;
      const path = 'api/protected/documents/sales/invoices/import/status';
      const payload = {
        transporterLabel: importTransporter,
        transporterAccountNumber: importTransporterAccountNumber,
        transporterKey,
        transporterExtension,
        dispatchDateZ: importDispatchDateZ,
        nextDispatchProcessCode: this.nextDispatchProcessCode,
        nextDispatchProcessLabel: this.nextDispatchProcessLabel,
        nextDispatchProcessLabelTranslate: this.nextDispatchProcessLabelTranslate,
        batch: importData
      };
      const specsForImportDataValues = this.specsForImportDataValues;
      try {
        const response = yield this.server.callJobs('POST', path, payload);
        this.isImported = true;
        const successful = R.pathOr([], ['data', 'successful'], response);
        this.successful = successful;
        const unsuccessful = R.pipe(R.pathOr([], ['data', 'unsuccessful']), R.map(row => {
          row.cells = R.values(R.pick(specsForImportDataValues, row));
          return row;
        }), R.values)(response);
        if (unsuccessful.length > 0) {
          this.hasUnsuccessful = true;
          this.unsuccessfulImportData = unsuccessful;
        }
      } catch (err) {
        this.isImported = false;
        this.crud.setValidationErrors('errors', err);
      }
    }
    get successfulCount() {
      const data = this.successful || [];
      return data.length;
    }
    get unsuccessfulCount() {
      const data = this.unsuccessfulImportData || [];
      return data.length;
    }
    selectTransporter(selectedTransporter) {
      this.selectedTransporter = selectedTransporter;
    }
    resetImportData() {
      this.importData = '';
      this.successful = [];
      this.hasUnsuccessful = false;
      this.unsuccessfulImportData = [];
      this.isImported = false;
    }
    toggleShowImportModal() {
      this.showImportModal = true;
      this.resetImportData();
    }
    closeImportModal({
      refresh = false
    }) {
      const hasSuccessful = R.pipe(R.propOr([], 'successful'), RA.isNotNilOrEmpty)(this);
      if (refresh && this.isImported && hasSuccessful) {
        const importDispatchDateZ = this.importDispatchDateZ || this.date.getStartOfDayZ();
        let period = this.date.getPeriod(importDispatchDateZ);
        let isDispatched = this.isDispatched;
        if (this.isDispatched) {
          this?.args?.onRefreshDispatched?.(period, isDispatched, this.autoShowPrintAllDialog);
        } else {
          period = false;
          isDispatched = false;
          const nextDispatchProcessModel = this.nextDispatchProcessModel;
          this?.args?.onRefresh?.(nextDispatchProcessModel, period, isDispatched, this.autoShowPrintAllDialog);
        }
      }
      this.showImportModal = false;
      this?.args?.onCloseDialogAction?.();
    }
    setDispatchData(dispatchProcessModel) {
      this.isDispatched = false;
      if (dispatchProcessModel?._data?.isDispatched) {
        const todayZ = this.date.getStartOfDayZ();
        this.importDispatchDateZ = todayZ;
        this.isDispatched = true;
      }
      this.nextDispatchProcessLabel = dispatchProcessModel?._data?.name;
      this.nextDispatchProcessLabelTranslate = dispatchProcessModel?._data?.translate;
      this.nextDispatchProcessModel = dispatchProcessModel;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showImportModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isImported", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "successful", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "unsuccessful", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "unsuccessfulImportData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "nextDispatchProcessCode", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'dispatched';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "nextDispatchProcessLabel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'dispatched';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "nextDispatchProcessLabelTranslate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isDispatched", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "importDispatchDateZ", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "autoShowPrintAllDialog", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "selectedTransporter", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "importDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "importDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTransporter", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "selectTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetImportData", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "resetImportData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowImportModal", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowImportModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeImportModal", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "closeImportModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDispatchData", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "setDispatchData"), _class.prototype), _class);
});