define("client/pods/extensions/apps/tw/e-invoices/tracks/numbers/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _model, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    crud: Ember.inject.service(),
    init() {
      this._super(...arguments);
    },
    prefix: Ember.computed('_data.prefix', function () {
      return this._data.prefix;
    }),
    hasUnallocatedNumbers: Ember.computed('synced', '_data.{allocatedNumbers.[]}', function () {
      const allocatedNumbers = this._data.allocatedNumbers || [];
      const lastAllocatedNumber = R.pipe(R.map(R.path(['_data', 'endNumber'])), R.reject(RA.isNilOrEmpty), _nventor.default.safeLast)(allocatedNumbers);
      const endNumber = this._data.endNumber || 0;
      if (!endNumber) {
        return false;
      }
      if (lastAllocatedNumber >= endNumber) {
        return false;
      }
      return true;
    }),
    newAllocatedNumbers() {
      const prefix = this.get('_data.prefix');
      return this.dataManager.newRecord({
        adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
        data: {
          prefix,
          batchKey: this._data.batchKey,
          index: this._data.index,
          mm: this._data.mm,
          yyyy: this._data.yyyy,
          isAllowed: true
        }
      });
    },
    addAllocatedNumbers(allocatedModel) {
      const prefix = this.get('_data.prefix');
      allocatedModel.setData('prefix', prefix);
      const allocatedNumbers = this._data.allocatedNumbers || [];
      allocatedNumbers.pushObject(allocatedModel);
      this.set('_data.allocatedNumbers', allocatedNumbers);
    },
    allocatedNumbers: Ember.computed('synced', '_data.allocatedNumbers.[]', function () {
      return this._data.allocatedNumbers || [];
    }),
    bookRange: Ember.computed('_data.{startNumber,endNumber}', function () {
      const tracksEndNumber = R.pathOr(0, ['_data', 'endNumber'])(this);
      let startNumber = R.pathOr(0, ['_data', 'startNumber'])(this);
      const startNumberBookLabel = R.clone(startNumber);
      const bookLabel = this.intl.t('tw_eInvoices_book');
      const numberLabel = this.intl.t('number');
      const range = [];
      while (startNumber <= tracksEndNumber) {
        const endNumber = startNumber + 49;
        const book = (startNumber - startNumberBookLabel) / 50 + 1;
        let startNumberString = startNumber.toString();
        startNumberString = startNumberString.padStart(8, '0');
        let endNumberString = endNumber.toString();
        endNumberString = endNumberString.padStart(8, '0');
        range.pushObject(Ember.Object.create({
          startNumberLabel: `${bookLabel}: ${book} | ${numberLabel}: ${startNumberString}`,
          startNumber: startNumber,
          book: book,
          endNumberLabel: `${bookLabel}: ${book} | ${numberLabel}: ${endNumberString}`,
          endNumber: endNumber
        }));
        startNumber += 50;
      }
      return range;
    })
  });
});