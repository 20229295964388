define("client/pods/components/transporters/iframe-waybills/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tLP64ueI",
    "block": "{\"symbols\":[\"@waybillsOptionsByProvider\"],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[28,\"or\",[[23,0,[\"dispatchService\",\"generateAndDownloadWaybillsTask\",\"isRunning\"]],[23,0,[\"dispatchService\",\"hctFetchWaybillRecordTask\",\"isRunning\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[\"srcDoc\"]],[23,1,[\"iframeId\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"panel-grid__content\"],[3,\"did-insert\",[[23,0,[\"setupPopup\"]]]],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/iframe-waybills/template.hbs"
    }
  });
});