define("client/pods/components/products/inventory/inventory-manager/component", ["exports", "ember-concurrency", "client/mixins/crud"], function (_exports, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    allowAdjustment: true,
    filtersAdapterName: 'products/inventory/filters',
    productsService: Ember.inject.service('products'),
    title: '',
    fetchChildrenInventory: true,
    init() {
      this._super(...arguments);
      this.setupTask.perform();
    },
    setupTask: (0, _emberConcurrency.task)(function* () {
      if (!this.fetchChildrenInventory) {
        return;
      }
      const product = this.product;
      const children = yield this.productsService.fetchChildrenInventoryTask.perform(product);
      this.set('_children', children);
      return children;
    }),
    childrenInventory: Ember.computed('_children.[]', 'children.[]', function () {
      if (!this.fetchChildrenInventory) {
        return this.children;
      }
      return this._children;
    }),
    _title: Ember.computed('title', 'product.{hasVariantsOrChoices,_data.isSet,_data.name}', function () {
      const hasVariantsOrChoices = this.get('product.hasVariantsOrChoices');
      if (hasVariantsOrChoices) {
        return '';
      }
      const isSet = this.get('product._data.isSet');
      if (isSet) {
        return this.get('product._data.name');
      }
      return this.title;
    }),
    fetchAdjustmentsTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      const product = this.product;
      const searchSettings = {
        adapterName: 'products/inventory/adjustments',
        appendPath: product.getData('_key'),
        resultsProperty: '',
        resultsToggle: '',
        filters
      };
      const adjustmentsData = yield this.searchRecordsTask.perform(searchSettings);
      this.set('adjustmentsData', adjustmentsData);
      return adjustmentsData;
    })
  });
});