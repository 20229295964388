define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-thumbnail/component", ["exports", "client/mixins/crud", "client/mixins/errors"], function (_exports, _crud, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import DroppableMixin from '../../../../../../../../../mixins/droppable'
  var _default = _exports.default = Ember.Component.extend(_crud.default, _errors.default, {
    // export default Component.extend(CrudMixin, DroppableMixin, ErrorsMixin, {
    tagName: '',
    disabled: false,
    translate: true
  });
});