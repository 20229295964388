define("client/pods/components/extensions/apps/tw/e-invoices/print-all-btn/edit-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yx7kw6QN",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\",\"disabled\"],[\"edit\",[24,[\"disabled\"]]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\"],[\"email\",[24,[\"printErrors\"]],\"email\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"dirty\",\"_data\",\"email\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\"],[\"tax number\",[24,[\"printErrors\"]],\"taxNumber\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"dirty\",\"_data\",\"taxNumber\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\"],[\"address\",[24,[\"printErrors\"]],\"address\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"dirty\",\"_data\",\"address\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"elements/btn-save\",null,[[\"label\",\"isRunning\",\"onSubmit\"],[\"save\",[24,[\"updateTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"updateTask\"]],[23,1,[]]],null]]]],false],[0,\"\\n\\n\\t\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"isRunning\",\"onSubmit\"],[\"cancel\",[24,[\"updateTask\",\"isRunning\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/e-invoices/print-all-btn/edit-btn/template.hbs"
    }
  });
});