define("client/pods/components/emails/search-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UqrAK9pj",
    "block": "{\"symbols\":[\"onHideDropdown\"],\"statements\":[[4,\"lists/list-btn\",null,[[\"list\",\"icon\"],[[23,0,[\"crud\",\"lists\",\"emails-lists-filters-search\"]],\"fas fa-filter\"]],{\"statements\":[[0,\"\\n\\t\"],[7,\"legend\",true],[10,\"class\",\"list-results__legend\"],[8],[1,[28,\"tt\",[\"list all\"],null],false],[9],[0,\"\\n\\t\\n\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"isRunning\",\"onSubmit\"],[\"latest\",[24,[\"listByTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"listByTask\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]],[23,1,[]]],null]]]],false],[0,\"\\n\\n\"],[0,\"\\n\\t\"],[1,[22,\"helpers/divider-h\"],false],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"customBlock\"],[true]],{\"statements\":[[4,\"forms/form-cell\",null,[[\"label\"],[\"number of results\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"readonly\",\"translate\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"value\"],[true,true,false,[23,0,[\"crud\",\"lists\",\"filters-count\"]],\"_data.value\",\"_data.label\",[24,[\"filters\",\"_data\",\"count\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[22,\"helpers/divider-h\"],false],[0,\"\\n\\n\"],[4,\"forms/form-input-row\",null,[[\"label\"],[\"search filters\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"lists/list-selector\",null,[[\"translate\",\"list\",\"classNames\",\"model\",\"valueKey\",\"optionValueKey\",\"optionLabelKey\",\"itemClassNames\",\"allowEmpty\",\"multiSelect\"],[true,[23,0,[\"crud\",\"lists\",\"emails-lists-filters-search\"]],\"list-selector\",[24,[\"filters\"]],\"_data.match\",\"_data.value\",\"_data.label\",\"list-selector__item list-selector__item--horizontal\",false,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[22,\"helpers/divider-h\"],false],[0,\"\\n\\n\"],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/search-filters/template.hbs"
    }
  });
});