define("client/pods/components/bsp/virtual-account-editor/component", ["exports", "ramda", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor", "client/pods/options/model"], function (_exports, R, _component, _emberConcurrencyDecorators, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // @TODO: Future backend validation
  let BspVirtualAccountEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _class = class BspVirtualAccountEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      // @service tabsManager
      _initializerDefineProperty(this, "modelProxy", _descriptor2, this);
      _initializerDefineProperty(this, "virtualAccountErrors", _descriptor3, this);
      _initializerDefineProperty(this, "hashKeysErrors", _descriptor4, this);
      _defineProperty(this, "adapterName", this.args?.customAdapterName || this.args?.adapterName || 'settings');
      const isEditing = this?.args?.isEditing;
      const model = this.args?.model || {};
      const tab = this.args?.tab || {};
      this.tab = tab;
      this.modelProxy = _nventor.default.createModel(_model.default, {
        enabled: false,
        isDefault: false,
        merchantId: '',
        hash: {}
      });
      if (isEditing) {
        const parentModel = R.pathOr(null, ['args', 'parentModel'])(this);
        const twPaymentsBsp = R.pathOr({}, ['_data', 'tw_payments_bsp'])(parentModel);
        const virtualAccounts = R.pathOr([], ['virtualAccounts'])(twPaymentsBsp);
        const data = R.pipe(R.pathOr({}, ['_data']), R.clone)(model);
        const currentEditModelIndex = R.findIndex(R.pathEq(['_data', 'merchantId'], data.merchantId))(virtualAccounts);
        this.modelProxy.set('_data', data);
        this.modelProxy.set('index', currentEditModelIndex);
      }
    }
    get hasErrors() {
      if (this.virtualAccountErrors.length !== 0 || this.hashKeysErrors.length !== 0) {
        return true;
      }
      return false;
    }

    // @TODO: should all use backend validation
    validateVirtualAccount() {
      // Uniq names
      const currentMerchantId = R.pathOr('', ['modelProxy', '_data', 'merchantId'])(this);
      if (!currentMerchantId) {
        this.virtualAccountErrors = [{
          type: 'isRequired',
          message: 'is required',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
      } else if (currentMerchantId?.length !== 10) {
        this.virtualAccountErrors = [{
          type: 'incorrectLength',
          message: 'incorrect length',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
      }
      const hash = R.pathOr({}, ['modelProxy', '_data', 'hash'])(this);
      const HASH_KEYS = ['A1', 'A2', 'B1', 'B2'];
      HASH_KEYS.forEach(key => {
        if (!hash?.[key]) {
          this.hashKeysErrors.push({
            type: 'isRequired',
            message: 'is required',
            path: [`hash.${key}`],
            context: {
              key: `hash.${key}`
            },
            isError: true
          });
        } else if (hash[key].length !== 16) {
          this.hashKeysErrors.push({
            type: 'incorrctLength',
            message: 'incorrect length',
            path: [`hash.${key}`],
            context: {
              key: `hash.${key}`
            },
            isError: true
          });
        }
      });
      if (this.hasErrors) {
        throw new Error('Frontend Validation Error: BspVirtualAccountEditor Error');
      }
    }
    isMerchantIdUniq() {
      // Uniq names
      const virtualAccounts = R.pathOr([], ['args', 'parentModel', '_data', 'tw_payments_bsp', 'virtualAccounts'])(this);
      const currentMerchantId = R.pathOr('', ['modelProxy', '_data', 'merchantId'])(this);
      const virtualAccountMerchantIds = R.pipe(R.map(R.pathOr('', ['_data', 'merchantId'])))(virtualAccounts);
      const duplicateMerchantId = virtualAccountMerchantIds?.find(id => id === currentMerchantId);
      if (duplicateMerchantId) {
        this.virtualAccountErrors = [{
          type: 'isUnique',
          message: 'not unique',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
        throw new Error('MerchantId Already in Used!!!');
      }
    }
    *updateVirtualAccountsTask({
      remove = false
    } = {}) {
      const parentModel = R.pathOr(null, ['args', 'parentModel'])(this);
      try {
        if (parentModel) {
          const twPaymentsBsp = R.pathOr({}, ['_data', 'tw_payments_bsp'])(parentModel);
          const virtualAccounts = R.pathOr([], ['virtualAccounts'])(twPaymentsBsp);
          this.hashKeysErrors = [];
          this.virtualAccountErrors = [];
          if (this?.args?.isEditing) {
            const index = this.modelProxy.index;
            virtualAccounts.removeAt(index);
            if (!remove) {
              virtualAccounts.insertAt(index, this.modelProxy);
              this.validateVirtualAccount();
              this.isMerchantIdUniq();
            }
          } else {
            this.validateVirtualAccount();
            const isFirstVirtualAccount = R.length(virtualAccounts) === 0;
            if (isFirstVirtualAccount) {
              const merchantId = this?.modelProxy?._data?.merchantId;
              parentModel.set('_data.tw_payments_bsp.defaultVirtualAccount', merchantId);
              this.modelProxy.setData('enabled', true);
              this.modelProxy.setData('isDefault', true);
            }
            virtualAccounts.addObject(this.modelProxy);
          }
          parentModel.setData('tw_payments_bsp.virtualAccounts', virtualAccounts);
        }
        this.onCloseDialogAction();
      } catch (error) {
        console.log(error.message);
      }
    }
    *cancelEdittingTask() {
      this.onCloseDialogAction();
    }
    onCloseDialogAction() {
      const toggleShowEditor = R.pathOr(null, ['args', 'toggleShowEditor'])(this);
      const onCloseDialogAction = R.pathOr(null, ['args', 'onCloseDialogAction'])(this);
      const onCloseDialogActionListSelector = R.pathOr(null, ['args', 'addItemOptions', 'onCloseDialogActionListSelector'])(this);
      const virtualAccounts = R.pathOr(null, ['args', 'parentModel', '_data', 'tw_payments_bsp', 'virtualAccounts'])(this);
      const isFirstVirtualAccount = R.length(virtualAccounts) === 1;
      if (isFirstVirtualAccount) {
        return onCloseDialogActionListSelector();
      }
      if (onCloseDialogAction) {
        return onCloseDialogAction();
      }
      if (toggleShowEditor) {
        return toggleShowEditor(false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelProxy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "virtualAccountErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hashKeysErrors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateVirtualAccountsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateVirtualAccountsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEdittingTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdittingTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseDialogAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDialogAction"), _class.prototype), _class);
});