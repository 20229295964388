define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/commission-base-cost-history-prices/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import * as R from 'ramda'

  class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelectorCommissionBaseCostHistoryPrices extends _component.default {}
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelectorCommissionBaseCostHistoryPrices;
});