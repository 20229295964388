define("client/pods/components/extensions/apps/tw/e-invoices/panels-display/component", ["exports", "client/mixins/date", "client/mixins/crud", "client/mixins/e-invoices-list-by", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _date, _crud, _eInvoicesListBy, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import nventor from '../../../../../../../utils/nventor'
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, _eInvoicesListBy.default, {
    applicationService: Ember.inject.service('application'),
    hasStatement: false,
    adapterName: 'extensions/apps/tw/e-invoices',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    init() {
      this._super(...arguments);
      let period = this.period;
      if (!period) {
        period = this.get('tab.period');
        this.set('period', period);
      }
      if (RA.isNilOrEmpty(period)) {
        return;
      }
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.setData('provider', this.provider);
      this.set('filters', filters);
      const {
        dateStart,
        dateEnd
      } = period;
      const status = ['all'];
      let adapterName = this.adapterName;
      let appendPath = '';
      const podKey = this.podKey;
      if (podKey) {
        appendPath = `/${podKey}`;
      }
      const selectedTableView = this?.selectedTableView || 'invoice';
      // don't allow admin to use view by document for now?
      if (selectedTableView === 'document') {
        adapterName = 'extensions/apps/tw/e-invoices/docs-for-display';
      }
      this.listByTask.perform({
        filters,
        adapterName,
        appendPath,
        dateStart,
        dateEnd,
        status
      });
      if (this.hasStatement) {
        this.fetchStatementTask.perform(period);
      }
    },
    fetchStatementTask: (0, _emberConcurrency.task)(function* (period) {
      const data = R.clone(period);
      const selectedTableView = this?.selectedTableView || 'invoice';
      data.viewType = selectedTableView;
      if (selectedTableView !== 'document') {
        data.provider = this.provider;
      }
      const adapterName = this.adapterName;
      const adapter = this.server.getAdapter(adapterName);
      let resourcePath = `${adapter.resourcePath}/statement`;
      const podKey = this.podKey;
      if (podKey) {
        resourcePath = `${adapter.resourcePath}/statement/${podKey}`;
      }
      const response = yield this.server.call('GET', resourcePath, data);
      this.set('statement', response.data);
      return response.data;
    }),
    fetchUnpaginatedDataTask: (0, _emberConcurrency.task)(function* (period, {
      rawResponse = false
    } = {}) {
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      const {
        dateStart,
        dateEnd
      } = period;
      // const status = ['successful']
      // const adapterName = this.adapterName
      // const appendPath = '/docs'

      filters.setData('dateStart', dateStart);
      filters.setData('dateEnd', dateEnd);
      filters.setData('provider', this.provider);
      const searchSettings = {
        adapterName: 'extensions/apps/tw/e-invoices/docs',
        resultsProperty: 'exportResults',
        resultsToggle: '',
        query: '',
        filters,
        rawResponse
      };
      return yield this.searchRecordsTask.perform(searchSettings);
    }),
    _listByTask: (0, _emberConcurrency.task)(function* ({
      filters,
      adapterName,
      appendPath,
      match,
      abandoned,
      status,
      date,
      dateStart,
      dateEnd,
      query = '',
      contact,
      contactKey,
      sourceKey,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);
      if (match) {
        filters.setData('match', match);
      }
      if (sourceKey) {
        filters.setData('sourceKey', sourceKey);
      }
      if (abandoned) {
        filters.setData('abandoned', abandoned);
      }
      if (contactKey) {
        filters.setData('contactKey', contactKey);
      } else if (contact) {
        filters.setData('contact', contact);
      }
      if (status) {
        if (!R.is(Array, status)) {
          status = [status];
        }
        filters.setData('status', status);
      }
      if (date) {
        filters.setData('match', ['dateZ']);
        query = date;
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }).drop()
  });
});