define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products-limits/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsPanelsDisplaySubPanelsProductsLimitsComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _class = class ChannelsShopCampaignsPanelsDisplaySubPanelsProductsLimitsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "batch", _descriptor3, this);
      _initializerDefineProperty(this, "isBulkEditing", _descriptor4, this);
      // @tracked isSettingUpBulkDirty = false
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "listsIsLoaded", _descriptor6, this);
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/limits/filters',
        defaultFiltersData: {
          master
        }
      });
      this.filters = filters;
      this.crud.addLists(this, ['filters/count', 'channels/shop/campaigns/lists/limitTypes', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'products/lists/menu', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypesFilters', 'statuses', 'toggles/yesNo']);
    }
    _getBulkEditingDirty(batch) {
      return R.map(detail => {
        return this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details/limits',
          model: detail,
          attrs: {
            product: detail.product
          }
        });
      })(batch);
    }
    *fetchCampaignDetailsLimitsTask() {
      const model = this.args.model;
      const master = model.getData('_key');
      let batch = [];
      if (master) {
        model.set('detailsLimitsUpdated', false);
        batch = yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details/limits',
          appendPath: `/master/${master}`,
          filters: this.filters,
          component: this
        });
        model.set('detailsLimits', batch);
      } else {
        model.set('detailsLimits', batch);
      }
      this.batch = batch;
    }
    *customPaginateSearchRecordsTask() {
      let batch = yield this.crud.paginateSearchRecordsTask.perform(...arguments);
      if (this.isBulkEditing) {
        batch = this._getBulkEditingDirty(batch);
      }
      this.batch = batch;
      const model = this.args.model;
      model.set('detailsLimits', batch);
    }
    bulkEdit() {
      this.isBulkEditing = true;
      const batch = this.batch || [];
      this.batch = this._getBulkEditingDirty(batch);
    }
    toggleBulkEdit() {
      this.isBulkEditing = !this.isBulkEditing;
      if (!this.isBulkEditing) {
        this.fetchCampaignDetailsLimitsTask.perform();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "batch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isBulkEditing", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "listsIsLoaded", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchCampaignDetailsLimitsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignDetailsLimitsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "customPaginateSearchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "customPaginateSearchRecordsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkEdit", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "bulkEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBulkEdit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkEdit"), _class.prototype), _class);
});