define("client/pods/flows/lists/steps/types/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {}
    } = {}) {
      data = [{
        value: 'text',
        label: 'flow_message',
        isPreset: false,
        isPresetLocked: false,
        text: 'message',
        type: 'TEXT',
        stepType: 'text',
        dataType: '',
        isReplyRequired: false
        // hasReply: false,
        // hasChoices: false
      }, {
        label: 'flow_reply',
        value: 'replyText',
        isPreset: false,
        isPresetLocked: false,
        text: 'name',
        type: 'QUICK_REPLY',
        stepType: 'replyText',
        dataType: 'text',
        isReplyRequired: true
        // hasReply: true,
        // hasChoices: false
      }, {
        isPreset: false,
        isPresetLocked: false,
        value: 'replyChoices',
        label: 'flow_reply_choices',
        text: 'email',
        type: 'QUICK_REPLY',
        stepType: 'replyChoices',
        isReplyRequired: true,
        dataType: 'email',
        // hasReply: true,
        // hasChoices: true,
        choices: []
      }];
      return _nventor.default.resolve(data);
    }
  });
});