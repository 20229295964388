define("client/pods/components/products/panels-display/faqs-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a7bcd6sn",
    "block": "{\"symbols\":[\"@tab\",\"@lists\",\"@model\",\"@isReadonly\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"forms/form-section\",null,[[\"title\",\"errors\",\"context\",\"documentationResource\"],[\"faqs for product\",[24,[\"errors\"]],\"faqsProducts\",\"products\"]],{\"statements\":[[0,\"      \"],[5,\"faqs\",[],[[\"@adapterName\",\"@appendPath\",\"@tab\",\"@lists\",\"@model\",\"@isReadonly\",\"@isDisabled\"],[\"products\",\"\",[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],false]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/faqs-display/template.hbs"
    }
  });
});