define("client/pods/products/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    dateService: Ember.inject.service('date'),
    defaults() {
      const period = this.dateService.getPeriod();
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [this.get('constants.status.active')],
        types: [],
        setTypes: [],
        recurringFilters: [],
        hasImages: '',
        name: '',
        code: '',
        brands: [],
        barcode: '',
        tags: [],
        hideNoActivity: false,
        units: [],
        statusesCount: '',
        fetchWithActiveCampaigns: '',
        hasActiveCampaigns: '',
        salesTaxRate: '',
        purchasesTaxRate: '',
        fetchMax: false,
        inventoryReportMMStart: period.mm || '',
        inventoryReportYYYYStart: period.yyyy || '',
        inventoryReportMMEnd: period.mm || '',
        inventoryReportYYYYEnd: period.yyyy || '',
        itemKey: '',
        itemCode: '',
        includeInventoryReport: false,
        hideVariantChildrenInProductsSearch: false,
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    },
    tagsCount: Ember.computed('_data.tags.[]', function () {
      return this.getPropLength('_data.tags');
    }),
    unitsCount: Ember.computed('_data.units.[]', function () {
      return this.getPropLength('_data.units');
    }),
    brandsCount: Ember.computed('_data.brands.[]', function () {
      return this.getPropLength('_data.brands');
    }),
    typesCount: Ember.computed('_data.types.[]', function () {
      return this.getPropLength('_data.types');
    }),
    setTypesCount: Ember.computed('_data.setTypes.[]', function () {
      return this.getPropLength('_data.setTypes');
    }),
    recurringFiltersCount: Ember.computed('_data.recurringFilters.[]', function () {
      return this.getPropLength('_data.recurringFilters');
    })
  });
});