define("client/pods/components/comments/comments-manager/modal-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tbGWrBh7",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@commentsManager\",\"@isRunning\",\"@options\"],\"statements\":[[4,\"if\",[[23,4,[\"master\"]]],null,{\"statements\":[[0,\"  \"],[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@badge\",\"@icon\",\"@isRunning\",\"@label\",\"@modalDialogContainerClassName\",\"@modalDialogTitle\",\"@onClose\",\"@tooltipLabel\"],[[23,2,[\"commentsCount\"]],[23,0,[\"icon\"]],[28,\"or\",[[23,3,[]],[23,2,[\"fetchCommentsTask\",\"isRunning\"]]],null],[23,4,[\"btnLabel\"]],[28,\"if\",[[23,4,[\"isPublic\"]],\"public-comments\"],null],[28,\"if\",[[23,4,[\"isPublic\"]],\"customer comments\",\"internal comments\"],null],[23,2,[\"cancelEdit\"]],[23,4,[\"btnTooltipLabel\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"comments/comments-manager/comments-container\",[],[[\"@isRunning\",\"@commentsManager\",\"@options\"],[[23,3,[]],[23,2,[]],[23,4,[]]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/comments/comments-manager/modal-btn/template.hbs"
    }
  });
});