define("client/pods/recurring/schedules/options/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/recurring/schedules/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/recurring/schedules',
    modelObj: _model.default,
    idParam: '_id',
    serialize(model) {
      const data = this._super(model);
      const months = Number(data.months) || 0;
      const days = Number(data.days) || 0;
      data.daysOrMonths = months + days;
      return data;
    }
  });
});