define("client/pods/components/comments/comments-manager/display-item/component", ["exports", "client/mixins/crud", "ramda"], function (_exports, _crud, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    classNames: ['comments__display-item'],
    username: '',
    isEditing: false,
    adapterName: 'comments',
    init() {
      this._super(...arguments);
      const tokenData = this.storage.get('tokenData');
      this.set('username', tokenData.username);
      const model = this.model;
      if (model?._data?.emailType === 'fromMerchantToCustomer') {
        this.set('classNames', ['comments__display-item-right']);
      }
    },
    isEditable: Ember.computed('model._data.{emailType,isPublic}', 'hasCommentEditorFooter', function () {
      const model = this.model || {};
      const isPublic = R.pathOr(false, ['_data', 'isPublic'])(model);
      const username = model?._data?.username || '';
      const hasCommentEditorFooter = R.propOr(true, 'hasCommentEditorFooter')(this);
      if (!hasCommentEditorFooter) {
        return false;
      }
      if (!R.equals(this.username, username)) {
        return false;
      }
      if (isPublic) {
        return false;
      }
      return true;
    })
  });
});