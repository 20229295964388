define("client/pods/export/lists/specs/shared/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import nventor from '../../../../../utils/nventor'
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default
  });
});