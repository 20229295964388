define("client/pods/components/menus/tab-menu-side/tab-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UR2RfFMZ",
    "block": "{\"symbols\":[\"@translate\",\"&default\",\"@isActive\"],\"statements\":[[7,\"li\",false],[12,\"class\",[29,[[23,0,[\"classNames\"]],\" \",[28,\"if\",[[23,3,[]],\"is-active\",\"\"],null]]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"onClick\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"\\t\\t\"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"item\",\"_data\",\"icon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"elements/element-icon\",[],[[\"@icon\"],[[23,0,[\"item\",\"_data\",\"icon\"]]]]],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"u-icon-spacer--right\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"elements/element-icon\",[],[[\"@icon\"],[[23,0,[\"item\",\"_data\",\"icon\"]]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"tt\",[[23,0,[\"label\"]]],[[\"translate\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"elements/element-icon\",[],[[\"@icon\"],[\"fas fa-asterisk element-icon--xs has-text-danger\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"item\",\"_data\",\"badge\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"element-btn__badge\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,0,[\"item\",\"_data\",\"badge\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"subTab\",\"errors\",\"errors\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"element-btn__badge\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,0,[\"subTab\",\"errors\",\"errors\",\"length\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/tab-menu-side/tab-item/template.hbs"
    }
  });
});