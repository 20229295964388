define("client/pods/components/products/inventory/inventory-status-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mTkO/1nF",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@showQty\"],\"statements\":[[4,\"if\",[[24,[\"product\",\"hasInventory\"]]],null,{\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchProductTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"lists/list-btn\",[],[[\"@disabled\",\"@label\",\"@translate\",\"@icon\",\"@btnClassNames\",\"@iconClassName\",\"@hasToggleIconRight\"],[[23,0,[\"isReadonly\"]],[23,0,[\"qty\"]],false,[28,\"if\",[[23,2,[]],\"\",\"fas fa-info-circle\"],null],[28,\"if\",[[23,0,[\"isEmpty\"]],\"has-text-danger\",\"\"],null],[28,\"if\",[[28,\"and\",[[28,\"not\",[[23,2,[]]],null],[23,0,[\"isEmpty\"]]],null],\"has-text-danger\",\"\"],null],false]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"products/inventory/inventory-manager\",null,[[\"product\",\"isTable\",\"allowAdjustment\",\"fetchChildrenInventory\",\"children\"],[[24,[\"product\"]],true,[24,[\"allowAdjustment\"]],false,[23,0,[\"children\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/inventory/inventory-status-tooltip/template.hbs"
    }
  });
});