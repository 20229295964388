define("client/helpers/highlight", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params, hash) {
      const string = params[0] || '';
      let query = params[1] || '';
      if (hash.disabled) {
        return string;
      }

      // @TODO: take care of mixed case
      // instead of replacing sting:
      // make string and query same case, then find start and end pos of matching.
      // insert span at start and end pos

      let safeString = Ember.Handlebars.Utils.escapeExpression(string);
      query = R.toLower(query);
      const s = R.toLower(safeString);

      // find pos start
      const posStart = R.indexOf(query, s);
      const posEnd = posStart + query.length;
      const foundQuery = safeString.substring(posStart, posEnd);
      if (R.toLower(foundQuery) === query) {
        safeString = safeString.replace(foundQuery, `<span class='u-highlight'>${foundQuery}</span>`);
        return Ember.String.htmlSafe(safeString);
      }
      return Ember.String.htmlSafe(safeString);
    }
  });
});