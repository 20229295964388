define("client/pods/components/elements/element-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X4us9BeW",
    "block": "{\"symbols\":[\"@context\"],\"statements\":[[5,\"elements/error-wrapper\",[],[[\"@context\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"elements/element-textareapvt\",null,[[\"classNames\",\"placeholder\",\"disabled\",\"readonly\",\"name\",\"id\",\"type\",\"rows\",\"maxlength\",\"maxRows\",\"autoResize\",\"autocomplete\",\"autofocus\",\"onInput\",\"action\",\"value\"],[[24,[\"inputPvtClassNames\"]],[23,0,[\"inputPlaceHolder\"]],[24,[\"disabled\"]],[24,[\"readonly\"]],[24,[\"name\"]],[24,[\"inputId\"]],[24,[\"type\"]],[24,[\"rows\"]],[24,[\"maxlength\"]],[24,[\"maxRows\"]],[24,[\"autoResize\"]],[24,[\"autocomplete\"]],[24,[\"autofocus\"]],[24,[\"onInput\"]],[28,\"action\",[[23,0,[]],\"submit\"],null],[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-textarea/template.hbs"
    }
  });
});