define("client/pods/flows/steps/actions/promo-codes-default/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/flows/steps/actions/promo-codes-default/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    applicationService: Ember.inject.service('application'),
    resourcePath: '',
    idParam: false,
    modelObj: _model.default,
    serialize(model) {
      const type = model._data.type;
      if (type === this.constants.discountTypes.amount) {
        model.setData('discountRate', '');
        model.setData('discountRateOnSale', '');
      } else {
        model.setData('discountAmount', '');
      }
      if (!model._data.allowOnSale) {
        model.setData('discountRateOnSale', '');
      }
      const data = this._super(model);
      data.emails = this.applicationService.splitToArray(data.emails || '');
      data.telephones = this.applicationService.splitToArray(data.telephones || '');
      return data;
    }
  });
});