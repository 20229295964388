define("client/pods/components/emails/status-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yahvQwaY",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasEvents\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"sorted\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"emails/status-tags/status-tag\",null,[[\"data\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[\"email_queued\"],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/status-tags/template.hbs"
    }
  });
});