define("client/initializers/pikaday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'pikaday', 'service:pikaday');
    application.inject('adapter', 'pikaday', 'service:pikaday');
    application.inject('component', 'pikaday', 'service:pikaday');
  }
  var _default = _exports.default = {
    name: 'pikaday',
    initialize: initialize
  };
});