define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _class = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      _initializerDefineProperty(this, "big", _descriptor4, this);
      _initializerDefineProperty(this, "commissionBaseCostHistoryFilters", _descriptor5, this);
      _initializerDefineProperty(this, "commissionBaseCostHistoryData", _descriptor6, this);
      _defineProperty(this, "tagName", 'span');
      _defineProperty(this, "classNames", ['']);
      _defineProperty(this, "isReadonly", false);
      _defineProperty(this, "adapterName", 'channels/shop/campaigns/details/commission-base-cost/history');
      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/details/commission-base-cost/history/filters');
      const commissionBaseCostHistoryFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.commissionBaseCostHistoryFilters = commissionBaseCostHistoryFilters;
    }
    *fetchHistoryOnInitTask() {
      const isUsingAverage = this?.args?.isUsingAverage;
      const itemKey = this?.args?.itemKey;
      const selectedCommissionBaseCostMethod = this.args.selectedCommissionBaseCostMethod;
      const hasAdjustedShippingFee = this.args.hasAdjustedShippingFee;
      const type = this.args.type;
      const commissionBaseCostHistoryFilters = this.commissionBaseCostHistoryFilters;
      commissionBaseCostHistoryFilters.setData('itemKey', itemKey);
      commissionBaseCostHistoryFilters.setData('isUsingAverage', isUsingAverage);
      commissionBaseCostHistoryFilters.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
      commissionBaseCostHistoryFilters.setData('hasAdjustedShippingFee', hasAdjustedShippingFee);
      commissionBaseCostHistoryFilters.setData('type', type);
      if (hasAdjustedShippingFee) {
        let taxRate = this?.args?.model?._data?.taxRate;
        if (RA.isNilOrEmpty(taxRate)) {
          const taxRateFromSettings = this?.settings?.settingsModel?._data?.taxRate || 0;
          taxRate = taxRateFromSettings;
        }
        const isModelCalculatedByExclAmt = R.pathOr(true, ['args', 'isCalculatedByExclAmt'])(this);
        commissionBaseCostHistoryFilters.setData('taxRate', taxRate);
        commissionBaseCostHistoryFilters.setData('isModelCalculatedByExclAmt', isModelCalculatedByExclAmt);
      }
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionBaseCostHistoryFilters
      });
      this.commissionBaseCostHistoryData = results;
    }
    get isDisabled() {
      if (!this?.args?.itemKey) {
        return true;
      }
      return this.isReadonly;
    }
    select(model, campaignsDetailsModel, selectedCommissionBaseCostMethod, type, history, onHideDropdown) {
      this.args.onSelect.perform({
        model,
        campaignsDetailsModel,
        selectedCommissionBaseCostMethod,
        type,
        history,
        onHideDropdown
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostHistoryFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostHistoryData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchHistoryOnInitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchHistoryOnInitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
});