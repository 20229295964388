define("client/pods/open-hours/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let OpenHoursModel = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _class = class OpenHoursModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
      _initializerDefineProperty(this, "hasOpeningHours", _descriptor2, this);
    }
    get label() {
      return `${this._data.name} opening hours`;
    }
    get dayName() {
      return this._data.name;
    }
    defaults() {
      return {
        open: 'none',
        openAfterBreak: 'none',
        closeAfterBreak: 'none',
        close: 'none'
      };
    }
    populate(data, attrs) {
      super.populate(data, attrs);
    }
    toggleHasOpeningHours() {
      this.hasOpeningHours = this._data.open !== 'none';
    }
    copyOpeningHours(sortedOpenHours) {
      const weekDay = this._data.name;
      const currentIndex = R.findIndex(R.pathEq(['_data', 'name'], weekDay))(sortedOpenHours);
      const previousDayIndex = R.dec(currentIndex);
      const previousDay = sortedOpenHours[previousDayIndex];
      const previousDayData = previousDay._data;
      R.pipe(R.omit(['name']), R.forEachObjIndexed((value, key) => {
        this.setData(key, value);
      }))(previousDayData);
      this.toggleHasOpeningHours();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasOpeningHours", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this._data.open !== 'none';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleHasOpeningHours", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHasOpeningHours"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyOpeningHours", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "copyOpeningHours"), _class.prototype), _class);
});