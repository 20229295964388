define("client/pods/components/workflows/create-dynamic/workflow-editor-wrapper/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WorkflowsCreateDynamicWorkflowEditorWrapperComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('workflows'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _class = class WorkflowsCreateDynamicWorkflowEditorWrapperComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "workflowsService", _descriptor2, this);
      _initializerDefineProperty(this, "dataManager", _descriptor3, this);
      _initializerDefineProperty(this, "model", _descriptor4, this);
      this.crud.addLists(this, ['workflows/related/types']);
      this.setModel();
      this.setDefaults();
    }
    setModel() {
      if (this?.args?.isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: 'workflows',
          model: this?.args?.model
        });
      } else {
        this.model = this.workflowsService.newWorkflow({
          type: 'workflows'
        });
      }
    }
    setDefaults() {
      const options = R.pathOr({}, ['args', 'addItemOptions'])(this);
      const type = R.prop('type')(options);
      const name = R.path(['metaData', 'name'])(options);
      const master = R.prop('master')(options);
      if (type === 'documents') {
        return;
      }

      // "workflows/related"
      const related = this.dataManager.setAsRecord({
        adapterName: 'workflows/related',
        data: {
          resource: type,
          resourceValue: name,
          resourceKey: master
        }
      });
      this?.model?.setData('name', 'TODO');
      this?.model?._data?.related?.pushObject(related);
      this?.model?._data?.stages[0]?.setData('name', 'TODO');
    }
    onClose() {
      const onCloseDialogAction = R.path(['args', 'onCloseDialogAction'])(this);
      const toggleShowEditor = R.path(['args', 'toggleShowEditor'])(this);
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
      if (toggleShowEditor) {
        toggleShowEditor();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _class);
});