define("client/pods/pos/lists/profiles/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      const user = this._transformUsers(data);
      this.set('user', user);
      this._super(data, attrs);
    },
    _transformUsers(data) {
      if (data?.userData) {
        const userData = data.userData;
        delete data.userData;
        return this.dataManager.setPartialRecord({
          adapterName: 'users',
          data: userData
        });
      }
      return {};
    }
  });
});