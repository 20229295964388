define("client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * mixin for dispatch functionality
   */
  // @TODO: move fetchInvoice and cancelInvoice out of this mixin,
  // those are for the outer components whereas the other functions are for inner components
  // outer component: makes use of 'invoices' (array of invoices for given masterKey)
  // inner component: only makes use of 1 invoice
  var _default = _exports.default = Ember.Mixin.create({

    // isSaved: computed('model.{isDeleted,_data.status}', function () {
    //   if (this.get('model.isDeleted')) {
    //     return false
    //   }

    //   if (this.get('model._data.status') === 'printed' || this.get('model._data.status') === 'pending') {
    //     return true
    //   }

    //   return false
    // }),

    // isPending: computed('model._data.status', function () {
    //   if (this.get('model._data.status') === 'pending') {
    //     return true
    //   }

    //   return false
    // }),

    // isCancelled: computed('model._data.status', function () {
    //
    //   if (this.get('model._data.status') === 'cancelled') {
    //
    //     return true;
    //   }
    //
    //   return false;
    // }),

    // getInvoiceCacheId (model) {
    //   if (typeof model === 'object') {
    //     return model.getData('_key')
    //   }

    //   // invoice is masterKey
    //   return `tw_invoices_${model}`
    // }
  });
});