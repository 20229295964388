define("client/pods/components/payment-methods-editor/component", ["exports", "client/mixins/crud", "ramda"], function (_exports, _crud, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    adapterName: 'paymentMethods',
    init() {
      this._super(...arguments);
      const isEditing = this.isEditing;
      if (isEditing) {
        const dirty = this.setupDirty();
        this.set('model', dirty);
      } else {
        const model = this.setupNewRecord();
        this.set('model', model);
      }
    },
    defaultPaymentMethods: Ember.computed('crud.lists.paymentMethods.[]', 'crud.lists.paymentMethods.@each.synced', 'addItemOptions.docName', function () {
      const docName = this?.addItemOptions?.docName || '';
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      const defaultPaymentMethods = R.filter(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
      if (!R.equals(docName, 'consignments') && !R.equals(docName, 'consignments-returns')) {
        return defaultPaymentMethods;
      }
      return R.filter(paymentMethod => {
        const value = paymentMethod?._data?.value || '';
        if (R.equals(value, 'consignment')) {
          return true;
        }
        return false;
      })(defaultPaymentMethods);
    }),
    setHasPaymentTerms(paymentMethod) {
      const hasPaymentTerms = R.pathOr(false, ['_data', 'hasPaymentTerms'])(paymentMethod);
      const model = this.model;
      model.setData('hasPaymentTerms', hasPaymentTerms);
      model.setData('translate', false);
    },
    afterCreateRecordTask(model) {
      this.onAddItem(model);
      this.onCloseDialogAction();
    },
    afterReplaceRecordTask(model) {
      this.onUpdateItem(model);
      this.onCloseDialogAction?.();
    },
    afterRemoveRecordTask() {
      this.onRemoveItem(this.model);
    },
    actions: {
      cancel(model) {
        if (this.isEditing) {
          this.cancelEditing({
            dirty: model
          });
          this.set('showEditor', false);
        } else {
          this.onCloseDialogAction();
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "setHasPaymentTerms", [_dec], Object.getOwnPropertyDescriptor(_obj, "setHasPaymentTerms"), _obj), _applyDecoratedDescriptor(_obj, "afterCreateRecordTask", [_dec2], Object.getOwnPropertyDescriptor(_obj, "afterCreateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterReplaceRecordTask", [_dec3], Object.getOwnPropertyDescriptor(_obj, "afterReplaceRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterRemoveRecordTask", [_dec4], Object.getOwnPropertyDescriptor(_obj, "afterRemoveRecordTask"), _obj), _obj));
});