define("client/pods/components/export/worksheet/editor/component", ["exports", "@glimmer/component", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  /* global R */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExportWorksheetEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _class = class ExportWorksheetEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dataManager", _descriptor2, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);
      _initializerDefineProperty(this, "listAlreadyUsedInDropdown", _descriptor4, this);
      this.listAlreadyUsedInDropdown = this?.args?.model?._data?.defaultExportCustomSheets || [];
      const model = this?.args?.model || {};
      const showSetDetails = model.getData('showSetDetails');
      if (RA.isNilOrEmpty(showSetDetails)) {
        model.setData('showSetDetails', true);
      }
      const isGroupDocs = model.getData('isGroupDocs');
      const source = this?.args?.source || 'dispatch';
      if (source === 'reports') {
        model.setData('isGroupDocs', false);
      }
      if (RA.isNilOrEmpty(isGroupDocs) && source === 'dispatch') {
        model.setData('isGroupDocs', true);
      }
      this.crud.addLists(this, ['dispatch/lists/dispatch-sort', 'dispatch/lists/dispatch-sort-direction']);
      const filters = this?.args?.filters || {};
      if (RA.isNotNilOrEmpty(filters)) {
        let filtersSort = R.pipe(R.pathOr([], ['_data', 'sort']), _nventor.default.safeLastOr({}))(filters);
        model.setData('sortBy', filtersSort?.by || 'none');
        model.setData('sortByDirection', filtersSort?.direction || 'DESC');
      }
    }
    get availableCustomWorksheets() {
      const originalList = this?.args?.specsCollection?._data?.custom || [];
      const listAlreadyUsedInDropdown = this.listAlreadyUsedInDropdown || [];
      return R.filter(list => {
        const listKey = list._data._key;
        const listKeyAlreadyUsedInDropdown = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('value'), R.reject(RA.isNilOrEmpty))(listAlreadyUsedInDropdown);
        return !R.includes(listKey, listKeyAlreadyUsedInDropdown);
      })(originalList);
    }
    addDefaultExportCustomSheets(selectedSpec) {
      const model = this.args.model;
      const defaultCustomSheets = model.getData('defaultExportCustomSheets') || [];
      const data = {
        value: selectedSpec.getData('_key'),
        label: selectedSpec.getData('description'),
        index: defaultCustomSheets.length
      };
      const modelData = this.dataManager.setAsRecord({
        adapterName: 'dispatch/lists/processes/custom-worksheets',
        data
      });
      defaultCustomSheets.pushObject(modelData);
      model.setData('defaultExportCustomSheets', defaultCustomSheets);
      this.listAlreadyUsedInDropdown = model?._data?.defaultExportCustomSheets || [];
    }
    removeListDropdown(customSheet) {
      const model = this.args.model;
      const defaultExportCustomSheets = model.getData('defaultExportCustomSheets');
      defaultExportCustomSheets.removeObject(customSheet);
      RA.mapIndexed((val, idx) => {
        defaultExportCustomSheets.set(`${idx}.index`, idx);
      })(defaultExportCustomSheets);
      model.setData('defaultExportCustomSheets', defaultExportCustomSheets);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "listAlreadyUsedInDropdown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addDefaultExportCustomSheets", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addDefaultExportCustomSheets"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeListDropdown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeListDropdown"), _class.prototype), _class);
});