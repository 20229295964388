define("client/pods/components/channels/shop/campaigns/campaigns-editor/faqs-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K/D/IOnQ",
    "block": "{\"symbols\":[\"formInitData\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@hasColumns\",\"@formInitData\",\"@tab\"],[\"faqs\",false,[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"campaigns\",[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]]]]],[23,4,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@tab\",\"@title\"],[\"faqsSettings_group\",[23,1,[]],[23,4,[]],\"defaults faqs\"]],{\"statements\":[[0,\"\\n    \"],[5,\"faqs\",[],[[\"@adapterName\",\"@appendPath\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\",\"@tab\"],[\"settings\",\"/partial\",[23,6,[]],[23,7,[]],[23,8,[]],[23,0,[\"settings\",\"settingsModel\"]],[23,4,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"  \"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@tab\",\"@title\"],[\"faqsCampaigns_group\",[23,1,[]],[23,4,[]],\"faqs for shop\"]],{\"statements\":[[0,\"\\n    \"],[5,\"faqs\",[],[[\"@adapterName\",\"@appendPath\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\",\"@tab\"],[\"channels/shop/campaigns\",\"\",[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,4,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/faqs-editor/template.hbs"
    }
  });
});