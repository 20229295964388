define("client/pods/components/settings/defaults/transporter-drop-down/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/5X5cArg",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"lists/list-btn\",[],[[\"@disabled\",\"@icon\",\"@list\",\"@label\",\"@translate\",\"@onSubmit\"],[[23,2,[]],\"fas fa-tags\",[23,0,[\"transporters\"]],[23,0,[\"label\"]],false,[28,\"perform\",[[23,0,[\"fetchTransporters\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-selector\",[],[[\"@translate\",\"@allowEmpty\",\"@hasSearch\",\"@list\",\"@model\",\"@multiSelect\",\"@optionLabelKey\",\"@optionValueKey\",\"@valueKey\",\"@onSelect\"],[false,false,false,[23,0,[\"transporters\"]],[23,3,[]],false,\"_data.name\",\"_data._key\",[23,0,[\"valueKey\"]],[23,0,[\"setDefaultTransporter\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/defaults/transporter-drop-down/template.hbs"
    }
  });
});