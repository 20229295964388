define("client/pods/components/menus/progress-bar/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MenusProgressBarComponent extends _component.default {
    get hasCurrent() {
      return !!(this.args.current || this.args.currentIndex);
    }
  }
  _exports.default = MenusProgressBarComponent;
});