define("client/mixins/processes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Process from '../pods/processes/model';
  var _default = _exports.default = Ember.Mixin.create({
    classNameBindings: ['isProcessing'],
    processes: [],
    // init() {
    //
    //   this._super(...arguments);
    // },
    // isProcessing: Ember.computed('processes.[]', function () {
    //
    //   const active = this.get('processes').length;
    //
    //   if (active > 0) {
    //
    //     //has processes running
    //     return true;
    //   }
    //
    //   //no processes running
    //   return false;
    // }),
    whenReady() {
      // const isProcessing = this.get('isProcessing');
      // if (isProcessing) {
      //
      // }
      const self = this;
      const promises = self.get('processes');
      return new Ember.RSVP.all(promises);
      //
      // return new Ember.RSVP.Promise(function (resolve, reject) {
      //   /* jshint unused: false */
      //   return resolve(data);
      // });
    },
    startProcess(process) {
      // const process = new Ember.RSVP.Promise(function (resolve, reject) {
      //   /* jshint unused: false */
      //   return resolve(data);
      // });
      const processes = this.processes;
      if (Ember.isArray(processes)) {
        //   const process = Process.create();
        processes.pushObject(process);

        //   return process;
      }
      //
      // return false;
    },
    stopProcess(process) {
      if (process) {
        this.processes.removeObject(process);
        return true;
      }
      return false;
    }
    // create: function (component) {
    //   if (this.get('disableOnProcessing') === false || this.get('disableOnProcessing') === true) {
    //     this.set('disableOnProcessing', true);
    //     this.set('disabled', true);
    //   }
    //   //creates new process
    //   //@TODO: this is causing problems for list-picker
    //   //when an item is searched, the input removes the values when isProcessing is set
    //   // this.set('isProcessing', true);
    //
    //   var process = Process.create();
    // 	var promise = process.start(component);
    // 	var processes = this.get('processes');
    // 	var promises = this.get('promises');
    // 	processes.pushObject(process);
    // 	promises.pushObject(promise);
    // 	return process;
    // },
    // end: function (process) {
    //   //remove process from process manager
    //   var processes = this.get('processes');
    //   var promises = this.get('promises');
    //   var promise = process.get('promise');
    //   promises.removeObject(promise);
    //   processes.removeObject(process);
    //
    //   if (!this.get('isDestroyed')) {
    //     if (this.get('disableOnProcessing')) {
    //       this.set('disableOnProcessing', false);
    //       this.set('disabled', false);
    //     }
    //
    //     // if (this.get('processManager.processes').length < 1) {
    //       //@TODO: this is causing problems for list-picker
    //       //when an item is searched, the input removes the values when isProcessing is set
    //       // this.set('isProcessing', false);
    //     // }
    //   }
    // },
    // /**
    // * waits for all process to be ready before starting async operation. while it is waiting it sets isProcessing=true.
    // * @param  {boolean}  setProcessing - defaults to true. if set to false then it does not alter isProcessing.
    // */
    // isReady: function (setProcessing) {
    //   var self = this;
    //
    //   //disableOnProcessing only when the component has this property AND is disableOnProcessing argument is not set to false
    //   var componentHasDisableOnProcessing = false;
    //   if ((self.get('disableOnProcessing') === false || self.get('disableOnProcessing') === true) && setProcessing !== false) {
    //     componentHasDisableOnProcessing = true;
    //   }
    //
    //   //start processing..
    //   if (setProcessing !== false) {
    //     self.set('isProcessing', true);
    //   }
    //
    //   if (setProcessing === true || componentHasDisableOnProcessing) {
    //     self.set('disableOnProcessing', true);
    //     self.set('disabled', true);
    //   }
    //
    //   var promises = this.get('promises');
    // 	promises = Ember.RSVP.Promise.all(promises);
    //   return promises.all().then(function () {
    //     //when complete stop processing
    //     if (setProcessing !== false) {
    //       self.set('isProcessing', false);
    //     }
    //     if (setProcessing === true || componentHasDisableOnProcessing) {
    //       self.set('disableOnProcessing', false);
    //       self.set('disabled', false);
    //     }
    //   });
    // }
  });
});