define("client/pods/components/grapesjs/links-modal/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GrapesjsLinksModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('websites'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _class = class GrapesjsLinksModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _initializerDefineProperty(this, "websitesService", _descriptor4, this);
      _initializerDefineProperty(this, "tempModel", _descriptor5, this);
      _initializerDefineProperty(this, "selectedFilter", _descriptor6, this);
      _initializerDefineProperty(this, "selectedAdapter", _descriptor7, this);
      _initializerDefineProperty(this, "allFilters", _descriptor8, this);
      _initializerDefineProperty(this, "allSetupData", _descriptor9, this);
      _initializerDefineProperty(this, "query", _descriptor10, this);
      this.crud.addLists(this, ['websites/lists/types/links', 'websites/lists/types/links/targets']);
      this._setup();
    }
    _setup() {
      const selected = this.args.editor.getSelected();
      const data = {};
      if (selected) {
        const attributes = R.pathOr([], ['attributes', 'attributes'])(selected);
        data.linkType = R.pathOr(data.linkType || 'url', ['data-href-link-type'])(attributes);
        data.linkTitle = R.pathOr('', ['data-href-title'])(attributes);
        data.linkUrl = R.pathOr('', ['data-href'])(attributes);
        data.linkResourceName = R.pathOr('', ['data-href-resource-name'])(attributes);
        data.linkResourceType = R.pathOr('', ['data-href-resource-type'])(attributes);
        data.linkResourceKey = R.pathOr('', ['data-href-resource-key'])(attributes);
        data.linkTarget = R.pathOr(data.target, ['data-href-target'])(attributes);
      }
      const tempModel = this.crud.setupNewRecord({
        adapterName: 'websites/links',
        data
      });
      Ember.set(this, 'tempModel', tempModel);
    }
    save(onCloseDialogAction) {
      const selected = this.args.editor.getSelected();
      if (selected?.getAttributes) {
        const tempModel = this.tempModel;
        const attr = selected.getAttributes() || {};
        attr['data-href-link-type'] = tempModel?._data.linkType;
        attr['data-href-title'] = tempModel?._data.linkTitle;
        attr['data-href'] = tempModel?._data.linkUrl || tempModel?._data.linkResourceKey;
        attr['data-href-resource-name'] = tempModel?._data.linkResourceName;
        attr['data-href-resource-type'] = tempModel?._data.linkResourceType;
        attr['data-href-resource-key'] = tempModel?._data.linkResourceKey;
        attr['data-href-target'] = tempModel?._data.linkTarget;
        selected.setAttributes(attr);
      }
      onCloseDialogAction();
    }
    cancel(onCloseDialogAction) {
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websitesService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tempModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedAdapter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "allFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allSetupData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_setup", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "_setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
});