define("client/pods/components/files/uploadcare-widget/component", ["exports", "ember-concurrency-decorators", "jquery"], function (_exports, _emberConcurrencyDecorators, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: 'span',
    classNames: 'images-uploader',
    name: 'content',
    label: 'upload files',
    resource: '',
    resourceKey: '',
    uploadcare: Ember.inject.service(),
    // settings
    multiple: true,
    multipleMax: '',
    multipleMin: '',
    imagesOnly: true,
    previewStep: false,
    crop: false,
    imageShrink: '',
    clearable: false,
    // should always be false as no way to hook into "remove" function of widget
    tabs: 'file url',
    secureSignature: '',
    secureExpire: '',
    cdnBase: 'https://ucarecdn.com/',
    minSize: 0,
    maxSize: 10 * 1024 * 1024,
    // 10Mb
    didFetchSecureToken: false,
    didInsertElement() {
      const self = this;
      self._super(...arguments);
      self.setupTask.perform();
    },
    fileSizeLimit(min, max) {
      return function (fileInfo) {
        if (fileInfo.size === null) {
          return;
        }
        if (min && fileInfo.size < min) {
          throw new Error('fileMinimalSize');
        }
        if (max && fileInfo.size > max) {
          throw new Error('fileMaximumSize');
        }
      };
    },
    maxImageDimensions() {
      return function (fileInfo) {
        if (fileInfo.mimeType === null || fileInfo.isImage === null) {
          return;
        }
        const isImageMimeType = fileInfo.mimeType.match(/^image\//);
        if (isImageMimeType && !fileInfo.isImage) {
          throw new Error('maxDimensions');
        }
      };
    },
    *setupTask() {
      yield this.uploadcare.getSecureTokenTask.perform();
      this.set('didFetchSecureToken', true);
    },
    initWidget() {
      const self = this;
      self.set('error', '');
      if (self.get('resourceKey') && self.get('resource')) {
        const widget = self.get('uploadcare').getUc().initialize((0, _jquery.default)(this.element))[0];
        // uploadcare.Widget('[role=uploadcare-uploader]');

        // widget.validators.push(this.fileSizeLimit(this.minSize, this.maxSize))
        widget.validators.push(this.maxImageDimensions());
        self.set('widget', widget);
        const onUploadHandler = function (info) {
          self.updateServer(info);
          widget.onUploadComplete.remove(this);
        };
        self.set('onUploadHandler', onUploadHandler);
        widget.onUploadComplete(onUploadHandler);
      }
    },
    updateServer(info) {
      return this.onUpdateServer(info);
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        if (this.widget) {
          this.widget.onUploadComplete.remove(this.onUploadHandler);
        }
      }
    },
    actions: {
      triggerUploadBtn() {
        (0, _jquery.default)(this.element).find('.uploadcare--widget__button_type_open').click();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "setupTask"), _obj), _applyDecoratedDescriptor(_obj, "initWidget", [_dec], Object.getOwnPropertyDescriptor(_obj, "initWidget"), _obj), _obj));
});