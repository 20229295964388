define("client/pods/websites/pages/model", ["exports", "ramda", "client/pods/base/model", "client/utils/nventor", "client/mixins/templates"], function (_exports, R, _model, _nventor, _templates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj, _dec5, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const WebsitesPagesBaseModel = _model.default.extend(_templates.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    defaults() {
      return {
        css: '',
        hasTemplate: false,
        html: '',
        pageDataString: '{"html":"","css":"* { box-sizing: border-box; } body {margin: 0;}","assets":[],"styles":[],"components":[]}',
        products: [],
        pageSchedules: [],
        showOnMenu: true,
        showOnFooter: false,
        showOnFilters: false,
        templateImages: [],
        type: this.get('constants.websitePageTypes.page'),
        homePageRedirect: false,
        showBigOrdrStory: false,
        flowForms: [],
        facebookPixelKeys: [],
        googleTrackingKeys: [],
        pageBlocks: [],
        openGraph: {}
      };
    },
    populate(data, attrs) {
      data.pageBlocks = this._transformPageBlocks(data);
      data.pageSchedules = this._transformSchedules({
        data,
        schedulesProp: 'pageSchedules'
      });
      this._super(data, attrs);
    },
    _transformPageBlocks(data) {
      const pageBlocks = R.propOr([], 'pageBlocks')(data);
      return R.map(pageBlockData => {
        return this._newPageBlockModel(pageBlockData);
      })(pageBlocks);
    },
    _newPageBlockModel(pageBlockData) {
      const type = pageBlockData.type;
      return this.dataManager.setAsRecord({
        adapterName: `simple-editor/page-blocks/${type}`,
        data: pageBlockData
      });
    },
    _transformSchedules({
      data,
      schedulesProp
    }) {
      const schedules = R.propOr([], schedulesProp)(data);
      return R.map(schedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
      })(schedules);
    },
    addSchedule({
      schedule = {},
      schedulesProp = ''
    }) {
      if (schedulesProp) {
        const schedules = this.getData(schedulesProp) || [];
        const scheduleModel = this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
        schedules.pushObject(scheduleModel);
        this.setData(schedulesProp, schedules);
      }
    },
    statusClass: Ember.computed('_data.status', function () {
      // @TODO: needs to schedules into account
      const status = this.getData('status');
      if (status === this.constants.status.active) {
        return 'is-primary';
      }
      return 'is-danger';
    }),
    isPage: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.page')) {
        return true;
      }
      return false;
    }),
    isCategory: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.category')) {
        return true;
      }
      return false;
    }),
    isLink: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.link')) {
        return true;
      }
      return false;
    }),
    isProductLink: Ember.computed('isLink', '_data.linkType', function () {
      const linkType = this.get('_data.linkType');
      if (this.isLink && linkType === this.get('constants.websitePageLinkTypes.page')) {
        return true;
      }
      return false;
    }),
    allowWebBuilder: Ember.computed('model.{isPage,isLink,isCategory}', function () {
      if (this.get('model.isLink')) {
        return false;
      }
      return true;
    }),
    previewUrl: Ember.computed('_data.{_key,_rev,version}', 'synced', function () {
      const pageKey = this.get('_data._key');
      const version = this.get('_data.version');
      const previewHostingUrl = this.get('settings.config.APP.previewHostingUrl');
      const podKey = this.settings.getPodKey();
      return `${previewHostingUrl}/${podKey}/${pageKey}/${version}-preview.html`;
    }),
    isParent: Ember.computed('sub.[]', 'synced', function () {
      const sub = this.sub || [];
      if (R.isEmpty(sub)) {
        return false;
      }
      return true;
    }),
    lastParentSegment: Ember.computed('_data.parent', function () {
      const allParentKeys = this.get('_data.parent') || '';
      return R.pipe(R.split('/'), _nventor.default.safeLast)(allParentKeys);
    }),
    pageDataJson: Ember.computed('_data.pageDataString', function () {
      const pageDataString = this.get('_data.pageDataString');
      try {
        return JSON.parse(pageDataString);
      } catch (e) {
        return '';
      }
    }),
    templateFromPageDataString: Ember.computed('_data.pageDataString', function () {
      const pageDataString = this.get('_data.pageDataString');
      try {
        const pageData = JSON.parse(pageDataString);
        return R.propOr('', 'html', pageData);
      } catch (e) {
        return '';
      }
    }),
    pageName: Ember.computed('_data.{name,pageName,_rev}', 'synced', function () {
      const pageData = R.pathOr({}, ['_data'])(this);
      const pageName = R.prop('pageName')(pageData);
      if (pageData.isHome) {
        return pageName || this.intl.t('home page');
      }
      if (pageData.isDynamicPage) {
        return pageName || this.intl.t(pageData.name);
      }
      return R.propOr('no-name', 'name')(pageData);
    }),
    pageUrl: Ember.computed('_data.url', function () {
      const url = this?._data?.url || '';
      if (R.startsWith('http')(url)) {
        return url;
      }
      if (!url) {
        return '';
      }
      return `/${url}`;
    }),
    showOnLabels: Ember.computed('_data.{showOnMenu,showOnFilters,showOnFooter}', function () {
      const showOnMenu = this?._data?.showOnMenu;
      const showOnFilters = this?._data?.showOnFilters;
      const showOnFooter = this?._data?.showOnFooter;
      const showOnLabels = [];
      showOnLabels.push({
        isOn: showOnMenu,
        label: this.intl.t('webpage_show_on_menu_short')
      });
      showOnLabels.push({
        isOn: showOnFilters,
        label: this.intl.t('webpage_show_on_filters_short')
      });
      showOnLabels.push({
        isOn: showOnFooter,
        label: this.intl.t('webpage_show_on_footer_short')
      });
      return showOnLabels;
    }),
    activePageSchedule: Ember.computed('_data.pageSchedules.@each.isActive', function () {
      const pageSchedules = this.getData('pageSchedules') || [];
      return R.find(R.propEq('isActive', true))(pageSchedules);
    }),
    nextPageSchedule: Ember.computed('_data.pageSchedules.@each.isActiveNext', function () {
      const pageSchedules = this.getData('pageSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(pageSchedules);
    }),
    onSelectType() {
      const type = this.get('_data.type');
      if (type === this.constants.websitePageTypes.page) {
        this.setData('linkType', '');
        this.setData('hasTemplate', true);
        this.setData('showOnFilters', false);
        this.setData('linkType', '');
        this.setData('target', '');
      } else if (type === this.constants.websitePageTypes.link) {
        this.setData('showBreadcrumb', false);
        this.setData('linkType', this.get('constants.websitePageLinkTypes.url'));
        this.setData('target', '_self');
        this.setData('hasTemplate', false);
        this.setData('showOnFilters', false);
      } else if (type === this.constants.websitePageTypes.category) {
        this.setData('hasTemplate', false);
        this.setData('showOnFilters', true);
        this.setData('showOnFooter', false);
        this.setData('linkType', '');
        this.setData('target', '');
      }
    },
    serialize(model) {
      const data = this._super(model);

      // dont allow '__' in url
      let url = data?.url || '';
      if (url) {
        url = R.replace(/__/g, '')(url);
        url = R.replace(/--/g, '')(url);
        data.url = url;
      }
      return data;
    },
    /**
     * Adds a new page block to the model_data.pageBlocks array
     * @param {Object} pageBlock - The blank PageBlockModel to add
     * @returns {Object} The newly created PageBlockModel
     */
    addPageBlock(pageBlock) {
      const pageBlockData = pageBlock.serialize();
      const pageBlockModel = this._newPageBlockModel(pageBlockData);
      const pageBlocks = this.getData('pageBlocks') || [];
      pageBlocks.pushObject(pageBlockModel);
      this.setData('pageBlocks', pageBlocks);
      return pageBlockModel;
    },
    /**
     * Removes page block model from the model._data.pageBlocks array
     * @param {Object} pageBlock - The PageBlockModel to remove
     * @returns {Object} The removed PageBlockModel
     */
    removePageBlock(pageBlockModel) {
      const pageBlocks = this.getData('pageBlocks') || [];
      pageBlocks.removeObject(pageBlockModel);
      this.setData('pageBlocks', pageBlocks);
      return pageBlockModel;
    },
    pageBlocksSorted: Ember.computed('_data.pageBlocks.@each.synced', function () {
      const pageBlocks = this.getData('pageBlocks') || [];
      return R.sortBy(R.path(['_data', 'index']))(pageBlocks);
    })
  }, _applyDecoratedDescriptor(_obj, "onSelectType", [_dec], Object.getOwnPropertyDescriptor(_obj, "onSelectType"), _obj), _applyDecoratedDescriptor(_obj, "serialize", [_dec2], Object.getOwnPropertyDescriptor(_obj, "serialize"), _obj), _applyDecoratedDescriptor(_obj, "addPageBlock", [_dec3], Object.getOwnPropertyDescriptor(_obj, "addPageBlock"), _obj), _applyDecoratedDescriptor(_obj, "removePageBlock", [_dec4], Object.getOwnPropertyDescriptor(_obj, "removePageBlock"), _obj), _obj));
  let WebsitesPagesModel = _exports.default = (_dec5 = Ember._tracked, _class = class WebsitesPagesModel extends WebsitesPagesBaseModel {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});