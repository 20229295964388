define("client/pods/components/elements/btn-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hgjengtz",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"label\",\"tooltipLabel\",\"tooltipTranslate\",\"icon\",\"disabled\",\"isRunning\",\"onSubmit\"],[[24,[\"btnClassNames\"]],[24,[\"label\"]],[24,[\"tooltipLabel\"]],[24,[\"tooltipTranslate\"]],[24,[\"icon\"]],[24,[\"disabled\"]],[24,[\"isRunning\"]],[24,[\"onSubmit\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/btn-edit/template.hbs"
    }
  });
});