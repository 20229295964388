define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/print-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UAgq2rBI",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@document\",\"@disabled\",\"@printBtnLabel\"],\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\",\"icon\",\"tooltipLabel\",\"disabled\"],[[23,4,[]],\"fas fa-file-invoice-dollar\",\"tw_eInvoicing\",[23,3,[]]]],{\"statements\":[[0,\"  \"],[5,\"extensions/apps/tw/e-invoices/invoice-editor\",[],[[\"@document\",\"@onCloseDialogAction\"],[[23,2,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/e-invoices/print-manager/print-btn/template.hbs"
    }
  });
});