define("client/pods/status-schedules/model", ["exports", "client/pods/base/model", "client/mixins/statuses", "client/utils/nventor"], function (_exports, _model, _statuses, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @TODO: this is reused by product status schedule and onSaleSchedule and gjs schedule
  // this should not be reused as the data is different for both
  var _default = _exports.default = _model.default.extend(_statuses.default, {
    defaults() {
      return {
        dateStartZ: '',
        dateEndZ: '',
        status: 'active',
        scheduleId: _nventor.default.random.alphaNum(8),
        priceDiscounted: '',
        isOnSale: true
      };
    },
    populate(data, attrs) {
      this._super(data, attrs);
    },
    isActive: Ember.computed('_data.{dateStartZ,dateEndZ,isOnSale}', function () {
      const nowZ = this.getNowZ();
      const dateStartZ = this.getData('dateStartZ');
      const dateEndZ = this.getData('dateEndZ');
      if (dateStartZ && !dateEndZ && dateStartZ <= nowZ) {
        return true;
      }
      if (dateEndZ && !dateStartZ && dateEndZ >= nowZ) {
        return true;
      }
      if (dateStartZ <= nowZ && dateEndZ >= nowZ) {
        return true;
      }
      return false;
    }),
    isActiveNext: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const nowZ = this.getNowZ();
      const dateStartZ = this.getData('dateStartZ');
      if (dateStartZ > nowZ) {
        return true;
      }
      return false;
    })
  });
});