define("client/pods/components/reports/dashboard/card-revenue-for-year/component", ["exports", "ramda", "client/mixins/date"], function (_exports, R, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, {
    tagName: '',
    chartLegend: 'revenue',
    metric: 'net',
    chartType: null,
    chartHeight: null,
    chartWidth: null,
    translate: false,
    isTaxExclusive: true,
    averageByItemQty: false,
    isTableView: false,
    isReloadingChart: false,
    init() {
      this._super(...arguments);
      this.set('metricOptions', ['invoices', 'returns', 'net']);
    },
    autoChartHeight: Ember.computed('chartDatasets.[]', function () {
      const rows = parseInt(this.chartDatasets.length) || 1;
      const height = Math.ceil(rows * 30);
      if (height < 200) {
        return 200;
      }
      return height;
    }),
    chartData: Ember.computed('chartLegend', 'chartLabels.[]', 'chartDatasets.[]', 'isTaxExclusive', function () {
      return {
        labels: this.chartLabels,
        datasets: [{
          label: this.intl.t(this.chartLegend),
          data: this.chartDatasets
        }]
      };
    }),
    sorted: Ember.computed('data.[]', 'metric', function () {
      const metric = this.metric;
      const data = this.get(`data.${metric}`);
      if (Ember.isEmpty(data)) {
        return [];
      }
      const sort = R.pipe(R.map(detail => {
        const MM = this.getMM(detail.segmentValue.mm);
        const yyyy = detail.segmentValue.yyyy;
        if (MM && yyyy) {
          detail.segmentValue = `${yyyy}-${MM}-01`;
        } else {
          detail.segmentValue = '';
        }
        return detail;
      }), R.sortWith([R.ascend(R.prop('segmentValue'))]));
      return sort(data);
    }),
    chartLabels: Ember.computed('sorted.[]', 'scale', function () {
      const sorted = this.sorted;
      if (Ember.isEmpty(sorted)) {
        return [];
      }
      const formatDateFor = R.pipe(R.pluck('segmentValue'), R.map(date => {
        if (date) {
          return this.formatDate(date, 'YYYY-MM');
        }
        return '';
      }));
      return formatDateFor(sorted);
    }),
    chartDatasets: Ember.computed('sorted.[]', 'isTaxExclusive', function () {
      const sorted = this.sorted;
      if (Ember.isEmpty(sorted)) {
        return [];
      }
      const valueParam = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';
      return R.pluck(valueParam, sorted);
    }),
    getExcl(param, type) {
      return this.get(`${param}.${type}`);
    },
    getIncl(param, type) {
      return this.get(`${param}.${type}`);
    },
    getValue(param, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(param, exclParam) : this.getIncl(param, inclParam);
    },
    // total: computed('report.revenue.net.{exclTotal,inclTotal}', 'isTaxExclusive', function () {
    //   return this.getValue('report.revenue.net', 'exclTotal', 'inclTotal')
    // })

    actions: {
      toggleView() {
        this.toggleProperty('isTableView');
      }
    }
  });
});