define("client/pods/components/extensions/apps/tw/t-cat/settings-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "g+qNgQnb",
    "block": "{\"symbols\":[\"@errors\"],\"statements\":[[4,\"if\",[[23,0,[\"transporterModel\"]]],null,{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@translate\",\"@errors\",\"@context\",\"@documentationResource\"],[[23,0,[\"transporterModel\",\"_data\",\"name\"]],false,[23,1,[]],\"twTCat\",\"name\"]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"extensions/apps/tw/t-cat/create-contact\",[],[[\"@onLoadTransporterContact\",\"@label\",\"@model\"],[[23,0,[\"loadTransporter\"]],\"update t-cat contact\",[23,0,[\"transporterModel\"]]]]],[0,\"\\n\\n    \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@icon\",\"@onSubmit\"],[\"unlink\",\"fas fa-unlink\",[28,\"perform\",[[23,0,[\"unlinkTransporterTask\"]]],null]]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"extensions/apps/tw/t-cat/create-contact\",[],[[\"@onLoadTransporterContact\",\"@label\",\"@model\"],[[23,0,[\"loadTransporter\"]],\"create t-cat contact\",[23,0,[\"transporterModel\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/t-cat/settings-editor/template.hbs"
    }
  });
});