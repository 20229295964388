define("client/pods/components/bot/message-window-toggler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GEIS6rvS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bot-chat-toggle\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleBotMessageWindow\"],null]],[8],[0,\"\\n\"],[0,\"    \"],[7,\"figure\",true],[10,\"style\",\"margin: auto\"],[10,\"class\",\"image is-48x48\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"class\",\"is-rounded\"],[10,\"src\",\"https://img.icons8.com/plasticine/344/bot.png\"],[10,\"alt\",\"User Profile Picture\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"status\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/bot/message-window-toggler/template.hbs"
    }
  });
});