define("client/pods/simple-editor/lists/styling/colors/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: 'black',
        color: '#0A0A0A'
      }, {
        label: 'white',
        color: '#fefefe'
      }, {
        label: 'gray',
        color: '#8d8d8d'
      }, {
        label: 'red',
        color: '#c24219'
      }, {
        label: 'green',
        color: '#3da33d'
      }, {
        label: 'blue',
        color: '#256ed1'
      }, {
        label: 'yellow',
        color: '#ffec3d'
      }, {
        label: 'orange',
        color: '#CC8400'
      }, {
        label: 'purple',
        color: '#660066'
      }, {
        label: 'brown',
        color: '#8B4513'
      }];
      return _nventor.default.resolve(data);
    }
  });
});