define("client/pods/components/extensions/apps/tw/e-invoices/current-status/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwEInvoicesCurrentStatusComponent = _exports.default = (_dec = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service('helpers'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('terminal'), _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed('args.{document._data.twEInvoiceUserKey,custom.twEInvoiceUserKey}'), _dec13 = Ember.computed('args.custom.{printerKey,twEInvoicePrinterId}', 'refresh'), _class = class ExtensionsAppsTwEInvoicesCurrentStatusComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "eInvoicesService", _descriptor, this);
      _initializerDefineProperty(this, "dateService", _descriptor2, this);
      _initializerDefineProperty(this, "helpersService", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "settings", _descriptor5, this);
      _initializerDefineProperty(this, "terminalService", _descriptor6, this);
      _initializerDefineProperty(this, "constants", _descriptor7, this);
      /** @type {{_data: {currentNumbers: {_data: {userKey: string}}[]}}} */
      _initializerDefineProperty(this, "availableCountData", _descriptor8, this);
      _initializerDefineProperty(this, "einvoicesPrintersTerminalsFilters", _descriptor9, this);
      _initializerDefineProperty(this, "hasAnyEInvoicesPrinterTerminals", _descriptor10, this);
      _initializerDefineProperty(this, "refresh", _descriptor11, this);
      _defineProperty(this, "availableCountToIssueThreshold", 50);
    }
    *setupTask() {
      // const customData = this.args.custom
      // const documentModel = this.args.document
      // if (RA.isNotNil(customData) && documentModel && !customData?.twEInvoiceUserKey) {
      //   set(customData, 'twEInvoiceUserKey', documentModel._data.twEInvoiceUserKey || '')
      //   set(customData, 'twEInvoicePrinterId', documentModel._data.twEInvoicePrinterId || '')
      // }

      yield this.fetchTerminalsDataTask.perform();
      yield this.fetchAvailableCountDataTask.perform();
    }
    *fetchTerminalsDataTask() {
      const einvoicesPrintersTerminalsFilters = this.search.setupFilters({
        adapterName: 'users/einvoices-printers-terminals/filters'
      });
      this.einvoicesPrintersTerminalsFilters = einvoicesPrintersTerminalsFilters;
      const results = yield this.terminalService.fetchEInvoicesPrinterTerminalsTask.perform({
        filters: einvoicesPrintersTerminalsFilters
      });
      if (results?.length > 0) {
        this.hasAnyEInvoicesPrinterTerminals = true;
      }
    }
    get userKey() {
      const documentModel = this.args.document;
      if (documentModel?._data?.twEInvoiceUserKey) {
        return documentModel?._data?.twEInvoiceUserKey;
      }
      const customData = this.args.custom;
      if (customData?.twEInvoiceUserKey) {
        return customData?.twEInvoiceUserKey;
      }
      return '';
    }
    get disableIssueAndPrint() {
      if (!this.userKey) {
        return true;
      }
      if (this.hasInvalidPrinterSetup) {
        return true;
      }
      if (!this.availableCountData?.hasRemainingCount) {
        return true;
      }
      if (this.settings.getProp('tw_eInvoice_systemlead.enabled') && this.availableCountData?.availableCountToIssue <= 0) {
        return true;
      }
      return false;
    }
    get disableReprint() {
      if (!this.userKey) {
        return true;
      }
      if (this.hasInvalidPrinterSetup) {
        return true;
      }
      return false;
    }
    get hasInvalidPrinterSetup() {
      if (!this.settings.getProp('tw_eInvoice_systemlead.enabled')) {
        return false;
      }
      if (!this.userKey) {
        return false;
      }
      const customData = this.args.custom || {};
      if (!customData?.twEInvoicePrinterId) {
        return true;
      }
      return false;
    }
    get isUnderThreshold() {
      if (this.availableCountData.availableCountToIssue <= this.availableCountToIssueThreshold) {
        return true;
      }
      return false;
    }
    *selectPrinterTerminalTask(selectedUserModel) {
      const customData = this.args.custom || {};
      if (RA.isNilOrEmpty(customData)) {
        return _nventor.default.throw('no custom data passed in on the template');
      }
      const userKey = selectedUserModel?._data?._key || '';
      Ember.set(customData, 'printerKey', selectedUserModel?._data?.printerKey); // @deprecated? prob not used but still kept for legacy purposes
      Ember.set(customData, 'twEInvoicePrinterId', selectedUserModel?._data?.printerId);
      Ember.set(customData, 'twEInvoiceAllowPrintingFrom', selectedUserModel?._data?.twEInvoiceAllowPrintingFrom);
      this.refresh = Math.random();
      yield this.fetchAvailableCountDataTask.perform(userKey);
    }
    *fetchAvailableCountDataTask(userKey) {
      userKey = userKey || this.userKey;
      if (this.args.isPrintOnly && !userKey) {
        // dont fetch any available count data when printer user key has not yet been selected.
        // it will only show available count for non-printing
        return;
      }
      const fetchAvailableCountToIssueTaskOptions = {
        userKey
      };
      this.availableCountData = yield this.eInvoicesService.fetchAvailableCountToIssueTask.perform(fetchAvailableCountToIssueTaskOptions);
    }
    get showAvailableCountData() {
      if (this.args.isReprint) {
        return false;
      }
      if (this.args.isPrintOnly && !this.userKey) {
        return false;
      }
      if (RA.isNilOrEmpty(this.availableCountData)) {
        return false;
      }
      return true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "helpersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "terminalService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "availableCountData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "einvoicesPrintersTerminalsFilters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasAnyEInvoicesPrinterTerminals", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "refresh", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTerminalsDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTerminalsDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userKey", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "userKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasInvalidPrinterSetup", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "hasInvalidPrinterSetup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPrinterTerminalTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectPrinterTerminalTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchAvailableCountDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAvailableCountDataTask"), _class.prototype), _class);
});