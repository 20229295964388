define("client/pods/grapesjs/plugins/tabs/index", ["exports", "client/pods/grapesjs/plugins/tabs/components/index", "client/pods/grapesjs/plugins/tabs/blocks", "client/pods/grapesjs/plugins/tabs/options", "ramda"], function (_exports, _index, _blocks, _options, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: index
   * @author Nafis Rubio
   * @since 27/03/2021
   * @copyright (C) 2021 BigOrdr
   * @description: $DESCRIPTION$
   */
  var _default = editor => {
    const customOptions = R.pathOr({}, ['Config', 'pluginsOpts', 'bigordr-tabs'])(editor);
    const defOptions = (0, _options.default)(customOptions);
    const options = {
      ...defOptions,
      ...customOptions
    };

    // Add components
    (0, _index.default)(editor, options);

    // Add blocks
    (0, _blocks.default)(editor, options);
    editor.on('component:remove', component => {
      if (component?.is('tabs')) {
        component.set('removeAll', true);
      }
    });
  };
  _exports.default = _default;
});