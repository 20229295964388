define("client/pods/components/documents/document-print/print-list/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    hasPrices: true,
    hasGroups: true,
    hasReturnsDetails: true,
    // init () {
    //   this._super(...arguments)

    //   this.
    // },

    // togglePrintAllAsPackingList () {
    //   this.toggleProperty('printAsPackingList')
    //   const all = this.get('results') || []
    //   all.forEach(doc => doc.toggleProperty('printAsPackingList'))
    // },

    // togglePrintAllAsPackingListOn () {
    //   this.set('printAsPackingList', true)
    //   const all = this.get('results') || []
    //   all.forEach(doc => doc.set('printAsPackingList', true))
    // },

    // togglePrintAsPackingList (model) {
    //   model.toggleProperty('printAsPackingList')
    // },

    toPrint: Ember.computed('results.@each.{print}', 'filter', 'updated', function () {
      const all = this.results;
      const filter = this.filter;
      const mapIndexed = R.addIndex(R.map);
      return R.pipe(R.filter(doc => {
        if (R.prop('print', doc) === false && !filter) {
          return false;
        }
        if (filter) {
          const data = R.prop('_data', doc);
          const values = R.pipe(R.pick(['telephone', 'ref', 'docNo', 'contact', 'transporterContactPerson', '', 'address']), R.values)(data);
          const matches = R.filter(value => {
            if (value.indexOf(filter) > -1) {
              return true;
            }
            return false;
          })(values);
          if (matches.length > 0) {
            return true;
          }
          return false;
        }
        return true;
      }), mapIndexed((doc, i) => {
        doc.set('index', i);
        return doc;
      }))(all);
    }),
    hiddenItems: Ember.computed('results.@each.print', 'filter', function () {
      const results = this.results;
      return R.reject(R.prop('print'), results);
    }),
    actions: {
      togglePrint(model) {
        model.toggleProperty('print', false);
      },
      togglePrintAll() {
        this.set('filter', '');
        const all = this.results || [];
        all.forEach(doc => doc.set('print', true));
      },
      toggleHidePrintAll() {
        this.set('filter', '');
        const all = this.results || [];
        all.forEach(doc => doc.set('print', false));
      },
      // setPrintStyle (selected) {
      //   const printStyle = selected._data.value
      //   this.storage.set('printListStyle', printStyle)
      // },
      // togglePrintAsPackingList () {
      //   this.togglePrintAsPackingList()
      // },

      // togglePrintAllAsPackingList () {
      //   this.togglePrintAllAsPackingList()
      // },

      refreshList() {
        this.set('updated', Math.random());
        // const results = this.get('results')
        // this.set('results', results)
      }
    }
  });
});