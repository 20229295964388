define("client/pods/components/transporters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uL+P8KTo",
    "block": "{\"symbols\":[\"@isReadonly\",\"@allowCreate\",\"@model\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"_data\",\"transporterExtension\"]],[23,0,[\"constants\",\"transporterExtensions\",\"tCat\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"extensions/apps/tw/t-cat/create-contact\",[],[[\"@isReadonly\",\"@disabled\",\"@allowCreate\",\"@disallowDeselect\",\"@fetchTransporter\",\"@label\",\"@model\",\"@transporterModel\"],[[23,1,[]],[23,1,[]],[23,2,[]],true,false,\"update t-cat contact\",[23,3,[]],[23,3,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"_data\",\"transporterExtension\"]],[23,0,[\"constants\",\"transporterExtensions\",\"hct\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[5,\"extensions/apps/tw/hct/create-contact\",[],[[\"@disabled\",\"@isReadonly\",\"@allowCreate\",\"@label\",\"@model\",\"@disallowDeselect\"],[[23,1,[]],[23,1,[]],[23,2,[]],\"update hct contact\",[23,3,[]],true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"_data\",\"transporterExtension\"]],[23,0,[\"constants\",\"transporterExtensions\",\"sevenEleven\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"extensions/apps/tw/seven-eleven/create-contact\",[],[[\"@isReadonly\",\"@disabled\",\"@allowCreate\",\"@label\",\"@model\",\"@transporterModel\",\"@disallowDeselect\"],[[23,1,[]],[23,1,[]],[23,2,[]],\"update seven-eleven contact\",[23,3,[]],[23,3,[]],true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[28,\"tt\",[\"no transporter selected\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/template.hbs"
    }
  });
});