define("client/pods/components/store-location/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GrLvKkk1",
    "block": "{\"symbols\":[\"@errors\",\"@onCancel\",\"@onDisplay\",\"@tab\"],\"statements\":[[5,\"store-location/store-location-editor\",[],[[\"@errors\",\"@isNew\",\"@model\",\"@onCancel\",\"@onDisplay\",\"@saveTask\",\"@tab\"],[[23,1,[]],true,[23,0,[\"model\"]],[23,2,[]],[23,3,[]],[23,0,[\"crud\",\"createRecordTask\"]],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/store-location/panels-new/template.hbs"
    }
  });
});