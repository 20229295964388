define("client/pods/components/settings/files/files-gallery-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MmoXU9ZE",
    "block": "{\"symbols\":[],\"statements\":[[5,\"files-manager\",[],[[\"@tab\",\"@allowRemove\",\"@allowShowPreviewModal\",\"@model\",\"@resource\",\"@resourceKey\",\"@showDefaultImagePreview\",\"@allowMultipleItems\",\"@allowUploads\"],[[22,\"tab\"],[22,\"allowRemove\"],[22,\"allowShowPreviewModal\"],[22,\"model\"],[22,\"resource\"],[22,\"resourceKey\"],[22,\"showDefaultImagePreview\"],[22,\"allowMultipleItems\"],[22,\"allowUploads\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/files/files-gallery-uploader/template.hbs"
    }
  });
});