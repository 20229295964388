define("client/pods/components/reports/commissions/panels-display/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReportsCommissionsPanelsDisplay = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _class = class ReportsCommissionsPanelsDisplay extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "date", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "model", _descriptor5, this);
      _initializerDefineProperty(this, "commissionsGeneratedInvoicesFilters", _descriptor6, this);
      _initializerDefineProperty(this, "commissionsGeneratedReturnedFilters", _descriptor7, this);
      _initializerDefineProperty(this, "commissionsDueInvoicesFilters", _descriptor8, this);
      _initializerDefineProperty(this, "commissionsDueReturnedFilters", _descriptor9, this);
      _initializerDefineProperty(this, "commissionsRecordsFilters", _descriptor10, this);
      _initializerDefineProperty(this, "commissionsPayoutsFilters", _descriptor11, this);
      _initializerDefineProperty(this, "bySalesPersonsFilters", _descriptor12, this);
      _initializerDefineProperty(this, "bySalesPersonsSummary", _descriptor13, this);
      _initializerDefineProperty(this, "mm", _descriptor14, this);
      _initializerDefineProperty(this, "yyyy", _descriptor15, this);
      _defineProperty(this, "adapterName", 'reports/commissions/data');
      _defineProperty(this, "filtersName", 'reports/commissions/data/filters');
      this.crud.addLists(this, ['periods/months', 'periods/years']);
      const commissionsGeneratedInvoicesFilters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      const commissionsGeneratedReturnedFilters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      const commissionsDueInvoicesFilters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      const commissionsDueReturnedFilters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      const commissionsRecordsFilters = this.search.setupFilters({
        adapterName: 'reports/commissions/data/adjustment/filters'
      });
      const commissionsPayoutsFilters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      this.commissionsGeneratedInvoicesFilters = commissionsGeneratedInvoicesFilters;
      this.commissionsGeneratedReturnedFilters = commissionsGeneratedReturnedFilters;
      this.commissionsDueInvoicesFilters = commissionsDueInvoicesFilters;
      this.commissionsDueReturnedFilters = commissionsDueReturnedFilters;
      this.commissionsRecordsFilters = commissionsRecordsFilters;
      this.commissionsPayoutsFilters = commissionsPayoutsFilters;
    }
    afterLoadLists() {
      const model = this?.args?.tab?.model || this?.args?.model;
      this.model = model;
      this.getMonthAndYear();
      this.makeSummaryFilter();
      this.setup();
    }
    makeSummaryFilter() {
      const model = this.model;
      const bySalesPersonsFilters = this.search.setupFilters({
        adapterName: 'reports/commissions/data/all/filters'
      });
      const salesPersons = {
        name: model._data.name,
        key: model._data._key,
        username: model._data.username
      };
      bySalesPersonsFilters.setData('salesPersons', RA.list(salesPersons));
      this.bySalesPersonsFilters = bySalesPersonsFilters;
    }
    setup() {
      this.loadDataTask.perform();
    }
    getMonthAndYear() {
      const yyyy = this.args.yyyy;
      const mm = this.args.mm;
      let startOfMonth = '';
      if (yyyy.value && mm.value) {
        startOfMonth = `${yyyy.value}-${mm.value}-01`;
      }
      const period = this.date.getPeriod(startOfMonth);
      this.yyyy = {
        value: period.yyyy,
        label: period.yyyy
      };
      this.mm = {
        value: period.mm,
        label: R.pipe(R_.dotPathOr([], 'periods-months'), R.find(lists => R_.dotPathEq('_data.value', period.mm, lists)), R_.dotPathOr('', '_data.label'))(this.crud.lists)
      };
    }
    *loadDataTask() {
      const model = this.model;
      const salesPersonKey = R_.dotPathOr('', '_data._key', model);

      // GENERATED INVOICE FILTERS
      const commissionsGeneratedInvoicesFilters = this.commissionsGeneratedInvoicesFilters;
      commissionsGeneratedInvoicesFilters.setData('salesPersonKey', salesPersonKey);
      commissionsGeneratedInvoicesFilters.setData('mm', this.mm.value);
      commissionsGeneratedInvoicesFilters.setData('yyyy', this.yyyy.value);
      commissionsGeneratedInvoicesFilters.setData('type', 'generated');
      commissionsGeneratedInvoicesFilters.setData('docName', 'invoices');
      commissionsGeneratedInvoicesFilters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionsGeneratedInvoicesFilters
      });

      // COMMISSIONS RECORD FILTERS
      const commissionsRecordsFilters = this.commissionsRecordsFilters;
      commissionsRecordsFilters.setData('salesPersonKey', salesPersonKey);
      commissionsRecordsFilters.setData('query', '');
      commissionsRecordsFilters.setData('mm', this.mm.value);
      commissionsRecordsFilters.setData('yyyy', this.yyyy.value);
      yield this.crud.searchRecordsTask.perform({
        adapterName: 'reports/commissions/data/adjustment',
        filters: commissionsRecordsFilters
      });

      // COMMISSIONS PAYOUTS FILTERS
      const commissionsPayoutsFilters = this.commissionsPayoutsFilters;
      commissionsPayoutsFilters.setData('salesPersonKey', salesPersonKey);
      commissionsPayoutsFilters.setData('mm', this.mm.value);
      commissionsPayoutsFilters.setData('yyyy', this.yyyy.value);
      commissionsPayoutsFilters.setData('type', 'payouts');
      commissionsPayoutsFilters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionsPayoutsFilters
      });

      // GENERATED RETURNED FILTERS
      const commissionsGeneratedReturnedFilters = this.commissionsGeneratedReturnedFilters;
      commissionsGeneratedReturnedFilters.setData('salesPersonKey', salesPersonKey);
      commissionsGeneratedReturnedFilters.setData('mm', this.mm.value);
      commissionsGeneratedReturnedFilters.setData('yyyy', this.yyyy.value);
      commissionsGeneratedReturnedFilters.setData('type', 'generated');
      commissionsGeneratedReturnedFilters.setData('docName', 'returns');
      commissionsGeneratedReturnedFilters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionsGeneratedReturnedFilters
      });

      // DUE INVOICES FILTERS
      const commissionsDueInvoicesFilters = this.commissionsDueInvoicesFilters;
      commissionsDueInvoicesFilters.setData('salesPersonKey', salesPersonKey);
      commissionsDueInvoicesFilters.setData('mm', this.mm.value);
      commissionsDueInvoicesFilters.setData('yyyy', this.yyyy.value);
      commissionsDueInvoicesFilters.setData('type', 'due');
      commissionsDueInvoicesFilters.setData('docName', 'invoices');
      commissionsDueInvoicesFilters.setData('query', '');
      commissionsDueInvoicesFilters.setData('isDispatched', true);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionsDueInvoicesFilters
      });

      // DUE RETURNED FILTERS
      const commissionsDueReturnedFilters = this.commissionsDueReturnedFilters;
      commissionsDueReturnedFilters.setData('salesPersonKey', salesPersonKey);
      commissionsDueReturnedFilters.setData('mm', this.mm.value);
      commissionsDueReturnedFilters.setData('yyyy', this.yyyy.value);
      commissionsDueReturnedFilters.setData('type', 'due');
      commissionsDueReturnedFilters.setData('docName', 'returns');
      commissionsDueReturnedFilters.setData('query', '');
      commissionsDueReturnedFilters.setData('isDispatched', true);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionsDueReturnedFilters
      });

      // SUMMARY TABLE VIEW
      const bySalesPersonsFilters = this.bySalesPersonsFilters;
      bySalesPersonsFilters.setData('query', '');
      bySalesPersonsFilters.setData('mm', this.mm.value);
      bySalesPersonsFilters.setData('yyyy', this.yyyy.value);
      bySalesPersonsFilters.setData('isDispatched', true);
      yield this.crud.searchRecordsTask.perform({
        adapterName: 'reports/commissions/data/all',
        filters: bySalesPersonsFilters,
        appendPath: '/by-sales-persons'
      });
      this.bySalesPersonsSummary = R.head(this.bySalesPersonsFilters.results);

      // SET FILTERS TO THIS
      this.bySalesPersonsFilters = bySalesPersonsFilters;
      this.commissionsGeneratedInvoicesFilters = commissionsGeneratedInvoicesFilters;
      this.commissionsGeneratedReturnedFilters = commissionsGeneratedReturnedFilters;
      this.commissionsDueInvoicesFilters = commissionsDueInvoicesFilters;
      this.commissionsDueReturnedFilters = commissionsDueReturnedFilters;
      this.commissionsRecordsFilters = commissionsRecordsFilters;
      this.commissionsPayoutsFilters = commissionsPayoutsFilters;
    }
    get hasCommissionsGenerated() {
      const docs = this?.commissionsGeneratedInvoicesFilters?.results || [];
      const files = R_.dotPathOr('', 'generated._data.invoices', docs);
      if (!Ember.isEmpty(files)) {
        return true;
      }
      return false;
    }
    get hasCommissionsAdjustment() {
      const docs = this?.commissionsRecordsFilters || [];
      const files = R_.dotPathOr('', 'results', docs);
      if (!Ember.isEmpty(files)) {
        return true;
      }
      return false;
    }
    get hasCommissionsPayouts() {
      const docs = this?.commissionsPayoutsFilters?.results || [];
      const files = R_.dotPathOr('', 'generated._data.payouts', docs);
      if (!Ember.isEmpty(files)) {
        return true;
      }
      return false;
    }
    get hasCommissionsGeneratedReturned() {
      const docs = this?.commissionsGeneratedReturnedFilters?.results || [];
      const files = R_.dotPathOr('', 'generated._data.returns', docs);
      if (!Ember.isEmpty(files)) {
        return true;
      }
      return false;
    }
    get hasCommissionsDue() {
      const docs = this?.commissionsDueInvoicesFilters?.results || [];
      const files = R_.dotPathOr('', 'due._data.invoices', docs);
      if (!Ember.isEmpty(files)) {
        return true;
      }
      return false;
    }
    get hasCommissionsDueReturned() {
      const docs = this?.commissionsDueReturnedFilters?.results || [];
      const files = R_.dotPathOr('', 'due._data.returns', docs);
      if (!Ember.isEmpty(files)) {
        return true;
      }
      return false;
    }
    get hasNoCommission() {
      const hasGeneratedInvoicesComissions = this.hasCommissionsGenerated;
      const hasGeneratedReturnedComissions = this.hasCommissionsGeneratedReturned;
      const hasDueInvoicesComissions = this.hasCommissionsDue;
      const hasDueReturnedComissions = this.hasCommissionsDueReturned;
      const hasCommissionsAdjustment = this.hasCommissionsAdjustment;
      const hasCommissionsPayouts = this.hasCommissionsPayouts;
      return hasCommissionsAdjustment || hasCommissionsPayouts || hasGeneratedInvoicesComissions || hasGeneratedReturnedComissions || hasDueInvoicesComissions || hasDueReturnedComissions;
    }
    get hasContactLink() {
      const model = this?.model || {};
      if (RA.isNotNilOrEmpty(model)) {
        const id = R.pathOr('', ['_data', '_id'])(model);
        if (R.includes('contacts', id)) {
          return true;
        }
      }
      return false;
    }
    generateSummaryAndTable() {
      this.setup();
    }
    reloadAfterPayout() {
      const model = this?.args?.tab?.model || this?.args?.model;
      this.args.onDisplayWithDate(model, this.yyyy, this.mm);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "commissionsGeneratedInvoicesFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "commissionsGeneratedReturnedFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "commissionsDueInvoicesFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "commissionsDueReturnedFilters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "commissionsRecordsFilters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "commissionsPayoutsFilters", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "bySalesPersonsFilters", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "bySalesPersonsSummary", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateSummaryAndTable", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "generateSummaryAndTable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadAfterPayout", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "reloadAfterPayout"), _class.prototype), _class);
});