define("client/pods/components/documents/links-conflict/discount-conflict/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l7JDjOp+",
    "block": "{\"symbols\":[\"linkFromDetail\"],\"statements\":[[7,\"td\",false],[12,\"class\",[29,[\"documents__conflict-option u-container \",[28,\"if\",[[24,[\"isSelected\"]],\"is-selected\"],null]]]],[3,\"action\",[[23,0,[]],[24,[\"onSelectConflictResolution\"]],[24,[\"conflictCategory\"]],[24,[\"valueGroup\"]]]],[8],[0,\"\\n  \"],[7,\"h3\",true],[11,\"class\",[29,[\"has-text-centered \",[28,\"if\",[[24,[\"isSelected\"]],\"has-text-link\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"valueGroup\",\"value\",\"discountAmount\"]]],null,{\"statements\":[[0,\"      $\"],[1,[28,\"number\",[[24,[\"valueGroup\",\"value\",\"discountAmount\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"number\",[[24,[\"valueGroup\",\"value\",\"discountRate\"]]],null],false],[0,\"%  \\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"valueGroup\",\"linkFromDetails\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"u-desc\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"isEditorValue\"]]],null,{\"statements\":[[0,\"          #\"],[1,[28,\"tt\",[\"current document\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          #\"],[1,[23,1,[\"_data\",\"doc\",\"docNo\"]],false],[0,\" \"],[4,\"if\",[[23,1,[\"_data\",\"doc\",\"ref\"]]],null,{\"statements\":[[0,\" . \"],[1,[23,1,[\"_data\",\"doc\",\"ref\"]],false],[0,\" \"]],\"parameters\":[]},null],[0,\" - \"],[1,[23,1,[\"_data\",\"item\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/links-conflict/discount-conflict/template.hbs"
    }
  });
});