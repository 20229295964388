define("client/pods/components/contacts/search-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'div',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['contacts/lists/relationships', 'contacts/lists/tags', 'statuses']);
    },
    statusTagClassName: Ember.computed('result._data.status', function () {
      const status = this.get('result._data.status');
      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    })
  });
});