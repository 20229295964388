define("client/pods/date/lists/unit-of-time/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/base/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class OpenHours extends _model.default {}
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: OpenHours,
    idParam: false,
    findAll() {
      const data = [{
        //   value: 'milliseconds',
        //   label: 'milliseconds'
        // }, {
        //   value: 'seconds',
        //   label: 'seconds'
        // }, {
        value: 'minutes',
        label: 'minutes'
      }, {
        value: 'hours',
        label: 'hours'
      }, {
        value: 'days',
        label: 'days'
      }, {
        value: 'weeks',
        label: 'weeks'
      }, {
        value: 'months',
        label: 'months'
      }, {
        //   value: 'quarters',
        //   label: 'quarters'
        // }, {
        value: 'years',
        label: 'years'
      }];
      return _nventor.default.resolve(data);
    }
  });
});