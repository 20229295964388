define("client/pods/components/helpers/info-tooltip-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4NJlN38j",
    "block": "{\"symbols\":[\"@debug\",\"@modalPos\",\"@isTabLink\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"isViewable\"]],[24,[\"hasContent\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"modals/modal-container-new\",[],[[\"@debug\",\"@modalPos\",\"@closeOnOverlayClick\",\"@hasModalOverlay\",\"@hasCustomBlock\",\"@modelContainerClassNames\",\"@onToggleModalDialog\"],[[23,1,[]],[23,2,[]],true,[22,\"hasModalOverlay\"],[22,\"hasCustomModalBlock\"],[29,[[28,\"if\",[[23,3,[]],\"is-tab-link-tooltip\",\"info-tooltip\"],null],\" is-tooltip info-tooltip-modal\"]],[28,\"fn\",[[23,0,[\"onToggleModalDialog\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[14,4],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/info-tooltip-content/template.hbs"
    }
  });
});