define("client/pods/channels/shop/campaigns/lists/products-images-ratio/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: 'oneByOne',
        label: 'oneByOne',
        svgIconTop: '<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.b{fill:#58585b;}</style></defs><path class="b" d="M112.5,112.5H37.5V37.5H112.5V112.5Zm-73.53-1.47H111.03V38.97H38.97V111.03Z"/><g><path class="b" d="M63.26,67.21v18.12h-2.77v-14.79c-1.09,1.04-2.49,1.8-4.16,2.28v-2.74c.81-.2,1.65-.56,2.56-1.07,.91-.56,1.67-1.14,2.28-1.8h2.08Z"/><path class="b" d="M71.4,72.21l2.89,4.16,2.87-4.16h3.15l-4.54,6.14,5.1,6.98h-3.15l-3.43-5-3.45,5h-3.17l5.13-6.98-4.54-6.14h3.15Z"/><path class="b" d="M90.86,67.21v18.12h-2.77v-14.79c-1.09,1.04-2.49,1.8-4.16,2.28v-2.74c.81-.2,1.65-.56,2.56-1.07,.91-.56,1.67-1.14,2.28-1.8h2.08Z"/></g></svg>'
      }, {
        value: 'fourByThree',
        label: 'fourByThree',
        svgIconTop: '<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.b{fill:#58585b;}</style></defs><path class="b" d="M124.95,112.5H25.05V37.5H124.95V112.5Zm-98.42-1.47H123.47V38.97H26.53V111.03Z"/><g><path class="b" d="M63.14,66.9v11.98h2.74v2.21h-2.74v3.93h-2.64v-3.93h-8.83v-2.61l8.93-11.57h2.54Zm-9.21,11.98h6.57v-8.48h-.08l-6.5,8.48Z"/><path class="b" d="M72.15,71.89l2.89,4.16,2.87-4.16h3.15l-4.54,6.14,5.1,6.98h-3.15l-3.42-5-3.45,5h-3.17l5.13-6.98-4.54-6.14h3.15Z"/><path class="b" d="M95.82,67.86c1.07,.89,1.62,2.11,1.62,3.65,0,1.98-1.01,3.32-2.99,3.98,1.07,.33,1.88,.84,2.44,1.5,.61,.69,.91,1.57,.91,2.64,0,1.67-.58,3.04-1.73,4.11-1.22,1.07-2.82,1.62-4.75,1.62s-3.35-.48-4.47-1.42c-1.27-1.07-1.98-2.61-2.13-4.64h2.79c.05,1.22,.43,2.16,1.19,2.82,.66,.61,1.52,.91,2.59,.91,1.17,0,2.11-.36,2.82-1.01,.61-.61,.94-1.34,.94-2.26,0-1.07-.33-1.85-.99-2.36-.61-.51-1.52-.76-2.71-.76h-1.32v-2.08h1.29c1.12,0,1.95-.25,2.51-.74,.56-.48,.86-1.19,.86-2.11s-.28-1.6-.79-2.08c-.56-.51-1.4-.74-2.46-.74s-1.95,.28-2.56,.84c-.61,.56-.99,1.4-1.09,2.51h-2.71c.15-1.8,.81-3.2,2-4.21,1.12-1.01,2.56-1.5,4.34-1.5s3.27,.43,4.39,1.32Z"/></g></svg>'
      }, {
        value: 'twoByThree',
        label: 'twoByThree',
        svgIconTop: '<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.b{fill:#58585b;}</style></defs><path class="b" d="M112.5,131.25H37.5V18.75H112.5v112.5Zm-73.53-1.48H111.03V20.23H38.97v109.54Z"/><g><path class="b" d="M64.34,68.76c1.09,1.02,1.65,2.31,1.65,3.91s-.61,2.99-1.78,4.29c-.71,.74-1.95,1.7-3.75,2.92-1.95,1.29-3.12,2.44-3.5,3.42h9.06v2.44h-12.48c0-1.8,.58-3.38,1.8-4.75,.66-.79,2.03-1.88,4.08-3.3,1.22-.86,2.08-1.55,2.59-2.08,.81-.91,1.24-1.9,1.24-2.97s-.28-1.78-.84-2.28c-.56-.51-1.4-.76-2.46-.76-1.14,0-2,.38-2.59,1.17-.61,.76-.91,1.85-.96,3.32h-2.77c.03-2.03,.61-3.65,1.75-4.87,1.17-1.32,2.74-1.95,4.67-1.95,1.73,0,3.17,.48,4.29,1.5Z"/><path class="b" d="M73.14,72.61l2.89,4.16,2.87-4.16h3.15l-4.54,6.14,5.1,6.98h-3.15l-3.43-5-3.45,5h-3.17l5.13-6.98-4.54-6.14h3.15Z"/><path class="b" d="M96.82,68.58c1.07,.89,1.62,2.11,1.62,3.65,0,1.98-1.01,3.32-2.99,3.98,1.07,.33,1.88,.84,2.44,1.5,.61,.69,.91,1.57,.91,2.64,0,1.67-.58,3.04-1.73,4.11-1.22,1.07-2.82,1.62-4.75,1.62s-3.35-.48-4.47-1.42c-1.27-1.07-1.98-2.61-2.13-4.64h2.79c.05,1.22,.43,2.16,1.19,2.82,.66,.61,1.52,.91,2.59,.91,1.17,0,2.11-.36,2.82-1.01,.61-.61,.94-1.34,.94-2.26,0-1.07-.33-1.85-.99-2.36-.61-.51-1.52-.76-2.71-.76h-1.32v-2.08h1.29c1.12,0,1.95-.25,2.51-.74,.56-.48,.86-1.19,.86-2.11s-.28-1.6-.79-2.08c-.56-.51-1.4-.74-2.46-.74s-1.95,.28-2.56,.84c-.61,.56-.99,1.4-1.09,2.51h-2.72c.15-1.8,.81-3.2,2-4.21,1.12-1.01,2.56-1.5,4.34-1.5s3.27,.43,4.39,1.32Z"/></g></svg>'
      }, {
        value: 'threeByTwo',
        label: 'threeByTwo',
        svgIconTop: '<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.b{fill:#58585b;}</style></defs><path class="b" d="M131.25,112.5H18.75V37.5h112.5V112.5Zm-111.02-1.47h109.54V38.97H20.23V111.03Z"/><g><path class="b" d="M63.36,67.86c1.07,.89,1.62,2.11,1.62,3.65,0,1.98-1.02,3.32-2.99,3.98,1.07,.33,1.88,.84,2.44,1.5,.61,.69,.91,1.57,.91,2.64,0,1.67-.58,3.04-1.73,4.11-1.22,1.07-2.82,1.62-4.75,1.62s-3.35-.48-4.47-1.42c-1.27-1.07-1.98-2.61-2.13-4.64h2.79c.05,1.22,.43,2.16,1.19,2.82,.66,.61,1.52,.91,2.59,.91,1.17,0,2.11-.36,2.82-1.01,.61-.61,.94-1.34,.94-2.26,0-1.07-.33-1.85-.99-2.36-.61-.51-1.52-.76-2.72-.76h-1.32v-2.08h1.29c1.12,0,1.95-.25,2.51-.74s.86-1.19,.86-2.11-.28-1.6-.79-2.08c-.56-.51-1.4-.74-2.46-.74s-1.95,.28-2.56,.84c-.61,.56-.99,1.4-1.09,2.51h-2.72c.15-1.8,.81-3.2,2-4.21,1.12-1.01,2.56-1.5,4.34-1.5s3.27,.43,4.39,1.32Z"/><path class="b" d="M72.16,71.9l2.89,4.16,2.87-4.16h3.15l-4.54,6.14,5.1,6.98h-3.15l-3.42-5-3.45,5h-3.17l5.13-6.98-4.54-6.14h3.15Z"/><path class="b" d="M95.83,68.04c1.09,1.02,1.65,2.31,1.65,3.91s-.61,2.99-1.78,4.29c-.71,.74-1.95,1.7-3.75,2.92-1.95,1.29-3.12,2.44-3.5,3.42h9.06v2.44h-12.48c0-1.8,.58-3.38,1.8-4.75,.66-.79,2.03-1.88,4.08-3.3,1.22-.86,2.08-1.55,2.59-2.08,.81-.91,1.24-1.9,1.24-2.97s-.28-1.78-.84-2.28c-.56-.51-1.4-.76-2.46-.76-1.14,0-2,.38-2.59,1.17-.61,.76-.91,1.85-.96,3.32h-2.77c.03-2.03,.61-3.65,1.75-4.87,1.17-1.32,2.74-1.95,4.67-1.95,1.73,0,3.17,.48,4.29,1.5Z"/></g></svg>'
      }, {
        value: 'threeByFour',
        label: 'threeByFour',
        svgIconTop: '<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.b{fill:#58585b;}</style></defs><path class="b" d="M112.5,124.95H37.5V25.05H112.5V124.95Zm-73.53-1.48H111.03V26.53H38.97V123.47Z"/><g><path class="b" d="M62.56,67.86c1.07,.89,1.62,2.11,1.62,3.65,0,1.98-1.02,3.32-2.99,3.98,1.07,.33,1.88,.84,2.44,1.5,.61,.69,.91,1.57,.91,2.64,0,1.67-.58,3.04-1.73,4.11-1.22,1.07-2.82,1.62-4.75,1.62s-3.35-.48-4.47-1.42c-1.27-1.07-1.98-2.61-2.13-4.64h2.79c.05,1.22,.43,2.16,1.19,2.82,.66,.61,1.52,.91,2.59,.91,1.17,0,2.11-.36,2.82-1.01,.61-.61,.94-1.34,.94-2.26,0-1.07-.33-1.85-.99-2.36-.61-.51-1.52-.76-2.72-.76h-1.32v-2.08h1.29c1.12,0,1.95-.25,2.51-.74s.86-1.19,.86-2.11-.28-1.6-.79-2.08c-.56-.51-1.4-.74-2.46-.74s-1.95,.28-2.56,.84c-.61,.56-.99,1.4-1.09,2.51h-2.72c.15-1.8,.81-3.2,2-4.21,1.12-1.01,2.56-1.5,4.34-1.5s3.27,.43,4.39,1.32Z"/><path class="b" d="M71.36,71.89l2.89,4.16,2.87-4.16h3.15l-4.54,6.14,5.1,6.98h-3.15l-3.42-5-3.45,5h-3.17l5.13-6.98-4.54-6.14h3.15Z"/><path class="b" d="M94.83,66.9v11.98h2.74v2.21h-2.74v3.93h-2.64v-3.93h-8.83v-2.61l8.93-11.57h2.54Zm-9.21,11.98h6.57v-8.48h-.08l-6.5,8.48Z"/></g></svg>'
        // }, {
        //   value: 'original',
        //   label: 'ratio_original'
      }];
      return _nventor.default.resolve(data);
    }
  });
});