define("client/pods/guide-menu/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _model, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor; // import { computed } from '@ember/object'
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TabGuideMenuModel = _exports.default = (_dec = Ember._tracked, _class = class TabGuideMenuModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "menu", _descriptor, this);
    }
    /**
     * Used for comparison in template
     * @returns {String} Specific className of current active guide menu tab
     */
    get activeMenuClassName() {
      const className = this.currentActiveGuideMenu?.className || '';
      return className;
    }

    /**
     * Used for comparison in template
     * @returns {String} Specific className of current active guide menu item
     */
    get activeMenuItemClassName() {
      const subs = R.pipe(R.propOr([], 'sub'), R.filter(R.propEq('showActive', true)))(this.currentActiveGuideMenu);
      if (RA.isNotNilOrEmpty(subs)) {
        return subs?.[0]?.className;
      }
      return '';
    }

    /**
     * Used to provide current active guide menu
     * @returns {Object}
     */
    get currentActiveGuideMenu() {
      const menu = this.menu || [];
      const allActiveTabs = R.filter(R.propEq('showActive', true))(menu);
      return _nventor.default.safeHeadOr({}, allActiveTabs);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "menu", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _class);
});