define("client/pods/components/guests/app-logged-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9y/UWFB3",
    "block": "{\"symbols\":[\"tab\",\"@tab\"],\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchSettingsTask\",\"isRunning\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"app-grid u-no-print guest-app buttons are-small \",[28,\"if\",[[23,0,[\"applicationService\",\"isMobile\"]],\"is-mobile\",\"is-desktop\"],null]]]],[8],[0,\"\\n\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-grid__container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"app-grid__content\"],[8],[0,\"\\n        \\n        \\n        \"],[5,\"tabs/for-each\",[],[[\"@tabs\",\"@isParent\"],[[24,[\"tabsManager\",\"left\"]],true]],{\"statements\":[[0,\"\\n          \"],[5,\"layouts/tabs/grid\",[],[[\"@tab\",\"@parentComponent\"],[[23,2,[]],[23,0,[]]]],{\"statements\":[[0,\"\\n            \"],[5,\"layouts/tabs/grid/content\",[],[[],[]],{\"statements\":[[0,\"\\n              \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"guestResource\",\"tabs\",\"tab\"],[[24,[\"guestResource\"]],[24,[\"tabsManager\",\"left\"]],[23,1,[]]]]],false],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/app-logged-in/template.hbs"
    }
  });
});