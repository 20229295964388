define("client/pods/components/extensions/apps/tw/invoices/print-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LcI8K+II",
    "block": "{\"symbols\":[\"invoice\"],\"statements\":[[4,\"if\",[[24,[\"isAllowedInvoice\"]]],null,{\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchInvoicesTask\",\"isRunning\"]]]],{\"statements\":[[4,\"each\",[[24,[\"invoices\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"extensions/apps/tw/invoices/print-btn\",null,[[\"onPrintInvoice\",\"updateFollowingInvoices\",\"model\",\"invoiceMasterKey\",\"isDisplayNumberOnly\",\"onFetchInvoices\",\"document\"],[[28,\"action\",[[23,0,[]],\"printInvoice\"],null],true,[23,1,[]],[24,[\"invoiceMasterKey\"]],[24,[\"isDisplayNumberOnly\"]],[28,\"action\",[[23,0,[]],\"fetchInvoices\"],null],[24,[\"document\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/print-manager/template.hbs"
    }
  });
});