define("client/pods/components/contacts/panels-display/sub-panels-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nE9drysv",
    "block": "{\"symbols\":[\"subTab\",\"@tab\",\"@errors\",\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",false],[12,\"class\",\"panel-grid__header is-sticky-row-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n\\t\\t\"],[5,\"menus/tab-menu-new\",[],[[\"@tab\",\"@tabs\",\"@errors\",\"@menu\",\"@onSelect\"],[[23,2,[]],[23,2,[\"subTabs\"]],[23,3,[]],[23,0,[\"docTypesList\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"displaySubPanelNew\"]],[23,2,[]]],null]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,2,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"errors\",\"model\",\"tab\"],[[23,3,[]],[23,4,[]],[23,1,[]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/panels-display/sub-panels-activity/template.hbs"
    }
  });
});