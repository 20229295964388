define("client/pods/components/emails/dispatch-batch/component", ["exports", "ramda", "ramda-adjunct", "ember-concurrency", "client/utils/nventor"], function (_exports, R, RA, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    settingsService: Ember.inject.service('settings'),
    dateService: Ember.inject.service('date'),
    tagName: '',
    useDefaultDispatchedEmailMessage: true,
    emailMessage: '',
    emailSubject: '',
    useDefaultsDispatchedEmailEstimatedTransporterArrivalDate: true,
    isCompilingMessage: false,
    tabsChanged: 0,
    init() {
      this.set('emailMessage', '');
      this.crud.addLists(this, ['emails/lists/toSendTabs']);
      this._super(...arguments);
      if (this?.useDefaultsDispatchedEmailEstimatedTransporterArrivalDate) {
        this.set('dispatchedEmailHasEstimateTransporterArrivalDate', this.settings.getProp('dispatchedEmailHasEstimateTransporterArrivalDate'));
        this.set('dispatchedEmailEstimateTransporterArrivalDaysFromDispatch', this.settings.getProp('dispatchedEmailEstimateTransporterArrivalDaysFromDispatch'));
        this.set('dispatchedEmailTransporterArrivalDateSkipWeekends', this.settings.getProp('dispatchedEmailTransporterArrivalDateSkipWeekends'));
      }
    },
    afterLoadLists() {
      this._super(...arguments);
      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || this.get('crud.lists.emails-lists-toSendTabs').get('firstObject');
      this.set('currentSubTabOptions', initialPanel);
      this.tabsManager.displaySubPanel(this, initialPanel);
      this.set('tabsChanged', Math.random());
    },
    didInsertElement() {
      this._super(...arguments);
      const data = {
        useDefaultDispatchedEmailMessage: this.useDefaultDispatchedEmailMessage,
        dispatchDateZ: this.dispatchDateZ,
        docKey: this.docKey,
        docType: this.docType,
        docName: this.docName,
        dispatchProcess: this.dispatchProcess
      };
      this.getToSendEmailsTask.perform(data);
    },
    headerTabs: Ember.computed('crud.lists.emails-lists-toSendTabs.[]', 'allEmailsData.{toSend.count,alreadySent.count,cannotSend.count}', function () {
      const allEmailsData = this.allEmailsData || {};
      const list = this.crud.lists['emails-lists-toSendTabs'] || [];
      return R.pipe(R.map(header => {
        const headerValue = header._data.value;
        const hasData = R.pipe(R.path([headerValue]), _nventor.default.isNilOrEmpty, R.not)(allEmailsData);
        if (!hasData) {
          return false;
        }
        const badge = R.path([headerValue, 'count'])(allEmailsData) || 0;
        header.setData('badge', badge);
        return header;
      }), R.reject(R.equals(false)))(list);
    }),
    panelData: Ember.computed('allEmailsData.toSend.count', 'currentSubTabOptions', 'tabsChanged', function () {
      const subTabOptions = this.currentSubTabOptions || {};
      if (!_nventor.default.isNilOrEmpty(subTabOptions)) {
        const value = subTabOptions.getData('value');
        const allEmailsData = this.allEmailsData || {};
        const data = R.prop(value)(allEmailsData) || {};
        return data;
      }
      return {};
    }),
    hasCustomMessage: Ember.computed('isDispatched', 'useDefaultDispatchedEmailMessage', function () {
      if (!this.isDispatched) {
        return true;
      }
      if (this.isDispatched && !this.useDefaultDispatchedEmailMessage) {
        return true;
      }
      return false;
    }),
    allowSetEstimatedDeliveryDate: Ember.computed('transporterArrivalDateZ', 'isDispatched', function () {
      const isDispatched = R.propEq('isDispatched', true)(this);
      const hasTransporterArrivalDateZ = R.pipe(R.prop('transporterArrivalDateZ'), RA.isNotNilOrEmpty)(this);
      return isDispatched && !hasTransporterArrivalDateZ;
    }),
    estimatedArrivalDate: Ember.computed('useDefaultsDispatchedEmailEstimatedTransporterArrivalDate', 'dispatchedEmailEstimateTransporterArrivalDaysFromDispatch', 'dispatchedEmailTransporterArrivalDateSkipWeekends', function () {
      const useDefaultsDispatchedEmailEstimatedTransporterArrivalDate = this.useDefaultsDispatchedEmailEstimatedTransporterArrivalDate;
      let noOfDays = this.dispatchedEmailEstimateTransporterArrivalDaysFromDispatch;
      let skipWeekends = this.dispatchedEmailTransporterArrivalDateSkipWeekends;
      if (useDefaultsDispatchedEmailEstimatedTransporterArrivalDate) {
        noOfDays = this.settings.getProp('dispatchedEmailEstimateTransporterArrivalDaysFromDispatch');
        skipWeekends = this.settings.getProp('dispatchedEmailTransporterArrivalDateSkipWeekends');
      }
      const today = this.dateService.getToday();

      // should be the same with api: services/emails/dispatched
      let arrivalDate;
      // must use if else, if not arrival date will be added twice
      if (skipWeekends) {
        arrivalDate = this.dateService.addBusinessDays({
          date: today,
          duration: noOfDays
        });
      } else {
        arrivalDate = today.add(noOfDays, 'days');
      }
      return this.dateService.formatDate(arrivalDate, 'YYYY-MM-DD');
    }),
    displayPanel(allEmailsData, subTabOptions) {
      this.set('currentSubTabOptions', subTabOptions);
      this.set('tabsChanged', Math.random());
      const tab = this.tab;
      this.tabsManager.displaySubPanelNew(tab, subTabOptions);
    },
    getToSendEmailsTask: (0, _emberConcurrency.task)(function* (data) {
      const allEmailsData = yield this.onGetToSendEmailsTask.perform(data);
      this.set('allEmailsData', allEmailsData);
    }),
    next() {
      this.set('isCompilingMessage', true);
    },
    toggleCustomMessage() {
      const emailSubject = this.intl.t('email_dispatched_default_subject');
      this.set('translatedPlaceholder', emailSubject);
      if (this.useDefaultDispatchedEmailMessage) {
        this.set('emailSubject', '');
      } else {
        this.set('emailSubject', emailSubject);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "displayPanel", [_dec], Object.getOwnPropertyDescriptor(_obj, "displayPanel"), _obj), _applyDecoratedDescriptor(_obj, "next", [_dec2], Object.getOwnPropertyDescriptor(_obj, "next"), _obj), _applyDecoratedDescriptor(_obj, "toggleCustomMessage", [_dec3], Object.getOwnPropertyDescriptor(_obj, "toggleCustomMessage"), _obj), _obj));
});