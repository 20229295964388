define("client/pods/components/extensions/apps/tw/t-cat/settings-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwTCatSettingsEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _class = class ExtensionsAppsTwTCatSettingsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "transporterModel", _descriptor3, this);
      _initializerDefineProperty(this, "renderCreateContact", _descriptor4, this);
      _defineProperty(this, "unlinkMessage", 'are you sure you want to remove');
      _defineProperty(this, "adapterNameContactsTransporters", 'contacts');
      _defineProperty(this, "transporterExtension", 't-cat');
      this.crud.addLists(this, ['contacts/transporters']);
    }
    afterLoadLists() {
      const transporters = R.pathOr([], ['crud', 'lists', 'contacts-transporters'])(this);
      const transporterModel = transporters.findBy('_data.transporterExtension', this.transporterExtension);
      if (transporterModel) {
        this.transporterModel = transporterModel;
      }
      this.renderCreateContact = true;
    }
    *unlinkTransporterTask() {
      const msg = this.intl.t(this.unlinkMessage);
      if (window.confirm(msg) && this.transporterModel) {
        const dirty = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model: this.transporterModel
        });
        dirty.setData('transporterExtension', '');
        yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterNameContactsTransporters,
          model: dirty
        });
        this.transporterModel = null;
      }
    }
    loadTransporter(model) {
      this.transporterModel = model;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "renderCreateContact", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "unlinkTransporterTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "unlinkTransporterTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTransporter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadTransporter"), _class.prototype), _class);
});