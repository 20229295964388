define("client/pods/components/transporters/hct-waybill/component", ["exports", "client/mixins/droppable", "client/mixins/date", "ramda", "ember-concurrency-decorators"], function (_exports, _droppable, _date, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_date.default, _droppable.default, (_obj = {
    big: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    tagName: 'div',
    classNames: ['hct-waybill__container-outer'],
    transporterWaybillBase64Image: null,
    // @deprecated?
    arrivalDate: Ember.computed('model._data.arrivalDate', function () {
      const model = this.model;
      let arrivalDate = '';
      const transporterArrivalDateZ = model.getData('transporterArrivalDateZ');
      const tomorrow = this.getTomorrow();
      const tomorrowZ = this.getStartOfDayZ(tomorrow);
      if (transporterArrivalDateZ && (this.isEqualWeekday(transporterArrivalDateZ, 6) || transporterArrivalDateZ > tomorrowZ)) {
        arrivalDate = this.formatDate(transporterArrivalDateZ, 'YYYY-MM-DD');
      }
      return arrivalDate;
    }),
    waybillStyle: Ember.computed('model._data.waybill', 'transporterWaybillBase64Image', function () {
      return `
  width: 100%;
  height: 100%;
  display: block;
  `;
    }),
    // for print all, hasGroups always true
    // @deprecated?
    hasDocumentsWithCash: Ember.computed('model._data.{paymentMethodKey,groups.@each.synced}', function () {
      const model = this.model || {};
      let paymentMethodKeys = [];
      if (model) {
        paymentMethodKeys = model.getGroupedDocumentsPaymentMethodKeys({
          document: model,
          hasGroups: true,
          hasPaymentLastFiveDigits: false,
          isJoinResults: false,
          isTranslate: false
        });
      }
      return R.includes('cash', paymentMethodKeys);
    }),
    // @deprecated?
    groupedDocsTotal: Ember.computed('model._data.{groups.@each.synced,exclTotal,inclTotal,taxTotal,cashCollectionInclTotal,paymentMethodKey}', function () {
      const model = this.model || {};
      if (model) {
        return model.getGroupedDocumentsTotal({
          document: model,
          hasGroups: true
        });
      }
      return {
        exclTotal: 0,
        taxTotal: 0,
        inclTotal: 0,
        cashCollectionInclTotal: 0
      };
    }),
    *fetchWaybillRecord(model) {
      const result = yield this.dispatch.hctFetchWaybillRecordTask.perform({
        component: this,
        documents: [model]
      });
      const transporterWaybillBase64Image = result?.data?.[0]?.transporterWaybillBase64Image;
      this.set('transporterWaybillBase64Image', transporterWaybillBase64Image);
    }
  }, _applyDecoratedDescriptor(_obj, "fetchWaybillRecord", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "fetchWaybillRecord"), _obj), _obj));
});