define("client/pods/grapesjs/slider/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        src: '',
        link: '',
        status: 'active',
        buttonlabel: '',
        pixelratio: '2048x1024',
        ratio: '2:1',
        schedules: [],
        applytoall: false
      };
    },
    populate(data, attrs) {
      data = this._transform(data);
      data.schedules = this._transformSchedules({
        data,
        schedulesProp: 'schedules'
      });
      this._super(data, attrs);
    },
    _transform({
      src = '',
      pixelratio,
      link,
      status,
      buttonlabel,
      schedules = [],
      applytoall
    }) {
      if (pixelratio) {
        this.setRatio(pixelratio);
      }
      if (link) {
        this.setData('link', link);
      }
      if (status) {
        this.setData('status', status);
      }
      if (buttonlabel) {
        this.setData('buttonlabel', buttonlabel);
      }
      if (_ramdaAdjunct.default.isNotNilOrEmpty(schedules)) {
        this.setData('schedules', schedules);
      }
      if (_ramdaAdjunct.default.isNotNilOrEmpty(applytoall)) {
        this.setData('applytoall', applytoall);
      }
      if (src.includes('placeholder')) {
        const regex = this.getRegex();
        this._data.src = R.pipe(R.split(regex), R.head)(src);
        return this._data;
      }
      this._data.src = this.setSrc(src);
      return this._data;
    },
    getRegex() {
      return new RegExp(/\d{2,4}x\d{2,4}/gm);
    },
    _transformSchedules({
      data,
      schedulesProp
    }) {
      const schedules = R.propOr([], schedulesProp)(data);
      return R.map(schedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
      })(schedules);
    },
    addSchedule({
      schedule = {},
      schedulesProp = ''
    } = {}) {
      if (_ramdaAdjunct.default.isNotNilOrEmpty(schedulesProp)) {
        const schedules = this.getData(schedulesProp) || [];
        const scheduleModel = this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
        schedules.pushObject(scheduleModel);
        this.setData(schedulesProp, schedules);
      }
    },
    statusClass: Ember.computed('_data.status', function () {
      const status = this.getData('status');
      if (status === this.constants.status.active) {
        return 'is-primary';
      }
      return 'is-danger';
    }),
    activeSchedule: Ember.computed('_data.schedules.@each.isActive', function () {
      const schedules = this.getData('schedules') || [];
      return R.find(R.propEq('isActive', true))(schedules);
    }),
    nextSchedule: Ember.computed('_data.schedules.@each.isActiveNext', function () {
      const schedules = this.getData('schedules') || [];
      return R.find(R.propEq('isActiveNext', true))(schedules);
    }),
    getSrcWithRatio: Ember.computed('_data.{src,pixelratio,ratio}', function () {
      const src = this.getData('src');
      const pixelratio = this.getData('pixelratio');
      if (src.includes('placeholder')) {
        const regex = this.getRegex();
        const requiredReplacePixelRatio = R.test(regex)(src);
        if (requiredReplacePixelRatio) {
          return R.replace(regex, this.getData('pixelratio'))(src);
        }
        return `${src}/${pixelratio}/?text=Image`;
      }
      return `${src}-/scale_crop/${pixelratio}/center/`;
    }),
    isPlaceholder: Ember.computed('_data.src', function () {
      const src = this.getData('src');
      return src.includes('placeholder');
    }),
    uuid: Ember.computed('_data.src', function () {
      const getUploadcareUuid = R.pipe(R.pathOr('', ['_data', 'src']), R.split(/https:\/\/ucarecdn\.com\//), R.pathOr('', [1]), R.split('/'), R.pathOr('', [0]));
      return getUploadcareUuid(this);
    }),
    setSrc(src) {
      return R.pipe(R.match(/https:\/\/ucarecdn\.com\/\S{36}\//mg), R.head)(src);
    },
    setRatio(pixelRatio) {
      switch (pixelRatio) {
        case '2048x682':
          this.setData('ratio', '3:1');
          this.setData('pixelratio', pixelRatio);
          break;
        case '2048x1024':
          this.setData('ratio', '2:1');
          this.setData('pixelratio', pixelRatio);
          break;
        case '2048x1536':
          this.setData('ratio', '4:3');
          this.setData('pixelratio', pixelRatio);
          break;
        default:
          this.setData('ratio', '2:1');
          this.setData('pixelratio', pixelRatio);
          break;
      }
      return this;
    },
    checkOverlapDate({
      model
    }) {
      const schedules = model.getData('schedules') || [];
      const emptyStartDateZSchedules = R.filter(R.pathEq(['_data', 'dateStartZ'], ''))(schedules);
      if (R.length(emptyStartDateZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyStartDateZSchedules)
        };
      }
      const emptyDateEndZSchedules = R.filter(R.pathEq(['_data', 'dateEndZ'], ''))(schedules);
      if (R.length(emptyDateEndZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyDateEndZSchedules)
        };
      }
      const emptyStartDateZSchedule = R.head(emptyStartDateZSchedules) || {};
      const emptyDateEndZSchedule = R.head(emptyDateEndZSchedules) || {};
      const scheduleWithoutEmptyDate = R.reject(R.anyPass([R.pathEq(['_data', 'startDateZ'], ''), R.pathEq(['_data', 'dateEndZ'], '')]))(schedules);
      const overlappedIds = [];
      const hasOverlap = R.pipe(R.sortBy(R.path(['_data', 'dateEndZ'])), R.sortBy(R.path(['_data', 'dateStartZ'])), R.prepend(emptyStartDateZSchedule), R.append(emptyDateEndZSchedule), R.reject(_ramdaAdjunct.default.isNilOrEmpty), _ramdaAdjunct.default.mapIndexed((data, i, sortedDatas) => {
        const dateEndZ = data.getData('dateEndZ') || '';
        let nextDateStartZ = '';
        const nextData = sortedDatas[i + 1];
        if (_ramdaAdjunct.default.isNotNilOrEmpty(nextData)) {
          nextDateStartZ = nextData.getData('dateStartZ');
          if (nextDateStartZ <= dateEndZ) {
            overlappedIds.push(data.getData('scheduleId'));
            overlappedIds.push(nextData.getData('scheduleId'));
            return true;
          }
        }
        return false;
      }), R.includes(true))(scheduleWithoutEmptyDate);
      return {
        hasOverlap,
        ids: R.uniq(overlappedIds)
      };
    }
  });
});