define("client/pods/drag-and-drop/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DragAndDropService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _class = class DragAndDropService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "draggedIndex", _descriptor2, this);
      _initializerDefineProperty(this, "toDropPosition", _descriptor3, this);
    }
    *updateRecordsTask() {
      return yield this.crud.updateRecordTask.perform(...arguments);
    }
    onDragStart(draggedIndex, data = {}) {
      this.draggedIndex = draggedIndex;
      this.draggedData = data;
    }
    onDragOver(hoveredIndex) {
      if (this.draggedIndex === hoveredIndex) {
        this.toDropPosition = '';
      } else if (this.draggedIndex < hoveredIndex) {
        this.toDropPosition = 'drop-container--below';
      } else {
        this.toDropPosition = 'drop-container--above';
      }
    }
    onDragOverCell(hoveredIndex) {
      if (this.draggedIndex === hoveredIndex) {
        this.toDropPosition = '';
        return this.toDropPosition;
      }
      if (this.draggedIndex < hoveredIndex) {
        this.toDropPosition = 'drop-container--right';
        return this.toDropPosition;
      }
      if (this.draggedIndex > hoveredIndex) {
        this.toDropPosition = 'drop-container--left';
        return this.toDropPosition;
      }
    }

    // @deprecated. use onDropWithIndex
    onDrop(droppedAt, results, outerDrop, draggedFrom) {
      const modelsIndexStart = R.view(R.lensPath([0, 'index']))(results);
      const fromModel = R.find(R.propEq('index', draggedFrom))(results);
      const droppedAtIndex = R.findIndex(R.propEq('index', droppedAt))(results);
      const fromModelIndex = R.findIndex(R.propEq('index', draggedFrom))(results);
      results.removeAt(fromModelIndex);
      results.insertAt(droppedAtIndex, fromModel);
      R.forEachObjIndexed((m, index) => {
        m.set('_data.index', parseInt(index) + modelsIndexStart);
      })(results);
      if (outerDrop) {
        outerDrop(fromModelIndex, droppedAtIndex, results);
      }
    }

    // @deprecated onDropWithIndex. args are now a hash
    onDropWithIndexOld(droppedAt, results, indexDotPath = '_data.index', outerDrop, draggedFrom) {
      const modelsIndexStart = R_.dotPathOr(0, `${0}.${indexDotPath}`)(results);
      const fromModel = R.find(R_.dotPathEq(indexDotPath, draggedFrom))(results);
      const droppedAtIndex = R.findIndex(R_.dotPathEq(indexDotPath, droppedAt))(results);
      const fromModelIndex = R.findIndex(R_.dotPathEq(indexDotPath, draggedFrom))(results);
      results.removeAt(fromModelIndex);
      results.insertAt(droppedAtIndex, fromModel);
      R.forEachObjIndexed((m, index) => {
        m.set(indexDotPath, parseInt(index) + modelsIndexStart);
      })(results);
      if (outerDrop) {
        outerDrop(fromModelIndex, droppedAtIndex, results);
      }
    }
    /**
     * Handles the drop action for drag and drop functionality with index-based sorting
     * @param {Object} options - The options object
     * @param {*} options.droppedAt - The model the item was dropped (will use indexDotPath to find the index)
     * @param {Array} options.results - The array of items being sorted
     * @param {string} [options.indexDotPath='_data.index'] - The path to the index property in each item
     * @param {Function} [options.outerDrop] - Optional callback function to be called after the drop action
     * @param {*} draggedFrom - The original index or identifier of the dragged item
     */
    onDropWithIndex({
      droppedAt,
      results,
      indexDotPath = '_data.index',
      outerDrop
    }, draggedFrom) {
      const modelsIndexStart = R_.dotPathOr(0, `${0}.${indexDotPath}`)(results);
      const fromModel = R.find(R_.dotPathEq(indexDotPath, draggedFrom))(results);
      const droppedAtIndex = R.findIndex(R_.dotPathEq(indexDotPath, droppedAt))(results);
      const fromModelIndex = R.findIndex(R_.dotPathEq(indexDotPath, draggedFrom))(results);
      results.removeAt(fromModelIndex);
      results.insertAt(droppedAtIndex, fromModel);
      R.forEachObjIndexed((m, index) => {
        m.set(indexDotPath, parseInt(index) + modelsIndexStart);
      })(results);
      if (outerDrop) {
        return outerDrop(fromModelIndex, droppedAtIndex, results);
      }
      return;
    }
    *onDropWithIndexTask({
      droppedAt,
      results,
      indexDotPath = '_data.index',
      outerDrop
    }, draggedFrom) {
      yield this.onDropWithIndex(...arguments);
    }
    onDropCell(droppedIndex, dirtyModel, originalIndex) {
      if (!R.equals(droppedIndex, originalIndex)) {
        const specs = R.pathOr(null, ['_data', 'specs'])(dirtyModel);
        if (R.equals(droppedIndex.parentIndex, originalIndex.parentIndex)) {
          let specRow = specs.get(`${droppedIndex.parentIndex}`);
          const movedItem = specRow.find(spec => spec === specRow[originalIndex.childIndex]);
          const remainingItems = specRow.filter(spec => spec !== specRow[originalIndex.childIndex]);
          specRow = R.flatten([RA.list(RA.sliceTo(droppedIndex.childIndex, remainingItems)), RA.list(movedItem), RA.list(RA.sliceFrom(droppedIndex.childIndex, remainingItems))]);
          specs.set(`${droppedIndex.parentIndex}`, specRow);
          dirtyModel.setData('specs', specs);
        } else {
          let specRowOfOriginalCell = specs.get(`${originalIndex.parentIndex}`);
          const movedItem = specRowOfOriginalCell.find(spec => spec === specRowOfOriginalCell[originalIndex.childIndex]);

          // for row that is left by original cell
          specRowOfOriginalCell = specRowOfOriginalCell.filter(spec => spec !== specRowOfOriginalCell[originalIndex.childIndex]);
          specs.set(`${originalIndex.parentIndex}`, specRowOfOriginalCell);

          // new row of original cell
          let specRowOfDestination = specs.get(`${droppedIndex.parentIndex}`);
          specRowOfDestination = R.flatten([RA.list(RA.sliceTo(droppedIndex.childIndex, specRowOfDestination)), RA.list(movedItem), RA.list(RA.sliceFrom(droppedIndex.childIndex, specRowOfDestination))]);
          specs.set(`${droppedIndex.parentIndex}`, specRowOfDestination);
          dirtyModel.setData('specs', specs);
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "draggedIndex", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toDropPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateRecordsTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateRecordsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragStart", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onDragStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragOver", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDragOverCell", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onDragOverCell"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrop", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDropWithIndexOld", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onDropWithIndexOld"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDropWithIndex", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onDropWithIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDropWithIndexTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "onDropWithIndexTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDropCell", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onDropCell"), _class.prototype), _class);
});