define("client/pods/components/faqs/faqs-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FaqsEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _class = class FaqsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "currentIndex", _descriptor3, this);
      _initializerDefineProperty(this, "errors", _descriptor4, this);
      _initializerDefineProperty(this, "faq", _descriptor5, this);
      _initializerDefineProperty(this, "model", _descriptor6, this);
      _initializerDefineProperty(this, "showAllDetails", _descriptor7, this);
      _defineProperty(this, "adapterName", this.args.adapterName);
      _defineProperty(this, "removeMessage", 'are you sure you want to delete');
      this.setupEditing();
    }
    setupEditing() {
      const originalModel = this.model;
      this.model = this.crud.setupDirty({
        adapterName: this.args.adapterName,
        model: this.model
      });
      if (this.args.isNew) {
        const faqModel = this.crud.setupNewRecord({
          adapterName: 'faqs'
        });
        this.faq = faqModel;
        this.model._data.faqs.pushObject(faqModel);
      } else {
        const faqModel = this.args.faq;
        const currentIndex = R.indexOf(faqModel)(originalModel._data.faqs);
        const dirtyFaq = this.crud.setupDirty({
          adapterName: 'faqs',
          model: faqModel
        });
        this.model._data.faqs.replace(currentIndex, 1, [dirtyFaq]);
        this.faq = dirtyFaq;
      }
    }
    *removeFaqTask({
      adapterName,
      onAfter
    }) {
      const msg = this.intl.t(this.removeMessage);
      if (window.confirm(msg)) {
        const dirty = this.model;
        const faq = dirty._data.faqs[this.currentIndex];
        dirty._data.faqs.removeObject(faq);
        yield this.crud.updateRecordTask.perform({
          adapterName,
          onAfter,
          model: dirty
        });
        this.args.onRemove();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.currentIndex;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "faq", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showAllDetails", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeFaqTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeFaqTask"), _class.prototype), _class);
});