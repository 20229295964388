define("client/pods/websites/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/websites/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    emails: Ember.inject.service(),
    resourcePath: 'api/protected/websites',
    idParam: '_id',
    modelObj: _model.default,
    serialize(model) {
      const data = this._super(model);

      // @TODO: remove?
      data.orderedEmailCopyTo = this.emails.splitToArray(data.orderedEmailCopyTo);
      return data;
    }
  });
});