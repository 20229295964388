define("client/pods/components/flows/search-results-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pLzqhq09",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--bold\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"flows-lists-events\"]],[24,[\"result\",\"_data\",\"eventType\"]]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"tagClassNames\",\"value\"],[[23,0,[\"crud\",\"lists\",\"statuses\"]],[24,[\"statusTagClassName\"]],[24,[\"result\",\"_data\",\"status\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/search-results-item/template.hbs"
    }
  });
});