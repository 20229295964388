define("client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/review-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wFgrCjch",
    "block": "{\"symbols\":[\"@reviewStep\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"workflows-item-activity__container\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"u-desc u-desc--bold\"],[8],[1,[23,1,[\"_data\",\"title\"]],false],[9],[0,\"\\n\\n  \"],[5,\"workflows/items/mark-as-completed\",[],[[\"@workflowsItemsActivities\"],[[23,1,[]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/review-steps/template.hbs"
    }
  });
});