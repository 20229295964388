define("client/helpers/isLast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute(params) {
      const array = params[0] || [];
      let index = parseInt(params[1]) || 0;
      const length = array.length || 0;
      if (length === 0) {
        return true;
      }
      if (length === index + 1) {
        return true;
      }
      return false;
    }
  });
});