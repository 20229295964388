define("client/pods/components/barcode/modal/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  /* global JsBarcode */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BarcodeGeneratorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _class = class BarcodeGeneratorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "barcodeCanvas", _descriptor2, this);
      _defineProperty(this, "labelLengthDefault", 15);
      this.crud.addLists(this, ['barcodes']);
    }
    get badgeCount() {
      const models = this.args.models || [];
      const modelsWithBarcode = R.pipe(R.filter(R.allPass([R.pipe(R.pathOr('', ['_data', 'barcode']), RA.isNotNilOrEmpty), R.pipe(R.pathOr('', ['_data', 'barcodeType']), RA.isNotNilOrEmpty)])), R.length)(models);
      return modelsWithBarcode || '';
    }
    get barcodeItemLabel() {
      return '';
      const modelData = this.args?.model?._data || {};
      const name = R.pipe(R.pathOr('', ['name']), R.take(4))(modelData);
      const price = R.pathOr('0', ['price'])(modelData);
      return `${name} - NT ${price}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "barcodeCanvas", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});