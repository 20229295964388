define("client/mixins/is-dirty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    originalValue: null,
    setOriginalValue(value) {
      this.set('originalValue', value);
    },
    getOriginalValue() {
      return this.originalValue;
    },
    resetOriginalValue() {
      this.set('originalValue', this.get('value'));
    },
    isDirty: Ember.computed('originalValue', 'value', function () {
      var originalValue = this.getOriginalValue();
      var value = this.value;
      if (Ember.isEmpty(value) && Ember.isEmpty(originalValue)) {
        // no original value set..therefore not dirty
        return false;
      }

      // @NOTE: intenionally use non-strict ==
      // this is so that numbers and string numbers will match
      if (value == originalValue) {
        return false;
      }
      return true;
    })
  });
});