define("client/pods/components/tabs/tab-headerpvt/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tab-headerpvt'],
    classNameBindings: ['tab.isActive'],
    translate: true,
    click() {
      const tab = this.tab;

      //set all other tabs to inActive
      const tabs = this.tabs;
      tabs.forEach(function (t) {
        t.set('isActive', false);
      });

      //set current tab to active
      tab.set('isActive', true);
      this.onSelected(tab);
    }
  });
});