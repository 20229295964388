define("client/pods/components/products/products-editor/virtual-contents-editor/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    tabsManager: Ember.inject.service(),
    crud: Ember.inject.service(),
    selectedTemplateIndex: null,
    selectedTemplateModel: null,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['products/lists/attachmentsTypes', 'videoSources']);
    },
    didInsertElement() {
      this._super(...arguments);
      const model = this.model;
      if (!model) {
        return false;
      }
      const templateModel = model.getData('templates').get('firstObject');
      templateModel.instanceId = model?.instanceId;
      this.selectTemplate(templateModel);
      Ember.set(this, 'showAdditionalDescription', model?._data?.additionalDescription ? true : false);
    },
    updateContent(template) {
      this.model.setData('template', template);
    },
    newTemplate(model) {
      const templates = model?._data?.templates || [];
      if (templates.length > 4) {
        return;
      }
      const templateModel = model.addNewTemplate();
      this.selectTemplate(templateModel);
    },
    selectTemplate(templateModel = false) {
      const templates = this.model?._data?.templates || [];
      let index = templates?.indexOf(templateModel) || 0;
      if (index < 0) {
        index = 0;
      }
      this.set('selectedTemplateIndex', index);
      this.set('selectedTemplateModel', templateModel);
    },
    clearAdditionalDescription(model) {
      model.setData('additionalDescription', '');
    }
  }, _applyDecoratedDescriptor(_obj, "updateContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateContent"), _obj), _applyDecoratedDescriptor(_obj, "newTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "newTemplate"), _obj), _applyDecoratedDescriptor(_obj, "selectTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selectTemplate"), _obj), _applyDecoratedDescriptor(_obj, "clearAdditionalDescription", [_dec4], Object.getOwnPropertyDescriptor(_obj, "clearAdditionalDescription"), _obj), _obj));
});