define("client/pods/components/emails/tab-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    // listAdapters: [],
    // results: [],
    resultsToggle: false,
    query: '',
    init() {
      this.crud.addLists(this, ['emails/lists/menu', 'dispatch/lists/processes']);
      this._super(...arguments);
      this.activity();
    },
    activity() {
      // create dashboard
      const tab = this.tab;
      const subTabOptions = {
        component: 'emails/panels-activity'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    scheduled() {
      // create dashboard
      const tab = this.tab;
      const subTabOptions = {
        component: 'emails/panels-scheduled'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    actions: {
      activity() {
        this.activity();
      },
      scheduled() {
        this.scheduled();
      }
    }
  });
});