define("client/pods/components/channels/shop/items-limits-export-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-extension", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopItemsLimitsExportBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _class = class ChannelsShopItemsLimitsExportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dateService", _descriptor2, this);
      _initializerDefineProperty(this, "excel", _descriptor3, this);
      _initializerDefineProperty(this, "export", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "search", _descriptor6, this);
      _initializerDefineProperty(this, "fileName", _descriptor7, this);
      _initializerDefineProperty(this, "exportData", _descriptor8, this);
      _initializerDefineProperty(this, "deconstructSet", _descriptor9, this);
      _initializerDefineProperty(this, "onlyHasActivity", _descriptor10, this);
      _initializerDefineProperty(this, "today", _descriptor11, this);
      _initializerDefineProperty(this, "fullCount", _descriptor12, this);
      this.today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD');
      this.fileName = this.getDefaultFilename();
    }
    getDefaultFilename() {
      const name = R.path(['args', 'model', '_data', 'name'])(this) || '';
      return `${name}-${this.intl.t('limited qty')}-${this.today}`;
    }
    *fetchDataTask({
      fetchMax = false
    }) {
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/limits/filters',
        defaultFiltersData: {
          master,
          fetchMax
        }
      });
      filters.setData('product.status', ['active']);
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'channels/shop/campaigns/details/limits',
        appendPath: `/master/${master}`,
        filters
      });
      this.fullCount = filters?.fullCount;
      const specsForSummary = [{
        column: this.intl.t('product'),
        prop: '_data.item'
      }, {
        column: this.intl.t('product code'),
        prop: '_data.itemCode'
      }, {
        column: this.intl.t('qty sold'),
        color: '',
        prop: '_data.salesQty',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('limited sales qty'),
        color: '',
        prop: '_data.hasSalesLimitedQty',
        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }
          return 'N';
        }
      }, {
        column: this.intl.t('limited qty'),
        color: '',
        prop: '_data.salesLimitedQty',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('increase/decrease limited qty'),
        color: '',
        prop: '',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('allow preOrder'),
        color: '',
        prop: '_data.allowPreOrder',
        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }
          return 'N';
        }
      }, {
        column: this.intl.t('limited preOrder qty'),
        color: '',
        prop: '_data.hasSalesPreOrderLimitedQty',
        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }
          return 'N';
        }
      }, {
        column: this.intl.t('limit preOrder qty'),
        color: '',
        prop: '_data.salesPreOrderLimitedQty',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }, {
        column: this.intl.t('preOrder-notes'),
        color: '',
        prop: '_data.salesPreOrderLimitedQtyDesc',
        transform(value, row, spec) {
          try {
            return R.trim(value || '');
          } catch (e) {
            return '';
          }
        }
      }, {
        column: this.intl.t('increase/decrease preOrder qty'),
        color: '',
        prop: '',
        transform(value, row, spec) {
          return parseInt(value) || 0;
        }
      }];
      const worksheetData = R.map(row => {
        return R.map(spec => {
          let value;
          const path = spec.prop;
          if (!path) {
            value = '';
          }
          value = R_.dotPath(path)(row);
          if (spec.transform) {
            value = spec.transform(value, row, spec);
          }
          return value;
        })(specsForSummary);
      })(results || []);
      const headers = R.pluck('column')(specsForSummary);
      const exportData = {
        headers,
        data: worksheetData
      };
      this.exportData = exportData;
      return exportData;
    }
    *exportTask(onCloseDialogAction) {
      const exportData = yield this.fetchDataTask.perform({
        fetchMax: true
      });
      const headers = exportData.headers || [];
      const worksheetData = exportData.data || [];
      const sheetWithHeaders = R.prepend(headers)(worksheetData);
      const worksheets = [];
      worksheets.push({
        sheetName: this.today,
        data: sheetWithHeaders
      });
      yield this.excel.exportFile({
        worksheets,
        title: this.fileName
      });
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "exportData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deconstructSet", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onlyHasActivity", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "today", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fullCount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype), _class);
});