define("client/pods/components/emails/campaigns/send-modal-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EmailsCampaignsSendModalBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('contacts'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _class = class EmailsCampaignsSendModalBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "contactsService", _descriptor2, this);
      _initializerDefineProperty(this, "constants", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "search", _descriptor5, this);
      _initializerDefineProperty(this, "settings", _descriptor6, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor7, this);
      _initializerDefineProperty(this, "dataContactsGroupsFilters", _descriptor8, this);
      _initializerDefineProperty(this, "errors", _descriptor9, this);
      _initializerDefineProperty(this, "isCompilingMessage", _descriptor10, this);
      _initializerDefineProperty(this, "allowReSend", _descriptor11, this);
      const model = this.args.model;
      const dataContactsGroupsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          sort: [{
            by: 'name',
            direction: 'ASC'
          }],
          websiteKey: model?._data?.websiteKey,
          emailsCampaignsKey: model?._data?._key,
          emailsCampaignsExcludeSent: false,
          resultsProperty: 'results',
          status: [this.constants.status.active]
        }
      });
      this.dataContactsGroupsFilters = dataContactsGroupsFilters;
    }
    *sendEmailsTask(onCloseDialogAction) {
      const msg = this.intl.t('send all emails');
      if (window.confirm(msg)) {
        const resp = yield this.crud.ajaxCallTask.perform('POST', {
          adapterName: 'emails/campaigns',
          appendPath: '/send',
          model: this.args.model
        });
        const sent = parseInt(resp?.sent) || 0;
        onCloseDialogAction();
        const queuedMsg = this.intl.t('queued');
        window.alert(`${queuedMsg}: ` + sent);
      }
    }
    *sendTestEmailTask(onCloseDialogAction) {
      const resp = yield this.crud.ajaxCallTask.perform('POST', {
        adapterName: 'emails/campaigns',
        appendPath: '/send/test',
        model: this.args.model
      });
      const sent = parseInt(resp?.sent) || 0;
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
      window.alert('sent: ' + sent + ' emails.');
    }
    loadEmailData() {
      this.isCompilingMessage = false;
      const model = this.args.model;
      const emailFromAddress = model.getData('emailFromAddress') || this.settings.settingsModel?._data?.emailFromAddress;
      const emailFromName = model.getData('emailFromName') || this.settings.settingsModel?._data?.emailFromName;
      const emailSignature = model.getData('emailSignature') || this.settings.settingsModel?._data?.emailSignature;
      const testEmailToName = model.getData('testEmailToName') || this.settings.settingsModel?._data?.emailFromName;
      const testEmailToAddress = model.getData('testEmailToAddress') || this.settings.settingsModel?._data?.emailFromAddress;
      model.setData('emailFromName', emailFromName);
      model.setData('emailFromAddress', emailFromAddress);
      model.setData('emailSignature', emailSignature);
      model.setData('testEmailToName', testEmailToName);
      model.setData('testEmailToAddress', testEmailToAddress);
    }
    next() {
      this.isCompilingMessage = true;
    }
    get allowSendEmails() {
      const enabled = this.settings.getProp('emails_mailgun.enabled');
      if (!enabled) {
        return false;
      }
      const apiKey = this.settings.getProp('emails_mailgun.apiKey');
      if (!apiKey) {
        return false;
      }
      const domain = this.settings.getProp('emails_mailgun.domain');
      if (!domain) {
        return false;
      }
      const toSendCount = parseInt(this?.dataContactsGroupsFilters?.fullCount) || 0;
      if (toSendCount <= 0) {
        return false;
      }
      return true;
    }
    get disableSend() {
      if (!this.allowSendEmails) {
        return true;
      }
      const model = this.args.model;
      if (model?._data?.hasSent && this.allowReSend) {
        return false;
      }
      if (!model?._data?.hasSent) {
        return false;
      }
      return true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dataContactsGroupsFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isCompilingMessage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "allowReSend", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendEmailsTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendEmailsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendTestEmailTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendTestEmailTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEmailData", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmailData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _class);
});