define("client/pods/components/dispatch/tab-container/component", ["exports", "ramda", "client/mixins/crud", "client/mixins/date", "ember-concurrency", "client/pods/dispatch/model", "client/utils/nventor", "ramda-extension", "ramda-adjunct"], function (_exports, R, _crud, _date, _emberConcurrency, _model, _nventor, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    settingsService: Ember.inject.service('settings'),
    model: null,
    errors: null,
    unprepared: null,
    adapterName: 'dispatch',
    results: null,
    count: 20,
    page: 1,
    dispatchSortByListName: 'dispatchSortBy',
    showDetails: true,
    showSideBar: true,
    showRules: false,
    filtersAdapterName: 'documents/dispatch/filters',
    resultsUpdated: '',
    init() {
      this._super(...arguments);
      this.set('results', []);
      this.crud.addLists(this, ['dispatch/lists/menu', 'dispatch/lists/print-all-sub-panels', 'dispatch/lists/processes', 'dispatch/lists/processes/menu', 'dispatch/lists/filters/dispatchstatuses', 'dispatch/lists/filters/sort', 'paymentMethods', 'paymentTerms', 'contacts/lists/tags', 'contacts/transporters/filters', 'contacts/transporters', 'filters/count', 'filters/sortDirection', 'transporter', 'extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time', 'extensions/apps/tw/seven-eleven/lists/thermosphere', 'extensions/apps/tw/payuni-seven-eleven/accounts', 'extensions/apps/tw/payuni-seven-eleven/type', 'dispatch/lists/dispatch-sort']);
      const pastDays = this.getPastDays(7);
      this.set('pastDays', pastDays);
      this.set('today', this.getPeriod(this.getToday()));
      const groupDocumentsByTransporter = this.settingsService.getProp('groupDocumentsByTransporter');
      this.set('groupDocumentsByTransporter', groupDocumentsByTransporter);
      this.loadTabTask.perform();
    },
    didInsertElement() {
      this._super(...arguments);
      this.fetchByProcessesCountTask.perform();
    },
    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        const dateZ = loadInstructions.getData('find.dateZ');
        yield this.displayDispatched({
          dateZ
        });
        return true;
      }
      return false;
    }),
    todayLabel: Ember.computed('today.dateZ', function () {
      const today = this.today;
      const dateZ = today.dateZ;
      const intl = this.intl;
      return intl.t('dispatched') + ': ' + this.formatDate(dateZ, 'MM/DD') + ' (' + intl.t('today') + ')';
    }),
    todayDispatchCount: Ember.computed('dispatchProcessesByCount.synced', function () {
      const todayZ = this.dateService.getStartOfDayZ();
      const count = R.path(['dispatchProcessesByCount', '_data', todayZ, 'count'])(this) || 0;
      return count;
    }),
    currentDispatchProcessLabel: Ember.computed('currentDispatchProcess', function () {
      const intl = this.intl;
      const currentDispatchProcess = this.currentDispatchProcess;
      if (currentDispatchProcess) {
        const name = currentDispatchProcess.getData('name');
        if (currentDispatchProcess.getData('translate') === true) {
          return intl.t(name);
        }
        return name;
      }
      return intl.t('undispatched');
    }),
    hasMinimizedDispatchProcesses: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      if (dispatchProcesses.length > 5) {
        return true;
      }
      return false;
    }),
    dispatchProcessesMenu: Ember.computed('crud.lists.dispatch-lists-processes-menu.@each.isDeleted', 'dispatchProcessesByCount.synced', function () {
      const list = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      const existingDispatchProcesses = R.pipe(R.reject(R.propEq('isDeleted', true)), R.sortWith([R.ascend(R.path(['_data', 'index']))]))(list);
      if (RA.isNilOrEmpty(existingDispatchProcesses)) {
        return existingDispatchProcesses;
      }
      const dispatchProcessesByCount = this.dispatchProcessesByCount || {};
      const danglingDisapatchProcesses = R.pipe(R.pathOr([], ['_data', 'processes']), R.reject(dispatchProcessByCountData => {
        if (dispatchProcessByCountData?.dispatchProcess === 'dispatched') {
          return true;
        }
        const code = dispatchProcessByCountData.process;
        return R.find(R.pathEq(['_data', 'code'], code))(existingDispatchProcesses);
      }), R.map(dispatchProcessByCountData => {
        const code = dispatchProcessByCountData.process;
        const hideIfEmpty = R.propEq('hideIfEmpty', true)(dispatchProcessByCountData);
        return this.crud.setupNewRecord({
          adapterName: 'dispatch/lists/processes/menu',
          data: {
            code: code,
            name: code,
            hideIfEmpty,
            isDangling: true,
            isEditable: false,
            isLocked: true,
            translate: false
          }
        });
      }))(dispatchProcessesByCount);
      return R.concat(existingDispatchProcesses, danglingDisapatchProcesses);
    }),
    fetchByProcessesCountTask: (0, _emberConcurrency.task)(function* () {
      const adapterName = 'dispatch/processes/count';
      const dispatchProcessesByCount = yield this.findTask.perform({
        adapterName
      });
      this.set('dispatchProcessesByCount', dispatchProcessesByCount);
    }),
    fetchDispatchDataTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period,
      filters
    }) {
      this.set('unpaginatedResults', []);
      filters.setData('dispatchProcessesCriterias', dispatchProcess.getData('criterias'));
      filters.setData('dispatchProcess', dispatchProcess.getData('code'));
      if (period) {
        filters.setData('dispatchDateZ', period.date);
      } else {
        filters.setData('dispatchDateZ', '');
      }

      // Hide isPos on dispatch
      filters.setData('isPos', false);
      const searchSettings = {
        adapterName: 'dispatch',
        resultsProperty: '',
        resultsToggle: '',
        query: '',
        filters,
        component: this
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      this.setResultsAndSummaryData(results);
      return results;
    }),
    // used for export
    fetchUnpaginatedDispatchDataTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period,
      setResults = true,
      filters,
      hidePrinted = ''
    }) {
      if (RA.isNilOrEmpty(filters)) {
        filters = this.filters;
      }
      const filtersData = filters.serialize();
      if (!hidePrinted) {
        hidePrinted = '';
      }
      const defaultFiltersData = R.mergeRight(filtersData, {
        matches: [],
        count: 1000,
        dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
        dispatchProcess: dispatchProcess.getData('code'),
        hidePrinted,
        sort: filters.getData('sort')
      });

      // Only filter by date when on dispatch process
      const isDispatchProcess = defaultFiltersData.dispatchProcess === this.get('constants.dispatchProcess.dispatched');
      if (period && isDispatchProcess) {
        defaultFiltersData.dispatchDateZ = period.date;
      } else {
        defaultFiltersData.dispatchDateZ = '';
      }
      const unpaginatedFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData
      });
      const searchSettings = {
        adapterName: 'dispatch',
        resultsProperty: '',
        resultsToggle: '',
        query: '',
        filters: unpaginatedFilters,
        component: this
      };
      const results = yield this.crud.searchRecordsTask.perform(searchSettings);
      if (setResults !== false) {
        this.setResultsAndSummaryData(results, {
          propResults: 'unpaginatedResults',
          propDashData: 'unpaginatedDashData'
        });
      }
      return results;
    }),
    customSearchTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      filters
    } = {}, query) {
      adapterName = adapterName || this.adapterName;
      filters.setData('query', query);
      const results = yield this.searchRecordsTask.perform({
        adapterName,
        filters,
        query
      });
      this.setResultsAndSummaryData(results);
      return this.results;
    }),
    customAdvancedSearchTask: (0, _emberConcurrency.task)(function* ({
      filters
    }) {
      filters.setData('query', '');
      const adapterName = this.adapterName;
      const results = yield this.searchRecordsTask.perform({
        adapterName,
        filters
      });
      this.setResultsAndSummaryData(results);
      return this.results;
    }),
    customResetSearchTask: (0, _emberConcurrency.task)(function* ({
      filters,
      lastSearchSettingsProperty = 'lastSearchSettings',
      searchSettings
    }) {
      return yield this.customReloadSearchTask.perform({
        filters,
        lastSearchSettingsProperty,
        searchSettings,
        reset: true
      });
    }),
    customReloadSearchTask: (0, _emberConcurrency.task)(function* ({
      filters,
      lastSearchSettingsProperty = 'lastSearchSettings',
      searchSettings,
      reset = false
    }) {
      if (reset) {
        filters.reset({
          resetExcludes: ['dispatchProcess', 'dispatchProcessesCriterias', 'dispatchDateZ', 'status']
        });
        this.setFiltersDefaultSort(filters);
      }

      // if (RA.isNilOrEmpty(filters?._data?.sort) || reset) {
      // 	this.setFiltersDefaultSort(filters)
      // }

      yield this.customAdvancedSearchTask.perform({
        filters,
        lastSearchSettingsProperty,
        searchSettings,
        reset
      });
    }),
    paginateDispatchDataTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.paginateSearchRecordsTask.perform(...arguments);
      this.setResultsAndSummaryData(results);
    }),
    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    isAwaitingPaymentProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const awaitingPaymentCode = this.get('constants.dispatchProcess.awaitingPayment');
      return R.find(R.pathEq(['_data', 'code'], awaitingPaymentCode), dispatchProcesses);
    }),
    // undispatchedPanels: computed('crud.lists.dispatch-lists-processes.[]', function () {
    //   const processes = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
    //   return R.reject(R.path(['_data', 'noPanel']), processes)
    // }),

    resultsProxy: Ember.computed('results.[]', function () {
      const results = this.results || [];
      return R.map(result => {
        return _nventor.default.createModel(_model.default, {}, {
          model: result,
          isSelected: false,
          showDetails: true // @TODO: get this from settings
        });
      })(results);
    }),
    unpaginatedResultsProxy: Ember.computed('unpaginatedResults.[]', function () {
      const results = this.unpaginatedResults || [];
      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: true // @TODO: get this from settings
      }), results);
    }),
    displayTask: (0, _emberConcurrency.task)(function* ({
      dispatchProcess,
      period,
      onCloseDialogAction,
      autoShowPrintAllDialog
    }) {
      const isDispatched = dispatchProcess?._data?.isDispatched || false;
      if (!dispatchProcess) {
        console.log('MISSING::dispatchProcess', {
          dispatchProcess,
          period,
          isDispatched,
          onCloseDialogAction
        });
      }
      this.set('currentDispatchProcess', dispatchProcess);
      const dispatchProcessCode = dispatchProcess.getData('code');
      if (!period && dispatchProcessCode === this.get('constants.dispatchStatus.dispatched')) {
        period = this.getPeriod();
      }
      const count = this.settingsService.getProp('dispatchFiltersCount');
      const sortBy = this.settingsService.getProp('dispatchFiltersSortBy');
      const sortByDirection = this.settingsService.getProp('dispatchFiltersSortByDirection');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        altStorageProp: this.filtersAdapterName + `__${dispatchProcessCode}`,
        rememberProps: ['sortBy'],
        rememberMe: true
      });
      filters.setData('count', count);
      filters.setData('sort', []);
      const groupDocumentsByTransporter = this.groupDocumentsByTransporter;
      if (groupDocumentsByTransporter) {
        filters.addSortNoModel('transporterLabel', 'ASC');
        filters.addSortNoModel('transporterAccount', 'ASC');
        filters.addSortNoModel('transporterAccountNumber', 'ASC');
      }
      filters.addSortNoModel(sortBy, sortByDirection);
      const sortByDefault = R.pipe(R.pathOr([], ['crud', 'lists', 'dispatch-lists-dispatch-sort']), R.find(R.pathEq(['_data', 'value'], sortBy)))(this);
      filters.setData('dateHeaderValue', sortByDefault?._data?.value);
      filters.setData('dateHeaderLabel', sortByDefault?._data?.label);
      this.set('filters', filters);
      const subTabOptions = {
        component: 'dispatch/panels-display',
        dispatchProcess: dispatchProcess,
        period: period,
        isDispatched,
        filters,
        autoShowPrintAllDialog
      };
      yield this.fetchDispatchDataTask.perform({
        dispatchProcess,
        period,
        filters
      });
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }),
    displayDispatched({
      period,
      dateZ
    }) {
      if (!period && dateZ) {
        period = this.getPeriod(dateZ);
      }
      const dispatchProcess = this.isDispatchedProcessModel;
      this.displayTask.perform({
        dispatchProcess,
        period,
        isDispatched: true
      });
    },
    /**
     * Sets the results and summary data for the given results.
     *
     * @param {Object} results - The results object.
     * @param {Object} options - The options object containing optional properties.
     * @param {string} options.propResults - The property name for the results (default: 'results').
     * @param {string} options.propDashData - The property name for the dash data (default: 'dashData').
     *
     * @return {void}
     */
    setResultsAndSummaryData(results, {
      propResults = 'results',
      propDashData = 'dashData'
    } = {}) {
      const summaryData = R.prop('summaryData', results);
      const dashData = R.prop('dashData', results);
      const docs = R.prop('docs', results);
      this.set('summaryData', summaryData);
      this.set(propDashData, dashData);
      this.set(propResults, docs);
    },
    setFiltersDefaultSort(filters) {
      const dateHeaderValue = filters?._data?.dateHeaderValue || 'timestampZ_latest';
      filters.replaceSort(dateHeaderValue, 'DESC');
    },
    actions: {
      refresh(dispatchProcess, period, isDispatched, autoShowPrintAllDialog) {
        this.displayTask.perform({
          dispatchProcess,
          period,
          isDispatched,
          autoShowPrintAllDialog
        });
      },
      display(dispatchProcess, onCloseDialogAction) {
        this.displayTask.perform({
          dispatchProcess,
          onCloseDialogAction
        });
      },
      displayDispatched(period) {
        this.displayDispatched({
          period
        });
      },
      displayCustomDate(dateZ) {
        this.displayDispatched({
          dateZ
        });
      },
      displaySummaryPanel(resourceData) {
        const subTabOptions = {
          component: resourceData.getData('component'),
          value: resourceData.getData('value')
        };
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      },
      afterSplit(dispatchProcess) {
        this.displayTask.perform({
          dispatchProcess
        });
      },
      afterMerge(dispatchProcess) {
        this.displayTask.perform({
          dispatchProcess
        });
      }
    }
  });
});