define("client/pods/components/month-year-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bSMoo+Lv",
    "block": "{\"symbols\":[\"@isRunning\",\"@onSubmit\",\"@mm\",\"@yyyy\",\"&default\",\"@onSelect\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-inline-flex flex-container--align-items-center\"],[8],[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@btnClassNames\",\"@translate\",\"@list\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\",\"@defaultLabel\",\"@onSelect\"],[true,\"u-margin-right-5\",false,[23,0,[\"crud\",\"lists\",\"periods-months\"]],\"_data.value\",\"_data.label\",[23,3,[]],[28,\"tt\",[\"month\"],null],[23,6,[]]]]],[0,\"\\n\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@btnClassNames\",\"@translate\",\"@list\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\",\"@defaultLabel\",\"@onSelect\"],[true,\"u-margin-right-5\",false,[23,0,[\"allowedYears\"]],\"_data.value\",\"_data.label\",[23,4,[]],[28,\"tt\",[\"year\"],null],[23,6,[]]]]],[0,\"\\n\\n\"],[4,\"if\",[[25,5]],null,{\"statements\":[[0,\"    \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasActionButton\"]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-btn\",[],[[\"@classNames\",\"@icon\",\"@isRunning\",\"@onSubmit\",\"@disabled\"],[\"is-primary\",\"fas fa-search\",[23,1,[]],[23,2,[]],[28,\"if\",[[28,\"and\",[[23,4,[]],[23,3,[]]],null],false,true],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/month-year-selector/template.hbs"
    }
  });
});