define("client/pods/components/documents/panel-refund/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8wokQQPu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"documents/editor-main\",null,[[\"tab\",\"model\",\"selectedContact\",\"isNew\",\"isEditing\",\"isQuickLinkDoc\",\"quickLinkFromDocType\",\"quickLinkFromDocName\",\"quickLinkFromDocNo\",\"quickLinkToDocType\",\"quickLinkToDocName\",\"docType\",\"docName\",\"hideOriginalQty\",\"quickLinkQtyLabel\",\"splitQtyLabel\",\"onDash\",\"errors\",\"onPreSubmit\",\"onAddNewDetail\",\"onSubmitTask\",\"submitFinalAppendPath\",\"fetchHCTRegionDataTask\",\"onAfter\",\"onCancel\",\"showRefundWorkflow\"],[[24,[\"tab\"]],[24,[\"dirty\"]],[24,[\"dirty\",\"selectedContact\"]],true,true,true,[24,[\"quickLinkFromDocType\"]],[24,[\"quickLinkFromDocName\"]],[24,[\"quickLinkFromDocNo\"]],[24,[\"quickLinkToDocType\"]],[24,[\"quickLinkToDocName\"]],[24,[\"quickLinkToDocType\"]],[24,[\"quickLinkToDocName\"]],[24,[\"hideOriginalQty\"]],[24,[\"quickLinkQtyLabel\"]],[24,[\"splitQtyLabel\"]],[24,[\"onDash\"]],[24,[\"errors\"]],[28,\"action\",[[23,0,[]],\"preSubmit\"],null],[28,\"action\",[[23,0,[]],\"addNewDetail\",[24,[\"dirty\"]]],null],[24,[\"createReturnsRecordTask\"]],[24,[\"appendPath\"]],[24,[\"fetchHCTRegionDataTask\"]],[24,[\"onAfter\"]],[28,\"action\",[[23,0,[]],\"cancelEditing\",[28,\"hash\",null,[[\"dirty\",\"onAfter\"],[[24,[\"dirty\"]],[24,[\"onAfter\"]]]]]],null],[24,[\"showRefundWorkflow\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-refund/template.hbs"
    }
  });
});