define("client/pods/tabs-manager/model", ["exports", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TabsManagerModel = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _class = class TabsManagerModel extends Ember.Object {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "subTabs", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
      _initializerDefineProperty(this, "dirty", _descriptor3, this);
      _initializerDefineProperty(this, "errorsTracker", _descriptor4, this);
      _initializerDefineProperty(this, "allDirtyApiErrors", _descriptor5, this);
      _initializerDefineProperty(this, "loadInstructions", _descriptor6, this);
      _initializerDefineProperty(this, "errors", _descriptor7, this);
      this.resetTabs();
    }
    get errorsTrackerAll() {
      return this._getErrorsTrackerFromItselfAndChildren(this);
    }
    get listName() {
      return R.pipe(R.propOr('', 'adapterName'), R.replace(/[/]/g, '-'))(this);
    }
    get loadInstructionsIsDisplay() {
      return {
        loadInstructions: {
          component: this.loadInstructions.isDisplay,
          editingComponent: this.loadInstructions.component,
          value: this.loadInstructions.value
        }
      };
    }
    get currentErrors() {
      return this?.errors?.errors || [];
    }
    _getErrorsTrackerFromItselfAndChildren(tab) {
      const errorsTracker = tab.errorsTracker || [];
      const subTabs = tab?.subTabs || [];
      const _tracker = R.pipe(R.map(subTab => {
        const subTabs = R.propOr([], 'subTabs')(subTab);
        if (subTabs.length > 0) {
          return this._getErrorsTrackerFromItselfAndChildren(subTab);
        }
        return subTab?.errorsTracker || [];
      }), R.flatten, R.concat(errorsTracker), R.reject(_nventor.default.isNilOrEmpty), R.uniq)(subTabs);
      errorsTracker.forEach(tracker => {
        tracker.childTabIds = R.pluck('id')(subTabs) || [];
      });
      return _tracker;
    }

    /**
     * Use to clear all subTabs
     * @void
     */
    resetTabs() {
      const tabs = [];
      tabs._history = [];
      tabs._closed = [];
      this.subTabs = tabs;
    }

    /**
     * subTabs {Array}
     * @returns {Array}
     */
    get subTabsArray() {
      return this.subTabs;
    }

    /**
     * To refresh guide menu so as to render properly
     * @param {String} menuId the menuId used to register guide menu
     * @void
     */
    refreshMenu(menuId) {
      const guideMenuModel = this.get(menuId);
      if (RA.isNotNilOrEmpty(guideMenuModel)) {
        guideMenuModel.menu = guideMenuModel.menu || [];
      }
    }

    /**
     * Set the target 'isActive' to true
     * @param {Object} menuItemModel FormContainerModel || FormContainerSubModel
     * @void
     */
    setMenuItemAsActive(menuItemModel) {
      Ember.set(menuItemModel, 'showActive', true);
      const menuId = menuItemModel.menuId;
      this.refreshMenu(menuId);
    }

    /**
     * Set the target 'isActive' to false
     * @param {Object} menuItemModel FormContainerModel || FormContainerSubModel
     * @void
     */
    setMenuItemAsInactive(menuItemModel) {
      Ember.set(menuItemModel, 'showActive', false);
      const menuId = menuItemModel.menuId;
      this.refreshMenu(menuId);
    }
    setErrorTrackerOnModel(errorTracker) {
      const errorsTracker = this.errorsTracker || [];
      if (errorsTracker.length === 0) {
        this.errorsTracker = [errorTracker];
      } else {
        errorsTracker.addObject(errorTracker);
      }
      errorsTracker.addObject(errorTracker);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "subTabs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorsTracker", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "allDirtyApiErrors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadInstructions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setErrorTrackerOnModel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setErrorTrackerOnModel"), _class.prototype), _class);
});