define("client/pods/components/products/products-editor/variants-editor/variant-editor/component", ["exports", "client/mixins/errors", "client/mixins/sortable-actions"], function (_exports, _errors, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_errors.default, _sortableActions.default, (_dec = Ember._action, _obj = {
    productsService: Ember.inject.service('products'),
    tagName: '',
    modelProp: 'variant',
    indexProp: '_data.index',
    didInsertElement() {
      this._super(...arguments);
      if (this.isEditing || this.isNew) {
        const model = this.model;
        const variant = this.variant;
        model.addVariantOption(variant);
      }
    },
    reorderAndUpdateVariantChildrenList(option) {
      const variantModel = this.getModel();
      const dragItem = this.dragDetail;
      variantModel.reorderDetails(option, dragItem);
    },
    actions: {
      addVariantOption(model, variant, option) {
        model.addVariantOption(variant, option);
      },
      removeVariantOption(model, variant, option) {
        const msg = this.intl.t('are you sure you want to delete variant option');
        const confirm = window.confirm(msg);
        if (confirm) {
          model.removeVariantOption(variant, option);
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "reorderAndUpdateVariantChildrenList", [_dec], Object.getOwnPropertyDescriptor(_obj, "reorderAndUpdateVariantChildrenList"), _obj), _obj));
});