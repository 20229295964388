define("client/pods/components/channels/shop/campaigns/panels-dash-linked/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I2huX4Ov",
    "block": "{\"symbols\":[\"@linkedFilters\",\"@tab\"],\"statements\":[[5,\"forms/form-section\",[],[[\"@contentClassNames\"],[\"is-gray\"]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@filters\",\"@tableClassNames\",\"@results\",\"@tab\",\"@tableViewOptions\"],[\"channels/shop/campaigns/table-view/detail-row/linked\",[23,1,[]],\"new-table-view\",[23,1,[\"results\"]],[23,2,[]],[28,\"hash\",null,[[\"onSelectItem\",\"onToggleSelectAllItems\",\"selectedItems\"],[[23,0,[\"selectItem\"]],[23,0,[\"toggleSelectAllItems\"]],[23,0,[\"selectedItems\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPageNumbers\",\"@tagName\"],[[23,1,[]],[23,1,[\"results\"]],[23,0,[\"onPaginationTask\"]],true,\"span\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@onSubmit\",\"@isRunning\"],[\"sync campaigns\",[28,\"perform\",[[23,0,[\"syncCampaignsTask\"]]],null],[23,0,[\"syncCampaignsTask\",\"isRunning\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-dash-linked/template.hbs"
    }
  });
});