define("client/pods/components/reports/commissions/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReportsCommissionsTabContainer = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _class = class ReportsCommissionsTabContainer extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "dataManager", _descriptor3, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);
      _initializerDefineProperty(this, "search", _descriptor5, this);
      _initializerDefineProperty(this, "date", _descriptor6, this);
      _initializerDefineProperty(this, "server", _descriptor7, this);
      _initializerDefineProperty(this, "filters", _descriptor8, this);
      _initializerDefineProperty(this, "yyyy", _descriptor9, this);
      _initializerDefineProperty(this, "mm", _descriptor10, this);
      _defineProperty(this, "adapterName", 'users/associates');
      _defineProperty(this, "filtersName", 'users/associates/filters');
      this.crud.addLists(this, ['users/lists/menu', 'commissions/lists/panels']);
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      this.filters = filters;
      this.dash();
    }
    afterLoadLists() {
      this.loadTabTask.perform();
    }
    *loadTabTask() {
      const filters = this.filters;
      filters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: filters
      });
      this.filters = filters;
      this.dash(filters.results);
      this.filters.set('resultsToggle', true);
    }
    *markAsPayoutsTask({
      adapterName,
      model,
      onAfter
    }) {
      const dateZ = this.date.getNowZ();
      const period = this.date.getPeriod(dateZ);
      const payload = this.dataManager.setAsRecord({
        adapterName,
        data: {
          salesPerson: model._data.salesPerson,
          salesPersonKey: model._data.salesPersonKey,
          amount: model._data.amount,
          comments: model._data.comments,
          ref: period.monthStart,
          dateZ: dateZ,
          mm: period.mm,
          yyyy: period.yyyy
        }
      });
      yield this.crud.createRecordTask.perform({
        adapterName,
        model: payload,
        onAfter
      });
      // yield this.server.call('POST', 'api/protected/reports/commissions/payouts', payload)
    }

    /**
     * display the user
     * @param  {object} user model
     */
    display(model) {
      const subTabOptions = {
        component: 'reports/commissions/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }
    dash() {
      this.filters.set('resultsToggle', false);
      const tab = this.args.tab;
      const subTabOptions = {
        component: 'reports/commissions/panels-all'
        // model: model
      };
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    }
    // @TODO: NEED TO USE NGUARD TO RESTRICT CERTAIN ACTIONS

    onDash() {
      // should deselect results if any..
      // this.deselectResult();
      this.dash();
      // search () {
    }
    onDisplay(model) {
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      this.display(model);
    }
    onDisplayWithDate(model, yyyy, mm) {
      this.yyyy = yyyy;
      this.mm = mm;
      this.display(model);
    }
    displayPanel(resource) {
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      const tab = this.args.tab;
      const subTabOptions = {
        component: resource.getData('component')
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }
    displayPanelWithDate(resource, yyyy, mm) {
      this.yyyy = yyyy;
      this.mm = mm;
      const tab = this.args.tab;
      const subTabOptions = {
        component: resource.getData('component')
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAsPayoutsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markAsPayoutsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDash", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onDash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDisplay", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDisplayWithDate", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onDisplayWithDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayPanel", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayPanelWithDate", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanelWithDate"), _class.prototype), _class);
});