define("client/pods/components/froala/image-caption-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wa/Dim+h",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@modalDialogIsVisible\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@modalDialogIsVisible\",\"@onToggle\",\"@closeOnOverlayClick\",\"@modalSize\"],[[23,2,[]],[28,\"fn\",[[28,\"mut\",[[23,2,[]]],null]],null],false,\"small\"]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@errors\",\"@label\"],[\"description\",\"blogs\",[22,\"errors\"],\"short description\"]],{\"statements\":[[0,\"\\n      \"],[5,\"froala\",[],[[\"@basicToolbar\",\"@isReadonly\",\"@content\",\"@options\"],[true,false,[23,0,[\"imageCaption\"]],[28,\"hash\",null,[[\"clearFormattingAll\"],[true]]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/btn-delete\",[],[[\"@disabled\",\"@onSubmit\"],[[23,0,[\"disabled\"]],[28,\"fn\",[[23,0,[\"removeImageCaption\"]],[23,1,[]]],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/btn-cancel\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,0,[\"saveTask\",\"isRunning\"]],[23,1,[]]]]],[0,\"\\n      \"],[5,\"elements/btn-save\",[],[[\"@onSubmit\"],[[28,\"fn\",[[23,0,[\"upsertImageCaption\"]],[23,1,[]]],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/froala/image-caption-modal/template.hbs"
    }
  });
});