define("client/pods/documents/mixins/calculations-always-inclusive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * calculate document totals based on details
     * (sums up totals done by calculateLineTotals)
     * @TODO: REFACTOR
     */
    sumTotals() {
      // @TODO: get from settings
      const roundingCurrency = this.getData('roundingCurrency', this.getData('roundingCurrency', this.get('settings.roundingCurrency')));
      const details = this.getData('details') || [];
      const isTaxExclusive = this.isTaxExclusive;
      const hasTaxMethod = this.hasTaxMethod;
      let exclTotal = this.newBig(0);
      let taxTotal = this.newBig(0);
      let inclTotal = this.newBig(0);
      this.set('details', details.map(detailModel => {
        detailModel.calculateLineTotals({
          isTaxExclusive,
          hasTaxMethod
        });
        const detailExclAmt = detailModel.getData('exclAmt') || 0;
        const detailTaxAmt = detailModel.getData('taxAmt') || 0;
        const detailInclAmt = detailModel.getData('inclAmt') || 0;

        // not rounding here because each detail is already rounded
        exclTotal = exclTotal.plus(detailExclAmt);
        taxTotal = taxTotal.plus(detailTaxAmt);
        inclTotal = inclTotal.plus(detailInclAmt);
        return detailModel;
      }));
      let exclTotalRounded;
      let taxTotalRounded;
      let inclTotalRounded;
      taxTotalRounded = taxTotal.toFixed(roundingCurrency);
      inclTotalRounded = inclTotal.toFixed(roundingCurrency);
      exclTotalRounded = this.newBig(inclTotalRounded).minus(taxTotalRounded).toFixed(roundingCurrency);
      this.setData('exclTotal', exclTotalRounded);
      this.setData('taxTotal', taxTotalRounded);
      this.setData('inclTotal', inclTotalRounded);
      this._applyDiscount(exclTotalRounded, inclTotalRounded);
      this._sumCommissionAmts();
    },
    /**
     * checks if discount is a rate or amount (for both price exclusive/inclusive),
     * returns rounded discount amount
     */
    _calculateDiscountTotal(isTaxExclusive, exclTotalRounded, inclTotalRounded) {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      let discRateBig = this.getDataBig('discountRate');
      let discAmountBig = this.getDataBig('discountAmount');
      if (this.isDiscountRate) {
        if (discRateBig.gt(0)) {
          // discount is %, divide by 100
          discRateBig = discRateBig.div(100);
          if (isTaxExclusive) {
            discAmountBig = this.newBig(exclTotalRounded).times(discRateBig);
          } else {
            discAmountBig = this.newBig(inclTotalRounded).times(discRateBig);
          }
        } else {
          return false;
        }
      }
      if (discAmountBig.gt(0)) {
        return discAmountBig.toFixed(roundingCurrency);
      }
      return false;
    },
    /**
     * calculate new tax line item after discount
     */
    _calculateDetailTaxAmt(isTaxExclusive, lineAmtBig, detail) {
      const unroundedCurrency = this.getData('unroundedCurrency', this.get('settings.unroundedCurrency'));
      if (isTaxExclusive) {
        const taxRate = detail.getData('taxRate');
        return lineAmtBig.times(taxRate).toFixed(unroundedCurrency);
      } else {
        const taxRateBig = detail.getDataBig('taxRate');
        const taxAmtBig = lineAmtBig.times(taxRateBig).div(taxRateBig.plus(1));
        return taxAmtBig.toFixed(unroundedCurrency);
      }
    },
    /**
     * allocates discount total evenly to each detail line.
     * calculates and sets new lower taxTotalRounded
     */
    _applyDocumentDiscountToDetails(isTaxExclusive, preDiscTotalRounded, discTotalRounded) {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      const unroundedCurrency = this.getData('unroundedCurrency', this.get('settings.unroundedCurrency'));
      const details = this.getData('details');
      let newTaxTotal = this.newBig();
      let method;
      if (isTaxExclusive) {
        method = 'exclAmt';
      } else {
        method = 'inclAmt';
      }
      this.setData('details', details.map(detailModel => {
        // allocate disc to each amt
        let lineAmtBig = detailModel.getDataBig(method);
        if (!lineAmtBig.eq(0)) {
          const ratio = lineAmtBig.div(preDiscTotalRounded);
          const discAmtBig = ratio.times(discTotalRounded);
          lineAmtBig = lineAmtBig.minus(discAmtBig);
          const unroundedTaxAmt = this._calculateDetailTaxAmt(isTaxExclusive, lineAmtBig, detailModel);
          newTaxTotal = newTaxTotal.plus(unroundedTaxAmt);

          // always use excl amt to calculate commission
          if (!isTaxExclusive) {
            // convert incl lineAmtBig to exclTax
            lineAmtBig = lineAmtBig.minus(unroundedTaxAmt);
          }
          const qty = detailModel.getData('qty', 0);

          // @TODO: should use discPrice be rounded/unrounded?
          // @TODO: should use postDiscExclAmt be rounded/unrounded?
          const discPriceRounded = lineAmtBig.div(qty).toFixed(roundingCurrency);
          detailModel.setData('discPrice', discPriceRounded);
          detailModel.setData('postDiscTax', unroundedTaxAmt);
          detailModel.setData('postDiscExclAmt', lineAmtBig.toFixed(unroundedCurrency));
          detailModel.setData('allocatedDiscount', discAmtBig.toFixed(unroundedCurrency));
        }
        return detailModel;
      }));
      return newTaxTotal.toFixed(roundingCurrency);
    },
    _removeDocumentDiscountFromDetails() {
      const details = this.getData('details');
      this.setData('details', details.map(detailModel => {
        detailModel.setData('discPrice', '');
        detailModel.setData('postDiscTax', '');
        detailModel.setData('postDiscExclAmt', '');
        detailModel.setData('allocatedDiscount', '');
        return detailModel;
      }));
    },
    _applyDiscount(exclTotalRounded, inclTotalRounded) {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      const isTaxExclusive = false;
      const discTotalRounded = this._calculateDiscountTotal(isTaxExclusive, exclTotalRounded, inclTotalRounded);
      if (discTotalRounded) {
        // has discount

        let docTotalBig;
        let preDiscTotalRounded;
        let postDiscExclTotalRounded;
        let postDiscInclTotalRounded;
        const preDiscExclTotal = exclTotalRounded;
        docTotalBig = this.newBig(inclTotalRounded);
        // set pre discount total
        preDiscTotalRounded = inclTotalRounded;
        postDiscInclTotalRounded = docTotalBig.minus(discTotalRounded).toFixed(roundingCurrency);
        this.setData('inclTotal', postDiscInclTotalRounded);
        this.setData('preDiscExclTotal', preDiscExclTotal);
        // allocate TOTAL discount to each detail item and get new tax total
        const taxTotalRounded = this._applyDocumentDiscountToDetails(isTaxExclusive, preDiscTotalRounded, discTotalRounded);
        this.setData('taxTotal', taxTotalRounded);
        this.setData('discInclTotal', discTotalRounded);
        if (isTaxExclusive) {
          postDiscInclTotalRounded = this.newBig(postDiscExclTotalRounded).plus(taxTotalRounded).toFixed(roundingCurrency);
          this.setData('inclTotal', postDiscInclTotalRounded);
        } else {
          postDiscExclTotalRounded = this.newBig(postDiscInclTotalRounded).minus(taxTotalRounded).toFixed(roundingCurrency);
          this.setData('exclTotal', postDiscExclTotalRounded);
        }
      } else {
        this.setData('discInclTotal', '');
        this.setData('preDiscExclTotal', '');
        this._removeDocumentDiscountFromDetails();
      }
    },
    _sumCommissionAmts() {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      const details = this.getData('details') || [];
      let commissionTotalBig = this.newBig(0);
      details.map(detailModel => {
        const commissionAmt = detailModel.getData('commissionAmt') || 0;
        commissionTotalBig = commissionTotalBig.plus(commissionAmt);
        return detailModel;
      });
      this.setData('commissionTotal', commissionTotalBig.toFixed(roundingCurrency));
    }
  });
});