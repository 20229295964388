define("client/helpers/user/get", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    users: Ember.inject.service(),
    compute(params /* ,  hash */) {
      return this.users.getProp(params[0]);
    }
  });
});