define("client/pods/components/flows/panels-dash/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    search: Ember.inject.service(),
    crud: Ember.inject.service(),
    filtersAdapterName: 'products/filters',
    init() {
      this._super(...arguments);
      this.tabsManager.setHeader(this.tabParent, '');
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'products/lists/tags', 'products/lists/types', 'products/lists/setTypes', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'products/lists/editorSubPanels', 'settings/roundingAuto', 'units', 'units/dimensions', 'units/weight', 'statuses']);
      const toPrintFilters = this.search.copyFilters({
        filters: this.filters,
        adapterName: this.filtersAdapterName,
        altStorageProp: 'toPrintResults',
        defaultFiltersData: {
          resultsProperty: 'results',
          types: ['inventory']
        }
      });
      this.set('toPrintFilters', toPrintFilters);
    },
    loadPrintDataTask: (0, _emberConcurrency.task)(function* () {
      const toPrintFilters = this.toPrintFilters;
      const lastSearchSettingsProperty = this.filters.get('lastSearchSettingsProperty');
      const lastSearchSettings = this.filters.get(lastSearchSettingsProperty);
      toPrintFilters.set(lastSearchSettingsProperty, lastSearchSettings);
      yield this.crud.reloadSearchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: toPrintFilters
      });
    })
  });
});