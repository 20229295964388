define("client/pods/components/admin/payments/card/panels-payments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "k/ZqIWgo",
    "block": "{\"symbols\":[\"subTab\",\"period\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\"],[0,\"\\n\"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__sidebar\"],[8],[0,\"\\n\\t\\t\"],[5,\"month-year-selector\",[],[[\"@mm\",\"@yyyy\",\"@isRunning\",\"@onSubmit\"],[[23,0,[\"mm\"]],[23,0,[\"yyyy\"]],[28,\"or\",[[24,[\"fetchMonthTask\",\"isRunning\"]],[24,[\"fetchYearTask\",\"isRunning\"]]],null],[28,\"action\",[[23,0,[]],\"displayByMonth\",[28,\"hash\",null,[[\"mm\",\"yyyy\"],[[23,0,[\"mm\"]],[23,0,[\"yyyy\"]]]]]],null]]]],[0,\"\\n\\t\\t\\n\\t\\t\"],[7,\"hr\",true],[8],[9],[0,\" \\n\"],[4,\"lists/list-results\",null,[[\"list\",\"onSelect\"],[[24,[\"pastMonths\"]],[28,\"action\",[[23,0,[]],\"displayByMonth\"],null]]],{\"statements\":[[0,\"\\t\\t\\t\\n\\t\\t\\t\"],[1,[28,\"tt\",[[23,2,[\"name\"]]],null],false],[0,\" \"],[1,[23,2,[\"yyyy\"]],false],[0,\"\\n\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tab\",\"selectedProvider\",\"period\",\"results\"],[[23,1,[]],[24,[\"selectedProvider\"]],[24,[\"period\"]],[24,[\"results\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/admin/payments/card/panels-payments/template.hbs"
    }
  });
});