define("client/pods/components/products/price-selectors/price-selector-campaigns/component", ["exports", "ramda", "ramda-adjunct", "ramda-extension", "ember-concurrency"], function (_exports, R, RA, R_, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: [''],
    isReadonly: false,
    fetchHistoryOnInitTask: (0, _emberConcurrency.task)(function* () {
      const {
        preferredHistoryData,
        priceHistoryData
      } = yield this.onFetchPriceHistoryTask();
      this.set('priceHistoryData', priceHistoryData);
    }),
    sortedData: Ember.computed('priceHistoryData.history.{campaigns.[],automations.[],documents.[]}', function () {
      // const campaignDetailKey = this?.campaignDetailKey || ''
      const forCampaigns = this.productsService.getFor('campaigns', this.priceHistoryData);

      // remove itself so it wouldn't show as history price
      // forCampaigns = R.reject(campaign => R.propEq('_key', campaignDetailKey, campaign), forCampaigns)
      // forCampaigns = R.reject(campaign => R.propEq('campaignDetailKey', campaignDetailKey, campaign), forCampaigns)

      const forAutomations = this.productsService.getFor('automations', this.priceHistoryData);
      const forDocuments = this.productsService.getFor('documents', this.priceHistoryData);
      return RA.concatAll([forCampaigns, forAutomations, forDocuments]);
    }),
    isDisabled: Ember.computed('isReadonly', 'itemKey', function () {
      if (!this.itemKey) {
        return true;
      }
      return this.isReadonly;
    }),
    actions: {
      select(history, onHideDropdown) {
        this.onSelect(history, onHideDropdown);
      }
    }
  });
});