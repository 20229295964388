define("client/pods/components/lists/list-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xvoOC7du",
    "block": "{\"symbols\":[\"tag\",\"@tabOptionsData\",\"@tabLinkKey\",\"@isTabLink\",\"@isFilterTag\",\"@icon\",\"@disableClassNamesFromList\"],\"statements\":[[4,\"each\",[[24,[\"tagged\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"lists/list-option\",null,[[\"translate\",\"selected\",\"optionValueKey\",\"optionLabelKey\",\"optionLabelKey2\",\"optionLabelKey2Translate\",\"optionLabelKey2Prefix\",\"isDefaultKey\",\"classNames\",\"icon\",\"isTag\",\"isFilterTag\",\"isSelected\",\"prefix\",\"suffix\",\"option\",\"isReadonly\",\"allowDeselect\",\"onDeselectTagItem\",\"isTabLink\",\"tabLinkKey\",\"tabOptionsData\"],[[24,[\"translate\"]],[24,[\"selected\"]],[24,[\"optionValueKey\"]],[24,[\"optionLabelKey\"]],[24,[\"optionLabelKey2\"]],[24,[\"optionLabelKey2Translate\"]],[24,[\"optionLabelKey2Prefix\"]],[24,[\"isDefaultKey\"]],[28,\"if\",[[23,7,[]],\"\",[23,1,[\"_data\",\"classNames\"]]],null],[23,6,[]],true,[23,5,[]],true,[24,[\"prefix\"]],[24,[\"suffix\"]],[23,1,[]],[24,[\"isReadonly\"]],[24,[\"allowDeselect\"]],[28,\"action\",[[23,0,[]],\"onDeselectTagItem\",[23,1,[]]],null],[23,4,[]],[23,3,[]],[23,2,[]]]]],false],[0,\"\\n  \"],[1,[23,1,[\"classNames\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/lists/list-tags/template.hbs"
    }
  });
});