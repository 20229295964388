define("client/helpers/substr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute(params /* , hash */) {
      //returns substring using start index and length of characters to return

      //@NOTE: don't confuse with substring.

      const str = params[0] + '';
      const indexStart = params[1];
      const length = params[2];
      if (str) {
        return str.substr(indexStart, length);
      }
      return '';
    }
  });
});