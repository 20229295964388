define("client/pods/components/channels/shop/rewards/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KWs1Fbqc",
    "block": "{\"symbols\":[\"@tab\",\"@errors\",\"@onEdit\"],\"statements\":[[5,\"channels/shop/rewards/rewards-editor\",[],[[\"@tab\",\"@model\",\"@errors\",\"@isEditing\",\"@isNew\",\"@onEdit\"],[[23,1,[]],[23,1,[\"model\"]],[23,2,[]],false,false,[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/panels-display/template.hbs"
    }
  });
});