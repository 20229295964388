define("client/pods/workflows/items/activities/model", ["exports", "client/pods/base/model", "ramda", "client/constants"], function (_exports, _model, R, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    intl: Ember.inject.service(),
    populate(data = {}) {
      data.users = this._transformUsers(data);
      data.related = this._transformRelated(data);
      data.salesInvoices = this._transformSalesInvoices(data);
      this._super(...arguments);
    },
    /**
     * Allow mark as complete on the item card
     */
    allowOuterMarkAsCompleted: Ember.computed('synced', '_data.type', function () {
      const type = R.path(['_data', 'type'])(this);
      const isTypeAllowOuterMark = R.anyPass([R.equals(_constants.default.workflowsItemsActivitiesTypes.authorization), R.equals(_constants.default.workflowsItemsActivitiesTypes.review), R.equals(_constants.default.workflowsItemsActivitiesTypes.attentionRequired), R.equals(_constants.default.workflowsItemsActivitiesTypes.refund), R.equals(_constants.default.workflowsItemsActivitiesTypes.todo)])(type);
      if (isTypeAllowOuterMark) {
        return true;
      }
      return false;
    }),
    creatorUids: Ember.computed('synced', '_data.creator.userKey', function () {
      return R.pathOr('', ['_data', 'creator', 'userKey'])(this);
    }),
    userUids: Ember.computed('synced', '_data.{users.@each.synced}', function () {
      return R.pipe(R.pathOr([], ['_data', 'users']), R.map(R.pathOr('', ['_data', '_key'])))(this);
    }),
    isDisabled: Ember.computed('_data.{related.@each.synced,related.@each.docType}', function () {
      // Currently only check for comments. If the activity was created from a comment
      // then, will be read only.
      const hasComments = R.pipe(R.pathOr([], ['_data', 'related']), R.map(R.pathOr('', ['_data', 'resource'])), R.includes('comment'))(this);
      const isGenerated = R.pathOr(false, ['_data', 'isGenerated'])(this);
      if (hasComments || isGenerated) {
        return true;
      }
      return false;
    }),
    title: Ember.computed('synced', '_data.title', function () {
      const isGenerated = R.pathOr(false, ['_data', 'isGenerated'])(this);
      const translate = R.pathOr(false, ['_data', 'translate'])(this);
      const truncateBy = 50;
      const append = '...';
      const title = R.pipe(R.pathOr('', ['_data', 'title']), title => {
        const p = document.createElement('div');
        p.innerHTML = title;
        // Translate when is auto generated and has the trasnlate flag
        if (isGenerated && translate) {
          return this.intl.t(p.innerText);
        }
        return p.innerText;
      }, R.when(R.propSatisfies(R.gt(R.__, truncateBy), 'length'), R.pipe(R.take(truncateBy), R.append(append), R.join(''))))(this);
      return title;
    }),
    _transformSalesInvoices(data) {
      const salesInvoices = R.prop('salesInvoices')(data);
      if (!salesInvoices) {
        return null;
      }
      return this.dataManager.setAsRecord({
        adapterName: 'documents/sales/invoices',
        data: salesInvoices
      });
    }
  });
});