define("client/pods/components/status-schedules/status-schedules-row/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StatusSchedulesRowComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _class = class StatusSchedulesRowComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "date", _descriptor3, this);
      _initializerDefineProperty(this, "constants", _descriptor4, this);
    }
    get dateInputType() {
      const isDateOnly = this.args?.isDateOnly || false;
      if (isDateOnly) {
        return 'date';
      }
      return 'datetime-local';
    }
    get statusClass() {
      const status = this.args.schedule.getData('status');
      if (status === this.constants.status.active) {
        return 'is-primary';
      }
      return 'is-danger';
    }
    checkDateError() {
      const schedule = this.args?.schedule || {};
      const dateStartZ = R.pathOr('', ['_data', 'dateStartZ'])(schedule);
      const dateEndZ = R.pathOr('', ['_data', 'dateEndZ'])(schedule);
      let isDateError = false;
      if (dateStartZ && dateEndZ) {
        const isDateOnly = this.args?.isDateOnly || false;
        if (isDateOnly) {
          if (dateEndZ < dateStartZ) {
            isDateError = true;
          }
        } else {
          if (dateEndZ <= dateStartZ) {
            isDateError = true;
          }
        }
      }
      schedule.set('isDateError', isDateError);
    }
    checkOverlapSchedule() {
      const model = this.args?.model || {};
      const overlapSchedule = this._getOverlapSchedule();
      const schedules = this.args?.schedules || [];
      if (RA.isNotNilOrEmpty(schedules) && overlapSchedule.hasOverlap) {
        const overlapIds = overlapSchedule.ids || [];
        R.forEach(schedule => {
          const scheduleId = schedule.getData('scheduleId');
          if (R.includes(scheduleId, overlapIds)) {
            schedule.set('hasDateConflict', true);
          } else {
            schedule.set('hasDateConflict', false);
          }
        })(schedules);
        model.set('hasScheduleDateConflict', true);
      }
      if (RA.isNotNilOrEmpty(schedules) && !overlapSchedule.hasOverlap) {
        R.forEach(schedule => {
          schedule.set('hasDateConflict', false);
        })(schedules);
        model.set('hasScheduleDateConflict', false);
      }
    }
    _getOverlapSchedule() {
      let schedules = this.args?.schedules || [];
      schedules = R.reject(R.allPass([R.pathEq(['_data', 'dateStartZ'], ''), R.pathEq(['_data', 'dateEndZ'], '')]))(schedules);
      const emptyStartDateZSchedules = R.filter(R.pathEq(['_data', 'dateStartZ'], ''))(schedules);
      if (R.length(emptyStartDateZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyStartDateZSchedules)
        };
      }
      const emptyDateEndZSchedules = R.filter(R.pathEq(['_data', 'dateEndZ'], ''))(schedules);
      if (R.length(emptyDateEndZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyDateEndZSchedules)
        };
      }
      const emptyStartDateZSchedule = R.head(emptyStartDateZSchedules) || {};
      const emptyDateEndZSchedule = R.head(emptyDateEndZSchedules) || {};
      const scheduleWithoutEmptyDate = R.reject(R.anyPass([R.pathEq(['_data', 'startDateZ'], ''), R.pathEq(['_data', 'dateEndZ'], '')]))(schedules);
      const isDateOnly = this.args?.isDateOnly || false;
      const overlappedIds = [];
      const hasOverlap = R.pipe(R.sortBy(R.path(['_data', 'dateEndZ'])), R.sortBy(R.path(['_data', 'dateStartZ'])), R.prepend(emptyStartDateZSchedule), R.append(emptyDateEndZSchedule), R.reject(RA.isNilOrEmpty), RA.mapIndexed((data, i, sortedDatas) => {
        const dateEndZ = data.getData('dateEndZ') || '';
        let nextDateStartZ = '';
        const nextData = sortedDatas[i + 1];
        if (RA.isNotNilOrEmpty(nextData)) {
          nextDateStartZ = nextData.getData('dateStartZ');
          if (isDateOnly) {
            if (nextDateStartZ < dateEndZ) {
              overlappedIds.push(data.getData('scheduleId'));
              overlappedIds.push(nextData.getData('scheduleId'));
              return true;
            }
          } else {
            if (nextDateStartZ <= dateEndZ) {
              overlappedIds.push(data.getData('scheduleId'));
              overlappedIds.push(nextData.getData('scheduleId'));
              return true;
            }
          }
        }
        return false;
      }), R.includes(true))(scheduleWithoutEmptyDate);
      return {
        hasOverlap,
        ids: R.uniq(overlappedIds)
      };
    }
    onSubmitRemoveSchedule(schedule) {
      const schedules = this.args.schedules;
      schedules.removeObject(schedule);
      this.checkOverlapSchedule();
    }
    onChangeSetActivation() {
      const isDateOnly = this.args?.isDateOnly || false;
      const schedule = this.args?.schedule || {};
      const dateStartZ = schedule?._data?.dateStartZ || '';
      const dateEndZ = schedule?._data?.dateEndZ || '';
      if (isDateOnly) {
        if (dateStartZ) {
          schedule.setData('dateStartZ', this.date.getStartOfDayZ(dateStartZ));
        }
        if (dateEndZ) {
          schedule.setData('dateEndZ', this.date.getEndOfDayZ(dateEndZ));
        }
      } else {
        if (dateEndZ) {
          const newDateEndZ = this.date.getMoment(dateEndZ).set('seconds', 59).set('millisecond', 999).toISOString();
          schedule.setData('dateEndZ', newDateEndZ);
        }
      }
      this.checkDateError();
      this.checkOverlapSchedule();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveSchedule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSetActivation", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSetActivation"), _class.prototype), _class);
});