define("client/pods/components/channels/shop/campaigns/items/limit-qty-selector/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsItemsLimitQtySelectorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _class = class ChannelsShopCampaignsItemsLimitQtySelectorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _defineProperty(this, "adapterName", 'channels/shop/campaigns/details/limits');
      this.crud.addLists(this, ['channels/shop/campaigns/lists/limit-adjust-types']);
      this.limitAdjustType = this?.args?.limitAdjustType || 'adjustTotalQty';
    }
    get modalDialogTitle() {
      const type = this?.args?.type || '';
      if (R.equals(type, 'preOrder')) {
        return 'adjust limit preorder qty';
      }
      return 'adjust limit qty';
    }
    get modalLabel() {
      const model = this?.args?.model || {};
      if (RA.isNotNilOrEmpty(model)) {
        const type = this?.args?.type || 'normal';
        const limitAdjustType = this?.args?.limitAdjustType || 'adjustTotalQty';
        if (R.equals(type, 'preOrder')) {
          if (R.equals(limitAdjustType, 'adjustTotalQty')) {
            const salesPreOrderLimitedQty = R.pathOr('', ['_data', 'salesPreOrderLimitedQty'])(model);
            return R.is(Number, salesPreOrderLimitedQty) ? R.toString(salesPreOrderLimitedQty) : salesPreOrderLimitedQty;
          } else {
            return this?.args?.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart || '';
          }
        } else {
          if (R.equals(limitAdjustType, 'adjustTotalQty')) {
            const salesLimitedQty = R.pathOr('', ['_data', 'salesLimitedQty'])(model);
            return R.is(Number, salesLimitedQty) ? R.toString(salesLimitedQty) : salesLimitedQty;
          } else {
            return model?._data?.calculatedAvailableQtyBeforeCart || '';
          }
        }
      }
      return '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});