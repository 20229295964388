define("client/pods/components/channels/shop/items-export-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/constants/index", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _index, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopItemsLimitsExportBtnComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _class = class ChannelsShopItemsLimitsExportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dateService", _descriptor2, this);
      _initializerDefineProperty(this, "excel", _descriptor3, this);
      _initializerDefineProperty(this, "export", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "search", _descriptor6, this);
      _initializerDefineProperty(this, "fileName", _descriptor7, this);
      _initializerDefineProperty(this, "exportData", _descriptor8, this);
      _initializerDefineProperty(this, "deconstructSet", _descriptor9, this);
      _initializerDefineProperty(this, "onlyHasActivity", _descriptor10, this);
      _initializerDefineProperty(this, "today", _descriptor11, this);
      _initializerDefineProperty(this, "notEditableStringIndicator", _descriptor12, this);
      _initializerDefineProperty(this, "exportType", _descriptor13, this);
      _initializerDefineProperty(this, "fullCount", _descriptor14, this);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/campaigns-details-export-types']);
      this.today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD');
      this.fileName = this.getDefaultFilename();
    }
    getDefaultFilename() {
      const name = R.path(['args', 'model', '_data', 'name'])(this) || '';
      return `${name}-${this.intl.t('limited qty')}-${this.today}`;
    }
    *fetchDataTask({
      fetchMax = false
    }) {
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          master,
          fetchMax
        }
      });
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'channels/shop/campaigns/details/export',
        appendPath: `/export/master/${master}`,
        filters,
        serverType: 'jobs'
      });
      this.fullCount = filters?.fullCount;
      const specsForSummary = [{
        column: this.intl.t('index'),
        validInput: this.intl.t('dataType_number'),
        prop: '_data.index',
        productTypes: ['']
      }, {
        column: this.intl.t('product'),
        validInput: this.intl.t('not editable'),
        prop: '_data.item'
      }, {
        column: this.intl.t('product code'),
        validInput: this.intl.t('not editable'),
        prop: '_data.itemCode',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          if (RA.isNotNilOrEmpty(value)) {
            return value;
          }
          return notEditableStringIndicator;
        }
      }, {
        //   column: this.intl.t('product tags'),
        //   prop: '_data.productTags',
        //   validInput: this.intl.t('not editable'),
        //   transform (value, row, spec, intl, notEditableStringIndicator) {
        //     return R.pipe(
        //       RA.ensureArray,
        //       R.map(R.propOr('', 'label')),
        //       R.reject(RA.isNilOrEmpty),
        //       R.join(', ')
        //     )(value)
        //   }
        // }, {
        //   column: this.intl.t('campaign detail product tags'),
        //   prop: '_data.campaignDetailProductTags',
        //   validInput: this.intl.t('not editable'),
        //   transform (value, row, spec, intl, notEditableStringIndicator) {
        //     return R.pipe(
        //       RA.ensureArray,
        //       R.map(R.propOr('', 'label')),
        //       R.reject(RA.isNilOrEmpty),
        //       R.join(', ')
        //     )(value)
        //   }
        // }, {
        column: this.intl.t('product type'),
        validInput: this.intl.t('not editable'),
        prop: '_data.productType',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isSet = R_.dotPathOr(false, '_data.isSet')(row);
          const isSetWithChoices = R_.dotPathOr(false, '_data.isSetWithChoices')(row);
          const hasVariants = R_.dotPathOr(false, '_data.hasVariants')(row);
          const isChoices = R_.dotPathOr(false, '_data.isChoices')(row);
          const variantChildId = R_.dotPathOr('', '_data.variantChildId')(row);
          if (isSet) {
            value = intl.t('product_set');
            const isSetChild = R_.dotPathOr(false, '_data.isSetChild')(row);
            if (isSetChild) {
              value = intl.t('product_sets_contents');
            }
          } else if (isSetWithChoices) {
            value = intl.t('product_sets_with_choices');
          } else if (hasVariants) {
            value = intl.t('variants');
          } else if (isChoices) {
            value = intl.t('choices');
          } else if (RA.isNotNilOrEmpty(variantChildId)) {
            value = intl.t('variant child');
          } else {
            value = intl.t('product_general');
          }
          return value;
        }
      }, {
        column: this.intl.t('status'),
        validInput: `${this.intl.t(_index.default.productsStatus.active)} / ${this.intl.t(_index.default.productsStatus.inactive)} / ${this.intl.t(_index.default.productsStatus.preOrder)} / ${this.intl.t(_index.default.productsStatus.soldOut)} / ${this.intl.t(_index.default.productsStatus.draft)}`,
        prop: '_data.status',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isChoices = row?._data?.isChoices;
          if (isChoices) {
            return notEditableStringIndicator;
          }
          const isSetChild = R_.dotPathOr(false, '_data.isSetChild')(row);
          if (isSetChild) {
            return notEditableStringIndicator;
          }
          return intl.t(value);
        }
      }, {
        column: this.intl.t('on sale'),
        validInput: 'Y / N',
        prop: '_data.onSale',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isMainRow = R.pathOr(false, ['_data', 'isMainRow'])(row);
          if (!isMainRow) {
            return notEditableStringIndicator;
          }
          if (value === 'on') {
            return 'Y';
          }
          return 'N';
        }
      }, {
        column: this.intl.t('inclusive price'),
        validInput: this.intl.t('dataType_number'),
        prop: '_data.price',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isSetWithChoices = R_.dotPathOr(false, '_data.isSetWithChoices')(row);
          if (isSetWithChoices) {
            return notEditableStringIndicator;
          }
          const isChoices = row?._data?.isChoices;
          if (isChoices) {
            const isSetPriceOnBundle = row?._data?.isSetPriceOnBundle;
            if (isSetPriceOnBundle) {
              return RA.isNotNilOrEmpty(value) ? Number(value) : value;
            }
            return '';
          }
          return RA.isNotNilOrEmpty(value) ? Number(value) : value;
        }
      }, {
        column: this.intl.t('inclusive discounted price'),
        validInput: `${this.intl.t('dataType_number')} (${this.intl.t('must be smaller than inclusive price')})`,
        prop: '_data.priceDiscounted',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isSetWithChoices = R_.dotPathOr(false, '_data.isSetWithChoices')(row);
          if (isSetWithChoices) {
            return notEditableStringIndicator;
          }
          const isChoices = row?._data?.isChoices;
          if (isChoices) {
            const isSetPriceOnBundle = row?._data?.isSetPriceOnBundle;
            if (isSetPriceOnBundle) {
              return RA.isNotNilOrEmpty(value) ? Number(value) : value;
            }
            return '';
          }
          return RA.isNotNilOrEmpty(value) ? Number(value) : value;
        }
      }, {
        column: this.intl.t('choose qty'),
        validInput: this.intl.t('dataType_number'),
        prop: '_data.choiceQty',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isChoices = row?._data?.isChoices;
          if (isChoices) {
            return RA.isNotNilOrEmpty(value) ? Number(value) : value;
          }
          return notEditableStringIndicator;
        }
      }, {
        column: this.intl.t('set price on bundle'),
        validInput: 'Y / N',
        prop: '_data.isSetPriceOnBundle',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          if (row?._data?.isChoices) {
            if (value === true) {
              return 'Y';
            }
            if (value === false) {
              return 'N';
            }
            return notEditableStringIndicator;
          }
          return notEditableStringIndicator;
        }
      }, {
        column: `${this.intl.t('product_sets_contents')} ${this.intl.t('qty')}`,
        validInput: this.intl.t('not editable'),
        prop: '_data.qty',
        transform(value, row, spec, intl, notEditableStringIndicator) {
          const isSetChild = row?._data?.isSetChild;
          if (isSetChild) {
            return RA.isNotNilOrEmpty(value) ? Number(value) : value;
          }
          return notEditableStringIndicator;
        }
      }];
      const createRow = row => R.map(spec => {
        let value;
        const path = spec.prop;
        if (!path) {
          value = '';
        }
        value = R_.dotPath(path)(row);
        if (spec.transform) {
          value = spec.transform(value, row, spec, this.intl, this.notEditableStringIndicator);
        }
        return value;
      })(specsForSummary);
      const worksheetData = R.pipe(RA.mapIndexed((row, index0) => {
        row._data.index = `${index0 + 1}`;
        row._data.isMainRow = true;
        const isSet = R_.dotPathOr(false, '_data.isSet')(row);
        const isSetWithChoices = R_.dotPathOr(false, '_data.isSetWithChoices')(row);
        const hasVariants = R_.dotPathOr(false, '_data.hasVariants')(row);
        const isNormalProduct = !isSet && !isSetWithChoices && !hasVariants;
        if (isNormalProduct) {
          const rowValue = createRow(row);
          return [rowValue];
        }
        if (isSet) {
          const parentRow = createRow(row);
          const setDetails = row?._data?.setDetails || [];
          const setDetailsRow = RA.mapIndexed((setDetail, index1) => {
            setDetail._data.index = `${row._data.index}.${index1 + 1}`;
            return createRow(setDetail);
          })(setDetails);
          return R.concat([parentRow])(setDetailsRow);
        }
        if (hasVariants) {
          const parentRow = createRow(row);
          const variantChildren = row?._data?.variantChildren || [];
          const variantChildrenRow = RA.mapIndexed((variantChild, index1) => {
            variantChild._data.index = `${row._data.index}.${index1 + 1}`;
            return createRow(variantChild);
          })(variantChildren);
          return R.concat([parentRow])(variantChildrenRow);
        }
        if (isSetWithChoices) {
          const parentRow = createRow(row);
          const choices = row?._data?.choices || [];
          const choicesRow = R.pipe(RA.mapIndexed((choice, index1) => {
            choice._data.choiceQty = choice?._data?.qty || '';
            choice._data.item = choice?._data?.name || '';
            choice._data.index = `${row._data.index}.${index1 + 1}`;
            choice._data.isChoices = true;
            const choiceRow = createRow(choice);
            const details = choice?._data?.details || [];
            const detailsRow = R.pipe(RA.mapIndexed((detail, index2) => {
              detail._data.index = `${choice._data.index}.${index2 + 1}`;
              const detailRow = createRow(detail);
              const isSet = R_.dotPathOr(false, '_data.isSet')(detail);
              const hasVariants = R_.dotPathOr(false, '_data.hasVariants')(detail);
              if (isSet) {
                const setDetails = detail?._data?.setDetails || [];
                const setDetailsRow = RA.mapIndexed((setDetail, index3) => {
                  setDetail._data.index = `${detail._data.index}.${index3 + 1}`;
                  return createRow(setDetail);
                })(setDetails);
                return R.concat([detailRow])(setDetailsRow);
              }
              if (hasVariants) {
                const variantChildren = detail?._data?.variantChildren || [];
                const variantChildrenRow = RA.mapIndexed((variantChild, index3) => {
                  variantChild._data.index = `${detail._data.index}.${index3 + 1}`;
                  return createRow(variantChild);
                })(variantChildren);
                return R.concat([detailRow])(variantChildrenRow);
              }
              return [detailRow];
            }), R.unnest)(details);
            return R.concat([choiceRow])(detailsRow);
          }), R.unnest)(choices);
          return R.concat([parentRow])(choicesRow);
        }
      }), R.unnest)(results);
      const headers = R.pluck('column')(specsForSummary);
      const subHeaders = R.pluck('validInput')(specsForSummary);
      const exportData = {
        headers,
        subHeaders,
        data: worksheetData
      };
      this.exportData = exportData;
      return exportData;
    }
    *exportTask(onCloseDialogAction) {
      const exportData = yield this.fetchDataTask.perform({
        fetchMax: true
      });
      const headers = exportData.headers || [];
      const subHeaders = exportData.subHeaders || [];
      const worksheetData = exportData.data || [];
      const sheetWithHeaders = R.pipe(worksheetData => {
        if (this.exportType === 'adjustIndexes') {
          return R.filter(data => {
            const indexValue = R.head(data);
            if (R.includes('.', indexValue)) {
              return false;
            }
            return true;
          })(worksheetData);
        }
        return worksheetData;
      }, R.prepend(subHeaders), R.prepend(headers))(worksheetData);
      const worksheets = [];
      worksheets.push({
        sheetName: this.today,
        data: sheetWithHeaders
      });
      yield this.excel.exportFile({
        worksheets,
        title: this.fileName
      });
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "exportData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deconstructSet", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onlyHasActivity", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "today", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "notEditableStringIndicator", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '-';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "exportType", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'adjustPrices';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "fullCount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype), _class);
});