define("client/pods/components/transporters/hct-row-waybill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lmcxUX22",
    "block": "{\"symbols\":[\"model\",\"@row\"],\"statements\":[[7,\"tr\",true],[10,\"class\",\"hct-waybills__row u-print-no-break\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[10,\"class\",\"hct-waybills__column\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"transporters/hct-waybill\",null,[[\"pageIndex\",\"rowIndex\",\"model\",\"onTogglePrintWaybill\"],[[24,[\"pageIndex\"]],[24,[\"rowIndex\"]],[23,1,[]],[28,\"fn\",[[23,0,[\"togglePrintWaybill\"]],[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"hct-waybill__container hct-waybills__container--empty\"],[8],[0,\"\\n          \"],[7,\"h1\",true],[10,\"class\",\"u-no-print\"],[8],[0,\"- \"],[1,[28,\"tt\",[\"empty\"],null],false],[0,\" -\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/hct-row-waybill/template.hbs"
    }
  });
});