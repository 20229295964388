define("client/pods/components/settings/settings-editor/social-editor/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['channels/shop/campaigns/lists/commission-types', 'channels/shop/campaigns/lists/review-steps', 'channels/shop/campaigns/lists/products-filters', 'channels/shop/campaigns/lists/limit-adjust-types', 'channels/shop/campaigns/automations/shipping-behavior', 'settings/checkout-date-method', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'recurring/discounts', 'recurring/shipping', 'periods', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
    },
    afterLoadLists() {
      const model = this.model;
      const selectedCommissionBaseCostMethod = model.getData('selectedCommissionBaseCostMethod');
      const isCommissionsUsingAverage = model.getData('isCommissionsUsingAverage');
      const isCommissionsCalculatedByExclAmt = model.getData('isCommissionsCalculatedByExclAmt');
      const isCommissionsUsingSetChild = model.getData('isCommissionsUsingSetChild');
      const isCommissionsHasAdjustedShippingFee = model.getData('isCommissionsHasAdjustedShippingFee');
      if (RA.isNilOrEmpty(selectedCommissionBaseCostMethod)) {
        model.setData('selectedCommissionBaseCostMethod', 'normalPercent');
      }
      if (RA.isNilOrEmpty(isCommissionsUsingAverage)) {
        model.setData('isCommissionsUsingAverage', true);
      }
      if (RA.isNilOrEmpty(isCommissionsCalculatedByExclAmt)) {
        model.setData('isCommissionsCalculatedByExclAmt', true);
      }
      if (RA.isNilOrEmpty(isCommissionsUsingSetChild)) {
        model.setData('isCommissionsUsingSetChild', false);
      }
      if (RA.isNilOrEmpty(isCommissionsHasAdjustedShippingFee)) {
        model.setData('isCommissionsHasAdjustedShippingFee', false);
      }
    },
    shopPaymentMethodsDefaultsList: Ember.computed('crud.lists.paymentMethods-shop.[]', 'model._data.shopPaymentMethods.[]', function () {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.get('model._data.shopPaymentMethods') || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }),
    setShopPaymentMethodsDefault(model = {}) {
      const shopPaymentMethodsDefaultsList = this.shopPaymentMethodsDefaultsList || [];
      const currentDefault = model.getData('shopPaymentMethodsDefault');
      let resetDefault = false;
      if (!R.includes(currentDefault, shopPaymentMethodsDefaultsList)) {
        resetDefault = true;
      }
      if (!currentDefault) {
        resetDefault = true;
      }
      if (resetDefault) {
        const first = shopPaymentMethodsDefaultsList.get('firstObject');
        if (first) {
          model.setData('shopPaymentMethodsDefault', first.getData('value'));
        } else {
          model.setData('shopPaymentMethodsDefault', '');
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "setShopPaymentMethodsDefault", [_dec], Object.getOwnPropertyDescriptor(_obj, "setShopPaymentMethodsDefault"), _obj), _obj));
});