define("client/pods/components/documents/document-print/sub-header-references/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    showFromPerson: false,
    showSubHeader: Ember.computed('model._data.{ref,paymentMethodKey}', 'showFromPerson', function () {
      const ref = this.get('model._data.ref');
      const paymentMethodKey = this.get('model._data.paymentMethodKey');
      const showFromPerson = this.showFromPerson;
      if (ref) {
        return true;
      }
      if (paymentMethodKey) {
        return true;
      }
      if (showFromPerson) {
        return true;
      }
      return false;
    }),
    paymentMethodKeys: Ember.computed('hasGroups', 'model.{synced,_data.groups.@each.synced}', function () {
      const model = this.model || {};
      const hasGroups = this.hasGroups || false;
      if (model) {
        return model.getGroupedDocumentsPaymentMethodKeys({
          document: model,
          hasGroups,
          hasPaymentLastFiveDigits: true,
          isJoinResults: true,
          isTranslate: true
        });
      }
      return [];
    }),
    source: Ember.computed('model._data.source', function () {
      const source = this.get('model._data.source');
      if (!source || typeof source !== 'string') {
        return '';
      }
      if (source.length > 30) {
        return `${source.substring(0, 27)}...`;
      }
      return source;
    })
  });
});