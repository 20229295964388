define("client/pods/components/channels/shop/campaigns/items/table-view/detail-row/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _emberConcurrency, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    date: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    adapterName: 'channels/shop/campaigns/details',
    showDetails: false,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['filters/count', 'products/lists/menu', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypesFilters', 'statuses', 'channels/shop/campaigns/statuses', 'toggles/yesNo']);
      let limitAdjustType = 'adjustTotalQty';
      const adjustCampaignsDetailsLimitQtyBy = this.settings.getProp('adjustCampaignsDetailsLimitQtyBy');
      if (RA.isNotNilOrEmpty(adjustCampaignsDetailsLimitQtyBy)) {
        limitAdjustType = adjustCampaignsDetailsLimitQtyBy;
      }
      this.set('limitAdjustType', limitAdjustType);
      const model = this?.model || {};
      if (RA.isNotNilOrEmpty(model)) {
        const bulkDiscountSchedule = this?.bulkDiscountSchedule || {};
        if (!bulkDiscountSchedule?.hasAnyActive) {
          model.setData('bulkDiscountId', '');
        }
      }
    },
    bulkDiscountSchedule: Ember.computed('model._data.bulkDiscountId', 'tableViewOptions.campaign._data.bulkDiscounts.@each.synced', function () {
      const model = this.model;
      const campaign = this?.tableViewOptions?.campaign || {};
      if (RA.isNilOrEmpty(model)) {
        return {};
      }
      const bulkDiscountSchedule = model.getBulkDiscountSchedule({
        campaign
      });
      return {
        hasAnyActive: bulkDiscountSchedule.hasAnyActive,
        hasIsActive: bulkDiscountSchedule.hasIsActive,
        hasIsActiveNext: bulkDiscountSchedule.hasIsActiveNext,
        isActive: bulkDiscountSchedule.isActive,
        isActiveNext: bulkDiscountSchedule.isActiveNext
      };
    }),
    isSelected: Ember.computed('tableViewOptions.selectedItems.[]', function () {
      const selectedItems = this?.tableViewOptions?.selectedItems || [];
      if (selectedItems.indexOf(this.model) > -1) {
        return true;
      }
      return false;
    }),
    hasContentsIcon: Ember.computed('model._data.setType', 'showDetails', function () {
      const model = this.model;
      const setType = model?._data?.setType;
      if (!R.includes(setType, ['hasVariants', 'isSet', 'isSetWithChoices'])) {
        return '';
      }
      const showDetails = this.showDetails;
      if (showDetails) {
        return 'fas fa-angle-down';
      }
      return 'fas fa-angle-right';
    }),
    isDraggable: Ember.computed('filters._data.sort.[]', function () {
      const shopProductsSort = this.filters?.getData('shopProductsSort') || 'recommended';
      const existingSortBy = [{
        label: 'recommended',
        by: 'index',
        direction: 'ASC',
        toNumber: true
      }, {
        label: 'newestFirst',
        by: 'index',
        direction: 'DESC',
        toNumber: true
      }, {
        label: 'priceAsc',
        by: 'priceFinal',
        direction: 'ASC',
        toNumber: true
      }, {
        label: 'priceDesc',
        by: 'priceFinal',
        direction: 'DESC',
        toNumber: true
      }];
      const sortArray = R.filter(R.propEq('label', shopProductsSort))(existingSortBy);
      const sortBy = R.pathOr('', ['firstObject', 'by'])(sortArray);
      if (sortBy === 'index') {
        return true;
      }
      return false;
    }),
    isAllSelected: Ember.computed('tableViewOptions.{selectedItems.[],campaignDetails.[]}', function () {
      const tableViewOptions = this?.tableViewOptions || {};
      const selectedItems = tableViewOptions?.selectedItems || [];
      const campaignDetails = tableViewOptions?.campaignDetails || [];
      if (R.length(selectedItems) === R.length(campaignDetails)) {
        return true;
      }
      return false;
    }),
    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isSingleOrBulkEditing', function () {
      return !this.isSingleOrBulkEditing;
    }),
    isShowProductSchedules: Ember.computed('tableViewOptions.{isAutomations,isProductsSelector}', function () {
      const isAutomations = this?.tableViewOptions?.isAutomations || false;
      if (isAutomations) {
        return false;
      }
      const isProductsSelector = this?.tableViewOptions?.isProductsSelector || false;
      if (isProductsSelector) {
        return false;
      }
      return true;
    }),
    isShowExtraRowColumnPadding: Ember.computed('tableViewOptions.{isProductsSelector,fromGrapesJs}', function () {
      const isProductsSelector = this?.tableViewOptions?.isProductsSelector || false;
      if (isProductsSelector) {
        return false;
      }
      const fromGrapesJs = this?.tableViewOptions?.fromGrapesJs || false;
      if (fromGrapesJs) {
        return false;
      }
      return true;
    }),
    hasDetailShippingZones: Ember.computed('model._data.{allowedShippingZones.[],disallowedShippingZones.[]}', function () {
      const model = this?.model || {};
      const allowedShippingZones = model?._data?.allowedShippingZones || [];
      const disallowedShippingZones = model?._data?.disallowedShippingZones || [];
      if (RA.isNotNilOrEmpty(allowedShippingZones) || RA.isNotNilOrEmpty(disallowedShippingZones)) {
        return true;
      }
      return false;
    }),
    allowedShippingErrorMessage: Ember.computed('model.hasAllowedChildItemsInDisallowedZones', function () {
      const model = this?.model || {};
      const tableViewOptions = this.tableViewOptions;
      // console.log('tableViewOptions', tableViewOptions?.campaign)
      if (tableViewOptions?.campaign?._data?.shippingFeeType === this.constants.shippingFeeType.flat) {
        return '';
      }
      if (RA.isNilOrEmpty(tableViewOptions?.campaign?.shippingZonesIds) && tableViewOptions?.campaign?._data?.shippingFeeType === this.constants.shippingFeeType.zones) {
        return this.intl.t('no shipping zones set in campaign');
      }
      const hasAllowedChildItemsInDisallowedZones = model?.hasAllowedChildItemsInDisallowedZones || false;
      if (hasAllowedChildItemsInDisallowedZones) {
        return this.intl.t('some items have incorrect shipping zones');
      }
      return this.intl.t('no applicable shipping zones');
    }),
    // showResetFilter: computed('filters._data.{statuses.[],tags.[],setTypes.[],hasImages,item,itemCode,price}', function () {
    //   const filtersData = this?.filters?._data
    //   if (RA.isNilOrEmpty(filtersData)) {
    //     return false
    //   }

    //   const statuses = filtersData.statuses
    //   const hasImages = filtersData.hasImages
    //   const item = filtersData.item
    //   const itemCode = filtersData.itemCode
    //   const price = filtersData.price
    //   const setTypes = filtersData.setTypes
    //   const tags = filtersData.tags

    //   if (
    //     statuses.length !== 5 ||
    //     tags.length !== 0 ||
    //     setTypes.length !== 0 ||
    //     RA.isNilOrEmpty(hasImages) !== true ||
    //     item !== '' ||
    //     itemCode !== '' ||
    //     price !== ''
    //   ) {
    //     return true
    //   }

    //   return false
    // }),

    updateBulkEditingTask: (0, _emberConcurrency.task)(function* ({
      appendPath = '',
      model,
      isSkipBulkDiscountConflict = false
    }) {
      if (RA.isNilOrEmpty(appendPath)) {
        appendPath = '';
      }
      if (this.tableViewOptions?.isBulkEditing) {
        yield this.updateTask.perform({
          appendPath,
          model
        });
      }
      if (isSkipBulkDiscountConflict) {
        if (RA.isNilOrEmpty(model)) {
          model = this.model;
        }
        model.set('isShowBulkDiscountConflictDialogInDetailRow', false);
      }
    }),
    refetchCampaignDetail: (0, _emberConcurrency.task)(function* (model) {
      const updated = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/details',
        appendPath: `/${model?._data?._key}`
      });
      this.set('model', model);
    }),
    updateTask: (0, _emberConcurrency.task)(function* ({
      appendPath,
      model
    }) {
      model = model || this.model;
      let dirty = model;
      if (!model.isDirty) {
        dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details',
          model
        });
      }
      const product = model.product;
      const updated = yield this.crud.replaceRecordTask.perform({
        adapterName: 'channels/shop/campaigns/details',
        appendPath,
        model: dirty,
        component: this
      });
      if (updated && !this.tableViewOptions?.isBulkEditing) {
        this.set('model', updated);
        this.set('original', false);
        this.set('isSingleEditing', false);
        // } else if (updated && this.tableViewOptions?.isBulkEditing) {
        // const adapterName = this.adapterName
        // const dirty = this.crud.setupDirty({
        //   adapterName,
        //   model: updated,
        //   attrs: {
        //     product
        //   }
        // })

        // this.set('model', dirty)
      }
      if (updated) {
        this.set('errors', false);
      }
    }),
    cancel() {
      const original = this.original;
      const dirty = this.model;
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty
      });
      this.set('model', original);
      this.set('original', false);
      this.set('isSingleEditing', false);
      this.set('errors', false);
    },
    isEvenOrOddClassName: Ember.computed('model._data.index', function () {
      const index = this?.model?._data?.index || '';
      if (R.is(Number)(index)) {
        if (_nventor.default.isEven(index)) {
          return 'even';
        }
        return 'odd';
      }
      return '';
    }),
    rowClassName: Ember.computed('dropPosition', 'isEvenOrOddClassName', function () {
      const dropPosition = this.get('dropPosition') || '';
      // @TODO: remove classnames
      // table-view__detail-row
      // new-table-view__tr--detail-row
      // first check drag/drop
      return `new-table-view__tr table-view__detail-row new-table-view__tr--detail-row ${dropPosition}`;
    }),
    mainFocusAction(model) {
      if (this.onDisplay) {
        return this.onDisplay(model);
      }
    },
    mainActionWithModel(onCloseDialogAction, model) {
      // this should be deprecated?
      if (this.onMainAction) {
        return this.onMainAction(model);
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    },
    toggleDetails() {
      let showDetails = this.showDetails;
      this.toggleProperty('showDetails');
    },
    actions: {
      onCloseUpdateProductTags() {
        this.updateBulkEditingTask.perform({
          appendPath: '/bulk-discount-conflict'
        });
        const model = this.model || {};
        model.set('isShowBulkDiscountConflictDialogInDetailRow', true);
      },
      drop() {
        if (this.get('tableViewOptions.onDrop')) {
          this.get('tableViewOptions.onDrop')(...arguments);
        }
      },
      dragOver() {
        if (this.get('tableViewOptions.onDragOver')) {
          const dropPosition = this.get('tableViewOptions.onDragOver')(...arguments);
          this.set('dropPosition', dropPosition);
        }
      },
      dragStart() {
        if (this.get('tableViewOptions.onDragStart')) {
          this.get('tableViewOptions.onDragStart')(...arguments);
        }
      },
      edit() {
        const adapterName = this.adapterName;
        const model = this.model;
        const dirty = this.crud.setupDirty({
          adapterName,
          model
        });
        this.set('original', model);
        // dirty.set('product', model.get('product'))
        this.set('model', dirty);
        this.set('isSingleEditing', true);
        this.set('errors', false);
      },
      cancel() {
        this.cancel();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "mainFocusAction", [_dec], Object.getOwnPropertyDescriptor(_obj, "mainFocusAction"), _obj), _applyDecoratedDescriptor(_obj, "mainActionWithModel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "mainActionWithModel"), _obj), _applyDecoratedDescriptor(_obj, "toggleDetails", [_dec3], Object.getOwnPropertyDescriptor(_obj, "toggleDetails"), _obj), _obj));
});