define("client/pods/simple-editor/lists/styling/button-images-style/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: this.constants.buttonImagesOptions.none,
        value: this.constants.buttonImagesOptions.none
      }, {
        label: this.constants.buttonImagesOptions.left,
        value: this.constants.buttonImagesOptions.left
      }, {
        label: this.constants.buttonImagesOptions.right,
        value: this.constants.buttonImagesOptions.right
      }];
      return _nventor.default.resolve(data);
    }
  });
});