define("client/pods/components/documents/pos/profiles-status/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsPosProfilesStatusComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('args.posProfileModel.{synced,_data.autoIssueEInvoice}'), _dec7 = Ember.computed('args.posProfileModel.{_data.autoIssueEInvoice,user._data.printerId,user._data.printerKey}'), _class = class DocumentsPosProfilesStatusComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "eInvoicesService", _descriptor2, this);
      _initializerDefineProperty(this, "constants", _descriptor3, this);
      _initializerDefineProperty(this, "trackNumbersModel", _descriptor4, this);
      _initializerDefineProperty(this, "availableCountData", _descriptor5, this);
      _defineProperty(this, "availableCountToIssueThreshold", 50);
    }
    *setupTask() {
      const fetchAvailableCountToIssueTaskOptions = {
        userKey: ''
      };
      if (this.args.posProfileModel?.user?._data?.twEInvoiceAllowPrintingFrom === this.constants.twEInvoicePrinter.allowPrintingFrom.printerAllocatedNumbers) {
        fetchAvailableCountToIssueTaskOptions.userKey = this.args.posProfileModel?.user?._data?._key;
      }
      this.availableCountData = yield this.eInvoicesService.fetchAvailableCountToIssueTask.perform(fetchAvailableCountToIssueTaskOptions);
    }
    get autoIssueEInvoiceLabel() {
      const posProfileModel = this.args.posProfileModel;
      if (posProfileModel?._data?.autoIssueEInvoice) {
        return 'on';
      }
      return 'off';
    }
    get hasInvalidPrinterSetup() {
      const posProfileModel = this.args.posProfileModel;
      const autoIssueEInvoice = posProfileModel?._data?.autoIssueEInvoice;
      if (!autoIssueEInvoice) {
        return false;
      }
      if (!posProfileModel?.user?._data?.printerId) {
        return true;
      }
      if (!posProfileModel?.user?._data?.printerKey) {
        return true;
      }
      return false;
    }
    get isUnderThreshold() {
      if (this.availableCountData.availableCountToIssue <= this.availableCountToIssueThreshold) {
        return true;
      }
      return false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "trackNumbersModel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "availableCountData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoIssueEInvoiceLabel", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "autoIssueEInvoiceLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasInvalidPrinterSetup", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "hasInvalidPrinterSetup"), _class.prototype), _class);
});