define("client/pods/components/websites/websites-editor/pages-editor/preview-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Pqp28KHY",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@onSubmitAfterPreview\",\"@onAfterShowSavedLabel\",\"@model\"],\"statements\":[[5,\"lists/list-btn\",[],[[\"@icon\"],[\"fas fa-eye\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@label\",\"@tooltipTranslate\",\"@isRunning\",\"@onSubmit\"],[\"\",\"view webpage\",false,[23,2,[\"isRunning\"]],[28,\"perform\",[[23,2,[]],[23,3,[]],[23,1,[]],false],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,4,[\"_data\",\"password\"]]],null,{\"statements\":[[0,\"    \"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n    \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@label\",\"@tooltipTranslate\",\"@isRunning\",\"@onSubmit\"],[\"\",\"view locked\",false,[23,2,[\"isRunning\"]],[28,\"perform\",[[23,2,[]],[23,3,[]],[23,1,[]],true],null]]]],[0,\"\\n\\n    \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@translate\",\"@label\",\"@onSubmit\"],[\"far fa-copy\",false,[28,\"concat\",[[28,\"tt\",[\"password\"],null],\": \",[23,4,[\"_data\",\"password\"]]],null],[28,\"fn\",[[23,0,[\"application\",\"copyText\"]],[23,4,[\"_data\",\"password\"]],false],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/pages-editor/preview-link/template.hbs"
    }
  });
});