define("client/pods/contacts/groups/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _obj = {
    defaults() {
      return {
        name: '',
        description: '',
        relationships: ['customer'],
        criterias: []
      };
    },
    populate(data = {}, attrs = {}) {
      // may be overridden

      data = this._transformCriterias(data);
      this._super(...arguments);
    },
    _transformCriterias(rawData) {
      rawData.criterias = R.pipe(R.propOr([], 'criterias'), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'contacts/groups/criterias',
          data
        });
      }))(rawData);
      return rawData;
    },
    newCriteria() {
      const criteria = this.dataManager.newRecord({
        adapterName: 'contacts/groups/criterias',
        data: {}
      });
      const criterias = this.getData('criterias') || [];
      criterias.pushObject(criteria);
      this.setData('criterias', criterias);
    },
    removeCriteria(criteria) {
      const hasSelected = criteria?._data?.by;
      const message = this.intl.t('are you sure you want to remove item', {
        item: this.intl.t('match_contacts_groups_criteria')
      });
      let confirmed = true;
      if (hasSelected) {
        confirmed = window.confirm(message);
      }
      if (confirmed) {
        const criterias = this.getData('criterias') || [];
        criterias.removeObject(criteria);
        this.setData('criterias', criterias);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "newCriteria", [_dec], Object.getOwnPropertyDescriptor(_obj, "newCriteria"), _obj), _applyDecoratedDescriptor(_obj, "removeCriteria", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeCriteria"), _obj), _obj));
});