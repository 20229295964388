define("client/pods/components/elements/error-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uNPAPemW",
    "block": "{\"symbols\":[\"&default\",\"@errorWrapperClassname\",\"@context\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"error-wrapper \",[23,2,[]]]]],[11,\"data-validation\",[23,3,[]]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[23,3,[]],\"-error error-message has-text-small has-text-danger\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/error-wrapper/template.hbs"
    }
  });
});