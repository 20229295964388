define("client/pods/components/elements/btn-back/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    tooltipLabel: 'back to previous',
    label: '',
    btnClassNames: '',
    icon: 'fas fa-arrow-left'
  });
});