define("client/pods/components/users/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Z7LBrwEv",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"each\",[[24,[\"results\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[3,\"action\",[[23,0,[]],[24,[\"onDisplay\"]],[23,1,[]]]],[8],[0,\"\\n    \"],[1,[23,1,[\"_data\",\"name\"]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"users-lists-types\"]],[23,1,[\"_data\",\"type\"]]]]],false],[0,\"\\n\\n    \\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"users-lists-roles-internal\"]],[23,1,[\"_data\",\"role\"]]]]],false],[0,\"\\n\\n    \\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"statuses\"]],[23,1,[\"_data\",\"status\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/users/panels-dash/template.hbs"
    }
  });
});