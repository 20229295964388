define("client/pods/components/helpers/drag-and-drop/drag-container/component", ["exports", "client/mixins/draggable"], function (_exports, _draggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this is the NEW way.
  // helpers/DragContainer and hlepers/DropContainer should be deprecated
  var _default = _exports.default = Ember.Component.extend(_draggable.default, {
    tagName: 'span',
    classNames: ['drag-container']
  });
});