define("client/pods/components/documentation/features-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4TP1GYyQ",
    "block": "{\"symbols\":[\"carouselComponent\",\"slide\",\"index\",\"@modalDialogTitleSuffix\",\"@modalSize\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"documentation\",\"_data\",\"_key\"]]],null,{\"statements\":[[0,\"  \"],[5,\"modals/modal-dialog\",[],[[\"@onClose\",\"@modelContainerClassNames\",\"@modalDialogIsVisible\",\"@hasModalDialogHeader\",\"@hasModalOverlay\",\"@hasSectionWrapper\",\"@title\",\"@translate\",\"@titleSuffix\",\"@closeOnOverlayClick\",\"@modalSize\",\"@modalDialogContainerClassName\",\"@modalDialogContentClassName\"],[[28,\"fn\",[[23,0,[\"closeDialog\"]]],null],\"documentation-features-popup\",[23,0,[\"modalDialogIsVisible\"]],true,[23,0,[\"hasModalOverlay\"]],false,\"new features\",true,[23,4,[]],true,[23,5,[]],[22,\"modalDialogContainerClassName\"],[22,\"modalDialogContentClassName\"]]],{\"statements\":[[0,\"\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"setupTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/carousel\",[],[[\"@slides\",\"@onClose\"],[[23,0,[\"documentation\",\"_data\",\"templates\"]],[23,0,[\"closeDialog\"]]]],{\"statements\":[[0,\"\\n        \"],[5,\"layouts/section\",[],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"helpers/content-display\",[],[[\"@content\"],[[23,2,[\"_data\",\"template\"]]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[1,2,3]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentation/features-popup/template.hbs"
    }
  });
});