define("client/pods/components/channels/shop/shop-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UrIpZjDc",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@tagClassNames\"],\"statements\":[[4,\"if\",[[24,[\"badgeOnly\"]]],null,{\"statements\":[[0,\"  \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[\"icon \",[28,\"if\",[[24,[\"isOnline\"]],\"has-text-primary\",\"has-text-danger\"],null],\" \",[23,2,[]]]]],[8],[0,\"\\n      \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"fas fa-circle\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\"],[[23,1,[]]]],{\"statements\":[[0,\"      \"],[1,[24,[\"model\",\"_data\",\"name\"]],false],[0,\"\\n      \"],[1,[28,\"channels/shop/shop-status\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"tag \",[28,\"if\",[[24,[\"isOnline\"]],\"is-primary\",\"is-danger\"],null],\" \",[23,2,[]]]]],[8],[0,\"\\n    \"],[1,[22,\"status\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shop-status/template.hbs"
    }
  });
});