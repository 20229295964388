define("client/pods/components/helpers/no-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1/5rFO/3",
    "block": "{\"symbols\":[\"&attrs\",\"@isRunning\"],\"statements\":[[4,\"unless\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"u-container u-container--no-data\"],[13,1],[8],[0,\"\\n    \"],[1,[28,\"tt\",[\"no data\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/no-data/template.hbs"
    }
  });
});