define("client/pods/components/elements/mixins/has-date-picker", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { observer } from '@ember/object'
  var _default = _exports.default = Ember.Mixin.create(_date.default, {
    isDatePicker: false,
    onSelectDate: null,
    isDatePickerVisible: false,
    init() {
      this._super(...arguments);
      //   //   const self = this

      //   //   // sets up pikaday, will only run after it has been rendered
      if (this.isDatePicker) {
        //     //     // setup pikaday with options
        //     //     const options = {
        //     //       // bound: false => do not bind pikaday to input focus, handle it manually
        //     //       bound: false,
        //     //       keyboardInput: false,
        //     //       position: '',
        //     //       firstDay: 1,
        //     //       onSelect: function (/* date */) {
        //     //         self._onSelectDate()
        //     //       }
        //     //     }
        //     //     // create new pikaday instance
        //     //     const pikaday = this.get('pikaday').setup(options)
        //     //     pikaday.hide()

        //     //     // set pikaday instance to datePicker property
        //     //     this.set('datePicker', pikaday)

        this.setupLocalDateValue();
      }
    },
    // // didInsertElement () {
    // //   this._super(...arguments)
    // //   if (this.get('isDatePicker')) {
    // //     this.setupDatePicker()
    // //   }
    // // },

    // // setupDatePicker () {
    // //   const pikaday = this.get('datePicker')
    // //   const picker = this.$(pikaday.el)
    // //   const container = this.$('.u-datepicker-container')

    // //   container.append(picker)

    // //   this.setPickerDate()

    // //   const datePicker = this.get('datePicker')
    // //   datePicker.show()
    // // },

    setupLocalDateValue() {
      const dateZ = this.dateZ;
      if (dateZ) {
        const localDate = this.formatDate(dateZ);
        this.set('value', localDate);
      }
    },
    click() {
      if (this.isDatePicker && this.readonly !== true) {
        this.showDatePicker();
      }
    },
    // dateDisplayValue: Ember.computed('value', 'dateZ', function () {
    //
    //   const dateZ = this.get('dateZ');
    //
    //   return this.formatDate(dateZ);
    // }),
    // dateZHasChanged: observer('isDatePicker', 'dateZ', function () {
    //   if (this.get('isDatePicker')) {
    //     const dateZ = this.get('dateZ')

    //     const dateString = this.formatDate(dateZ)
    //     this.set('value', dateString)
    //   }
    // }),

    // _onSelectDate () {
    //   // format date on select

    //   // @TODO: test

    //   // const input = this.$()[0];
    //   const datePicker = this.get('datePicker')

    //   // pikaday uses iso8601 YYYY/MM/DD as default
    //   const dateString = datePicker.toString()

    //   // date at local time zone
    //   const localDate = this.formatDate(dateString)

    //   // date at Zulu time zone
    //   const dateZ = datePicker.getDate().toISOString()

    //   const previousDateZ = this.get('dateZ')

    //   this.set('dateZ', dateZ)

    //   // hide pikaday on select
    //   this.hideDatePicker()

    //   if (this.get('onSelectDate')) {
    //     if (dateZ !== previousDateZ) {
    //       // trigger onSelectDate action if exists
    //       this.get('onSelectDate')(dateZ, localDate)
    //     }
    //   }
    // },

    // setPickerDate (localDate) {
    //   localDate = localDate || this.get('value')

    //   const dateZ = this.getStartOfDayZ(localDate, this.get('dateFormat'))

    //   const datePicker = this.get('datePicker')
    //   datePicker.setDate(dateZ)
    // },

    // willDestroyElement () {
    //   // tear down pikaday
    //   if (this.get('isDatePicker')) {
    //     this.get('datePicker').destroy()
    //   }
    // },

    showDatePicker() {
      this.set('isDatePickerVisible', true);

      // const pikaday = this.get('datePicker')
      // const picker = this.$(pikaday.el)
      // const container = this.$('.u-datepicker-container')

      // container.append(picker)

      // this.setPickerDate()

      // const datePicker = this.get('datePicker')
      // datePicker.show()

      // const dropdownContainer = this.$('.u-datepicker-container')
      // const height = dropdownContainer.outerHeight()
      // const width = dropdownContainer.outerWidth()
      // const top = dropdownContainer.offset().top
      // const right = dropdownContainer.offset().left + width

      // const bottom = top + height
      // const viewportTop = this.$(window).scrollTop()
      // const viewportRight = this.$(window).width()
      // const viewportBottom = viewportTop + this.$(window).height() - 35

      // if (bottom > viewportBottom) {
      //   const topDifference = bottom - viewportBottom + 40
      //   dropdownContainer.css('marginTop', `-=${topDifference}px`)
      // }

      // if (right > viewportRight) {
      //   const rightDifference = right - viewportRight + 40
      //   dropdownContainer.css('marginLeft', `-=${rightDifference}px`)
      // }
    },
    hideDatePicker() {
      this.set('isDatePickerVisible', false);
    },
    focusIn: function (e) {
      this._super(e);
      if (this.isDatePicker && this.readonly !== true) {
        // show datePicker when focusIn
        this.showDatePicker();
      }
    },
    focusOut: function (e) {
      this._super(e);
      if (this.isDatePicker && this.readonly !== true) {
        this.hideDatePicker();
      }
    },
    keyDown(e) {
      this._super(...arguments);
      if (this.isDatePicker) {
        const keyCode = e.keyCode || e.which;

        // disable arrow keys
        if (keyCode >= 37 && keyCode <= 40) {
          e.stopPropagation();
        }
        if (keyCode === 13) {
          e.stopPropagation();
          this.hideDatePicker();
          // this.setPickerDate()
        }
      }
    },
    actions: {
      onSelectDate() {
        this.setupLocalDateValue();
        if (this.onSelectDate) {
          this.onSelectDate();
        }
        this.hideDatePicker();
      }
    }
  });
});