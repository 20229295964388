define("client/pods/components/forms/form-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6GGt5sCt",
    "block": "{\"symbols\":[\"@betaPath\",\"@allowedForFeatures\",\"@disallowedForFeatures\",\"&default\"],\"statements\":[[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\",\"@allowedForFeatures\",\"@disallowedForFeatures\"],[[23,1,[]],[23,2,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"unless\",[[24,[\"overrideFormInputClassNames\"]],\"form__input\"],null],\" \",[22,\"formInputClassNames\"]]]],[8],[0,\"\\n    \"],[14,4],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-input/template.hbs"
    }
  });
});