define("client/pods/components/channels/shop/promo-codes/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QLXaSoyE",
    "block": "{\"symbols\":[],\"statements\":[[5,\"channels/shop/promo-codes/promo-codes-editor\",[],[[\"@model\",\"@showUseExpiryDateToggler\",\"@errors\",\"@isRunning\",\"@onSaveTask\",\"@onRemoveRecordTask\",\"@isNew\",\"@isEditing\",\"@tab\",\"@onCancel\"],[[22,\"model\"],false,[22,\"errors\"],[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"component\",\"onAfter\"],[[23,0,[\"adapterName\"]],[24,[\"model\"]],[23,0,[]],[24,[\"onDash\"]]]]]],null],[28,\"perform\",[[23,0,[\"crud\",\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\"],[[23,0,[\"adapterName\"]],[23,0,[]],[24,[\"model\"]],[24,[\"onDash\"]]]]]],null],false,true,[22,\"tab\"],[22,\"onDash\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/promo-codes/panels-edit/template.hbs"
    }
  });
});