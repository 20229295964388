define("client/pods/components/channels/shop/items/item-price/item-price-current/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YRhppIAr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"store-item__price \",[28,\"if\",[[24,[\"currentIsOnSale\"]],\"store-item__price--sale\"],null]]]],[8],[0,\"\\n  \"],[1,[28,\"currency\",[[24,[\"currentPrice\"]]],null],false],[0,\" \\n\"],[4,\"if\",[[24,[\"currentHasPriceRange\"]]],null,{\"statements\":[[0,\"    +\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/items/item-price/item-price-current/template.hbs"
    }
  });
});