define("client/pods/components/blogs/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yDH6KjuO",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"blogs/blogs-editor\",null,[[\"model\",\"errors\",\"saveTask\",\"isNew\",\"isEditing\",\"isReadonly\",\"isDisabled\",\"tab\",\"isDuplicate\",\"onCancel\",\"onRemoveRecordTask\"],[[24,[\"dirty\"]],[24,[\"errors\"]],[24,[\"updateRecordTask\"]],false,true,false,false,[24,[\"tab\"]],[24,[\"isDuplicate\"]],[24,[\"onCancel\"]],[28,\"perform\",[[23,0,[\"removeRecordTask\"]],[28,\"hash\",null,[[\"noConfirm\"],[true]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/panels-edit/template.hbs"
    }
  });
});