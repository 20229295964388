define("client/pods/components/users/users-editor/component", ["exports", "ramda", "ember-concurrency-decorators", "client/constants"], function (_exports, R, _emberConcurrencyDecorators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_obj = {
    crud: Ember.inject.service(),
    users: Ember.inject.service(),
    terminalService: Ember.inject.service('terminal'),
    eInvoicesService: Ember.inject.service('extensions/apps/tw/e-invoices'),
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    isNew: false,
    hasRemainingCount: false,
    init() {
      this.crud.addLists(this, ['users/lists/types', 'users/lists/roles/internal', 'users/lists/roles/external', 'users/lists/roles/terminal', 'users/lists/ip-addresses', 'statuses', 'printer/lists/allow-printing-from']);
      this._super(...arguments);
      this.fetchRemainingCount.perform();
    },
    *fetchRemainingCount() {
      const isEInvoicePrinterTerminal = R.pathEq(['model', '_data', 'type'], _constants.default.userTypes.eInvoicePrinterTerminal)(this);
      if (!isEInvoicePrinterTerminal) {
        return;
      }
      const allowPrintingFromAnyNextAvailableNumbers = R.pathEq(['model', '_data', 'twEInvoiceAllowPrintingFrom'], _constants.default.twEInvoicePrinter.allowPrintingFrom.anyNextAvailableNumbers)(this);
      if (allowPrintingFromAnyNextAvailableNumbers) {
        return;
      }
      const fetchAvailableCountToIssueData = yield this.eInvoicesService.fetchAvailableCountToIssueTask.perform({
        userKey: this.model?._data?._key
      });
      const hasRemainingCount = fetchAvailableCountToIssueData?.hasRemainingCount || false;
      this.set('hasRemainingCount', hasRemainingCount);
      return fetchAvailableCountToIssueData;
    },
    allowPrintingFromList: Ember.computed('model._data.role', function () {
      const userRole = this.users.getProp('role');
      if (userRole === 'super') {
        return false;
      }
      return true;
    }),
    notAllowedToChangeSecurity: Ember.computed('crud.lists.printer-lists-allow-printing-from', 'hasRemainingCount', function () {
      const list = R.pathOr([], ['crud', 'lists', 'printer-lists-allow-printing-from'])(this);
      if (!this.hasRemainingCount) {
        return list;
      }
      return R.filter(R.pathEq(['_data', 'value'], _constants.default.twEInvoicePrinter.allowPrintingFrom.printerAllocatedNumbers))(list);
    }),
    notAllowDelete: Ember.computed('model._data._key', function () {
      const tokenData = this.storage.get('tokenData') || {};
      const uid = tokenData?.uid;
      const userKey = R.path(['model', '_data', '_key'])(this);
      if (uid === userKey) {
        return true;
      }
      const role = tokenData?.role || '';
      if (R.toLower(role) === 'super') {
        return false;
      }
      return true;
    }),
    isReadonly: Ember.computed('isEditing', 'isNew', function () {
      if (this.isNew) {
        return false;
      }
      if (this.isEditing) {
        return false;
      }
      return true;
    }),
    disableChangeRoles: Ember.computed('model._data.role', 'isReadonly', function () {
      if (this.isReadonly) {
        return true;
      }
      const role = this.get('users').getProp('role');
      if (R.toLower(role) === 'super') {
        return false;
      }
      return true;
    }),
    rolesListByUserType: Ember.computed('model._data.{role,type}', 'crud.lists.users-lists-roles-internal', 'crud.lists.users-lists-roles-external', 'crud.lists.users-lists-roles-terminal', function () {
      const userType = this.model?._data?.type || '';
      const dynamicRolesList = R.pathOr([], ['crud', 'lists', `users-lists-roles-${userType}`])(this);
      if (dynamicRolesList && dynamicRolesList.length) {
        return dynamicRolesList;
      }
      const customRolesList = {
        [this.constants.userTypes.employee]: R.pathOr([], ['crud', 'lists', 'users-lists-roles-internal'])(this),
        [this.constants.userTypes.eInvoicePrinterTerminal]: R.pathOr([], ['crud', 'lists', 'users-lists-roles-terminal'])(this)
      };
      return customRolesList[userType];
    }),
    shouldShowAllowPublicTemplate: Ember.computed('model._data.{role,type}', function () {
      const isAdmin = this.users.getProp('isAdmin') === true;
      if (!isAdmin) {
        return false;
      }
      const userType = this.model?._data?.type || '';
      if (userType === this.constants.userTypes.eInvoicePrinterTerminal) {
        return false;
      }
      return true;
    }),
    actions: {
      toggleType() {
        const model = this.model;
        if (model.getData('type') === this.get('constants.userTypes.eInvoicePrinterTerminal')) {
          model.setData('twEInvoiceAllowPrintingFrom', 'anyNextAvailableNumbers');
        } else {
          model.setData('twEInvoiceAllowPrintingFrom', '');
        }
        if (model.getData('type') === this.get('constants.userTypes.external')) {
          model.setData('username', '');
          model.setData('role', 'external');
        } else {
          model.setData('role', 'general');
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "fetchRemainingCount", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "fetchRemainingCount"), _obj), _obj));
});