define("client/pods/channels/shop/campaigns/automations/details/model", ["exports", "client/mixins/products-children", "client/mixins/uploads", "client/pods/channels/shop/campaigns/details/model"], function (_exports, _productsChildren, _uploads, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ChannelShopCampaignsAutomationsDetailsBaseModel = _model.default.extend(_productsChildren.default, _uploads.default, {
    init() {
      this._super(...arguments);
      this.set('requiredAttrs', ['_data.itemKey', '_data.item', '_data.priceDiscounted']);
    },
    defaults() {
      return {
        productSync: ['item', 'templates', 'productTags', 'productDisplayTags', 'description', 'additionalDescription', 'recurringSchedules'],
        automationQty: 1,
        status: this.get('constants.status.active'),
        deliveryDateSettings: this.get('constants.togglesAuto.auto'),
        // showOriginalPrice: this.get('constants.togglesAuto.auto'),
        onSale: this.get('constants.togglesOnOff.on'),
        showLimitedQty: this.get('constants.togglesAuto.auto'),
        showCountdownTimer: this.get('constants.togglesAuto.auto'),
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        commissionMethod: this.get('constants.togglesAuto.auto')
      };
    }
  });
  let ChannelShopCampaignsAutomationsDetailsModel = _exports.default = (_dec = Ember._tracked, _class = class ChannelShopCampaignsAutomationsDetailsModel extends ChannelShopCampaignsAutomationsDetailsBaseModel {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});