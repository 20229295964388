define("client/pods/components/websites/websites-editor/website-wizard/quick-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XuOeB49H",
    "block": "{\"symbols\":[\"formInitData\",\"@resourceList\",\"@wizardContainerComponent\",\"@tab\",\"@documentationIsMinimized\",\"@model\",\"@onCloseDialogAction\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@resourceList\",\"@hasColumns\",\"@isUserAllowed\",\"@formInitData\",\"@tab\"],[\"websites-wizard-templates\",[23,2,[]],false,true,[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"websitesPages\",[23,5,[]],[23,4,[]],[23,2,[]],[23,3,[]]]]],[23,4,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@isWizard\",\"@tab\",\"@title\"],[\"themeName_group\",[23,1,[]],true,[23,4,[]],\"pick a color theme\"]],{\"statements\":[[0,\"\\n    \"],[5,\"color-themes\",[],[[\"@model\",\"@autoSelect\"],[[23,6,[]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"  \"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@isWizard\",\"@tab\",\"@title\"],[\"defaultHomePageTemplate_group\",[23,1,[]],true,[23,4,[]],\"website template\"]],{\"statements\":[[0,\"\\n    \"],[5,\"websites/websites-editor/templates\",[],[[\"@model\",\"@onCloseDialogAction\",\"@tab\",\"@templateOptions\"],[[23,6,[]],[23,7,[]],[23,4,[]],[28,\"hash\",null,[[\"perTemplate\"],[[28,\"hash\",null,[[\"onClick\",\"hideButtonsTemplate\"],[[23,0,[\"onClick\"]],true]]]]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/website-wizard/quick-templates/template.hbs"
    }
  });
});