define("client/pods/dispatch/lists/print-all-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'pickingList',
        label: 'picking list',
        component: 'dispatch/panels-display/display-list',
        tabType: 'reuseSubTab',
        icon: 'fas fa-people-carry'
      }, {
        value: 'deliveryNotes',
        label: 'delivery notes',
        component: 'documents/document-print/print-list',
        tabType: 'reuseSubTab',
        icon: 'fas fa-truck'
      }, {
        value: 'waybills',
        label: 'hct waybills',
        component: 'transporters/hct-waybills-list',
        tabType: 'reuseSubTab',
        icon: 'fas fa-sticky-note',
        transporterExtension: 'hct'
      }, {
        value: 'hctSummary',
        label: 'hct summary',
        component: 'transporters/hct-summary',
        tabType: 'reuseSubTab',
        icon: 'fas fa-sticky-note',
        transporterExtension: 'hct'
      }, {
        value: 't-cat',
        label: 't-cat waybill',
        component: 'transporters/t-cat-waybill',
        tabType: 'reuseSubTab',
        icon: 'fas fa-cat',
        transporterExtension: 't-cat'
      }];
      if (this.settings.getProp('beta.sevenEleven')) {
        data.push({
          value: 'seven-eleven',
          label: 'seven-eleven waybill',
          component: 'transporters/seven-eleven-waybill',
          tabType: 'reuseSubTab',
          icon: 'fas fa-sticky-note',
          transporterExtension: 'seven-eleven'
        });
      }
      if (this.settings.getProp('beta.payuniSevenEleven')) {
        data.push({
          value: 'payuni-seven-eleven',
          label: 'payuni-seven-eleven waybill',
          component: 'transporters/payuni-seven-eleven-waybill',
          tabType: 'reuseSubTab',
          icon: 'fas fa-sticky-note',
          transporterExtension: 'payuni-seven-eleven'
        });
      }
      return _nventor.default.resolve(data);
    }
  });
});