define("client/pods/components/websites/panels-display/sub-panels-shop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XByFk3IE",
    "block": "{\"symbols\":[\"@tab\"],\"statements\":[[4,\"if\",[[23,0,[\"shop\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"channels/shop/campaigns/tab-container\",[],[[\"@allowDuplicating\",\"@preloadedModel\",\"@tab\",\"@tabContentOnly\"],[false,[23,0,[\"shop\"]],[23,1,[]],true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-shop/template.hbs"
    }
  });
});