define("client/pods/components/grapesjs/re-index-modal/component", ["exports", "ramda", "ramda-adjunct", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, R, _ramdaAdjunct, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GrapesjsReIndexModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _class = class GrapesjsReIndexModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "indexModelArray", _descriptor2, this);
      _initializerDefineProperty(this, "frontendErrors", _descriptor3, this);
      _initializerDefineProperty(this, "saveDisabled", _descriptor4, this);
      _defineProperty(this, "slidesTypeName", 'lory-slides');
      _defineProperty(this, "slideTypeName", 'lory-slide');
      this.setIndexModelArray();
    }

    /**
     * Get Index from current elements, and store the Re-Indexed value
     */
    setIndexModelArray() {
      const selected = this.args.editor.getSelected();
      const targets = selected.findType(this.slideTypeName);
      let index = 0;
      R.forEach(child => {
        index++;
        const children = child.getEl().innerHTML;
        this.indexModelArray.push({
          slide: R.clone(child),
          children,
          index
        });
      })(targets);
    }
    *frontendValidation(indexModelArray) {
      // currently the @model does not contains dirty, thus cannot show validation
      this.frontendErrors = [];
      const errors = this.frontendErrors;
      let i = 0;
      indexModelArray.forEach(model => {
        const index = model.index;
        if (_ramdaAdjunct.default.isNilOrEmpty(index)) {
          errors.push({
            type: 'isRequired',
            message: 'is required',
            path: [`grapejs_index[${i}]`],
            context: {
              key: `grapejs_index[${i}]`
            },
            isError: true
          });
        }
        i++;
      });
      if (errors.length === 0) {
        const uniqueLength = R.pipe(R.pluck('index'), R.map(userInputIndex => Number(userInputIndex)), R.uniq, R.length)(indexModelArray);
        if (uniqueLength !== indexModelArray.length) {
          // there may not be no such error yet
          errors.push({
            type: 'notUnique',
            message: 'should be unique values',
            path: ['grapejs'],
            context: {
              key: 'grapejs'
            },
            isError: true
          });
        }
      }
      if (errors.length > 0) {
        return false;
      }
      return true;
    }
    *onSave(onCloseDialogAction) {
      const indexModelArray = this.indexModelArray;
      const isValid = yield this.frontendValidation.perform(indexModelArray);
      if (!isValid) {
        return;
      }
      const gjsSelectedElement = this.args.editor.getSelected();
      const slides = gjsSelectedElement.findType(this.slidesTypeName)[0];
      const newChildren = R.pipe(R.sortWith([R.ascend(R.prop('index'))]), R.pluck('slide'))(indexModelArray);
      slides.empty();
      slides.append(newChildren);
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "indexModelArray", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "frontendErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setIndexModelArray", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setIndexModelArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "frontendValidation", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "frontendValidation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype), _class);
});