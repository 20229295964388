define("client/pods/channels/shop/shipping-zones/lists/sub-panels-editor/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 'details',
        label: 'details',
        component: 'channels/shop/shipping-zones/shipping-zones-editor/details-editor',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/shipping-zones',
        errorsTracker: [{
          context: 'name',
          label: 'name'
        }, {
          context: 'status',
          label: 'status'
        }, {
          context: 'zoneType',
          label: 'zoneType'
        }, {
          context: 'productTags',
          label: 'productTags'
        }, {
          context: 'denyProductTags',
          label: 'denyProductTags'
        }, {
          context: 'additionalFreeShippingRule.when',
          label: 'additionalFreeShippingRule.when'
        }, {
          context: 'additionalFreeShippingRule.productTags',
          label: 'additionalFreeShippingRule.productTags'
        }, {
          context: 'transporterLabel',
          label: 'transporterLabel'
        }, {
          context: 'transporterAccountNumber',
          label: 'transporterAccountNumber'
        }, {
          context: 'shopPaymentMethods',
          label: 'shopPaymentMethods'
        }]
      }];
      return _nventor.default.resolve(data);
    }
  });
});