define("client/pods/documents/mixins/calculations-exclusive-or-inclusive", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    dateService: Ember.inject.service('date'),
    constants: Ember.inject.service(),
    /**
     * calculate document totals based on details
     * (sums up totals done by calculateLineTotals)
     * @TODO: REFACTOR
     */
    sumTotals() {
      const docType = this.getData('docType');
      const docName = this.getData('docName');

      // @TODO: get from settings
      const roundingCurrency = this.getData('roundingCurrency', this.getData('roundingCurrency', this.get('settings.roundingCurrency')));
      const details = this.getData('details') || [];
      const isTaxExclusive = this.isTaxExclusive;
      const hasTaxMethod = this.hasTaxMethod;
      let exclTotal = this.newBig(0);
      let taxTotal = this.newBig(0);
      let inclTotal = this.newBig(0);
      let itemOnlyInclTotal = this.newBig(0);
      let itemOnlyExclTotal = this.newBig(0);
      const rewardData = this.getData('rewardData') || {};
      if (!(docType === 'sales' && docName === 'returns')) {
        this._setContactRewardData({
          rewardData
        });
      }
      const rewardEventForOrder = this._getRewardEventForOrder({
        rewardData
      });
      this.set('rewardEventForOrder', rewardEventForOrder);
      this.set('details', details.map(detailModel => {
        const isSetChild = detailModel.getData('isSetChild') || false;
        if (isSetChild) {
          return detailModel;
        }
        detailModel.calculateLineTotals({
          isTaxExclusive,
          hasTaxMethod
        });
        const detailExclAmt = detailModel.getData('exclAmt') || 0;
        const detailTaxAmt = detailModel.getData('taxAmt') || 0;
        const detailInclAmt = detailModel.getData('inclAmt') || 0;

        // not rounding here because each detail is already rounded
        const item = detailModel.getData('item') || '';
        if (RA.isNotNilOrEmpty(item)) {
          exclTotal = exclTotal.plus(detailExclAmt);
          taxTotal = taxTotal.plus(detailTaxAmt);
          inclTotal = inclTotal.plus(detailInclAmt);
        }
        const itemType = detailModel.getData('type') || '';
        if (itemType === '' || itemType === 'automationItems' || itemType === 'inventory' || itemType === 'service') {
          itemOnlyInclTotal = itemOnlyInclTotal.plus(detailInclAmt);
          itemOnlyExclTotal = itemOnlyExclTotal.plus(detailExclAmt);
        }
        return detailModel;
      }));
      let exclTotalRounded;
      let inclTotalRounded;
      const taxTotalRounded = taxTotal.toFixed(roundingCurrency);
      if (this.isTaxExclusive) {
        exclTotalRounded = exclTotal.toFixed(roundingCurrency);
        inclTotalRounded = this.newBig(exclTotalRounded).plus(taxTotalRounded).toFixed(roundingCurrency);
      } else {
        inclTotalRounded = inclTotal.toFixed(roundingCurrency);
        exclTotalRounded = this.newBig(inclTotalRounded).minus(taxTotalRounded).toFixed(roundingCurrency);
      }
      this.setData('exclTotal', exclTotalRounded);
      this.setData('taxTotal', taxTotalRounded);
      this.setData('inclTotal', inclTotalRounded);
      const itemOnlyInclTotalRounded = itemOnlyInclTotal.toFixed(roundingCurrency);
      const itemOnlyExclTotalRounded = itemOnlyExclTotal.toFixed(roundingCurrency);
      this._applyDiscount(exclTotalRounded, inclTotalRounded);
      this._applyReward({
        rewardEventForOrder,
        itemOnlyInclTotalRounded,
        itemOnlyExclTotalRounded
      });
      if (docType === 'sales' && docName === 'returns') {
        const details = this.getData('details') || [];
        const creditCardRefundTotalBig = R.reduce((acc, detail) => {
          const isSetChild = detail.getData('isSetChild') || false;
          if (!isSetChild) {
            const qty = Number(detail.getData('qty')) || 0;
            const originalQty = Number(detail.getData('originalQty')) || 0;
            if (detail.getData('paymentCreditCardStatus') === this.constants.paymentCreditCardStatus.refundRequested && qty > 0) {
              let postDiscInclAmt = detail.getData('postDiscInclAmt') || 0;
              if (R.gt(originalQty, 0)) {
                postDiscInclAmt = this.newBig(postDiscInclAmt).times(qty).div(originalQty).toFixed(roundingCurrency);
              }
              acc = acc.plus(postDiscInclAmt);
            }
          }
          return acc;
        }, this.newBig(0))(details);
        this.set('creditCardRefundTotal', creditCardRefundTotalBig.toFixed(roundingCurrency));
      }
      this._sumCommissionAmts();
    },
    /**
     * checks if discount is a rate or amount (for both price exclusive/inclusive),
     * returns rounded discount amount
     */
    _calculateDiscountTotal(isTaxExclusive, exclTotalRounded, inclTotalRounded) {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      let discRateBig = this.getDataBig('discountRate');
      if (this.isDiscountRate) {
        if (discRateBig.gt(0)) {
          // discount is %, divide by 100
          discRateBig = discRateBig.div(100);
          let discAmountBig = this.newBig();
          if (isTaxExclusive) {
            discAmountBig = this.newBig(exclTotalRounded).times(discRateBig);
          } else {
            discAmountBig = this.newBig(inclTotalRounded).times(discRateBig);
          }
          return discAmountBig.toFixed(roundingCurrency);
        } else {
          return false;
        }
      }
      if (!this.isDiscountRate) {
        const discAmountBig = this.getDataBig('discountAmount');
        if (this.getData('docType') === 'sales' && this.getData('docName') === 'returns') {
          const isReturnAllDiscountAmount = this.get('isReturnAllDiscountAmount') || false;
          const details = this.getData('details');
          let discountAmountTotal = this.newBig();
          R.forEach(detailModel => {
            const isSetChild = detailModel.getData('isSetChild') || false;
            if (!isSetChild) {
              const returnedQty = detailModel.getDataBig('qty');
              const originalQty = detailModel.getDataBig('originalQty');
              const allocatedInclDiscountAmount = detailModel?._data?.returnPointsData?.allocatedInclDiscountAmount || detailModel?._data?.allocatedInclDiscountAmount || 0;
              const allocatedInclDiscountAmountBig = this.newBig(allocatedInclDiscountAmount);
              let discountAmount;
              if (isReturnAllDiscountAmount) {
                discountAmount = allocatedInclDiscountAmountBig;
              }
              if (!isReturnAllDiscountAmount) {
                discountAmount = allocatedInclDiscountAmountBig.times(returnedQty).div(originalQty);
              }
              discountAmountTotal = discountAmountTotal.plus(discountAmount);
            }
          })(details);
          this.setData('discountAmount', discountAmountTotal.toFixed(roundingCurrency));
          return discountAmountTotal.toFixed(roundingCurrency);
        }
        if (discAmountBig.gt(0)) {
          return discAmountBig.toFixed(roundingCurrency);
        } else {
          return false;
        }
      }
      return false;
    },
    /**
     * calculate new tax line item after discount
     */
    _calculateDetailTaxAmt(isTaxExclusive, lineAmtBig, detail) {
      const unroundedCurrency = this.getData('unroundedCurrency', this.get('settings.unroundedCurrency'));
      if (isTaxExclusive) {
        const taxRate = detail.getData('taxRate');
        return lineAmtBig.times(taxRate).toFixed(unroundedCurrency);
      } else {
        const taxRateBig = detail.getDataBig('taxRate');
        const taxAmtBig = lineAmtBig.times(taxRateBig).div(taxRateBig.plus(1));
        return taxAmtBig.toFixed(unroundedCurrency);
      }
    },
    /**
     * allocates discount total evenly to each detail line.
     * calculates and sets new lower taxTotalRounded
     */
    _applyDocumentDiscountToDetails({
      isTaxExclusive,
      preDiscTotalRounded,
      discTotalRounded
    }) {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      const unroundedCurrency = this.getData('unroundedCurrency', this.get('settings.unroundedCurrency'));
      const details = this.getData('details');
      let newTaxTotal = this.newBig();
      let method;
      if (isTaxExclusive) {
        method = 'exclAmt';
      } else {
        method = 'inclAmt';
      }
      this.setData('details', details.map(detailModel => {
        const isSetChild = detailModel.getData('isSetChild') || false;
        if (isSetChild) {
          return detailModel;
        }

        // allocate disc to each amt
        let lineAmtBig = detailModel.getDataBig(method);
        if (!lineAmtBig.eq(0)) {
          let ratio = this.newBig();
          if (parseFloat(preDiscTotalRounded) > 0) {
            ratio = lineAmtBig.div(preDiscTotalRounded);
          }
          const discAmtBig = ratio.times(discTotalRounded);
          lineAmtBig = lineAmtBig.minus(discAmtBig);
          const unroundedTaxAmt = this._calculateDetailTaxAmt(isTaxExclusive, lineAmtBig, detailModel);
          newTaxTotal = newTaxTotal.plus(unroundedTaxAmt);

          // always use excl amt to calculate commission
          if (!isTaxExclusive) {
            // convert incl lineAmtBig to exclTax
            lineAmtBig = lineAmtBig.minus(unroundedTaxAmt);
          }
          const qty = detailModel.getData('qty', 0);

          // @TODO: should use discPrice be rounded/unrounded?
          // @TODO: should use postDiscExclAmt be rounded/unrounded?
          let discPriceRounded = 0;
          if (parseFloat(qty) > 0) {
            discPriceRounded = lineAmtBig.div(qty).toFixed(roundingCurrency);
          }
          detailModel.setData('discPrice', discPriceRounded);
          detailModel.setData('postDiscTax', unroundedTaxAmt);
          detailModel.setData('postDiscExclAmt', lineAmtBig.toFixed(unroundedCurrency));
          detailModel.setData('allocatedDiscount', discAmtBig.toFixed(unroundedCurrency));
        }
        return detailModel;
      }));
      return newTaxTotal.toFixed(roundingCurrency);
    },
    _removeDocumentDiscountFromDetails() {
      const details = this.getData('details');
      this.setData('details', details.map(detailModel => {
        detailModel.setData('discPrice', '');
        detailModel.setData('postDiscTax', '');
        detailModel.setData('postDiscExclAmt', '');
        detailModel.setData('allocatedDiscount', '');
        return detailModel;
      }));
    },
    /**
     * Apply and calculate reward points to document
     * @param {Object} args
     * @param {Object} args.rewardEventForOrder - reward event for order
     * @return {void}
     */
    _applyReward({
      rewardEventForOrder
    }) {
      if (RA.isNotNilOrEmpty(rewardEventForOrder)) {
        const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
        let rewardTotalRounded = 0;
        const ratePerPoint = rewardEventForOrder.ratePerPoint || 0;
        const pointApplied = this.getData('rewardPointApplied') || 0;
        if (ratePerPoint > 0) {
          rewardTotalRounded = this.newBig(pointApplied).times(ratePerPoint).toFixed(roundingCurrency);
        }
        const isTaxExclusive = false;
        const exclTotalRounded = this.getData('exclTotal');
        const inclTotalRounded = this.getData('inclTotal');
        if (rewardTotalRounded > 0) {
          this.setData('rewardAmountTotal', rewardTotalRounded);
          this.setData('rewardAmountTotalWithoutPromoCode', rewardTotalRounded);
          let docTotalBig;
          let preDiscTotalRounded;
          let postDiscExclTotalRounded;
          let postDiscInclTotalRounded;
          const preDiscExclTotal = exclTotalRounded;
          if (isTaxExclusive) {
            docTotalBig = this.newBig(exclTotalRounded);

            // set pre discount total
            preDiscTotalRounded = exclTotalRounded;
            // calculate post discount amount
            postDiscExclTotalRounded = docTotalBig.minus(rewardTotalRounded).toFixed(roundingCurrency);
            this.setData('preDiscExclTotal', preDiscTotalRounded);
            this.setData('exclTotal', postDiscExclTotalRounded);
          } else {
            docTotalBig = this.newBig(inclTotalRounded);
            // set pre discount total
            preDiscTotalRounded = inclTotalRounded;
            postDiscInclTotalRounded = docTotalBig.minus(rewardTotalRounded).toFixed(roundingCurrency);
            this.setData('inclTotal', postDiscInclTotalRounded);
          }
          this.setData('preDiscExclTotal', preDiscExclTotal);
          const taxTotalRounded = this._applyDocumentDiscountToDetails({
            isTaxExclusive,
            preDiscTotalRounded,
            discTotalRounded: rewardTotalRounded
          });
          this.setData('taxTotal', taxTotalRounded);
          this.setData('rewardInclTotal', rewardTotalRounded);
          if (isTaxExclusive) {
            postDiscInclTotalRounded = this.newBig(postDiscExclTotalRounded).plus(taxTotalRounded).toFixed(roundingCurrency);
            this.setData('inclTotal', postDiscInclTotalRounded);
          } else {
            postDiscExclTotalRounded = this.newBig(postDiscInclTotalRounded).minus(taxTotalRounded).toFixed(roundingCurrency);
            this.setData('exclTotal', postDiscExclTotalRounded);
          }
        } else {
          this.setData('rewardInclTotal', '');
          this._removeDocumentDiscountFromDetails();
        }
      }
    },
    _applyDiscount(exclTotalRounded, inclTotalRounded) {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      const isTaxExclusive = false;
      const discTotalRounded = this._calculateDiscountTotal(isTaxExclusive, exclTotalRounded, inclTotalRounded);
      if (discTotalRounded) {
        // has discount

        let docTotalBig;
        let preDiscTotalRounded;
        let postDiscExclTotalRounded;
        let postDiscInclTotalRounded;
        const preDiscExclTotal = exclTotalRounded;
        if (isTaxExclusive) {
          docTotalBig = this.newBig(exclTotalRounded);

          // set pre discount total
          preDiscTotalRounded = exclTotalRounded;
          // calculate post discount amount
          postDiscExclTotalRounded = docTotalBig.minus(discTotalRounded).toFixed(roundingCurrency);
          this.setData('preDiscExclTotal', preDiscTotalRounded);
          this.setData('exclTotal', postDiscExclTotalRounded);
        } else {
          docTotalBig = this.newBig(inclTotalRounded);
          // set pre discount total
          preDiscTotalRounded = inclTotalRounded;
          postDiscInclTotalRounded = docTotalBig.minus(discTotalRounded).toFixed(roundingCurrency);
          this.setData('inclTotal', postDiscInclTotalRounded);
        }
        this.setData('preDiscExclTotal', preDiscExclTotal);
        // allocate TOTAL discount to each detail item and get new tax total
        const taxTotalRounded = this._applyDocumentDiscountToDetails({
          isTaxExclusive,
          preDiscTotalRounded,
          discTotalRounded
        });
        this.setData('taxTotal', taxTotalRounded);
        this.setData('discInclTotal', discTotalRounded);
        if (isTaxExclusive) {
          postDiscInclTotalRounded = this.newBig(postDiscExclTotalRounded).plus(taxTotalRounded).toFixed(roundingCurrency);
          this.setData('inclTotal', postDiscInclTotalRounded);
        } else {
          postDiscExclTotalRounded = this.newBig(postDiscInclTotalRounded).minus(taxTotalRounded).toFixed(roundingCurrency);
          this.setData('exclTotal', postDiscExclTotalRounded);
        }
      } else {
        this.setData('discInclTotal', '');
        this.setData('preDiscExclTotal', '');
        this._removeDocumentDiscountFromDetails();
      }
    },
    _sumCommissionAmts() {
      const roundingCurrency = this.getData('roundingCurrency', this.get('settings.roundingCurrency'));
      const details = this.getData('details') || [];
      let commissionTotalBig = this.newBig(0);
      details.map(detailModel => {
        const commissionAmt = detailModel.getData('commissionAmt') || 0;
        commissionTotalBig = commissionTotalBig.plus(commissionAmt);
        return detailModel;
      });
      this.setData('commissionTotal', commissionTotalBig.toFixed(roundingCurrency));
    },
    /**
     * Get reward event for order
     * @param {Object} rewardData - reward data
     * @return {Object} - reward event
     */
    _getRewardEventForOrder({
      rewardData
    }) {
      if (RA.isNilOrEmpty(rewardData)) {
        return {};
      }
      let rewardEventForOrder = this.get('rewardEventForOrder') || {};
      const contactRewardsData = this.getData('fetchedContactRewardData') || {};
      if (RA.isNilOrEmpty(rewardEventForOrder) && RA.isNotNilOrEmpty(contactRewardsData)) {
        const memberLevelId = R.propOr('', 'memberLevelId')(contactRewardsData);
        rewardEventForOrder = R.pipe(R.propOr([], 'memberLevels'), R.find(R.propEq('memberLevelId', memberLevelId)), R.propOr([], 'rewardsEvents'), R.find(R.propEq('type', this.constants.rewardEvents.order)))(rewardData) || {};
      }
      return rewardEventForOrder;
    },
    _setContactRewardData(args) {
      const {
        rewardData
      } = args;
      const docType = this.getData('docType');
      const docName = this.getData('docName');
      const fetchedContactRewardData = this.getData('fetchedContactRewardData') || {};
      const rewardKey = R.propOr('', '_key')(rewardData);
      if (RA.isNotNilOrEmpty(rewardKey)) {
        const contactData = this.get('selectedContact') || {};
        const contactKey = R.pathOr('', ['_data', '_key'])(contactData);
        if (RA.isNotNilOrEmpty(contactData) && RA.isNotNilOrEmpty(contactKey)) {
          const contactRewardsData = R.pathOr([], ['_data', 'contactRewardsData'])(contactData);
          const contactRewardData = R.find(R.allPass([R.pathEq(['rewardData', '_key'], rewardKey), R.pathEq(['rewardData', 'status'], 'active')]))(contactRewardsData);
          if (RA.isNilOrEmpty(fetchedContactRewardData)) {
            this.setData('fetchedContactRewardData', {
              contactKey,
              signupValidPoints: 0,
              // if contact already created, signup point will calculated with validPoints
              memberLevelId: R.pathOr('', ['level', 'memberLevelId'])(contactRewardData),
              validPoints: R.pathOr(0, ['pointsData', 'validPoints'])(contactRewardData),
              memberLevelPoints: R.pathOr(0, ['level', 'points'])(contactRewardData)
            });
          }

          // only need to recalculate points on update
          if (RA.isNotNilOrEmpty(fetchedContactRewardData) && RA.isNotNilOrEmpty(contactData) && RA.isNotNilOrEmpty(contactKey) && RA.isNotNilOrEmpty(this.getData('_key'))) {
            const docDateZ = this.getData('dateZ');
            const docKey = this.getData('_key');
            let docTimestampZ = this.getData('timestampZ');
            if (docTimestampZ) {
              docTimestampZ = this.dateService.getMoment(docTimestampZ).seconds(0).milliseconds(0).toISOString();
            }
            const docContactRewards = R.propOr([], 'contactRewards')(contactRewardData);
            const contactRewards = R.filter(contactReward => {
              const master = R.propOr('', 'master')(contactReward);
              let timestampZ_original = contactReward.timestampZ_original || contactReward.timestampZ;
              if (timestampZ_original) {
                timestampZ_original = this.dateService.getMoment(timestampZ_original).seconds(0).milliseconds(0).toISOString();
              }
              if (docType === 'sales' && docName === 'returns') {
                return timestampZ_original <= docTimestampZ;
              }
              return timestampZ_original <= docTimestampZ && master !== docKey;
            })(docContactRewards);
            const contactRewardsForMemberLevelCalculation = R.filter(contactReward => {
              const master = R.propOr('', 'master')(contactReward);
              if (docType === 'sales' && docName === 'returns') {
                return true;
              }
              return master !== docKey;
            })(docContactRewards);
            const validPoints = this._calculatePoints({
              contactRewards,
              docDateZ,
              docTimestampZ
            });
            const memberLevel = this._getCurrentMemberLevel({
              contactRewards: contactRewardsForMemberLevelCalculation,
              docDateZ: this.dateService.getStartOfDayZ(),
              memberLevels: rewardData.memberLevels,
              docTimestampZ
            });
            this.setData('fetchedContactRewardData', {
              contactKey,
              signupValidPoints: 0,
              // if contact already created, signup point will calculated with validPoints
              memberLevelId: R.propOr('', 'memberLevelId')(memberLevel),
              validPoints,
              memberLevelPoints: R.propOr(0, 'points')(memberLevel)
            });
          }
          return contactRewardData;
        }
      }
    },
    _getEarnsData({
      contactRewards,
      docDateZ,
      docTimestampZ
    }) {
      const todayZ = docDateZ;
      const validEarnsData = R.pipe(R.filter(contactReward => {
        const type = R.prop('type')(contactReward);
        const method = R.prop('method')(contactReward);
        const startDateZ = R.prop('startDateZ')(contactReward);
        const expireDateZ = R.prop('expireDateZ')(contactReward);
        const timestampZ = contactReward.timestampZ_original || contactReward.timestampZ;
        if (docTimestampZ) {
          return method === 'earn' && startDateZ <= todayZ && expireDateZ >= todayZ && timestampZ <= docTimestampZ && type !== 'memberLevelAdjustment';
        }
        return method === 'earn' && startDateZ <= todayZ && expireDateZ >= todayZ && type !== 'memberLevelAdjustment';
      }), R.sortWith([R.ascend(R.prop('expireDateZ')), R.ascend(R.prop('startDateZ')), R.descend(R.prop('points'))]))(contactRewards) || [];
      const expiredEarnsData = R.pipe(R.filter(contactReward => {
        const type = R.prop('type')(contactReward);
        const method = R.prop('method')(contactReward);
        const startDateZ = R.prop('startDateZ')(contactReward);
        const expireDateZ = R.prop('expireDateZ')(contactReward);
        const timestampZ = contactReward.timestampZ_original || contactReward.timestampZ;
        if (docTimestampZ) {
          return method === 'earn' && startDateZ <= todayZ && expireDateZ < todayZ && timestampZ <= docTimestampZ && type !== 'memberLevelAdjustment';
        }
        return method === 'earn' && startDateZ <= todayZ && expireDateZ < todayZ && type !== 'memberLevelAdjustment';
      }), R.sortWith([R.ascend(R.prop('expireDateZ')), R.ascend(R.prop('startDateZ')), R.descend(R.prop('points'))]))(contactRewards) || [];
      return R.concat(expiredEarnsData, validEarnsData);
    },
    _getSpendData({
      contactRewards,
      docDateZ,
      docTimestampZ
    }) {
      const todayZ = docDateZ;
      return R.pipe(R.filter(contactReward => {
        const method = R.prop('method')(contactReward);
        const dateZ = R.prop('dateZ')(contactReward);
        const timestampZ = contactReward.timestampZ_original || contactReward.timestampZ;
        if (docTimestampZ) {
          return method === 'use' && dateZ <= todayZ && timestampZ <= docTimestampZ;
        }
        return method === 'use' && dateZ <= todayZ;
      }), R.sortWith([R.ascend(R.prop('dateZ'))]))(contactRewards);
    },
    _getCurrentPoints({
      contactReward
    }) {
      const docName = R.prop('docName')(contactReward);
      const isReturnAdjustment = R.propOr(false, 'isReturnAdjustment')(contactReward);
      const points = R.propOr(0, 'points')(contactReward);
      if (docName === 'returns' && !isReturnAdjustment) {
        return R.negate(points);
      }
      return points;
    },
    _calculatePoints({
      contactRewards,
      docDateZ,
      docTimestampZ
    }) {
      const todayZ = docDateZ;
      const earnsData = this._getEarnsData({
        contactRewards,
        docDateZ,
        docTimestampZ
      });
      let spendsData = this._getSpendData({
        contactRewards,
        docDateZ,
        docTimestampZ
      });
      let currentPoints = 0;
      let currentExpireDateZ;
      let earnRemain = {};
      let spendRemain = {};
      R.forEachObjIndexed(earn => {
        earn.spends = [];
        const earnMaster = earn.master || '';
        currentPoints = this._getCurrentPoints({
          contactReward: earn
        });
        const currentStartDateZ = R.propOr('', 'startDateZ')(earn);
        currentExpireDateZ = R.propOr('', 'expireDateZ')(earn);

        // add earn remain points to currentPoints if the points is continuable
        if (RA.isNotNilOrEmpty(earnRemain)) {
          const earnRemainExpireDateZ = R.propOr('', 'expireDateZ')(earnRemain);
          const earnRemainPoints = R.propOr(0, 'points')(earnRemain);
          if (earnRemainExpireDateZ >= currentStartDateZ) {
            currentPoints = currentPoints + earnRemainPoints;
          }
        }

        // reduce current points with spend remain (no need to check date)
        if (RA.isNotNilOrEmpty(spendRemain)) {
          const spendRemainPoints = R.propOr(0, 'points')(spendRemain);
          currentPoints = currentPoints + spendRemainPoints;
          if (RA.isNotNilOrEmpty(spendRemain.remain)) {
            spendRemain.remain.spendRemainPoints = spendRemainPoints;
          }
          earn.spends.push(spendRemain.remain);
        }
        const spendsInEarn = R.filter(spend => {
          const spendDateZ = R.propOr('', 'dateZ')(spend);
          return spendDateZ >= currentStartDateZ && spendDateZ < currentExpireDateZ;
        })(spendsData);
        R.forEach(spend => {
          const spendPoints = this._getCurrentPoints({
            contactReward: spend
          });
          const spendKey = R.propOr('', '_key')(spend);
          const spentMaster = spend.master || '';
          const spendDocType = spend.docType || '';
          const spendDocName = spend.docName || '';
          const isSpendReturn = spendDocType === 'sales' && spendDocName === 'returns';
          if (currentPoints > 0 || isSpendReturn && spentMaster === earnMaster) {
            earn.spends.push(spend);
            spendsData = R.reject(R.propEq('_key', spendKey))(spendsData);
            currentPoints = currentPoints - spendPoints;
            if (currentPoints <= 0) {
              earnRemain = {};
              spendRemain = {
                points: currentPoints,
                remain: spend
              };
            }
          }
        })(spendsInEarn);
        if (currentPoints > 0) {
          if (currentExpireDateZ < todayZ) {
            currentPoints = 0;
          }
          earnRemain = {
            points: currentPoints,
            expireDateZ: currentExpireDateZ
          };
          spendRemain = {};
        }
        if (currentPoints < 0) {
          const prevRemains = spendRemain.remain || {};
          spendRemain = {
            points: currentPoints,
            remain: prevRemains
          };
          earnRemain = {};
        }
        if (currentPoints === 0) {
          earnRemain = {};
          spendRemain = {};
        }
        earn.currentPoints = currentPoints;
      })(earnsData);
      if (currentExpireDateZ < todayZ) {
        return 0;
      }
      return currentPoints;
    },
    _getCurrentMemberLevel(args) {
      const {
        contactRewards,
        isRecalculateFromStart = true,
        memberLevelPoints = 0,
        docDateZ,
        docTimestampZ
      } = args;
      let {
        memberLevels
      } = args;
      const todayZ = docDateZ;
      memberLevels = R.sortWith([R.ascend(R.prop('requiredPoints'))])(memberLevels);
      const filteredContactRewardsData = R.pipe(R.filter(contactReward => {
        const isCalculatedForMemberLevel = R.propEq('isCalculatedForMemberLevel', true)(contactReward);
        const startDateZ = R.propOr('', 'startDateZ')(contactReward);
        const isStarted = startDateZ <= todayZ;
        const timestampZ = contactReward.timestampZ_original || contactReward.timestampZ;
        if (docTimestampZ) {
          return isCalculatedForMemberLevel && isStarted && timestampZ <= docTimestampZ;
        }
        return isCalculatedForMemberLevel && isStarted;
      }), R.sortWith([R.ascend(R.prop('dateZ'))]))(contactRewards);
      let total = 0;
      if (!isRecalculateFromStart) {
        total = memberLevelPoints;
      }
      let currentMemberLevel = R.head(memberLevels) || {};
      const time = R.propOr(0, 'expiryTime')(currentMemberLevel);
      const periods = R.propOr(0, 'expiryPeriod')(currentMemberLevel);
      let levelExpiryDateZ = this.dateService.getMoment(docDateZ).add(time, periods).toISOString();
      let prevDateZ = '';
      R.forEachObjIndexed((contactReward, index) => {
        index = Number(index);
        let points = R.propOr(0, 'points')(contactReward);
        if (contactReward.docName === 'returns' && contactReward.method === 'earn') {
          points = R.negate(points);
        }
        const pointDateZ = R.propOr(todayZ, 'dateZ')(contactReward);
        const pointDateZPlusmemberLevelExpiryDateZ = this.dateService.getMoment(pointDateZ).add(time, periods).toISOString();
        if (index === 0) {
          total = total + points;
        }
        const isLastIndex = R.equals(index, R.length(filteredContactRewardsData) - 1);
        const previousDateZPlusmemberLevelExpiryDateZ = this.dateService.getMoment(prevDateZ).add(time, periods).toISOString();
        if (index > 0 && isLastIndex && pointDateZPlusmemberLevelExpiryDateZ >= todayZ) {
          if (previousDateZPlusmemberLevelExpiryDateZ >= pointDateZ) {
            total = total + points;
          } else {
            total = points;
          }
        }
        if (index > 0 && !isLastIndex) {
          if (previousDateZPlusmemberLevelExpiryDateZ >= pointDateZ) {
            total = total + points;
          } else {
            total = 0;
          }
        }
        prevDateZ = pointDateZ;
        R.forEach(memberLevel => {
          const requiredPoints = R.propOr(0, 'requiredPoints')(memberLevel);
          if (total >= requiredPoints) {
            currentMemberLevel = memberLevel;
            const time = R.propOr(0, 'expiryTime')(currentMemberLevel);
            const periods = R.propOr(0, 'expiryPeriod')(currentMemberLevel);
            levelExpiryDateZ = this.dateService.getMoment(pointDateZ).add(time, periods).toISOString();
          }
        })(memberLevels);
      })(filteredContactRewardsData);
      if (levelExpiryDateZ <= todayZ) {
        currentMemberLevel = R.head(memberLevels) || {};
        total = 0;
      }
      currentMemberLevel.levelExpiryDateZ = levelExpiryDateZ;
      currentMemberLevel.points = total;
      return currentMemberLevel;
    }
  });
});