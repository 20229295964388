define("client/pods/components/transporters/iframe-waybills/component", ["exports", "@glimmer/component", "client/utils/nventor"], function (_exports, _component, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersIframeWaybillsComponent = _exports.default = (_dec = Ember.inject.service('dispatch'), _dec2 = Ember._action, _dec3 = Ember._action, _class = class TransportersIframeWaybillsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dispatchService", _descriptor, this);
      const iframeId = _nventor.default.random.alphaNum(8);
      Ember.set(this, 'args.waybillsOptionsByProvider.iframeId', `iframe-${iframeId}`);
    }

    /**
     *
     * @param {HTMLIFrameElement} iframe
     */
    setOnloadEvent(iframe) {
      const resetHeight = () => {
        const iframeContentHeight = iframe.contentDocument.body.scrollHeight;
        iframe.style.height = `${iframeContentHeight + 50}px`;
      };
      resetHeight();
      iframe.onload = () => {
        resetHeight();
        if (!this.args?.waybillsOptionsByProvider?.iframeLoaded && this.args?.waybillsOptionsByProvider?.onLoadShowPrintDialog) {
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          Ember.set(this, 'args.waybillsOptionsByProvider.iframeLoaded', true);
        }
      };
    }
    setupPopup() {
      const popupWindow = window.open('', '_blank', 'width=800,height=600');
      popupWindow.document.write(this.args.waybillsOptionsByProvider.srcDoc);
      popupWindow.document.close();
      popupWindow.focus();
      popupWindow.print();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setOnloadEvent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setOnloadEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupPopup", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupPopup"), _class.prototype), _class);
});