define("client/pods/components/transporters/editor-component/component", ["exports", "ember-concurrency", "ramda-adjunct", "client/mixins/date", "client/mixins/search-contacts"], function (_exports, _emberConcurrency, RA, _date, _searchContacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, _searchContacts.default, {
    crud: Ember.inject.service(),
    dispatchService: Ember.inject.service('dispatch'),
    listAdapters: null,
    receiver: null,
    // contact receiving goods
    tagName: 'div',
    isReadonly: false,
    isPickerOnly: false,
    // true: only has transport selector. false: will have additional form info
    // autoFetchData: false, //auto fetch hct data on init
    searchTransportersContactsList: null,
    // contacts search results
    // list of phone numbers for contact person (not the transporter)
    contactsPhonesList: null,
    loadHCTData: false,
    init() {
      this._super(...arguments);
      this.set('searchTransportersContactsList', []);
      if (this.allowEdit) {
        this.set('isReadonly', true);
      }
      this.setupTCat.perform();
    },
    setupTCat: (0, _emberConcurrency.task)(function* () {
      const transporterKey = this?.model?._data?.transporterKey;
      const tCatModel = yield this.dispatchService.tCatGetModel.perform();
      this.set('tCatModel', tCatModel);
    }),
    filteredTransportersList: Ember.computed('transportersList.[]', function () {
      return this.transportersList || [];
    }),
    isTCatExtension: Ember.computed('model._data.{transporterExtension,transporterKey}', 'tCatModel.{_data._key,synced}', function () {
      const model = this.model;
      if (!model?._data?.transporterKey) {
        return false;
      }
      if (model?._data?.transporterExtension === 't-cat') {
        return true;
      }
      if (model?._data?.transporterKey === this?.dispatchService?.tCatModel?._data?._key) {
        return true;
      }
      return false;
    }),
    _hideTitleContainer: Ember.computed('hideTitleContainer', function () {
      return RA.defaultWhen(RA.isNilOrEmpty, false, this.hideTitleContainer);
    }),
    /**
     * disable if component isReadonly or if has already been despatched
     * @return {[type]} [description]
     */
    isDisabled: Ember.computed('isReadonly', 'model._data.transporterIsDispatched', function () {
      const isReadonly = this.isReadonly;
      const model = this.model;
      const isDispatched = model.getData('transporterIsDispatched');
      if (isReadonly || isDispatched) {
        return true;
      }
      return false;
    }),
    hasHCT: Ember.computed('model._data.transporterExtension', 'settings._data.features.extensions.tw_hct', 'dontFetchRegionCode', function () {
      if (!this.dontFetchRegionCode && this.settings.getProp('features.extensions.tw_hct') && this.get('model._data.transporterExtension') === 'hct') {
        return true;
      }
      return false;
    }),
    transporterEditorComponent: Ember.computed('model._data.transporterExtension', function () {
      const transporterExtension = this.model?._data?.transporterExtension;
      return `transporters/${transporterExtension}-editor`;
    })
  });
});