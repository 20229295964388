define("client/pods/components/channels/shop/campaigns/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+vCx1D5A",
    "block": "{\"symbols\":[\"@onAddItem\",\"@onDash\",\"@onDisplayItem\",\"@onEditItem\",\"@onSetAsDefaultImageTask\",\"@parentTab\",\"@tab\",\"@tabContentOnly\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor\",[],[[\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@model\",\"@onAddItem\",\"@onCancel\",\"@onDash\",\"@onDisplayItem\",\"@onEditItem\",\"@onSetAsDefaultImageTask\",\"@parentTab\",\"@saveTask\",\"@tab\",\"@tabContentOnly\"],[true,false,false,[23,0,[\"dirty\"]],[23,1,[]],[28,\"fn\",[[23,0,[\"cancel\"]],[23,0,[\"dirty\"]]],null],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,0,[\"onSaveTask\"]],[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-edit/template.hbs"
    }
  });
});