define("client/pods/extensions/apps/tw/invoices/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/extensions/tw-invoices',
    modelObj: _model.default,
    idParam: '_key',
    createBatch({
      payload
    }) {
      const path = this.getPath('resourcePath');
      payload.batch = payload.batch.map(dirty => this.dataManager.getAdapter('extensions/apps/tw/invoices').serialize(dirty));
      return this.server.call('POST', path, payload);
    }
  });
});