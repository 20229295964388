define("client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/3rd-party-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5RIPLUFN",
    "block": "{\"symbols\":[\"@isEditing\",\"@isReadonly\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\"],\"statements\":[[5,\"settings/defaults/3rd-party-editor\",[],[[\"@documentationResource\",\"@hideGuideMenu\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\",\"@hideRss\"],[\"campaigns\",false,[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/3rd-party-editor/template.hbs"
    }
  });
});