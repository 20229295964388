define("client/modifiers/add-style", ["exports", "ember-modifier", "ramda"], function (_exports, _emberModifier, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)(function addStyle(element, [style]) {
    R.mapObjIndexed((value, key) => {
      element.style[key] = value;
    })(style);
  });
});