define("client/pods/components/elements/element-checkbox/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // import TranslateMixin from '../../../../mixins/translate'
  // import jQuery from 'jquery'
  let ElementsElementCheckboxComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _class = class ElementsElementCheckboxComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "_isSelected", _descriptor2, this);
    }
    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }
    get allowToggle() {
      return R.pathOr(true, ['args', 'allowToggle'])(this);
    }
    get isSelected() {
      return R.pathOr(this._isSelected, ['args', 'value'])(this);
    }

    // didInsertElement () {
    //   this._super(...arguments)
    //   const initialWidth = jQuery(this.element).outerWidth()
    //   const spans = jQuery(this.element).children('span')
    //   let totalWidth = 0
    //   spans.each((key, element) => {
    //     element = jQuery(element)
    //     totalWidth = totalWidth + element.outerWidth()
    //   })
    //   totalWidth = Math.ceil(totalWidth + 10)
    //   if (initialWidth < totalWidth) {
    //     jQuery(this.element).outerWidth(totalWidth)
    //   }
    // },
    toggle() {
      if (!this.args.disabled && this.allowToggle) {
        const customToggler = this.args.onToggle;
        const isSelected = !this.isSelected;
        this._isSelected = isSelected;
        if (this.args.onToggleProperty) {
          this.args.onToggleProperty(isSelected);
        }
        if (customToggler) {
          return customToggler(isSelected);
        }
        const onAfterToggle = this.args.onAfterToggle;
        if (onAfterToggle) {
          return onAfterToggle(isSelected);
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _class);
});