define("client/pods/components/documentation/documentation-edit-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentationDocumentationEditModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('documentation'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('dirty._data.type'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class DocumentationDocumentationEditModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "documentationService", _descriptor3, this);
      _initializerDefineProperty(this, "dirty", _descriptor4, this);
      _initializerDefineProperty(this, "helpFilters", _descriptor5, this);
      _initializerDefineProperty(this, "selectedTemplateIndex", _descriptor6, this);
      _initializerDefineProperty(this, "selectedTemplateModel", _descriptor7, this);
      _defineProperty(this, "adapterName", 'documentation');
      this.crud.addLists(this, ['documentation/lists/help', 'statuses']);
      this.setup();
    }
    setup() {
      const documentation = this.documentation;
      const documentationId = R.pathOr(null, ['args', 'documentationId'])(this);
      const documentationResource = R.pathOr(null, ['args', 'documentationResource'])(this);
      this.helpFilters = this.search.setupFilters({
        adapterName: 'documentation/filters',
        defaultFiltersData: {
          type: 'isHelp',
          documentationResource
        }
      });
      let model;
      if (RA.isNotNilOrEmpty(documentation)) {
        model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model: documentation
        });
      } else {
        let index = 0;
        let type = '';
        if (this.args.isHelp || this.args.isNewFeature) {
          index = this.args.index || 0;
          type = 'isHelp';
        }
        model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data: {
            type,
            documentationId,
            documentationResource,
            index
          }
        });
        model.addNewTemplate();
      }
      this.dirty = model;
      const templates = model.getData('templates');
      if (RA.isNilOrEmpty(templates)) {
        return;
      }
      const templateModel = templates.get('firstObject');
      templateModel.instanceId = model?.instanceId;
      this.selectTemplate(templateModel);
    }
    get documentation() {
      const documentation = this.args.documentation || {};
      return documentation;
    }
    get isNewFeature() {
      if (this.dirty?._data?.type === 'isNewFeature') {
        return true;
      }
      return false;
    }
    get documentationIdLabel() {
      if (this.args.isHelp) {
        return 'label';
      }
      if (this.args.isNewFeature) {
        return 'label';
      }
      return 'documentationId';
    }
    get documentationIdIsReadonly() {
      if (this.args.isHelp) {
        return false;
      }
      if (this.args.isNewFeature) {
        return false;
      }
      return true;
    }
    get hasDescription() {
      if (this.args.isLabel) {
        return true;
      }
      return false;
    }
    get hasIndex() {
      const documentation = this.documentation;
      if (this.args.isHelp || documentation?.isHelp) {
        return true;
      }
      return false;
    }
    get hasTemplate() {
      const documentation = this.documentation;
      if (this.args.isHelp || documentation?.isHelp) {
        return true;
      }
      return true;
      // if (this.args.isGroup || documentation?.isGroup) {
      //   return true
      // }

      // return false
    }
    *createDocumentationTask() {
      const model = this.dirty;
      yield this.crud.createRecordTask.perform({
        adapterName: this.adapterName,
        model,
        onAfter: this.args.onCloseDialogAction
      });
      this.crud.refreshList(this.crud.lists);
    }
    selectTemplate(templateModel = false) {
      const model = this.dirty;
      const templates = model?._data?.templates || [];
      let index = templates?.indexOf(templateModel) || 0;
      if (index < 0) {
        index = 0;
      }
      this.selectedTemplateIndex = index;
      this.selectedTemplateModel = templateModel;
    }
    newTemplate() {
      const model = this.dirty;
      const templates = model?._data?.templates || [];
      const templateModel = model.addNewTemplate();
      this.selectTemplate(templateModel);
    }
    loadHelp(dirty, selected) {
      dirty.setData('helpKey', selected._data._key);
    }
    unloadHelp(dirty) {
      dirty.setData('helpKey', '');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "documentationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "helpFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedTemplateIndex", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedTemplateModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isNewFeature", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isNewFeature"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createDocumentationTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "createDocumentationTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTemplate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newTemplate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "newTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadHelp", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadHelp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadHelp", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "unloadHelp"), _class.prototype), _class);
});