define("client/pods/grapesjs/plugins/text", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: grid
   * @author Nafis Rubio
   * @since 19/09/2020
   * @copyright (C) 2020 BigOrdr
   * @description: Grid component
   */

  const margin0 = 'margin-0';
  /**
   * @typedef {import("../categories").Categories}
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'text';
    const textBlock = 'text-block';
    const textBlockWithSubHeader = 'text-block-with-subtitle';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl,
      /** @type {Categories} */
      categories
    } = options;
    const blockLabel = intl.t(pluginName);
    const textBlockLabel = intl.t(textBlock);
    const blockLabelId = `${blockLabel}-id`;
    const textBlockLabelId = `${textBlockLabel}-id`;
    const textBlockWithSubHeaderLabel = intl.t(textBlockWithSubHeader);
    const textBlockWithSubHeaderId = `${textBlockWithSubHeaderLabel}-id`;
    const textSample = intl.t('gjs-Insert your text here');

    // After saving it will show this
    DomComponents.addType(pluginName, {
      extend: 'text',
      model: {
        defaults: {
          style: {
            padding: '10px'
          },
          name: blockLabel,
          content: textSample,
          draggable: '.bigordr-cell, [data-gjs-type="link"], section',
          traits: [{
            type: 'bigordr-animation-text-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }],
          classes: [margin0]
        }
      },
      view: {
        onRender({
          model /* , editor, el */
        }) {
          // add id as class name. so, style can be added to a specific text
          const className = `id-${model.getId()}`;
          model.addClass(className);
        }
      }
    });
    BlockManager.add(blockLabelId, {
      label: blockLabel,
      category: categories.basic,
      content: {
        type: pluginName
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
        <g transform='matrix(1,0,0,1,49.9262,39.6375)'>
            <g transform='matrix(1,0,0,1,-34.9989,-70.467)'>
                <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:15px;'/>
            </g>
            <g transform='matrix(1,0,0,1,-57.9517,-98.8077)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(1,0,0,1,-644.609,-98.8077)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(1,0,0,1,-644.609,-685.465)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(1,0,0,1,-57.9517,-685.465)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(0.715811,0,0,0.676173,154.154,151.358)'>
                <g transform='matrix(551.145,0,0,551.145,260.627,673.073)'>
                    <path d='M0.599,-0.72L0.599,-0.592L0.384,-0.592L0.384,0L0.232,0L0.232,-0.592L0.016,-0.592L0.016,-0.72L0.599,-0.72Z' style='fill-rule:nonzero;'/>
                </g>
            </g>
        </g>
      </svg>
      <div class="block-label">${model.get('label')}</div>
      </div>`
    });

    // This block here only shows when onCreate
    BlockManager.add(textBlockLabelId, {
      label: textBlockLabel,
      category: categories.basic,
      content: {
        draggable: '.cell, .bigordr-cell, [data-gjs-type="link"]',
        tagName: 'section',
        name: textBlockLabel,
        style: {
          padding: '10px'
        },
        components: [{
          type: 'text',
          name: intl.t('title'),
          classes: ['bigordr-title', margin0],
          traits: [{
            type: 'bigordr-animation-text-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }]
        }, {
          type: 'text',
          style: {
            padding: 0
          },
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
          traits: [{
            type: 'bigordr-animation-text-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }],
          classes: [margin0]
        }],
        traits: []
      },
      render: ({
        model
      }) => `
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
        <g transform='matrix(1,0,0,1,49.9262,39.6375)'>
            <g transform='matrix(1,0,0,1,-34.9989,-70.467)'>
                <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:15px;'/>
            </g>
            <g transform='matrix(1,0,0,1,-57.9517,-98.8077)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(1,0,0,1,-644.609,-98.8077)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(1,0,0,1,-644.609,-685.465)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
            <g transform='matrix(1,0,0,1,-57.9517,-685.465)'>
                <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
            </g>
        </g>
        <g transform='matrix(0.697024,0,0,0.646383,87.8947,149.831)'>
            <rect x='301.902' y='349.059' width='385.98' height='98.525'/>
        </g>
        <g transform='matrix(1.11832,0,0,1,-62.6481,-73.0593)'>
            <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
        </g>
        <g transform='matrix(1.11832,0,0,1,-62.6481,-21.5278)'>
            <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
        </g>
        <g transform='matrix(1.11832,0,0,1,-62.6481,24.6035)'>
            <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
        </g>
        <g transform='matrix(1.11832,0,0,1,-62.6481,71.9924)'>
            <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
        </g>
      </svg>
      <div class="block-label">${model.get('label')}</div>`
    });

    // This block here only shows when onCreate
    BlockManager.add(textBlockWithSubHeaderId, {
      label: textBlockWithSubHeaderLabel,
      category: categories.basic,
      content: {
        draggable: '.cell, .bigordr-cell, [data-gjs-type="link"]',
        tagName: 'section',
        name: textBlockWithSubHeaderLabel,
        style: {
          padding: '10px'
        },
        components: [{
          type: 'text',
          name: intl.t('title'),
          classes: ['bigordr-title', margin0],
          traits: [{
            type: 'bigordr-animation-text-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }]
        }, {
          type: 'text',
          name: intl.t('subtitle'),
          classes: ['bigordr-subtitle', margin0],
          traits: [{
            type: 'bigordr-animation-text-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }]
        }, {
          type: 'text',
          style: {
            padding: 0
          },
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
          traits: [{
            type: 'bigordr-animation-text-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }],
          classes: [margin0]
        }],
        traits: []
      },
      render: ({
        model
      }) => `
<svg viewBox="0 0 1024 1024" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;" xmlns="http://www.w3.org/2000/svg">
  <g transform="matrix(1, 0, 0, 1, 49.92620086669922, 39.637500762939446)">
    <g transform="matrix(1,0,0,1,-34.9989,-70.467)">
      <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:15px;"/>
    </g>
    <g transform="matrix(1,0,0,1,-57.9517,-98.8077)">
      <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
    </g>
    <g transform="matrix(1,0,0,1,-644.609,-98.8077)">
      <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
    </g>
    <g transform="matrix(1,0,0,1,-644.609,-685.465)">
      <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
    </g>
    <g transform="matrix(1,0,0,1,-57.9517,-685.465)">
      <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
    </g>
  </g>
  <g transform="matrix(0.6970241069793701, 0, 0, 0.6463829874992371, 87.89469909667969, 114.01539611816406)">
    <rect x="301.902" y="349.059" width="385.98" height="98.525"/>
  </g>
  <g transform="matrix(1.1183199882507324, 0, 0, 1, -62.648101806640625, -73.05930328369142)">
    <rect x="322.785" y="550.771" width="382.132" height="25.78"/>
  </g>
  <g transform="matrix(1.1183199882507324, 0, 0, 1, -62.648101806640625, -21.527799606323242)">
    <rect x="322.785" y="550.771" width="382.132" height="25.78"/>
  </g>
  <g transform="matrix(1.1183199882507324, 0, 0, 1, -62.648101806640625, 24.603500366210938)">
    <rect x="322.785" y="550.771" width="382.132" height="25.78"/>
  </g>
  <g transform="matrix(1.1183199882507324, 0, 0, 1, -62.648101806640625, 71.99240112304688)">
    <rect x="322.785" y="550.771" width="382.132" height="25.78"/>
  </g>
  <g transform="matrix(0.8832429647445679, 0, 0, 0.4125649929046631, 31.98003387451172, 275.9749755859375)" style="">
    <rect x="301.902" y="349.059" width="385.98" height="98.525"/>
  </g>
</svg>
      <div class="block-label">${model.get('label')}</div>`
    });
  };
  _exports.default = _default;
});