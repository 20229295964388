define("client/pods/specs/collection/model", ["exports", "ramda", "ramda-extension", "client/pods/base/model", "ramda-adjunct"], function (_exports, R, R_, _model, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    intl: Ember.inject.service(),
    populate(data, attrs) {
      data.shared = this._createModels('shared', data);
      data.custom = this._createModels('custom', data);
      this._super(...arguments);
    },
    _createModels(prop, data) {
      return R.pipe(R.propOr([], prop), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'specs',
          data
        });
      }))(data);
    },
    allSpecs: Ember.computed('synced', function () {
      const shared = R_.dotPath('_data.shared')(this) || [];
      const custom = R_.dotPath('_data.custom')(this) || [];
      return R.concat(shared)(custom);
    }),
    defaultSelectedExportSheets: Ember.computed('_data.shared.[]', function () {
      return R.pipe(R.pathOr([], ['_data', 'shared']), R.pluck('_data'), R.pluck('_key'))(this);
    }),
    getSerializedByProp({
      prop,
      worksheets,
      selected
    }) {
      const data = this.serialize();
      return R.pipe(R.propOr([], prop), R.filter(spec => {
        if (selected && RA.isNotNilOrEmpty(worksheets)) {
          const keys = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('value'), R.reject(RA.isNilOrEmpty))(worksheets);
          const specKey = R.propOr('', '_key')(spec);
          return R.includes(specKey, keys);
        }
        if (selected && RA.isNilOrEmpty(worksheets)) {
          return false;
        }
        if (!selected) {
          if (worksheets) {
            const specKey = R.propOr('', '_key')(spec);
            return R.includes(specKey, worksheets);
          } else {
            return true;
          }
        }
      }))(data);
    }
  });
});