define("client/pods/channels/shop/delivery-date-options/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        prepareDays: '',
        deliveryMaxDays: '',
        expectedArrivalDateSubTitle: '',
        disallowedDispatchDays: [],
        isRequired: false,
        isAllowedToday: false,
        latestShippingTime: '',
        calendarSettingType: 'settingPeriodOfTime',
        earliestDeliveryDateZ: '',
        lastestDeliveryDateZ: '',
        excludedSpecificDates: []
      };
    }
  });
});