define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/cancel-btn/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/date", "ember-concurrency", "ramda-extension"], function (_exports, R, RA, _date, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    tagName: '',
    reason: '',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['extensions/apps/tw/e-invoices/reasons']);
    },
    allInvoicesToCancel: Ember.computed('model.{successfulEInvoices,synced}', 'crud.lists.extensions-apps-tw-e-invoices-reasons', 'invoices', function () {
      const reasons = R_.dotPath('crud.lists.extensions-apps-tw-e-invoices-reasons')(this) || [];
      const reason = reasons.get('firstObject');
      if (RA.isNilOrEmpty(reason)) {
        return [];
      }
      const model = this.model;
      let allInvoicesToCancel = model?.successfulEInvoices || [];
      if (RA.isNilOrEmpty(model)) {
        allInvoicesToCancel = this.invoices || [this.invoice];
      }
      return R.map(invoice => {
        invoice.set('reason', reason._data.value);
        return invoice;
      })(allInvoicesToCancel);
    }),
    actions: {
      loadReason(invoice, reason) {
        invoice.set('reason', reason.getData('value'));
      }
    }
  });
});