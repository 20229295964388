define("client/pods/components/extensions/apps/tw/t-cat/credentials/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ExtensionsAppsTwTCatCredentialsComponent = _exports.default = (_dec = Ember.computed('args.model._data.tCatHasCustomProductName'), _class = class ExtensionsAppsTwTCatCredentialsComponent extends _component.default {
    get isCustomProductNameReadOnly() {
      const isReadonly = R.pathEq(['args', 'isReadonly'], true)(this);
      if (isReadonly) {
        return true;
      }
      const tCatHasCustomProductName = R.pathEq(['args', 'model', '_data', 'tCatHasCustomProductName'], true)(this);
      if (!tCatHasCustomProductName) {
        return true;
      }
      return false;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isCustomProductNameReadOnly", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isCustomProductNameReadOnly"), _class.prototype), _class);
});