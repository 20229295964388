define("client/pods/channels/shop/campaigns/lists/review-steps/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      data.users = this._transformUsers(data.users);
      this._super(data, attrs);
    },
    _transformUsers(users = []) {
      return users.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: 'users',
          data
        });
      });
    }
  });
});