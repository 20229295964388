define("client/pods/components/extensions/apps/tw/invoices/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VPHNNuFO",
    "block": "{\"symbols\":[\"subTab\",\"period\",\"period\",\"@tab\"],\"statements\":[[5,\"layouts/tabs/grid\",[],[[\"@tab\",\"@parentComponent\"],[[23,4,[]],[23,0,[]]]],{\"statements\":[[0,\"\\n\\n\\t\"],[5,\"layouts/sidebar\",[],[[\"@isLeft\"],[true]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"layouts/sidebar/content\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-results\"],[8],[0,\"\\n\"],[0,\"\\t\\t\\t\\t\"],[5,\"elements/date-input\",[],[[\"@target\",\"@model\",\"@date\",\"@onSelectDate\"],[\"customDateZ\",[23,0,[]],[23,0,[\"customDateZ\"]],[28,\"action\",[[23,0,[]],\"displayByCustomDate\",[23,0,[\"customDateZ\"]]],null]]]],[0,\"\\n\\n\"],[4,\"lists/list-results\",null,[[\"list\",\"onSelect\"],[[24,[\"pastDays\"]],[28,\"action\",[[23,0,[]],\"displayByDate\"],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,3,[\"mm\"]],false],[0,\"/\"],[1,[23,3,[\"dd\"]],false],[0,\" (\"],[1,[28,\"tt\",[[23,3,[\"weekday\"]]],null],false],[0,\")\\n\\n\"]],\"parameters\":[3]},null],[0,\"\\n\\t\\t\\t\\t\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[4,\"lists/list-results\",null,[[\"list\",\"onSelect\"],[[24,[\"pastMonths\"]],[28,\"action\",[[23,0,[]],\"displayByMonth\"],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"tt\",[[23,2,[\"name\"]]],null],false],[0,\" \"],[1,[23,2,[\"yyyy\"]],false],[0,\"\\n\\n\"]],\"parameters\":[2]},null],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[5,\"layouts/tabs/grid/content\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\"],[[24,[\"tab\"]],[23,1,[]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/tab-container/template.hbs"
    }
  });
});