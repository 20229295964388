define("client/pods/components/flows/flows-editor/steps-editor/reply-choices/choice-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vpR7LkhQ",
    "block": "{\"symbols\":[\"@isReadonly\",\"@tab\",\"@choice\",\"@onRemove\",\"@isNew\",\"@isEditing\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"table-form__padding\"],[8],[0,\"\\n\"],[0,\"      \\n      \"],[1,[23,3,[\"_data\",\"index\"]],false],[0,\"\\n\\n\"],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"td\",true],[10,\"class\",\"table-form__td--no-border-right\"],[8],[0,\"\\n    \"],[5,\"elements/element-input\",[],[[\"@inputPvtClassNames\",\"@readonly\",\"@value\"],[\"table-form__input\",[23,1,[]],[23,3,[\"_data\",\"label\"]]]]],[0,\"\\n    \\n    \"],[5,\"msgs/error-msg\",[],[[\"@errors\",\"@classNames\",\"@context\",\"@icon\",\"@tooltip\"],[[23,0,[\"validationError\"]],\"documents__details-error\",\"variants.options.name\",\"fas fa-exclamation-circle\",true]]],[0,\"\\n  \"],[9],[0,\" \\n\\n  \"],[7,\"td\",true],[10,\"class\",\"table-form__td--no-border-right\"],[8],[0,\"\\n    \"],[5,\"elements/element-input\",[],[[\"@inputPvtClassNames\",\"@readonly\",\"@value\"],[\"table-form__input\",[23,1,[]],[23,3,[\"_data\",\"value\"]]]]],[0,\"\\n    \\n    \"],[5,\"msgs/error-msg\",[],[[\"@errors\",\"@classNames\",\"@context\",\"@icon\",\"@tooltip\"],[[23,0,[\"validationError\"]],\"documents__details-error\",\"variants.options.code\",\"fas fa-exclamation-circle\",true]]],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,6,[]],[23,5,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[10,\"class\",\"has-text-centered table-form__padding\"],[8],[0,\"\\n      \"],[5,\"elements/element-pushbtn\",[],[[\"@disabled\",\"@classNames\",\"@tabindex\",\"@tab\",\"@onSubmit\"],[[23,1,[]],\"u-close-btn\",\"-1\",[23,2,[]],[28,\"fn\",[[23,4,[]],[23,3,[]]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/flows-editor/steps-editor/reply-choices/choice-editor/template.hbs"
    }
  });
});