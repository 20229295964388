define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/customizations-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZFUMyP5/",
    "block": "{\"symbols\":[\"customization\",\"index\",\"@tab\",\"@isReadonly\",\"@isEditing\",\"@isNew\",\"@model\"],\"statements\":[[5,\"forms/form-section\",[],[[\"@context\",\"@documentationResource\"],[\"customizations\",\"products\"]],{\"statements\":[[0,\"\\n\\t\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\",\"@model\",\"@tab\"],[\"hasCustomizations\",\"campaignsDetails\",\"enable customizations\",[23,7,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"elements/element-toggler\",[],[[\"@disabled\",\"@toggleOffValue\",\"@toggleOnValue\",\"@toggleValue\"],[[23,4,[]],false,true,[23,7,[\"_data\",\"hasCustomizations\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,7,[\"_data\",\"hasCustomizations\"]]],null,{\"statements\":[[4,\"each\",[[23,7,[\"_data\",\"customizations\"]]],null,{\"statements\":[[0,\"      \"],[7,\"table\",true],[10,\"class\",\"table-form\"],[8],[0,\"\\n        \"],[5,\"products/products-editor/customizations-editor/customization-editor\",[],[[\"@tab\",\"@errors\",\"@errorIndex\",\"@isReadonly\",\"@isEditing\",\"@isNew\",\"@model\",\"@autoAddCustomizationOption\",\"@customization\",\"@isFromCampaignDetail\"],[[23,3,[]],[22,\"errors\"],[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],false,[23,1,[]],true]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/customizations-editor/template.hbs"
    }
  });
});