// import Component from '@ember/component'

// export default Component.extend({
//   tagName: 'div',
//   translate: true,
//   attributeBindings: ['tabindex', 'zIndex:z-index'],
//   closeOnOverlayClick: true,
//   tabindex: 0,
//   zIndex: 50,
//   classNames: ['screen-overlay'],
//   focusIn () {
//     if (this.get('closeOnOverlayClick')) {
//       this.hide()
//     }
//   },

//   hide () {
//     this.set('toggle', false)
//   }
// })
define("client/pods/components/elements/screen-overlay/component", [], function () {
  "use strict";
});