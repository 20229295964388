define("client/pods/operator/change-logs/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const customData = {
        list: [{
          resource: 'api/protected/operator/change-logs/menu/actions/dash',
          component: 'operator/change-logs/panels-dash',
          icon: 'fas fa-table',
          label: 'overview',
          action: 'dash',
          tabType: 'replaceSubTab',
          className: 'hide-when-overflow'
        }, {
          resource: 'api/protected/operator/change-logs/menu/actions/new',
          component: 'operator/change-logs/panels-new',
          icon: 'fas fa-plus',
          label: 'new',
          action: 'new',
          tabType: 'replaceSubTab',
          className: 'show-when-overflow'
        }]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});