define("client/pods/operator/change-logs/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    dateService: Ember.inject.service('date'),
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        description: '',
        privateNotes: '',
        publicReleaseNotes: '',
        sort: [{
          by: 'dateZ',
          direction: 'DESC'
        }]
      };
    }
  });
});