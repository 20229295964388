define("client/pods/components/helpers/drag-and-drop/drop-container/component", ["exports", "client/mixins/droppable"], function (_exports, _droppable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this is the NEW way.
  // helpers/DragContainer and hlepers/DropContainer should be deprecated
  var _default = _exports.default = Ember.Component.extend(_droppable.default, {
    tag: 'div',
    classNames: ['drop-container'],
    classNameBindings: ['dragPosition', 'isActive:is-active']
  });
});