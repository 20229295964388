define("client/pods/extensions/apps/tw/e-invoices/to-issue/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    _onlyValid(path, showZeroValueItems, self) {
      return R.pipe(R.pathOr([], path), R.filter(detail => {
        if (showZeroValueItems) {
          return true;
        }
        return R.gt(detail.postDiscInclAmt, 0);
      }))(self);
    },
    invoiceDetails: Ember.computed('_data.details.[]', function () {
      // const showZeroValueItems = this.attrs.settings.getProp('tw_eInvoice_systemlead.showZeroValueItems')
      return this._onlyValid(['_data', 'details'], true, this);
    }),
    returnsDetails: Ember.computed('_data.returns.[]', 'synced', function () {
      return this._onlyValid(['_data', 'returns'], false, this);
    })
  });
});