define("client/pods/components/transporters/hct-page-waybill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ovNB6h6U",
    "block": "{\"symbols\":[\"row\",\"rowIndex\",\"@firstRowBottomPadding\",\"@lastRowTopPadding\",\"@hctWaybillsPerPage\",\"@page\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"hct-waybills__multiple-waybills-per-page u-page-break-not-last-child\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"hct-waybills__table\"],[8],[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[5,\"transporters/hct-row-waybill\",[],[[\"@firstRowBottomPadding\",\"@lastRowTopPadding\",\"@hctWaybillsPerPage\",\"@rowIndex\",\"@row\"],[[23,3,[]],[23,4,[]],[23,5,[]],[23,2,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/hct-page-waybill/template.hbs"
    }
  });
});