define("client/pods/components/flows/flows-editor/steps-editor/actions-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "La9rFAVu",
    "block": "{\"symbols\":[\"@onCloseDialogAction\",\"@step\",\"@isDisplay\",\"@isEditing\",\"@isNew\",\"@tab\",\"@flow\",\"@isReadonly\"],\"statements\":[[1,[28,\"component\",[[23,2,[\"actionsComponent\"]]],[[\"isReadonly\",\"flow\",\"step\",\"tab\",\"isNew\",\"isEditing\",\"isDisplay\",\"model\",\"onCloseDialogAction\"],[[23,8,[]],[23,7,[]],[23,2,[]],[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[\"_data\",\"actionData\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/flows-editor/steps-editor/actions-editor/template.hbs"
    }
  });
});