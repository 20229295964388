define("client/pods/channels/shop/promo-codes/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        status: this.constants.status.active,
        type: this.constants.discountTypes.rate,
        discountAmount: '',
        discountRate: '',
        discountRateOnSale: '',
        emails: '',
        telephones: '',
        productTags: [],
        denyProductTags: [],
        usageLimit: 1,
        hasUsageLimitPerPerson: false,
        usageLimitPerPerson: '',
        expiryDateStartUnit: 'days',
        expiryDateEndUnit: 'days',
        useExpiryDate: false
      };
    },
    populate(data = {}) {
      data.emails = this.applicationService.joinAsString(data.emails || []);
      data.telephones = this.applicationService.joinAsString(data.telephones || []);
      this._super(...arguments);
    }
  });
});