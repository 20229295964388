define("client/pods/components/news/panels-display/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    adapterName: 'news',
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    init() {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      const model = this.get('tab.model');
      this.set('model', model);
    }
  });
});