define("client/pods/components/extensions/apps/tw/invoices/invoices-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bL18tK8P",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[7,\"p\",true],[8],[0,\"\\n\\t\"],[1,[28,\"elements/element-input\",null,[[\"placeholder\",\"inputClassNames\",\"value\"],[\"auto\",\"form__input-large\",[24,[\"dirty\",\"_data\",\"number\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"\\n\\t\"],[1,[28,\"date\",[[24,[\"dirty\",\"_data\",\"dateZ\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"cancel\",[28,\"action\",[[23,0,[]],\"cancelEdit\"],null]]]],false],[0,\"\\n\\n\"],[4,\"elements/element-btn-with-modal-dialog\",null,[[\"btnclassNames\",\"hasSectionWrapper\",\"label\"],[\"is-primary\",true,\"save\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"updateFollowingInvoices\"]]]]],false],[0,\"\\n\\t\\t\"],[7,\"span\",false],[3,\"action\",[[23,0,[]],\"toggleUpdateFollowingInvoices\"]],[8],[0,\"automatically update all following invoices\"],[9],[0,\"\\n\\n\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"cancel\",[28,\"action\",[[23,0,[]],\"cancelEdit\"],null]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"elements/btn-save\",null,[[\"label\",\"onSubmit\"],[\"save and update next invoice number\",[28,\"action\",[[23,0,[]],\"updateInvoiceAndNumbers\",[23,1,[]]],null]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"elements/btn-save\",null,[[\"label\",\"onSubmit\"],[\"save\",[28,\"action\",[[23,0,[]],\"updateInvoiceNumber\",[23,1,[]]],null]]]],false],[0,\"\\n\\n\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/invoices-editor/template.hbs"
    }
  });
});