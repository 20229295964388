define("client/pods/components/menus/tab-menu/component", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    // classNames: ['tabs', 'u-tab-menu'],
    translate: true,
    menu: null,
    selected: null,
    // itemClassNames: null,
    menuLabelKey: '_data.label',
    init() {
      this._super(...arguments);
      const classNames = this.classNames;
      if (RA.isNilOrEmpty(classNames)) {
        this.set('classNames', ['tabs', 'u-tab-menu']);
      }
    },
    menuProxy: Ember.computed('menu.{[],synced}', 'menu.@each.synced', function () {
      return this.menu || [];
    }),
    actions: {
      select: function (item) {
        // must always use action set on item model

        this.set('selected', item);
        if (this.onSelect) {
          return this.onSelect(item);
        }
      }
    }
  });
});