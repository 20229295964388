define("client/pods/components/users/tab-container/component", ["exports", "client/mixins/crud", "client/mixins/users-list-by", "ember-concurrency"], function (_exports, _crud, _usersListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _usersListBy.default, {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    adapterName: 'users',
    filtersAdapterName: 'users/filters',
    init() {
      this.crud.addLists(this, ['users/lists/menu', 'users/lists/types', 'users/lists/roles/internal', 'statuses']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.set('filters.showResults', true);
      this._super(...arguments);
    },
    afterLoadLists() {
      this._super(...arguments);
      this.loadTabTask.perform();
    },
    loadTabTask: (0, _emberConcurrency.task)(function* () {
      // const tab = this.get('tab')
      // const loadInstructions = this.get('tabsManager').getLoadInstructions(tab)
      // if (loadInstructions) {
      //   const model = loadInstructions.getData('model')
      //   if (model) {
      //     this.display(loadInstructions.getData('model'))
      //   } else {
      //     const id = loadInstructions.getData('find.id')
      //     const found = yield this.get('findTask').perform({
      //       appendPath: id
      //     })
      //     this.display(found)
      //   }
      // } else {
      const adapterName = this.adapterName;
      const filters = this.filters;
      filters.setData('query', '');
      const results = yield this.fetchDefaultDataTask.perform({
        adapterName,
        filters
      });
      this.set('results', results);
      this.dash(results);
      // }
    }),
    dash(results) {
      // create dashboard

      if (!results) {
        this.loadTabTask.perform();
      }
      const tab = this.tab;
      const subTabOptions = {
        component: 'users/panels-dash'
        // model: model
      };
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    },
    /**
     * display the user
     * @param  {object} user model
     */
    display(model) {
      const subTabOptions = {
        component: 'users/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    new(subTabOptions) {
      const tab = this.tab;
      this.set('filters.showResults', false);
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    // @TODO: NEED TO USE NGUARD TO RESTRICT CERTAIN ACTIONS

    actions: {
      dash() {
        this.set('filters.showResults', true);
        this.dash();
      },
      // search () {
      //   const self = this

      //   // get search term
      //   const data = {
      //     query: self.get('query')
      //   }

      //   self.get('dataStore').search('users', data).then(function (results) {
      //     if (!self.get('isDestroyed')) {
      //       self.set('resultsToggle', true)
      //       self.set('results', results)
      //     }
      //   })
      // },
      display(model) {
        this.set('filters.showResults', false);
        this.display(model);
      },
      new(subTabOptions) {
        this.new(...arguments);
      },
      edit(model) {
        const tab = this.tab;
        this.set('filters.showResults', false);
        const subTabOptions = {
          component: 'users/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }
    }
  });
});