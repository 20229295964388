define("client/pods/components/documents/btn-refund/workflow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "o3Q5Bh84",
    "block": "{\"symbols\":[\"@model\",\"@isInclTotalReadonly\",\"@inclTotal\",\"@isReadOnly\"],\"statements\":[[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-input-row\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-checkbox\",[],[[\"@disabled\",\"@value\",\"@onToggleProperty\"],[[23,4,[]],[23,1,[\"_data\",\"createRefundWorkflow\"]],[28,\"fn\",[[28,\"mut\",[[23,1,[\"_data\",\"createRefundWorkflow\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"tt\",[\"create workflow\"],null],false],[0,\": \"],[1,[28,\"tt\",[\"refund\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n  \\n\"],[4,\"if\",[[23,1,[\"_data\",\"createRefundWorkflow\"]]],null,{\"statements\":[[0,\"    \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@model\",\"@documentationResource\",\"@isRequired\"],[\"refund reminder amount\",[23,0,[\"errors\"]],\"inclTotal\",[23,1,[]],\"workflowsItems\",true]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@autofocus\",\"@translatedPlaceholder\",\"@value\"],[[23,2,[]],false,[23,3,[]],[23,3,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/btn-refund/workflow/template.hbs"
    }
  });
});