define("client/pods/components/operator/change-logs/search-results-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'div',
    init() {
      this.crud.addLists(this, ['products/lists/types', 'products/lists/tracking', 'statuses']);
      this._super(...arguments);
    },
    trackingTagLabel: Ember.computed(function () {
      return this.intl.t('tracking') + ': ';
    }),
    statusTagClassName: Ember.computed('result._data.status', function () {
      const status = this.get('result._data.status');
      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    })
  });
});