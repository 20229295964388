define("client/pods/components/documents/pos/view/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wMEmHkfZ",
    "block": "{\"symbols\":[\"campaginDetail\",\"@tab\",\"@onAddItemToCart\",\"@onRemoveItemFromCart\",\"@isPosFullScreenMode\",\"@isRunning\",\"@filters\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"pos-products\"],[8],[0,\"\\n  \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,6,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,7,[\"results\"]]],null,{\"statements\":[[0,\"      \"],[5,\"documents/pos/product/card\",[],[[\"@tab\",\"@product\",\"@onAddItemToCart\",\"@onRemoveItemFromCart\",\"@isPosFullScreenMode\"],[[23,2,[]],[23,1,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"pos-products-pagination-footer\"],[8],[0,\"\\n\"],[0,\"  \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@showPageNumbers\"],[[23,7,[]],true]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/pos/view/details/template.hbs"
    }
  });
});