define("client/mixins/validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({

    // validator: null,
    //
    // validate(model) {
    //
    //   this.get('validator')
    //   this.set('originalValue', value);
    // }
  });
});