define("client/pods/storage/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * stores to localstorage using the store.js library.
   * will use other methods in localstorage is not available
   */
  // @TODO: test
  var _default = _exports.default = Ember.Service.extend({
    userId: '',
    companyName: '',
    // init () {
    //   this._super(...arguments)
    // },

    setup(companyName, userId) {
      this.companyName = companyName;
      this.userId = userId;
      const keys = this.get('keys') || [];
      this._set('keys', keys);
    },
    wrapWithPrefix(key) {
      return `${this.companyName}-${this.userId}__${key}`;
    },
    setWithPrefix(prefix, key, val, exp) {
      key = `${prefix}__${key}`;
      store.set(key, {
        val: val,
        exp: exp,
        time: new Date().getTime()
      });
    },
    getWithPrefix(prefix, key) {
      key = `${prefix}__${key}`;
      var info = store.get(key);
      if (!info) {
        return null;
      }
      if (new Date().getTime() - info.time > info.exp) {
        // localstorage item has expired, remove and return null
        store.remove(key);
        return null;
      }
      return info.val;
    },
    /**
     * stores to localstorage
     * @param {string} keys
     * @param {mixed} val
     * @param {integer} exp - expiry in ms
     */
    setWithoutPrefix: function (key, val, exp) {
      this._setWithoutPrefix(key, val, exp);

      // this.updateKeys(key, exp)
    },
    /**
     * stores to localstorage
     * @param {string} keys
     * @param {mixed} val
     * @param {integer} exp - expiry in ms
     */
    set(key, val, exp) {
      this._set(key, val, exp);
    },
    _set(key, val, exp) {
      key = this.wrapWithPrefix(key);
      store.set(key, {
        val: val,
        exp: exp,
        time: new Date().getTime()
      });
    },
    _setWithoutPrefix: function (key, val, exp) {
      store.set(key, {
        val: val,
        exp: exp,
        time: new Date().getTime()
      });
    },
    updateKeys(key, exp) {
      key = this.wrapWithPrefix(key);
      const keys = this.get('keys') || [];
      keys.push(key);
      this._set('keys', keys);
    },
    getKeys() {
      return this.get('keys') || [];
    },
    get(key) {
      key = this.wrapWithPrefix(key);
      var info = store.get(key);
      if (!info) {
        return null;
      }
      if (new Date().getTime() - info.time > info.exp) {
        // localstorage item has expired, remove and return null
        store.remove(key);
        return null;
      }
      return info.val;
    },
    getWithoutPrefix: function (key) {
      var info = store.get(key);
      if (!info) {
        return null;
      }
      if (new Date().getTime() - info.time > info.exp) {
        // localstorage item has expired, remove and return null
        store.remove(key);
        return null;
      }
      return info.val;
    },
    remove(key) {
      key = this.wrapWithPrefix(key);
      store.remove(key);
    },
    clear() {
      window.localStorage.clear();
    }

    // session: {
    //   set: function (key, value) {
    //     if(typeof Storage !== "undefined") {
    //       sessionStorage.setItem(key, value);
    //     }
    //   },
    //   get: function (key, defaultValue) {
    //     if(typeof Storage !== "undefined") {
    //       return sessionStorage.getItem(key) || defaultValue;
    //     }
    //   },
    //   del: function () {
    //     //@TODO: need to remove key
    //   }
    // }
  });
});