define("client/pods/components/documents/documents-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y+lJjurP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"translate\",\"readonly\",\"disabled\",\"list\",\"value\"],[true,true,true,[24,[\"isReadonly\"]],[23,0,[\"crud\",\"lists\",\"toggles-allCustom\"]],[24,[\"selectedType\"]]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[28,\"eq\",[[24,[\"selectedType\"]],\"all\"],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"lists/list-selector\",null,[[\"hasSearch\",\"translate\",\"list\",\"classNames\",\"model\",\"valueKey\",\"optionValueKey\",\"optionLabelKey\",\"itemClassNames\",\"allowEmpty\",\"multiSelect\"],[true,true,[24,[\"availableDocs\"]],\"list-selector list-selector--horizontal\",[24,[\"model\"]],[24,[\"valueKey\"]],\"_data.value\",\"_data.label\",\"list-selector__item list-selector__item--horizontal\",false,true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/documents-selector/template.hbs"
    }
  });
});