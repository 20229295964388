define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/numbers/row/component", ["exports", "@glimmer/component", "ramda", "ramda-extension", "ember-concurrency-decorators", "client/utils/nventor", "ramda-adjunct"], function (_exports, _component, R, R_, _emberConcurrencyDecorators, _nventor, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwEInvoicesTracksManagerNumbersRowComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('date'), _dec5 = Ember.inject.service('helpers'), _dec6 = Ember.inject.service('terminal'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('modelProxy._data.isAllowed', 'args.{isNew,model._data.isAllowed}', 'isReadonly'), _dec12 = Ember.computed('modelProxy._data.isAllowed', 'isReadonly', 'hasAnyEInvoicesPrinterTerminals', 'isLocked'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _class = class ExtensionsAppsTwEInvoicesTracksManagerNumbersRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "constants", _descriptor3, this);
      _initializerDefineProperty(this, "dateService", _descriptor4, this);
      _initializerDefineProperty(this, "helpersService", _descriptor5, this);
      _initializerDefineProperty(this, "terminalService", _descriptor6, this);
      _initializerDefineProperty(this, "isReadonly", _descriptor7, this);
      _initializerDefineProperty(this, "dirty", _descriptor8, this);
      _initializerDefineProperty(this, "einvoicesPrintersTerminalsFilters", _descriptor9, this);
      _initializerDefineProperty(this, "hasAnyEInvoicesPrinterTerminals", _descriptor10, this);
      const isNew = R.pathOr(false, ['args', 'isNew'])(this);
      if (isNew) {
        this.setDefaultsForNew();
      }
      this.setupDefaultDataTask.perform();
    }
    *setupDefaultDataTask() {
      const einvoicesPrintersTerminalsFilters = this.search.setupFilters({
        adapterName: 'users/einvoices-printers-terminals/filters',
        defaultFiltersData: {
          twEInvoiceAllowPrintingFrom: this.constants.twEInvoicePrinter.allowPrintingFrom.printerAllocatedNumbers
        }
      });
      this.einvoicesPrintersTerminalsFilters = einvoicesPrintersTerminalsFilters;
      const results = yield this.terminalService.fetchEInvoicesPrinterTerminalsTask.perform({
        filters: einvoicesPrintersTerminalsFilters
      });
      if (results?.length > 0) {
        this.hasAnyEInvoicesPrinterTerminals = true;
      }
    }
    get isLocked() {
      if (this.isReadonly) {
        return true;
      }
      const period = this.dateService.getPeriod();
      const model = this.args.model;
      const mm = model.getData('mm');
      const yyyy = model.getData('yyyy');
      if (mm > period.mm && yyyy > period.yyyy) {
        return true;
      }
      if (model?._data?.index < model?._data?.totalAllocatedIndexes) {
        return true;
      }
      if (model?._data?.status === this.constants.eInvoicesAllocationsStatus.started) {
        return true;
      }
      if (model?._data?.status === this.constants.eInvoicesAllocationsStatus.finished) {
        return true;
      }
      if (_ramdaAdjunct.default.isNotNilOrEmpty(model?._data?.blanks)) {
        return true;
      }
      return false;
    }
    get showNotAllowedWarning() {
      const modelProxy = this.modelProxy;
      const isCurrentlyOn = modelProxy?._data?.isAllowed;
      if (this.args.isNew && !isCurrentlyOn) {
        return true;
      }
      const original = this.args.model;
      if (!this.isReadonly && !isCurrentlyOn && original?._data?.isAllowed) {
        return true;
      }
      return false;
    }
    get isToggleLocked() {
      if (this.isReadonly) {
        return true;
      }
      if (R.pathEq(['args', 'isNew'], true)(this)) {
        return false;
      }
      const period = this.dateService.getPeriod();
      const model = this.args.model;
      const mm = model.getData('mm');
      const yyyy = model.getData('yyyy');
      if (mm > period.mm && yyyy > period.yyyy) {
        return true;
      }
      if (model?._data?.isAllowed !== true) {
        return true;
      }

      // allow toggling OFF if it was ON and numbers are not yet used
      return false;
    }
    get isDisabledSelectEInvoicesTerminal() {
      if (this.isReadonly) {
        return true;
      }
      if (this.isLocked) {
        return true;
      }
      if (!this.modelProxy._data.isAllowed) {
        return true;
      }
      if (!this.hasAnyEInvoicesPrinterTerminals) {
        return true;
      }
      return false;
    }

    /**
     * Returns the end range of book numbers based on certain conditions.
     *
     * @returns {Array} The end range of book numbers.
     */
    get endBookRange() {
      const allAllocatedNumbers = this.args?.trackNumbers?.allocatedNumbers || [];
      const currentIndex = R.findIndex(R.pathEq(['_data', '_key'], this.args?.model?._data?._key))(allAllocatedNumbers) + 1;
      const nextStartNumber = currentIndex ? R.path([currentIndex, 'startNumber'])(allAllocatedNumbers) : 0;
      const bookRange = R.pathOr([], ['args', 'trackNumbers', 'bookRange'])(this);
      const endRange = R.filter(({
        endNumber
      }) => {
        const isCurrentNumberGtThanEndNumber = endNumber <= this.args?.model?._data?.lastNumber;
        if (isCurrentNumberGtThanEndNumber) {
          return false;
        }
        const isEndNumberGtThanStartNumber = endNumber >= this.args?.model?.startNumber;
        if (!nextStartNumber) {
          return isEndNumberGtThanStartNumber;
        }
        const isEndNumberLsThanNextStartNumber = endNumber < nextStartNumber;
        return isEndNumberGtThanStartNumber && isEndNumberLsThanNextStartNumber;
      })(bookRange);
      return endRange;
    }
    get modelProxy() {
      return this.dirty || this.args.model;
    }
    toggleReadonly() {
      this.isReadonly = !this.isReadonly;
    }
    setDefaultsForNew() {
      const allocatedNumbers = R.pathOr([], ['args', 'trackNumbers', '_data', 'allocatedNumbers'])(this);
      const index = R.length(allocatedNumbers);
      this.args.model.set('_data.index', index);
      const bookRange = R.pathOr([], ['args', 'trackNumbers', 'bookRange'])(this);
      let nextBookRange;
      if (index === 0) {
        nextBookRange = _nventor.default.safeHead(bookRange);
        this.args.model.set('_data.startNumber', nextBookRange.startNumber);
        this.args.model.set('_data.endNumber', nextBookRange.endNumber);
      } else {
        const lastAllocatedNumber = _nventor.default.safeLast(allocatedNumbers);
        const currentBookIndex = R.findIndex(R.propEq('endNumber', lastAllocatedNumber.endNumber))(bookRange);
        nextBookRange = R.view(R.lensIndex(R.inc(currentBookIndex)))(bookRange);
        this.args.model.set('_data.startNumber', nextBookRange.startNumber);
        this.args.model.set('_data.endNumber', nextBookRange.endNumber);
      }
      this.args.model.set('_data.isAllowed', false);
    }
    onEdit() {
      const onEdit = this.args.onEdit;
      this.toggleReadonly();
      this.args.toggleIsEditing();
      if (onEdit) {
        onEdit(...arguments);
      }
      this.dirty = this.crud.setupDirty({
        adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
        model: this.args.model
      });
    }
    onEditBlank() {
      this.dirty = this.crud.setupDirty({
        adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
        model: this.args.model
      });
    }
    onCancel() {
      const onCancel = this.args.onCancel;
      this.toggleReadonly();
      this.args.toggleIsEditing();
      this.dirty = null;
      if (onCancel) {
        onCancel(...arguments);
      }
    }
    onCancelBlanks(onCloseDialogAction) {
      this.dirty = null;
      onCloseDialogAction();
    }
    *saveTask(modelProxy) {
      yield this.args.saveTask.perform(modelProxy);
      this.toggleReadonly();
    }
    *removeTask(modelProxy) {
      yield this.args.removeTask.perform(modelProxy);
      this.toggleReadonly();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "helpersService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "terminalService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isReadonly", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'isReadonly'])(this);
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "einvoicesPrintersTerminalsFilters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasAnyEInvoicesPrinterTerminals", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupDefaultDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showNotAllowedWarning", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showNotAllowedWarning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDisabledSelectEInvoicesTerminal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isDisabledSelectEInvoicesTerminal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaultsForNew", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultsForNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEdit", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditBlank", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onEditBlank"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancelBlanks", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelBlanks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _class);
});