define("client/pods/components/channels/shop/campaigns/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BdWVuZWt",
    "block": "{\"symbols\":[\"@showMinimalResult\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"u-results__text u-desc u-desc--smallest\"],[8],[0,\"\\n\\t\\t\\t(/\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"url\"]],[24,[\"query\"]]],null],false],[0,\")\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[4,\"unless\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[0,\"/\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"url\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"result\",\"_data\",\"salesPersons\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"channels/shop/shop-sales-persons\",[],[[\"@model\"],[[22,\"result\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[5,\"channels/shop/shop-commissions-status\",[],[[\"@model\"],[[22,\"result\"]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\"],[1,[28,\"channels/shop/shop-status\",null,[[\"model\"],[[24,[\"result\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\"],[1,[28,\"dateRange\",[[24,[\"result\",\"_data\",\"dateStartZ\"]],[24,[\"result\",\"_data\",\"dateEndZ\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/search-results/template.hbs"
    }
  });
});