define("client/pods/users/lists/ip-addresses/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ember-concurrency-decorators"], function (_exports, _adapter, _adaptersNew, _model, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, (_obj = {
    server: Ember.inject.service(),
    modelObj: _model.default,
    resourcePath: 'api/protected/users/ip-addresses',
    idParam: '_id',
    *getIpUsersTable(ipKey) {
      const path = `${this.resourcePath}/${ipKey}/users`;
      const response = yield this.server.call('GET', path);
      return response?.data || [];
    }
  }, _applyDecoratedDescriptor(_obj, "getIpUsersTable", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "getIpUsersTable"), _obj), _obj));
});