define("client/pods/components/documentation/documentation-desc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NffwOoaX",
    "block": "{\"symbols\":[\"&attrs\",\"@documentation\",\"@documentationWrapper\",\"@isLabel\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,4,[]],[23,3,[\"showDocumentation\"]],[23,2,[\"_data\",\"description\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"documentation-description documentation-description__description\"],[13,1],[8],[0,\"\\n    \"],[1,[28,\"helpers/pre-line\",null,[[\"value\"],[[23,2,[\"_data\",\"description\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[23,3,[\"showDocumentation\"]],[23,2,[\"_data\",\"template\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"documentation-description documentation-description__template\"],[13,1],[8],[0,\"\\n      \"],[1,[28,\"html-safe\",[[23,2,[\"_data\",\"template\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentation/documentation-desc/template.hbs"
    }
  });
});