define("client/helpers/count", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    /**
     * get length of values.
     * can pass in multiple arrays as params
     */
    compute(params /* , hash */) {
      const values = R.values(params) || [];
      return R.reduce((acc, value) => {
        if (value != null) {
          const length = value.length || 0;
          return acc + length;
        }
        return acc;
      }, 0, values);
    }
  });
});