define("client/pods/components/tabs/tab-headers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l+Klue/k",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"\\n  \"],[14,2],[0,\"\\n  \\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[28,\"tabs/tab-header\",null,[[\"translate\",\"tab\",\"tabs\",\"classNames\"],[true,[23,1,[]],[24,[\"tabs\"]],[24,[\"itemClassNames\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/tabs/tab-headers/template.hbs"
    }
  });
});