define("client/pods/components/transporters/waybill-display/list/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TransportersWaybillDisplayListItemComponent extends _component.default {
    get isActive() {
      const isCanceled = this.args?.shippingWaybill?.isCancel === true;
      const isDeleted = this.args?.shippingWaybill?.isDeleted === true;
      if (isCanceled || isDeleted) {
        return false;
      }
      return true;
    }
  }
  _exports.default = TransportersWaybillDisplayListItemComponent;
});