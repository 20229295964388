define("client/pods/components/documents/mixins/details", ["exports", "ramda-extension"], function (_exports, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    crud: Ember.inject.service(),
    addNewDetail(model) {
      model.newBlankDetail({
        taxesList: R_.dotPath('crud.lists.taxes')(this)
      });
      return model;
    },
    actions: {
      addNewDetail() {
        this.addNewDetail(...arguments);
      }
    }
  });
});