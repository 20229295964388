define("client/pods/components/helpers/pagination-controls/component", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'div',
    showPageNumbers: false,
    classNames: ['pagination', 'is-centered', 'is-small'],
    isNextDisabled: Ember.computed('list.[]', 'filters.{results,_data.count}', 'hasFullCount', function () {
      const count = this.get('filters._data.count') || 0;
      let results = this.get('filters.results') || false;
      if (R.isNil(results) || !results) {
        results = this.get('list') || [];
      }
      const resultsCount = this?.list?.length || results.length || 0;
      if (resultsCount < 0) {
        return true;
      }
      if (resultsCount < count) {
        return true;
      }
      if (this.hasFullCount) {
        const currentPage = this?.filters?._data?.page || 1;
        const allPagesArray = this.allPagesArray || [];
        const lastPageNumber = R.pipe(_nventor.default.safeLastOr({}), R.propOr('', 'value'))(allPagesArray);
        if (currentPage === lastPageNumber) {
          return true;
        }
      }
      return false;
    }),
    hasFullCount: Ember.computed('filters.{fullCount,_data.count,_data.page}', function () {
      const filters = this.filters;
      if (filters) {
        const hasFullCount = R.hasPath(['fullCount'])(filters);
        if (hasFullCount) {
          const fullCount = R.path(['fullCount'])(filters) || 0;
          return fullCount;
        }
      }
      return false;
    }),
    allPagesArray: Ember.computed('hasFullCount', 'filters.{fullCount,_data.count,_data.page}', function () {
      const filters = this.filters;
      if (filters) {
        const fullCount = this.hasFullCount;
        const count = R.path(['_data', 'count'])(filters);
        if (fullCount > 0 && count > 0) {
          const totalPages = Math.ceil(fullCount / count);
          return R.pipe(R.range(1), R.map(pageNumber => {
            return Ember.Object.create({
              label: pageNumber,
              value: pageNumber
            });
          }))(totalPages + 1);
        }
      }
      return [];
    }),
    _goToPage(page) {
      if (page) {
        const filters = this.filters;
        const component = this.component;
        page = parseInt(page) || 1;
        filters.setData('page', page);
        this.set('showGoToPage', false);
        const context = this.context;
        const paginationData = {
          filters,
          context,
          component
        };
        // Use default pagination task if custom isn't pass in
        if (!this.onPaginationTask && !this.onPaginationTask?.perform) {
          return this.crud.paginateSearchRecordsTask.perform(paginationData);
        }
        if (this?.onPaginationTask?.perform) {
          return this.onPaginationTask.perform(paginationData);
        } else {
          return this.onPaginationTask(paginationData);
        }
      }
    },
    actions: {
      previousPage() {
        const filters = this.filters;
        const currentPage = parseInt(filters.getData('page')) || 1;
        const page = currentPage - 1;
        filters.setData('page', page);
        this.set('showGoToPage', false);
        const context = this.context;
        const paginationData = {
          filters,
          context,
          component: this
        };
        // Use default pagination task if custom isn't pass in
        if (!this.onPaginationTask && !this.onPaginationTask?.perform) {
          return this.crud.paginateSearchRecordsTask.perform(paginationData);
        }
        if (this.onPaginationTask.perform) {
          return this.onPaginationTask.perform(paginationData);
        }
        return this.onPaginationTask(paginationData);
      },
      nextPage() {
        // @TODO: redo this. onPaginationTask should be a curried function
        const filters = this.filters;
        if (!filters) {
          return;
        }
        const currentPage = parseInt(filters.getData('page')) || 1;
        const page = currentPage + 1;
        filters.setData('page', page);
        this.set('showGoToPage', false);
        const context = this.context;
        const paginationData = {
          filters,
          context,
          component: this
        };
        // Use default pagination task if custom isn't pass in
        if (!this.onPaginationTask && !this.onPaginationTask?.perform) {
          return this.crud.paginateSearchRecordsTask.perform(paginationData);
        }
        if (!this?.onPaginationTask) {
          // handle the case where onPaginationTask is not passed down
          return;
        }
        if (this.onPaginationTask?.perform) {
          return this.onPaginationTask.perform?.(paginationData);
        }
        return this.onPaginationTask?.(paginationData);
      },
      selectPage(pageData) {
        const page = pageData?.value || 1;
        this._goToPage(page);
      },
      goToPage(page) {
        this._goToPage(page);
      },
      showGoToPage() {
        if (!this.page) {
          this.set('page', 1);
        }
        this.set('showGoToPage', true);
      }
    }
  });
});