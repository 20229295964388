define("client/pods/channels/shop/campaigns/lists/settings-editor/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';

  const filterTabs = (list, filterByLabel) => R.map(R.pipe(R.unless(R.propSatisfies(R.includes(R.__, filterByLabel), 'label'), R.set(R.lensProp('component'), null))))(list);
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const customData = {
        list: [{
          value: 'basic',
          label: 'basic',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/basic-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/basic-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'style',
          label: 'settings_style',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/style-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/style-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'payments',
          label: 'payments',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/payments-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/payments-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'shipping',
          label: 'shipping',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/shipping-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/shipping-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'checkout',
          label: 'checkout',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/checkout-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/checkout-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'emails',
          label: 'emails',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/emails-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/emails-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: '3rd-party',
          label: 'settings_social',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/3rd-party-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/3rd-party-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'advanced',
          label: 'advanced',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor/advanced-editor',
          editingComponent: 'channels/shop/campaigns/campaigns-editor/settings-editor/advanced-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab'
        }]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(res => res?.data || []);
    }
  });
});