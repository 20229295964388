define("client/pods/components/products/products-editor/sets-with-choices-editor/component", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init() {
      this._super(...arguments);
      const model = this?.model || {};
      const choices = model?._data?.choices;
      if (this?.isNew && RA.isNilOrEmpty(choices)) {
        model.addChoice();
      }
    },
    isReadonlyAndAvailable: Ember.computed('model._data.isSetWithChoices', 'isReadonly', function () {
      if (!this.get('model._data.isSetWithChoices')) {
        return true;
      }
      return this.isReadonly;
    }),
    actions: {
      addChoice(model) {
        model.addChoice();
      },
      removeChoice(model, choice) {
        const msg = this.intl.t('are you sure you want to remove');
        if (window.confirm(msg)) {
          model.removeChoice(choice);
        }
      }
    }
  });
});