define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwEInvoicesTracksManagerComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec5 = Ember.inject.service('terminal'), _dec6 = Ember.inject.service('settings'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _class = class ExtensionsAppsTwEInvoicesTracksManagerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "dateService", _descriptor3, this);
      _initializerDefineProperty(this, "eInvoicesService", _descriptor4, this);
      _initializerDefineProperty(this, "terminalService", _descriptor5, this);
      _initializerDefineProperty(this, "settingsService", _descriptor6, this);
      _initializerDefineProperty(this, "track", _descriptor7, this);
      _initializerDefineProperty(this, "years", _descriptor8, this);
      _initializerDefineProperty(this, "months", _descriptor9, this);
      _initializerDefineProperty(this, "yyyy", _descriptor10, this);
      _initializerDefineProperty(this, "yyyyLabel", _descriptor11, this);
      _initializerDefineProperty(this, "mm", _descriptor12, this);
      _initializerDefineProperty(this, "selectedPeriod", _descriptor13, this);
      _initializerDefineProperty(this, "uploadUnusedBlanks", _descriptor14, this);
      _initializerDefineProperty(this, "uploadedResults", _descriptor15, this);
      const currentPeriod = this.dateService.getPeriod();
      const currentYear = currentPeriod.yyyy;
      this.yyyy = currentYear;
      this.yyyyLabel = currentYear - 1911;
      this.mm = this.dateService.getEvenInvoiceMonth(currentPeriod.mm);
      const period = this.args.period;
      if (RA.isNotNilOrEmpty(period)) {
        this.yyyy = period.yyyy;
        this.mm = period.mm;
      }
      let years = this.dateService.getPastYears({
        number: 2,
        futureYearsNumber: 1
      });
      years = R.map(year => {
        year.set('yyyyLabel', year.yyyy - 1911);
        return year;
      })(years);
      this.years = years;
      const months = R.pipe(R.map(month => {
        const previousMonth = month - 1;
        return Ember.Object.create({
          mm: month,
          label: this.intl.t(`mm${previousMonth}`) + ' / ' + this.intl.t(`month${month}`)
        });
      }))([2, 4, 6, 8, 10, 12]);
      this.months = months;
      const selectedPeriod = R.find(R.propEq('mm', this.mm))(months);
      this.fetchTrackNumbers.perform(selectedPeriod);
    }
    get hasHeader() {
      return R_.dotPathOr(false, 'args.header')(this);
    }
    get translate() {
      return R_.dotPathOr(true, 'args.translate')(this);
    }

    // @deprecated
    get currentNumber() {
      // @TODO: refactor to use currentNumbers, pick from currentNumbers for each available
      return R.pipe(R.pathOr([], ['track', '_data', 'current']), R.pathOr(0, ['currentNumber']))(this);
    }
    get allowUploadBlanks() {
      const selectedPeriod = this.selectedPeriod || {};
      if (selectedPeriod.mm) {
        const currentInvoiceMonth = this.dateService.getEvenInvoiceMonth();
        let previousInvoiceMonth;
        const period = this.dateService.getPeriod();
        let isCorrectYear = true;
        if (currentInvoiceMonth === 2) {
          previousInvoiceMonth = 12;
          isCorrectYear = this.yyyy + 1 === period.yyyy;
        } else {
          previousInvoiceMonth = currentInvoiceMonth - 2;
        }
        if (previousInvoiceMonth === selectedPeriod.mm && isCorrectYear) {
          if (period.dd < 5) {
            return true;
          }
        }
      }
      return false;
    }
    *fetchTrackNumbers(selectedPeriod) {
      this.selectedPeriod = selectedPeriod;
      const mm = selectedPeriod.mm;
      const yyyy = this.yyyy;
      const track = yield this.eInvoicesService.fetchTrackNumbers.perform({
        mm,
        yyyy
      });
      this.track = track || {};
    }
    updateTaxYear() {
      this.mm = '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "terminalService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "track", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "years", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "months", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "yyyyLabel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedPeriod", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "uploadUnusedBlanks", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "uploadedResults", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTrackNumbers", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTrackNumbers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTaxYear", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updateTaxYear"), _class.prototype), _class);
});