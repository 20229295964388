define("client/pods/components/comments/comments-manager/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct", "client/utils/nventor", "ember-concurrency"], function (_exports, R, R_, RA, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _obj = {
    commentsService: Ember.inject.service('comments'),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    settingsService: Ember.inject.service('settings'),
    tagName: 'span',
    classNames: ['comments__display-container'],
    tooltipLabel: 'internal comments',
    label: '',
    adapterName: 'comments',
    showEditor: false,
    isEditing: false,
    // icon: 'far fa-comment',

    _btnTooltipLabel: Ember.computed('btnTooltipLabel', 'tooltipLabel', function () {
      return this.btnTooltipLabel || this.tooltipLabel || '';
    }),
    isAllowed: Ember.computed('isPublic', function () {
      if (!this.isPublic) {
        return true;
      }
      if (!R.equals(this.subType, 'sales-invoices')) {
        return false;
      }
      return this.settingsService?.settingsModel?._data?.allowOrderMessages;
    }),
    init() {
      this._super(...arguments);
      this.set('isEditing', false);
      const firstCommentFilters = this.search.setupFilters({
        adapterName: 'comments/contacts/filters',
        defaultFiltersData: {
          fetchWithActiveCampaigns: true
        }
      });
      this.set('firstCommentFilters', firstCommentFilters);
    },
    didInsertElement() {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      if (!this.isAllowed) {
        return false;
      }
      this.setupNewCommentRecord();
      const master = this.master;
      if (master) {
        this.fetchCommentsTask.perform(master);
        const filters = this.firstCommentFilters;
        this.fetchFirstCommentDataTask.perform({
          adapterName: 'comments',
          filters
        });
      }
    },
    setupNewCommentRecord() {
      if (this?.isDestroyed === false) {
        const master = this.master;
        const tokenData = this.storage.get('tokenData');
        const data = {
          username: tokenData.username,
          master: master
        };
        const model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data
        });
        this.set('newModel', model);
      }
    },
    comments: Ember.computed('commentsCollection._data.comments.[]', 'commentsCollection.synced', function () {
      return this.get('commentsCollection._data.comments') || [];
    }),
    commentsCount: Ember.computed('comments.[]', 'commentsCollection.synced', function () {
      const comments = this.comments || [];
      return comments.length;
    }),
    fetchFirstCommentDataTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      filters
    }) {
      const master = this.master;
      filters.setData('master', master);
      const results = yield this.crud.searchRecordsConcurrentlyTask.perform({
        adapterName: `${adapterName}/contacts`,
        filters
      });
      const firstCommentModel = _nventor.default.safeHeadOr({}, results);
      if (RA.isNotNilOrEmpty(firstCommentModel)) {
        this.set('firstCommentModel', firstCommentModel);
      }
      if (this.isPublic) {
        this.metaData.name = firstCommentModel?._data?.docNo || '';
      }
    }),
    fetchCommentsTask: (0, _emberConcurrency.task)(function* (master) {
      if (!this.isAllowed) {
        return false;
      }
      const data = {};
      const options = {
        data,
        excludeFromFilters: this.excludeFromFilters || [],
        master,
        metaData: this.metaData,
        related: this.related,
        type: this.type
      };
      yield this.commentsService.qFetch(options, (batchCommentsCollection = []) => {
        if (this?.isDestroyed === false) {
          if (!R.is(Array, batchCommentsCollection)) {
            return false;
          }
          const type = this.type;
          let criteria = R_.dotPathEq('_data.master', master);
          if (type) {
            criteria = R.allPass([R_.dotPathEq('_data.master', master), R_.dotPathEq('_data.type', type)]);
          }
          const commentsCollection = R.find(criteria)(batchCommentsCollection) || [];
          this.set('commentsCollection', commentsCollection);
        }
      });
    }),
    createCommentTask: (0, _emberConcurrency.task)(function* (onUpdateContent) {
      try {
        const nowZ = this.dateService.getNowZ();
        const type = this.type;
        const subType = this.subType;
        const model = this.newModel;
        // const message = model.getData('message.string')
        // model.setData('message', message)

        if (!model.getData('message')) {
          return false;
        }
        let confirmed = true;
        if (this.isPublic) {
          const msg = this.intl.t('are you sure you want to comment and notify customer');
          confirmed = window.confirm(msg);
        }
        if (confirmed) {
          model.setData('dateZ', nowZ);
          model.setData('type', type);
          if (subType) {
            model.setData('subType', subType);
          }
          const metaData = this.metaData;
          if (metaData) {
            Object.keys(metaData).map(key => {
              const value = metaData[key];
              model.setData(key, value);
            });
          }
          if (this.onBeforeCreateComment) {
            this.onBeforeCreateComment(model);
          }
          let firstCommentModel = this.firstCommentModel;
          if (RA.isNilOrEmpty(firstCommentModel)) {
            // check if there's first comment model one more time
            // first comment will not be there if somehow APP user is already inside comment modal, and the broadcast updated customer message from SHOP
            yield this.fetchFirstCommentDataTask.perform({
              adapterName: this.adapterName,
              filters: this.firstCommentFilters
            });
            firstCommentModel = this.firstCommentModel;
          }

          // set as isFirst if there's still no firstCommentModel
          if (RA.isNilOrEmpty(firstCommentModel)) {
            model.setData('isFirst', true);
          }
          const msg = yield this.crud.createRecordTask.perform({
            adapterName: this.adapterName,
            model
          });
          if (RA.isNilOrEmpty(firstCommentModel)) {
            yield this.fetchFirstCommentDataTask.perform({
              adapterName: this.adapterName,
              filters: this.firstCommentFilters
            });
          }
          this.setupNewCommentRecord();
          if (onUpdateContent) {
            onUpdateContent(msg);
          }
        }
      } catch (err) {
        this.set('errors', err.data);
        _nventor.default.throw('createCommentTask failed', err);
      }
    }).drop(),
    afterRemoveRecordTask(model) {
      const dirty = this.dirty;
      const comments = this.comments;
      comments.removeObject(model);
      if (dirty && model) {
        if (dirty.getData('_id') === model.getData('_id')) {
          this.cancelEdit();
        }
      }
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this.cancelEdit();
      }
    },
    cancelEdit() {
      this.set('dirty', '');
      this.set('isEditing', false);
    },
    edit(model) {
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model
      });
      this.set('dirty', dirty);
      this.set('isEditing', true);
    }
  }, _applyDecoratedDescriptor(_obj, "cancelEdit", [_dec], Object.getOwnPropertyDescriptor(_obj, "cancelEdit"), _obj), _applyDecoratedDescriptor(_obj, "edit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _obj));
});