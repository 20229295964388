define("client/pods/grapesjs/plugins/slider-native/components/NavDots", ["exports", "client/pods/grapesjs/plugins/slider-native/utils", "client/pods/grapesjs/plugins/slider-native/constants"], function (_exports, _utils, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (dc, config = {}) => {
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const {
      dotsName
    } = _constants.default;
    const classId = dotsName;
    const type = dotsName;
    const {
      intl
    } = config;
    dc.addType(type, {
      model: defaultModel.extend({
        defaults: {
          ...defaultModel.prototype.defaults,
          name: intl.t('nav dots'),
          copyable: 0,
          draggable: false,
          selectable: false,
          layerable: false,
          droppable: false,
          hoverable: false,
          classes: ['lory-dots']
        },
        init() {
          this.get('classes').pluck('name').indexOf(classId) < 0 && this.addClass(classId);
        }
      }, {
        isComponent(el) {
          if ((0, _utils.elHasClass)(el, classId)) return {
            type
          };
        }
      }),
      view: {
        ...defaultType.view
      }
    });
  };
  _exports.default = _default;
});