define("client/initializers/socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'socket', 'service:socket');
    application.inject('adapter', 'socket', 'service:socket');
    application.inject('component', 'socket', 'service:socket');
  }
  var _default = _exports.default = {
    name: 'socket',
    after: 'dataManager',
    initialize: initialize
  };
});