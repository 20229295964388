define("client/mixins/tabbable", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    getTabbable() {
      return (0, _jquery.default)(this.element).find('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]');
    },
    tabCycleForward(e) {
      const tabbable = this.getTabbable();
      if (document.activeElement === tabbable[tabbable.length - 1]) {
        e.preventDefault();
        this.$(tabbable[0]).focus();
      }
    },
    tabCycleBackward(e) {
      const tabbable = this.getTabbable();
      if (document.activeElement === tabbable[0]) {
        e.preventDefault();
        this.$(tabbable[tabbable.length - 1]).focus();
      }
    }
  });
});