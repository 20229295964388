define("client/pods/components/barcode/print-modal/component", ["exports", "@glimmer/component", "jsbarcode", "ramda", "ramda-adjunct"], function (_exports, _component, _jsbarcode, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BarcodePrintModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class BarcodePrintModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dataManager", _descriptor2, this);
      _initializerDefineProperty(this, "showModal", _descriptor3, this);
      _initializerDefineProperty(this, "selectedBarcodeType", _descriptor4, this);
      _initializerDefineProperty(this, "iframeElement", _descriptor5, this);
      _defineProperty(this, "iframeId", `iframe-${Ember.guidFor(this)}`);
      this.selectedBarcodeType = this.args.selectedBarcodeType || 'EAN13';
      this.crud.addLists(this, ['barcodes']);
    }
    selectBarcodeType(onCloseDialogAction, selected) {
      this.selectedBarcodeType = selected?._data?.value;
      this.showModal = true;
    }
    get iframeElement() {
      return document.querySelector(`#${this.iframeId}`);
    }
    get allBarcodeModels() {
      const models = RA.ensureArray(this.args.model) || [];
      const synced = R.map(R.prop('synced'))(models);
      const modelsWithBarcode = R.pipe(R.map(model => {
        const barcodes = model?._data?.barcodes || [];
        return R.map(barcodeModel => {
          if (barcodeModel?._data?.barcode && barcodeModel?._data?.barcodeType) {
            barcodeModel._data._key = model?._data?._key;
            barcodeModel._data.name = model?._data?.name;
            barcodeModel._data.code = model?._data?.code;
            return barcodeModel;
          }
        })(barcodes);
      }), R.flatten, R.reject(RA.isNilOrEmpty))(models);
      return modelsWithBarcode || '';
    }
    get allBarcodeModelsCount() {
      const synced = this.synced;
      const models = this.allBarcodeModels || [];
      return models?.length || 0;
    }
    get barcodeModelsForType() {
      const models = this.allBarcodeModels || [];
      const selectedBarcodeType = this.selectedBarcodeType;
      const modelsWithBarcode = R.filter(barcodeModel => {
        if (barcodeModel?._data?.barcodeType !== selectedBarcodeType) {
          return false;
        }
        return true;
      })(models);
      return modelsWithBarcode || '';
    }
    get barcodesWithCount() {
      const selectedBarcodeModels = this.allBarcodeModels || [];
      const groupedByTypes = R.groupBy(R.path(['_data', 'barcodeType']))(selectedBarcodeModels);
      const barcodesList = this.crud.lists.barcodes || [];
      if (RA.isNilOrEmpty(barcodesList)) {
        return [];
      }

      // recreate the list so that count can be added
      const barcodesListCopy = R.pipe(R.map(barcodeModel => {
        const barcodeModelCopy = this.dataManager.copyRecord('barcodes', barcodeModel);
        const barcodeType = barcodeModelCopy?._data?.value;
        const barcodeModelsForType = groupedByTypes[barcodeType];
        const barcodeModelsForTypeCount = barcodeModelsForType?.length || 0;
        if (!barcodeModelsForTypeCount) {
          return;
        }
        barcodeModelCopy.setData('count', barcodeModelsForTypeCount);
        return barcodeModelCopy;
      }), R.reject(RA.isNilOrEmpty))(barcodesList);
      return barcodesListCopy;
    }
    setupIframe(iframeElement) {
      const barcodeHtml = this.generateBarcodeHtml();
      this.iframeElement = iframeElement;
      const html = this._getHtmlDefaults(barcodeHtml);
      iframeElement.src = this._parseHtmlAsSrc(html);
      iframeElement.srcdoc = html;
    }
    resetIframe() {
      this.setupIframe(this.iframeElement);
    }
    _getHtmlDefaults(content) {
      return `<html>
      <head>
        <style>
          html, body {
            margin: 0;
            page-break-after: avoid;
            page-break-before: avoid;
          }

          .p-label {
            margin: 0;
            padding-top: 1pt;
            font-size: 8pt;
            text-align: center;
          }

          .svg-barcode {
            padding: 0;
            margin: 0;
            width: 90vw;
            height: auto;
          }

          .barcode-wrapper {
            page-break-after: always;
          }

          /*@media print {*/
          /*  .p-label {*/
          /*    margin: 0;*/
          /*    padding: 0.5pt;*/
          /*    font-size: 8pt;*/
          /*    text-align: center;*/
          /*  }*/

          /*  .svg-barcode {*/
          /*    padding: 0;*/
          /*    margin: 0;*/
          /*    width: 90vw;*/
          /*    height: auto;*/
          /*  }*/

          /*  .barcode-wrapper {*/
          /*    page-break-after: always;*/
          /*  }*/
          /*}*/
        </style>
      </head>
      <body>${content}</body>
    </html>`;
    }
    _parseHtmlAsSrc(html) {
      return `data:text/html;charset=utf-8,${encodeURI(html)}`;
    }
    generateBarcodeHtml() {
      const models = RA.ensureArray(this.barcodeModelsForType) || [];
      const html = models.map(model => {
        const label = R.pipe(R.pathOr('', ['_data', 'name']), R.take(30))(model);
        const value = model._data.barcode;
        const format = model._data.barcodeType;
        if (!value || !format) {
          return '';
        }
        const options = {
          format,
          // margin: 0,
          height: 50
          // width: 2,
          // fontSize: 18,
          // textMargin: 0,
          // flat: true
        };
        const div = document.createElement('div');
        div.classList.add('barcode-wrapper');
        const p = document.createElement('p');
        p.classList.add('p-label');
        p.textContent = label;
        const svg = document.createElement('svg');
        svg.classList.add('svg-barcode');
        (0, _jsbarcode.default)(svg, value, options);
        div.appendChild(p);
        div.appendChild(svg);
        return div.outerHTML;
      });
      return html.join('\n');
    }
    printIFrameContent() {
      const iframeElement = this.iframeElement;
      iframeElement.contentWindow.focus();
      iframeElement.contentWindow.print();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedBarcodeType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'EAN13';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "iframeElement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectBarcodeType", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectBarcodeType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupIframe", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupIframe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetIframe", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "resetIframe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getHtmlDefaults", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "_getHtmlDefaults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_parseHtmlAsSrc", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "_parseHtmlAsSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateBarcodeHtml", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "generateBarcodeHtml"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printIFrameContent", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "printIFrameContent"), _class.prototype), _class);
});