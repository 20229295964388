define("client/pods/components/channels/shop/promo-codes/table-view/detail-row/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    allowedProductTagLists: Ember.computed('crud.lists.products-lists-tags', 'model._data.denyProductTags', function () {
      const allProductTags = this.crud.lists['products-lists-tags'] || [];
      const deniedProductTags = this?._data?.denyProductTags || [];
      return R.reject(productTag => {
        const productTagKey = productTag?._data?._key || '';
        return R.includes(productTagKey, deniedProductTags);
      })(allProductTags);
    }),
    deniedProductTagLists: Ember.computed('crud.lists.products-lists-tags', 'model._data.productTags', function () {
      const allProductTags = this.crud.lists['products-lists-tags'] || [];
      const allowedProductTags = this?._data?.productTags || [];
      return R.reject(productTag => {
        const productTagKey = productTag.getData('_key') || '';
        return R.includes(productTagKey, allowedProductTags);
      })(allProductTags);
    })
  });
});