define("client/pods/components/extensions/apps/tw/e-invoices/panels-display/panels-statement/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    bigService: Ember.inject.service('big'),
    dispatchedInclTotal: Ember.computed('statement.statusTotals.{inclTotal,notYetInvoiced.inclTotal}', function () {
      const inclTotalBig = this.bigService.newBig(this?.statement?.statusTotals?.inclTotal);
      const notYetInvoicedInclTotalBig = this.bigService.newBig(this?.statement?.statusTotals?.notYetInvoiced?.inclTotal);
      return this.bigService.newBig(inclTotalBig).plus(notYetInvoicedInclTotalBig).toFixed();
    }),
    dispatchedCount: Ember.computed('statement.statusTotals.{count,notYetInvoiced.count}', function () {
      const countBig = this.bigService.newBig(this?.statement?.statusTotals?.count);
      const notYetInvoicedCountBig = this.bigService.newBig(this?.statement?.statusTotals?.notYetInvoiced?.count);
      return this.bigService.newBig(countBig).plus(notYetInvoicedCountBig).toFixed();
    })
  });
});