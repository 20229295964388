define("client/pods/websites/lists/layout/menu-spacing/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      let data = [];
      for (let i = 0; i <= 10; i++) {
        data.push({
          value: `${i}px`,
          label: `${i}px`
        });
      }
      return _nventor.default.resolve(data);
    }
  });
});