define("client/pods/components/simple-editor/page-block-add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mGJ3l+Ld",
    "block": "{\"symbols\":[\"@pageBlock\",\"@onAddPageBlock\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"simple-editor__block-container simple-editor__block-container-content simple-editor__block-container--is-add-block\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[]],[23,1,[]]],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"simple-editor__block-label\"],[8],[0,\"\\n    \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"label\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"simple-editor__block-add-icon\"],[8],[0,\"\\n    \"],[5,\"elements/element-icon\",[],[[\"@icon\"],[\"fas fa-plus\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/simple-editor/page-block-add/template.hbs"
    }
  });
});