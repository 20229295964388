define("client/pods/components/products/price-selectors/price-selector-campaigns/history-prices/component", ["exports", "client/pods/components/products/price-selectors/mixins/products"], function (_exports, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_products.default, {
    tagName: '',
    selectedKey: Ember.computed('preferredPriceData', function () {
      const preferredPriceData = this?.preferredPriceData || {};
      return preferredPriceData?._key;
    }),
    isSelected: Ember.computed('history.{type,_key}', 'preferredPriceData.{type,_key}', function () {
      if (this?.preferredPriceData?.type === 'none' && this?.history?.type === 'none') {
        return true;
      }
      const preferredPriceKey = this?.preferredPriceData?._key;
      const historyKey = this?.history?._key;
      if (historyKey === preferredPriceKey) {
        return true;
      }
      return false;
    })
  });
});