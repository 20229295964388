define("client/pods/components/documentation/documentation/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentationDocumentationComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _class = class DocumentationDocumentationComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "users", _descriptor3, this);
      _initializerDefineProperty(this, "_showDocumentation", _descriptor4, this);
      _defineProperty(this, "adapterName", 'documentation');
      const dynamicList = {
        name: 'documentation/lists/resource',
        appendPath: this.documentationResource
      };
      this.crud.addLists(this, ['documentation/lists/tags', 'statuses', dynamicList]);
    }
    get documentationResource() {
      const documentationResource = this?.args?.documentationResource;
      return documentationResource || this?.args?.options?.documentationResource;
    }
    get documentationId() {
      if (this.args.options) {
        const {
          documentationId,
          documentationResource,
          context
        } = this.args.options;
        if (documentationId) {
          return documentationId;
        }
        if (documentationResource && context) {
          return `${documentationResource}-${context}`;
        }
        return null;
      }
      return null;
    }
    get documentation() {
      const documentationResource = `documentation-lists-resource--${this.documentationResource}`;
      const documentation = R.pipe(R.pathOr([], ['crud', 'lists', documentationResource]), R.find(R.pathEq(['_data', 'documentationId'], this.documentationId)))(this);
      if (!this.users.isDocumentationsEditor) {
        if (documentation?._data?.status !== 'active') {
          return {};
        }
      }
      return documentation;
    }
    toggleDocumentation() {
      this._showDocumentation = !this._showDocumentation;
    }
    get showDocumentation() {
      const isHelp = this.args.isHelp;
      if (isHelp) {
        return true;
      }
      if (RA.isNilOrEmpty(this._showDocumentation)) {
        // if _showDocumentation is NULL it means that its on init

        if (this.applicationService.documentationIsMinimized && this.args.isGroup) {
          return false;
        }
        if (RA.isNotNilOrEmpty(this.args.documentationIsMinimizedOverride)) {
          return !this.args.documentationIsMinimizedOverride;
        }
        if (RA.isNilOrEmpty(this.args.documentationIsMinimized)) {
          return true;
        }
        return !this.args.documentationIsMinimized;
      }
      return this._showDocumentation;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_showDocumentation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDocumentation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDocumentation"), _class.prototype), _class);
});