define("client/pods/status-schedules/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/status-schedules/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @TODO: this is reused by product status schedule and onSaleSchedule and gjs schedule
  // this should not be reused as the data is different for both
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false
  });
});