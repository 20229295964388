define("client/pods/components/workflows/search-filters/component", ["exports", "client/mixins/crud", "client/pods/components/workflows/mixins/emails-list-by"], function (_exports, _crud, _emailsListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _emailsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    init() {
      this.crud.addLists(this, ['emails/lists/filters/search',
      // 'emails/lists/filters/status',
      'emails/lists/filters/sort', 'filters/count', 'filters/sortDirection']);
      this._super(...arguments);
    }

    // actions: {
    //   listByDate (onHideDropdown, dateZ, date) {
    //     return this.get('listByTask').perform({ date, dateZ, resultsToggle: 'resultsToggle' }, onHideDropdown)
    //   }
    // }
  });
});