define("client/pods/messages/messages/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        query: '',
        match: ['all'],
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    }
  });
});