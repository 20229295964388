define("client/pods/components/reports/dashboard/card-revenue-by-group/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/date"], function (_exports, R, RA, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_date.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    tagName: '',
    chartLegend: 'revenue',
    metric: 'net',
    chartType: null,
    // chartHeight: null,
    chartWidth: null,
    translate: false,
    isTaxExclusive: true,
    averageByItemQty: false,
    isTableView: true,
    isReloadingChart: false,
    defaultLimitResults: 10,
    limitResults: 10,
    maxChartResults: 30,
    showAverages: true,
    toggleResult: '',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['reports/lists/show-item-by']);
      this.set('metricOptions', ['invoices', 'returns', 'net']);
      this.set('chartOptions', {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      });
      if (this.isItem || this.isItemSets) {
        this.set('toggleResult', 'showItem');
      }
    },
    didInsertElement() {
      this._super(...arguments);
      let autoChartHeight = 30;
      const rows = parseInt(this.chartDatasets.length) || 1;
      const height = Math.ceil(rows * 30);
      if (height < 200) {
        autoChartHeight = 200;
      } else {
        autoChartHeight = height;
      }
      this.set('autoChartHeight', autoChartHeight);
    },
    // autoChartHeight: computed('chartDatasets.[]', function () {
    //   const rows = parseInt(this.chartDatasets.length) || 1
    //   const height = Math.ceil(rows * 30)

    //   if (height < 200) {
    //     return 200
    //   }

    //   return height
    // }),

    chartData: Ember.computed('chartLegend', 'chartLabels.[]', 'chartDatasets.[]', function () {
      return {
        labels: this.chartLabels,
        datasets: [{
          label: this.intl.t(this.chartLegend),
          data: this.chartDatasets
        }]
      };
    }),
    sorted: Ember.computed('data.[]', 'metric', 'limitResults', 'toggleResult', function () {
      const metric = this.metric;
      let data = this.get(`data.${metric}`);
      if (this.isItem) {
        const toggleResult = this?.toggleResult || 'showItem';
        let paramName = 'item';
        if (R.equals(toggleResult, 'showItemWithSetChildOnly')) {
          paramName = 'itemWithSetChildOnly';
        }
        data = this.get(`data.${paramName}.${metric}`);
      }
      if (this.isItemSets) {
        const toggleResult = this?.toggleResult || 'showItem';
        let paramName = 'itemWithGroupedSetWithChoices';
        if (R.equals(toggleResult, 'showItemWithSetChildOnly')) {
          paramName = 'itemWithGroupedSetWithChoicesWithSetChildOnly';
        }
        data = this.get(`data.${paramName}.${metric}`);
      }
      if (Ember.isEmpty(data)) {
        return [];
      }
      const valueParam = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';
      const sort = R.pipe(R.sortWith([R.descend(R.pipe(R.prop(valueParam), parseFloat))]));
      return sort(data);
    }),
    limited: Ember.computed('sorted.[]', 'limitResults', function () {
      const sorted = this.sorted;
      const limitResults = this.limitResults;
      if (limitResults) {
        return R.take(limitResults, sorted);
      }
      return sorted;
    }),
    chartLabels: Ember.computed('limited.[]', 'translate', 'limitResults', function () {
      const limited = this.limited;
      const translate = this.translate;
      if (Ember.isEmpty(limited)) {
        return [];
      }
      // const maxChartResults = this.get('maxChartResults')
      return R.pipe(R.pluck('segmentValue'), R.map(label => translate || label === 'none' ? this.intl.t(label) : label)
      // R.take(maxChartResults)
      )(limited);
    }),
    chartDatasets: Ember.computed('limited.[]', 'limitResults', function () {
      const limited = this.limited;
      if (Ember.isEmpty(limited)) {
        return [];
      }
      const valueParam = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';

      // const maxChartResults = this.get('maxChartResults')
      return R.pipe(R.pluck(valueParam)
      // R.take(maxChartResults)
      )(limited);
    }),
    hasMoreResults: Ember.computed('sorted.[]', 'limited.[]', 'limitResults', function () {
      if (this.sorted.length > this.limited.length) {
        return true;
      }
      return false;
    }),
    hasLessResults: Ember.computed('sorted.[]', 'limited.[]', 'limitResults', function () {
      if (this.sorted.length === this.limited.length) {
        return false;
      }
      return true;
    }),
    reloadChart() {
      const self = this;
      this.set('isReloadingChart', true);
      setTimeout(() => {
        self.set('isReloadingChart', false);
      }, 100);
    },
    actions: {
      toggleView() {
        this.toggleProperty('isTableView');
      },
      viewMore() {
        this.set('limitResults', false);
        if (this.reloadChart) {
          this.reloadChart();
        }
      },
      viewLess() {
        this.set('limitResults', this.defaultLimitResults);
        if (this.reloadChart) {
          this.reloadChart();
        }
      }
    },
    toggleDataView(toggleValue) {
      if (RA.isNotNilOrEmpty(toggleValue)) {
        this.set('toggleResult', toggleValue.getData('value'));
      }
    }
  }, _applyDecoratedDescriptor(_obj, "toggleDataView", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleDataView"), _obj), _obj));
});