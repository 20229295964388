define("client/pods/components/documents/pos/view/cart-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lIk2/HEf",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"detail\",\"_data\",\"qty\"]],false],[0,\" \"],[1,[24,[\"detail\",\"_data\",\"item\"]],false],[0,\" $\"],[1,[24,[\"detail\",\"_data\",\"price\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/pos/view/cart-detail/template.hbs"
    }
  });
});