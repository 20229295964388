define("client/pods/components/blogs/files/delete-files-gallery-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gr+9zRO5",
    "block": "{\"symbols\":[\"response\"],\"statements\":[[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"forms/form-row\",null,[[\"classNames\"],[\"has-text-centered\"]],{\"statements\":[[0,\"\\n\"],[4,\"files/files-fetcher\",null,[[\"resource\",\"resourceKey\",\"adapterName\",\"fetchUploadsOnInit\",\"fetchUploadsCollectionOnInit\"],[[24,[\"resource\"]],[24,[\"resourceKey\"]],\"uploads/collection\",false,true]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"files/files-gallery\",null,[[\"adapterName\",\"showDefaultImagePreview\",\"uploads\",\"defaultImage\",\"onSetAsDefaultImageTask\",\"label\",\"translate\",\"allowSetDefault\",\"resource\",\"resourceKey\"],[\"uploads/collection\",[24,[\"showDefaultImagePreview\"]],[23,1,[\"uploadsCollection\",\"_data\",\"uploads\"]],[24,[\"model\",\"_data\",\"defaultImage\"]],[28,\"perform\",[[24,[\"setAsDefaultImageTask\"]]],null],[24,[\"label\"]],[24,[\"translate\"]],[24,[\"allowSetDefault\"]],[24,[\"resource\"]],[24,[\"resourceKey\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \\n\"]],\"parameters\":[]},null],[0,\"  \\n\"],[4,\"if\",[[24,[\"allowUploads\"]]],null,{\"statements\":[[4,\"forms/form-row\",null,[[\"classNames\"],[\"has-text-centered\"]],{\"statements\":[[0,\"      \"],[1,[28,\"files/files-uploader\",null,[[\"resource\",\"resourceKey\",\"label\",\"tagName\"],[[24,[\"resource\"]],[24,[\"resourceKey\"]],\"upload images\",\"span\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/files/delete-files-gallery-uploader/template.hbs"
    }
  });
});