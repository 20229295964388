define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/numbers/row/blanks/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ExtensionsAppsTwEInvoicesTracksManagerNumbersRowBlanksComponent = _exports.default = (_dec = Ember.computed('args.model._data.blanks.[]'), _dec2 = Ember.computed('args.model._data.blanks.[]'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _class = class ExtensionsAppsTwEInvoicesTracksManagerNumbersRowBlanksComponent extends _component.default {
    get suggestedBlankStartNumber() {
      const model = this.args.model;
      if (!model) {
        return '';
      }
      let suggestedNumber;
      const lastNumber = Number(model?._data?.lastNumber) || 0;
      if (lastNumber) {
        suggestedNumber = lastNumber + 1;
      }
      const blanks = model?._data?.blanks || [];
      if (blanks.length > 1 && suggestedNumber) {
        return '';
      }
      if (suggestedNumber) {
        if (suggestedNumber > model?._data?.endNumber) {
          return '';
        }
        return suggestedNumber;
      }
      suggestedNumber = R.path([0, 'endNumber'])(blanks) || '';
      if (suggestedNumber) {
        return suggestedNumber;
      }
      return model?._data?.startNumber || '';
    }
    get suggestedBlankEndNumber() {
      const model = this.args.model;
      if (!model) {
        return '';
      }
      if (!model?._data?.isAllowed) {
        return model?._data?.endNumber || '';
      }
      const remainingCount = Number(model?._data?.remainingCount) || 0;
      if (!remainingCount) {
        return '';
      }
      const blanks = model?._data?.blanks || [];
      if (blanks.length > 1) {
        return '';
      }
      return model?._data?.endNumber || '';
    }
    setSuggestedNumber(blankModel, path, suggestedBlankStartNumber) {
      Ember.set(blankModel, path, suggestedBlankStartNumber);
      this.checkBlanks(true);
    }
    addBlankNumberSet() {
      this.args.model.addBlankNumberSet();
    }
    checkBlanks(isFocusOut) {
      const model = this.args.model;
      model.checkBlanksAllowed(isFocusOut);
    }
    removeBlankNumberSet(blank) {
      const model = this.args.model;
      model.removeBlankNumberSet(blank);
    }
    *saveTask(model) {
      yield this.args.saveTask.perform(model);
      this.args.onCloseDialogAction();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "suggestedBlankStartNumber", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "suggestedBlankStartNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "suggestedBlankEndNumber", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "suggestedBlankEndNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSuggestedNumber", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setSuggestedNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addBlankNumberSet", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addBlankNumberSet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkBlanks", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "checkBlanks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeBlankNumberSet", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeBlankNumberSet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _class);
});