define("client/pods/components/products/price-selectors/mixins/products", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    isOnSale: Ember.computed('history.onSale', function () {
      if (this.get('history.onSale') === 'on') {
        return true;
      }
      return false;
    }),
    oldPrice: Ember.computed('history.{isSetWithChoices,hasVariants,price,priceDiscounted}', 'synced', function () {
      if (this.get('history.isSetWithChoices')) {
        return this.lowestSetWithChoicesPriceOld;
      }
      if (this.get('history.hasVariants')) {
        return this.lowestVariantPriceOld;
      }
      return this.get('history.price');
    }),
    currentPrice: Ember.computed('isOnSale', 'history.{price,priceDiscounted}', 'synced', function () {
      if (this.get('history.isSetWithChoices')) {
        if (this.isOnSale) {
          return this.lowestSetWithChoicesPricePossible;
        }
        return this.lowestSetWithChoicesPriceOld;
      }
      if (this.get('history.hasVariants')) {
        if (this.isOnSale) {
          return this.lowestVariantPriceCurrent;
        }
        return this.lowestVariantPriceOld;
      }
      if (this.isOnSale) {
        if (this.get('history.priceDiscounted')) {
          return this.get('history.priceDiscounted');
        }
      }
      return this.get('history.price');
    }),
    currentInclPrice: Ember.computed('isOnSale', 'history.{price,priceDiscounted,isSetPriceOnBundle}', 'synced', function () {
      if (this.get('history.isSetWithChoices')) {
        if (this.isOnSale) {
          return this.lowestSetWithChoicesPricePossible;
        }
        return this.lowestSetWithChoicesPriceOld;
      }
      if (this.get('history.hasVariants')) {
        if (this.isOnSale) {
          return this.lowestVariantPriceCurrent;
        }
        return this.lowestVariantPriceOld;
      }
      if (this.isOnSale) {
        if (this.get('history.priceDiscounted')) {
          return this.get('history.priceDiscounted');
        }
      }
      return this.get('history.inclPrice');
    }),
    lowestVariantOldPriceRange: Ember.computed('history.{variantChildren.[],price,priceDiscounted}', function () {
      const variantChildren = this.get('history.variantChildren') || [];
      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };
      let variantMasterPrice = this.get('history.price');
      if (!variantMasterPrice) {
        variantMasterPrice = this.get('history.priceDiscounted');
      }
      const isEmpty = R.curry((prop, child) => {
        return R.isEmpty(R.path(['history', prop], child));
      });
      return R.pipe(R.reject(R.allPass([isEmpty('price'), isEmpty('priceDiscounted')])), R.map(variantChild => {
        const price = R.path(['history', 'price'])(variantChild);
        if (price) {
          return price;
        }
        return R.path(['history', 'priceDiscounted'])(variantChild);
      }), R.concat([variantMasterPrice]), R.uniq, R.sort(diff))(variantChildren);
    }),
    lowestVariantPriceOld: Ember.computed('history.{variantChildren.[],price,priceDiscounted}', function () {
      const lowestVariantPrices = this.lowestVariantOldPriceRange || [];
      let lowestVariantPrice;
      if (lowestVariantPrices.length > 0) {
        lowestVariantPrice = lowestVariantPrices[0];
      }
      return lowestVariantPrice;
    }),
    lowestVariantCurrentPriceRange: Ember.computed('history.{variantChildren.[],price,priceDiscounted,onSale}', function () {
      const variantChildren = this.get('history.variantChildren') || [];
      const diff = (a, b) => parseFloat(a) - parseFloat(b);
      const onSale = this.get('history.onSale');
      let variantMasterPrice = this.get('history.price');
      if (!variantMasterPrice || onSale) {
        variantMasterPrice = this.get('history.priceDiscounted');
      }
      const isEmpty = R.curry((prop, child) => {
        return R.isEmpty(R.path(['history', prop], child));
      });
      return R.pipe(R.reject(R.allPass([isEmpty('price'), isEmpty('priceDiscounted')])), R.map(variantChild => {
        if (onSale === 'on') {
          if (R.pathEq(['history', 'onSale'], 'on', variantChild)) {
            return R.path(['history', 'priceDiscounted'], variantChild);
          }
        }
        return R.path(['history', 'price'], variantChild);
      }), R.concat([variantMasterPrice]), R.uniq, R.sort(diff))(variantChildren);
    }),
    lowestVariantPriceCurrent: Ember.computed('history.{variantChildren.[],price,priceDiscounted,onSale}', function () {
      const lowestVariantPrices = this.lowestVariantCurrentPriceRange || [];
      let lowestVariantPrice;
      if (lowestVariantPrices.length > 0) {
        lowestVariantPrice = lowestVariantPrices[0];
      }
      return lowestVariantPrice;
    }),
    lowestSetWithChoicesPriceOld: Ember.computed('history.{choices.[]}', function () {
      const choices = this.get('history.choices') || [];
      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };
      const lowestTotalPrice = R.pipe(R.map(choice => {
        const chooseQty = choice.qty;
        const isSetPriceOnBundle = choice.isSetPriceOnBundle;
        const details = choice.details || [];
        const lowestPrices = R.map(detail => {
          if (detail.hasVariants) {
            return detail.lowestVariantPriceOld;
          }
          if (detail.price) {
            return detail.price;
          }
          return detail.priceDiscounted;
        })(details);
        let lowestPrice = R.pipe(R.sort(diff), R.head)(lowestPrices);
        if (isSetPriceOnBundle) {
          if (choice.price) {
            lowestPrice = choice.price || 0;
          } else {
            lowestPrice = choice.priceDiscounted || 0;
          }
          return lowestPrice;
        }
        return chooseQty * lowestPrice;
      }), R.flatten, R.reduce((acc, lowestPrice) => {
        acc = parseInt(acc) + parseInt(lowestPrice);
        return acc;
      }, 0))(choices);
      return lowestTotalPrice;
    }),
    lowestSetWithChoicesPricePossible: Ember.computed('history.{choices.[],onSale}', function () {
      const choices = this.get('history.choices') || [];
      const diff = function (a, b) {
        return parseFloat(a) - parseFloat(b);
      };
      const onSale = this.get('history.onSale');
      const lowestTotalPrice = R.pipe(R.map(choice => {
        const chooseQty = choice.qty;
        const isSetPriceOnBundle = choice.isSetPriceOnBundle;
        const details = choice.details || [];
        const lowestPrices = R.map(detail => {
          if (onSale === 'on') {
            if (detail.hasVariants) {
              return detail.lowestVariantPriceCurrent;
            }

            // if (detail.onSale === 'on') {
            return detail.priceDiscounted;
            // }
          }
          return detail.price;
        })(details);
        let lowestPrice = R.pipe(R.sort(diff), R.head)(lowestPrices);
        if (isSetPriceOnBundle) {
          if (onSale === 'on') {
            lowestPrice = choice.priceDiscounted || 0;
          } else {
            lowestPrice = choice.price || 0;
          }
          return lowestPrice;
        }
        return chooseQty * lowestPrice;
      }), R.flatten, R.reduce((acc, lowestPrice) => {
        acc = parseInt(acc) + parseInt(lowestPrice);
        return acc;
      }, 0))(choices);
      return lowestTotalPrice;
    }),
    hasPriceRange: Ember.computed('history.{choices.[],onSale,variantChildren.[]}', function () {
      const choices = this.get('history.choices') || [];
      const onSale = this.get('history.onSale');
      if (this.get('history.hasVariants')) {
        const lowestVariantCurrentPriceRange = this.lowestVariantCurrentPriceRange || [];
        if (lowestVariantCurrentPriceRange.length > 1) {
          return true;
        }
        return false;
      }
      const onlyChoicesWithPriceRanges = R.filter(choice => {
        const details = choice.details || [];
        const isSetPriceOnBundle = choice.isSetPriceOnBundle;
        if (isSetPriceOnBundle) {
          return false;
        }
        const allUniqueDetailPrices = R.pipe(R.map(detail => {
          if (onSale === 'on') {
            if (detail.onSale === 'on') {
              return detail.priceDiscounted;
            }
          }
          return detail.price;
        }), R.uniq)(details);
        if (allUniqueDetailPrices.length > 1) {
          return true;
        }
        return false;
      })(choices);
      if (onlyChoicesWithPriceRanges.length > 0) {
        return true;
      }
      return false;
    })
  });
});