define("client/pods/components/extensions/apps/tw/t-cat/create-contact/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "client/constants", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, _constants, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwTCatCreateContactComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('contacts'), _dec4 = Ember.inject.service('transporter'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _class = class ExtensionsAppsTwTCatCreateContactComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "contactService", _descriptor3, this);
      _initializerDefineProperty(this, "transporterService", _descriptor4, this);
      _initializerDefineProperty(this, "contactsList", _descriptor5, this);
      _initializerDefineProperty(this, "model", _descriptor6, this);
      _initializerDefineProperty(this, "isLinkContact", _descriptor7, this);
      _initializerDefineProperty(this, "transporter", _descriptor8, this);
      _initializerDefineProperty(this, "refreshed", _descriptor9, this);
      _defineProperty(this, "adapterNameContacts", 'contacts');
      _defineProperty(this, "adapterNameContactsTransporters", 'contacts');
      _defineProperty(this, "defaultThermosphere", 'unrefrigerated');
      _defineProperty(this, "productTypeDefault", 'general food');
      this.setupLists.perform();
    }
    get linkIcon() {
      return this.isLinkContact ? 'fas fa-link' : null;
    }
    get thermosphereSelected() {
      const transporterAccounts = R.pathOr([], ['model', '_data', 'transporterAccounts'])(this);
      return R.pipe(R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-t-cat-lists-thermosphere']), R.filter(R.pipe(R.pathOr('', ['_data', 'value']), value => R.includes(value, transporterAccounts))))(this);
    }
    get disableSenderInputData() {
      // const refreshed = this.refreshed
      // const model = this?.args?.model || this.model
      // const hasDefaultPickupLocationPersonId = this?.args?.model?._data?.defaultPickupLocationPersonId || this.model?._data?.defaultPickupLocationPersonId
      // const hasNonSenderDetails = !model?._data?.senderName && !model?._data?.senderTelephone && !model?._data?.senderAddress
      // if (hasDefaultPickupLocationPersonId || hasNonSenderDetails || refreshed) {
      //   return true
      // }
      return false;
    }
    get allowCreate() {
      const allowCreate = this.args.allowCreate;
      const isNilOrEmpty = RA.isNilOrEmpty(allowCreate);
      if (isNilOrEmpty) {
        return true;
      }
      return allowCreate;
    }
    afterLoadLists() {
      this.setupModel();
    }
    setupModel({
      isNew = false
    } = {}) {
      const existingModel = R.pathOr(null, ['args', 'model'])(this);
      if (existingModel && !isNew) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model: existingModel
        });
      } else {
        const transporterAccounts = this.crud.lists['extensions-apps-tw-t-cat-lists-thermosphere'];
        const transporterDeliveryTime = this.crud.lists['extensions-apps-tw-t-cat-lists-delivery-time'];
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterNameContacts,
          data: {
            defaultTransporterAccount: this.defaultThermosphere,
            transporterAccounts: transporterAccounts,
            packageSizeDefault: '60cm',
            productTypeDefault: this.productTypeDefault,
            transporterDeliveryTime: transporterDeliveryTime,
            defaultTransporterWaybillPrintType: 'thermal',
            defaultDeliveryTime: 'anytime'
          }
        });
      }
    }
    *saveTask(onCloseDialogAction) {
      let transporterModel;
      this.model.setData('relationships', [_constants.default.contactsRelationship.supplier]);
      this.model.setData('isTransporter', _constants.default.isTransporter.on);
      this.model.setData('transporterExtension', 't-cat');
      this.model.setData('isAllowedLinkMember', false);
      const isDirty = this.model.isDirty && this.model?._data?._key;
      if (this.isLinkContact) {
        const dirty = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model: this.model
        });
        transporterModel = yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterNameContactsTransporters,
          model: dirty
        });
      } else if (isDirty) {
        transporterModel = yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterNameContactsTransporters,
          model: this.model
        });
      } else if (this.allowCreate) {
        transporterModel = yield this.crud.createRecordTask.perform({
          adapterName: 'contacts',
          model: this.model
        });
      } else {
        const data = this.model._data;
        this.args.model.set('_data', data);
      }
      if (this?.args?.onLoadTransporterContact) {
        this.args.onLoadTransporterContact(transporterModel);
        this.model = transporterModel;
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
    *searchContactsTask(args = {
      relationship: 'supplier'
    }) {
      this.contactsList = yield this.contactService.searchInputTask.perform(args);
    }
    *setupLists() {
      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time']);
      if (this.args.fetchTransporter !== false) {
        yield this.searchContactsTask.perform();
      }
    }
    loadTransporter(transporter) {
      this.isLinkContact = true;
      this.transporter = transporter;
      R.pipe(R.propOr({}, '_data'), R.forEachObjIndexed((value, key) => {
        this.model.setData(key, value);
      }))(transporter);
      this.model.setData('_key', transporter.getData('_key'));
      this.model.setData('_rev', transporter.getData('_rev'));
      this.model.setData('code', transporter.getData('code'));
      this.model.setData('transporterAccounts', this.crud.lists['extensions-apps-tw-t-cat-lists-thermosphere']);
      const defaultTransporterAccount = transporter?._data?.defaultTransporterAccount || this.transporterService.defaultTransporterAccountNumber;
      this.model.setData('defaultTransporterAccount', defaultTransporterAccount);
      this.refreshModel(this.model);
    }
    unloadTransporter() {
      this.isLinkContact = false;
      this.transporter = '';
      this.model.setData('_key', '');
      this.model.setData('_rev', '');
      this.model.setData('code', '');
      this.model.setData('transporterAccounts', []);
      this.model.setData('defaultTransporterAccount', '');
      this.setupModel({
        isNew: true
      });
      this.refreshModel();
    }
    selectThermosphere(model) {
      const transporterAccounts = R.pathOr([{}], ['model', '_data', 'transporterAccounts'])(this);
      transporterAccounts.addObject(model);
      const thermosphereDefault = R.pathOr({}, ['model', '_data', 'thermosphereDefault'])(this);
      const isThermosphereDefaultValid = R.includes(thermosphereDefault, transporterAccounts);
      if (!isThermosphereDefaultValid) {
        const thermosphereOnlyChoice = R.pathOr(this.defaultThermosphere, [0])(transporterAccounts);
        this.model.setData('thermosphereDefault', thermosphereOnlyChoice);
      }
      this.refreshModel();
    }
    refreshModel(model) {
      const isDirty = R.pathEq(['model', 'isDirty'], true)(this);
      if (isDirty) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model
        });
        this.model.set('_data', this.model._data);
      } else {
        this.model = model || R.propOr({}, 'model')(this);
      }
      this.refreshed = !this.refreshed;
    }
    setDefaultPickupLocationPersonId(person = {}) {
      const personId = person?.personId || '';
      const name = person?.name || '';
      const telephone = person?.telephone || '';
      const address = person?.address || '';
      const defaultDeliveryTime = person?.transporterDeliveryTime || '';
      const defaultTransporterAccount = person?.transporterPackageTemperature || '';
      const packageSizeDefault = person?.transporterPackageSize || '';
      const productTypeDefault = person?.transporterPackageType || '';
      const defaultTransporterNotes = person?.transporterNotes || '';
      this.model.set('_data.defaultPickupLocationPersonId', personId);
      this.model.set('_data.senderName', name);
      this.model.set('_data.senderTelephone', telephone);
      this.model.set('_data.senderAddress', address);
      this.model.set('_data.defaultDeliveryTime', defaultDeliveryTime);
      this.model.set('_data.defaultTransporterAccount', defaultTransporterAccount);
      this.model.set('_data.packageSizeDefault', packageSizeDefault);
      this.model.set('_data.productTypeDefault', productTypeDefault);
      this.model.set('_data.defaultTransporterNotes', defaultTransporterNotes);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "contactsList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isLinkContact", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "transporter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "refreshed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchContactsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchContactsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupLists", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTransporter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "loadTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadTransporter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "unloadTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectThermosphere", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectThermosphere"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaultPickupLocationPersonId", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultPickupLocationPersonId"), _class.prototype), _class);
});