define("client/pods/grapesjs/plugins/slider-native/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const prefix = 'lory-';
  const sliderName = `${prefix}slider`;
  const slideName = `${prefix}slide`;
  const slidesName = `${prefix}slides`;
  const frameName = `${prefix}frame`;
  const prevName = `${prefix}prev`;
  const nextName = `${prefix}next`;
  const dotsName = `${prefix}dots`;
  const dotName = `${prefix}dot`;
  var _default = _exports.default = {
    sliderName,
    slideName,
    slidesName,
    prevName,
    nextName,
    frameName,
    dotsName,
    dotName,
    // Selectors
    sliderSelector: `[data-${sliderName}]`,
    slidesSelector: `[data-${slidesName}]`,
    slideSelector: `[data-${slideName}]`,
    prevSelector: `[data-${prevName}]`,
    nextSelector: `[data-${nextName}]`,
    frameSelector: `[data-${frameName}]`,
    dotsSelector: `[data-${dotsName}]`,
    dotSelector: `[data-${dotName}]`,
    // IDs
    sliderId: `data-${sliderName}`,
    slideId: `data-${slideName}`,
    slidesId: `data-${slidesName}`,
    prevId: `data-${prevName}`,
    nextId: `data-${nextName}`,
    frameId: `data-${frameName}`,
    dotsId: `data-${dotsName}`,
    dotId: `data-${dotName}`
  };
});