define("client/pods/components/channels/shop/shop-sales-persons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PrwdyfSP",
    "block": "{\"symbols\":[\"salesPerson\",\"@model\"],\"statements\":[[4,\"each\",[[23,2,[\"_data\",\"salesPersons\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n    \"],[1,[23,1,[\"_data\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shop-sales-persons/template.hbs"
    }
  });
});