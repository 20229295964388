define("client/pods/components/contacts/persons/pickup-location/editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactsPersonsPickupLocationEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('transporter'), _dec3 = Ember.inject.service('settings'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _class = class ContactsPersonsPickupLocationEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "transporterService", _descriptor2, this);
      _initializerDefineProperty(this, "settingsService", _descriptor3, this);
      _initializerDefineProperty(this, "model", _descriptor4, this);
      _initializerDefineProperty(this, "modelTransporter", _descriptor5, this);
      _defineProperty(this, "adapterName", 'contacts/persons');
      _defineProperty(this, "modelTransporterAdapterName", 'contacts');
      this.crud.addLists(this, ['transporter/lists/pickup-editor-extension']);
      this.setupModelTransporterDirty(this.args.parentModel);
      this.setupDirty(this.args.model);
    }
    get isRunning() {
      return this.removeTask.isRunning || this.updateTask.isRunning || this.addTask.isRunning;
    }
    setupModelTransporterDirty(model) {
      this.modelTransporter = this.crud.setupDirty({
        adapterName: this.modelTransporterAdapterName,
        model
      });
      this.modelTransporter.setTransporterKeysToPersonDetailsModel();
    }
    setupDirty(model) {
      const isEditing = this.args?.isEditing === true;
      if (isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data: {
            type: 'pickupLocation',
            name: this.settingsService?.settingsModel?._data?.company
          }
        });
        const transporterExtension = this.args.parentModel._data.transporterExtension;
        const setupPersonOptions = {
          transporterExtension,
          transporterSenderTelephone: this.settingsService?.settingsModel?._data?.companyTel
        };
        this.model.setupPerson(setupPersonOptions);
      }
    }
    *updateTask(onCloseDialogAction, parentModel, model) {
      yield this.transporterService.replacePersonTask.perform(parentModel, model);
      onCloseDialogAction();
    }
    *removeTask(onCloseDialogAction, parentModel, model) {
      yield this.transporterService.removePersonTask.perform(parentModel, model);
      onCloseDialogAction();
    }
    *addTask(onCloseDialogAction, parentModel, model) {
      yield this.transporterService.addPersonTask.perform(parentModel, model);
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelTransporter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupModelTransporterDirty", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupModelTransporterDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDirty", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addTask"), _class.prototype), _class);
});