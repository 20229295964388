define("client/pods/components/channels/shop/shipping-zones/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y3U7YqFB",
    "block": "{\"symbols\":[\"@result\",\"@query\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[23,1,[\"_data\",\"name\"]],[23,2,[]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"_data\",\"transporterLabel\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[23,1,[\"_data\",\"transporterLabel\"]],false],[0,\" \\n\"],[4,\"if\",[[23,1,[\"_data\",\"transporterAccount\"]]],null,{\"statements\":[[0,\"\\t\\t\\t(\"],[1,[23,1,[\"_data\",\"transporterAccount\"]],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/search-results/template.hbs"
    }
  });
});