define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8K8oZJE6",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"conflict\",\"@model\",\"@detailModel\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@closeOnOverlayClick\",\"@onClose\"],[\"bulk discount conflicts\",[23,0,[\"modalDialogIsVisible\"]],false,[28,\"fn\",[[23,0,[\"onCloseBulkDiscountConflictDialog\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,3,[\"_data\",\"bulkDiscountConflictDetails\"]]],null,{\"statements\":[[0,\"      \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts\",[],[[\"@model\",\"@conflict\",\"@onCloseDialogAction\",\"@detailModel\"],[[23,3,[]],[23,2,[]],[23,1,[]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/btn-save\",[],[[\"@icon\",\"@label\",\"@onSubmit\"],[false,\"done\",[28,\"fn\",[[23,0,[\"onCloseBulkDiscountConflictDialog\"]],[23,1,[]]],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/template.hbs"
    }
  });
});