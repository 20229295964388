define("client/pods/components/modals/modal-dialog/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ModalsModalDialogComponent = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _class = class ModalsModalDialogComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "hasModalDialogHeader", _ramdaAdjunct.default.isNotNilOrEmpty(this.args.hasModalDialogHeader) ? this.args.hasModalDialogHeader : true);
      _defineProperty(this, "hasSectionWrapper", _ramdaAdjunct.default.isNotNilOrEmpty(this.args.hasSectionWrapper) ? this.args.hasSectionWrapper : false);
      _defineProperty(this, "hasClosed", false);
    }
    // constructor () {
    //   super(...arguments)
    // }

    setup() {
      this.hasClosed = false;
      if (this.args.onInit) {
        this.args.onInit(this);
      }
    }
    get hasModalOverlay() {
      if (R.isNil(this.args.hasModalOverlay)) {
        return true;
      }
      return this.args.hasModalOverlay;
    }
    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }
    get closeOnOverlayClick() {
      return R.pathOr(true, ['args', 'closeOnOverlayClick'])(this);
    }
    get modalSizeClassName() {
      const modalSize = this.args?.modalSize || 'large';
      if (modalSize === 'small') {
        return 'modal-dialog__container-small';
      }
      if (modalSize === 'medium') {
        return 'modal-dialog__container-medium';
      }
      return '';
    }
    close() {
      if (this.hasClosed) {
        return;
      }
      this.hasClosed = true;
      if (this.args.onToggle) {
        this.args.onToggle(false);
      }
      if (this.args.onClose) {
        this.args.onClose();
      }
    }
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this.close();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "setup", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype), _class);
});