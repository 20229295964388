define("client/pods/components/dispatch/processes-bar-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kby/f7ap",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\t\"],[14,1,[[24,[\"setIsDispatchedProcessTask\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"allowDispatchProcesses\"]],[24,[\"showProcessBar\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"isModal\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"modals/modal-container-new\",[],[[\"@closeOnOverlayClick\",\"@modalContentClassNames\",\"@onToggleModalDialog\"],[true,\"is-list-btn\",[28,\"fn\",[[23,0,[\"onToggleModalDialog\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"dispatch/processes-bar-container/processes-bar\",null,[[\"isVertical\",\"model\",\"isSelected\",\"selected\",\"disabled\",\"steps\",\"stepValueParam\",\"stepLabelParam\",\"onSubmitTask\",\"onSubmitCustomDateTask\",\"selectCustomDate\"],[[24,[\"isVertical\"]],[24,[\"model\"]],[24,[\"isSelected\"]],[24,[\"selected\"]],[24,[\"disabled\"]],[24,[\"steps\"]],[24,[\"stepValueParam\"]],[24,[\"stepLabelParam\"]],[24,[\"submitTask\"]],[24,[\"submitCustomDateTask\"]],[24,[\"selectCustomDate\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"dispatch/processes-bar-container/processes-bar\",null,[[\"model\",\"isVertical\",\"isSelected\",\"selected\",\"steps\",\"stepValueParam\",\"stepLabelParam\",\"onSubmitTask\",\"onSubmitCustomDateTask\",\"selectCustomDate\"],[[24,[\"model\"]],[24,[\"isVertical\"]],[24,[\"isSelected\"]],[24,[\"selected\"]],[24,[\"steps\"]],[24,[\"stepValueParam\"]],[24,[\"stepLabelParam\"]],[24,[\"submitTask\"]],[24,[\"submitCustomDateTask\"]],[24,[\"selectCustomDate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/processes-bar-container/template.hbs"
    }
  });
});