define("client/pods/components/websites/websites-editor/pages-editor/component", ["exports", "ember-concurrency", "ramda", "jquery", "client/mixins/crud", "client/utils/nventor", "client/config/environment", "ramda-adjunct"], function (_exports, _emberConcurrency, R, _jquery, _crud, _nventor, _environment, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    ajax: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    grapesjs: Ember.inject.service(),
    users: Ember.inject.service(),
    search: Ember.inject.service(),
    application: Ember.inject.service(),
    websitesService: Ember.inject.service('websites'),
    resource: 'files',
    resourceKey: 'files',
    isLoadingTemplate: false,
    showLinkToProductsModal: false,
    showProductsModal: false,
    showScratchCardModal: false,
    productsResults: null,
    lastHeight: '',
    showSliderModal: false,
    showButtons: true,
    grapesJsFullscreen: false,
    isEditorCanvasLoading: true,
    showBtnModal: false,
    showFormModal: false,
    showScheduleModal: false,
    showReIndexModal: false,
    editorClassName: 'gjs-column editor-clm',
    dataTabContainer: 'websites',
    schedulesTargetContainerClass: null,
    schedulesTatgetClass: null,
    schedulesTatgetIsArray: false,
    linksModalData: null,
    init() {
      this.crud.addLists(this, ['websites/lists/types', 'websites/lists/types/links', 'statuses']);
      this.set('productsResults', []);
      let editorId = this.editorId;
      if (!editorId) {
        editorId = _nventor.default.random.alpha(10);
        this.set('editorId', editorId);
        const storagePrefix = this.storage.wrapWithPrefix(editorId);
        this.set('storagePrefix', storagePrefix);
      }
      this.set('config', _environment.default);
      this._super(...arguments);
    },
    didInsertElement() {
      this._super(...arguments);
      this.setup();
      const timeoutTime = R.pathOr(null, ['model', '_data', 'canvasHeight'])(this) ? 0 : 2500;

      // if (!this.isNew && this.fullscreenOnInit === true) {
      if (!this.isNew && this.isEditing) {
        this.setIsFullscreen(true);
      }
      setTimeout(() => {
        if (this?.isDestroyed === false) {
          this.set('isEditorCanvasLoading', false);
        }
      }, timeoutTime);
    },
    previewLockedLink: Ember.computed('dataTabContainer', 'model._data.url', 'website._data.{domainVerified,domain,subdomain}', function () {
      const model = this.model;
      const website = this.website;
      return this.websitesService.getPreviewLink({
        model,
        website,
        dataTabContainer: this.dataTabContainer,
        isPreview: true
      });
    }),
    blogsTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.search.setupFilters({
        adapterName: 'blogs/filters',
        defaultFiltersData: {
          master: this.website._data._key,
          count: 1
        }
      });
      return yield this.crud.searchRecordsTask.perform({
        adapterName: 'blogs',
        filters
      });
    }),
    /**
     * search form flows task for website
     */
    searchFormFlowsForWebsiteTask: (0, _emberConcurrency.task)(function* () {
      try {
        const filters = this.search.setupFilters({
          adapterName: 'flows/filters',
          defaultFiltersData: {
            // no websiteKey filter now
            // websiteKey: this.website._data._key,
            // shopKey: this.shopKey,
            type: 'form'
          }
        });
        return yield this.crud.searchRecordsTask.perform({
          adapterName: 'flows',
          filters
        });
      } catch (error) {
        console.error('Error while: searchFormFlowsForWebsiteTask: ', error);
        return [];
      }
    }),
    isReadonly: Ember.computed('isNew', 'isEditing', function () {
      if (this.isNew || this.isEditing) {
        return false;
      }
      return true;
    }),
    shouldHideFooterExtraOptions: Ember.computed('model._data.isDynamicPage', 'model._data.hasTemplate', 'model.isCategory', 'model.isLink', 'isReadonly', function () {
      if (this.model?._data?.isDynamicPage || this.model?.isLink || this.model?.isCategory || this.isReadonly || this.model?._data?.hasTemplate === false) {
        return true;
      }
      return false;
    }),
    shopKey: Ember.computed('channelsShopKey', 'dataTabContainer', 'website._data.shopKey', function () {
      if (this.dataTabContainer === 'campaigns') {
        return this.channelsShopKey;
      }
      return this.website._data.shopKey;
    }),
    previewLink: Ember.computed('dataTabContainer', 'model._data.url', 'website._data.{domainVerified,domain,subdomain}', function () {
      const model = this.model;
      const website = this.website;
      return this.websitesService.getPreviewLink({
        model,
        website,
        dataTabContainer: this.dataTabContainer
      });
    }),
    setIsFullscreen(currentValue) {
      const editorElement = document.getElementById(this.editorId);
      if (editorElement) {
        const editorContainerElement = editorElement.closest('.editor-clm');
        if (currentValue === true) {
          this.set('grapesJsFullscreen', true);
          return true;
        }
        if (currentValue === false) {
          this.set('grapesJsFullscreen', false);
          return false;
        }
        if (this.isEditing || this.isNew) {
          this.set('grapesJsFullscreen', true);
        } else {
          this.set('grapesJsFullscreen', false);
        }
      } else {
        this.set('grapesJsFullscreen', false);
      }
    },
    setup(setupOnInitToggle = false) {
      if (this.showWebBuilder) {
        this.setupEditor();
      } else if (this.dataTabContainer === 'campaigns' || setupOnInitToggle) {
        this.setupEditor();
      }
    },
    setupEditor() {
      Ember.run.scheduleOnce('afterRender', this, async function () {
        const editorId = this.editorId;
        const storagePrefix = this.storagePrefix;

        // @TODO: rename to better names
        const showSearchProductsModal = productsViewComponent => {
          this.set('productsViewComponent', productsViewComponent);
          this.set('showProductsModal', true);
        };

        // @TODO: rename to better names
        const showProductsModal = productsViewComponent => {
          // this.set('productsViewComponent', productsViewComponent)
          this.set('showManyProductsModal', true);
        };
        const showBlogsModal = blogsViewComponent => {
          this.set('showBlogsModal', true);
          this.set('blogsViewComponent', blogsViewComponent);
          // this.set('showBlogsModal', true)
        };
        const showFormModal = formsViewComponent => {
          this.set('showFormModal', true);
          this.set('formsViewComponent', formsViewComponent);
        };
        const showSearchImagesModal = (imgType, imagesManager, allowSrcSet = true) => {
          const imgSet = R.prop('imgSet')(imagesManager);
          if (imgSet) {
            this.set('previousImage', {
              imgSet
            });
          }
          this.set('imgType', imgType);
          this.set('imagesManager', imagesManager);
          this.set('showImagesModal', true);
          this.set('allowSrcSet', allowSrcSet);
        };
        const toggleLinksModal = (linksModalData = {}) => {
          const showLinksModal = !this.showLinksModal;
          this.set('linksModalData', linksModalData);
          this.set('showLinksModal', showLinksModal);
        };
        const toggleScratchCardModal = scratchCardComponent => {
          this.set('scratchCardComponent', scratchCardComponent);
          const toggle = !this.showScratchCardModal;
          this.set('showScratchCardModal', toggle);
        };
        const toggleSliderModal = el => {
          const children = el.children;
          const bigordrSlider = el;
          this.set('sliderImages', children);
          this.set('bigordrSlider', bigordrSlider);
          this.set('imgType', 'optimized-image');
          this.set('imagesManager', {});
          const toggle = !this.showSliderModal;
          this.set('showSliderModal', toggle);
        };
        const toggleSetSlidesSchedule = () => {
          this.set('schedulesTargetContainerClass', 'lory-slides');
          this.set('schedulesTatgetClass', 'gjs-lory-slide');
          this.set('schedulesTatgetIsArray', true);
          const toggle = !this.showScheduleModal;
          this.set('showScheduleModal', toggle);
        };
        const toggleReIndexModal = () => {
          const toggle = !this.showReIndexModal;
          this.set('showReIndexModal', toggle);
        };
        const toggleBtnModal = () => {
          const toggle = !this.showBtnModal;
          this.set('showBtnModal', toggle);
        };
        const fetchProduct = component => this.fetchProductTask.perform(component);
        const fetchBlogs = component => this.fetchBlogsTask.perform(component);
        const model = this.model;
        const website = this.website;
        const grapesJsService = this.grapesjs;
        const pageDataString = model.getData('pageDataString') || '{}';
        const customPlugins = grapesJsService.getPlugins();
        const hasNoShop = this.dataTabContainer === 'campaigns' ? false : !this.website._data.shopKey;
        const isReadonly = this.isReadonly;
        const blogs = (await this.blogsTask.perform()) || [];
        const hasBlogs = blogs.length >= 1;
        const formFlows = (await this.searchFormFlowsForWebsiteTask.perform()) || [];
        const hasFormFlows = formFlows.length >= 1;
        const editor = grapesJsService.create(editorId, storagePrefix, pageDataString, {
          isReadonly,
          canvasHeight: model.getData('canvasHeight'),
          translations: {
            'gjs-Attachment': '附件',
            'gjs-Background': '背景',
            'gjs-Background-color': '背景顏色'
          },
          openLinksModal: toggleLinksModal,
          pluginsOpts: {
            'bigordr-background-fixed': {
              parentComponent: this,
              onDbClick: showSearchImagesModal
            },
            'bigordr-button': {
              parentComponent: this,
              website: this.website,
              onDbClick: toggleBtnModal,
              onDrop: toggleBtnModal
            },
            'bigordr-slider': {
              parentComponent: this,
              onDbClick: toggleSliderModal,
              onDrop: toggleSliderModal
            },
            'bigordr-slider-native': {
              parentComponent: this,
              onDbClick: toggleSliderModal,
              onDrop: toggleSliderModal,
              onReIndex: toggleReIndexModal,
              onSetSchedule: toggleSetSlidesSchedule
            },
            'bigordr-scratch-card': {
              parentComponent: this,
              onDrop: toggleScratchCardModal,
              onDbClick: toggleScratchCardModal
            },
            'bigordr-blogs': {
              onDrop: showBlogsModal,
              onDbClick: showBlogsModal,
              onRender: fetchBlogs,
              hasBlogs,
              disabled: true // hasNoBlogs
            },
            'bigordr-form': {
              onDrop: showFormModal,
              onDbClick: showFormModal,
              hasFormFlows,
              disabled: true
            },
            'bigordr-product': {
              onDrop: showSearchProductsModal,
              onDbClick: showSearchProductsModal,
              onRender: fetchProduct,
              disabled: hasNoShop
            },
            'bigordr-products': {
              onDrop: showProductsModal,
              onDbClick: showProductsModal,
              disabled: hasNoShop
            },
            'bigordr-panels': {
              parentComponent: this
            },
            [customPlugins.ImagePlugin]: {
              onOpenAssets: showSearchImagesModal
            },
            [customPlugins.FullWidthImagePlugin]: {
              onOpenAssets: showSearchImagesModal,
              openLinksModal: toggleLinksModal
            },
            'bigordr-strip': {
              'bigordr-strip-image': {
                onDbClick: showSearchImagesModal
              }
            }
          }
        }, model, website);
        this.set('editor', editor);
      });
    },
    // @TODO: FETCH FROM CAMPAIGN DETAILS RATHER THAN PRODUCTS
    // @TODO: ISSUE #881
    fetchProductTask: (0, _emberConcurrency.task)(function* (component) {
      const itemKey = component.get('itemKey');
      const hasNoDetails = itemKey === '0000000';
      if (hasNoDetails) {
        return;
      }
      const productModel = yield this.crud.findTask.perform({
        adapterName: 'products',
        appendPath: `/${itemKey}`
      });
      let defaultImage;
      try {
        if (productModel.getDefaultImageUrl) {
          defaultImage = productModel.getDefaultImageUrl('/-/scale_crop/200x200/smart/');
          component.set('defaultImage', defaultImage);
        }
      } catch (e) {
        console.error({
          productModel,
          itemKey,
          e
        });
      }
    }),
    fetchBlogsTask: (0, _emberConcurrency.task)(function* (component) {
      const resourceKey = R.pipe(c => c.getAttributes(), R.pathOr(null, ['data-bigordr-blog-key']))(component);
      if (resourceKey) {
        const uploadsModel = yield this.crud.fetchUploadsTask.perform({
          resource: 'blogs',
          resourceKey: resourceKey,
          adapterName: 'uploads/collection',
          params: {
            isDefault: true,
            onlyDisplayDefault: true
          }
        });
        if (uploadsModel.getDefaultImageUrl) {
          component.addAttributes({
            src: uploadsModel.getDefaultImageUrl('/-/scale_crop/200x200/smart/')
          });
        }
      }
    }),
    willDestroyElement() {
      if (!this?.isDestroyed) {
        this._super(...arguments);
        this.destroyEditor();
      }
    },
    destroyEditor() {
      const storagePrefix = this.storagePrefix;
      this.grapesjs.removeFromStorage(storagePrefix);
      if (this.editor) {
        try {
          // Remove editor from the global scope
          grapesjs.editors.clear();
          // remove current editor
          // this.editor.destroy()
          this.set('editor', null);
        } catch (e) {
          console.error(e);
        }
      }
    },
    disablePanel: Ember.computed('model._data.hasTemplate', 'isReadonly', function () {
      if (this.isReadonly) {
        return true;
      } else {
        return !this.model._data.hasTemplate;
      }
    }),
    disableIfEmptyTemplate: Ember.computed('model._data.hasTemplate', 'pageDataString', function () {
      const hasTemplate = !!this.model.getData('hasTemplate');
      // if the pageDataString lengh is less than 130 then is an empty template
      const isEmptyTemplate = this.model.getData('pageDataString').length <= 130;
      return hasTemplate && isEmptyTemplate;
    }),
    hasChanged: Ember.computed('model._data.modified', function () {
      const modified = this.model.getData('modified') || [];
      return modified.length >= 1;
    }),
    parent: Ember.computed('model.{synced,_data.parent}', function () {
      const lastParentKey = this.get('model._data.parent') || '';
      if (lastParentKey) {
        const pagesCollection = this.pagesCollection || [];
        const pages = pagesCollection.getData('pages');
        const parent = R.find(R.pathEq(['_data', '_key'], lastParentKey), pages);
        if (parent) {
          return parent;
        }
      }
      return false;
    }),
    isCancellable: Ember.computed('model._data.isHome', 'isNew', function () {
      const model = this.model;
      if (model.getData('isHome') && this.isNew) {
        return false;
      }
      return true;
    }),
    isRemovable: Ember.computed('model.{parent,_data.isHome}', 'isEditing', 'pagesCollection.synced', function () {
      const model = this.model;
      if (this.isNew || !this.pagesCollection) {
        return false;
      }
      const pagesCollection = this.pagesCollection;
      const originalModel = pagesCollection.getCurrentPage(model);
      if (!originalModel) {
        return true;
      }
      if (originalModel.getData('isHome') || originalModel.get('isParent')) {
        return false;
      }
      return true;
    }),
    pageMenuModalLabel: Ember.computed('model._data.{pageName,name}', function () {
      const model = R.pathOr({}, ['model', '_data'])(this);
      const pageName = R.prop('pageName')(model);
      if (model.isHome) {
        return pageName || this.intl.t('home page');
      }
      if (model.isDynamicPage) {
        return pageName || this.intl.t(model.name);
      }
      return R.propOr('no-name', 'name')(model);
    }),
    _disableAllSubs(model) {
      const subs = model.sub || [];
      model.set('disabled', true);
      R.forEach(this._disableAllSub)(subs);
    },
    showWebBuilder: Ember.computed('model.{allowWebBuilder,_data.hasTemplate}', function () {
      if (!this?.model?.allowWebBuilder) {
        return false;
      }
      if (this.get('model._data.hasTemplate')) {
        return true;
      }
      return false;
    }),
    hasWebBuilderEditing: Ember.computed('isEditing', 'isNew', 'model._data.hasTemplate', function () {
      if (this.isEditing || this.isNew) {
        if (this.get('model._data.hasTemplate')) {
          return true;
        }
      }
      return false;
    }),
    submitTaskAsTemplate: (0, _emberConcurrency.task)(function* ({
      isPublicTemplate = false,
      onAfter
    }) {
      const msg = this.intl.t('are you sure you want to save as template');
      const confirm = window.confirm(msg);
      if (confirm) {
        this.submitTask.perform(...arguments);
      }
    }),
    submitWebsiteTask: (0, _emberConcurrency.task)(function* ({
      isPublicTemplate = false,
      onAfter
    }) {
      const website = this.websiteDirty;
      yield this.crud.updateRecordTask.perform({
        adapterName: 'websites',
        model: website
      });
      yield this.submitTask.perform(...arguments);
    }).drop(),
    submitTask: (0, _emberConcurrency.task)(function* ({
      model,
      isPublicTemplate = false,
      isPreview = false,
      isLockedPreview,
      onAfter
    } = {}) {
      model = model || this.model;
      const editor = this.editor;
      if (editor) {
        this.removeUnUsedCssClassNames(editor);

        // REMOVE DANGLING IMAGES
        try {
          const editorComponents = editor?.Components?.getComponent();
          if (editorComponents) {
            const fullWidthImageComponents = editorComponents.findType('full-width-image') || [];
            const optimizedImageComponents = editorComponents.findType('optimized-image') || [];
            R.pipe(R.concat(optimizedImageComponents), R.filter(img => {
              // Remove all base64 images
              const imgElement = img.getEl();
              const src = imgElement.getAttribute('src');
              const isBase64Image = src.includes('base64');
              if (isBase64Image) {
                imgElement.setAttribute('src', '');
              }
              if (!imgElement) {
                return false;
              }
              const removeNow = imgElement.getAttribute('data-remove-now');
              if (removeNow === 'true') {
                return true;
              }
              return false;
            }), R.forEach(img => img.remove()))(fullWidthImageComponents);
          }
        } catch (error) {
          console.error('==== ERROR: WHILE REMOVING DANGLING IMAGES FROM TEMPLATE', error);
        }
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          editor.selectToggle(selectedComponent);
        }
        editor.store();
        const wrapper = editor.DomComponents.getWrapper();
        const imagesOnCanvas = yield this.getImagesOnCanvas(wrapper);
        let productsOnCanvas = [];
        wrapper.findType('bigordr-product').forEach(model => {
          const element = model?.view?.$el;
          if (!element) {
            return;
          }
          const products = element.find('[data-bigordr-product]');
          R.forEach(component => {
            const itemKey = (0, _jquery.default)(component).attr('data-bigordr-product');
            productsOnCanvas.pushObject(itemKey);
            return itemKey;
          })(products);
        });
        productsOnCanvas = R.uniq(productsOnCanvas);
        model.setData('products', productsOnCanvas);
        let flowForms = [];
        const bigordrForm = wrapper.findType('bigordr-form');
        R.forEach(model => {
          const attributes = model.getAttributes();
          const formKey = attributes['data-gjs-form-key'];
          flowForms.pushObject(formKey);
        })(bigordrForm);
        flowForms = R.uniq(flowForms);
        model.setData('flowForms', flowForms);
        const storagePrefix = this.storagePrefix;
        const storage = yield this.grapesjs.getPageDataStringFromStorage(editor, storagePrefix);
        const {
          html,
          css,
          pageDataString
        } = storage;
        model.setData('templateImages', imagesOnCanvas);
        model.setData('pageDataString', pageDataString);
        model.setData('html', html);
        model.setData('css', css);
        model.setData('isPublicTemplate', isPublicTemplate);

        // if (!this.isNew && nventor.isNilOrEmpty(html)) {
        //   model.setData('hasTemplate', false)
        // }
        // if (!this.isNew) {
        //   model.setData('preSave', false)
        // }

        /* Add "Theme" to public template */
        if (isPublicTemplate) {
          const websiteData = this.website._data;
          const theme = R.pipe(R.toPairs, R.filter(R.test(/^theme|^swatches/)), R.fromPairs)(websiteData);
          model.setData('theme', theme);
        }
      }
      const channelsShopAdapterName = R.prop('channelsShopAdapterName')(this);
      if (channelsShopAdapterName) {
        model.set('adapterName', channelsShopAdapterName);
      }
      let saved;
      if (isPreview) {
        const dataTabContainer = this.dataTabContainer;
        if (dataTabContainer === 'campaigns') {
          // this one using crud mixin replaceRecordTask
          saved = yield this.onSaveTask(model, {
            onAfter
          });
        }
        if (dataTabContainer === 'websites') {
          // this one using crud mixin updateRecordTask
          saved = yield this.onSaveTask(model, {
            onAfter
          });
        }
        if (isLockedPreview) {
          window.open(this.previewLockedLink);
        } else {
          window.open(this.previewLink);
        }
      } else {
        saved = yield this.onSaveTask(model);
      }
      const body = (0, _jquery.default)('html, body');
      const gjsRowOffset = (0, _jquery.default)(this.element).find('.gjs-row');
      if (gjsRowOffset.offset()) {
        const scrollTo = gjsRowOffset.offset().top - 30;
        body.stop().animate({
          scrollTop: scrollTo
        }, 500, 'swing');
      }
      if (isPublicTemplate) {
        return;
      }
      return saved;
    }).drop(),
    fetchPageDataTask: (0, _emberConcurrency.task)(function* () {
      const pageKey = this.get('model._data._key');
      const version = this.get('model._data.version');
      let pageData = {};
      const podKey = this.settings.getPodKey();
      if (podKey && pageKey && version) {
        try {
          const response = yield this.server.call('GET', `api/protected/websites/pages/${podKey}/${pageKey}/${version}`);
          pageData = R.propOr('', 'data', response);
        } catch (e) {
          //
        }
        this.set('pageData', pageData);
      }
      return pageData;
    }),
    onSubmitAfterPreview: (0, _emberConcurrency.task)(function* (onAfterShowSavedLabel, onCloseDialogAction, isLockedPreview = false) {
      const isReadonly = this.isReadonly;
      if (!isReadonly) {
        yield this.submitTask.perform({
          isPreview: true,
          isLockedPreview,
          onAfter: onAfterShowSavedLabel
        });
        onCloseDialogAction();
      }
      if (isReadonly) {
        if (isLockedPreview) {
          window.open(this.previewLockedLink);
        } else {
          window.open(this.previewLink);
        }
      }
    }),
    disableChangeLinkType: Ember.computed('isReadonly', 'model._data.linkToItemKey', function () {
      if (this.get('model._data.linkToItemKey')) {
        return true;
      }
      return this.isReadonly;
    }),
    addBigOrdrStoryOnTop() {
      const editor = this.editor;
      const canvasComponent = editor.getComponents();
      const hasBigOComponent = canvasComponent.find(models => models.get('type') === 'bigordr-stories');
      if (!hasBigOComponent) {
        const canvasComponent = editor.getWrapper();
        canvasComponent.append({
          type: 'bigordr-stories'
        }, {
          at: 0
        });
        this.model.setData('showBigOrdrStory', true);
        return;
      }
      hasBigOComponent.remove();
      this.model.setData('showBigOrdrStory', false);
    },
    validateDisplayProductsInShop() {
      const displayAdvancedTemplateInShop = R.pathOr(false, ['model', '_data', 'displayInShop'])(this);
      if (!displayAdvancedTemplateInShop) {
        this.model.setData('displayProductsInShop', true);
      }
    },
    async getImagesOnCanvas(wrapper) {
      try {
        const domElement = wrapper?.getEl();
        if (!domElement) {
          return [];
        }
      } catch (error) {
        return [];
      }
      let images = this.model.getData('templateImages');
      images = R.map(image => {
        if (image._data) {
          return image;
        }
        return Ember.Object.create({
          _data: image
        });
      })(images);
      const resource = this.resource;
      const resourceKey = this.resourceKey;
      const wrapperImages = RA.ensureArray(wrapper.find('img'));
      const wrapperPictureSource = RA.ensureArray(wrapper.find('source'));
      const allImageTypes = R.concat(wrapperImages, wrapperPictureSource);
      const getUploadcareUuid = R.pipe(R.split(/https:\/\/ucarecdn\.com\//), R.pathOr('', [1]), R.split('/'), R.pathOr('', [0]));
      const imagesOnCanvas = R.pipe(R.map(gjsComponent => {
        /** @type {HTMLImageElement|HTMLSourceElement} */
        const gjsDomElement = gjsComponent.getEl();
        const src = gjsDomElement.getAttribute('src') || gjsDomElement.getAttribute('srcset') || '';
        const isUploadcare = R.startsWith('https://ucarecdn.com')(src);
        if (!src || !isUploadcare) {
          return null;
        }
        const gjsComponentUuid = getUploadcareUuid(src);
        const gjsDomElementWidth = Math.round(gjsDomElement.width);
        const gjsDomElementHeight = Math.round(gjsDomElement.height);
        gjsComponent.addAttributes({
          'data-width': gjsDomElementWidth,
          'data-height': gjsDomElementHeight,
          'data-blink-uuid': gjsComponentUuid
        });
        const mimeType = R.pipe(R.find(R.pathEq(['_data', 'uuid'], gjsComponentUuid)), R.pathOr('', ['_data', 'mimeType']))(images);
        const imageData = Ember.Object.create({
          _data: {
            resource,
            resourceKey,
            mimeType,
            uuid: gjsComponentUuid,
            width: gjsDomElementWidth,
            height: gjsDomElementHeight
          }
        });
        return imageData;
      }), R.reject(RA.isNilOrEmpty))(allImageTypes);

      // GET IMAGES FROM CSS BACKGROUND
      const storagePrefix = this.storagePrefix;
      const data = await this.grapesjs.getPageDataStringFromStorage(this.editor, this.storagePrefix);
      const {
        css
      } = data;
      let cssImages = [];
      if (css) {
        cssImages = R.pipe(R.split(/;/gm), R.filter(R.includes('https://ucarecdn.com')), R.map(getUploadcareUuid), R.map(uuid => {
          return Ember.Object.create({
            _data: {
              resource,
              resourceKey,
              uuid,
              mimeType: '',
              width: '',
              height: ''
            }
          });
        }))(css);
      }
      const uniqImages = R.pipe(R.concat(cssImages), R.uniqBy(R.path(['_data', 'uuid'])))(imagesOnCanvas);
      return uniqImages;
    },
    removeUnUsedCssClassNames(editor) {
      if (!editor) {
        return;
      }
      try {
        const wrapperComponent = editor.Components.getComponent();
        const optimizeTypes = ['optimized-image', 'full-width-image', 'text', 'cell', 'bigordr-flex-cell'];
        const allCssSelectors = editor.Css.getAll().map(m => m.getSelectorsString()) || [];
        optimizeTypes.forEach(type => {
          const typeModels = wrapperComponent.findType(type) || [];
          typeModels.forEach(typeModel => {
            const currentClassNames = typeModel.getClasses() || [];
            currentClassNames.forEach(currentValue => {
              const isClassNameBeenUsed = allCssSelectors.some(s => s.includes(currentValue));
              const isDynamicClassName = R.test(/^(id-i|i)[a-z\d]{3,10}$/gm)(currentValue);
              if (!isClassNameBeenUsed && isDynamicClassName) {
                typeModel.removeClass(currentValue);
                if (_environment.default.environment !== 'production') {
                  console.log(`removeUnUsedCssClassNames::${type}::removeClass: `, currentValue);
                }
              }
            });
          });
        });
      } catch (error) {
        console.error('ERROR WHILE REMOVING UNUSED CSS CLASS NAMES', error);
      }
    },
    showSearchImagesModal(previousImage, allowSrcSet = true, onAddImage = () => null, index) {
      this.set('showImagesModal', true);
      this.set('showImagesModalFromSliderModal', true);
      this.set('previousImage', previousImage);
      this.set('allowSrcSet', allowSrcSet);
      this.set('onAddImage', onAddImage);
      this.set('onAddImageIndex', index);
    },
    addImage(onCloseDialogAction, options, uploadsModel, data) {
      if (this.showImagesModalFromSliderModal) {
        this.onAddImage(uploadsModel, this.onAddImageIndex);
      } else {
        const imagesManager = this.imagesManager;
        const imgType = this.imgType;
        const imageUrl = uploadsModel.getUrl();
        imagesManager.onImageSelect(imgType, imageUrl, uploadsModel.getData('mimeType'), uploadsModel, {
          editor: this.editor
        });
        const model = this.model;
        const images = model.getData('templateImages') || [];
        images.pushObject(uploadsModel);
        model.setData('templateImages', images);
      }
    },
    actions: {
      edit() {
        // scroll to top)
        _nventor.default.goToTop(30);
        this.onEdit(...arguments);
      },
      cancel() {
        // nventor.goToTop(30)
        this.onCancel(...arguments);
      },
      cancelFullscreen() {
        let confirm = true;
        if (this.grapesJsFullscreen) {
          const msg = this.intl.t('are you sure you want to cancel?');
          confirm = window.confirm(msg);
        }
        if (confirm) {
          this.onCancel(...arguments);
        }
      },
      setupEditor(setupOnInitToggle) {
        if (setupOnInitToggle) {
          this.setup(setupOnInitToggle);
        } else {
          this.set('grapesJsFullscreen', false);
        }
      },
      resetUrl() {
        const model = this.model;
        model.setData('url', '');
        model.setData('linkToItemKey', '');
      },
      addLinkToProduct(onCloseDialogAction, item) {
        const model = this.model;
        model.setData('linkToItemKey', item.getData('itemKey'));
        const url = `item/${item._data.itemKey}/${item._data.item}`;
        model.setData('url', url);
        onCloseDialogAction();
      },
      removeLinkToProducts() {
        const model = this.model;
        model.setData('linkToItemKey', '');
        model.setData('url', '');
      },
      autoSetUrl() {
        const model = this.model;
        const url = model.getData('url');
        if (!url) {
          const name = model.getData('name');
          let newUrl = R.pipe(R.trim, R.toLower, R.replace(/\\/g, ''), R.replace(/\s/g, '-'))(name);
          let parentUrl = model.getData('parentUrl');
          if (!parentUrl) {
            parentUrl = model.getData('parentName');
          }
          if (parentUrl && newUrl) {
            newUrl = `${parentUrl}-${newUrl}`;
          }
          model.setData('url', newUrl);
        }
      },
      autoFixUrl() {
        const model = this.model;
        const url = model.getData('url');
        if (url) {
          const newUrl = R.pipe(R.trim, R.replace(/\\/g, ''), R.replace(/\s/g, '-'))(url);
          model.setData('url', newUrl);
        }
      },
      setParent(onCloseDialogAction, parent) {
        const model = this.model;
        model.setData('parent', parent.getData('_key'));
        const parentDepth = parseInt(parent.getData('depth')) || 0;
        const currentDepth = parentDepth + 1;
        model.setData('depth', currentDepth);
        model.setData('parentUrl', parent.getData('url') || '');
        model.setData('parentName', parent.getData('name') || '');
        this.set('parent', parent);
        onCloseDialogAction();
      },
      unsetParent() {
        const model = this.model;
        const pagesCollection = this.pagesCollection;
        const index = pagesCollection.get('nextIndex');
        model.setData('parent', '');
        model.setData('index', index);
        model.setData('depth', 0);
        model.setData('parentUrl', '');
        model.setData('parentName', '');
        this.set('parent', '');
      },
      cancelAddImages(onCloseDialogAction) {
        onCloseDialogAction();
      },
      addProduct(onCloseDialogAction, product) {
        const defaultImageUrl = product.getDefaultImageUrl('/-/scale_crop/200x200/');
        const productsViewComponent = this.productsViewComponent;
        productsViewComponent.set('defaultImage', defaultImageUrl);
        productsViewComponent.set('itemKey', product.getData('itemKey'));
        const model = this.model;
        const products = model.getData('products') || [];
        products.pushObject(product.getData('itemKey'));
        model.setData('products', products);
        onCloseDialogAction();
      },
      clearTemplate() {
        const msg = this.intl.t('are you sure you want to clear page');
        if (window.confirm(msg)) {
          const editor = this.editor;
          const grapesJsService = this.grapesjs;
          grapesJsService.clearPage(editor, this.website);
        }
      },
      replaceTemplate(pageData, templateImages = []) {
        const model = this.model;
        const website = this.website;
        model.replaceTemplate('', templateImages);
        const editor = this.editor;
        const grapesJsService = this.grapesjs;
        grapesJsService.setTemplate(editor, {
          pageData,
          website
        });
      },
      async insertTemplate(toInsertPageData, templateImages = []) {
        // REMOVE IMPORTED THEMES
        toInsertPageData.styles = R.pipe(R.propOr([], 'styles'), R.reject(R.propEq('selectorsAdd', ':root')))(toInsertPageData);
        const model = this.model;
        const website = this.website;
        model.insertTemplate('', templateImages);
        const editor = this.editor;
        const storagePrefix = this.storagePrefix;
        const grapesJsService = this.grapesjs;
        const currentPageData = await grapesJsService.getPageDataFromStorage(editor, storagePrefix);
        const newPageData = {
          html: '',
          css: '',
          styles: [],
          components: []
        };
        newPageData.html = R.concat(R.propOr('', 'html', currentPageData), R.propOr('', 'html', toInsertPageData));
        newPageData.css = R.propOr('', 'css', currentPageData);
        newPageData.styles = R.concat(R.propOr('', 'styles', currentPageData), R.propOr('', 'styles', toInsertPageData));
        newPageData.components = R.concat(R.propOr('', 'components', currentPageData), R.propOr('', 'components', toInsertPageData));
        grapesJsService.setTemplate(editor, {
          pageData: newPageData,
          website
        });
      },
      selectWebsitePageType() {
        const model = this.model;
        model.onSelectType();
      },
      toggleShowButtons(model, type) {
        if (type === 'page') {
          model.setData('hasTemplate', false);
          model.setData('type', 'page');
        } else {
          model.setData('type', 'link');
        }
        this.set('showButtons', !this.showButtons);
      },
      toggleFullscreen() {
        this.toggleProperty('grapesJsFullscreen');
        this.setIsFullscreen(this.grapesJsFullscreen);
        this.editor.refresh();
      },
      showLayerContainer() {
        const layerManager = document.querySelector('.layer-manager');
        const styleManager = document.querySelector('.style-manager');
        if (window.getComputedStyle(layerManager).display === 'none') {
          layerManager.style.display = 'block';
          styleManager.style.display = 'none';
          this.set('showLayerManager', true);
        } else {
          layerManager.style.display = 'none';
          styleManager.style.display = 'block';
          this.set('showLayerManager', false);
        }
      },
      onDidInsertBtnWithModal(component) {
        if (this.isNew) {
          component.toggleOnModalDialog();
        }
      },
      cancelSettingsModal(onCloseDialogAction, onCancel) {
        onCloseDialogAction();
        if (this.isNew) {
          onCancel();
        }
      },
      onAfter(onCloseDialogAction) {
        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      },
      onAfterShowSavedLabel() {
        this.set('showHasSavedLabel', true);
        this.setIsFullscreen();
        setTimeout(() => {
          if (this?.isDestroyed === false) {
            this.set('showHasSavedLabel', false);
          }
        }, 5000);
      },
      setupDirtyWebsite(website) {
        const dirty = this.crud.setupDirty({
          adapterName: 'websites',
          model: website
        });
        this.set('websiteDirty', dirty);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "addBigOrdrStoryOnTop", [_dec], Object.getOwnPropertyDescriptor(_obj, "addBigOrdrStoryOnTop"), _obj), _applyDecoratedDescriptor(_obj, "validateDisplayProductsInShop", [_dec2], Object.getOwnPropertyDescriptor(_obj, "validateDisplayProductsInShop"), _obj), _applyDecoratedDescriptor(_obj, "getImagesOnCanvas", [_dec3], Object.getOwnPropertyDescriptor(_obj, "getImagesOnCanvas"), _obj), _applyDecoratedDescriptor(_obj, "removeUnUsedCssClassNames", [_dec4], Object.getOwnPropertyDescriptor(_obj, "removeUnUsedCssClassNames"), _obj), _applyDecoratedDescriptor(_obj, "showSearchImagesModal", [_dec5], Object.getOwnPropertyDescriptor(_obj, "showSearchImagesModal"), _obj), _applyDecoratedDescriptor(_obj, "addImage", [_dec6], Object.getOwnPropertyDescriptor(_obj, "addImage"), _obj), _obj));
});