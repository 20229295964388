define("client/pods/components/elements/element-textareapvt/component", ["exports", "jquery", "ramda", "ramda-adjunct"], function (_exports, _jquery, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextArea.extend({
    autoResize: true,
    translate: true,
    attributeBindings: ['placeholder', 'disabled'],
    classNames: ['textarea', 'is-small'],
    autofocus: false,
    placeholder: '',
    rows: 1,
    maxRows: 5,
    onInput: null,
    // action handler for keyUp
    didInsertElement() {
      this._super(...arguments);
      const autoResize = this.autoResize;
      if (autoResize) {
        this.doAutoResize();
      }
      document.querySelector(`#${this.elementId}`).placeholder = R.replace(/\\n/gm, '\n')(this.placeholder);
    },
    /**
     * input event:
     * similar to keyUp except only triggers if input value changes
     * (unsupported in IE < 9)
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    input(e) {
      if (this.onInput) {
        this.onInput(e);
      }
    },
    resize(numberOfRows) {
      if ((0, _jquery.default)(this.element)) {
        (0, _jquery.default)(this.element).attr('rows', numberOfRows);
      }
    },
    /**
     * observes value and resizes textarea
     */
    checkResize: Ember.observer('value', function () {
      window.setTimeout(this.doAutoResize(), 0);
    }),
    doAutoResize() {
      const autoResize = this.autoResize;
      if (autoResize) {
        // @TODO: DOESNT WORK CORRECTLY
        // line breaks work fine when line exceeds textarea length it alternates between correct/incorrect scrollHeight (no idea why)
        const value = this.value;
        if (RA.isNotNilOrEmpty(value)) {
          // console.log('..resizing..')
          // check how many line breaks
          const numberOfBreaks = R.pipe(R.tryCatch(R.split(/\r\n|\r|\n/gm), R.always('1')), R.length)(value);

          // check what the actual content rows are
          const rowHeight = (0, _jquery.default)(this.element).height();
          // var innerHeight = self.$().innerHeight();
          let scrollHeight = (0, _jquery.default)(this.element)[0].scrollHeight;
          if (scrollHeight < rowHeight) {
            scrollHeight = rowHeight;
          }

          // console.log('scrollHeight')
          // console.log(scrollHeight)
          // console.log('rowHeight')
          // console.log(rowHeight)

          const contentRows = scrollHeight / rowHeight;

          // console.log(numberOfBreaks)
          // console.log(contentRows)
          let numberOfRows = numberOfBreaks;
          if (contentRows > numberOfBreaks) {
            numberOfRows = contentRows;
          }

          // check max rows (not tested)
          const min = this.rows;
          const max = this.maxRows;
          if (max && numberOfRows > max) {
            // dont resize: number of rows > max
          } else if (numberOfRows < min) {
            // dont resize: number of rows < min
          } else {
            // resize
            this.resize(numberOfRows);
          }
        } else {
          this.resize(this.rows);
        }
      }
    }
  });
});