define("client/pods/components/grapesjs/schedules-modal/schedules-dialog/schedules-row/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GrapesjsSchedulesModalSchedulesDialogSchedulesRowComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _class = class GrapesjsSchedulesModalSchedulesDialogSchedulesRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "date", _descriptor2, this);
      _initializerDefineProperty(this, "constants", _descriptor3, this);
      const schedule = this.args.schedule;
      const dateStartZ = schedule.getData('dateStartZ');
      if (_ramdaAdjunct.default.isNotNilOrEmpty(dateStartZ)) {
        let startHours = this.date.getMoment(dateStartZ).hours();
        if (parseInt(startHours) < 10) {
          startHours = `0${startHours}`;
        }
        let startMinutes = this.date.getMoment(dateStartZ).minutes();
        if (parseInt(startMinutes) < 10) {
          startMinutes = `0${startMinutes}`;
        }
        Ember.set(schedule, 'startHours', startHours);
        Ember.set(schedule, 'startMinutes', startMinutes);
      }
      const dateEndZ = schedule.getData('dateEndZ');
      if (_ramdaAdjunct.default.isNotNilOrEmpty(dateEndZ)) {
        let endHours = this.date.getMoment(dateEndZ).hours();
        if (parseInt(endHours) < 10) {
          endHours = `0${endHours}`;
        }
        let endMinutes = this.date.getMoment(dateEndZ).minutes();
        if (parseInt(endMinutes) < 10) {
          endMinutes = `0${endMinutes}`;
        }
        Ember.set(schedule, 'endHours', endHours);
        Ember.set(schedule, 'endMinutes', endMinutes);
      }
    }
    get statusClass() {
      const schedule = this.args.schedule;
      const status = schedule.getData('status');
      if (status === this.constants.status.active) {
        return 'is-primary';
      }
      return 'is-danger';
    }
    setDateTime(args) {
      const {
        schedule,
        type
      } = args;
      let hoursProperty;
      let minutesProperty;
      let dateProperty;
      if (type === 'dateStartZ') {
        hoursProperty = 'startHours';
        minutesProperty = 'startMinutes';
        dateProperty = 'dateStart';
      }
      if (type === 'dateEndZ') {
        hoursProperty = 'endHours';
        minutesProperty = 'endMinutes';
        dateProperty = 'dateEnd';
      }
      let dateZ = schedule.getData(type);
      if (_ramdaAdjunct.default.isNotNilOrEmpty(dateZ)) {
        let hours = schedule[hoursProperty] || 0;
        let minutes = schedule[minutesProperty] || 0;
        if (parseInt(hours) > 23) {
          hours = 23;
          Ember.set(schedule, hoursProperty, '23');
        }
        if (parseInt(hours) < 0) {
          hours = 0;
          Ember.set(schedule, hoursProperty, '00');
        }
        if (parseInt(minutes) > 59) {
          minutes = 59;
          Ember.set(schedule, minutesProperty, '59');
        }
        if (parseInt(minutes) < 0) {
          minutes = 0;
          Ember.set(schedule, minutesProperty, '00');
        }
        dateZ = this.date.getMoment(dateZ).hours(hours).minutes(minutes).toISOString();
        schedule.setData(type, dateZ);
        const date = this.date.formatDate(dateZ, 'YYYY-MM-DD');
        Ember.set(schedule, dateProperty, date);
      }
    }
    onSubmitRemoveSchedule(schedule) {
      const model = this.args.model;
      const schedules = model.getData('schedules');
      schedules.removeObject(schedule);
      this.args.checkOverlapDate(model, schedules);
    }
    onChangeSetActivation() {
      const schedule = this.args.schedule;
      this.setDateTime({
        schedule,
        type: 'dateStartZ'
      });
      this.setDateTime({
        schedule,
        type: 'dateEndZ'
      });
      const model = this.args.model;
      const schedules = model.getData('schedules');
      this.args.checkOverlapDate(model, schedules);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSetActivation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSetActivation"), _class.prototype), _class);
});