define("client/pods/extensions/apps/tw/e-invoices/tracks/numbers/current-number/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    prefix: Ember.computed('_data.prefix', function () {
      return this._data.prefix;
    }),
    index: Ember.computed('_data.index', function () {
      return this._data.index;
    }),
    startNumber: Ember.computed('_data.startNumber', function () {
      return this._data.startNumber;
    }),
    endNumber: Ember.computed('_data.endNumber', function () {
      return this._data.endNumber;
    }),
    currentNumber: Ember.computed('_data.currentNumber', function () {
      return this._data.currentNumber;
    }),
    nextNumber: Ember.computed('_data.currentNumber', function () {
      const currentNumber = this._data.currentNumber || 0;
      return currentNumber + 1;
    }),
    nextNumberWithPrefix: Ember.computed('_data.currentNumber', '_data.prefix', function () {
      const currentNumber = this._data.currentNumber || 0;
      const nextNumber = currentNumber + 1;
      return `${this.prefix}${nextNumber}`;
    }),
    currentNumberWithPrefix: Ember.computed('_data.current', '_data.prefix', function () {
      return `${this.prefix}${this.currentNumber}`;
    }),
    remainingCount: Ember.computed('currentNumber', 'endNumber', function () {
      const remainingCount = Math.max(0, this.endNumber - this.currentNumber);
      if (remainingCount) {
        return this.endNumber - this.currentNumber;
      }
      return 0;
    })
  });
});