define("client/pods/periods/years/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/mixins/date", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _date, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, _date.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      // @TODO: should set more years later/or custom year
      const pastYears = this.getPastYears({
        number: 5,
        futureYearsNumber: 1
      });
      const data = [];
      const intl = this.intl;
      pastYears.forEach(period => {
        const item = {
          value: period.yyyy,
          label: period.yyyy
        };
        data.pushObject(item);
      });
      return _nventor.default.resolve(data);
    }
  });
});