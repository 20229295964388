define("client/pods/components/payments/linepay/panels-display/component", ["exports", "client/mixins/crud", "client/mixins/payments-list-by", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _crud, _paymentsListBy, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _paymentsListBy.default, (_dec = Ember._action, _dec2 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),
    exportService: Ember.inject.service('export'),
    excel: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    adapterName: 'payments/linepay',
    isExporting: false,
    init() {
      this._super(...arguments);
      const period = this.get('tab.period');
      this.set('period', period);
      const nowZ = this.dateService.getMoment();
      const monthNow = nowZ.month() + 1;
      const yearNow = nowZ.year();
      this.set('mm', monthNow);
      this.set('yyyy', yearNow);
      const filters = this.filters;
      const {
        dateStart,
        dateEnd
      } = period;
      const paymentCreditCardStatus = [this.get('constants.paymentCreditCardStatus.approved'), this.get('constants.paymentCreditCardStatus.refundRequested'), this.get('constants.paymentCreditCardStatus.refunded'), this.get('constants.paymentCreditCardStatus.paidOut')];
      this.listByTask.perform({
        adapterName: 'payments/linepay',
        filters,
        dateStart,
        dateEnd,
        paymentCreditCardStatus
      });
      if (!this.query) {
        this.fetchStatementTask.perform(period);
      }
      const self = this;
      const paymentSpecs = {
        description: 'cc_payment',
        labelValue: 'CreditCardPayment',
        method: 'byDetails',
        index: 0,
        subMethod: '',
        paymentMethod: 'all',
        specs: [[{
          value: 'creator.md',
          prop: 'creator.md',
          label: this.intl.t('date'),
          transforms: [{
            label: 'getPropOr',
            fn: 'getPropOr',
            options: {
              propOr: 'paymentCreditCardTransactionTimestampZ'
            },
            type: ''
          }, {
            type: 'date',
            label: 'Date',
            fn: '',
            options: ''
          }],
          hideDefault: false,
          type: "date",
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'contact',
          prop: 'contact',
          label: this.intl.t('contact'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'email',
          prop: 'email',
          label: this.intl.t('email'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'docNo',
          prop: 'docNo',
          label: '#',
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'ref',
          prop: 'ref',
          label: this.intl.t('ref'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'externalId',
          prop: 'externalId',
          label: this.intl.t('cc_ref'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'inclTotal',
          prop: 'inclTotal',
          label: this.intl.t('subtotal'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'refundRequestedInclTotal',
          prop: 'refundRequestedInclTotal',
          label: this.intl.t('cc_refundRequested'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'refundedInclTotal',
          prop: 'refundedInclTotal',
          label: this.intl.t('cc_refunded'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'netInclTotal',
          prop: 'netInclTotal',
          label: this.intl.t('inclusive total'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'paymentCreditCardProcessingFee',
          prop: 'paymentCreditCardProcessingFee',
          label: this.intl.t('fees'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'campaign.name',
          prop: 'campaign.name',
          label: this.intl.t('shop'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'paymentCreditCardProvider',
          prop: 'paymentCreditCardProvider',
          label: '',
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'paymentCreditCardLastFourDigits',
          prop: 'paymentCreditCardLastFourDigits',
          label: this.intl.t('cc_partial_card_number'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'dispatchProcessLabel',
          prop: 'dispatchProcessLabel',
          label: this.intl.t('dispatch status'),
          transforms: [],
          hideDefault: false,
          translateFn(propValue, eachDocData) {
            const status = R_.dotPath('status')(eachDocData);
            if (status !== 'final') {
              return self.intl.t(status);
            }
            let translate = true;
            const translateProp = 'dispatchProcessLabelTranslate';
            if (translateProp) {
              translate = R_.dotPath(translateProp)(eachDocData);
            }
            if (translate) {
              return self.intl.t(propValue);
            }
          },
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
          // }, {
          //   value: 'paymentCreditCardStatus',
          //   prop: 'paymentCreditCardStatus',
          //   label: this.intl.t('credit card status'),
          //   transforms: [],
          //   hideDefault: false,
          //   translateFn (propValue) {
          //     return self.intl.t(`cc_${propValue}`)
          //   },
          //   type: '',
          //   item: '',
          //   itemKey: '',
          //   isDetail: ''
        }]]
      };
      const specsModel = this.crud.setupNewRecord({
        adapterName: 'payments/linepay/specs',
        data: paymentSpecs
      });
      this.set('specs', specsModel);
    },
    fetchStatementTask: (0, _emberConcurrency.task)(function* (period) {
      const appendPath = this.selectedProvider;
      const response = yield this.server.call('GET', `api/protected/payments/linepay/statement/${appendPath}`, period);
      response.data.podKey = this?.settings?.settingsModel?._data?.podKey;
      this.set('statement', response.data);
      return response.data;
    }),
    requestCancellationTask: (0, _emberConcurrency.task)(function* (model) {
      const msg = this.intl.t('are you sure you want to cancel?');
      if (window.confirm(msg)) {
        const updated = yield this.updateRecordTask.perform({
          adapterName: this.adapterName,
          appendPath: '/refund/request',
          model
        });
        const period = this.period;
        this.fetchStatementTask.perform(period);
        return updated;
      }
    }),
    undoCancellationTask: (0, _emberConcurrency.task)(function* (model) {
      const msg = this.intl.t('are you sure you want to undo cancel?');
      if (window.confirm(msg)) {
        const updated = yield this.updateRecordTask.perform({
          adapterName: this.adapterName,
          appendPath: '/refund/undo',
          model
        });
        const period = this.period;
        this.fetchStatementTask.perform(period);
        return updated;
      }
    }),
    exportTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction /* filter */) {
      this.set('isExporting', true);
      let filename = this.filename;
      if (!filename) {
        filename = 'untitled';
      }
      const newSerializedCustomSpecs = [this?.specs?._data] || [];
      const worksheets = [];
      const filters = this.exportFilters;
      const {
        dateStart,
        dateEnd
      } = this.period;
      const paymentCreditCardStatus = [this.get('constants.paymentCreditCardStatus.approved'), this.get('constants.paymentCreditCardStatus.refundRequested'), this.get('constants.paymentCreditCardStatus.refunded'), this.get('constants.paymentCreditCardStatus.paidOut')];
      const results = yield this.listByTask.perform({
        adapterName: 'payments/linepay',
        appendPath: '/export',
        filters,
        dateStart,
        dateEnd,
        paymentCreditCardStatus
      });
      const serialized = R.pluck('_data', results) || [];
      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);
        if (method) {
          const customSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized
          });
          worksheets.push(customSheetToPush);
        }
      })(newSerializedCustomSpecs);
      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename
        });
        this.set('isExporting', false);
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
      onCloseDialogAction();
    }).drop(),
    setupFilename() {
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      const filename = `${this.intl.t('line pay transactions')} ${dateStart}-${dateEnd}`;
      this.set('filename', filename);
    },
    displayByMonth(period) {
      period.dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      period.dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.filters._data.dateEnd = period.dateEnd;
      this.filters._data.dateStart = period.dateStart;
      const updatePeriod = R.mergeRight(this.period, period);
      this.set('period', updatePeriod);
      const paymentCreditCardStatus = [this.get('constants.paymentCreditCardStatus.approved'), this.get('constants.paymentCreditCardStatus.refundRequested'), this.get('constants.paymentCreditCardStatus.refunded'), this.get('constants.paymentCreditCardStatus.paidOut')];
      this.listByTask.perform({
        adapterName: 'payments/linepay',
        filters: this.filters,
        dateStart: period.dateStart,
        dateEnd: period.dateEnd,
        paymentCreditCardStatus
      });
      this.fetchStatementTask.perform({
        dateStart: period.dateStart,
        dateEnd: period.dateEnd
      });
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
    }
  }, _applyDecoratedDescriptor(_obj, "setupFilename", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupFilename"), _obj), _applyDecoratedDescriptor(_obj, "displayByMonth", [_dec2], Object.getOwnPropertyDescriptor(_obj, "displayByMonth"), _obj), _obj));
});