define("client/pods/components/operator/change-logs/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AjTUMsmw",
    "block": "{\"symbols\":[\"@onEdit\",\"@onDash\",\"@onCancel\",\"@tab\",\"@onAfterUpdateRecordTask\"],\"statements\":[[5,\"operator/change-logs/change-logs-editor\",[],[[\"@adapterName\",\"@isRunning\",\"@model\",\"@isEditing\",\"@isNew\",\"@isDisplay\",\"@onEdit\",\"@onDash\",\"@onCancel\",\"@onSaveTask\",\"@tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[23,0,[\"model\"]],false,false,true,[23,1,[]],[23,2,[]],[23,3,[]],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"model\"]],[23,5,[]],[23,4,[]]]]]],null],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/operator/change-logs/panels-display/template.hbs"
    }
  });
});