define("client/pods/components/extensions/apps/tw/e-invoices/blank-upload-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FfsAl+Fc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"tag \",[23,0,[\"statusClassNames\"]]]]],[8],[0,\"\\n  \"],[1,[28,\"tt\",[[23,0,[\"statusLabel\"]]],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/e-invoices/blank-upload-status/template.hbs"
    }
  });
});