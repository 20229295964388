define("client/pods/components/websites/panels-display/sub-panels-footer-btns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nhBfG9bG",
    "block": "{\"symbols\":[\"@disabled\",\"@onEditPanel\",\"@errors\",\"@model\",\"@hasErrors\",\"@onDash\",\"@tab\",\"@hasEditBtn\"],\"statements\":[[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\"],[5,\"msgs/footer-msgs\",[],[[\"@errors\",\"@model\",\"@hasErrors\"],[[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\\n\\t\"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"elements/btn-back\",[],[[\"@onSubmit\",\"@tooltipLabel\"],[[23,6,[]],\"overview\"]]],[0,\"\\n\\n\\t\\t\"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n\\t\\t\"],[5,\"comments/comments-manager\",[],[[\"@componentName\",\"@isPreview\",\"@master\",\"@metaData\",\"@tab\",\"@type\"],[\"comments/comments-manager/modal-btn\",true,[23,4,[\"_data\",\"_key\"]],[28,\"hash\",null,[[\"campaign\",\"campaignKey\",\"name\"],[[23,4,[\"_data\",\"name\"]],[23,4,[\"_data\",\"_key\"]],[23,4,[\"_data\",\"name\"]]]]],[23,7,[]],\"websites\"]]],[0,\"\\n\\n\"],[4,\"if\",[[23,8,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n\\t\\t\\t\"],[5,\"elements/btn-edit\",[],[[\"@disabled\",\"@onSubmit\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-footer-btns/template.hbs"
    }
  });
});