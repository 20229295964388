define("client/pods/components/extensions/apps/tw/invoices/mixins/invoices-printer", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @TODO: move fetchInvoice and cancelInvoice out of this mixin,
  // those are for the outer components whereas the other functions are for inner components
  // outer component: makes use of 'invoices' (array of invoices for given masterKey)
  // inner component: only makes use of 1 invoice
  var _default = _exports.default = Ember.Mixin.create({
    printError: '',
    printAllInvoicesTask: (0, _emberConcurrency.task)(function* (invoices, number, dateZ) {
      // first save all invoices to db
      const payload = {
        batch: invoices,
        number: number,
        dateZ: dateZ
      };
      this.set('printError', '');
      try {
        yield this.getAdapter('extensions/apps/tw/invoices').createBatch({
          payload
        });
      } catch (err) {
        this.set('printError', err);
      }
    }).drop(),
    actions: {
      printInvoice(onCloseDialogAction, dialog) {
        onCloseDialogAction();
        const self = this;
        const number = dialog.customInvoiceNumber;
        const dateZ = dialog.customInvoiceDateZ;
        const document = self.get('document');
        const invoices = [document];
        self.get('printAllInvoicesTask').perform(invoices, number, dateZ);
      },
      printAllInvoices(onCloseDialogAction, dialog) {
        //   onCloseDialogAction()

        //   const self = this

        //   const number = dialog.customInvoiceNumber
        //   const dateZ = dialog.customInvoiceDateZ

        //   const invoices = []
        //   const invoicesToDispatchList = self.get('invoicesToDispatchList')

        //   invoicesToDispatchList.forEach(function (doc) {
        //     if (doc.getData('autoPrintInvoices') === self.get('constants.invoicing.on')) {
        //       const master = doc.getData('_key')
        //       if (self.get('dataManager').getFromCache(`tw_invoices_${master}`)) {
        //         invoices.push(doc)
        //       }
        //     }
        //   })

        //   self.get('printAllInvoicesTask').perform(invoices, number, dateZ)
      }
    }
  });
});