define("client/pods/components/elements/steps-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "feM6WSVE",
    "block": "{\"symbols\":[\"step\",\"index\",\"&default\"],\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"isRunning\"]]]],{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"steps-bar\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"customBar\"]]],null,{\"statements\":[[0,\"      \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"steps\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"elements/steps-bar/step-node\",null,[[\"disabled\",\"step\",\"index\",\"hasCounter\",\"selected\",\"stepValueParam\",\"stepLabelParam\",\"selectedIsEmptyWhen\",\"onSubmit\"],[[24,[\"disabled\"]],[23,1,[]],[23,2,[]],[24,[\"hasCounter\"]],[24,[\"selected\"]],[24,[\"stepValueParam\"]],[24,[\"stepLabelParam\"]],[24,[\"selectedIsEmptyWhen\"]],[24,[\"onSubmit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/steps-bar/template.hbs"
    }
  });
});