define("client/pods/components/guide-menu/guide-menu-nav/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "client/utils/nventor", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, R, RA, _nventor, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GuideMenuNavComponent extends _component.default {
    // @service tabsManager
    // @service('application') applicationService

    // @tracked hasInitiated = false
    // @tracked _element
    // @tracked _content
    // @tracked masterTab
    // @tracked onInitDeviceIsMobile
    // @tracked adjustWidth = null

    // constructor () {
    //   super(...arguments)

    //   this.masterTab = this.tabsManager.getMasterTab('left')

    //   this.onInitDeviceIsMobile = this.applicationService.isMobile
    // }

    // get showGuideMenu () {
    //   if (this.args.hide) {
    //     return false
    //   }

    //   if (this.hasInitiated) {
    //     // only one menu
    //     const guideMenuArray = this.guideMenuArray
    //     if (guideMenuArray.length === 0) {
    //       return false
    //     }

    //     // if (guideMenuArray.length === 1) {
    //     //   const menuSub = R.pathOr([], [0, 'sub'])(guideMenuArray)
    //     //   return menuSub.length > 1
    //     // }
    //   }

    //   return true
    // }

    // /**
    //  * To get the registered guide menu
    //  * @returns {Object}
    //  */
    // get currentGuideMenu () {
    //   const menuId = this.args?.formInitData?.menuId || ''
    //   const tab = this.args?.tab || {}

    //   if (menuId && tab?.get) {
    //     return tab.get(menuId) || {}
    //   }

    //   return {}
    // }

    // get modifiedFormInitData () {
    //   const formInitData = this.args.formInitData || {}
    //   formInitData.showGuideMenu = this.showGuideMenu
    //   return formInitData
    // }

    // /**
    //  * To get 'menu' {Array} in current guide menu
    //  * @returns {Array}
    //  */
    // get guideMenuArray () {
    //   return this.currentGuideMenu?.menu || []
    // }

    // /**
    //  * To get active menu tab of current guide menu, get the first menu tab by default
    //  * @returns {Object} current active menuTab or the first menuTab (default)
    //  */
    // get activeGuideMenuTab () {
    //   const allMenu = this.currentGuideMenu?.menu || []
    //   const menuTab = R.find(R.pathEq(['showActive'], true))(allMenu)
    //   if (!menuTab) {
    //     return allMenu.firstObject || {}
    //   }

    //   return menuTab
    // }

    // /**
    //  * To get the offset of tab header's bottom
    //  * @returns {String} the vertical position of header
    //  */
    // get offset () {
    //   const tabHeaderDefaultOffset = 164 // unit: px
    //   let headerBottom = this.args.headerBottom || tabHeaderDefaultOffset
    //   const parent = this.parentPanelGrid

    //   if (!this.args.onSetHeaderBottom) {
    //     headerBottom = this._setHeaderBottom(headerBottom, parent)
    //   }

    //   if (this.isModalContainer) {
    //     const modalHeader = parent.querySelector('.modal-dialog__header')
    //     if (modalHeader) {
    //       const offsetRect = modalHeader.getBoundingClientRect()
    //       headerBottom = offsetRect?.height || 0
    //     } else {
    //       headerBottom = 0
    //     }
    //   }

    //   if (headerBottom < 0) {
    //     return tabHeaderDefaultOffset
    //   }

    //   return headerBottom
    // }

    // _setHeaderBottom (headerBottom, parent) {
    //   if (RA.isNotNilOrEmpty(headerBottom)) {
    //     return headerBottom
    //   }

    //   const headerTitle = parent.querySelector('.panel-grid__header-title')
    //   if (headerTitle && !headerTitle.classList.contains('hidden')) {
    //     const titleRect = headerTitle?.getBoundingClientRect?.()
    //     const headerTitleDefaultOffset = 310 // unit: px

    //     return titleRect?.bottom || headerTitleDefaultOffset
    //   }

    //   const tabHeader = parent.querySelector('.panel-grid__header-with-guide-menu')
    //   const rectObj = tabHeader?.parentElement?.getBoundingClientRect?.()
    //   const tabHeaderDefaultOffset = 164 // unit: px

    //   return rectObj?.bottom || tabHeaderDefaultOffset
    // }

    // /**
    //  * Check if it's inside a modal
    //  * @returns {boolean}
    //  */
    // get isModalContainer () {
    //   const parent = this.parentPanelGrid
    //   if (parent.classList.contains('is-modal')) {
    //     return true
    //   }

    //   return false
    // }

    // /**
    //  * Should be inside class='panel-grid'
    //  * @returns {HTMLDivElement}
    //  */
    // get parentPanelGrid () {
    //   return this._element.closest('.panel-grid')
    // }

    // /**
    //  * To get full visible width
    //  * @return {string} unit: px
    //  */
    // get fullVisableHeight () {
    //   const parent = this.parentPanelGrid
    //   const tabFooters = parent.querySelectorAll('.panel-grid__footer')
    //   const _checkLength = tabFooters?.length || 0
    //   let rectObj
    //   let tabFooter
    //   let _count = 0

    //   while (_count < _checkLength) {
    //     tabFooter = tabFooters[_count]
    //     const tabFooterRect = tabFooter.getBoundingClientRect?.()

    //     if (tabFooterRect && tabFooterRect?.top > 0) {
    //       rectObj = tabFooterRect
    //       break
    //     }

    //     _count++
    //   }

    //   const footerTop = rectObj?.top || document?.documentElement?.clientHeight

    //   if (this.isModalContainer) {
    //     const _footerTop = tabFooter?.offsetTop || footerTop
    //     return `${_footerTop - (this.offset)}px`
    //   }

    //   return `${footerTop - this.offset}px`
    // }

    // /**
    //  * To get tabFooter's height and used to set paddingBottom of guideMenuNav
    //  */
    // get extraPaddingBottom () {
    //   if (this.isModalContainer) {
    //     return '0px'
    //   }

    //   const tabFooter = this.parentPanelGrid.querySelector('.panel-grid__footer')
    //   const rectObj = tabFooter?.getBoundingClientRect?.()
    //   const footerHeight = rectObj?.height || 60

    //   return `${footerHeight}px`
    // }

    // /**
    //  * Scroll to the target by classname
    //  * @param {String} targetClass The specific classname
    //  * @void
    //  */
    //  @action
    //  goToTarget (targetClass) {
    //    const goTo = this?._content.querySelector?.(`.${targetClass}`) || {}
    //    const offset = this.offset

    //    if (this.isModalContainer) {
    //      const parent = this.parentPanelGrid.querySelector('.modal-dialog__content')
    //      nventor.goToElement({
    //        parent,
    //        goTo,
    //        offset
    //      })
    //    } else {
    //      nventor.goToElement({
    //        goTo,
    //        offset
    //      })
    //    }
    //  }

    // /**
    //  * setup tracked property _element and adjust for web page / mobile
    //  * @param {HTMLDivElement} element Default provided by glimmer component
    //  * @void
    //  */
    // @action
    // setupElement (element) {
    //   this._element = element
    // }

    // /**
    //  * setup tracked property _content
    //  * @param {HTMLDivElement} element Default provided by glimmer component
    //  * @void
    //  */
    // @action
    // setupContentElement (element) {
    //   this._content = element

    //   this.modifyLayout(true)
    // }

    // /**
    //  * Use to resize guide menu, and uses dropTask to add debounce
    //  * @param {Object} options contentRect not in use
    //  */
    // @dropTask
    // * resizeTask ({ contentRect: { width, height } }) {
    //   const DEBOUNCE_MS = 500
    //   yield timeout(DEBOUNCE_MS)
    //   const tab = this.args?.tab
    //   const parentTab = this.args?.parentTab

    //   if (parentTab?.isActive && tab?.isActive) {
    //     this.modifyLayout()
    //   }
    // }

    // /**
    //  * Modify the layout depends on isMobile
    //  * @void
    //  */
    // @action
    // modifyLayout (isSetup = false) {
    //   this.hasInitiated = true

    //   if (!this.showGuideMenu) {
    //     return
    //   }

    //   this._setGuideMenuNavCSSProp()

    //   if (this.onInitDeviceIsMobile !== this.applicationService.isMobile || isSetup === true) {
    //     this.onInitDeviceIsMobile = this.applicationService.isMobile
    //     scheduleOnce('afterRender', () => {
    //       this.applicationService.isMobile
    //         ? this._adjustForMobile(this._content)
    //         : this._adjustForWebPage(this._content)
    //     })
    //   }
    // }

    // /**
    //  * Use to adjust the offset of the content that guide menu refers to
    //  * @param {HTMLDivElement} container Uses recursive method to get the closest guide menu content container
    //  * @void
    //  */
    // @action
    // _adjustForWebPage (container = {}) {
    //   const navElement = this._element
    //   if (RA.isNotNilOrEmpty(container)) {
    //     let adjustWidth
    //     if (this.isModalContainer) {
    //       adjustWidth = 200
    //     } else {
    //       adjustWidth = navElement?.offsetWidth + navElement?.offsetLeft
    //       container.style.marginBottom = '30vh'
    //     }

    //     if (adjustWidth > 230) {
    //       adjustWidth = 230
    //     }

    //     if (!this.applicationService.isMobile) {
    //       adjustWidth = 230
    //     }

    //     container.style.marginLeft = `${adjustWidth}px`

    //     this.currentGuideMenu.width = adjustWidth
    //     this.adjustWidth = adjustWidth
    //   }
    // }

    // /**
    //  * Use to adjust the offset of the content that guide menu refers to
    //  * @param {HTMLDivElement} container Uses recursive method to get the closest guide menu content container
    //  * @void
    //  */
    // @action
    // _adjustForMobile (container = {}) {
    //   if (RA.isNotNilOrEmpty(container)) {
    //     container.style.marginLeft = 0
    //     this.adjustWidth = 0
    //   }
    // }

    // /**
    //  * Before modify layout, set CSS properties
    //  * @void
    //  */
    // @action
    // _setGuideMenuNavCSSProp () {
    //   const element = this._element
    //   if (RA.isNotNilOrEmpty(element)) {
    //     if (this.applicationService.isMobile === false) {
    //       const style = element.style
    //       const fullVisableHeight = this.fullVisableHeight || '40vh'

    //       const headerBottom = this.args.headerBottom
    //       if (!headerBottom) {
    //         this.args?.onSetHeaderBottom?.()
    //       }

    //       set(style, 'top', `${this.offset}px`)
    //       set(style, 'maxHeight', fullVisableHeight)
    //       set(style, 'minHeight', fullVisableHeight)

    //       const extraPaddingBottom = this.extraPaddingBottom
    //       set(style, 'paddingBottom', extraPaddingBottom)

    //       if(!this.applicationService.isMobile) {
    //         set(style, 'width', `230px`)
    //       } else {
    //         let adjustWidth = this.adjustWidth
    //         if (adjustWidth === null) {
    //           adjustWidth = element?.offsetWidth + element?.offsetLeft
    //         }

    //         if (adjustWidth > 230) {
    //           adjustWidth = 230
    //         }

    //         if (adjustWidth !== 0) {
    //           set(style, 'maxWidth', `${adjustWidth}px`)
    //           set(style, 'minWidth', `${adjustWidth}px`)
    //         }
    //       }
    //     } else {
    //       const style = element.style
    //       set(style, 'top', '')
    //       set(style, 'maxHeight', '')
    //       set(style, 'minHeight', '')
    //       set(style, 'maxWidth', '')
    //       set(style, 'minWidth', '')
    //     }
    //   }
    // }
  }
  _exports.default = GuideMenuNavComponent;
});