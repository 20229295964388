define("client/pods/components/barcode/generator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gPkDC8Av",
    "block": "{\"symbols\":[\"@format\",\"@value\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,2,[]],[23,1,[]]],null]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"u-page-break u-full-width u-margin-5\"],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setBarcode\"]],[23,2,[]],[23,1,[]]],null]]],[3,\"did-update\",[[28,\"fn\",[[23,0,[\"setBarcode\"]],[23,2,[]],[23,1,[]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"svg\",true],[11,\"id\",[29,[[23,0,[\"svgId\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/barcode/generator/template.hbs"
    }
  });
});