define("client/pods/components/documents/documents-selector/component", ["exports", "ramda-extension"], function (_exports, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: '',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['toggles/allCustom', 'documents/lists/types/sales', 'documents/lists/types/purchases']);
    },
    availableDocs: Ember.computed('crud.lists.{documents-lists-types-sales.[],documents-lists-types-purchases.[]}', 'hasSales', 'hasPurchases', function () {
      let salesDocs = [];
      if (this.hasSales) {
        salesDocs = R_.dotPath('crud.lists.documents-lists-types-sales')(this) || [];
      }
      let purchasesDocs = [];
      if (this.hasPurchases) {
        purchasesDocs = R_.dotPath('crud.lists.documents-lists-types-purchases')(this) || [];
      }
      return salesDocs.concat(purchasesDocs);
    })
  });
});