define("client/pods/components/elements/element-input/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/is-dirty", "client/mixins/date", "client/mixins/has-dropdown", "client/pods/components/elements/mixins/has-date-picker", "client/mixins/translate"], function (_exports, R, RA, _isDirty, _date, _hasDropdown, _hasDatePicker, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_translate.default, _hasDatePicker.default, _date.default, _hasDropdown.default, _isDirty.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    application: Ember.inject.service(),
    parser: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    tagName: 'span',
    classNames: ['element-input'],
    classNameBindings: ['isFullW:element-input--fullw', 'isRunning:is-loading', 'hasModifiedWarning:element-input--modified-value-warning'],
    datePickerClassName: null,
    autofocus: false,
    autocomplete: 'off',
    translate: true,
    isDatePicker: false,
    isFloat: false,
    isInt: false,
    isDropdownVisible: false,
    focusInAction: null,
    allowCalculations: true,
    type: 'text',
    isFullW: false,
    // used for when is in a dropdown/list searcher, set width to fullw of outer component
    onInput: null,
    // external action to trigger when input has changed (via keyboard)
    onSelectDate: null,
    // external action to trigger when date selected
    dateZ: null,
    // zulu date value binding (only for isDatePicker=true)
    showInstructionModal: false,
    instructionMessage: 'press enter or search button to search',
    instrctionTranslate: true,
    instructionTimeout: 5000,
    hasModifiedWarning: false,
    init() {
      this._super(...arguments);
      const tab = this.tab;
      const model = this.model;
      this.validationService.setErrorsTracker({
        tab,
        model,
        context: this.context
      });
      this.setup();
    },
    setup() {
      // storing original value
      this.setOriginalValue(this.value);
    },
    hasCopyBtn: Ember.computed('readonly', function () {
      // if (this.isDatePicker) {
      //   return false
      // }

      // if (this.isSearchBar) {
      //   return false
      // }

      // if (this.readonly) {
      //   return true
      // }

      return false;
    }),
    showRequiredError: Ember.computed('value', 'isRequired', function () {
      if (this.isRequired && !this.value) {
        return true;
      }
      return false;
    }),
    isRequiredIcon: Ember.computed('value', 'isRequired', function () {
      let iconClassNames = 'fas fa-asterisk element-icon--xs';
      if (this.isRequired && !this.value) {
        iconClassNames += ' has-text-danger';
      }
      return iconClassNames;
    }),
    inputPlaceHolder: Ember.computed('isRequired', function () {
      if (this.translatedPlaceholder) {
        return this.translatedPlaceholder;
      }
      if (this.isRequired) {
        return this.intl.t('is required');
      }
      return '';
    }),
    hasIconsLeft: Ember.computed('prefix', 'leftIcon', 'negative', function () {
      return this.prefix || this.leftIcon || this.negative;
    }),
    hasIconsRight: Ember.computed('suffix', 'leftIcon', 'hasCheckIcon', 'isRequired', function () {
      return this.suffix || this.rightIcon || this.hasCheckIcon || this.isRequired;
    }),
    currentErrors: Ember.computed('tab', 'tab.errors.errors.[]', function () {
      return this.tab?.errors?.errors || [];
    }),
    hasCurrentError: Ember.computed('currentErrors.[]', 'context', function () {
      const currentErrors = this?.currentErrors || [];
      const context = this?.context;
      return R.pipe(R.find(R.anyPass([R.pathEq(['context', 'key'], context), R.pathEq(['context', 'label'], context), R.pathEq(['context', 'keyWithIndex'], context)])), RA.isNotNilOrEmpty)(currentErrors);
    }),
    focusOut(e) {
      this._super(e);
      this.hideInstructions();
      if (this.autoTrim) {
        try {
          const value = R.trim(this.value);
          this.set('value', value);
        } catch (error) {
          console.error('could not auto trim', error);
        }
      }
      if (this.hasCalculationExpression) {
        const result = this.result;
        if (result) {
          this.set('value', result);
          this.set('isFloat', true);
        }
      }
      this.set('result', '');
      const isDirty = this.get('isDirty');
      if (this.isFloat) {
        var float = parseFloat(this.value);
        if (isNaN(float)) {
          float = 0;
        }
        if (!this.readonly) {
          this.set('value', float);
        }
        if (this.onInput) {
          this.onInput(float, e, isDirty, this.resetOriginalValue);
        }
      }
      if (this.isInt) {
        var int = parseInt(this.value);
        if (isNaN(int)) {
          int = 0;
        }
        if (!this.readonly) {
          this.set('value', int);
        }
        if (this.onInput) {
          this.onInput(int, e, isDirty, this.resetOriginalValue);
        }
      }
      let value = this.value;
      if (RA.isNotNilOrEmpty(value)) {
        if (RA.isNotNilOrEmpty(this.maxValue) && RA.isNotNilOrEmpty(value)) {
          // if (RA.isNotNilOrEmpty(this.maxValue) && RA.isNotNilOrEmpty(value) && !isNaN(Number(value))) {
          const maxValue = Number(this.maxValue);
          if (value > maxValue) {
            value = maxValue;
            this.set('value', value);
            this.showWarning();
          }
        }
        if (RA.isNotNilOrEmpty(this.minValue) && RA.isNotNilOrEmpty(value)) {
          // if (RA.isNotNilOrEmpty(this.minValue) && RA.isNotNilOrEmpty(value) && !isNaN(Number(value))) {
          const minValue = Number(this.minValue);
          if (value < minValue) {
            value = minValue;
            this.set('value', value);
            this.showWarning();
          }
        }
      }
      if (this.isFloat || this.isInt) {
        if (this.onInput) {
          this.onInput(this.value, e, isDirty, this.resetOriginalValue);
        }
      }
      if (this.isDatePicker) {
        const value = this.value;
        let dateZ = '';
        if (value) {
          const validDate = this.getMoment(value, this.dateFormat);
          if (validDate.isValid()) {
            dateZ = this.getStartOfDayZ(value);
            this.set('dateZ', dateZ);
          } else {
            dateZ = '';
            this.set('dateZ', dateZ);
            this.set('value', dateZ);
          }
        } else {
          this.set('dateZ', dateZ);
        }
        if (this.onInput) {
          this.onInput(dateZ, e, isDirty, this.resetOriginalValue);
        }
      }
      if (this.onValidate) {
        this.onValidate(e, this.valueKey, this.value, isDirty);
      }
      if (this.onFocusOut) {
        this.onFocusOut(this.value, this.valueKey, e, isDirty);
      }
      if (this.onFocusOutSave && isDirty) {
        this.onFocusOutSave(this.value, this.valueKey, e, isDirty);
        this.resetOriginalValue();
      }
    },
    showWarning() {
      Ember.set(this, 'hasModifiedWarning', true);
      // this.classNames.pushObject('element-input--modified-value-warning')
      setTimeout(() => {
        Ember.set(this, 'hasModifiedWarning', false);
      }, 1000);
    },
    hasCalculationExpression: Ember.computed('value', function () {
      let value = this.value;
      if (value) {
        value = value.toString();
        if (value.charAt(0) === '=' && value.length > 1 && this.allowCalculations) {
          return true;
        }
      }
      return false;
    }),
    keyDown(e) {
      this._super(e);
      if (this.hasCalculationExpression) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
          const result = this.result;
          if (result) {
            this.set('value', result);
            return false;
          }
        }
      }
    },
    keyUp(e) {
      this._super(e);
      const value = this.value;
      let result = '';
      if (this.hasCalculationExpression) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
          // key code: Enter
          const result = this.result;
          if (result) {
            this.set('value', result);
            return false;
          }
        }
        // begin calculations
        // =12*12+3
        const expr = R.takeLast(value.length - 1, value);
        try {
          result = this.parser.evaluate(expr);
        } catch (e) {
          result = '';
        }
      }
      this.set('result', result);
      if (this.onKeyUp) {
        const isDirty = this.get('isDirty');
        this.onKeyUp(e, value, isDirty);
      }
    },
    hideInstructions() {
      Ember.set(this, 'showInstructionModal', false);
    },
    submit() {
      if (this.onSubmit) {
        this.hideInstructions();
        if (this.onSubmit.perform) {
          this.onSubmit.perform(this.value);
        } else {
          this.onSubmit(this.value);
        }
      }
    },
    actions: {
      input(value /*, e */) {
        if (this.isSearchBar) {
          // isSearchBar instruction
          Ember.set(this, 'showInstructionModal', true);
          return;
        }
        if (this.onInput) {
          return this.onInput(...arguments);
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "focusOut", [_dec], Object.getOwnPropertyDescriptor(_obj, "focusOut"), _obj), _applyDecoratedDescriptor(_obj, "showWarning", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showWarning"), _obj), _applyDecoratedDescriptor(_obj, "hideInstructions", [_dec3], Object.getOwnPropertyDescriptor(_obj, "hideInstructions"), _obj), _applyDecoratedDescriptor(_obj, "submit", [_dec4], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj), _obj));
});