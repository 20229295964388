define("client/pods/components/admin/payments/card/panels-payments/component", ["exports", "client/mixins/crud", "client/mixins/date", "ember-concurrency"], function (_exports, _crud, _date, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, {
    dateService: Ember.inject.service('date'),
    crud: Ember.inject.service(),
    // adapterName: 'admin/payments/card',
    filtersAdapterName: 'admin/payments/card/filters',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['periods/months', 'periods/years']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      const nowZ = this.dateService.getMoment();
      const monthNow = nowZ.month() + 1;
      const yearNow = nowZ.year();
      this.set('mm', monthNow);
      this.set('yyyy', yearNow);
      const period = this.getPeriod();
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      this.displayByMonth(period);
    },
    refreshTask: (0, _emberConcurrency.task)(function* () {
      const period = this.period;
      this.displayByMonth(period);
    }),
    displayByMonth(period) {
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      this.display({
        dateStart,
        dateEnd
      });
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
    },
    display(period) {
      // const { dateStart, dateEnd } = period

      // this.set('period', period)
      // this.set('dateStart', dateStart)
      // this.set('dateEnd', dateEnd)

      // this.get('listByTask').perform({ dateStart, dateEnd })
      const tab = this.tab;
      const subTabOptions = {
        component: 'admin/payments/card/panels-payments/panels-statements',
        period
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    actions: {
      // dash () {
      //   this.set('resultsToggle', false)

      //   this.dash()
      // },

      display(model) {
        this.display(model);
      },
      displayByMonth(period) {
        this.displayByMonth(period);
      }
    }
  });
});