define("client/pods/grapesjs/plugins/background-fixed", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: scratch-card
   * @author Derek Chou
   * @since 29/11/2022
   * @copyright (C) 2022 BigOrdr
   * @description: Background Fixed
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'bigordr-background-fixed';
    const pluginBlockId = `${pluginName}-block`;
    const bigordrBackgroundFixedCover = 'bigordr-background-fixed-cover';
    const bigordrBackgroundFixedBackground = 'bigordr-background-fixed-background';
    const BODY_SELECTOR = '[data-gjs-type="wrapper"]';
    const COVER_SELECTOR = '[data-gjs-type="bigordr-background-fixed-cover"]';
    const P_CLASSNAME = 'bigordr-background-fixed-p';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', 'bigordr-background-fixed'])(editor);
    const onImageSelect = (model, ...args) => {
      const uploadModel = args[3];
      const imgSet = uploadModel?.imgSet || {};
      const {
        desktopUrl,
        mobileUrl,
        tabletUrl
      } = imgSet;
      const id = model?.getId?.();
      if (id) {
        const style = `
        <style>
          @media screen and (min-width: 1000px) {
            #${id} {
              background-image: url(${desktopUrl});
            }
          }
  
          @media screen and (min-width: 700px) and (max-width: 1000px) {
            #${id} {
              background-image: url(${tabletUrl});
            }
          }
  
          @media screen and (max-width: 700px) {
            #${id} {
              background-image: url(${mobileUrl});
            }
          }
        </style>
      `;
        model.components().add(style);
      }
    };
    const {
      onDbClick,
      intl,
      isReadonly,
      /** @type {Categories} */
      categories
    } = options;
    const blockLabel = intl.t(pluginName);
    DomComponents.addType(bigordrBackgroundFixedBackground, {
      model: {
        defaults: {
          tagName: 'div',
          name: intl.t(bigordrBackgroundFixedBackground),
          attributes: {
            'data-gjs-component': bigordrBackgroundFixedBackground,
            alt: 'none'
          },
          classes: [bigordrBackgroundFixedBackground],
          badgable: false,
          copyable: false,
          draggable: false,
          droppable: false,
          editable: true,
          highlightable: true,
          hoverable: false,
          layerable: false,
          removable: false,
          resizable: false,
          selectable: true,
          stylable: false,
          traits: []
        }
      },
      view: {
        events: {
          dblclick: 'onDbClick'
        },
        onDbClick() {
          if (onDbClick && !isReadonly) {
            const component = this.model;
            const imgType = '';
            const imagesManager = {
              onImageSelect: (...args) => onImageSelect(component, ...args)
            };
            const allowSrcSet = true;
            onDbClick(imgType, imagesManager, allowSrcSet);
          }
        }
      }
    });
    DomComponents.addType(bigordrBackgroundFixedCover, {
      model: {
        defaults: {
          tagName: 'div',
          name: intl.t(bigordrBackgroundFixedCover),
          attributes: {
            'data-gjs-component': bigordrBackgroundFixedCover,
            alt: 'none'
          },
          badgable: false,
          copyable: false,
          draggable: false,
          droppable: false,
          editable: false,
          highlightable: false,
          hoverable: false,
          layerable: false,
          removable: false,
          resizable: false,
          selectable: false,
          stylable: false,
          traits: []
        }
      }
    });
    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          tagName: 'div',
          name: blockLabel,
          droppable: BODY_SELECTOR,
          removable: true,
          copyable: true,
          resizable: false,
          draggable: BODY_SELECTOR,
          selectable: true,
          attributes: {
            'data-gjs-component': pluginName
          },
          toolbar: [{
            attributes: {
              class: 'fas fa-pencil-alt'
            },
            command(editor) {
              const selected = editor.getSelected();
              const component = selected.findType(bigordrBackgroundFixedBackground)[0];
              if (onDbClick) {
                const imgType = '';
                const imagesManager = {
                  onImageSelect: (...args) => onImageSelect(component, ...args)
                };
                const allowSrcSet = true;
                onDbClick(imgType, imagesManager, allowSrcSet);
              }
            }
          }, {
            attributes: {
              class: 'fa fa-trash'
            },
            command: 'tlb-delete'
          }],
          components: [{
            selectable: true,
            removable: false,
            draggable: false,
            type: bigordrBackgroundFixedBackground,
            style: {
              width: '100%',
              'min-height': '100vh',
              position: 'sticky',
              top: '0'
            },
            components: [{
              type: 'row',
              selectable: true,
              highlightable: true,
              hoverable: true,
              removable: false,
              components: [{
                type: 'cell',
                selectable: true,
                highlightable: true,
                hoverable: true,
                removable: false,
                components: [{
                  type: 'text',
                  style: {
                    padding: '10px',
                    margin: '10px'
                  },
                  tagName: 'p',
                  classes: [P_CLASSNAME],
                  content: intl.t('insert text or drag content here, remove this text block if not needed')
                }]
              }],
              style: {
                'min-height': '100vh',
                position: 'sticky',
                top: '0',
                'text-align': 'center',
                width: '100vw',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                padding: '10px'
              }
            }],
            attributes: {},
            traits: []
          }, {
            selectable: false,
            removable: false,
            draggable: false,
            type: bigordrBackgroundFixedCover,
            style: {
              background: 'transparent',
              width: '100%',
              position: 'sticky',
              top: '0',
              'min-height': '100vh',
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center'
            },
            attributes: {},
            components: [{
              type: 'row',
              selectable: true,
              highlightable: true,
              hoverable: true,
              removable: false,
              components: [{
                type: 'cell',
                selectable: true,
                highlightable: true,
                hoverable: true,
                removable: false,
                components: [{
                  type: 'text',
                  style: {
                    padding: '10px',
                    margin: '10px'
                  },
                  tagName: 'p',
                  classes: [P_CLASSNAME],
                  content: intl.t('insert text or drag content here, remove this text block if not needed')
                }]
              }],
              style: {
                'text-align': 'center',
                width: '100vw',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                position: 'relative',
                padding: '10px'
              }
            }],
            traits: []
          }],
          traits: []
        }
      },
      view: {
        onRender({
          model,
          editor,
          el
        }) {
          const cover = el.querySelector(COVER_SELECTOR);
          cover.style.minHeight = 'unset';
        }
      }
    });
    BlockManager.add(pluginBlockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: pluginName
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
    <svg class='block-icon' width="100%" height="100%" version="1.1" id="圖層_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 299 291"
    style="enable-background:new 0 0 299 291;" xml:space="preserve">
    <style type="text/css">
      .st0{fill:#FFFFFF;}
      .st1{fill:#231815;}
      .st2{fill:#EFEFEF;}
      .st3{fill:#3E3A39;}
      .st4{fill:#FFFFFF;stroke:#3E3A39;stroke-width:8;stroke-miterlimit:10;}
      .st5{fill:#FFFFFF;stroke:#231815;stroke-width:12;stroke-miterlimit:10;}
      .st6{fill:#EFEFEF;stroke:#231815;stroke-width:12;stroke-miterlimit:10;}
      .st7{fill:none;stroke:#231815;stroke-width:5.9009;stroke-miterlimit:10;}
      .st8{fill:none;stroke:#231815;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
      .st9{fill:#B5B5B6;stroke:#231815;stroke-width:12;stroke-miterlimit:10;}
      .st10{fill:none;stroke:#231815;stroke-width:12.6247;stroke-miterlimit:10;}
      .st11{fill:none;stroke:#231815;stroke-width:12;stroke-miterlimit:10;}
      .st12{fill:none;stroke:#231815;stroke-width:20;stroke-miterlimit:10;}
      .st13{fill:#FFFFFF;stroke:#231815;stroke-width:8;stroke-miterlimit:10;}
      .st14{stroke:#231815;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
    </style>
    <switch>
      <foreignObject requiredExtensions="&ns_ai;" x="0" y="0" width="1" height="1">
        <i:aipgfRef  xlink:href="#adobe_illustrator_pgf">
        </i:aipgfRef>
      </foreignObject>
      <g i:extraneous="self">
        <g>
          <path class="st1" d="M276,288H23V3h253V288z M35,276h229V15H35V276z"/>
        </g>
        <g>
          <g>
            <path class="st1" d="M232.64,215h-180V99h180V215z M64.64,203h156v-92h-156V203z"/>
          </g>
          <g>
            <g>
              <path class="st0" d="M142.13,261.38c-1.9,0-3.68-0.9-4.82-2.42l-18.43-24.81c-1.35-1.82-1.56-4.25-0.54-6.27s3.09-3.3,5.36-3.3
                h3.54v-32.83c0-3.31,2.69-6,6-6h18.11c3.31,0,6,2.69,6,6v32.83h3.22c2.27,0,4.34,1.28,5.36,3.3s0.81,4.45-0.54,6.27
                l-18.43,24.81C145.81,260.48,144.03,261.38,142.13,261.38z"/>
            </g>
            <g>
              <path class="st1" d="M151.34,191.74v38.83h9.22l-18.43,24.81l-18.43-24.81h9.54v-38.83H151.34 M151.34,179.74h-18.11
                c-6.63,0-12,5.37-12,12v27.09c-3.53,0.74-6.6,3.05-8.26,6.36c-2.04,4.05-1.62,8.91,1.09,12.55l18.43,24.81
                c2.26,3.05,5.84,4.84,9.63,4.84s7.37-1.8,9.63-4.84l18.43-24.81c2.71-3.64,3.13-8.5,1.09-12.55c-1.61-3.2-4.54-5.47-7.94-6.28
                v-27.16C163.34,185.11,157.97,179.74,151.34,179.74L151.34,179.74z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st0" d="M131.91,124.48c-3.31,0-6-2.69-6-6V85.65h-3.22c-2.27,0-4.34-1.28-5.36-3.3s-0.81-4.45,0.54-6.27
                l18.43-24.81c1.13-1.52,2.92-2.42,4.82-2.42s3.68,0.9,4.82,2.42l18.43,24.81c1.35,1.82,1.56,4.25,0.54,6.27s-3.09,3.3-5.36,3.3
                h-3.54v32.83c0,3.31-2.69,6-6,6H131.91z"/>
            </g>
            <g>
              <path class="st1" d="M141.13,54.84l18.43,24.81h-9.54v38.83h-18.11V79.65h-9.22L141.13,54.84 M141.13,42.84
                c-3.8,0-7.37,1.8-9.63,4.84l-18.43,24.81c-2.71,3.64-3.13,8.5-1.09,12.55c1.61,3.2,4.54,5.47,7.94,6.28v27.16
                c0,6.63,5.37,12,12,12h18.11c6.63,0,12-5.37,12-12V91.39c3.53-0.74,6.6-3.05,8.26-6.36c2.04-4.05,1.62-8.91-1.09-12.55
                l-18.43-24.81C148.5,44.64,144.92,42.84,141.13,42.84L141.13,42.84z"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st0" d="M252.04,206.79c-1.02-1.95-2.34-3.93-3.12-5.97c-1.33-3.47-1.47-7.5-2.15-11.13
                c-1.44-7.77-4.03-15.27-7.7-22.28l-5.49-10.49c-0.7-1.33-1.88-2.32-3.32-2.77c-1.44-0.45-2.96-0.32-4.3,0.38
                c-2.76,1.44-3.83,4.86-2.38,7.62c0.08,0.15,0.02,0.33-0.13,0.41c-0.15,0.08-0.33,0.02-0.41-0.13l-2.17-4.14
                c-0.7-1.34-1.88-2.32-3.32-2.77c-1.44-0.45-2.97-0.32-4.31,0.38c-2.76,1.44-3.83,4.86-2.38,7.62c0.08,0.15,0.02,0.33-0.13,0.41
                c-0.15,0.08-0.33,0.02-0.41-0.13l-2.56-4.9c-0.7-1.33-1.88-2.32-3.32-2.77c-1.44-0.45-2.97-0.32-4.3,0.38
                c-2.76,1.44-3.83,4.86-2.39,7.62c0.08,0.15,0.02,0.33-0.13,0.41c-0.15,0.08-0.33,0.02-0.41-0.13l-8.47-16.2
                c-0.7-1.33-1.87-2.32-3.31-2.77c-1.45-0.45-2.98-0.31-4.31,0.38c-2.76,1.44-3.83,4.86-2.39,7.62l19.26,36.83
                c0.06,0.12,0.04,0.26-0.05,0.35c-0.02,0.02-0.05,0.04-0.08,0.06c-0.08,0.04-0.19,0.05-0.27,0l-10.07-4.95
                c-1.44-0.45-2.96-0.31-4.3,0.39c-1.34,0.7-2.32,1.88-2.77,3.31c-1.23,3.92,0.14,4.86,10.75,12.14
                c2.52,1.73,5.66,3.89,9.38,6.51c4.25,3,8.82,5.57,13.59,7.64c1.8,0.78,3.03,1.24,3.98,3.02c0.79,1.49,1.63,2.97,2.41,4.47
                c0.71,1.35,2.39,1.88,3.74,1.17l26.57-13.89C252.23,209.83,252.75,208.15,252.04,206.79z"/>
              <path class="st1" d="M223.03,228.74c-2.44,0-4.8-1.32-6.01-3.63c-0.46-0.88-0.92-1.73-1.39-2.59
                c-0.34-0.62-0.67-1.24-1.01-1.86c-0.21-0.39-0.24-0.45-1.75-1.11l-0.29-0.13c-5.02-2.18-9.83-4.89-14.3-8.04
                c-3.65-2.58-6.75-4.71-9.25-6.42l-0.09-0.06c-11.08-7.6-14.42-9.9-12.31-16.64c0.77-2.45,2.45-4.46,4.74-5.66
                c2.29-1.2,4.9-1.43,7.35-0.66l0.49,0.19l-14.02-26.81c-2.46-4.71-0.64-10.56,4.08-13.02c2.27-1.19,4.88-1.42,7.34-0.66
                c2.47,0.78,4.48,2.46,5.67,4.74l4.12,7.88c0.57-0.49,1.2-0.92,1.88-1.28c2.29-1.2,4.9-1.43,7.36-0.65
                c1.24,0.39,2.37,1.01,3.33,1.82c0.69-0.7,1.5-1.31,2.41-1.79c2.28-1.19,4.89-1.43,7.35-0.66c0.98,0.3,1.89,0.75,2.7,1.33
                c0.74-0.81,1.63-1.51,2.65-2.05c2.29-1.2,4.91-1.43,7.37-0.65c2.45,0.77,4.46,2.45,5.66,4.73l5.49,10.49
                c3.85,7.36,6.57,15.23,8.08,23.4c0.23,1.25,0.4,2.51,0.57,3.73c0.33,2.45,0.64,4.76,1.38,6.69c0.41,1.07,1.07,2.23,1.77,3.46
                c0.4,0.7,0.79,1.39,1.16,2.09c1.73,3.31,0.45,7.41-2.86,9.14l-26.57,13.89C225.16,228.49,224.09,228.74,223.03,228.74z
                M184.11,191.31c0.77,1.1,4.59,3.72,9.37,7l0.09,0.06c2.51,1.72,5.64,3.87,9.33,6.48c4.03,2.84,8.36,5.28,12.88,7.25l0.28,0.12
                c1.91,0.83,4.08,1.77,5.64,4.69c0.32,0.6,0.64,1.19,0.97,1.79c0.29,0.54,0.59,1.08,0.88,1.62l24.37-12.75
                c-0.14-0.25-0.28-0.5-0.42-0.75c-0.82-1.43-1.67-2.92-2.3-4.56c-1.08-2.81-1.47-5.7-1.84-8.49c-0.16-1.18-0.31-2.29-0.5-3.34
                c-1.37-7.38-3.83-14.5-7.31-21.15l-5.49-10.49c-0.21-0.39-0.54-0.67-0.97-0.81c-0.43-0.13-0.86-0.09-1.25,0.11
                c-0.8,0.42-1.11,1.42-0.69,2.22c0.53,1.01,0.64,2.18,0.29,3.28c-0.34,1.09-1.09,1.99-2.1,2.53c-2.11,1.1-4.72,0.29-5.82-1.82
                l-2.17-4.14c-0.21-0.4-0.54-0.67-0.96-0.8c-0.44-0.14-0.87-0.1-1.26,0.11c-0.8,0.42-1.11,1.42-0.69,2.22
                c1.1,2.09,0.29,4.7-1.81,5.8c-2.13,1.1-4.72,0.28-5.82-1.82l-2.56-4.9c-0.21-0.39-0.54-0.67-0.97-0.81
                c-0.43-0.13-0.86-0.09-1.25,0.11c-0.52,0.27-0.73,0.72-0.81,0.97c-0.08,0.25-0.16,0.73,0.11,1.26
                c0.53,1.01,0.64,2.18,0.29,3.28s-1.09,2-2.12,2.53c-2.09,1.1-4.7,0.29-5.8-1.81l-8.47-16.21c-0.21-0.4-0.54-0.68-0.97-0.81
                c-0.42-0.13-0.85-0.09-1.25,0.11c-0.8,0.42-1.12,1.42-0.69,2.22l19.26,36.83c0.87,1.64,0.58,3.66-0.74,5.01l-0.43,0.44
                l-0.62,0.35c-1.24,0.65-2.68,0.67-3.91,0.08l-9.74-4.79c-0.35-0.06-0.71-0.01-1.04,0.17c-0.4,0.21-0.68,0.54-0.81,0.96
                C184.17,190.98,184.13,191.19,184.11,191.31z M248.5,208.65C248.5,208.65,248.5,208.65,248.5,208.65L248.5,208.65
                C248.5,208.65,248.5,208.65,248.5,208.65z M194.46,192.14c0.01,0.01,0.01,0.02,0.02,0.03L194.46,192.14z M194.21,165.99
                c0,0.01,0.01,0.02,0.01,0.03C194.22,166.01,194.22,166,194.21,165.99z M207.32,165.37c0,0.01,0.01,0.02,0.01,0.03
                C207.33,165.39,207.33,165.38,207.32,165.37z M220.03,164c0,0.01,0.01,0.02,0.01,0.03C220.04,164.02,220.04,164.01,220.03,164z
                "/>
            </g>
            <g>
              <g>
                <path class="st1" d="M192.76,164.27c2.33,4.67,4.67,9.33,7,14c2.59,5.18,10.36,0.63,7.77-4.54c-2.33-4.67-4.67-9.33-7-14
                  C197.94,154.55,190.17,159.1,192.76,164.27L192.76,164.27z"/>
              </g>
            </g>
            <g>
              <g>
                <path class="st1" d="M204.76,162.27c2.33,4.67,4.67,9.33,7,14c2.59,5.18,10.36,0.63,7.77-4.54c-2.33-4.67-4.67-9.33-7-14
                  C209.94,152.55,202.17,157.1,204.76,162.27L204.76,162.27z"/>
              </g>
            </g>
            <g>
              <g>
                <path class="st1" d="M217.76,161.27c2.33,4.67,4.67,9.33,7,14c2.59,5.18,10.36,0.63,7.77-4.54c-2.33-4.67-4.67-9.33-7-14
                  C222.94,151.55,215.17,156.1,217.76,161.27L217.76,161.27z"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path d="M58.65,45.65c-1.02,0-2.03-0.19-3.03-0.56c-1-0.37-1.89-0.86-2.68-1.46c-0.79-0.6-1.43-1.28-1.91-2.04
            c-0.49-0.76-0.73-1.53-0.73-2.32v-2.32l3.13,2.34l-1.35,1.35c0.48,0.9,1.3,1.7,2.46,2.4c1.16,0.7,2.3,1.09,3.41,1.17v-9h-3.02
            v-1.39h3.02V32.1c-0.59-0.22-1.04-0.54-1.37-0.97c-0.32-0.43-0.49-0.93-0.49-1.48c0-0.71,0.25-1.31,0.75-1.81
            c0.5-0.49,1.1-0.74,1.8-0.74c0.71,0,1.31,0.25,1.81,0.74c0.49,0.49,0.74,1.1,0.74,1.81c0,0.56-0.16,1.05-0.49,1.48
            c-0.32,0.43-0.78,0.76-1.37,0.97v1.72h3.02v1.39h-3.02v9c1.11-0.08,2.25-0.47,3.41-1.17s1.98-1.5,2.46-2.4l-1.35-1.35L67,36.95
            v2.32c0,0.79-0.24,1.56-0.73,2.32c-0.49,0.76-1.13,1.44-1.91,2.04c-0.79,0.6-1.68,1.09-2.68,1.46
            C60.68,45.46,59.67,45.65,58.65,45.65z M58.65,30.8c0.32,0,0.6-0.12,0.82-0.35c0.22-0.23,0.34-0.5,0.34-0.81
            c0-0.32-0.11-0.6-0.34-0.82s-0.5-0.34-0.82-0.34c-0.31,0-0.58,0.11-0.81,0.34c-0.23,0.22-0.35,0.5-0.35,0.82
            c0,0.31,0.12,0.58,0.35,0.81C58.07,30.69,58.34,30.8,58.65,30.8z"/>
          <path class="st1" d="M58.65,46.15c-1.08,0-2.15-0.2-3.2-0.59c-1.04-0.39-1.98-0.9-2.81-1.53c-0.83-0.63-1.51-1.36-2.03-2.17
            c-0.54-0.84-0.81-1.71-0.81-2.59v-2.32c0-0.19,0.11-0.36,0.28-0.45c0.17-0.08,0.37-0.06,0.52,0.05l3.13,2.34
            c0.12,0.09,0.19,0.22,0.2,0.36c0.01,0.14-0.04,0.29-0.15,0.39l-1.07,1.07c0.45,0.69,1.15,1.33,2.09,1.89
            c0.9,0.54,1.79,0.89,2.65,1.04v-7.94h-2.52c-0.28,0-0.5-0.22-0.5-0.5v-1.39c0-0.28,0.22-0.5,0.5-0.5h2.52v-0.88
            c-0.53-0.25-0.95-0.58-1.27-1.01c-0.39-0.52-0.59-1.12-0.59-1.78c0-0.85,0.3-1.58,0.9-2.17c0.6-0.59,1.32-0.89,2.15-0.89
            c0.84,0,1.57,0.3,2.16,0.89c0.59,0.59,0.89,1.32,0.89,2.16c0,0.66-0.2,1.26-0.59,1.78c-0.32,0.42-0.74,0.76-1.27,1.01v0.88h2.52
            c0.28,0,0.5,0.22,0.5,0.5v1.39c0,0.28-0.22,0.5-0.5,0.5h-2.52v7.94c0.86-0.14,1.75-0.49,2.65-1.04c0.94-0.57,1.64-1.2,2.09-1.89
            l-1.07-1.07c-0.1-0.1-0.16-0.24-0.15-0.39c0.01-0.14,0.08-0.28,0.2-0.36l3.13-2.34c0.15-0.11,0.35-0.13,0.52-0.05
            c0.17,0.08,0.28,0.26,0.28,0.45v2.32c0,0.88-0.27,1.75-0.81,2.59c-0.52,0.8-1.2,1.53-2.03,2.17c-0.82,0.63-1.77,1.15-2.81,1.53
            C60.8,45.95,59.72,46.15,58.65,46.15z M50.8,37.95v1.32c0,0.69,0.22,1.38,0.65,2.05c0.45,0.71,1.06,1.35,1.8,1.91
            c0.75,0.57,1.6,1.04,2.55,1.39c1.87,0.7,3.83,0.7,5.71,0c0.95-0.35,1.8-0.82,2.55-1.39c0.74-0.56,1.34-1.21,1.8-1.92
            c0.43-0.67,0.65-1.36,0.65-2.05v-1.32l-1.87,1.4l0.94,0.94c0.16,0.16,0.19,0.39,0.09,0.59c-0.52,0.97-1.41,1.85-2.64,2.59
            c-1.22,0.74-2.45,1.16-3.64,1.24c-0.13,0.01-0.27-0.04-0.38-0.13c-0.1-0.09-0.16-0.23-0.16-0.37v-9c0-0.28,0.22-0.5,0.5-0.5h2.52
            v-0.39h-2.52c-0.28,0-0.5-0.22-0.5-0.5V32.1c0-0.21,0.13-0.4,0.33-0.47c0.5-0.18,0.87-0.45,1.14-0.81
            c0.26-0.35,0.39-0.74,0.39-1.18c0-0.58-0.2-1.05-0.6-1.46c-0.4-0.4-0.88-0.6-1.46-0.6c-0.56,0-1.04,0.2-1.45,0.6
            c-0.41,0.4-0.6,0.88-0.6,1.45c0,0.45,0.13,0.84,0.39,1.18c0.27,0.36,0.64,0.62,1.14,0.81c0.2,0.07,0.33,0.26,0.33,0.47v1.72
            c0,0.28-0.22,0.5-0.5,0.5h-2.52v0.39h2.52c0.28,0,0.5,0.22,0.5,0.5v9c0,0.14-0.06,0.27-0.16,0.37c-0.1,0.1-0.25,0.15-0.38,0.13
            c-1.19-0.08-2.41-0.5-3.63-1.24c-1.23-0.75-2.12-1.62-2.64-2.59c-0.1-0.19-0.07-0.43,0.09-0.59l0.94-0.94L50.8,37.95z
            M58.65,31.3c-0.45,0-0.84-0.17-1.17-0.5c-0.32-0.32-0.49-0.73-0.49-1.17c0-0.46,0.17-0.87,0.5-1.18
            c0.67-0.64,1.71-0.62,2.34,0.01c0.32,0.31,0.48,0.72,0.48,1.18c0,0.44-0.16,0.83-0.48,1.16C59.52,31.13,59.11,31.3,58.65,31.3z
            M58.65,28.98c-0.18,0-0.33,0.06-0.46,0.2c-0.13,0.13-0.2,0.28-0.2,0.46c0,0.17,0.06,0.32,0.2,0.46
            c0.29,0.29,0.67,0.27,0.92,0.01c0.13-0.14,0.2-0.29,0.2-0.46c0-0.19-0.06-0.34-0.19-0.47C58.99,29.04,58.84,28.98,58.65,28.98z"
            />
        </g>
      </g>
    </switch>
  </svg>
  <div class="block-label">${model.get('label')}</div>
  </div>`
    });
  };
  _exports.default = _default;
});