define("client/pods/components/products/price-selectors/price-selector-campaigns/commission-history-prices/component", ["exports", "client/pods/components/products/price-selectors/mixins/products"], function (_exports, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_products.default, {
    tagName: ''
  });
});