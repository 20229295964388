define("client/pods/components/elements/element-inputfile/component", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextField.extend({
    type: 'file',
    multiple: true,
    attributeBindings: ['multiple'],
    classNames: ['element-inputfile'],
    onChange: null,
    // external action to handle file changes
    getFilesAsArray: false,
    change(e) {
      // send external action onChange
      if (this.onChange) {
        let files = e.target.files;
        if (this.getFilesAsArray) {
          files = _nventor.default.getFilesArray(files);
        }
        this.onChange(e, files, this);
      }
    }
  });
});