define("client/pods/components/layouts/tabs/grid/content/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module LayoutsTabsGridContentComponent
   * @description Component for tab grid content
   */

  class LayoutsTabsGridContentComponent extends _component.default {}
  _exports.default = LayoutsTabsGridContentComponent;
});