define("client/pods/documentation/model", ["exports", "client/pods/base/model", "client/constants", "ramda"], function (_exports, _model, _constants, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentationModel = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _class = class DocumentationModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_data", _descriptor, this);
    }
    get isActive() {
      return R.pathEq(['_data', 'status'], _constants.default.status.active)(this);
    }
    get hasDocumentation() {
      return R.pipe(R.pathOr({}, ['_data', '_id']), R.propSatisfies(R.gte(R.__, 1), 'length'))(this);
    }
    get hasTemplate() {
      return R.pipe(R.pathOr({}, ['_data', 'template']), R.propSatisfies(R.gte(R.__, 1), 'length'))(this);
    }
    get type() {
      return this?._data?.type || '';
    }
    get isHelp() {
      if (this.type === 'isHelp') {
        return true;
      }
      return false;
    }
    get isGroup() {
      if (this.type === 'isGroup') {
        return true;
      }
      return false;
    }
    populate(data, attrs) {
      super.populate(data, attrs);
      data.templates = this._transformTemplates(data);
    }
    _transformTemplates(data) {
      return R.pipe(R.pathOr([], ['templates']), R.map(template => this.dataManager.setAsRecord({
        adapterName: 'documentation/templates',
        data: this.getTemplateHash(template)
      })))(data);
    }
    getTemplateHash(args = {}) {
      const {
        template,
        header,
        status
      } = args;
      return {
        component: 'products/products-editor/template',
        get label() {
          return this.header;
        },
        action: 'new',
        tabType: 'reuseSubTab',
        header,
        template,
        status
      };
    }
    addNewTemplate() {
      const newTemplate = this.dataManager.newRecord({
        adapterName: 'documentation/templates',
        data: this.getTemplateHash()
      });
      if (!newTemplate?.instanceId) {
        newTemplate.instanceId = this?.instanceId;
      }
      this?._data?.templates?.pushObject(newTemplate);
      return newTemplate;
    }
    get hasMultipleTemplates() {
      const templates = this._data.templates || [];
      if (templates.length > 1) {
        return true;
      }
      return false;
    }
    defaults() {
      return {
        quickTip: '',
        status: _constants.default.status.active,
        template: '',
        templates: []
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_transformTemplates", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_transformTemplates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTemplateHash", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getTemplateHash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNewTemplate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addNewTemplate"), _class.prototype), _class);
});