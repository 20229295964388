define("client/pods/components/documents/panel-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0Aekszi6",
    "block": "{\"symbols\":[\"@onDisplay\"],\"statements\":[[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"documents/editor-main\",null,[[\"tab\",\"tabParent\",\"model\",\"isEditing\",\"onEdit\",\"onDash\",\"onDisplay\",\"onSetupDirty\",\"onUpdateDocInfoTask\"],[[24,[\"tab\"]],[24,[\"tabParent\"]],[24,[\"model\"]],false,[28,\"action\",[[23,0,[]],[24,[\"onEdit\"]],[24,[\"model\"]]],null],[24,[\"onDash\"]],[23,1,[]],[24,[\"onSetupDirty\"]],[24,[\"onUpdateDocInfoTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-display/template.hbs"
    }
  });
});