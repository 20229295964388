define("client/pods/components/documents/tab-container/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct", "client/mixins/crud", "client/mixins/doc-list-by", "client/utils/nventor", "client/pods/components/documents/docConfig", "ember-concurrency", "ember-concurrency-decorators", "client/pods/documents/proxy/model"], function (_exports, R, R_, RA, _crud, _docListBy, _nventor, _docConfig, _emberConcurrency, _emberConcurrencyDecorators, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, _docListBy.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    uploadcareService: Ember.inject.service('uploadcare'),
    dateService: Ember.inject.service('date'),
    posService: Ember.inject.service('pos'),
    storageService: Ember.inject.service('storage'),
    subTabsCopy: null,
    filtersAdapterName: 'documents/filters',
    posShopKey: null,
    products: null,
    shop: null,
    enableAutoIssueEInvoice: true,
    showFilters: false,
    listsIsLoaded: null,
    shopsList: null,
    init() {
      this._super(...arguments);
      const tab = this.tab;
      const docConfigData = _docConfig.default.getFor(tab);
      const {
        docType,
        docName
      } = docConfigData;
      this.crud.addLists(this, ['dispatch/lists/processes', 'units', 'taxes', 'taxMethods', 'contacts/lists/tags', 'discounts/types', 'dispatch/lists/processes', 'documents/lists/discountmethods', 'documents/lists/filters/statuses', 'documents/lists/filters/dispatch-status', 'documents/lists/print', 'documents/lists/print/unit', 'documents/lists/print/product-code', 'paymentMethods', 'paymentTerms', 'paymentTerms/from', 'paymentTerms/end', 'documents/lists/deliverydatesoptions', 'dispatch/lists/processes', 'extensions/apps/tw/invoicingAuto', 'toggles/onOff', 'shipping/deliveryTimes', 'documents/lists/date-filter', {
        adapterName: 'documents/lists/menu',
        appendPath: `${docType}/${docName}`,
        options: {
          docType,
          docName
        }
      }, {
        adapterName: 'documents/lists/menu/pos',
        appendPath: `${docType}/${docName}`,
        options: {
          docType,
          docName
        }
      }, {
        adapterName: 'users',
        appendPath: 'internal'
      }, 'printer/lists/allow-printing-from']);
      this.set('config', docConfigData);
      this.set('tab.tabs', []);
      const title = tab.get('docType') + '-' + tab.get('docName');
      this.set('docTitle', title);
      this.set('adapterName', this.get('config.adapterName'));
      this.set('docType', docType);
      this.set('docName', docName);
      const period = this.getPeriod();
      this.set('period', period);
      const isPos = R.pathEq(['tab', 'isPos'], true)(this);
      const defaultFiltersData = {
        menuId: `${this.filtersAdapterName}--${docType}-${docName}`,
        isPos,
        dateHeaderValue: 'dateZ'
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData
      });
      filters.set('showStarred', true);
      this.set('filters', filters);
      const tableViewOptions = {
        hasDispatchStatus: false,
        hasDispatchProcess: false,
        allowDispatching: false
      };
      if (docType === 'sales' && (docName === 'invoices' || docName === 'consignments')) {
        tableViewOptions.hasDispatchStatus = true;
        tableViewOptions.hasDispatchProcess = true;
        tableViewOptions.allowDispatching = true;
      }
      this.set('tableViewOptions', tableViewOptions);
    },
    loadTabTask: (0, _emberConcurrency.task)(function* ({
      tab
    }) {
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        this.display();
      } else {
        this.dash();
      }
    }),
    toggleResults(results) {
      const subTab = this.tabsManager.getActiveSubTab(this.tab);
      if (subTab?.component === 'documents/panel-dash') {
        return results;
      }
      Ember.set(this, 'filters.showResults', true);
      return results;
    },
    *fetchCampaignsWithPosEnableTask() {
      const shopsList = yield this.posService.fetchCampaignsWithPosEnableTask.perform();
      this.set('shopsList', shopsList);
    },
    afterLoadLists() {
      this._super(...arguments);
      const tab = this.tab;
      this.set('listsIsLoaded', Math.random());
      this.setupListByFilters();
      this.loadTabTask.perform({
        tab
      });
    },
    setupListByFilters() {
      const filters = this.filters;
      const monthsRange = R.pathOr('6', ['crud', 'lists', 'documents-lists-date-filter', 0, '_data', 'value'])(this);
      filters.set('monthsRange', monthsRange);
      this.set('filters', filters);
    },
    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    updateDocInfoTask: (0, _emberConcurrency.task)(function* (dirty) {
      const adapterName = this.adapterName;
      return yield this.updateRecordTask.perform({
        adapterName,
        appendPath: '/info',
        model: dirty
      });
    }).drop(),
    documentsListsMenu: Ember.computed(
    // 'terminalProxy.terminal',
    'listsIsLoaded', 'docType', 'docName', function () {
      const docType = this.docType;
      const docName = this.docName;
      const isPos = R.pathEq(['tab', 'isPos'], true)(this);
      if (!isPos) {
        return this.get(`crud.lists.documents-lists-menu--${docType}-${docName}`) || [];
      }
      return R.pipe(R.pathOr([], ['crud', 'lists', `documents-lists-menu-pos--${docType}-${docName}`])
      // R.map(
      //   menu => {
      //     const enterPosModeBtn = R.pathEq(['_data', 'label'], 'enter pos mode')(menu)
      //     if (!enterPosModeBtn) {
      //       return menu
      //     }
      //     const allowEnterPosMode = R.pipe(
      //       R.path(['terminalProxy', 'terminal']),
      //       RA.isNotNilOrEmpty
      //     )(this)
      //     if (menu._data.enable === allowEnterPosMode) {
      //       return menu
      //     }
      //     menu.set('_data.enable', allowEnterPosMode)
      //     return menu
      //   }
      // )
      )(this);
    }),
    resultsProxy: Ember.computed('filters.results.[]', function () {
      const results = this?.filters?.results || [];

      // let results = this.results || []
      // if (RA.isNotNilOrEmpty(filterResults)) {
      //   results = filterResults
      // }

      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: false // @TODO: get this from settings
      }), results);
    }),
    dash() {
      this._dashTask.perform();
    },
    _dashTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      Ember.set(this, 'filters.showResults', false);
      const adapterName = this.adapterName;
      const filters = this.filters;
      filters.setData('query', '');
      const monthsRange = filters.monthsRange || 6;
      const dateStart = this.dateService.getMoment().subtract(monthsRange, 'months').startOf('day').toISOString();
      filters.setData('dateStart', dateStart);
      yield this.crud.fetchDefaultDataTask.perform({
        adapterName,
        filters,
        listByTask: this.listByTask
      });
      const subTabOptions = {
        component: 'documents/panel-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }),
    display(model) {
      Ember.set(this, 'filters.showResults', false);
      const component = this.get('config.displayComponent');
      const tab = this.tab;
      const isNew = tab.isNew;
      const isFromShortcut = tab.isFromShortcut || false;
      const isFromPendingShortcut = tab.isFromPendingShortcut || false;
      const isFromContactShortcut = tab.isFromContactShortcut || false;
      const isFromDocument = tab.isFromDocument || false;
      const isDuplicateDocumentShortcut = tab.isDuplicateDocumentShortcut || false;
      const isFromOutstandingQty = tab.isFromOutstandingQty || false;
      const isFromToInvoiceQty = tab.isFromToInvoiceQty || false;
      const isLinkingNeeded = RA.isNilOrEmpty(tab.isLinkingNeeded) ? true : tab.isLinkingNeeded;
      let subTabOptions;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (tab?.isNew) {
        tab.set('isNew', false);
        return this.new();
        // subTabOptions = {
        //   component: this.get('config.newComponent'),
        //   loadInstructions,
        //   pendingDoc: model,
        //   isFromShortcut,
        //   isFromPendingShortcut,
        //   isFromContactShortcut,
        //   isFromDocument,
        //   isDuplicateDocumentShortcut,
        //   isFromOutstandingQty,
        //   isFromToInvoiceQty,
        //   isLinkingNeeded,
        //   config: this.config
        // }
        // tab.set('isFromShortcut', false)
      } else {
        subTabOptions = {
          component: component,
          model: model,
          loadInstructions,
          config: this.config
        };
      }
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    new() {
      Ember.set(this, 'filters.showResults', false);
      const self = this;
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructionsAndClear(tab);
      const component = self.get('config.newComponent');
      const subTabOptions = {
        component: component,
        config: self.get('config'),
        loadInstructions
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    enterPosMode() {
      const tab = this.tab;
      this.posService.exitPosProfile(tab);
      this.new(...arguments);
    },
    updateMonthsRange(model) {
      const months = model?._data?.value;
      this.dash();
    },
    actions: {
      dash() {
        this.dash();
      },
      display(model) {
        Ember.set(this, 'filters.showResults', false);
        if (model) {
          this.display(model);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },
      new() {
        this.new(...arguments);
      },
      close(panel) {
        this.tab.closePanel(panel);
      },
      edit(model) {
        Ember.set(this, 'filters.showResults', false);
        const self = this;
        const component = self.get('config.editComponent');
        const subTabOptions = {
          component: component,
          model: model,
          config: self.get('config')
        };
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "toggleResults", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleResults"), _obj), _applyDecoratedDescriptor(_obj, "fetchCampaignsWithPosEnableTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_obj, "fetchCampaignsWithPosEnableTask"), _obj), _applyDecoratedDescriptor(_obj, "setupListByFilters", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setupListByFilters"), _obj), _applyDecoratedDescriptor(_obj, "dash", [_dec3], Object.getOwnPropertyDescriptor(_obj, "dash"), _obj), _applyDecoratedDescriptor(_obj, "display", [_dec4], Object.getOwnPropertyDescriptor(_obj, "display"), _obj), _applyDecoratedDescriptor(_obj, "new", [_dec5], Object.getOwnPropertyDescriptor(_obj, "new"), _obj), _applyDecoratedDescriptor(_obj, "enterPosMode", [_dec6], Object.getOwnPropertyDescriptor(_obj, "enterPosMode"), _obj), _applyDecoratedDescriptor(_obj, "updateMonthsRange", [_dec7], Object.getOwnPropertyDescriptor(_obj, "updateMonthsRange"), _obj), _obj));
});