define("client/pods/components/emails/campaigns/panels-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      const model = this.get('tab.model');
      const dirty = this.crud.setupDirty({
        adapterName: 'emails/campaigns',
        model
      });
      this.set('dirty', dirty);
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    }
  });
});