define("client/pods/components/messages/tab-container/messages-window/message-bubble/video-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "j5hg+zNb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"video\",true],[10,\"controls\",\"\"],[8],[0,\"\\n  \"],[7,\"source\",true],[11,\"src\",[22,\"messageUrl\"]],[11,\"type\",[22,\"messageType\"]],[8],[9],[0,\"\\n  Sorry, your browser doesn't support embedded videos.\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/messages-window/message-bubble/video-type/template.hbs"
    }
  });
});