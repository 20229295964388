define("client/pods/processes/model", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    init: function () {
      this._super(...arguments);
      const random = Math.floor(Math.random() * 100000);
      this.set('id', random);
    },
    serialize(model) {
      const data = this._super(model);
      if (data.isSystemProcess) {
        return R.dissoc('index', data);
      }
      return data;
    }
  });
});