define("client/pods/components/modals/modal-overlay/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: '',
    modalClassNames: '',
    closeOnOverlayClick: true,
    close() {
      if (this.closeOnOverlayClick) {
        Ember.set(this, 'toggle', false);
        if (this.onClose) {
          if (R.is(Function, this.onClose)) {
            this.onClose();
          } else {
            // @TODO: fix all instances where modal-container-new is used and need to just pass in one function
            // this just supresses errors, some places boolean is passed in
            console.log('onClose is not a function');
          }
        }
        if (this.onToggle) {
          // this is a temp fix
          if (R.is(Function, this.onToggle)) {
            this.onToggle();
          } else {
            // @TODO: fix all instances where modal-container-new is used and need to just pass in one function
            // this just supresses errors, some places boolean is passed in
            console.log('onToggle is not a function');
          }
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "close", [_dec], Object.getOwnPropertyDescriptor(_obj, "close"), _obj), _obj));
});