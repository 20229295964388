define("client/pods/channels/shop/shipping-zones/countries/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/shipping-zones/countries/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/settings/countries',
    modelObj: _model.default,
    idParam: false
  });
});