define("client/pods/components/emails/member-level/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _ramda, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EmailsMemberLevelComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _class = class EmailsMemberLevelComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "constants", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "server", _descriptor6, this);
      _initializerDefineProperty(this, "useDefaultEmailMessage", _descriptor7, this);
      _initializerDefineProperty(this, "emailMessage", _descriptor8, this);
      _initializerDefineProperty(this, "emailSubject", _descriptor9, this);
      _initializerDefineProperty(this, "isCompilingMessage", _descriptor10, this);
      _initializerDefineProperty(this, "tabsChanged", _descriptor11, this);
      _initializerDefineProperty(this, "currentSubTabOptions", _descriptor12, this);
      _initializerDefineProperty(this, "allEmailsData", _descriptor13, this);
      _initializerDefineProperty(this, "filters", _descriptor14, this);
      const filters = this.search.setupFilters({
        adapterName: 'contacts/rewards/recipients/filters'
      });
      this.filters = filters;
      this.crud.addLists(this, ['emails/lists/toSendTabs']);
    }
    get headerTabs() {
      const allEmailsData = this.allEmailsData || {};
      const list = this.crud.lists['emails-lists-toSendTabs'] || [];
      return _ramda.default.pipe(_ramda.default.map(header => {
        const headerValue = header._data.value;
        const hasData = _ramda.default.pipe(_ramda.default.path([headerValue]), _nventor.default.isNilOrEmpty, _ramda.default.not)(allEmailsData);
        if (!hasData) {
          return false;
        }
        const badge = _ramda.default.path([headerValue, 'count'])(allEmailsData) || 0;
        header.setData('badge', badge);
        return header;
      }), _ramda.default.reject(_ramda.default.equals(false)))(list);
    }
    getBirthday(contact) {
      const birthdays = [];
      const contactBirthday = _ramda.default.propOr('', 'birthday')(contact);
      const memberBirthday = _ramda.default.pathOr('', ['member', 'birthday'])(contact);
      const createdDateZ = _ramda.default.propOr('', 'createdDateZ')(contact);
      if (RA.isNotNilOrEmpty(contactBirthday)) {
        birthdays.push({
          date: contactBirthday,
          source: 'contact'
        });
      }
      if (RA.isNotNilOrEmpty(memberBirthday)) {
        birthdays.push({
          date: memberBirthday,
          source: 'member'
        });
      }
      if (RA.isNilOrEmpty(birthdays)) {
        birthdays.push({
          date: createdDateZ,
          source: 'created date'
        });
      }
      return birthdays;
    }
    get panelData() {
      const subTabOptions = this.currentSubTabOptions || {};
      if (!_nventor.default.isNilOrEmpty(subTabOptions)) {
        const value = subTabOptions.getData('value');
        const allEmailsData = this.allEmailsData || {};
        const data = _ramda.default.prop(value)(allEmailsData) || {};
        data.data = _ramda.default.map(contact => {
          contact.birthdays = this.getBirthday(contact);
          contact.emails = _ramda.default.pipe(_ramda.default.propOr([], 'details'), _ramda.default.filter(_ramda.default.propEq('type', 'email')))(contact);
          contact.memberEmail = _ramda.default.pathOr('', ['member', 'email'])(contact);
          contact.contactRewardsData = _nventor.default.safeHeadOr({})(contact.contactRewardsData);
          return contact;
        })(data.data);
        return data;
      }
      return {};
    }
    *getToSendMemberLevelBatchEmailTask(args) {
      const {
        memberLevel,
        rewardKey
      } = args;
      const filters = this.filters;
      filters.setData('rewardKeys', [rewardKey]);
      filters.setData('memberLevelIds', [_ramda.default.pathOr('', ['_data', 'memberLevelId'])(memberLevel)]);
      filters.setData('hasBirthdayFilter', false);
      filters.setData('hasSubscriptionFilter', true);
      const response = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts/rewards/recipients',
        appendPath: '/notification',
        filters,
        rawResponse: true
      });
      this.allEmailsData = {
        toSend: {
          data: _ramda.default.propOr([], 'contacts')(response),
          count: _ramda.default.propOr(0, 'count')(response)
        }
      };
    }
    *sendMemberLevelBatchEmailTask(onCloseDialogAction, {
      useDefaultEmailMessage,
      subject,
      message
    }) {
      const filters = this.filters;
      const emailData = {
        useDefaultEmailMessage,
        subject,
        message,
        hasBirthdayFilter: false,
        hasSubscriptionFilter: true,
        memberLevelIds: filters.get('_data.memberLevelIds'),
        rewardKeys: filters.get('_data.rewardKeys'),
        isNotification: true
      };
      const response = yield this.server.call('PUT', 'api/protected/contacts/rewards/send/notification', emailData);
      let sent = 0;
      if (response.data) {
        sent = _ramda.default.length(response.data);
      }
      onCloseDialogAction();
      window.alert('sent: ' + sent + ' emails.');
    }
    setRecipientData() {
      const memberLevel = this.args.memberLevel;
      const rewardKey = this.args.model._data._key;
      this.getToSendMemberLevelBatchEmailTask.perform({
        memberLevel,
        rewardKey
      });
    }
    displayPanel(allEmailsData, subTabOptions) {
      this.currentSubTabOptions = subTabOptions;
      this.tabsChanged = Math.random();
      this.tabsManager.displaySubPanel(this, subTabOptions);
    }
    next() {
      this.isCompilingMessage = true;
    }
    onToggleSetCustomMessage() {
      const useDefaultEmailMessage = this.useDefaultEmailMessage;
      if (useDefaultEmailMessage) {
        this.useDefaultEmailMessage = true;
        this.emailSubject = '';
      } else {
        this.useDefaultEmailMessage = false;
        this.emailSubject = '';
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "useDefaultEmailMessage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "emailMessage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "emailSubject", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isCompilingMessage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "tabsChanged", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currentSubTabOptions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "allEmailsData", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getToSendMemberLevelBatchEmailTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getToSendMemberLevelBatchEmailTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendMemberLevelBatchEmailTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "sendMemberLevelBatchEmailTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setRecipientData", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setRecipientData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayPanel", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSetCustomMessage", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetCustomMessage"), _class.prototype), _class);
});