define("client/helpers/isEven", ["exports", "client/mixins/big", "client/utils/nventor"], function (_exports, _big, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    compute(params) {
      const n = parseInt(params[0]);
      return _nventor.default.isEven(n);
    }
  });
});