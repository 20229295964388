define("client/pods/components/importer/import-table/component", ["exports", "ramda", "client/pods/options/model", "client/utils/nventor", "ember-concurrency"], function (_exports, R, _model, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    init() {
      this._super(...arguments);
      this.set('importInstructions', Ember.Object.create({
        headers: []
      }));
    },
    columns: Ember.computed('model', function () {
      const model = this.model;
      const intl = this.intl;
      return R.pipe(R.prop('importParams'), R.map(param => {
        return _nventor.default.createModel(_model.default, {
          label: intl.t(param.label),
          value: param.value
        });
      }))(model);
    }),
    importTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, batchString) {
      const rows = R.split('\n', batchString);
      const columns = this.columns;
      const columnKeys = R.pipe(R.pluck('_data'), R.pluck('value'))(columns);
      const importData = R.map(row => {
        return R.pipe(R.split('\t'), R.zipObj(columnKeys))(row);
      }, rows);
      return yield this.onImportTask.perform(onCloseDialogAction, importData);
    }),
    actions: {
      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
  });
});