define("client/pods/documents/sales/invoices/details/adapter", ["exports", "@ember-data/adapter", "client/pods/documents/mixins/details/adapters", "client/pods/documents/details/model"], function (_exports, _adapter, _adapters, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adapters.default, {
    resourcePath: 'api/protected/documents/sales/invoices',
    modelObj: _model.default,
    idParam: false
  });
});