define("client/pods/components/elements/status-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PAZpEx0T",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"&default\"],\"statements\":[[5,\"helpers/info-tooltip\",[],[[\"@customBlock\",\"@clickToView\"],[true,false]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"element-btn__status-badge tag \",[23,0,[\"statusBadgeClassNames\"]]]]],[8],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"closeOnOverlayClick\",\"hasModalOverlay\",\"isViewable\",\"hasContent\"],[true,false,[23,1,[]],[23,0,[\"statusLabel\"]]]],{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"        \"],[14,2,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"statusBadgeClassNames\"]]]]],[8],[0,\"\\n          \"],[1,[28,\"tt\",[[23,0,[\"statusLabel\"]]],[[\"translate\"],[[23,0,[\"tooltipTranslate\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/status-badge/template.hbs"
    }
  });
});