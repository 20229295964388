define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor/rules-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "dataManager", _descriptor3, this);
      _initializerDefineProperty(this, "constants", _descriptor4, this);
    }
    get bulkDiscountsWhen() {
      const whenLists = this.crud.lists['automations-when'];
      const bulkDiscountsWhenLists = R.concat(whenLists, []);
      return R.filter(whenData => {
        const when = R.pathOr('', ['_data', 'value'])(whenData);
        return R.includes(when, ['itemQty', 'itemValue']);
      })(bulkDiscountsWhenLists);
    }
    get rewardMemberLevels() {
      const model = this.args.model;
      const rewardData = model.getData('rewardData') || {};
      if (RA.isNotNilOrEmpty) {
        const memberLevels = R.propOr([], 'memberLevels')(rewardData);
        return R.map(memberLevel => {
          return this.dataManager.setAsRecord({
            adapterName: 'channels/shop/rewards/member-levels',
            data: memberLevel
          });
        })(memberLevels);
      }
      return [];
    }
    onSubmitRemoveRule(schedule, rule) {
      const rules = schedule.getData('rules');
      rules.removeObject(rule);
    }
    onToggleSetDescription(rule) {
      const isDefaultDescription = rule.getData('isDefaultDescription');
      if (isDefaultDescription) {
        rule.setData('description', '');
      }
    }
    onInputCheckHasDuplicate() {
      const rule = this.args?.rule || {};
      const schedule = this.args?.schedule || {};
      let hasDuplicate = false;
      if (RA.isNotNilOrEmpty(rule)) {
        const rules = schedule.getData('rules') || [];
        const criteria = rule.getData('criteria') || '';
        const value = rule.getData('value') || '';
        hasDuplicate = R.pipe(R.reject(eachRule => {
          const hasMemberLevels = eachRule.getData('hasMemberLevels') || false;
          const memberLevelId = eachRule.getData('memberLevelId') || '';
          return hasMemberLevels && RA.isNotNilOrEmpty(memberLevelId);
        }), R.filter(R.allPass([R.pathEq(['_data', 'criteria'], criteria), R.anyPass([R.pathEq(['_data', 'value'], value), R.pathEq(['_data', 'value'], parseInt(value))])])), R.length, R.gt(R.__, 1))(rules);
      }
      rule.set('hasDuplicate', hasDuplicate);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveRule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSetDescription", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInputCheckHasDuplicate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onInputCheckHasDuplicate"), _class.prototype), _class);
});