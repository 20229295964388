define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/panels-new/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    tagName: '',
    isEditing: false,
    isNew: true,
    isReadonly: false,
    init() {
      this._super(...arguments);
      this.newAutomationModel();
    },
    newAutomationModel() {
      const master = this.master;
      const automations = this.get('automationsCollection._data.automations');
      const index = automations.get('lastObject._data.index') || 0;
      const newBlankAutomationModel = this.setupNewRecord({
        adapterName: 'channels/shop/campaigns/automations',
        data: {
          index: index + 1,
          master: master.getData('_key')
        }
      });
      newBlankAutomationModel.set('instance', Math.random());
      this.set('automationModel', newBlankAutomationModel);
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this.set('automationModel', '');
        this._super(...arguments);
      }
    },
    actions: {
      nextStep(saved) {
        this.set('automationModel', saved);
        this.set('isNew', false);
        this.set('isEditing', false);
        this.set('isReadonly', true);
      }
    }
  });
});