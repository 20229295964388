define("client/pods/components/channels/shop/rewards/rewards-editor/member-levels-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fsPLiz5J",
    "block": "{\"symbols\":[\"formInitData\",\"memberLevel\",\"memberLevelIndex\",\"@isReadonly\",\"@model\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\",\"@outerFormInitData\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@formInitData\",\"@tab\"],[\"member levels\",[28,\"if\",[[23,10,[]],[23,10,[]],[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"rewards\",[23,9,[]],[23,8,[]],[23,7,[]],[23,6,[]]]]]],null],[23,8,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@tab\",\"@title\"],[\"description_attachments_group\",[23,1,[]],[23,8,[]],\"description attachments\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,5,[\"_data\",\"memberLevels\"]]],null,{\"statements\":[[0,\"      \"],[5,\"channels/shop/rewards/rewards-editor/member-levels-editor/member-level-editor\",[],[[\"@memberLevel\",\"@memberLevelIndex\",\"@isReadonly\",\"@model\",\"@formInitData\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"\\n    \"],[5,\"elements/error-wrapper\",[],[[\"@context\"],[\"memberLevels\"]]],[0,\"\\n\\n    \"],[5,\"elements/element-btn\",[],[[\"@disabled\",\"@label\",\"@icon\",\"@onSubmit\"],[[23,4,[]],\"new member level\",\"fas fa-plus\",[28,\"fn\",[[23,0,[\"addMemberLevelAction\"]],[23,5,[]]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/rewards-editor/member-levels-editor/template.hbs"
    }
  });
});