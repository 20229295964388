define("client/mixins/uploads", ["exports", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    populate(data = {}) {
      if (!this.isUploadsCollection) {
        const uploadsCollection = R.propOr([], 'uploadsCollection', data);
        let uploadsCollectionModel = {};
        if (!_nventor.default.isNilOrEmpty(uploadsCollection)) {
          uploadsCollectionModel = this.dataManager.mergeAndSetPartialRecord({
            adapterName: 'uploads/collection',
            data: uploadsCollection
          });
          data = R.dissoc('uploadsCollection')(data);
        }
        this.set('uploadsCollection', uploadsCollectionModel);
      }
      const uploads = R.propOr([], 'uploads')(data);
      if (!R.isEmpty(uploads)) {
        data.uploads = RA.mapIndexed((file, index) => {
          if (RA.isNilOrEmpty(data.index)) {
            data.index = index;
          }

          /*
            Ignore upload with missing _id.
            When _id is missing, the uploads are corrupted. By returning, empty the UI will render no image, and make it
            easy to find the issue.
          */
          if (!file?._data?._id && !file?._id) {
            return;
          }
          return this.dataManager.setPartialRecord({
            adapterName: 'uploads',
            data: file
          });
        })(uploads);
      }
      this._super(...arguments);
    },
    /**
     * @deprecated
     * */
    defaultImage: Ember.computed('uploadsCollection._data.uploads.[]', '_data.{defaultImage,uploads.[]}', 'synced', function () {
      // TODO: REMOVE AND IMPLEMENT ISSUE #420
      // @TODO: should get default image from _data.uploads (the one with isDefault = true)
      // this.get('_data.upload')
      // filter..
      // return is default one

      if (this.get('_data.defaultImage')) {
        return this.get('_data.defaultImage');
      }
      let uploadsCollection = this.get('uploadsCollection._data.uploads') || this.get('_data.uploads.[]');
      uploadsCollection = uploadsCollection || [];
      uploadsCollection = R.sortWith([R.ascend(R.path(['_data', 'timestampZ']))])(uploadsCollection);
      return this.getFirstImage(uploadsCollection);
    }),
    getFirstImage(files = []) {
      const allUploads = R.pipe(R.flatten, R.filter(R.pathEq(['_data', 'isImage'], true)), R.reject(_nventor.default.isNilOrEmpty))(files);
      const firstImage = allUploads.get('firstObject');
      if (firstImage) {
        return R.pathOr(false, ['_data', 'uuid'], firstImage);
      }
      return false;
    },
    defaultImageUrl: Ember.computed('defaultImage', function () {
      const defaultImage = this.defaultImage;
      this.uploadcare.getFileUrl(defaultImage);
    }),
    getDefaultImageUrl(transform) {
      const uuid = this.defaultImage;
      return this.uploadcare.getFileUrl(uuid, transform);
    }
  });
});