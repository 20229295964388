define("client/pods/components/extensions/apps/tw/e-invoices/tax-number-input/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwEInvoicesTaxNumberInputComponent = _exports.default = (_dec = Ember.inject.service('documents'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('args.modelData.twEInvoiceCarrierType'), _class = class ExtensionsAppsTwEInvoicesTaxNumberInputComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "documentsService", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "taxNumber", _descriptor4, this);
      _initializerDefineProperty(this, "companyDetails", _descriptor5, this);
      _initializerDefineProperty(this, "mobileBarcode", _descriptor6, this);
      _initializerDefineProperty(this, "previousConfirmedCompanyName", _descriptor7, this);
      this.crud.addLists(this, ['extensions/apps/tw/e-invoices/carrier-types']);
    }
    get iconClassName() {
      const classNames = ['icon'];
      if (this.companyDetails) {
        if (this.companyDetails?.error) {
          classNames.push('has-text-danger');
        } else {
          classNames.push('has-text-info');
        }
      }
      return classNames.join(' ');
    }
    get placeholder() {
      if (!this.args?.modelData?.twEInvoiceCarrierType) {
        return;
      }
      const twEInvoiceCarrierType = this.args?.modelData?.twEInvoiceCarrierType;
      if (!twEInvoiceCarrierType) {
        return '';
      }
      const carrierTypeLabel = R.pipe(R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-e-invoices-carrier-types']), R.find(R.pathEq(['_data', 'value'], twEInvoiceCarrierType)), R.pathOr('', ['_data', 'label']))(this);
      return this.intl.t(carrierTypeLabel);
    }
    get isReadonly() {
      if (this.args?.isReadonly === true) {
        return true;
      }
      if (this.args?.isDisabled === true) {
        return true;
      }
      if (!this.args?.modelData?.twEInvoiceCarrierType) {
        return true;
      }
      return false;
    }
    *onSearchTask() {
      this.companyDetails = null;
      this.mobileBarcode = null;
      const taxNumber = R.trim(this.args?.modelData?.taxNumber) || '';
      if (!taxNumber) {
        return;
      }
      this.mobileBarcode = '';
      this.companyDetails = yield this.documentsService?.fetchAllDetailsFromGovTask.perform(taxNumber);
      if (!this.companyDetails?.name) {
        return;
      }
      const currentCompanyName = this.args?.modelData?.companyName || '';
      if (currentCompanyName) {
        const isSameCompanyName = this?.companyDetails?.name === currentCompanyName;
        if (!isSameCompanyName) {
          const message = this.intl.t('the company name, {companyName}, does not match the company name for the tax number. Do you want to use {taxNumberCompanyName}?', {
            companyName: currentCompanyName,
            taxNumberCompanyName: this.companyDetails?.name
          });
          if (this.args?.parentComponent?.previousConfirmedCompanyName === currentCompanyName || !window.confirm(message)) {
            this.companyDetails.name = currentCompanyName;
            this.args?.parentComponent?.set('previousConfirmedCompanyName', currentCompanyName);
          } else {
            this.args?.parentComponent?.set('previousConfirmedCompanyName', '');
          }
        }
      }
      this.companyDetails.value = taxNumber;
      return this.args.setupTaxDetails?.(this.companyDetails);
    }

    // @action
    // setTaxNumber (value) {
    //   debugger
    //   const model = this.args.model
    //   if (modelData) {
    //     model.setData('taxNumber', value)
    //     return
    //   }

    //   set(model, 'taxNumber', value)
    // }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "documentsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "taxNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "companyDetails", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mobileBarcode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "previousConfirmedCompanyName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isReadonly", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isReadonly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSearchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSearchTask"), _class.prototype), _class);
});