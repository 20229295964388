define("client/pods/components/channels/shop/promo-codes/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VkErhc6j",
    "block": "{\"symbols\":[\"@tab\",\"@errors\",\"@onDash\",\"@onEdit\"],\"statements\":[[5,\"channels/shop/promo-codes/promo-codes-editor\",[],[[\"@model\",\"@errors\",\"@isRunning\",\"@isNew\",\"@isEditing\",\"@isReadonly\",\"@tab\",\"@onCancel\",\"@onEdit\",\"@onRemoveRecordTask\"],[[23,1,[\"model\"]],[23,2,[]],[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]],false,false,true,[23,1,[]],[23,3,[]],[23,4,[]],[28,\"perform\",[[23,0,[\"crud\",\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\"],[\"channels/shop/promoCodes\",[23,0,[]],[23,1,[\"model\"]],[23,3,[]]]]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/promo-codes/panels-display/template.hbs"
    }
  });
});