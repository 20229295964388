define("client/pods/components/elements/element-select/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ElementsElementSelect = _exports.default = (_dec = Ember._action, _class = class ElementsElementSelect extends _component.default {
    get optionLabelKey() {
      return R.pathOr('label', ['optionLabelKey'])(this.args);
    }
    get optionValueKey() {
      return R.pathOr('value', ['optionValueKey'])(this.args);
    }
    get translate() {
      return R.pathOr(false, ['translate'])(this.args);
    }
    get hasEmpty() {
      return R.pathOr(false, ['hasEmpty'])(this.args);
    }
    get _options() {
      const options = R.pathOr([], ['options'])(this.args);
      return R.map(option => {
        if (R.is(Object, option)) {
          return option;
        }
        return Ember.Object.create({
          label: option,
          value: option
        });
      })(options);
    }
    select(e) {
      const selectedOption = R.pipe(R.pathOr([], ['target', 'selectedOptions']), R.head)(e);
      let selected = '';
      if (selectedOption) {
        selected = R.propOr('', 'value')(selectedOption);
      }
      return this.args?.onSelect?.(selected);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "select", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
});