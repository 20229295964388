define("client/pods/components/products/products-editor/variants-editor/variant-editor/options-editor/component", ["exports", "client/mixins/errors", "client/mixins/droppable", "ramda", "ramda-adjunct"], function (_exports, _errors, _droppable, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_droppable.default, _errors.default, {
    productsService: Ember.inject.service('products'),
    tagName: 'tr',
    originalOptionName: '',
    init() {
      this._super(...arguments);
      this.set('originalOptionName', this?.option?._data?.name || '');
    },
    droppable: Ember.computed('options.[]', 'index', function () {
      const index = parseInt(this.index) + 1;
      const options = this.options || [];
      if (index >= options.length) {
        return false;
      }
      return true;
    }),
    draggable: Ember.computed('droppable', 'isReadonly', function () {
      if (!this.isReadonly) {
        return this.droppable;
      }
      return false;
    }),
    isVariantOptionReadonly: Ember.computed('isReadonly', 'option.{noInitialOptionUid,_data.name}', function () {
      let isVariantOptionReadonly = false;
      const option = this.option;
      if (this.isEditing && option.get('noInitialOptionUid') && option.get('_data.name')) {
        isVariantOptionReadonly = true;
      }
      if (!isVariantOptionReadonly) {
        isVariantOptionReadonly = this.isReadonly;
      }
      return isVariantOptionReadonly;
    }),
    filesManagerResourceKeys: Ember.computed('model._data.variantChildren.{[],@each.synced}', 'option._data.optionUid', function () {
      let resourceKeys = [];
      const variantChildren = R.pathOr([], ['model', '_data', 'variantChildren'])(this);
      const optionUid = R.pathOr('', ['option', '_data', 'optionUid'])(this);
      if (RA.isNotNilOrEmpty(variantChildren)) {
        resourceKeys = R.pipe(
        // shouldn't add images to permanentlyUnavailable variantChildren?
        R.reject(R.pathEq(['_data', 'permanentlyUnavailable'], true)), R.map(variantChild => {
          const variantChildUid = R.pathOr('', ['_data', 'variantChildUid'])(variantChild);
          if (R.includes(optionUid, variantChildUid)) {
            const variantChildKey = variantChild?._data?._key || '';
            return variantChildKey;
          }
          return '';
        }), R.reject(RA.isNilOrEmpty))(variantChildren);
      }
      return resourceKeys;
    })
  });
});