define("client/pods/recurring/schedules/filters/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/recurring/schedules/filters/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false
  });
});