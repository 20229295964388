define("client/pods/components/reports/dashboard/card-revenue-by-group-with-percentage/component", ["exports", "ramda", "client/mixins/date", "ramda-adjunct"], function (_exports, R, _date, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, {
    tagName: '',
    chartLegend: 'revenue',
    metric: 'net',
    chartType: null,
    // chartHeight: null,
    chartWidth: null,
    translate: false,
    isTaxExclusive: true,
    averageByItemQty: false,
    isTableView: true,
    isReloadingChart: false,
    defaultLimitResults: 10,
    limitResults: 10,
    maxChartResults: 30,
    showAverages: true,
    init() {
      this._super(...arguments);
      this.set('metricOptions', ['invoices', 'returns', 'net']);
      this.set('chartOptions', {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      });
    },
    didInsertElement() {
      this._super(...arguments);
      let autoChartHeight = 30;
      const rows = parseInt(this.chartDatasets.length) || 1;
      const height = Math.ceil(rows * 30);
      if (height < 200) {
        autoChartHeight = 200;
      } else {
        autoChartHeight = height;
      }
      this.set('autoChartHeight', autoChartHeight);
    },
    // autoChartHeight: computed('chartDatasets.[]', function () {
    //   const rows = parseInt(this.chartDatasets.length) || 1
    //   const height = Math.ceil(rows * 30)

    //   if (height < 200) {
    //     return 200
    //   }

    //   return height
    // }),

    chartData: Ember.computed('chartLegend', 'chartLabels.[]', 'chartDatasets.[]', function () {
      return {
        labels: this.chartLabels,
        datasets: [{
          label: this.intl.t(this.chartLegend),
          data: this.chartDatasets
        }]
      };
    }),
    getExcl(detail, param) {
      return detail[param] || '0';
    },
    getIncl(detail, param) {
      return detail[param] || '0';
    },
    getValue(detail, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(detail, exclParam) : this.getIncl(detail, inclParam);
    },
    amountTotal: Ember.computed('limited.[]', 'isTaxExclusive', function () {
      const limited = this?.limited || [];
      if (RA.isNotNilOrEmpty(limited) && R.is(Array, limited)) {
        return R.pipe(R.map(detail => {
          let value = this.getValue(detail, 'postDiscExclAmt', 'postDiscInclAmt');
          if (RA.isNilOrEmpty(value)) {
            value = this.getValue(detail, 'exclAmt', 'inclAmt');
          }
          return parseFloat(value) || 0;
        }), R.sum)(limited);
      }
      return 0;
    }),
    qtyTotal: Ember.computed('limited.[]', 'averageByItemQty', 'isTaxExclusive', function () {
      const limited = this?.limited || [];
      if (RA.isNotNilOrEmpty(limited) && R.is(Array, limited)) {
        return R.pipe(R.map(detail => {
          const averageByItemQty = R.propOr(false, 'averageByItemQty')(this);
          let value = detail?.docCount || 0;
          if (averageByItemQty) {
            value = detail?.qty || 0;
          }
          return parseInt(value) || 0;
        }), R.sum)(limited);
      }
      return 0;
    }),
    sorted: Ember.computed('data', 'metric', 'limitResults', function () {
      const metric = this.metric;
      const data = this.get(`data.${metric}`);
      if (Ember.isEmpty(data)) {
        return [];
      }
      const valueParam = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';
      const sort = R.pipe(R.sortWith([R.descend(R.pipe(R.prop(valueParam), parseFloat))]));
      return sort(data);
    }),
    limited: Ember.computed('sorted.[]', 'limitResults', function () {
      const sorted = this.sorted;
      const limitResults = this.limitResults;
      if (limitResults) {
        return R.take(limitResults, sorted);
      }
      return sorted;
    }),
    chartLabels: Ember.computed('limited.[]', 'translate', 'limitResults', function () {
      const limited = this.limited;
      const translate = this.translate;
      if (Ember.isEmpty(limited)) {
        return [];
      }
      // const maxChartResults = this.get('maxChartResults')
      return R.pipe(R.pluck('segmentValue'), R.map(label => translate || label === 'none' ? this.intl.t(label) : label)
      // R.take(maxChartResults)
      )(limited);
    }),
    chartDatasets: Ember.computed('limited.[]', 'limitResults', function () {
      const limited = this.limited;
      if (Ember.isEmpty(limited)) {
        return [];
      }
      const valueParam = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';

      // const maxChartResults = this.get('maxChartResults')
      return R.pipe(R.pluck(valueParam)
      // R.take(maxChartResults)
      )(limited);
    }),
    hasMoreResults: Ember.computed('sorted.[]', 'limited.[]', 'limitResults', function () {
      if (this.sorted.length > this.limited.length) {
        return true;
      }
      return false;
    }),
    hasLessResults: Ember.computed('sorted.[]', 'limited.[]', 'limitResults', function () {
      if (this.sorted.length === this.limited.length) {
        return false;
      }
      return true;
    }),
    reloadChart() {
      const self = this;
      this.set('isReloadingChart', true);
      setTimeout(() => {
        self.set('isReloadingChart', false);
      }, 100);
    },
    actions: {
      toggleView() {
        this.toggleProperty('isTableView');
      },
      viewMore() {
        this.set('limitResults', false);
        if (this.reloadChart) {
          this.reloadChart();
        }
      },
      viewLess() {
        this.set('limitResults', this.defaultLimitResults);
        if (this.reloadChart) {
          this.reloadChart();
        }
      }
    }
  });
});