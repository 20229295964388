define("client/pods/components/extensions/apps/tw/invoices/invoices-editor/component", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices", "client/mixins/crud", "ember-concurrency"], function (_exports, _invoices, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _invoices.default, {
    adapterName: 'extensions/apps/tw/invoices',
    tagName: '',
    init() {
      this._super(...arguments);
      const dirty = this.setupDirty();
      this.set('dirty', dirty);
    },
    _updateInvoiceTask: (0, _emberConcurrency.task)(function* (appendPath) {
      yield this.replaceRecordTask.perform({
        appendPath
      });
      this.set('isEditing', false);
    }).drop(),
    actions: {
      updateInvoiceAndNumbers(onCloseDialogAction) {
        onCloseDialogAction();
        let appendPath;
        const updateFollowingInvoices = this.updateFollowingInvoices;
        if (updateFollowingInvoices) {
          const model = this.model;
          const fromNumber = model.getData('number');
          appendPath = `?mode=update-numbers-following&fromNumber=${fromNumber}`;
        } else {
          appendPath = `?mode=update-numbers`;
        }
        this._updateInvoiceTask.perform(appendPath);
      },
      updateInvoiceNumber(onCloseDialogAction) {
        onCloseDialogAction();
        const appendPath = `?mode=update`;
        this._updateInvoiceTask.perform(appendPath);
      },
      toggleUpdateFollowingInvoices() {
        this.toggleProperty('updateFollowingInvoices');
      },
      cancelEdit() {
        this.set('isEditing', false);
      }
    }
  });
});