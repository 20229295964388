define("client/pods/extensions/apps/tw/e-invoices/available-count-to-issue/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    availableCountToIssueThreshold: 50,
    isUnderThreshold: Ember.computed('_data.{availableCountToIssue,hasRemainingCount}', function () {
      if (this._data?.availableCountToIssue <= this.availableCountToIssueThreshold) {
        return true;
      }
      return false;
    })
  });
});