define("client/pods/components/websites/panels-display/sub-panels-pages/pages-display/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    init() {
      this._super(...arguments);
      if (this?.website?._data?.isSimpleSite) {
        const dirty = this.crud.setupDirty({
          adapterName: 'websites/pages',
          model: this.model
        });
        this.set('dirty', dirty);
      }
    },
    icon: Ember.computed('model._data.isHome', function () {
      const isHome = R.pathOr(false, ['model', '_data', 'isHome'])(this);
      return isHome ? 'fas fa-home' : 'fas fa-bars';
    })

    // pageName: computed('model._data.@each', function () {
    //   const pageData = R.pathOr({}, ['model', '_data'])(this)
    //   const pageName = R.prop('pageName')(pageData)

    //   if (pageData.isHome) {
    //     return pageName || this.intl.t('home page')
    //   }
    //   if (pageData.isDynamicPage) {
    //     return pageName || this.intl.t(pageData.name)
    //   }
    //   return R.propOr('no-name', 'name')(pageData)
    // })
  });
});