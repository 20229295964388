define("client/pods/components/extensions/apps/tw/e-invoices/invoice-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15; // required params
  // @document
  // @invalid
  // @toIssue
  // @onCloseDialogAction
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ExtensionsAppsTwEInvoicesInvoiceEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('terminal'), _dec4 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _class = class ExtensionsAppsTwEInvoicesInvoiceEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "terminalService", _descriptor3, this);
      _initializerDefineProperty(this, "eInvoicesService", _descriptor4, this);
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "issueErrors", _descriptor6, this);
      _initializerDefineProperty(this, "isReadonly", _descriptor7, this);
      _initializerDefineProperty(this, "custom", _descriptor8, this);
      _initializerDefineProperty(this, "toIssue", _descriptor9, this);
      _initializerDefineProperty(this, "invalid", _descriptor10, this);
      _initializerDefineProperty(this, "noAvailableNumbers", _descriptor11, this);
      _initializerDefineProperty(this, "invalidCount", _descriptor12, this);
      _initializerDefineProperty(this, "toIssueCount", _descriptor13, this);
      _initializerDefineProperty(this, "hasSetup", _descriptor14, this);
      _initializerDefineProperty(this, "document", _descriptor15, this);
      this.crud.addLists(this, ['extensions/apps/tw/e-invoices/carrier-types']);
    }
    *setupTask() {
      if (this.args.isAttentionRequired) {
        yield this.setupForAttentionRequiredTask.perform();
        this.hasSetup = true;
        return;
      }
      yield this.setupForDocumentWithoutInvoiceTask.perform();
      this.hasSetup = true;
    }
    _setupCustomData(document) {
      let telephone = document?._data?.billingTelephone;
      if (!telephone) {
        telephone = document?._data?.telephone;
      }
      const customData = Ember.Object.create();
      Ember.set(customData, 'telephone', telephone);
      Ember.set(customData, 'taxNumber', document?._data?.taxNumber);
      Ember.set(customData, 'companyName', document?._data?.companyName);
      Ember.set(customData, 'email', document?._data?.email);
      Ember.set(customData, 'address', document?._data?.address);
      Ember.set(customData, 'twEInvoiceCarrierType', document?._data?.twEInvoiceCarrierType || '');
      Ember.set(customData, 'twEInvoiceCarrierId', document?._data?.twEInvoiceCarrierId || '');
      Ember.set(customData, 'twEInvoicePrinterId', document?._data?.twEInvoicePrinterId || '');
      Ember.set(customData, 'twEInvoiceAllowPrintingFrom', document?._data?.twEInvoiceAllowPrintingFrom || '');
      Ember.set(customData, 'twEInvoiceUserKey', document?._data?.twEInvoiceUserKey || '');
      Ember.set(this, 'custom', customData);
    }
    setupTaxDetails(custom, companyDetails) {
      if (companyDetails.isUnsuccessful) {
        Ember.set(custom, 'companyName', '');
        Ember.set(custom, 'twEInvoiceCarrierId', '');
      }
      const value = companyDetails.value || companyDetails.taxNumber || '';
      const isMobileBarcode = value.startsWith('/');
      if (!value || isMobileBarcode && model?._data?.twEInvoiceCarrierType === 'b2b') {
        return;
      }
      let twEInvoiceCarrierType = '';
      const hasCompanyDetails = RA.isNotNilOrEmpty(companyDetails);
      if (hasCompanyDetails) {
        const companyName = companyDetails?.name || '';
        const taxNumber = companyDetails?.taxNumber || '';
        twEInvoiceCarrierType = 'b2b';
        Ember.set(custom, 'companyName', companyName);
        Ember.set(custom, 'taxNumber', taxNumber);
        Ember.set(custom, 'twEInvoiceCarrierId', '');
      } else {
        Ember.set(custom, 'companyName', '');
        Ember.set(custom, 'taxNumber', '');
      }
      if (isMobileBarcode) {
        twEInvoiceCarrierType = 'mobile';
        Ember.set(custom, 'twEInvoiceCarrierId', value);
        Ember.set(custom, 'taxNumber', '');
      } else {
        Ember.set(custom, 'twEInvoiceCarrierId', '');
      }
      Ember.set(custom, 'twEInvoiceCarrierType', twEInvoiceCarrierType);
    }
    *setupForAttentionRequiredTask() {
      let document = this.document;
      const invoice = this.args.invoice;
      const docKey = invoice?._data?.doc?._key;
      if (!document) {
        // fetch document
        document = yield this.crud.findTask.perform({
          adapterName: 'documents/sales/invoices',
          appendPath: `/${docKey}`
        });
        this.document = document;
      }
      this._setupCustomData(document);
      // already has invoice
      if (invoice) {
        // set(invoice, '_data.doc', document)
        this.toIssue = [invoice];
      }
    }
    *setupForDocumentWithoutInvoiceTask() {
      const document = this.document;
      this._setupCustomData(document);
      const filters = this.search.setupFilters({
        adapterName: 'extensions/apps/tw/e-invoices/filters',
        defaultFiltersData: {}
      });
      this.filters = filters;
      const docKey = document?._data?._key;

      // @NOTE: will set component.toIssue in service. ideally should be moved out of service
      yield this.eInvoicesService.fetchToIssueEInvoicesTask.perform({
        component: this,
        appendPath: docKey,
        filters: this.filters
      });
    }
    isAddressRequired() {
      if (this.custom?.taxNumber) {
        return true;
      }
      return false;
    }
    printBtnLabel() {
      if (!this.hasPrintBtnLabel) {
        return '';
      }
      return 'tw_print_eInvoices_now';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "terminalService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "issueErrors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isReadonly", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, false, this.args.isReadonly);
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "custom", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "toIssue", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "invalid", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "noAvailableNumbers", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "invalidCount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "toIssueCount", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasSetup", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "document", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.document || null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_setupCustomData", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "_setupCustomData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTaxDetails", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setupTaxDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupForAttentionRequiredTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupForAttentionRequiredTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupForDocumentWithoutInvoiceTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupForDocumentWithoutInvoiceTask"), _class.prototype), _class);
});