define("client/pods/channels/shop/campaigns/details/limits/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    constants: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set('childAdapters.products', 'products');
    },
    defaults() {
      return {
        useInventoryAsLimitedQty: false,
        overrideUseInventoryAsLimitedQty: false,
        salesLimitedType: this.get('constants.detailLimitTypes.campaign'),
        hasSalesLimitedQty: false,
        qty: 0
      };
    },
    populate(data, attrs) {
      data = this._transformProduct(data);
      let isOverSalesLimitedQty = true;
      data.overSalesLimitedQtyStatus = data?.calculatedStatusBeforeCart || '';
      if (R.equals(data.calculatedStatusBeforeCart, 'active')) {
        isOverSalesLimitedQty = false;
        data.overSalesLimitedQtyStatus = '';
      }
      data.isOverSalesLimitedQty = isOverSalesLimitedQty;
      const commissionBaseCostNew = this._transformCommissionBaseCost(data);
      // if (RA.isNotNilOrEmpty(commissionBaseCostNew)) {
      this.set('commissionBaseCostNew', commissionBaseCostNew);
      // }
      this._super(data, attrs);
    },
    _transformCommissionBaseCost(data) {
      let commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew')(data);
      // if (RA.isNotNilOrEmpty(commissionBaseCostNew)) {
      delete data.commissionBaseCostNew;
      const mapIndexed = R.addIndex(R.map);
      const mergeArrayOfObjects = (key, left, right) => {
        if (R.equals(key, 'setDetails') || R.equals(key, 'variantChildren')) {
          return mapIndexed((val, idx) => R.mergeDeepRight(val, right[idx]), left);
        }
        if (R.equals(key, 'choices') || R.equals(key, 'details')) {
          return mapIndexed((val, idx) => {
            return R.mergeDeepWithKey(mergeArrayOfObjects, val, right[idx]);
          })(left);
        }
        return right;
      };
      commissionBaseCostNew = R.pipe(R.pick(['choices', 'setDetails', 'variantChildren', 'itemKey', 'item', 'salesQty', 'campaignKey', 'isSet', 'isSetWithChoices', 'hasVariants']), R.mergeDeepWithKey(mergeArrayOfObjects, commissionBaseCostNew))(data);
      commissionBaseCostNew.master = data._key;
      commissionBaseCostNew.campaignKey = data.master;
      if (commissionBaseCostNew._key) {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          data: commissionBaseCostNew
        });
      } else {
        return this.dataManager.newRecord({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          data: commissionBaseCostNew
        });
      }
      // }
    },
    _transformProduct(data) {
      if (data.product) {
        const product = this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.products'),
          data: data.product
        });
        this.set('product', product);
        delete data.product;
      }
      return data;
    },
    overSalesLimitedQtyStatusClass: Ember.computed('_data.overSalesLimitedQtyStatus', function () {
      const status = this.get('_data.overSalesLimitedQtyStatus');
      return this.productsService.getStatusClass(status);
    }),
    calculatedAvailablePreOrderQtyOnlyBeforeCart: Ember.computed('_data.{calculatedAvailableQtyBeforeCart,calculatedAvailablePreOrderQtyBeforeCart}', 'limits.@each.synced', function () {
      let calculatedAvailablePreOrderQtyOnlyBeforeCart = this.getData('calculatedAvailablePreOrderQtyBeforeCart');
      const calculatedAvailableQtyBeforeCart = this.getData('calculatedAvailableQtyBeforeCart');
      const salesPreOrderLimitedQty = this.getData('salesPreOrderLimitedQty') || '0';
      if (R.gt(calculatedAvailableQtyBeforeCart, 0)) {
        calculatedAvailablePreOrderQtyOnlyBeforeCart = salesPreOrderLimitedQty;
      }
      return calculatedAvailablePreOrderQtyOnlyBeforeCart;
    })
  });
});