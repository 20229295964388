define("client/pods/channels/shop/campaigns/members-campaign-menu/collection/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/campaigns/members-campaign-menu/collection/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/shop/campaigns/members-campaign-menu/collection',
    modelObj: _model.default,
    idParam: 'userKey',
    idPrefix: 'channels/shop/campaigns/members-campaign-menu/collection'
  });
});