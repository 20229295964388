define("client/pods/components/documents/editor-main/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/utils/nventor", "client/mixins/doc-status", "client/mixins/dispatch", "client/mixins/sortable-actions", "client/pods/options/model", "ember-concurrency", "client/mixins/transporter", "client/mixins/doc-contacts", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _date, _settings, _nventor, _docStatus, _dispatch, _sortableActions, _model, _emberConcurrency, _transporter, _docContacts, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj; // import constants from '../../../../constants'
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * documents/editor-main component
   * purpose of this component is to allow for shared properties
   * of documents info and documents detail.
   * (as compared to editor mixin where its properties/functions are only for doc info)
   */
  var _default = _exports.default = Ember.Component.extend(_date.default, _docContacts.default, _docStatus.default, _dispatch.default, _settings.default, _sortableActions.default, _transporter.default, (_dec = Ember._action, _obj = {
    crud: Ember.inject.service(),
    big: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    settings: Ember.inject.service(),
    storage: Ember.inject.service(),
    classNames: [''],
    contactsList: null,
    productsList: null,
    isEditing: false,
    recalculateTotals: 0,
    // determines whether totals need to be recalculated
    alreadyAddedDetails: false,
    linkFromDetails: null,
    isVisibleLinkConflictsModal: false,
    showDispatchProcesses: false,
    hasManagePods: false,
    submitDraftAppendPath: 'draft',
    submitFinalAppendPath: 'final',
    showCancelInvoiceModal: false,
    bulkInsertInstructions: null,
    init() {
      this._super(...arguments);
      this.setup();
      const adapters = [{
        adapterName: 'users',
        appendPath: 'internal'
      }, 'units', 'taxes', 'taxMethods', 'discounts/types', 'documents/lists/discountmethods', 'paymentMethods', 'paymentTerms', 'paymentTerms/from', 'paymentTerms/end', 'documents/lists/deliverydatesoptions', 'dispatch/lists/processes', 'extensions/apps/tw/invoicing', 'extensions/apps/tw/e-invoices/carrier-types', 'toggles/onOff'];
      this.bulkInsertInstructions = {
        instructions: '',
        separator: '=',
        // only used for display. allow user to change later when needed:
        fields: ['item', 'qty', 'price']
      };
      const userData = this.storage.get('tokenData');
      if (userData.isAdmin) {
        this.set('hasManagePods', true);
      }
      const associatesContactsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          count: 20,
          isAssociates: true
        }
      });
      this.loadContactTask.perform(associatesContactsFilters);
      const docType = this.docType;
      adapters.pushObject(`documents/${docType}/terms`);
      this.crud.addLists(this, adapters);
    },
    setup() {
      // products lists is populated when searching for products
      this.set('contactsList', []);
      this.set('productsList', []);
      this.set('selectedPaymentMethods', []);
      this.set('docType', this.get('tab.config.docType'));
      this.set('docName', this.get('tab.config.docName'));
      this.set('dontFetchRegionCode', this.get('tab.config.dontFetchRegionCode'));
      this.set('hasDeliveryDate', this.get('tab.config.hasDeliveryDate'));
      this.set('hasValidityDate', this.get('tab.config.hasValidityDate'));
      this.set('hasTaxNumber', this.get('tab.config.hasTaxNumber'));
      this.set('hasCommission', this.get('tab.config.hasCommission'));
      this.set('adapterName', this.get('tab.config.adapterName'));
      const docType = this?.docType || '';
      let excludes = [{
        key: 'setType',
        value: 'isSetWithChoices'
      }, {
        key: 'setType',
        value: 'hasVariants'
      }];
      if (R.equals(docType, 'purchases')) {
        excludes = R.append({
          key: 'setType',
          value: 'isSet'
        })(excludes);
      }
      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes
        }
      });
      this.set('productsFilters', productsFilters);
    },
    afterLoadLists() {
      this._super(...arguments);
      this.set('listsIsLoaded', Math.random());
      this.setDefaultTerms();
    },
    // removed for now (will fix later on fixing pending)
    // linkDocWithShortcutTask: task(function * () {
    //   const isFromPendingShortcut = this.isFromPendingShortcut
    //   const isFromDocument = this.isFromDocument
    //   const isLinkingNeeded = this.isLinkingNeeded
    //   if ((isFromPendingShortcut || isFromDocument) && isLinkingNeeded) {
    //     const model = this.model
    //     const linksList = yield this.searchForLinksTask.perform()
    //     const details = model.getData('details')
    //     const detailHead = R.take(1, details)
    //     let filteredLinksList = []
    //     R.forEach(detail => {
    //       const detailKey = detail.getData('_key') || ''
    //       if (RA.isNotNilOrEmpty(detailKey)) {
    //         const filteredLink = R.find(R.pathEq(['_data', '_key'], detailKey), linksList)
    //         filteredLinksList = R.append(filteredLink, filteredLinksList)
    //       }
    //     })(details)
    //     if (RA.isNotNilOrEmpty(filteredLinksList)) {
    //       model.setData('details', detailHead)
    //       this.preLoadLinkedDetails(filteredLinksList, R.head(detailHead))
    //     }
    //     this.model.sumTotals()
    //   }
    // }),

    requireContact: Ember.computed('tabParent.isPos', 'model._data.contact', function () {
      const model = this.model;
      if (!model) {
        return true;
      }
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);
      if (isPos) {
        return false;
      }
      const contact = model?._data?.contact;
      if (contact) {
        return false;
      }
      const isPosDocument = R.pathEq(['_data', 'isPos'], true)(model);
      if (isPosDocument) {
        return false;
      }
      return true;
    }),
    loadContactTask: (0, _emberConcurrency.task)(function* (associatesContactsFilters) {
      const associatesContacts = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts',
        filters: associatesContactsFilters
      });
      this.set('associatesContacts', associatesContacts);
    }),
    async setDefaultTerms() {
      if (this.isNew) {
        const docType = this.docType;
        const docName = this.docName;
        const defaultTermsKey = await this.settings.getDefaultTerms(docType, docName);
        const terms = this.docTermsList || [];
        const found = terms.findBy('_data._key', defaultTermsKey);
        if (found) {
          this.loadTerms(found);
        }
      }
    },
    loadTerms(term) {
      const model = this.model;
      if (model && model?.setData) {
        model.setData('terms', term.getData('value'));
      } else {
        // why does this throw an error sometimes?
        _nventor.default.throw('model does not exist', model);
      }
    },
    allowTwInvoicing: Ember.computed('docType', 'docName', '', function () {
      const docType = this.docType;
      const docName = this.docName;
      return this.settings.allowTwInvoicing(docType, docName);
    }),
    docTermsList: Ember.computed('listsIsLoaded', 'docType', function () {
      const docType = this.docType;
      return this.get(`crud.lists.documents-${docType}-terms`);
    }),
    isAllowPrintInvoice: Ember.computed('docType', 'docName', 'model._data.status', function () {
      const docType = this.docType;
      const docName = this.docName;
      const model = this.model;
      const status = this.get('model._data.status');
      if (docType === 'sales' && docName === 'invoices') {
        if (status === 'final') {
          return true;
        }
        if (status === 'void') {
          if (model.get('hasEInvoices')) {
            return true;
          }
        }
      }
      return false;
    }),
    selectedTermLabel: Ember.computed('docTermsList', 'model._data.terms', function () {
      const docTermsList = this.docTermsList;
      const terms = this.model.getData('terms');
      if (terms && docTermsList) {
        return R.pipe(R.find(R.pathEq(['_data', 'value'], terms)), R.pathOr('', ['_data', 'label']))(docTermsList);
      }
      return '';
    }),
    printBtnsComponent: Ember.computed('docType', 'docName', function () {
      const docType = this.docType;
      const docName = this.docName;
      return `documents/${docType}/${docName}/print-btns`;
    }),
    /**
     * determines if document isDraft
     * (computed property)
     *
     * @type {Boolean}
     */
    isDraft: Ember.computed('model._data.status', function () {
      const status = this.get('model._data.status');
      if (status === this.get('constants.documentsStatus.final')) {
        return false;
      }
      return true;
    }),
    /**
     * determines if document isReadonly based on isEditing
     * isReadonly is opposite to isEditing
     * (computed property)
     *
     * @type {Boolean}
     */
    isReadonly: Ember.computed('isEditing', 'onSubmitTask.isRunning', 'model._data.isVoid', function () {
      if (this.get('model._data.isVoid')) {
        return true;
      }
      if (this.get('onSubmitTask.isRunning')) {
        return true;
      }
      if (this.isEditing === true) {
        return false;
      }
      return true;
    }),
    // isEditingInfo: computed('isEditing', 'isSplitting', 'isMerging', function () {
    //   if (this.get('isSplitting')) {
    //     return false
    //   }

    //   if (this.get('isMerging')) {
    //     return false
    //   }

    //   return this.get('isEditing')
    // }),

    /**
     * determine if there is a single or mulitple delivery dates (line by line)
     */
    hasSingleDeliveryDate: Ember.computed('model._data.deliveryDateOption', function () {
      const deliveryDateOption = this.get('model._data.deliveryDateOption');
      if (deliveryDateOption === this.get('constants.deliveryDateOptions.single')) {
        return true;
      }
      return false;
    }),
    hasDiscount: Ember.computed('model._data.{discountRate,discountAmount}', function () {
      // @TODO: test
      const model = this.model;
      const discRateBig = model.getDataBig('discountRate');
      const discAmountBig = model.getDataBig('discountAmount');
      if (discRateBig.gt(0) || discAmountBig.gt(0)) {
        return true;
      }
      return false;
    }),
    // /**
    //  * returns true if discount is a %
    //  * @type {Boolean}
    //  */
    // isDiscountRate: computed('model._data.discountType', function () {
    //   const model = this.get('model')
    //   const discType = model.getData('discountType')

    //   if (discType === this.get('constants.discountTypes.rate')) {
    //     return true
    //   }

    //   return false
    // }),

    /**
     * check if any salesPersons are assigned to contact
     * @type {Boolean}
     */
    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');
      if (Ember.isEmpty(salesPersons)) {
        return false;
      }
      return salesPersons;
    }),
    // _linkAttrInfo (linkFromInfoObject, key, linkFromKey) {
    //   console.log('=====link from info ===: ' + key)
    //   const linkFromInfo = linkFromInfoObject[key]

    //   console.log(linkFromInfo)

    //   return this._linkAttr(linkFromInfo, key, linkFromKey)
    // },

    // _linkAttr (linkFromDetail, key, linkFromKey) {
    //   const model = this.get('model')

    //   linkFromKey = linkFromKey || key

    //   let value = linkFromDetail.getData(linkFromKey)
    //   if (value !== '') {
    //     model.setData(key, value)
    //   }

    //   return true
    // },

    relatedComments: Ember.computed('model._data.details.[]', function () {
      const related = [];
      const model = this.model;
      if (model) {
        const details = model.getData('details', []);
        R.forEach(function (detail) {
          const fromMaster = R_.dotPath('_data.fromMaster')(detail);
          if (fromMaster) {
            if (!related.findBy('master', fromMaster)) {
              related.push({
                param: 'master',
                value: fromMaster
              });
            }
          }
        })(details);
      }
      return related;
    }),
    hasGroupsReturns: Ember.computed('model._data.groups.@each.synced', function () {
      const model = this.model || {};
      let hasGroupsReturns = false;
      if (model && this.model) {
        const groups = model.getData('groups') || [];
        R.forEach(group => {
          const groupReturnDetails = group.get('returnsDetails') || [];
          if (RA.isNotNilOrEmpty(groupReturnDetails)) {
            hasGroupsReturns = true;
          }
        })(groups);
      }
      return hasGroupsReturns;
    }),
    /**
       * gets delivery date to insert into new Detail
       * will return todays date as default, or will
       * return previous line
       */
    getNextDeliveryDateZ() {
      // check previous line's delivery date
      const lastDetail = this.getLastDetail();
      if (lastDetail) {
        const lastDeliveryDate = lastDetail.getData('deliveryDateZ');
        if (lastDeliveryDate) {
          return lastDeliveryDate;
        }
      }
      const model = this.model;
      return model.getData('deliveryDateZ') || this.getStartOfDayZ();
    },
    getLastDetail() {
      const model = this.model;
      if (model) {
        const details = model.getData('details');
        if (!Ember.isEmpty(details)) {
          return details.get('lastObject');
        }
      }
      return false;
    },
    async resetDetail(detail) {
      const model = this.model;
      model.resetDetail(detail);
      const taxes = R_.dotPath('crud.lists.taxes')(this);
      detail.setData('discountType', this.get('constants.discountTypes.rate'));
      const defaultTaxRate = await this.settings.getDefaultTaxRate(taxes);
      detail.setData('taxRate', defaultTaxRate);
      if (this.docName === 'orders' || this.docName === 'quotes') {
        const todayZ = this.getStartOfDayZ();
        detail.setData('deliveryDateZ', todayZ);
      }
      model.sumTotals();
    },
    _getLinkConflicts(linkFromInfoResolutions) {
      // const linkFromInfoResolutions = this.get('linkFromInfoResolutions') || []
      if (Ember.isEmpty(linkFromInfoResolutions)) {
        return [];
      }
      return linkFromInfoResolutions.filter(infoGroup => {
        const values = infoGroup.values.filter(item => item.value != null);
        // const values = infoGroup.values.filter(item => item.value !== '' && item.value != null)
        if (values.length > 1) {
          return true;
        }
        return false;
      });
    },
    loadLinked() {
      const model = this.model;
      this.set('isVisibleLinkConflictsModal', false);
      model.linkFromTemp();
      const linkFromDetails = this.linkFromDetails;
      linkFromDetails.filter(linkFromDetail => linkFromDetail.isEditorValue !== true).map(linkFromDetail => {
        const linkToDetail = this.linkToDetail;
        model.loadLinkedDetail(linkFromDetail, linkToDetail);
        this.onAddNewDetail();
        this.set('linkToDetail', this.get('model._data.details').get('lastObject'));
      });
      this.set('linkFromInfoResolutions', []);

      // const transporterKey = model.getData('transporterKey')
      // this.findAndLoadTransporterByKey(model, transporterKey)
    },
    searchProductsTask: (0, _emberConcurrency.task)(function* ({
      adapterName = 'products',
      query
    }, inputQuery = '') {
      query = inputQuery || query;
      if (query?.trim?.() !== '') {
        try {
          const filters = this.productsFilters;
          // filters.setData('query', query)

          const results = yield this.searchRecordsTask.perform({
            adapterName,
            filters,
            query
          });
          return results;
        } catch (e) {
          console.error(e);
        }
      }
    }),
    onBulkInsertDetailsTask: (0, _emberConcurrency.task)(function* (bulkInsertInstructions = {}) {
      try {
        const detailLines = R.pipe(R.propOr('', 'instructions'), R.split(/\n/), R.trim, R.reject(RA.isNilOrEmpty), R.map(detailLine => {
          // [name] == [qty] == [price]
          // detail name == 123 == 12.2
          const separator = bulkInsertInstructions.separator || '=';
          const regex = new RegExp(separator, 'g');
          const detailRawData = R.pipe(R.split(regex), R.trim)(detailLine);
          const item = detailRawData?.[0] || '';
          if (!item) {
            return;
          }
          const qty = Number(detailRawData?.[1]) || null;
          const price = Number(detailRawData?.[2]) || null;
          return {
            item,
            qty,
            price
          };
        }), R.reject(RA.isNilOrEmpty))(bulkInsertInstructions);

        // search product, then add to detail
        const taxesList = R_.dotPath('crud.lists.taxes')(this);
        const model = this.model;
        const q = R.map(detailLine => {
          const item = detailLine?.item || '';
          return this.searchProductsTask.perform({
            query: item
          });
        })(detailLines);
        const results = yield (0, _emberConcurrency.all)(q);
        RA.mapIndexed((itemSearchResults, index) => {
          let detailModel;
          if (index === 0) {
            const details = model?._data?.details || [];
            const last = details.get('lastObject');
            if (!last?.item && details.length <= 1) {
              model.setData('details', []);
            } else if (!last?.item) {
              details.removeObject(last);
            }
          }
          detailModel = model.newBlankDetail({
            taxesList,
            bulkInsert: true
          });
          const detailLine = detailLines[index] || '';
          let item = detailLine.item;
          let matchingResult = R.find(R.pathEq(['_data', 'code'], item))(itemSearchResults);
          if (!matchingResult) {
            matchingResult = R.find(R.pathEq(['_data', 'name'], item))(itemSearchResults);
          }
          if (!matchingResult) {
            matchingResult = R.path([0])(itemSearchResults);
          }
          let itemCode = '';
          let itemKey = '';
          if (RA.isNotNilOrEmpty(matchingResult)) {
            itemCode = matchingResult?._data?.code || '';
            itemKey = matchingResult?._data?._key || '';
            detailModel.set('autoLoadProductQuery', item);
            detailModel.set('autoLoadProduct', matchingResult);
            detailModel.set('autoLoadProductResults', itemSearchResults);
            detailModel.setData('itemKey', itemKey);
            detailModel.setData('itemCode', itemCode);
          } else {
            detailModel.setData('itemCode', item);
          }
          if (RA.isNotNil(detailLine?.qty)) {
            detailModel.setData('qty', detailLine?.qty);
          }
          if (RA.isNotNil(detailLine?.price)) {
            detailModel.setData('price', detailLine?.price);
          }
          return detailModel;
        })(results);
      } catch (e) {
        console.error(e);
      }
    }),
    selectConflictResolution(conflictCategory, valueGroup) {
      const model = this.model;
      const key = conflictCategory.param;
      const linkFromDetail = valueGroup.get('linkFromDetails').get('firstObject');
      conflictCategory.set('selected', []);
      conflictCategory.get('selected').pushObject(valueGroup);
      model.preLinkInfo(linkFromDetail, key);
    },
    sendEmailTask: (0, _emberConcurrency.task)(function* ({
      email,
      params
    }) {
      const model = this.model;
      const key = model.getData('_key');
      const adapterName = this.adapterName;
      const adapter = this.server.getAdapter(adapterName);
      const payload = R.mergeRight(email._data, params);
      const result = yield adapter.call({
        method: 'POST',
        appendPath: `emails/${key}`,
        data: payload
      });
      return result;
    }).drop(),
    removeAllDetails() {
      const model = this.model;
      model.removeAllDetails();
      this.onAddNewDetail();
    },
    preLoadLinkedDetails(linkFromDetails, linkToDetail) {
      // @TODO: this can be done whilst adding linked detail?
      if (!Ember.isArray(linkFromDetails)) {
        linkFromDetails = [linkFromDetails];
      }
      const model = this.model;
      const editorValue = _nventor.default.createModel(_model.default, {
        doc: model.serialize()
      }, {
        isEditorValue: true
      });
      linkFromDetails.pushObject(editorValue);
      const possibleConflictProperties = model.get('linkAttrs').filter(key => {
        if (key.excludeForDocName) {
          if (!Ember.isArray(key.excludeForDocName)) {
            key.excludeForDocName = [key.excludeForDocName];
          }
          if (key.excludeForDocName.indexOf(this.docName) > -1) {
            return false;
          }
        }
        return true;
      });
      const linkFromInfoResolutions = possibleConflictProperties.map(key => {
        let component;
        let param;
        let group;
        let label;
        let translate;
        let translatePrefix;
        if (_nventor.default.confirm.isObject(key)) {
          if (key.param) {
            param = key.param;
          }
          if (key.group) {
            group = key.group;
          }
          if (key.translate) {
            translate = key.translate;
          }
          if (key.translatePrefix) {
            translatePrefix = key.translatePrefix;
          }
          component = key.component;
          label = key.label || param;
        } else {
          param = key;
        }
        if (!component) {
          component = 'documents/links-conflict/generic-conflict';
        }
        let numberOfDifferentValues = linkFromDetails.map(item => {
          if (group) {
            return JSON.stringify(group.map(param => _nventor.default.confirm.get(item._data.doc, param, '')));
          } else {
            return _nventor.default.confirm.get(item._data.doc, param, '');
          }
        }).filter((value, index, self) => self.indexOf(value) === index);
        if (group) {
          numberOfDifferentValues = numberOfDifferentValues.map(JSON.parse);
        }
        const linkFromInfoResolutionsGroup = Ember.Object.create({
          param: param,
          label: label || param,
          component,
          translate,
          translatePrefix,
          group: group,
          selected: [],
          values: numberOfDifferentValues.map(value => {
            let filteredLinkFromDetails;
            if (group) {
              filteredLinkFromDetails = group.reduce((filtered, param, index) => {
                return filtered.filter(item => _nventor.default.confirm.get(item._data.doc, param, '') === value[index]);
              }, linkFromDetails);
              value = group.reduce((valueObj, param, index) => {
                valueObj[param] = value[index];
                return valueObj;
              }, {});
            } else {
              filteredLinkFromDetails = linkFromDetails.filter(item => _nventor.default.confirm.get(item._data.doc, param, '') === value);
            }
            return Ember.Object.create({
              value: value,
              linkFromDetails: filteredLinkFromDetails
            });
          })
        });

        // if key == paymentMethod and have consignments, remove other values not consignments
        if (R.equals(param, 'paymentMethod')) {
          const values = linkFromInfoResolutionsGroup?.values || [];
          const paymentMethodKeys = R.pipe(R.map(data => {
            const paymentMethodKey = data?.value?.paymentMethodKey || '';
            return paymentMethodKey;
          }), R.uniq)(values);
          if (R.includes('consignment', paymentMethodKeys)) {
            linkFromInfoResolutionsGroup.values = R.filter(data => {
              const paymentMethodKey = data?.value?.paymentMethodKey || '';
              if (R.equals(paymentMethodKey, 'consignment')) {
                return true;
              }
              return false;
            })(values);
          }
        }
        this.selectConflictResolution(linkFromInfoResolutionsGroup, linkFromInfoResolutionsGroup.get('values').get('firstObject'));
        return linkFromInfoResolutionsGroup;
      });
      this.set('linkFromDetails', linkFromDetails);
      this.set('linkToDetail', linkToDetail);
      let hasLinkConflicts = this._getLinkConflicts(linkFromInfoResolutions);
      const isFromPendingShortcut = this.isFromPendingShortcut;
      const isFromDocument = this.isFromDocument;
      if (!Ember.isEmpty(hasLinkConflicts)) {
        this.set('hasLinkConflicts', hasLinkConflicts);
        this.set('isVisibleLinkConflictsModal', true);
        if (isFromPendingShortcut || isFromDocument) {
          this.loadLinked();
        }
      } else {
        this.set('hasLinkConflicts', []);
        this.set('isVisibleLinkConflictsModal', false);
        this.loadLinked();
      }
    },
    actions: {
      sumTotals() {
        this.model.sumTotals();
      },
      loadLinked() {
        this.loadLinked();
      },
      removeDetail(detail) {
        const model = this.model;
        const details = model.getData('details');
        // only remove detail if current line is not last line
        if (details.get('lastObject').getData('index') === detail.getData('index')) {
          this.resetDetail(detail);
        } else {
          model.removeDetail(detail);
        }

        // recalculate totals
        model.sumTotals();
      },
      resetDetail(detail) {
        this.resetDetail(detail);
      },
      removeAllDetails() {
        this.removeAllDetails();
      },
      toggleIsVoidStatus() {
        const model = this.model;
        let isVoid = model.getData('isVoid') || false;
        if (isVoid) {
          isVoid = false;
        } else {
          isVoid = true;
        }
        model.setData('isVoid', isVoid);
      },
      selectConflictResolution(conflictCategory, valueGroup) {
        this.selectConflictResolution(conflictCategory, valueGroup);
      },
      setupSendEmail() {
        const model = this.model;
        const tokenData = this.storage.get('tokenData');
        const docType = this.docType;
        const docName = this.docName;
        let docNo = model.getData('docNo');
        if (model.getData('ref') && model.getData('sendRefAsDocNo')) {
          docNo = model.getData('ref');
        }
        const subject = this.intl.t(`${docType}-${docName}`) + ' - #' + docNo;
        const isUseCompanyEmail = this?.settings?.settingsModel?.getData('isUseCompanyEmail') || false;
        let fromName = tokenData.name;
        let fromAddress = tokenData.email;
        if (isUseCompanyEmail) {
          fromName = this?.settings?.settingsModel?.getData('emailFromName') || '';
          fromAddress = this?.settings?.settingsModel?.getData('emailFromAddress') || '';
        }
        const email = Ember.Object.create({
          _data: {
            toAddress: model.getData('email'),
            toName: model.getData('contact'),
            fromName,
            fromAddress,
            subject
          }
        });
        this.set('email', email);
      },
      setupSendPaymentReminderEmail() {
        const model = this.model;
        const tokenData = this.storage.get('tokenData');
        const docType = this.docType;
        const docName = this.docName;
        let docNo = model.getData('docNo');
        let subject;
        if (model.getData('ref') && model.getData('sendRefAsDocNo')) {
          docNo = model.getData('ref');
          subject = this.intl.t(`email_payment_reminder_subject`) + ' - ' + docNo;
        } else {
          docNo = '#' + model.getData('docNo');
          subject = this.intl.t(`${docType}-${docName}`) + ' - #' + docNo;
        }
        const isUseCompanyEmail = this?.settings?.settingsModel?.getData('isUseCompanyEmail') || false;
        let fromName = tokenData.name;
        let fromAddress = tokenData.email;
        if (isUseCompanyEmail) {
          fromName = this?.settings?.settingsModel?.getData('emailFromName') || '';
          fromAddress = this?.settings?.settingsModel?.getData('emailFromAddress') || '';
        }
        const email = Ember.Object.create({
          _data: {
            toAddress: model.getData('email'),
            toName: model.getData('contact'),
            fromName,
            fromAddress,
            subject
          }
        });
        this.set('email', email);
      },
      clearSendEmail() {
        this.set('email', null);
      },
      async sendEmail(email, onCloseDialogAction, params = {}) {
        await this.sendEmailTask.perform({
          email,
          params
        });
        onCloseDialogAction();
      },
      cancelSendEmail(onCloseDialogAction) {
        onCloseDialogAction();
        this.set('email', null);
      },
      loadTerms(term) {
        this.loadTerms(term);
      },
      toggleDispatchProcesses() {
        this.toggleProperty('showDispatchProcesses');
      },
      showPreDispatchProcessDialog() {
        this.set('showPreDispatchProcess', true);
      },
      closePreDispatchProcessDialog() {
        this.set('showPreDispatchProcess', false);
      },
      showWaybills(model, dispatchProcess) {
        if (!R.is(Array, model) && dispatchProcess.getData('code') === 'dispatched' && model.getData('transporterExtension') === 'hct') {
          this.set('showWaybills', true);
        }
      },
      quickLinkAllQtys(isQuickLinkAll) {
        const details = this.model.getData('details') || [];

        // need to first set all as 0, otherwise detail.remainingQty will be wrong if user already entered a qty
        let newDetails = R.map(detail => {
          detail.setData('qty', 0);
          return detail;
        })(details);
        if (isQuickLinkAll) {
          newDetails = R.map(detail => {
            detail.setData('qty', detail.remainingQty);
            return detail;
          })(details);
        }
        this.model.setData('details', newDetails);
        this.model.sumTotals();
      },
      afterReturns(saved, savedOptions = {}) {
        if (savedOptions.isAutoCancelInvoice) {
          this.set('showCancelInvoiceModal', true);
        } else if (saved) {
          if ((saved?.model?._data?.isVoid === true || saved?._data?.isVoid === true) && this.docType === 'sales' && this.docName === 'invoices') {
            if (saved.lastSuccessfulEInvoiceNumber) {
              this.set('showCancelInvoiceModal', true);
            }
          }
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "preLoadLinkedDetails", [_dec], Object.getOwnPropertyDescriptor(_obj, "preLoadLinkedDetails"), _obj), _obj));
});