define("client/helpers/tconcat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute(params, hash) {
      let first = params[0];
      const seperator = params[1];
      let second = params[2];
      let string;
      let translate = true;
      if (hash.translate === false) {
        translate = false;
      }
      if (translate) {
        return this.intl.t(first) + seperator + this.intl.t(second);
      }
      string = first + seperator + second;
      return string;
    }
  });
});