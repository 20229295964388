define("client/helpers/percentage", ["exports", "client/mixins/big", "ramda"], function (_exports, _big, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    compute(params, hash) {
      const value = params[0];
      const defaultValue = params[1];
      const autoCalculate = R.propOr(true, 'autoCalculate')(hash);
      const negative = R.propOr(false, 'negative')(hash);
      let returnValue = '';
      if (value != null && value !== '') {
        if (autoCalculate) {
          returnValue = this.newBig(value).times(100).toFixed() + '%';
          return returnValue;
        }
        returnValue = value + '%';
        return returnValue;
      }
      if (defaultValue != null) {
        returnValue = defaultValue;
      }
      if (negative) {
        return `- ${returnValue}`;
      }
      return returnValue;
    }
  });
});