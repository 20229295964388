define("client/pods/components/messages/tab-container/component", ["exports", "ramda", "client/mixins/crud", "ember-concurrency"], function (_exports, R, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    searchService: Ember.inject.service('search'),
    filtersAdapterName: 'messages/users/filters',
    adapterName: 'messages/users',
    autoFetchToggle: true,
    didInsertElement() {
      this._super(...arguments);
      const filters = this.searchService.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'chatUsers',
          messageProvider: 'line'
        }
      });
      this.set('filters', filters);
      this.fetchDefaultDataTask.perform();
      this.usersAutoFetch();
    },
    usersAutoFetch() {
      const autoFetchToggle = this.autoFetchToggle;
      if (autoFetchToggle) {
        setTimeout(() => {
          this.fetchDefaultDataTask.perform();
          this.usersAutoFetch();
        }, 10000);
      }
    },
    fetchDefaultDataTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      yield this.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters,
        resultsProperty: 'chatUsers'
      });
    }),
    latestUsers: Ember.computed('chatUsers.@each.{synced,timestampZ}', function () {
      const users = this.chatUsers || [];
      return R.sortWith([R.descend(R.path(['_data', 'timestampZ']))])(users);
    }),
    display(model, additionalOptions = {}) {
      const panelOptions = {
        model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    },
    actions: {
      displayMessageWindow(chatUserModel) {
        this.display(chatUserModel);
      },
      usersAutoFetchAction() {
        this.usersAutoFetch();
      }
    }
  });
});