define("client/pods/simple-editor/lists/styling/button-images-types/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: this.constants.buttonImagesTypes.icon,
        value: this.constants.buttonImagesTypes.icon
      }, {
        label: this.constants.buttonImagesTypes.image,
        value: this.constants.buttonImagesTypes.image
      }];
      return _nventor.default.resolve(data);
    }
  });
});