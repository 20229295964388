define("client/pods/components/channels/shop/campaigns/tab-container/component", ["exports", "ramda", "client/mixins/crud", "client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by", "ramda-extension", "ramda-adjunct", "ember-concurrency", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, R, _crud, _campaignsListBy, R_, RA, _emberConcurrency, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_campaignsListBy.default, _crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    users: Ember.inject.service(),
    results: null,
    allowDuplicating: true,
    query: '',
    adapterName: 'channels/shop/campaigns',
    filtersAdapterName: 'channels/shop/campaigns/filters',
    showResults: false,
    showResultsWithStatus: false,
    menuBarList: Ember.A(['showResults', 'showResultsWithStatus']),
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['automations/when', 'automations/criterias', 'channels/shop/campaigns/lists/menu', 'channels/shop/shipping-zones/all', 'channels/shop/shipping-zones/lists/rate-method', 'channels/shop/shipping-zones/lists/rate-method/free-shipping', 'contacts/lists/tags', 'discounts/types', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'statuses', 'availability', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.set('showStarred', true);
      this.set('filters', filters);
      const upcomingCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'upcoming',
          count: 10
        }
      });
      this.set('upcomingCampaignStatusFilter', upcomingCampaignStatusFilter);
      const inProgressCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'inProgress',
          count: 10
        }
      });
      this.set('inProgressCampaignStatusFilter', inProgressCampaignStatusFilter);
      const expiredNoCommissionDataCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'expiredNoCommissionData',
          count: 10
        }
      });
      this.set('expiredNoCommissionDataCampaignStatusFilter', expiredNoCommissionDataCampaignStatusFilter);
      const expiredHaveCommissionDataCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'expiredHaveCommissionData',
          count: 10
        }
      });
      this.set('expiredHaveCommissionDataCampaignStatusFilter', expiredHaveCommissionDataCampaignStatusFilter);
      const noDateCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'noDate',
          count: 10
        }
      });
      this.set('noDateCampaignStatusFilter', noDateCampaignStatusFilter);
      const userKey = this.users.getUserKey();
      const campaignsBookmarkFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/members-campaign-menu/filters',
        defaultFiltersData: {
          sort: [{
            by: 'timestampZ_latest',
            direction: 'DESC'
          }],
          userKey,
          menuId: 'channels/shop/campaigns/panels-display'
        }
      });
      this.set('campaignsBookmarkFilters', campaignsBookmarkFilters);
      const pinnedCampaignFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('pinnedCampaignFilter', pinnedCampaignFilter);
    },
    afterLoadLists() {
      this._super(...arguments);
      this.loadTabTask.perform();
    },
    /**
     * @param {HTMLDivElement} tabHeader 
     * @void
     */
    updateStickyTop(tabHeader) {
      const top = tabHeader?.offsetTop;
      if (top) {
        tabHeader.style.top = `${top}px`;
      }
    },
    getCustomizedCampaignsBookmarkTask: (0, _emberConcurrency.task)(function* () {
      const campaignsBookmarkMemberMenu = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/members-campaign-menu/collection',
        filters: this.campaignsBookmarkFilters
      });
      this.set('campaignsBookmarkMemberMenu', campaignsBookmarkMemberMenu);
    }),
    resultsProxy: Ember.computed('synced', 'results', function () {
      const results = this.results || [];
      const userCampaignMenu = this?.campaignsBookmarkMemberMenu?.userCampaignMenu || [];
      const userCampaignMenuKey = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('_key'), R.reject(RA.isNilOrEmpty))(userCampaignMenu);
      return R.map(result => {
        const campaignKey = result.getData('_key') || '';
        if (R.includes(campaignKey, userCampaignMenuKey)) {
          result.setData('isPinned', true);
        }
        return result;
      })(results);
    }),
    *pinMenuTask(menu) {
      const campaignsBookmark = this.campaignsBookmark || [];
      const lastIndex = campaignsBookmark.length || 0;
      const isPinned = menu.getData('isPinned');
      const campaignKey = menu.getData('_key');
      const campaignName = menu.getData('name');
      if (isPinned) {
        const campaignsBookmarkMemberMenuCollection = this?.campaignsBookmarkMemberMenu?._data?.collection || [];
        const toRemove = R.find(R.pathEq(['_data', 'campaignKey'], campaignKey))(campaignsBookmarkMemberMenuCollection);
        menu.setData('isPinned', false);
        if (toRemove) {
          yield this.crud.removeRecordTask.perform({
            adapterName: 'channels/shop/campaigns/members-campaign-menu',
            model: toRemove,
            noConfirm: true
          });
        }
      } else {
        const userKey = this.users.getUserKey();
        menu.setData('userKey', userKey);
        menu.setData('menuId', 'channels/shop/campaigns/panels-display');
        menu.setData('index', lastIndex);
        menu.setData('campaignKey', campaignKey);
        menu.setData('campaignName', campaignName);
        yield this.crud.createRecordTask.perform({
          adapterName: 'channels/shop/campaigns/members-campaign-menu',
          model: menu
        });
      }

      // yield this.getCustomizedCampaignsBookmarkTask.perform()
    },
    loadTabTask: (0, _emberConcurrency.task)(function* () {
      if (this.preloadedModel) {
        this.display(this.preloadedModel);
        return true;
      }
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        Ember.set(this, 'filters.showResults', false);
        const model = loadInstructions.getData('model');
        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
        this.schedule();
        Ember.set(this, 'filters.showResults', true);
        this.set('filters.showResults', true);
        yield this.getCustomizedCampaignsBookmarkTask.perform();
      }
    }),
    dash(results) {
      const menuActionsList = R_.dotPath('crud.lists.channels-shop-campaigns-lists-menu')(this) || [];
      const found = menuActionsList.findBy('_data.component', 'channels/shop/campaigns/panels-dash');
      if (!found) {
        return this.schedule();
      }
      if (!results) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
      }
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-dash'
      };
      return this.tabsManager.replaceSubTab(tab, panelOptions);
    },
    schedule() {
      this.set('filters.showResults', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-schedule'
      };
      this.tabsManager.replaceSubTab(tab, panelOptions);
    },
    display(model, {
      tabOptions
    } = {}) {
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-display',
        model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },
    new(panelOptions) {
      this.set('filters.showResults', false);

      // add new contact sub tab
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions);
    },
    edit(model, {
      tabOptions
    } = {}) {
      this.set('filters.showResults', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-edit',
        model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },
    actions: {
      dash() {
        this.set('filters.showResults', false);
        // should deselect results if any..
        // this.deselectResult();
        this.dash();
      },
      schedule() {
        // should deselect results if any..
        // this.deselectResult();
        this.schedule();
      },
      display(model, {
        tabOptions
      } = {}) {
        this.set('filters.showResults', false);
        if (model) {
          this.display(...arguments);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },
      new(panelOptions) {
        this.new(...arguments);
      },
      editAutomationItem(automation, campaign, item) {
        this.set('filters.showResults', false);
        const tab = this.tab;
        const panelOptions = {
          component: 'channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-editor',
          campaign,
          automation,
          item
        };
        this.tabsManager.replaceSubTab(tab, panelOptions);
      }
    },
    dashLinked: (0, _emberConcurrency.task)(function* (options) {
      // should different adapter with 'channels/shop/campaigns'
      const adapterName = 'channels/shop/campaigns/linked';
      const linkedFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      linkedFilters.setData('linkedOption', options);
      this.set('linkedFilters', linkedFilters);
      yield this.fetchDefaultDataTask.perform({
        adapterName,
        filters: linkedFilters
      });
      const filters = this.filters;
      filters.set('showResults', false);
      filters.set('showResultsWithStatus', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-dash-linked'
      };
      return this.tabsManager.replaceSubTab(tab, panelOptions);
    }),
    displayPinnedCampaignTask: (0, _emberConcurrency.task)(function* (campaignKey) {
      const pinnedCampaignFilter = this?.pinnedCampaignFilter || {};
      if (RA.isNotNilOrEmpty(pinnedCampaignFilter)) {
        pinnedCampaignFilter.setData('campaignKey', campaignKey);
        const results = yield this.searchInputTask.perform({
          adapterName: this.adapterName,
          filters: pinnedCampaignFilter
        });
        const campaignModel = _nventor.default.safeHeadOr({}, results);
        if (RA.isNilOrEmpty(campaignModel)) {
          return;
        }
        this.display(campaignModel);
      }
    }),
    showResults() {
      Ember.set(this, 'filters.showResults', true);
      this.toggleShowResults();
    },
    toggleShowResults() {
      Ember.set(this, 'filters.showResultsWithStatus', false);
    },
    toggleShowResultsWithStatusTask: (0, _emberConcurrency.task)(function* /* event, btnComponent */
    () {
      const toggleValue = this.filters?.showResultsWithStatus || false;
      if (!toggleValue) {
        Ember.set(this, 'filters.showResults', true);
        return;
      }
      const upcomingCampaignStatusFilter = this.upcomingCampaignStatusFilter;
      const inProgressCampaignStatusFilter = this.inProgressCampaignStatusFilter;
      const expiredNoCommissionDataCampaignStatusFilter = this.expiredNoCommissionDataCampaignStatusFilter;
      const expiredHaveCommissionDataCampaignStatusFilter = this.expiredHaveCommissionDataCampaignStatusFilter;
      const noDateCampaignStatusFilter = this.noDateCampaignStatusFilter;
      if (RA.isNilOrEmpty(upcomingCampaignStatusFilter?.results)) {
        yield this.listByTask.perform({
          adapterName: this.adapterName,
          filters: upcomingCampaignStatusFilter,
          resultsProperty: 'results'
        });
      }
      if (RA.isNilOrEmpty(inProgressCampaignStatusFilter?.results)) {
        yield this.listByTask.perform({
          adapterName: this.adapterName,
          filters: inProgressCampaignStatusFilter,
          resultsProperty: 'results'
        });
      }
      if (RA.isNilOrEmpty(expiredNoCommissionDataCampaignStatusFilter?.results)) {
        yield this.listByTask.perform({
          adapterName: this.adapterName,
          filters: expiredNoCommissionDataCampaignStatusFilter,
          resultsProperty: 'results'
        });
      }
      if (RA.isNilOrEmpty(expiredHaveCommissionDataCampaignStatusFilter?.results)) {
        yield this.listByTask.perform({
          adapterName: this.adapterName,
          filters: expiredHaveCommissionDataCampaignStatusFilter,
          resultsProperty: 'results'
        });
      }
      if (RA.isNilOrEmpty(noDateCampaignStatusFilter?.results)) {
        yield this.listByTask.perform({
          adapterName: this.adapterName,
          filters: noDateCampaignStatusFilter,
          resultsProperty: 'results'
        });
      }
      Ember.set(this, 'filters.showResults', false);
    }),
    toggleCampaignStatusAndListByTask: (0, _emberConcurrency.task)(function* (campaignStatusToggled) {
      let newCampaignStatus = R.clone(campaignStatusToggled);
      const filters = this.filters;
      const currentCampaignStatus = filters.getData('campaignStatus') || '';
      if (currentCampaignStatus === campaignStatusToggled) {
        newCampaignStatus = '';
        filters.setData('campaignStatus', '');
      }
      yield this.listByTask.perform({
        campaignStatus: newCampaignStatus,
        filters,
        resultsProperty: 'results',
        component: this
      });
      Ember.set(this, 'filters.showResults', true);
      Ember.set(this, 'filters.showResultsWithStatus', false);
    })
  }, _applyDecoratedDescriptor(_obj, "pinMenuTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "pinMenuTask"), _obj), _applyDecoratedDescriptor(_obj, "edit", [_dec], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _applyDecoratedDescriptor(_obj, "showResults", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showResults"), _obj), _applyDecoratedDescriptor(_obj, "toggleShowResults", [_dec3], Object.getOwnPropertyDescriptor(_obj, "toggleShowResults"), _obj), _obj));
});