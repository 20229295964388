define("client/pods/channels/shop/rewards/member-levels/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/constants"], function (_exports, _model, R, RA, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        name: '',
        description: '',
        requiredPoints: '',
        type: '',
        status: _constants.default.status.active,
        expiryTime: 1,
        expiryPeriod: _constants.default.timePeriods.years,
        isNeverExpire: false,
        productTags: []
      };
    },
    populate(data, attrs) {
      const rewardsEvents = R.propOr([], 'rewardsEvents')(data);
      attrs.selectedRewardEvents = R.pipe(R.pluck('type'), R.reject(RA.isNilOrEmpty), R.append('order'), R.uniq)(rewardsEvents);
      data.rewardsEvents = this.transformRewardEvents(data);
      this._super(data, attrs);
    },
    transformRewardEvents(data) {
      const rewardsEvents = R.propOr([], 'rewardsEvents')(data);
      const sortedTypes = ['signup', 'order', 'birthday', 'referral'];
      return R.pipe(R.map(rewardEvent => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/member-levels/rewards-events',
          data: rewardEvent
        });
      }), R.sortBy(R.pipe(R.path(['_data', 'type']), R.flip(R.indexOf)(sortedTypes))))(rewardsEvents);
    },
    addRewardEvent(data) {
      let rewardsEvents = this.getData('rewardsEvents') || [];
      const rewardEventModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/rewards/member-levels/rewards-events',
        data
      });
      rewardsEvents.pushObject(rewardEventModel);
      const sortedTypes = ['signup', 'order', 'birthday', 'referral'];
      rewardsEvents = R.sortBy(R.pipe(R.path(['_data', 'type']), R.flip(R.indexOf)(sortedTypes)))(rewardsEvents);
      this.setData('rewardsEvents', rewardsEvents);
    }
  });
});