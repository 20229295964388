define("client/pods/settings/wizard/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SettingsWizardAdapter extends _adapter.default.extend(_adaptersNew.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelObj", _model.default);
      _defineProperty(this, "idParam", false);
    }
    // entities: use FormEntity > context
    // updateProps: use FormRow || FormInputRow > context
    // (should be the same as saved data variable key)
    findAll() {
      const data = [{
        component: 'settings/settings-editor/basic-editor',
        label: 'contact info',
        value: 'basic',
        containerId: 'information',
        resource: 'api/protected/settings/wizard',
        tabType: 'reuseSubTab',
        entities: ['logo', 'contactInfo'],
        updateProps: ['logo', 'company', 'companyTel', 'companyFax', 'address', 'taxNumber', 'shopUrlPrefix'],
        _isNewTab: true
      }, {
        component: 'settings/settings-editor/basic-editor',
        label: 'banking details',
        value: 'bankingDetails',
        containerId: 'information',
        resource: 'api/protected/settings/wizard',
        tabType: 'reuseSubTab',
        entities: ['bankingDetails'],
        updateProps: ['bankingDetails'],
        _isNewTab: true
      }, {
        component: 'settings/settings-editor/basic-editor',
        label: 'taxes',
        value: 'taxes',
        containerId: 'taxes',
        resource: 'api/protected/settings/wizard',
        tabType: 'reuseSubTab',
        entities: ['defaultTaxRate'],
        updateProps: ['taxRateKey'],
        _isNewTab: true
      }, {
        component: 'settings/settings-editor/basic-editor',
        label: 'emails',
        value: 'emails',
        containerId: 'emails',
        resource: 'api/protected/settings/wizard',
        tabType: 'reuseSubTab',
        entities: ['emailSettings'],
        updateProps: ['emailFromName', 'emailFromAddress', 'emailSignature', 'isUseCompanyEmail'],
        _isNewTab: true
      }];
      return _nventor.default.resolve(data);
    }
  }
  _exports.default = SettingsWizardAdapter;
});