define("client/pods/components/payments/card/table-view/panels-activity/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    tagName: '',
    crud: Ember.inject.service(),
    hideExcl: true,
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['payments/card/lists/statuses', 'filters/count']);
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters',
        defaultFiltersData: {}
      });
      this.set('campaignFilters', campaignFilters);
    },
    toggleView() {
      this.toggleProperty('showDetails');
    },
    actions: {
      loadShopSource(shop) {
        const filters = this.filters;
        filters.setData('sourceKey', shop.getData('_key'));
        this.onSearchTask.perform({
          adapterName: 'payments/card',
          filters
        });
      },
      unloadShopSource() {
        const filters = this.filters;
        filters.setData('sourceKey', '');
        this.onSearchTask.perform({
          adapterName: 'payments/card',
          filters
        });
      },
      selectDispatchProcesses() {
        const filters = this.filters;
        const dispatchProcessesModels = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
        const dispatchProcesses = filters.getData('dispatchProcesses');
        const dispatchProcessesCriterias = R.pipe(R.filter(dispatchProcess => {
          return R.includes(dispatchProcess.getData('code'), dispatchProcesses);
        }), R.map(R.path(['_data', 'criterias'])))(dispatchProcessesModels);
        filters.setData('multipleDispatchProcessesCriterias', dispatchProcessesCriterias);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "toggleView", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleView"), _obj), _obj));
});