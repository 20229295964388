define("client/pods/wizards/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WizardsModel = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed('synced', 'crud.lists.application-lists-wizards.[]'), _class = class WizardsModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
    }
    populate(data = {}) {
      data.collection = this._transformCollection(data);
      this._data = data;
    }
    _transformCollection(data) {
      return R.pipe(R.propOr([], 'collection'), R.map(menuData => {
        menuData.isPinned = true;
        return this.dataManager.setAsRecord({
          adapterName: 'members-menu',
          data: menuData
        });
      }))(data);
    }
    get allowedUserMenu() {
      const allowed = R.pathOr([], ['crud', 'lists', 'application-lists-wizards'])(this);
      const collection = R.pathOr([], ['_data', 'collection'])(this);
      const allPinned = R.pipe(R.map(menuItem => {
        if (menuItem?._data?.isFinished === false) {
          menuItem._data.isPinned = true;
        }
        return menuItem;
      }), R.filter(menuItem => menuItem?._data?.isPinned))(collection);
      const userWizardsMenu = this.applicationService.processMenu({
        allowed,
        allPinned
      }) || [];
      const wizardsData = {
        label: 'wizards',
        pinLabel: 'wizards',
        translate: true,
        isExpanded: true,
        icon: 'fas fa-book',
        sub: userWizardsMenu
      };
      const wizardsMenu = this.crud.setupNewRecord({
        adapterName: 'members-menu',
        data: wizardsData
      });
      return RA.list(wizardsMenu);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allowedUserMenu", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allowedUserMenu"), _class.prototype), _class);
});