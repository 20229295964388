define("client/pods/components/products/files/files-default-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "O2TBXqrp",
    "block": "{\"symbols\":[\"@height\",\"@width\",\"@resourceKey\",\"@toggleIsOn\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"product-image-thumb-container\"],[3,\"did-update\",[[28,\"perform\",[[24,[\"didInsertTask\"]],[24,[\"resourceKey\"]]],null]]],[8],[0,\"\\n  \"],[5,\"elements/element-toggle-wrapper\",[[12,\"class\",\"flex-container-row\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"toggle\"]]],null]]]],[[\"@isToggler\",\"@disabled\",\"@toggleIsOn\",\"@hasToggleIconRight\"],[[23,0,[\"isToggler\"]],[28,\"if\",[[23,3,[]],false,true],null],[23,4,[]],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"resourceKey\"]],[24,[\"product\",\"defaultImage\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"files/image-item\",[],[[\"@height\",\"@image\",\"@imageClassNames\",\"@transform\",\"@width\"],[[23,1,[]],[24,[\"product\",\"defaultImage\"]],\"u-v-align-auto\",[22,\"transform\"],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"elements/element-icon\",[],[[\"@classNames\",\"@icon\"],[\"u-no-image u-v-align-auto\",\"far fa-image\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/files/files-default-image/template.hbs"
    }
  });
});