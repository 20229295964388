define("client/pods/documents/mixins/adapters", ["exports", "client/mixins/adapters-new", "ramda"], function (_exports, _adaptersNew, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import nventor from '../../../utils/nventor'
  var _default = _exports.default = Ember.Mixin.create(_adaptersNew.default, {
    serialize(model) {
      const data = this._super(model);
      const detailsRequiredAttrs = this.detailsRequiredAttrs;
      const deliveryDateOption = data?.deliveryDateOption || false;
      data.details = R.pipe(R.propOr([], 'details'), R.filter(detail => this.isNotEmptyDetail(detail, detailsRequiredAttrs)), R.map(detail => {
        if (deliveryDateOption === 'single' && data?.docName === 'orders') {
          detail.deliveryDateZ = data.deliveryDateZ;
        }
        return detail;
      }))(data);
      try {
        delete data.transporterWaybillRecords;
      } catch (e) {
        console.error(e);
      }
      return data;
    }
  });
});