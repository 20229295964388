define("client/pods/components/helpers/drag-container/component", ["exports", "client/mixins/draggable"], function (_exports, _draggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this is DEPRECATED.
  // use the new way
  // helpers/dragAndDrop/DragContainer and helpers/dragAndDrop//DropContainer should be deprecated
  // @deprecated
  var _default = _exports.default = Ember.Component.extend(_draggable.default, {
    tagName: 'span',
    classNames: ['drag-container']
  });
});