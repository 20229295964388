define("client/pods/components/messages/tab-container/messages-window/message-bubble/audio-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xZJijFex",
    "block": "{\"symbols\":[],\"statements\":[[7,\"figure\",true],[8],[0,\"\\n  \"],[7,\"audio\",true],[10,\"controls\",\"\"],[11,\"src\",[22,\"messageUrl\"]],[8],[0,\"\\n    Your browser does not support the \"],[7,\"code\",true],[8],[0,\"audio\"],[9],[0,\" element.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/messages-window/message-bubble/audio-type/template.hbs"
    }
  });
});