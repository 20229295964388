define("client/mixins/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @todo: test
  // @TODO: move to base (dont need a mixin for this...see what implements this mixin and remove)
  // classes that need this functionality should just extend the base model
  /**
   * Use for Ember model objects only
   * this provides models with utility functions to convert to json, populate model
   */
  var _default = _exports.default = Ember.Mixin.create({
    // init () {
    //   this._super(...arguments)

    //   // private is required so that certain properties can be set on the model
    //   // that will not be included in the data that will be sent to the server.
    //   // eg. parent is the outer model. the child model may require a parent model but
    //   // when getData is called it should ignore 'parent' (will result in a circular reference error)
    //   this.set('_private', {})
    // },
    _setupDefaults: function () {

      // var self = this;
      //
      // //copy defaults
      // var defaults = self.get('_defaults');
      // if (defaults) {
      //   //ensure the original defaults are not altered
      //   defaults = JSON.stringify(defaults);
      //   self.set('_data', JSON.parse(defaults));
      // }
    },
    /**
     * start up a model by populating data and setting original state
     * should call this instead of populate on initiate
     */
    // _startup() {
    //
    // },
    /**
     * check if object is a model
     * @param  {class|object}  obj - object to check
     * @return {Boolean}
     */
    // isModel: function (obj) {

    //   if (obj._data != null) {
    //     return true;
    //   }

    //   return false;
    // },

    /**
     * set original state of model on first populate.
     * used for when checking if a model has been modified
     */
    setOriginalState(data) {
      // setting original data
      data = data | this._data;
      this.set('_original', data);
    },
    /**
     * populate for inserting data into new instances
     * (first populates with _default and then data )
     */
    // populate: function (data) {
    //   const self = this

    //   data = data || {}

    //   self.overwriteData(data)
    // },

    /**
     * re-populates by over writing _data
     */
    // rePopulate: function (data) {
    //   if (data) {
    //     //data cannot be a astring
    //     if (typeof data === 'string') {
    //       throw 'data cannot be a string when populating model';
    //     }
    //
    //     //first transform then set properties..
    //     //(when setting properties observers may be fired so it must be done after transform)
    //     if (this.transform) {
    //       //does not alter model yet, only transforms data
    //       //eg. if data.contact should also be a class, then it will convert data.contact to a class NOT model._data.contact
    //       this.transform(data);
    //     }
    //
    //     //then set properties (also sets transformed properties)
    //     this.set('_data', data);
    //
    //     //a hook for the model to perform custom tasks
    //     if (this.wasPopulated) {
    //       //@todo: test
    //       this.wasPopulated();
    //     }
    //   }
    //   return this;
    // },
    /**
     * set _data with model data
     * @param {object} data - object hash
     */
    overwriteData: function (data) {
      const self = this;
      data = data || {};

      // if (typeof data !== 'object') {
      //
      //   //@TODO: use custom error handler method
      //   throw 'data is not an object';
      // }

      // if (typeof data === 'object' && !Ember.isEmpty(data)) {

      // ensures that a _data prop exists
      // var _data = self.get('_data');
      // if (!_data) {
      //   self.set('_data', {});
      // }

      // only overrides properties that exists
      // (eg data from server doesn't have contact, it will keep the contact property on model)
      // var keys = Object.keys(data);
      // keys.forEach(function (key) {
      //   var d = data[key];
      //   self.setData(key, d);
      // });

      // overrides all properties in _data
      self.set('_data', data);

      // a hook for the model to perform custom tasks
      if (this.wasPopulated) {
        // @todo: test
        this.wasPopulated();
      }
    },
    // sync: function (data) {
    //   @TODO: remove not required. use populate directly
    //   this.populate(data);
    // },
    // save: function (method, editor) {
    //     var data = this.serialize();
    //     var self = this;
    //     self.startSaving();
    //     return this.server.rpc(method, data, editor).then(function (res) {
    //         self.stopSaving();
    //         /**
    //          * @todo: problem. if model is not 'synced' on server, it will not update
    //          */
    //         return res;
    //     }).catch(function (err) {
    //         self.stopSaving();
    //         if (err.revisionError) {
    //             //handle revision error..
    //             self.setOutOfDate();
    //             //throw an error to allow the other .catch to chain
    //             throw {};
    //         } else {
    //             //pass the error back down
    //             throw err;
    //         }
    //     });
    // },
    startSaving: function () {
      this.set('isSaving', true);
    },
    stopSaving: function () {
      this.set('isSaving', false);
    },
    setOutOfDate: function (newData) {
      // only set out of date if not busing saving..
      // during saving, the sync response will return when successful, this will happen whilst the copy is still on the editing list. this will
      // result in the copy being set as out of date.
      if (!this.isSaving) {
        this.set('isOutOfDate', 'data out of date');
        this.set('isEditable', false);
        this.set('modified', newData);
        const synced = Math.random();
        this.set('synced', synced);
      }
    },
    /**
     * set isDeleted
     */
    setIsDeleted() {
      this.set('isDeleted', true);
      this.set('isEditable', false);
    },
    /**
     * sets parent for the model. the parent will be in _private so that getData will not be circular
     * parents are usually set for parent-child (one-to-many) relationship models
     */
    setParent: function (parent) {
      this.set('_private.parent', parent);
    },
    getParent: function (param) {
      if (param) {
        return this.get('_private.parent.' + param);
      }
      return this.get('_private.parent');
    },
    /**
     * get all _data (including nested)
     * @param  {object} model (optional) the model to get data from
     * @return {object}
     */
    // _getAllData: function (model) {
    //   // @TODO: this is one giant mess.
    //   // @TODO: problem:  can have model nested in plain objects or within objects within array...too many possibilities
    //   // model._data = {
    //   //
    //   //  object: {
    //   //    model: {
    //   //      _data: modelData
    //   //    }
    //   //  }
    //   //
    //   //  array: [{
    //   //    object: {
    //   //      model: {
    //   //        _data: modelData
    //   //      }
    //   //    }
    //   //  }]
    //   //
    //   // }
    //   //
    //   // proposed solution. flatten everything from model._data into pure json.
    //   // then move all data out of _data
    //   // eg. for above object
    //   // model._data = {
    //   //  object: {
    //   //    model: modelData
    //   //  },
    //   //
    //   //  array: [{
    //   //    object: {
    //   //      model: modelData
    //   //    }
    //   //  }]
    //   // }

    //   var self = this
    //   model = model || this
    //   var onlyData = {}
    //   if (model._data != null) {
    //     var allData = model._data

    //     if (!Ember.isEmpty(allData)) {
    //       var keys = Object.keys(allData)
    //       keys.forEach(function (key) {
    //         var propValue = allData[key]

    //         // ignore prop if propValue is a function
    //         if (!Nventor.confirm.isFunction(propValue)) {
    //           if (Ember.isArray(propValue)) {
    //             // is an array...copy each within array

    //             // value is an array..need to check in each array
    //             if (!Ember.isEmpty(propValue)) {
    //               onlyData[key] = []
    //               propValue.forEach(function (arrayVal, i) {
    //                 if (typeof arrayVal === 'object') {
    //                   if (arrayVal._data || Array.isArray(arrayVal)) {
    //                     // array value is array/object
    //                     onlyData[key][i] = self._getAllData(arrayVal)
    //                   } else {
    //                     onlyData[key][i] = arrayVal
    //                   }
    //                 } else {
    //                   // array value is string/integer
    //                   onlyData[key][i] = arrayVal
    //                 }
    //               })
    //             }
    //           } else if (typeof propValue === 'object') {
    //             // is an object..
    //             // check if propValue has a child model? (eg. it has _data)
    //             if (propValue == null) {
    //               // value is null
    //               onlyData[key] = propValue
    //             } else if (self.isModel(propValue)) {
    //               onlyData[key] = self._getAllData(propValue)
    //             } else {
    //               // copy object: first stringify then parse
    //               if (propValue._data) {
    //                 onlyData[key] = JSON.parse(JSON.stringify(self._getAllData(propValue)))
    //               } else {
    //                 onlyData[key] = JSON.parse(JSON.stringify(propValue))
    //               }
    //             }
    //           } else {
    //             // normal value (string/integer)
    //             onlyData[key] = propValue
    //           }
    //         }
    //       })
    //     }
    //   }

    // need to stringify _data and parse it so that the original classes/objects are not affected when copied
    // onlyData = JSON.stringify(onlyData);
    // onlyData = JSON.parse(onlyData);
    //   return onlyData
    // },
    /**
     * get as JSON stringified
     * @return {json}
     */
    getJsonString: function () {
      return JSON.stringify(this._getAllData());
    }
    /**
     * get a plain javascript object (ready to be sent to the server)
     */
    // serialize: function () {
    //   return this._getAllData()
    // }
  }); // App.DataObj = Ember.Object.extend(App.DataMixin);
});