define("client/pods/components/products/products-editor/recurring-schedules-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4e72Qc7R",
    "block": "{\"symbols\":[\"formInitData\",\"@wizardContainerComponent\",\"@tab\",\"@resourceList\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@formInitData\",\"@tab\"],[\"recurringSchedules\",[28,\"hash\",null,[[\"documentationResource\",\"resourceList\",\"tab\",\"wizardContainerComponent\"],[\"products\",[23,4,[]],[23,3,[]],[23,2,[]]]]],[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-group\",[],[[\"@tab\",\"@context\",\"@formInitData\",\"@title\"],[[23,3,[]],\"recurringSchedules_group\",[23,1,[]],\"recurring schedules\"]],{\"statements\":[[0,\" \\n    \"],[5,\"recurring/schedules-table\",[],[[\"@isReadonly\",\"@model\",\"@tab\"],[[23,5,[]],[23,6,[]],[23,3,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/recurring-schedules-editor/template.hbs"
    }
  });
});