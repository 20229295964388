define("client/pods/components/emails/campaigns/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "D7iUMiVo",
    "block": "{\"symbols\":[\"subTab\",\"tabsHeaderComponent\",\"isOverflowMenu\",\"onToggleOverflowMenu\",\"@tab\"],\"statements\":[[5,\"layouts/tabs/grid\",[],[[\"@tab\",\"@parentComponent\"],[[23,5,[]],[23,0,[]]]],{\"statements\":[[0,\"\\n\\t\"],[5,\"layouts/tabs/header\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[0,\"\\n\\t\\t\"],[5,\"layouts/tabs/header/right\",[],[[\"@component\",\"@resource\",\"@tabsHeaderComponent\",\"@onToggleOverflowMenu\",\"@isOverflowMenu\"],[[23,0,[]],\"emails-campaigns\",[23,2,[]],[23,4,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\t\\n\\t\\t\\t\\t\"],[5,\"menus/nav-menu\",[],[[\"@menu\",\"@onSelect\"],[[23,0,[\"crud\",\"lists\",\"emails-lists-campaigns-menu\"]],[28,\"fn\",[[23,0,[\"applicationService\",\"addOnAfter\"]],[28,\"hash\",null,[[\"func\",\"onAfter\"],[[28,\"fn\",[[23,0,[\"tabsManager\",\"triggerMenuAction\"]],[23,0,[]]],null],[23,4,[]]]]]],null]]]],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[2,3,4]}],[0,\"\\n\\n\\t\"],[5,\"layouts/tabs/grid/content\",[],[[],[]],{\"statements\":[[0,\"\\n\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"onCancel\",\"onEdit\",\"onDash\"],[[24,[\"tab\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"edit\"],null],[28,\"action\",[[23,0,[]],\"activity\"],null]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"   \\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/campaigns/tab-container/template.hbs"
    }
  });
});