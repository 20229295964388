define("client/pods/search/service", ["exports", "ramda", "ramda-extension", "ember-concurrency-decorators"], function (_exports, R, R_, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SearchService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _class = class SearchService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "users", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
    }
    // props.dynamicFilterKeys - eg. campaign master must never be saved, but must be used for every load from saved. this way filters can be saved and re-used across multiple campaigns
    setupFilters({
      adapterName,
      altStorageProp = false,
      rememberMe = false,
      rememberProps = [],
      defaultFiltersData = {},
      resetDefaults = {},
      onAfter,
      props = {},
      debug = false
    }) {
      if (debug) {
        debugger; //eslint-disable-line
      }
      const storageProp = altStorageProp || adapterName;
      if (rememberMe === true) {
        defaultFiltersData = this.getFromStorage(storageProp) || defaultFiltersData;
      }
      defaultFiltersData.filtersAdapterName = adapterName;
      defaultFiltersData.storageProp = storageProp;
      defaultFiltersData.rememberMe = rememberMe;
      defaultFiltersData.rememberProps = rememberProps;
      const userKey = this.users.getUserKey();
      defaultFiltersData.userKey = userKey;
      if (!defaultFiltersData.menuId) {
        defaultFiltersData.menuId = adapterName;
      }
      const filters = this.dataManager.setAsRecord({
        debug,
        adapterName,
        data: defaultFiltersData
      });
      R_.mapKeysWithValue((key, value) => {
        filters.set(key, value);
      })(props);
      filters.set('resetDefaults', resetDefaults);
      return filters;
    }
    copyFilters({
      filters,
      adapterName,
      altStorageProp = false,
      rememberMe = false,
      lastSearchSettingsProperty = 'lastSearchSettings',
      defaultFiltersData
    }) {
      let toCopyDefaultFiltersData = filters.serialize();
      toCopyDefaultFiltersData = R.mergeRight(toCopyDefaultFiltersData, defaultFiltersData);
      adapterName = adapterName || defaultFiltersData.filtersAdapterName;
      const copy = this.setupFilters({
        adapterName,
        altStorageProp,
        rememberMe,
        defaultFiltersData: toCopyDefaultFiltersData
      });
      copy.set('lastSearchSettingsProperty', lastSearchSettingsProperty);
      return copy;
    }
    getFromStorage(storageProp) {
      storageProp = R.replace('/', '__')(storageProp);
      return this.storage.get(`settings__${storageProp}__filters`) || false;
    }
    setToStorage(filters, rememberProps = false) {
      let storageProp = filters.getData('storageProp');
      storageProp = R.replace('/', '__')(storageProp);
      rememberProps = rememberProps || filters.getData('rememberProps') || [];
      let filterData = filters.serialize();
      if (!R.isEmpty(rememberProps)) {
        filterData = R.pick(rememberProps)(filterData);
      }
      this.storage.set(`settings__${storageProp}__filters`, filterData);
      return filters;
    }
    *loadFromSavedFilters({
      currentFilters,
      savedFilter,
      onAfter
    }) {
      const currentFiltersSerialized = currentFilters.serialize();
      const savedFilterSerialized = savedFilter.serialize();
      const savedFilterData = savedFilterSerialized.data || {};

      // keep dynamic filter values from being overritten
      const dynamicFilterKeys = currentFilters?.dynamicFilterKeys || [];
      R.forEach(dynamicKey => {
        const dynamicValue = R.prop(dynamicKey)(currentFiltersSerialized);
        if (!R.isNil(dynamicValue)) {
          savedFilterData[dynamicKey] = dynamicValue;
        }
      })(dynamicFilterKeys);
      currentFilters.set('_data', savedFilterData);
      yield onAfter(currentFilters);
    }
    *saveFiltersTask({
      filters,
      onCloseDialogAction
    }) {
      // const userMenu = this.userMenu || []
      // const lastIndex = userMenu.length || 0
      // filters.setData('index', 0)
      const dynamicFilterKeys = filters?.dynamicFilterKeys || [];
      let filtersData = filters.serialize();
      filtersData = R.omit(dynamicFilterKeys)(filtersData);

      // @NOTE: always set to page 1.
      // if need to set a different page, should add input in save filter modal
      filtersData.page = 1;
      const model = this.crud.setupNewRecord({
        adapterName: filters.adapterName,
        data: {
          menuId: filtersData?.menuId,
          userKey: filtersData?.userKey,
          label: filtersData?.label,
          data: filtersData
        }
      });
      yield this.crud.createRecordTask.perform({
        adapterName: 'users/personal-filters',
        model
      });
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
    *removeSavedFiltersTask(savedFilter) {
      yield this.crud.removeRecordTask.perform({
        adapterName: 'users/personal-filters',
        model: savedFilter,
        noConfirm: false
      });
    }
    *getSavedFiltersTask({
      menuId
    }) {
      const personalFilters = this.setupFilters({
        adapterName: 'users/personal-filters/filters',
        defaultFiltersData: {
          sort: [{
            by: 'index',
            direction: 'ASC'
          }],
          menuId
        }
      });
      this.set('personalFilters', personalFilters);
      const personalSavedFilters = yield this.crud.findTask.perform({
        adapterName: 'users/personal-filters/collection',
        filters: personalFilters
      });
      return personalSavedFilters;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "users", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadFromSavedFilters", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadFromSavedFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSavedFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeSavedFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getSavedFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getSavedFiltersTask"), _class.prototype), _class);
});