define("client/pods/channels/shop/promo-codes/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/promo-codes/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    applicationService: Ember.inject.service('application'),
    resourcePath: 'api/protected/channels/shop/promo-codes',
    idParam: '_id',
    modelObj: _model.default,
    serialize(model) {
      const type = model._data.type;
      if (type === this.constants.discountTypes.amount) {
        model.setData('discountRate', '');
        model.setData('discountRateOnSale', '');
      } else {
        model.setData('discountAmount', '');
      }
      if (!model._data.allowOnSale) {
        model.setData('discountRateOnSale', '');
      }
      const data = this._super(model);
      data.emails = this.applicationService.splitToArray(data.emails || '');
      data.telephones = this.applicationService.splitToArray(data.telephones || '');
      return data;
    }
  });
});