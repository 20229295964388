define("client/pods/components/extensions/apps/tw/invoices/print-manager/component", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices-printer", "client/mixins/crud", "ember-concurrency"], function (_exports, _invoicesPrinter, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _invoicesPrinter.default, {
    settings: Ember.inject.service(),
    tagName: '',
    document: null,
    invoices: null,
    isDisplayNumberOnly: false,
    adapterName: 'extensions/apps/tw/invoices',
    didInsertElement() {
      this._super(...arguments);
      const document = this.document;
      const isAllowedInvoice = this.isAllowedInvoice;
      if (isAllowedInvoice) {
        const invoiceMasterKey = document.getData('_key');
        this.set('invoiceMasterKey', invoiceMasterKey);
        this.fetchInvoicesTask.perform({
          master: invoiceMasterKey
        });
      }
    },
    isAllowedInvoice: Ember.computed('document._data.autoPrintInvoices', function () {
      const document = this.document;
      return this.settings.hasTwEInvoices(document);
    }),
    fetchInvoicesTask: (0, _emberConcurrency.task)(function* ({
      master
    }) {
      const appendPath = `?master=${master}`;
      const adapterName = this.adapterName;
      const document = this.document;
      const invoices = yield this.searchRecordsTask.perform({
        appendPath,
        resultsProperty: false
      });
      if (invoices.length === 0) {
        if (document.getData('autoPrintInvoices') === this.get('constants.invoicing.on')) {
          const invoice = this.dataManager.setAsRecord({
            adapterName,
            data: {
              _key: `tw_invoices_${master}`,
              master: master
            }
          });
          invoices.push(invoice);
        }
      }
      this.set('invoices', invoices);
    }),
    actions: {
      fetchInvoices(invoiceMasterKey) {
        this.fetchInvoicesTask.perform({
          master: invoiceMasterKey
        });
      }
    }
  });
});