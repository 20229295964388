define("client/pods/components/elements/error-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lkTM/emm",
    "block": "{\"symbols\":[\"@label\",\"@hasIcon\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"has-text-danger\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-icon\",[],[[\"@classNames\",\"@icon\"],[\"has-text-danger u-icon-spacer--left\",\"fas fa-exclamation-circle\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\":\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[23,0,[\"message\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/error-message/template.hbs"
    }
  });
});