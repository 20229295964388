define("client/pods/ifetch/service", ["exports", "client/config/environment", "ramda-adjunct", "ramda"], function (_exports, _environment, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let IfetchService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _class = class IfetchService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "guests", _descriptor, this);
      _initializerDefineProperty(this, "storage", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _defineProperty(this, "host", _environment.default.APP.serverUrl);
    }
    async iFetch({
      url,
      path = '',
      method = 'GET',
      data
    } = {}) {
      const token = this.storage.get('token') || this.guests.getToken();
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        }
      };
      if (!url) {
        const headers = {
          Authorization: `Bearer ${token}`
        };
        options.headers = R.pipe(R.propOr({}, 'headers'), R.mergeLeft(headers))(options);
      }
      if (data && method === 'GET') {
        const searchParams = new URLSearchParams(data);
        path += `?${searchParams.toString()}`;
      } else if (data) {
        options.body = JSON.stringify(data);
      }
      url = url || `${this.host}/${path}`;
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          const error = await response.json();
          const message = this.intl.t(error.message);
          window.alert(message);
          return error;
        }
        const contentType = response.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          const filename = response.headers.get('content-disposition').split(';').find(n => n.includes('filename=')).replace('filename=', '').trim();
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else if (contentType.includes('application/json')) {
          return response.json();
        } else if (contentType.includes('text/html')) {
          const data = await response.text();
          if (RA.isNotNilOrEmpty(data)) {
            // const newWindow = window.open('', '_blank', 'width=750,height=850')
            // if (!newWindow) {
            //   const message = this.intl.t('please allow popups to open')
            //   window.alert(message)
            //   return
            // }
            // newWindow.document.write(data)
            // newWindow.document.close()
            return data;
          }
        }
        return response;
      } catch (error) {
        window.alert(error.message);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "guests", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});