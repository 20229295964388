define("client/mixins/crud", ["exports", "ember-concurrency", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    page: 1,
    /**
     * @deprecated
     * */
    setValidationErrors(errorsProp, err) {
      const errors = err.data || {};
      if (R.is(String, errors) || !err.data) {
        const msg = this.intl.t('error occurred please try again later');
        // window.alert(msg)
        console.log('Error!!!');
        console.log(msg);
        return false;
      }
      // when handlers are still using old nvalidator, there will not be an errors.errors array
      if (!errors.errors) {
        try {
          errors.errors = R.values(errors);
          errors.errors = R.map(error => {
            error.context = {
              key: error.context
            };
            return error;
          })(errors.errors);
          this.set(errorsProp, errors.errors);
        } catch (e) {
          console.log(e);
        }
      }

      // let errorsTracker = this.get('errorsTracker')
      // if (errorsTracker && errors.errors) {
      //   errors.errors = R.map(error => {
      //     const context = R.pathOr('', ['context', 'key'], error)

      //     if (errorsTracker[context]) {
      //       error.tab = errorsTracker[context]
      //       error.label = error.tab.label
      //     }
      //     return error
      //   })(errors.errors)
      // }
    },
    setNewValidationErrors(errorsProp = 'errors', err, options = {}) {
      const {
        dirty
      } = options;
      const errors = err.data || {};
      const isOutOfDate = R.prop('isOutOfDate')(errors);
      if (isOutOfDate) {
        dirty.set('isOutOfDate', isOutOfDate);
        const msg = this.intl.t('update failed the data is out of date as it may have been changed by someone else please cancel and try again');
        alert(msg);
      }
      if (R.is(String, errors) || !err.data) {
        const msg = this.intl.t('error occurred please try again later');
        console.log('error', msg);
        // window.alert(msg)
        return false;
      }
      // when handlers are still using old nvalidator, there will not be an errors.errors array
      try {
        if (!errors.errors) {
          errors.errors = R.values(errors);
          errors.errors = R.map(error => {
            if (R.is(String, error)) {
              return {
                context: {
                  key: error
                }
              };
            }
            error.context = {
              key: error.context
            };
            return error;
          })(errors.errors);
        }
        errors.errors = R.pipe(R.propOr([], 'errors'), R.map(error => {
          const hasIndex = RA.isNotNilOrEmpty(error.index);
          if (hasIndex && RA.isNotNilOrEmpty(error.context)) {
            const keyWithIndex = R.join(`[${error.index}].`, error.path);
            error.context.keyWithIndex = keyWithIndex;
          }
          return error;
        }))(errors);
      } catch (e) {
        console.log('error setting validation errors');
        console.log(e);
      }
      if (dirty) {
        if (dirty.set) {
          dirty.set('apiErrors', errors.errors);
        } else {
          dirty.apiErrors = errors.errors;
        }
      }
      return errors;
    },
    /**
     * @deprecated
     * */
    clearValidationErrors(errorsProp) {
      this.set(errorsProp, '');
    },
    /**
     * @deprecated
     * */
    getAdapter(adapterName) {
      adapterName = adapterName || this.adapterName;
      return this.server.getAdapter(adapterName);
    },
    /**
     * @deprecated
     * @param {NewRecordType?} dataOptions
     * @return {Object}
     */
    setupNewRecord(dataOptions = {}) {
      let {
        adapterName,
        data = {},
        attrs = {}
      } = dataOptions;
      adapterName = adapterName || this.adapterName;
      data = data || {};
      attrs = attrs || {};
      const model = this.dataManager.newRecord({
        adapterName,
        data,
        attrs
      });
      model.instanceId = _nventor.default.random.alphaNum(8);
      return model;
    },
    /**
     * @deprecated
     * */
    setupDirty({
      adapterName,
      model
    } = {}) {
      model = model || this.model;
      model = model || this.get('tab.model');
      adapterName = adapterName || this.adapterName;
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      dirty.instanceId = _nventor.default.random.alphaNum(8);
      return dirty;
    },
    /**
     * fetch default data task. should be implemented by tab-container
     * @deprecated
     * */
    fetchDefaultDataTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      filters,
      resultsProperty,
      byLatest = true
    }) {
      // the function implementing needs to also have the correct list by mixin
      const results = yield this.listByTask.perform({
        adapterName,
        filters,
        byLatest
      });
      if (resultsProperty) {
        this.set(resultsProperty, results);
      }
      return results;
    }).drop(),
    /**
     * @deprecated
     * */
    searchAdvancedTask: (0, _emberConcurrency.task)(function* ({
      filters,
      resultsProperty = 'results'
    }) {
      const DEBOUNCE_MS = 550;
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      const adapterName = this.adapterName;
      filters.setData('page', 1);
      filters.setData('query', '');
      const results = yield this.searchRecordsTask.perform({
        adapterName,
        appendPath: '/advanced',
        filters
      });
      this.set(resultsProperty, results);
      return results;
    }).restartable(),
    /**
     * @deprecated
     * */
    searchInputTask: (0, _emberConcurrency.task)(function* ({
      debug,
      adapterName,
      appendPath,
      data,
      filters,
      options,
      resultsProperty = 'results',
      lastSearchSettingsProperty,
      newFilters = false,
      copyFilters = false,
      onAfter
    } = {}, query, e) {
      const DEBOUNCE_MS = 550;
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      filters.setData('page', 1);
      query = query || filters.getData('query');
      query = query.trim();
      filters.setData('query', query);
      const results = yield this.searchRecordsTask.perform({
        adapterName,
        appendPath,
        query,
        data,
        filters,
        newFilters,
        copyFilters,
        options,
        resultsProperty,
        lastSearchSettingsProperty
      }, e);
      if (onAfter) {
        return onAfter(results);
      }
      if (this.afterSearchInputTask) {
        return this.afterSearchInputTask(results);
      }
      return results;
    }).restartable(),
    /**
     * @deprecated
     * */
    paginateSearchRecordsTask: (0, _emberConcurrency.task)(function* ({
      filters,
      lastSearchSettingsProperty = 'lastSearchSettings',
      resultsProperty,
      searchSettings
    }) {
      if (RA.isNilOrEmpty(searchSettings)) {
        searchSettings = filters.get(lastSearchSettingsProperty);
      }
      if (RA.isNilOrEmpty(searchSettings)) {
        searchSettings = {};
      }
      searchSettings.lastSearchSettingsProperty = lastSearchSettingsProperty;
      searchSettings.resultsProperty = resultsProperty || filters.getData('resultsProperty');
      searchSettings.filters = filters;
      const results = yield this.searchRecordsTask.perform(searchSettings);
      if (this.afterPaginateSearchRecordsTask) {
        return this.afterPaginateSearchRecordsTask(results);
      }
      return results;
    }),
    /**
     * @deprecated
     * */
    reloadSearchRecordsTask: (0, _emberConcurrency.task)(function* (args) {
      const {
        filters,
        lastSearchSettingsProperty = 'lastSearchSettings',
        reset = false,
        onAfter
      } = args;
      let {
        searchSettings
      } = args;
      filters.setData('page', 1);
      if (reset) {
        filters.reset(args);
      }
      if (RA.isNilOrEmpty(searchSettings)) {
        searchSettings = filters.get(lastSearchSettingsProperty);
      }
      if (RA.isNilOrEmpty(searchSettings)) {
        searchSettings = {};
      }
      searchSettings.lastSearchSettingsProperty = lastSearchSettingsProperty;
      searchSettings.filters = filters;
      searchSettings.onAfter = onAfter;
      return yield this.searchRecordsTask.perform(searchSettings);
    }),
    /**
     * @deprecated
     * */
    searchRecordsTask: (0, _emberConcurrency.task)(function* /* searchSettings, e */
    () {
      return yield this.searchRecordsConcurrentlyTask.perform(...arguments);
    }),
    /**
     * api/p/messages/{fb}/{name}
     * adapterName: 'channels/shop/details'
     * appendPath: `/${type}/${name}`
     * @deprecated
     * */
    searchRecordsConcurrentlyTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      modelAdapterName,
      appendPath,
      rawResponse,
      newFilters = false,
      copyFilters = false,
      query = '',
      data = {},
      filters,
      options,
      resultsProperty = 'results',
      lastSearchSettingsProperty = 'lastSearchSettings',
      errorsProp = 'errors',
      onAfter
    } = {} /* ,  e */) {
      adapterName = adapterName || this.adapterName;
      if (copyFilters) {
        filters = this.search.copyFilters({
          filters
        });
      }
      query = query || filters.getData('query');
      // CATCH CASE WHEN QUERY IS NOT A STRING AND SET IT TO AN EMPTY STRING
      const isNotString = typeof query !== 'string';
      if (isNotString) {
        query = '';
      }
      if (newFilters) {
        filters = this.search.setupFilters({
          adapterName: filters._data.filtersAdapterName
        });
      }
      filters.setData('query', query);
      resultsProperty = R.path(['_data', 'resultsProperty'])(filters) || resultsProperty;
      if (!resultsProperty) {
        resultsProperty = 'results';
      }
      try {
        this.clearValidationErrors(errorsProp);
        if (query?.trim?.() !== '') {
          data.query = query.trim();
        }
        const results = yield this.dataManager.findAll({
          adapterName,
          appendPath,
          modelAdapterName,
          data,
          filters,
          options,
          rawResponse
        });
        if (filters) {
          if (!filters.getData('page')) {
            this.set('page', 1);
          }
        }
        if (!data.page) {
          this.set('page', 1);
        }
        if (filters) {
          filters.set('lastSearchSettingsProperty', lastSearchSettingsProperty);
          filters.set(lastSearchSettingsProperty, {
            adapterName,
            appendPath,
            resultsProperty,
            query,
            options,
            data,
            filters
          });
          const previousQueries = filters?.previousQueries || [];
          previousQueries.unshiftObject(query);
          filters.set('previousQueries', previousQueries);
          filters.set('currentQuerySubmitted', query);
        }
        if (resultsProperty) {
          this.set(resultsProperty, results);
          if (filters) {
            filters.set(resultsProperty, results);
            filters.updateSynced();
          }
        }
        if (onAfter) {
          return onAfter(results);
        }
        if (this.afterSearchRecordsTask) {
          return this.afterSearchRecordsTask(results);
        }
        return results;
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('searchRecordsTask failed', err);
        return false;
      }
    }).enqueue(),
    /**
     * @deprecated
     * */
    findTask: (0, _emberConcurrency.task)(function* /* { adapterName, appendPath, params, errorsProp = 'errors', rawResponse } = {} */
    () {
      return yield this.findConcurrentTask.perform(...arguments);
    }).drop(),
    /**
     * @deprecated
     * */
    findConcurrentTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      params,
      errorsProp = 'errors',
      rawResponse
    } = {}) {
      adapterName = adapterName || this.adapterName;

      // debugger @TODDO: check not to use this
      try {
        this.clearValidationErrors(errorsProp);
        const model = yield this.dataManager.findRecord({
          adapterName,
          appendPath,
          params,
          rawResponse
        });
        if (this.afterFindRecordTask) {
          return this.afterFindRecordTask(model);
        }
        return model;
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('findTask failed', err);
        return false;
      }
    }).enqueue().maxConcurrency(4),
    /**
     * @deprecated
     * */
    validateTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      model,
      errorsProp = 'errors'
    } = {}) {
      try {
        this.clearValidationErrors(errorsProp);
        adapterName = adapterName || this.adapterName;
        const adapter = this.dataManager.getAdapter(adapterName);
        const data = adapter.serialize(model);
        const path = adapter.getPath('resourcePath', appendPath);
        const validated = yield this.server.call('POST', path, data);
        model.populate(validated.data);
        return model;
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('validation failed', err);
        return false;
      }
    }).drop(),
    /**
     * @deprecated
     * */
    createRecordTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      model,
      errorsProp = 'errors',
      onAfter,
      onAfterOptions,
      onAfterCreateRecordTask,
      rawResponse
    } = {}) {
      adapterName = adapterName || this.adapterName;
      model = model || this.model;
      try {
        this.clearValidationErrors(errorsProp);
        if (this.beforeCreateRecord) {
          model = this.beforeCreateRecord(model);
        }
        const saved = yield this.dataManager.createRecord({
          adapterName,
          appendPath,
          model,
          rawResponse
        });
        if (onAfter) {
          return onAfter(saved, onAfterOptions);
        }
        if (this.afterCreateRecordTask) {
          return this.afterCreateRecordTask(saved, onAfterOptions);
        }
        if (onAfterCreateRecordTask) {
          return onAfterCreateRecordTask(saved, onAfterOptions);
        }
        if (this.onAfterCreateRecordTask) {
          return this.onAfterCreateRecordTask(saved, onAfterOptions);
        }
        if (this.onDisplay) {
          this.onDisplay(saved, onAfterOptions);
          return saved;
        }
        return saved;
      } catch (err) {
        const dirty = model;
        this.setValidationErrors(errorsProp, err);
        this.setNewValidationErrors(errorsProp, err, {
          dirty
        });
        if (dirty.instanceId) {
          const parentTab = this.tabsManager.getOuterMostTab();
          this.tabsManager.registerErrors({
            dirty,
            parentTab,
            tab: this.tab
          });
        }
        _nventor.default.throw('createRecordTask failed', err);
        throw err;
      }
    }).drop(),
    queueUpdateRecordTask: (0, _emberConcurrency.task)(function* () {
      return yield this.updateRecordTask(...arguments);
    }).enqueue(),
    /**
     * @TODO: rename all updateRecordTask model to dirty
     * @deprecated
     * */
    updateRecordTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      model,
      errorsProp = 'errors',
      onBefore,
      onAfter,
      onAfterOptions,
      rawResponse,
      hasUpdateProps = false
    } = {}) {
      adapterName = adapterName || this.adapterName;
      model = model || this.dirty;
      let updateProps;
      if (hasUpdateProps) {
        const originalModel = model.get('originalSerialized');
        const originalData = JSON.parse(originalModel);
        const modifiedData = JSON.parse(JSON.stringify(model.serialize()));
        updateProps = this.getDifferentProperties(originalData, modifiedData);
      }

      // when updating specific properties but there is no changes at all
      if (hasUpdateProps && RA.isNilOrEmpty(updateProps)) {
        return model;
      }
      try {
        this.clearValidationErrors(errorsProp);
        if (this.beforeUpdateRecord) {
          model = this.beforeUpdateRecord(model);
        }
        if (onBefore) {
          model = onBefore(model);
        }
        const saved = yield this.dataManager.updateRecord({
          adapterName,
          appendPath,
          dirty: model,
          rawResponse,
          updateProps
        });
        if (onAfter) {
          return onAfter(saved, onAfterOptions);
        }
        if (this.afterUpdateRecordTask) {
          this.afterUpdateRecordTask(saved, onAfterOptions);
        }
        if (this.onDisplay) {
          // if (!onAfterOptions) {
          //   const activeSubTab = this.tabsManager.getActiveSubTab(this.tab)
          //   onAfterOptions = {
          //     loadInstructions: {
          //       component: activeSubTab.get('component')
          //     }
          //   }
          // }
          return this.onDisplay(saved, onAfterOptions);
        }
        return saved;
      } catch (err) {
        const dirty = model;
        this.setValidationErrors(errorsProp, err);
        this.setNewValidationErrors(errorsProp, err, {
          dirty
        });
        if (dirty.instanceId) {
          const parentTab = this.tabsManager.getOuterMostTab();
          this.tabsManager.registerErrors({
            dirty,
            parentTab,
            tab: this.tab
          });
        }
        _nventor.default.throw('updateRecordTask failed', err);
        throw err;
      }
    }).drop(),
    /**
     * @deprecated
     * */
    replaceRecordTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      model,
      errorsProp = 'errors',
      onAfter,
      rawResponse
    } = {}) {
      adapterName = adapterName || this.adapterName;
      model = model || this.dirty;
      try {
        this.clearValidationErrors(errorsProp);
        if (this.beforeReplaceRecord) {
          model = this.beforeReplaceRecord(model);
        }
        const saved = yield this.dataManager.replaceRecord({
          adapterName,
          appendPath,
          dirty: model,
          rawResponse
        });
        if (onAfter) {
          return onAfter(saved);
        }
        if (this.afterReplaceRecordTask) {
          return this.afterReplaceRecordTask(saved);
        }
        if (this.onDisplay) {
          return this.onDisplay(saved);
        }
        return saved;
      } catch (err) {
        this.setValidationErrors(errorsProp, err);
        _nventor.default.throw('replaceRecordTask failed', err);
        throw err;
      }
    }).drop(),
    /**
     * @deprecated
     * */
    removeRecordTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      batch,
      model,
      errorsProp = 'errors',
      onAfter,
      confirmMsg,
      rawResponse,
      noConfirm = false
    } = {}) {
      const intl = this.intl;
      const msg = confirmMsg || intl.t('are you sure you want to delete');
      const confirmed = noConfirm || window.confirm(msg);
      if (confirmed) {
        adapterName = adapterName || this.adapterName;
        if (RA.isNilOrEmpty(batch)) {
          model = model || this.model;
          model = model || this.dirty;
        }
        this.set('hasError', false);
        try {
          if (this.beforeRemoveRecord) {
            model = this.beforeRemoveRecord(model);
          }
          const success = yield this.dataManager.removeRecord({
            adapterName,
            appendPath,
            batch,
            model,
            rawResponse
          });
          if (onAfter) {
            if (!RA.isNilOrEmpty(batch)) {
              return onAfter(batch);
            }
            return onAfter(model);
          }
          if (this.afterRemoveRecordTask) {
            if (!RA.isNilOrEmpty(batch)) {
              return this.afterRemoveRecordTask(batch);
            }
            return this.afterRemoveRecordTask(model);
          }
          if (this.onDash) {
            if (!RA.isNilOrEmpty(batch)) {
              return this.onDash(batch);
            }
            return this.onDash(model);
          }
          return success;
        } catch (err) {
          this.setValidationErrors(errorsProp, err);
          _nventor.default.throw('removeRecordTask failed', err);
        }
      }
    }).drop(),
    /**
     * @deprecated
     * */
    cancelEditing({
      adapterName,
      dirty,
      onAfter
    } = {}) {
      adapterName = adapterName || this.adapterName;
      dirty = dirty || this.dirty;
      const original = this.dataManager.cancelDirty(adapterName, dirty);
      if (onAfter) {
        return onAfter();
      }
      if (this.afterCancelEditing) {
        return this.afterCancelEditing(original);
      }
      if (this.onDisplay) {
        return this.onDisplay(original);
      }
      return original;
    },
    getDifferentProperties(original, modified) {
      if (R.equals(original, modified)) {
        return [];
      }
      return R.pipe(R.keys, R.concat(R.keys(modified)), R.map(key => {
        if (modified[key] !== original[key] && !R.equals(original[key], modified[key])) {
          return key;
        }
      }), R.reject(RA.isNilOrEmpty), R.uniq)(original);
    },
    /**
     * @deprecated
     * */
    fetchUploadsTask: (0, _emberConcurrency.task)(function* ({
      resource,
      resourceKey,
      filters,
      adapterName,
      appendPath,
      params = {}
    }) {
      const collectionModel = yield this.findTask.perform({
        adapterName,
        appendPath,
        params: {
          resource,
          resourceKey,
          filters,
          ...params
        }
      });
      return collectionModel;
    }).enqueue(),
    /**
     * @deprecated
     * */
    adapterCallTask: (0, _emberConcurrency.task)(function* (method, {
      adapterName,
      appendPath,
      data,
      serverType = ''
    } = {}) {
      return yield this.dataManager.adapterCall({
        method,
        adapterName,
        appendPath,
        data,
        serverType
      });
    }).drop(),
    actions: {
      /**
       * @deprecated
       * */
      cancelEditing() {
        this.cancelEditing(...arguments);
      },
      /**
       * @deprecated
       * */
      setupDirty() {
        return this.setupDirty(...arguments);
      },
      /**
       * @deprecated
       * */
      afterSearch(dashComponent, results) {
        const tab = this.tab;
        if (!_nventor.default.isNilOrEmpty(results)) {
          if (this.tabsManager.isActiveSubTab(tab, {
            component: dashComponent
          })) {
            return this.dash({}, results);
          }
        }
        return this.set('showResults', true);
      }
    }
  });
});