define("client/pods/components/documents/btn-ungroup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JtB6lEvG",
    "block": "{\"symbols\":[],\"statements\":[[5,\"elements/element-btn\",[],[[\"@tooltipLabel\",\"@icon\",\"@onSubmit\",\"@isRunning\"],[\"ungroup_document\",\"fas fa-file-circle-minus\",[28,\"perform\",[[23,0,[\"ungroupDocumentsTask\"]]],null],[23,0,[\"ungroupDocumentsTask\",\"isRunning\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/btn-ungroup/template.hbs"
    }
  });
});