define("client/pods/shortcuts/service", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj; // import * as R from 'ramda'
  // import Service, { inject as service } from '@ember/service'
  // import { action } from '@ember/object'
  // export default Service.extend({
  //   keysPressed: {},
  //   alreadyTriggered: false,
  //   @action
  //   onKeyDown (handler, e) {
  //     this.keysPressed[event.key] = true
  //     // shortcuts are onKeyDown to appear more instant
  //     if (handler && !this.alreadyTriggered) {
  //       const key = e.key
  //       const hasCmdOrCtrl = this.keysPressed?.Meta || this.keysPressed?.Control
  //       const hasEnter = key === 'Enter'
  //       if (hasCmdOrCtrl && hasEnter) {
  //         this.set('alreadyTriggered', true)
  //         // return handler()
  //       }
  //     }
  //   },
  //   @action
  //   onKeyUp (handler, e) {
  //     delete this.keysPressed[event.key]
  //     if (handler) {
  //       return handler()
  //     }
  //     this.set('alreadyTriggered', false)
  //   }
  // })
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Service.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    keysPressed: {},
    alreadyTriggered: false,
    _hasAnyMatch(shortcutKeysArray, keysPressedArray) {
      return R.reduce((acc, shortcutKeys) => {
        if (acc) {
          return acc;
        }
        const intersection = R.intersection(shortcutKeys)(keysPressedArray);
        if (intersection.length === shortcutKeys.length) {
          acc = true;
        }
        return acc;
      }, false)(shortcutKeysArray);
    },
    matchByKeysPressed(shortcutsMap) {
      const keysPressedArray = R.keys(this.keysPressed || {});
      const matched = R.find(shortcutObj => {
        let shortcutKeys = shortcutObj.keys;
        if (!R.is(Array, shortcutKeys[0])) {
          shortcutKeys = [shortcutKeys];
        }

        // shortcutKeys should always be an array of array.
        // if a single array, make it an array of array.
        // this allows for 1 handler to have many shortcuts
        // shortcutKeys = [
        //   ['key1', 'key2'], // option1
        //   ['key1', 'key3'], // option2
        // ]
        return this._hasAnyMatch(shortcutKeys, keysPressedArray);
      })(shortcutsMap);
      return matched;
    },
    onKeyDown(shortcutsMap, e) {
      this.keysPressed[e.key] = true;

      // shortcuts are onKeyDown to appear more instant
      if (!this.alreadyTriggered) {
        const matched = this.matchByKeysPressed(shortcutsMap, this.keysPressed);
        if (matched) {
          e.preventDefault();
          this.set('alreadyTriggered', true);
          this.onKeyUp(false, e);
          if (matched?.handler?.perform) {
            return matched.handler.perform();
          }
          return matched.handler();
        }
      }
    },
    onKeyUp(handler, e) {
      if (e.key === 'Meta') {
        // problem with meta. it will only always trigger one key up event, even when many keys were pressed
        this.keysPressed = {};
      } else {
        delete this.keysPressed[e.key];
      }
      if (handler) {
        return handler();
      }
      this.set('alreadyTriggered', false);
    }
  }, _applyDecoratedDescriptor(_obj, "matchByKeysPressed", [_dec], Object.getOwnPropertyDescriptor(_obj, "matchByKeysPressed"), _obj), _applyDecoratedDescriptor(_obj, "onKeyDown", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onKeyDown"), _obj), _applyDecoratedDescriptor(_obj, "onKeyUp", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onKeyUp"), _obj), _obj));
});