define("client/pods/channels/shop/rewards/item-events/model", ["exports", "client/pods/base/model", "client/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        productTags: [],
        itemKeys: [],
        memberLevelId: '',
        status: _constants.default.status.active,
        expiryTime: 1,
        expiryPeriod: _constants.default.timePeriods.years
      };
    },
    populate(data, attrs) {
      this._super(data, attrs);
    }
  });
});