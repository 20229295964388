define("client/pods/components/contacts/icons/default-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CuwevK3h",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"detail\",\"_data\",\"isDefault\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"classNames\"],[\"fas fa-star\",\"element-icon--xs element-icon--margin\"]]],false],[0,\"\\n\\n\"],[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\"],[[23,1,[]]]],{\"statements\":[[0,\"        \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"classNames\"],[\"fas fa-star\",\"element-icon--xs element-icon--margin\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/icons/default-icon/template.hbs"
    }
  });
});