define("client/pods/components/files/files-gallery-row/gallery-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aCMMhRAU",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[\"files__gallery-item u-tappable \",[28,\"if\",[[24,[\"file\",\"_data\",\"isDefault\"]],\"is-default\",\"\"],null]]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"file\",\"_data\",\"isImage\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"files/image-item\",null,[[\"imageClassNames\",\"image\",\"width\",\"height\",\"transform\",\"onSubmit\"],[[24,[\"innerFilesGalleryRowClassNames\"]],[24,[\"file\",\"_data\",\"uuid\"]],[24,[\"imageWidth\"]],[24,[\"imageHeight\"]],[24,[\"transform\"]],[28,\"action\",[[23,0,[]],[24,[\"onShowPreview\"]],[24,[\"file\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"file\",\"_data\",\"originalFilename\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"flex-container-column u-link--text\"],[3,\"on\",[\"click\",[28,\"fn\",[[24,[\"onShowPreview\"]],[24,[\"file\"]]],null]]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"files__icon\"],[8],[7,\"i\",true],[10,\"class\",\"fas fa-file\"],[8],[9],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"[\"],[1,[24,[\"file\",\"_data\",\"originalFilename\"]],false],[0,\"]\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/files-gallery-row/gallery-item/template.hbs"
    }
  });
});