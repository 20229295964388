define("client/pods/components/channels/shop/promo-codes/panels-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    adapterName: 'channels/shop/promoCodes',
    init() {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      const model = this.tab.model;
      this.set('model', model);
    }
  });
});