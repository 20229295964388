define("client/pods/components/websites/panels-dash/component", ["exports", "ember-concurrency", "client/mixins/date", "client/pods/components/websites/mixins/websites-list-by", "ramda-adjunct"], function (_exports, _emberConcurrency, _date, _websitesListBy, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_websitesListBy.default, _date.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    adapterName: 'channels/shop/campaigns',
    init() {
      this.crud.addLists(this, ['periods/months', 'periods/years', 'channels/shop/campaigns/reports/filters', 'taxMethods/filters']);
      const customDateStartZ = this.dateService.getMonthStart().toISOString();
      const customDateEndZ = this.dateService.getMonthEnd().toISOString();
      this.customDateStartZ = customDateStartZ;
      this.customDateEndZ = customDateEndZ;
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: 'ordered',
        taxMethods: 'inclusive'
      }));
      this._super(...arguments);
      const period = this.getPeriod();
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      this.displayByMonth(period);
      this.tabsManager.setHeader(this.tabParent, '');
    },
    displayByMonth(period) {
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      this.display({
        dateStart,
        dateEnd
      });
    },
    display(period) {
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('period', period);
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      this.fetchReports({
        dateStart,
        dateEnd
      });
    },
    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');
      if (revenueBy === 'ordered') {
        return 'all';
      }
      return this.get('constants.dispatchStatus.dispatched');
    }),
    fetchReports({
      dateStart,
      dateEnd
    }) {
      this.fetchReportTask.perform({
        dateStart,
        dateEnd
      });
      this.fetchYearTask.perform({
        dateStart,
        dateEnd
      });
    },
    fetchReportTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd
    }) {
      const monthlyReport = yield this._fetchReportTask.perform({
        dateStart,
        dateEnd,
        segments: ['dateZ']
      });
      this.set('monthlyReport', monthlyReport);
      this.set('sourceReport', []);
      this.set('paymentMethodKeyReport', []);
      this.set('itemReport', []);
      this.set('contactReport', []);
    }),
    displaySourceReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const sourceReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['source']
        });
        this.set('sourceReport', sourceReport);
      }
    }),
    displayPaymentMethodKeyReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const paymentMethodKeyReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['paymentMethodKey']
        });
        this.set('paymentMethodKeyReport', paymentMethodKeyReport);
      }
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const itemReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['item']
        });
        this.set('itemReport', itemReport);
      }
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';
      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const contactReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['contact', 'contactTags', 'customerType', 'memberType', 'gender', 'age']
        });
        this.set('contactReport', contactReport);
      }
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd,
      segments = []
    }) {
      const monthParams = {
        docType: 'sales',
        dateStart,
        dateEnd,
        match: ['channel'],
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        query: 'shop',
        // segments: [
        //   'dateZ',
        //   'contact',
        //   'item',
        //   'channel',
        //   'source',
        //   'paymentMethodKey',
        //   'promoCode',
        //   'customerType',
        //   'memberType',
        //   'gender',
        //   'age'
        // ]
        segments
      };
      const results = yield this.server.call('GET', 'api/protected/reports/dashboard/', monthParams);
      return results.data;
    }),
    fetchYearTask: (0, _emberConcurrency.task)(function* ({
      dateStart,
      dateEnd
    }) {
      const yearParams = {
        docType: 'sales',
        dateStart: this.formatDateForUrl(this.getYearStart(dateStart)),
        dateEnd: this.formatDateForUrl(this.getYearEnd(dateEnd)),
        match: ['channel'],
        status: this.get('constants.documentsStatus.final'),
        dispatchStatus: this.dispatchStatusFilter,
        query: 'shop',
        segments: ['month']
      };
      const year = yield this.server.call('GET', 'api/protected/reports/dashboard/', yearParams);
      this.set('yearlyReport', year.data);
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }
      return false;
    }),
    actions: {
      display(model) {
        this.display(model);
      },
      displayByMonth(period) {
        this.displayByMonth(period);
      },
      displayByCustom() {
        if (!this.customDateEndZ || !this.customDateStartZ) {
          return;
        }
        const period = this.dateService.getCustomPeriod(this.customDateEndZ, this.customDateStartZ);
        this.display(period);
      },
      fetchReports({
        dateStart,
        dateEnd
      }) {
        dateStart = dateStart || this.dateStart;
        dateEnd = dateEnd || this.dateEnd;
        this.fetchReports({
          dateStart,
          dateEnd
        });
      }
    }
  });
});