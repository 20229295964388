define("client/pods/components/messages/tab-container/messages-window/message-bubble/options-type/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    isShowPreview: false,
    didInsertElement() {
      this._super(...arguments);
      const messageBubble = this.messageBubble;
      if (this.isNotText(messageBubble)) {
        const payload = messageBubble.payload;
        this.set('messageOptions', payload.options);
        this.set('messageType', messageBubble.type);
        this.set('messageText', payload.text);
      }
    },
    isNotText: R.is(Object)
  });
});