define("client/pods/components/websites/mixins/websites-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_crud.default, _date.default, {
    listByTask: (0, _emberConcurrency.task)(function* ({
      adapterName,
      appendPath,
      filters,
      match,
      byLatest,
      campaignStatus,
      dateStart,
      dateEnd,
      query,
      resultsProperty,
      resultsToggle
    } = {}, onHideDropdown) {
      filters.setData('page', 1);
      if (match) {
        filters.setData('match', match);
      }
      if (dateStart) {
        filters.setData('dateStart', dateStart);
      }
      if (dateEnd) {
        filters.setData('dateEnd', dateEnd);
      }
      if (campaignStatus) {
        filters.setData('campaignStatus', campaignStatus);
      }
      if (byLatest || campaignStatus) {
        filters.setData('sort', [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]);
      }
      const searchSettings = {
        adapterName,
        appendPath,
        resultsProperty,
        resultsToggle,
        query,
        filters
      };
      const results = yield this.searchRecordsTask.perform(searchSettings);
      if (onHideDropdown) {
        onHideDropdown();
      }
      return results;
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.listByTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }
    }
  });
});