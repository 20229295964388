define("client/pods/components/extensions/apps/tw/invoices/panels-display/component", ["exports", "client/mixins/date", "client/mixins/crud"], function (_exports, _date, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _date.default, {
    init() {
      this._super(...arguments);
      this.set('unprepared', [Ember.Object.create({
        label: 'undispatched'
      })]);
      this.set('results', this.get('tab.results'));
      this.set('dateEnd', this.get('tab.dateEnd'));
      this.set('dateStart', this.get('tab.dateStart'));
    }
  });
});