define("client/initializers/crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'crud', 'service:crud');
    application.inject('adapter', 'crud', 'service:crud');
    application.inject('component', 'crud', 'service:crud');
  }
  var _default = _exports.default = {
    name: 'crud',
    initialize: initialize
  };
});