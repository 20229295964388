define("client/pods/components/channels/shop/campaigns/tracking-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VdeiW1IX",
    "block": "{\"symbols\":[\"@trackingData\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"trackingCode\"]],\"none\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"tt\",[\"none\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"trackingCode\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"replace\",[\"big_o=\",\"\",[23,1,[\"trackingCode\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"tt\",[\"none\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/tracking-code/template.hbs"
    }
  });
});