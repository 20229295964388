define("client/pods/products/inventory/adjustments/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        countLabel: '10',
        query: '',
        match: ['all'],
        statuses: [this.get('constants.status.active')],
        types: [],
        setTypes: [],
        name: '',
        code: '',
        barcode: '',
        tags: [],
        dateZ: '',
        adjustments: [],
        adjustment: '',
        adjustmentLabel: '',
        // to reset list dropdown label
        itemCode: '',
        item: '',
        description: '',
        unit: '',
        // to reset list dropdown label
        unitKey: '',
        qty: '',
        exclPrice: '',
        notes: '',
        sort: [{
          by: 'dateZ',
          direction: 'DESC'
        }, {
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    }
  });
});