define("client/pods/schedules/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    users: Ember.inject.service(),
    crud: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    // defaults () {
    //   return {
    //     description: '',
    //     from: '',
    //     end: '',
    //     discountType: 'rate',
    //     discountRate: '',
    //     discountAmount: '',
    //     allowOnSale: '',
    //     discountRateOnSale: ''
    //   }
    // },

    get statusClass() {
      const status = this._data.status;
      switch (status) {
        case 'inactive':
          return 'is-danger';
        case 'cancelled':
          return 'is-danger';
        case 'pending':
          return 'is-warning';
        case 'completed':
          return 'is-success';
      }
    },
    populate(data, attrs) {
      data.workflowsItemsActivities = this._transformWorkflowsItemsActivities(data.workflowsItemsActivities);
      this._super(data, attrs);
    },
    _transformWorkflowsItemsActivities(reviewSteps = []) {
      return reviewSteps.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: 'workflows/items/activities',
          data
        });
      });
    }
  });
});