define("client/pods/components/reports/commissions/panels-all/payout-editor/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReportsCommissionsPanelsAllPayoutEditor = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class ReportsCommissionsPanelsAllPayoutEditor extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "date", _descriptor, this);
      _initializerDefineProperty(this, "server", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "dataManager", _descriptor4, this);
      _initializerDefineProperty(this, "model", _descriptor5, this);
      _initializerDefineProperty(this, "payoutModel", _descriptor6, this);
      _initializerDefineProperty(this, "originalEditAmt", _descriptor7, this);
      this.crud.addLists(this, ['ledgers/accounts/lists/banks']);
    }
    get isAllowed() {
      if (this.args.isEditing) {
        return true;
      }

      // THIS LOGIC IS USED IF PAYOUTS IS ONLY ALLOWED ON LATEST MONTH
      // REMOVED FOR NOW

      // const mm = this?.args?.mm || {}
      // const yyyy = this?.args?.yyyy || {}
      // const period = this.date.getPeriod(this.date.getNowZ())
      // const amount = Number(this?.args?.model?._data?.outstandingTotal) || 0
      // if (mm.value === period.mm && yyyy.value === period.yyyy) {
      //   return true
      // }

      // return false
      return true;
    }
    cancel(dirty, onCloseDialogAction) {
      this.crud.cancelEditing({
        adapterName: 'ledgers/journals/cashbook',
        dirty
      });
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
    closeModalDialogAndReloadTab(onCloseDialogAction) {
      this.args.onReloadAfterPayout();
      onCloseDialogAction();
    }
    get maxPayoutWithEditAmt() {
      const originalEditAmt = this.originalEditAmt;
      const maxPayoutAmount = this?.args?.maxPayoutAmount || 0;
      return parseInt(originalEditAmt) + parseInt(maxPayoutAmount);
    }
    createModel(model) {
      this.originalEditAmt = this?.args?.payoutModel?._data?.total || 0;
      if (this.args.isEditing) {
        const payoutModel = this.dataManager.setAsRecord({
          adapterName: 'ledgers/journals/cashbook',
          data: this.args.payoutModel
        });
        this.payoutModel = this.crud.setupDirty({
          adapterName: 'ledgers/journals/cashbook',
          model: payoutModel,
          component: this
        });
        // this.maxPayoutWithEditAmt = parseInt(this.payoutModel._data.amount) + parseInt(this.args.maxPayoutAmount)
      } else {
        const todayZ = this.date.getStartOfDayZ();
        const payoutModel = this.crud.setupNewRecord({
          adapterName: 'ledgers/journals/cashbook',
          data: {
            dateZ: todayZ,
            amount: model?._data?.outstandingTotal,
            comments: '',
            contra: model._data.salesPerson,
            contactKey: model._data.salesPersonKey,
            description: 'Payment',
            txType: 'cashOut',
            relationship: 'supplier',
            docType: 'commissions'
          }
        });
        // this.maxPayoutWithEditAmt = 0 + parseInt(this.args.maxPayoutAmount)
        this.payoutModel = payoutModel;
      }
      this.updateDateParams();
    }
    updateDateParams() {
      const payoutModel = this.payoutModel;
      const dateZ = payoutModel?._data?.dateZ;
      const period = this.date.getPeriod(dateZ);
      payoutModel.setData('ref', period.monthStart);
      // payoutModel.setData('dateZ', period.dateZ)
      payoutModel.setData('dd', period.dd);
      payoutModel.setData('mm', period.mm);
      payoutModel.setData('yyyy', period.yyyy);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "payoutModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "originalEditAmt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModalDialogAndReloadTab", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeModalDialogAndReloadTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createModel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "createModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDateParams", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateDateParams"), _class.prototype), _class);
});