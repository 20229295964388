define("client/mixins/translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    translatePlaceholder: true,
    translatedPlaceholder: Ember.computed('placeholder', 'translatePlaceholder', function () {
      const placeholder = this.placeholder;
      if (placeholder == null) {
        return '';
      }
      if (!this.translatePlaceholder) {
        return placeholder;
      }
      return this.intl.t(placeholder);
    }),
    shouldTranslateLabel(value, itemTranslate) {
      return this.settings.shouldTranslateLabel(value, this.translate, itemTranslate);
    }
  });
});