define("client/pods/contacts/departments/model", ["exports", "client/pods/base/model", "client/mixins/contacts-is-parent", "client/mixins/contacts-persons"], function (_exports, _model, _contactsIsParent, _contactsPersons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // details mixin add in persons mixin
  // export default BaseModel.extend({
  var _default = _exports.default = _model.default.extend(_contactsIsParent.default, _contactsPersons.default, {
    componentPath: 'contacts/departments/editor-departments',
    component: Ember.computed('_data.type', function () {
      return this.componentPath + this.get('_data.type');
    }),
    defaultForDocs: Ember.computed('_data.{isDefault,isDefaultFor,isDefaultForDocs.[]}', function () {
      if (this.get('_data.isDefault')) {
        if (this.get('_data.isDefaultFor') === 'all') {
          return ['all'];
        }
        return this.get('_data.isDefaultForDocs') || [];
      }
      return [];
    })
  });
});