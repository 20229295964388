define("client/pods/components/settings/defaults/3rd-party-editor/facebook-plugins/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    init() {
      this.crud.addLists(this, ['facebook/pages']);
      this._super(...arguments);
    }
  });
});