define("client/pods/components/elements/element-pushbtn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nfWPGDuf",
    "block": "{\"symbols\":[\"&default\",\"@isRunning\"],\"statements\":[[2,\" icon: empty span with background image \"],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"icon\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-pushbtn/template.hbs"
    }
  });
});