define("client/pods/components/modals/modal-container/component", ["exports", "jquery", "ramda-adjunct"], function (_exports, _jquery, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['modal-container'],
    autoPosition: false,
    hasModalOverlay: true,
    closeOnOverlayClick: true,
    // didInsertElement () {
    //   this._super(...arguments)
    //   const fixedContainers = [
    //     '.panel-grid__header-title',
    //     '.panel-grid__header',
    //     '.panel-grid__footer'
    //   ]

    //   const current = jQuery(this.element)

    //   const parentClassName = R.reduce((acc, className) => {
    //     if (acc !== false) {
    //       return acc
    //     }

    //     const parent = current.closest(className)
    //     if (parent.length > 0) {
    //       return className
    //     }

    //     return false
    //   }, false)(fixedContainers)

    //   this._setModalZIndex(fixedContainers, parentClassName, 'add', '.panel-grid')
    //   this._setModalZIndex(fixedContainers, parentClassName, 'remove', false)
    // },

    // didInsertElement () {
    //   this._super(...arguments)
    //   let element = this.$()
    //   element = R.path([0])(element)
    //   if (element) {
    //     const tabGrid = element.closest('.panel-grid')

    //     if (tabGrid) {
    //       this.modalElement = element
    //       const children = tabGrid.children || []
    //       const modalContainer = R.find(child => {
    //         return R.includes('tab-grid__modal', child.className)
    //       })(children)

    //       if (modalContainer) {
    //         modalContainer.appendChild(element)
    //       }
    //     }
    //   }
    // },

    didRender() {
      this._super(...arguments);
      if (this.autoPosition) {
        const container = this._getDropdownContainer();
        this._repositionContainer(container);
      }
    },
    _setModalZIndex(fixedContainers, parentClassName, action, setForContainerClassName) {
      // fixedContainers = R.without(parentClassName)(fixedContainers)
      // R.forEach(containerClassName => {
      //   let containers
      //   if (setForContainerClassName) {
      //     containers = jQuery(this.element).closest(setForContainerClassName).find(`${containerClassName}:visible`)
      //   } else {
      //     containers = jQuery(this.element).find(`${containerClassName}:visible`)
      //   }

      //   if (containers.length > 0) {
      //     if (action === 'add') {
      //       jQuery(containers).addClass('u-under-modal')
      //     } else {
      //       jQuery(containers).removeClass('u-under-modal')
      //     }
      //   }
      // })(fixedContainers)
    },
    _getDropdownContainer($child = null) {
      $child = $child || (0, _jquery.default)(this.element);
      let dropdownContainer = $child.closest('.modal-container__outer-wrapper');
      if (RA.lengthLte(0, dropdownContainer)) {
        dropdownContainer = $child.find('.modal-container__content');
      }
      if (RA.lengthLte(0, dropdownContainer)) {
        return null;
      }
      return dropdownContainer;
    },
    _repositionContainer(dropdownContainer = {}) {
      if (RA.lengthGt(0, dropdownContainer) && this.autoPosition) {
        const dropdownContainerDomElement = dropdownContainer[0];
        const parentElement = this.element.parentElement;
        const parentElementContainerBoundingClientRect = parentElement.getBoundingClientRect();
        const dropDownContainerBoundingClientRect = dropdownContainerDomElement.getBoundingClientRect();
        const width = dropdownContainer.outerWidth();
        const left = dropdownContainer.offset().left;
        const right = left + width;
        const viewportRight = document.documentElement.clientWidth;
        const viewportHeight = document.documentElement.clientHeight - 35;
        const modalElementBoundingClientRect = this.element.getBoundingClientRect();
        const moveModalOnTop = dropDownContainerBoundingClientRect.top + dropDownContainerBoundingClientRect.height > viewportHeight;
        const moveModalOnBottom = dropDownContainerBoundingClientRect.top < 70;
        if (moveModalOnTop) {
          this.element.dataset.moveModalOnTop = 'true';
          const newTopYPos = dropDownContainerBoundingClientRect.y - (parentElementContainerBoundingClientRect.bottom - dropDownContainerBoundingClientRect.top);
          const newNewTop = parentElementContainerBoundingClientRect.top - dropDownContainerBoundingClientRect.bottom;
          const maxTop = 70;
          if (newTopYPos > maxTop) {
            dropdownContainerDomElement.style.marginTop = `${newNewTop}px`;
          }
        } else if (moveModalOnBottom) {
          this.element.dataset.moveModalOnBottom = 'true';
          // @NOTE: Probably not the best way to do this, but it works for now
          dropdownContainerDomElement.style.top = `${parentElementContainerBoundingClientRect.top}px` || '35px';
          dropdownContainerDomElement.style.position = 'fixed';
        } else if (modalElementBoundingClientRect.top < 70) {
          // for modal in a modal
          this.element.style.marginTop = '95px';
        }
        if (right > viewportRight) {
          const rightDifference = right - viewportRight + 40;
          const maxLeft = 5;
          const newLeft = left - rightDifference;
          if (newLeft > maxLeft) {
            dropdownContainer.css('position', 'relative');
            dropdownContainer.css('left', `-=${rightDifference}px`);
          }
        }
      }
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        // const fixedContainers = [
        //   '.panel-grid__header-title',
        //   '.panel-grid__header',
        //   '.panel-grid__footer'
        // ]

        // const current = jQuery(this.element)

        // const parentClassName = R.reduce((acc, className) => {
        //   if (acc !== false) {
        //     return acc
        //   }

        //   const parent = current.closest(className)
        //   if (parent.length > 0) {
        //     return className
        //   }

        //   return false
        // }, false)(fixedContainers)

        // this._setModalZIndex(fixedContainers, parentClassName, 'remove', '.panel-grid')
        // this._setModalZIndex(fixedContainers, parentClassName, 'add', false)

        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });
});