define("client/pods/components/documents/filters-advanced/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsFiltersAdvancedComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service('products'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _class = class DocumentsFiltersAdvancedComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "productsService", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "campaignFilters", _descriptor6, this);
      _initializerDefineProperty(this, "shopNoneToggle", _descriptor7, this);
      this.setup();
    }
    setup() {
      this.crud.addLists(this, [
      // {
      //   name: 'taxes',
      //   appendPath: 'products'
      // },
      // 'filters/count',
      // 'products/brands',
      // 'products/lists/tags',
      // 'products/lists/display-tags',
      // 'products/lists/product-display-position',
      // 'products/lists/types',
      // 'products/lists/setTypes',
      // 'products/lists/tracking',
      // 'products/lists/invoiceDescriptions',
      // 'products/lists/editorSubPanels',
      // 'settings/roundingAuto',
      // 'units',
      // 'units/dimensions',
      // 'units/weight',
      // 'products/lists/pageImportBtnSubPanels',
      // 'products/lists/sync',
      // 'contacts/transporters',
      'channels/shop/campaigns/lists/locations', 'dispatch/lists/processes', 'dispatch/lists/date-header', 'documents/lists/filters/dispatch-status', 'documents/lists/filters/extra', 'paymentMethods', 'statuses', {
        name: 'users',
        appendPath: 'internal'
      }]);
      const defaultFiltersData = {};
      const source = R.path(['args', 'filters', '_data', 'source'])(this);
      if (source) {
        defaultFiltersData.query = source;
      }
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters',
        defaultFiltersData
      });
      this.campaignFilters = campaignFilters;

      // const recurringFilters = yield this.productsService.getRecurringFiltersTask.perform() || []
      // this.recurringFilters = recurringFilters
    }
    selectDispatchProcesses() {
      const filters = this.args.filters;
      const dispatchProcessesModels = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const dispatchProcesses = filters.getData('dispatchProcesses');
      const dispatchProcessesCriterias = R.pipe(R.filter(dispatchProcess => {
        return R.includes(dispatchProcess.getData('code'), dispatchProcesses);
      }), R.map(R.path(['_data', 'criterias'])))(dispatchProcessesModels);
      filters.setData('multipleDispatchProcessesCriterias', dispatchProcessesCriterias);
    }
    selectItem(item, isSelected) {
      if (this?.args?.onSearchTask) {
        this.args.onSearchTask.perform();
      }
    }
    get showStatusTags() {
      // @TODO: move to filter model
      const statuses = this?.crud?.lists?.statuses || [];
      const selectedStatuses = this.args.filters?._data?.statuses || [];
      if (selectedStatuses.length === 0) {
        return false;
      }
      if (selectedStatuses.length === statuses.length) {
        return false;
      }
      return true;
    }
    get showResetFilter() {
      // @TODO: move to filter model
      const filtersData = this?.args?.filters?._data;
      if (RA.isNilOrEmpty(filtersData)) {
        return false;
      }
      const statuses = filtersData.statuses;
      const hasImages = filtersData.hasImages;
      const item = filtersData.item;
      const itemCode = filtersData.itemCode;
      const price = filtersData.price;
      const setTypes = filtersData.setTypes;
      const tags = filtersData.tags;
      if (statuses.length !== 5 || tags.length !== 0 || setTypes.length !== 0 || RA.isNilOrEmpty(hasImages) !== true || item !== '' || itemCode !== '' || price !== '') {
        return true;
      }
      return false;
    }
    *updateFiltersTask() {
      // @TODO: how can this be improved?
      const filters = this.args.filters || {};
      Ember.set(filters, '_data', filters?._data || {}); // reset data

      return yield this.args.onSearchTask.perform(...arguments);
    }
    *clearSelectedItemsTask() {
      if (this.args.onResetSearchRecordsTask) {
        const filters = this.args.filters;
        if (this.args.onResetSearchRecordsTask.perform) {
          return yield this.args.onResetSearchRecordsTask.perform({
            filters
          });
        }
        return yield this.args.onResetSearchRecordsTask({
          filters
        });
      }

      // default action
      if (this?.crud?.reloadSearchRecordsTask) {
        const resetExcludes = this.args.tableViewOptions?.resetExcludes || [];
        yield this?.crud?.reloadSearchRecordsTask.perform({
          filters: this.args.filters,
          reset: true,
          resetExcludes
        });
        const filters = this.args.filters || {};
        Ember.set(filters, '_data', filters?._data || {}); // reset data
      }
    }
    get customPaymentMethods() {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.reject(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    }
    get defaultPaymentMethods() {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.filter(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    }
    loadShopSource(shop) {
      const filters = this.args.filters;
      filters.setData('sourceKey', shop.getData('_key'));
      if (this.args.onSearchTask) {
        this.args.onSearchTask.perform({
          filters
        });
      }
    }
    loadShopSourceWithNone() {
      const shopNoneToggle = this.shopNoneToggle;
      const filters = this.args.filters;
      if (shopNoneToggle) {
        filters.setData('sourceKey', 'none');
        filters.setData('source', this.intl.t('none'));
      } else {
        this.unloadShopSource();
      }
      if (this.args.onSearchTask) {
        this.args.onSearchTask.perform({
          filters
        });
      }
    }
    unloadShopSource() {
      const filters = this.args.filters;
      filters.setData('sourceKey', '');
      filters.setData('source', '');
      this.shopNoneToggle = false;
      if (this.args.onSearchTask) {
        this.args.onSearchTask.perform({
          filters
        });
      }
    }
    deselectDateFilter() {
      const filters = this.args.filters;
      filters.setData('dateHeaderValue', 'dateZ');
      filters.setData('dateZ', '');
      filters.setData('timestampZ_latest', '');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "campaignFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "shopNoneToggle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDispatchProcesses", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectDispatchProcesses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSelectedItemsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelectedItemsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadShopSource", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadShopSourceWithNone", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSourceWithNone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadShopSource", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselectDateFilter", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "deselectDateFilter"), _class.prototype), _class);
});