define("client/pods/components/channels/shop/shipping-zones/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oMU04iqY",
    "block": "{\"symbols\":[\"tabsHeaderComponent\",\"isOverflowMenu\",\"onToggleOverflowMenu\",\"@filters\",\"@onDisplay\",\"@onEdit\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onSearchTask\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container\"],[8],[0,\"\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@hasHeadersSort\",\"@filters\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onSearchTask\",\"@results\",\"@tab\",\"@tableClassNames\"],[\"channels/shop/shipping-zones/table-view/detail-row\",true,[23,4,[]],[23,5,[]],[23,6,[]],[23,5,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,4,[\"results\"]],[23,10,[]],\"shipping-zones-dash\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/btns-group\",[[12,\"class\",\"show-when-overflow\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPageNumbers\"],[[23,4,[]],[23,4,[\"results\"]],[23,8,[]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2,3]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-dash/template.hbs"
    }
  });
});