define("client/pods/components/transporters/seven-eleven-waybill/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, R, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TransportersSevenElevenWaybillComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('args.waybillsOptionsByProvider.showPresPrintButtonMessage'), _dec8 = Ember.computed('args.waybillsPerPage', 'args.waybillsOptionsByProvider'), _class = class TransportersSevenElevenWaybillComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "constants", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "transporter", _descriptor3, this);
      _initializerDefineProperty(this, "sevenElevenHtmlWaybills", _descriptor4, this);
      _initializerDefineProperty(this, "waybillsPerPagePreviousValue", _descriptor5, this);
      _initializerDefineProperty(this, "transporterArray", _descriptor6, this);
      this.crud.addLists(this, ['extensions/apps/tw/seven-eleven/lists/waybill-print-per-page']);
    }
    get waybillsOptionsByProvider() {
      return R.pathOr({}, ['args', 'waybillsOptionsByProvider', this.constants.transporterExtensions.sevenEleven])(this);
    }
    get showPresPrintButtonMessage() {
      if (!this.waybillsOptionsByProvider.waybillsPerPage) {
        return false;
      }
      if (this.waybillsOptionsByProvider.waybillsPerPage === this.args.waybillsPerPage) {
        return false;
      }
      return true;
    }
    *fetchTransporter(models = []) {
      const transporterArrayTask = R.pipe(R.map(R.pathOr('', ['_data', 'transporterKey'])), R.uniq, R.map(transporterKey => this.transporter.fetchTransporterTask.perform({
        transporterKey
      })))(models);
      const transporterArray = yield (0, _emberConcurrency.all)(transporterArrayTask);
      const transporter = transporterArray[0];
      const waybillsPerPage = R.pathOr(4, ['_data', 'sevenEleven', 'waybillsPerPage'])(transporter);
      const waybillPrintPerPageModel = R.pipe(R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-seven-eleven-lists-waybill-print-per-page']), R.filter(R.pathEq(['_data', 'value'], waybillsPerPage)), R.path([0]))(this);
      if (waybillPrintPerPageModel) {
        this.waybillsPerPage = waybillsPerPage;
        this.args.onSetWaybillsPerPage(waybillPrintPerPageModel, {
          showConfirmation: false,
          defaultWaybillsPerPage: waybillsPerPage
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transporter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sevenElevenHtmlWaybills", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "waybillsPerPagePreviousValue", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transporterArray", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "waybillsOptionsByProvider", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "waybillsOptionsByProvider"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showPresPrintButtonMessage", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showPresPrintButtonMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTransporter", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporter"), _class.prototype), _class);
});