define("client/pods/channels/shop/campaigns/members-campaign-menu/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    populate(data, attrs) {
      data.campaign = this._transformCampaign(data.campaign);
      this._super(...arguments);
    },
    _transformCampaign(data = {}) {
      return this.dataManager.setPartialRecord({
        adapterName: 'channels/shop/campaigns',
        data
      });
    }
  });
});