define("client/pods/components/helpers/info-tooltip/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * When to use: when the label and content are together.
   */
  let HelpersInfoTooltip = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class HelpersInfoTooltip extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", '');
      _initializerDefineProperty(this, "clickToView", _descriptor, this);
      // clickToClose = false
      _initializerDefineProperty(this, "translate", _descriptor2, this);
      _initializerDefineProperty(this, "hasContent", _descriptor3, this);
      _initializerDefineProperty(this, "isTooltipVisible", _descriptor4, this);
      _initializerDefineProperty(this, "hasModalOverlay", _descriptor5, this);
      _initializerDefineProperty(this, "mouseLeft", _descriptor6, this);
      _initializerDefineProperty(this, "hasInfoTooltip", _descriptor7, this);
      if (this.args.translate == null) {
        this.translate = true;
      } else {
        this.translate = this.args.translate;
      }
      if (this.args.clickToView == null) {
        this.clickToView = true;
      } else {
        this.clickToView = this.args.clickToView;
      }
      if (this.args.hasContent == null) {
        this.hasContent = true;
      } else {
        this.hasContent = this.args.hasContent;
      }
      if (this.args.hasModalOverlay == null) {
        this.hasModalOverlay = true;
      } else {
        this.hasModalOverlay = this.args.hasModalOverlay;
      }
      if (!this.clickToView) {
        this.hasModalOverlay = false;
      }
    }
    handleMouseEnter(e) {
      if (!this.clickToView) {
        this.isTooltipVisible = true;
        this.mouseLeft = false;
      }
    }
    handleMouseLeave(e) {
      if (!this.clickToView) {
        this.mouseLeft = true;
        window.setTimeout(() => {
          if (this.mouseLeft) {
            this.isTooltipVisible = false;
          }
        }, 100);
      }
    }
    toggleIsVisible(e) {
      if (this.clickToView) {
        if (!this.isTooltipVisible) {
          this.isTooltipVisible = true;
        }
      }
    }
    toggleClickToView() {
      this.clickToView = !this.clickToView;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "clickToView", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "translate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'translate'])(this);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasContent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isTooltipVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.isAlwaysVisible || false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasModalOverlay", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mouseLeft", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasInfoTooltip", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'hasInfoTooltip'])(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseLeave", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleIsVisible", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleClickToView", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleClickToView"), _class.prototype), _class);
});