define("client/helpers/isNotNumeric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute(params, hash) {
      const number = params[0];
      if (!isNaN(parseFloat(number)) && isFinite(number)) {
        return false;
      }
      return true;
    }
  });
});