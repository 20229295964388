define("client/pods/documents/dispatch/filters/model", ["exports", "client/pods/documents/filters/model", "ramda-adjunct"], function (_exports, _model, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        query: '',
        master: '',
        match: ['all'],
        documentStatus: [],
        documentStatuses: [],
        productStatuses: [],
        dispatchStatus: [],
        dispatchProcesses: [],
        multipleDispatchProcessesCriterias: [],
        date: '',
        dateHeaderValue: 'dateZ',
        dateHeaderLabel: 'date',
        dateZ: '',
        timestampZ_latest: '',
        dispatchDateZ: '',
        timestampZ: '',
        expectedDispatchDateZ: '',
        expectedPrepareDateZ: '',
        transporterArrivalDateZ: '',
        latestDispatchDate: '',
        docNo: '',
        contact: '',
        promoCode: '',
        code: '',
        ref: '',
        item: '',
        inclTotal: '',
        transporterLabel: '',
        transporterAccount: '',
        waybill: '',
        packages: '',
        paymentMethods: [],
        paymentMethodsCustomLabel: [],
        paymentTerms: [],
        paymentLastFiveDigits: '',
        pickupLocations: [],
        salesPersons: [],
        source: '',
        sourceKey: '',
        statuses: [this.get('constants.status.active')],
        contactKey: '',
        contactTags: [],
        isVoid: false,
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    },
    tagsCount: Ember.computed('_data.tags.[]', function () {
      return this.getPropLength('_data.tags');
    }),
    productTags: Ember.computed('_data.productTags.[]', function () {
      return this.getPropLength('_data.productTags');
    }),
    contactTags: Ember.computed('_data.contactTags.[]', function () {
      return this.getPropLength('_data.contactTags');
    }),
    dateCount: Ember.computed('_data.{dateHeaderValue,dateZ,timestampZ_latest}', function () {
      const dateHeaderValue = this?._data?.dateHeaderValue || 'dateZ';
      const dateZ = this?._data?.dateZ || '';
      const timestampZ_latest = this?._data?.timestampZ_latest || '';
      if (dateHeaderValue === 'dateZ' && dateZ) {
        return 1;
      }
      if (dateHeaderValue === 'timestampZ_latest' && timestampZ_latest) {
        return 1;
      }
      return 0;
    }),
    paymentMethodsCount: Ember.computed('_data.paymentMethods.[]', function () {
      return this.getPropLength('_data.paymentMethods');
    }),
    paymentMethodsCustomLabelCount: Ember.computed('_data.paymentMethodsCustomLabel.[]', function () {
      return this.getPropLength('_data.paymentMethodsCustomLabel');
    }),
    paymentTermsCount: Ember.computed('_data.paymentTerms.[]', function () {
      return this.getPropLength('_data.paymentTerms');
    }),
    inclTotalCount: Ember.computed('_data.inclTotal', function () {
      const inclTotal = this?._data?.inclTotal || '';
      if (RA.isNilOrEmpty(inclTotal)) {
        return 0;
      }
      return 1;
    }),
    paymentLastFiveDigitsCount: Ember.computed('_data.paymentLastFiveDigits', function () {
      const paymentLastFiveDigits = this?._data?.paymentLastFiveDigits || '';
      if (RA.isNilOrEmpty(paymentLastFiveDigits)) {
        return 0;
      }
      return 1;
    }),
    transporterLabelCount: Ember.computed('_data.transporterLabel', function () {
      const transporterLabel = this?._data?.transporterLabel || '';
      if (RA.isNilOrEmpty(transporterLabel)) {
        return 0;
      }
      return 1;
    }),
    transporterAccountCount: Ember.computed('_data.transporterAccount', function () {
      const transporterAccount = this?._data?.transporterAccount || '';
      if (RA.isNilOrEmpty(transporterAccount)) {
        return 0;
      }
      return 1;
    }),
    waybillCount: Ember.computed('_data.waybill', function () {
      const waybill = this?._data?.waybill || '';
      if (RA.isNilOrEmpty(waybill)) {
        return 0;
      }
      return 1;
    }),
    packagesCount: Ember.computed('_data.packages', function () {
      const packages = this?._data?.packages || '';
      if (RA.isNilOrEmpty(packages)) {
        return 0;
      }
      return 1;
    }),
    pickupLocationsCount: Ember.computed('_data.pickupLocations.[]', function () {
      return this.getPropLength('_data.pickupLocations');
    }),
    productStatusesCount: Ember.computed('_data.productStatuses.[]', function () {
      return this.getPropLength('_data.productStatuses');
    }),
    dispatchDateZCount: Ember.computed('_data.dispatchDateZ', function () {
      const dispatchDateZ = this?._data?.dispatchDateZ || '';
      if (RA.isNilOrEmpty(dispatchDateZ)) {
        return 0;
      }
      return 1;
    }),
    dispatchProcessesCount: Ember.computed('_data.dispatchProcesses.[]', function () {
      return this.getPropLength('_data.dispatchProcesses');
    }),
    sourceKeyCount: Ember.computed('_data.sourceKey', function () {
      const sourceKey = this?._data?.sourceKey || '';
      if (RA.isNilOrEmpty(sourceKey)) {
        return 0;
      }
      return 1;
    }),
    salesPersonsCount: Ember.computed('_data.salesPersons.[]', function () {
      return this.getPropLength('_data.salesPersons');
    }),
    documentStatusesCount: Ember.computed('_data.documentStatuses.[]', function () {
      return this.getPropLength('_data.documentStatuses');
    })
  });
});