define("client/pods/filters/count/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        value: 10,
        label: '10'
      }, {
        value: 20,
        label: '20'
      }, {
        value: 30,
        label: '30'
      }, {
        value: 40,
        label: '40'
      }, {
        value: 50,
        label: '50'
      }, {
        value: 60,
        label: '60'
      }, {
        value: 70,
        label: '70'
      }, {
        value: 80,
        label: '80'
      }, {
        value: 90,
        label: '90'
      }, {
        value: 100,
        label: '100'
      }];
      return _nventor.default.resolve(data);
    }
  });
});