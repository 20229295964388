define("client/pods/components/documentation/documentation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "POVQ7oUC",
    "block": "{\"symbols\":[\"&attrs\",\"@isLabel\",\"@isHelp\",\"@isGroup\",\"@tab\",\"&default\"],\"statements\":[[4,\"if\",[[25,6]],null,{\"statements\":[[0,\"  \"],[14,6,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",false],[13,1],[8],[0,\"\\n    \"],[5,\"documentation/documentation-icon\",[],[[\"@isLabel\",\"@isHelp\",\"@isGroup\",\"@documentation\",\"@documentationId\",\"@documentationResource\",\"@component\",\"@tab\",\"@documentationWrapper\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,0,[\"documentation\"]],[23,0,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,0,[]],[23,5,[]],[23,0,[]]]]],[0,\"\\n\\n    \"],[5,\"documentation/documentation-desc\",[],[[\"@isLabel\",\"@isHelp\",\"@isGroup\",\"@documentation\",\"@documentationId\",\"@documentationResource\",\"@documentationWrapper\",\"@tab\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,0,[\"documentation\"]],[23,0,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,0,[]],[23,5,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentation/documentation/template.hbs"
    }
  });
});