define("client/pods/documents/purchases/returns/adapter", ["exports", "@ember-data/adapter", "client/pods/documents/mixins/adapters", "client/pods/documents/model"], function (_exports, _adapter, _adapters, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adapters.default, {
    resourcePath: 'api/protected/documents/purchases/returns',
    modelObj: _model.default,
    idParam: '_id',
    hasHCTExtension: false,
    init() {
      this._super(...arguments);
      this.set('infoRequiredAttrs', ['contact', 'dateZ']);
      this.set('detailsRequiredAttrs', ['item']);
    }
  });
});