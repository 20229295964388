define("client/pods/components/payments/card/panels-display/panels-statement/payout-details/component", ["exports", "ramda", "client/mixins/big", "ember-concurrency"], function (_exports, R, _big, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_big.default, {
    tagName: '',
    didInsertElement() {
      this._super(...arguments);
      const period = this.period;
      const statement = this.statement;
      this.fetchPaymentsTask.perform(period, statement);
    },
    fetchPaymentsTask: (0, _emberConcurrency.task)(function* (period, statement) {
      const payload = period;
      payload.podKey = statement.podKey;
      const response = yield this.server.call('GET', 'api/protected/payments/card/payouts', payload);
      let results = response.data || [];
      results = R.map(result => Ember.Object.create(result), results);
      this.set('payouts', results);
    }),
    payoutsTotal: Ember.computed('payouts.[]', function () {
      const payouts = this.payouts;
      const roundingCurrency = this.roundingCurrency;
      return this.sumPropAndAutoRound(roundingCurrency, 'amount', payouts);
    }),
    outstandingTotal: Ember.computed('statement.totalDue', 'payoutsTotal', function () {
      const payoutsTotal = this.payoutsTotal;
      const totalDueBig = this.newBig(this.get('statement.totalDue'));
      const roundingCurrency = this.roundingCurrency;
      return totalDueBig.minus(payoutsTotal).toFixed(roundingCurrency);
    })
  });
});