define("client/pods/components/msgs/info-msg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XbH3eO4r",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"translatedMessage\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/msgs/info-msg/template.hbs"
    }
  });
});