define("client/pods/components/workflows/related/editor/detail-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WASqcZLF",
    "block": "{\"symbols\":[\"@related\",\"@model\",\"@isReadonly\",\"@isHeader\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n\"],[0,\"    \"],[7,\"th\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"type\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"resource\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \\n\"],[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"th\",true],[8],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@translate\",\"@list\",\"@itemValueKey\",\"@itemLabelKey\",\"@disabled\",\"@value\"],[true,true,[23,0,[\"crud\",\"lists\",\"workflows-related-types\"]],\"_data.value\",\"_data.label\",[23,1,[\"_data\",\"resourceValue\"]],[23,1,[\"_data\",\"resource\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[5,\"workflows/related/editor/detail-searcher\",[],[[\"@related\",\"@isReadonly\"],[[23,1,[]],[23,3,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n    \\n\"],[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[5,\"elements/btn-delete\",[],[[\"@icon\",\"@label\",\"@onSubmit\",\"@disabled\"],[\"fas fa-trash\",\"\",[28,\"fn\",[[23,2,[\"removeRelated\"]],[23,1,[]]],null],[23,3,[]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/related/editor/detail-editor/template.hbs"
    }
  });
});