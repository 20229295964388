define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent = _exports.default = (_dec = Ember._action, _class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent extends _component.default {
    get modalDialogIsVisible() {
      const model = this.args.model;
      if (model) {
        const bulkDiscountConflictDetails = model.getData('bulkDiscountConflictDetails') || [];
        if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscountConflictDetails)) {
          return true;
        }
      }
      return false;
    }
    onCloseBulkDiscountConflictDialog(onCloseDialogAction) {
      const model = this.args.model;
      const bulkDiscountConflictDetails = model.getData('bulkDiscountConflictDetails') || [];
      if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscountConflictDetails)) {
        model.setData('bulkDiscountConflictDetails', []);
      }
      if (this.args.onDone) {
        this.args.onDone();
      }
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onCloseBulkDiscountConflictDialog", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseBulkDiscountConflictDialog"), _class.prototype), _class);
});