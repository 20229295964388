define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/has-variants/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P3i3KWab",
    "block": "{\"symbols\":[\"variantChild\",\"childIndex\",\"@isPresetTableView\",\"@isDetailRow\",\"@index\",\"@model\",\"@whiteSpace\",\"@tableViewOptions\",\"@results\",\"@filters\",\"@hasHeadersSort\",\"@commissionBaseCostNewModel\",\"@campaignsDetailsModel\",\"@wasSaved\",\"@setCommissionBaseCostFromHistoryTask\",\"@commissionBaseCostNew\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details\",[],[[\"@isPresetTableView\",\"@isDetailRow\",\"@model\",\"@index\",\"@whiteSpace\",\"@tableViewOptions\",\"@results\",\"@filters\",\"@hasHeadersSort\",\"@commissionBaseCostNewModel\",\"@campaignsDetailsModel\",\"@wasSaved\",\"@setCommissionBaseCostFromHistoryTask\"],[[23,3,[]],[23,4,[]],[23,16,[]],[23,5,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]]]]],[0,\"\\n\"],[4,\"each\",[[23,16,[\"_data\",\"variantChildren\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details\",[],[[\"@isPresetTableView\",\"@isDetailRow\",\"@model\",\"@index\",\"@details\",\"@whiteSpace\",\"@tableViewOptions\",\"@results\",\"@filters\",\"@hasHeadersSort\",\"@commissionBaseCostNewModel\",\"@campaignsDetailsModel\",\"@wasSaved\",\"@setCommissionBaseCostFromHistoryTask\"],[[23,3,[]],[23,4,[]],[23,1,[]],[28,\"concat\",[[23,5,[]],\".\",[28,\"calculate\",[[23,2,[]],\"+\",\"1\"],null]],null],[23,6,[\"_data\",\"variantChildren\"]],[28,\"concat\",[\"\\t\",[23,7,[]]],null],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/has-variants/template.hbs"
    }
  });
});