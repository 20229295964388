define("client/pods/components/news/panels-new/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    adapterName: 'news',
    init: function () {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      if (!this.model) {
        const name = this.name || '';
        const master = this.master;
        const data = {
          name,
          master,
          status: this.get('constants.status.active')
        };

        // setup new news model
        const model = this.setupNewRecord({
          data
        });
        this.set('model', model);
      }
    }
  });
});