// import Mixin from '@ember/object/mixin'

// /**
//  * helper methods for setting up lists for tabs and panels
//  */
// export default Mixin.create({

//   // init() {
//   //
//   //   this._super(...arguments);
//   //
//   //   this.setData('persons', []);
//   // },

//   addPerson (data) {
//     const person = this.dataManager.setAsRecord({
//       adapterName: 'contacts/persons',
//       data: data
//     })
//     const persons = this.getData('persons')

//     persons.pushObject(person)
//   },

//   removePerson (person) {
//     const persons = this.getData('persons')
//     persons.removeObject(person)
//   }
// })
define("client/pods/contacts/persons/contacts-persons", [], function () {
  "use strict";
});