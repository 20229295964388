define("client/pods/channels/shop/campaigns/lists/locations/model", ["exports", "client/pods/options/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    defaults() {
      return {
        // @TODO: change
        deliveryDatesSelectSettings: {
          enable: false,
          prepareDays: null,
          deliveryMaxDays: null,
          expectedArrivalDateSubTitle: '',
          disallowedDispatchDays: [],
          isRequired: true,
          isAllowedToday: false,
          latestShippingTime: ''
        }
      };
    }
  });
});