define("client/pods/components/channels/shop/campaigns/campaigns-editor/reward-editor/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopCampaignsCampaignsEditorRewardEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _class = class ChannelsShopCampaignsCampaignsEditorRewardEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "search", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
      _defineProperty(this, "adapterName", 'channels/shop/rewards');
      this.filters = this.search.setupFilters({
        adapterName: 'channels/shop/rewards/filters',
        defaultFiltersData: {
          query: '',
          status: ['active']
        }
      });
    }

    // @task
    // * fetchRewardTask ({ query }) {
    //   query = query.trim()
    //   if (query) {
    //     const filters = this.filters
    //     const adapterName = this.adapterName
    //     filters.setData('query', query)

    //     const results = yield this.crud.searchInputTask.perform({
    //       adapterName,
    //       filters,
    //       component: this,
    //       resultsProperty: 'rewardsList'
    //     })

    //     return results
    //   }
    // }

    onLoadItem(selectedItem) {
      this.args.model.set('_data.rewardKey', selectedItem._data._key);
      this.args.model.set('_data.isAllowSpendReward', true);
      this.args.model.set('_data.isAllowEarnReward', true);
    }
    onResetItem() {
      this.args.model.set('_data.rewardKey', '');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onLoadItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onLoadItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onResetItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onResetItem"), _class.prototype), _class);
});