define("client/helpers/get-dot-path", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function getDotPath(params) {
    const model = R.last(params);
    if (!model || R.isEmpty(model)) {
      return '';
    }
    const path = R.pipe(R.dropLast(1), R.map(path => {
      if (R.type(path) !== 'String') {
        return R.toString(path);
      }
      return path;
    }), R.map(R.split('.')), R.flatten, R.join('.'))(params);
    return R_.dotPathOr(null, path)(model);
  });
});