define("client/pods/dispatch/export/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/documents/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/dispatch/processes/export',
    modelObj: _model.default,
    idParam: false,
    resultsProperty: 'docs' // findAll returns an object { docs: [results], summaryData } instead of the standard [results] array
  });
});