define("client/pods/components/documents/document-print/footer-sales-terms/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    isTermsVisible: true,
    hasPrintTerms: Ember.computed('model._data.terms', 'isTermsVisible', function () {
      if (!this.isTermsVisible) {
        return false;
      }
      return this.get('model._data.terms');
    }),
    actions: {
      togglePrintTerms() {
        this.toggleProperty('isTermsVisible');
      }
    }
  });
});