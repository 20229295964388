define("client/pods/components/dispatch/panels-display/print-item/component", ["exports", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    tagName: '',
    init() {
      this._super(...arguments);
    },
    isPrintable: Ember.computed('model._data.dispatchProcess', function () {
      const model = this.model;
      if (model && model.get('dispatchProcess') === this.get('dispatchProcess._data.code')) {
        return true;
      }
      return false;
    }),
    // for print all, hasGroups always true
    paymentMethodKeys: Ember.computed('model._data.{paymentMethodKey,groups.@each.synced}', function () {
      const model = this.model || {};
      if (model) {
        return model.getGroupedDocumentsPaymentMethodKeys({
          document: model,
          hasGroups: true,
          hasPaymentLastFiveDigits: false,
          isJoinResults: true,
          isTranslate: true
        });
      }
      return [];
    }),
    groupedDocsTotal: Ember.computed('model._data.{groups.@each.synced,exclTotal,inclTotal,taxTotal,rewardPointEarned,returnRewardAdjustmentPoints,returnRewardAdjustmentAmount}', function () {
      const model = this.model || {};
      if (model) {
        return model.getGroupedDocumentsTotal({
          document: model,
          hasGroups: true
        });
      }
      return {
        exclTotal: 0,
        taxTotal: 0,
        inclTotal: 0,
        cashCollectionInclTotal: 0,
        rewardPointEarned: 0,
        returnRewardAdjustmentPoints: 0,
        returnRewardAdjustmentAmount: 0
      };
    }),
    groupedDocsInclTotal: Ember.computed('groupedDocsTotal.{inclTotal,cashCollectionInclTotal}', function () {
      const cashCollectionInclTotal = this.groupedDocsTotal.cashCollectionInclTotal;
      if (cashCollectionInclTotal && cashCollectionInclTotal !== '0') {
        return cashCollectionInclTotal;
      }
      return this.groupedDocsTotal.inclTotal;
    }),
    hasGroupsReturns: Ember.computed('model._data.groups.@each.synced', function () {
      const model = this.model || {};
      let hasGroupsReturns = false;
      if (model && this.model) {
        const groups = model.getData('groups') || [];
        R.forEach(group => {
          const groupReturnDetails = group.get('returnsDetails') || [];
          if (RA.isNotNilOrEmpty(groupReturnDetails)) {
            hasGroupsReturns = true;
          }
        })(groups);
      }
      return hasGroupsReturns;
    }),
    maskedContact: Ember.computed('model._data.{transporterContactPerson,contact}', function () {
      const model = this?.model || {};
      let transporterContactPerson = model?._data?.transporterContactPerson || '';
      transporterContactPerson = R.pipe(R.trim, R.take(3))(transporterContactPerson);
      let contact = model?._data?.contact || '';
      contact = R.pipe(R.trim, R.take(3))(contact);
      let name;
      if (transporterContactPerson) {
        name = _nventor.default.maskString(transporterContactPerson, {
          start: 1,
          end: 1,
          placeholder: '*'
        });
        if (contact && contact !== transporterContactPerson) {
          const maskedContact = _nventor.default.maskString(contact, {
            start: 1,
            end: 1,
            placeholder: '*'
          });
          name += `(${maskedContact})`;
        }
        return name;
      } else {
        name = contact;
      }
      return _nventor.default.maskString(name, {
        start: 1,
        end: 1,
        placeholder: '*'
      });
    })
  });
});