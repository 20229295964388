define("client/pods/components/menus/tab-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TFDwZuG1",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"menuProxy\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"menus/tab-menu/tab-item\",null,[[\"item\",\"menuLabelKey\",\"selected\",\"translate\",\"errors\",\"classNames\",\"onSelect\"],[[23,1,[]],[24,[\"menuLabelKey\"]],[24,[\"selected\"]],[24,[\"translate\"]],[24,[\"errors\"]],[24,[\"itemClassNames\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"\\t\\t\"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/tab-menu/template.hbs"
    }
  });
});