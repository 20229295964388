define("client/pods/components/workflows/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let WorkflowsTabContainerComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('workflows'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _class = class WorkflowsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "dataManager", _descriptor3, this);
      _initializerDefineProperty(this, "dragAndDrop", _descriptor4, this);
      _initializerDefineProperty(this, "search", _descriptor5, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor6, this);
      _initializerDefineProperty(this, "workflowsService", _descriptor7, this);
      _initializerDefineProperty(this, "adapterName", _descriptor8, this);
      _initializerDefineProperty(this, "currentWorkflow", _descriptor9, this);
      _initializerDefineProperty(this, "dirty", _descriptor10, this);
      _initializerDefineProperty(this, "filters", _descriptor11, this);
      _initializerDefineProperty(this, "filtersWorkflows", _descriptor12, this);
      _initializerDefineProperty(this, "workflows", _descriptor13, this);
      _defineProperty(this, "adapterNameWorkflows", 'workflows/filters');
      _defineProperty(this, "filtersAdapterName", 'workflows/stagesCollection/filters');
      _initializerDefineProperty(this, "newModel", _descriptor14, this);
      _initializerDefineProperty(this, "newItemModel", _descriptor15, this);
      _initializerDefineProperty(this, "showSideBar", _descriptor16, this);
      _initializerDefineProperty(this, "status", _descriptor17, this);
      _initializerDefineProperty(this, "userKey", _descriptor18, this);
      this.crud.addLists(this, ['users/related', 'workflows/lists/menu', 'workflows/lists/items/statuses']);
      this.setupFilters();
      this.fetchDefaultDataTask.perform();
    }
    get allowedStatuses() {
      const statuses = this.crud.lists['workflows-lists-items-statuses'] || [];
      if (this.currentWorkflow) {
        const hasNextWorkflowKey = this.currentWorkflow?._data?.nextWorkflowKey;
        if (hasNextWorkflowKey) {
          return R.reject(R.pathEq(['_data', 'value'], 'successful'))(statuses);
        }
      }
      return statuses;
    }
    setupFilters() {
      this.filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          status: ['open'],
          users: [],
          sort: [{
            by: 'timestampZ',
            direction: 'ASC'
          }]
        }
      });
      this.filtersWorkflows = this.search.setupFilters({
        adapterName: this.adapterNameWorkflows,
        resultsProperty: 'results'
      });
    }
    *fetchDefaultDataTask() {
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        appendPath: '/type/workflows',
        filters: this.filtersWorkflows,
        component: this
      });
    }
    *onSearchInputTask(query) {
      if (!query) {
        return yield this.fetchDefaultDataTask.perform();
      }
      return yield this.crud.searchInputTask.perform({
        adapterName: this.adapterName,
        appendPath: '/type/workflows',
        filters: this.filtersWorkflows,
        component: this
      });
    }

    // @action
    // dash (model, results) {
    //   // const filters = this.filters
    //   // if (RA.isNilOrEmpty(filters.results)) {
    //   //   const adapterName = this.adapterName
    //   //   filters.setData('query', '')
    //   //   this.fetchDefaultDataTask.perform({ adapterName, filters })
    //   // }

    //   const subTabOptions = {
    //     component: 'workflows/panels-dash'
    //   }
    //   return this.tabsManager.replaceSubTab(this.args.tab, subTabOptions)
    // }

    display(workflow) {
      const workflowKey = workflow?._data?._key;
      this.filters.setData('workflowKey', workflowKey);
      this.filters.setData('status', [this.status]);
      const subTabOptions = {
        component: 'workflows/panels-display',
        model: workflow
      };
      this.currentWorkflow = workflow;
      this.showSideBar = false;
      return this.tabsManager.replaceSubTab(this.args.tab, subTabOptions);
    }
    displayItem(item) {
      const subTabOptions = {
        component: 'workflows/panels-display-item',
        model: item
      };
      return this.tabsManager.replaceSubTab(this.args.tab, subTabOptions);
    }
    updateWorkflowIndexesOnDrop(indexStart, indexEnd, results) {
      let reIndexBlogs;
      if (indexStart > indexEnd) {
        reIndexBlogs = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexBlogs = R.slice(indexStart, R.inc(indexEnd))(results);
      }
      this.crud.adapterCallTask.perform('updateIndexes', {
        adapterName: 'workflows/batch',
        appendPath: '/indexes',
        model: reIndexBlogs
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "adapterName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'workflows';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentWorkflow", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "filtersWorkflows", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "newModel", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "newItemModel", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "showSideBar", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'open';
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "userKey", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDefaultDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSearchInputTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onSearchInputTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayItem", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "displayItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateWorkflowIndexesOnDrop", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updateWorkflowIndexesOnDrop"), _class.prototype), _class);
});