define("client/pods/components/export/worksheet/editor/customworksheet-nested/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qUAO0N48",
    "block": "{\"symbols\":[\"customSheet\",\"@translate\",\"@labelKey\",\"@itemHasContentKey\",\"@model\",\"@removeListDropdown\",\"@specsCollection\",\"@availableCustomWorksheets\"],\"statements\":[[4,\"each\",[[23,5,[\"_data\",\"defaultExportCustomSheets\"]]],null,{\"statements\":[[0,\"  \"],[5,\"export/worksheet/editor/customworksheet-nested/customworksheet-item\",[],[[\"@translate\",\"@classNames\",\"@disabled\",\"@labelKey\",\"@itemHasContentKey\",\"@hasDropAboveContainer\",\"@isDraggable\",\"@model\",\"@removeListDropdown\",\"@specsCollection\",\"@onAfterAddItem\",\"@customSheet\",\"@availableCustomWorksheets\"],[[23,2,[]],\"nav-tree\",[23,0,[\"disabled\"]],[23,3,[]],[23,4,[]],true,[23,0,[\"isDraggable\"]],[23,5,[]],[23,6,[]],[23,7,[]],[23,0,[\"fetchSpecsTask\"]],[23,1,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/export/worksheet/editor/customworksheet-nested/template.hbs"
    }
  });
});