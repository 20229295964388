define("client/pods/components/contacts/filters-advanced/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactsFiltersAdvancedComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service('contacts'), _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class ContactsFiltersAdvancedComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "contactsService", _descriptor2, this);
      _initializerDefineProperty(this, "dateService", _descriptor3, this);
      _initializerDefineProperty(this, "crud", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "big", _descriptor6, this);
      _initializerDefineProperty(this, "recurringFilters", _descriptor7, this);
      this.setup();
    }
    setup() {
      this.crud.addLists(this, ['ages', 'filters/count', 'channels/shop/rewards', 'contacts/lists/tags', 'contacts/lists/filters/extra', 'toggles/yesNo', 'contacts/lists/relationships', 'paymentTerms', 'statuses', {
        name: 'users',
        appendPath: 'internal'
      }, 'periods/months']);
    }

    // @action
    // selectItem (item, isSelected) {
    //   if (this?.args?.onSearchTask) {
    //     this.args.onSearchTask.perform()
    //   }
    // }

    get memberLevelsList() {
      const rewards = this.crud.lists['channels-shop-rewards'] || [];

      // const rewardKeys = this.args.filters?._data?.rewardKeys || []
      // if (RA.isNilOrEmpty(rewardKeys)) {
      //   return []
      // }

      // filter rewards if rewardKeys are selected
      return R.pipe(
      // R.filter(reward => {
      //   return R.includes(reward?._data?._key || '', rewardKeys)
      // }),
      R.map(reward => {
        return reward?._data?.memberLevels || [];
      }), R.flatten)(rewards);
    }
    get availableTransporterExtensions() {
      const transporters = this.crud.lists['contacts-transporters'] || [];
      return R.reduce((acc, model) => {
        const isTransporterExtensionAlreadyAdded = acc.some(accModel => accModel?._data?.transporterExtension === model?._data?.transporterExtension);
        if (isTransporterExtensionAlreadyAdded) {
          return acc;
        }
        return acc.concat(model);
      }, [])(transporters);
    }
    get showStatusTags() {
      // @TODO: move to filter model
      const statuses = this?.crud?.lists?.statuses || [];
      const selectedStatuses = this.args.filters?._data?.statuses || [];
      if (selectedStatuses.length === 0) {
        return false;
      }
      if (selectedStatuses.length === statuses.length) {
        return false;
      }
      return true;
    }
    get birthdayMonthTagSuffix() {
      const filters = this?.args?.filters;
      const birthdayMonthStart = filters.getData('birthdayMonthStart') || '';
      const birthdayMonthStartLabel = filters.getData('birthdayMonthStartLabel') || '';
      const birthdayMonthEnd = filters.getData('birthdayMonthEnd') || '';
      const birthdayMonthEndLabel = filters.getData('birthdayMonthEndLabel') || '';
      let birthdayMonthTagSuffix = '';
      if (birthdayMonthStart) {
        birthdayMonthTagSuffix = `: ${birthdayMonthStartLabel}`;
      }
      if (birthdayMonthEnd) {
        birthdayMonthTagSuffix = `${birthdayMonthTagSuffix} - ${birthdayMonthEndLabel}`;
      }
      return birthdayMonthTagSuffix;
    }
    deselectBirthdayMonth() {
      const filters = this?.args?.filters;
      filters.setData('birthdayMonthStart', '');
      filters.setData('birthdayMonthStartLabel', '');
      filters.setData('birthdayMonthEnd', '');
      filters.setData('birthdayMonthEndLabel', '');
    }
    get birthdayYearTagSuffix() {
      const filters = this?.args?.filters;
      const birthdayYearStart = filters.getData('birthdayYearStart') || '';
      const birthdayYearEnd = filters.getData('birthdayYearEnd') || '';
      let birthdayYearTagSuffix = '';
      if (birthdayYearStart) {
        birthdayYearTagSuffix = `: ${birthdayYearStart}`;
      }
      if (birthdayYearEnd) {
        birthdayYearTagSuffix = `${birthdayYearTagSuffix} - ${birthdayYearEnd}`;
      }
      return birthdayYearTagSuffix;
    }
    deselectBirthdayYear() {
      const filters = this?.args?.filters;
      filters.setData('birthdayYearStart', '');
      filters.setData('birthdayYearEnd', '');
    }
    get ageSuffix() {
      const filters = this?.args?.filters;
      const ageStart = filters.getData('ageStart') || '';
      const ageEnd = filters.getData('ageEnd') || '';
      if (ageStart && ageEnd) {
        return `: ${ageStart} - ${ageEnd}`;
      }
      if (ageStart) {
        return `: ${ageStart}`;
      }
      if (ageEnd) {
        return `: ${ageEnd}`;
      }
      return '';
    }
    deselectAgeStartAndAgeEnd() {
      const filters = this?.args?.filters;
      filters.setData('ageStart', '');
      filters.setData('ageEnd', '');
    }
    verifyAgeStartAndAgeEnd() {
      const filters = this?.args?.filters;
      const ageStart = filters.getData('ageStart') || '';
      const ageEnd = filters.getData('ageEnd') || '';

      // FLIP THE AGE IF REVERSED
      if (RA.isNotNilOrEmpty(ageStart) && RA.isNotNilOrEmpty(ageEnd) && this.big.newBig(ageEnd).lt(ageStart)) {
        filters.setData('ageStart', ageEnd);
        filters.setData('ageEnd', ageStart);
      }
    }

    // get showResetFilter () {
    //   return true
    //   //   // @TODO: move to filter model
    //   //   const filtersData = this?.args?.filters?._data
    //   //   if (RA.isNilOrEmpty(filtersData)) {
    //   //     return false
    //   //   }

    //   //   const statuses = filtersData.statuses
    //   //   // const hasImages = filtersData.hasImages
    //   //   const name = filtersData.name
    //   //   const code = filtersData.code
    //   //   const price = filtersData.price
    //   //   const setTypes = filtersData.setTypes
    //   //   const tags = filtersData.tags

    //   //   if (
    //   //     statuses.length !== 5 ||
    //   //     tags.length !== 0 ||
    //   //     setTypes.length !== 0 ||
    //   //     RA.isNilOrEmpty(hasImages) !== true ||
    //   //     item !== '' ||
    //   //     itemCode !== '' ||
    //   //     price !== ''
    //   //   ) {
    //   //     return true
    //   //   }

    // //   return false
    // }

    // @task
    // * updateFiltersTask () {
    //   // @TODO: how can this be improved?
    //   const filters = this.args.filters || {}
    //   set(filters, '_data', filters?._data || {})// reset data

    //   return yield this.args.onSearchTask.perform(...arguments)
    // }

    // @task
    // * clearSelectedItemsTask () {
    //   if (this?.crud?.reloadSearchRecordsTask) {
    //     yield this?.crud?.reloadSearchRecordsTask.perform({ filters: this.args.filters, reset: true })
    //     const filters = this.args.filters || {}
    //     set(filters, '_data', filters?._data || {}) // reset data
    //   }
    // }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "recurringFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselectBirthdayMonth", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "deselectBirthdayMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselectBirthdayYear", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "deselectBirthdayYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselectAgeStartAndAgeEnd", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "deselectAgeStartAndAgeEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "verifyAgeStartAndAgeEnd", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "verifyAgeStartAndAgeEnd"), _class.prototype), _class);
});