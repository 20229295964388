define("client/pods/components/documents/pending/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // import { computed } from '@ember/object'
  // import nventor from '../../../../../utils/nventor'
  let DocumentPendingComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _class = class DocumentPendingComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "settings", _descriptor5, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "isLoaded", _descriptor8, this);
      _initializerDefineProperty(this, "listsIsLoaded", _descriptor9, this);
      const defaultTaxMethod = this.settings.getProp(`${this.args?.tab?.docType}PendingTaxMethod`) || 'exclusive';
      let contactModel;
      let productModel;
      let contact = '';
      let item = '';
      if (this.args?.fromContactsTab) {
        contactModel = this.args?.model || {};
        contact = contactModel.getData('name');
      }
      if (this.args?.fromProductsTab) {
        productModel = this.args?.model || {};
        item = productModel.getData('name');
      }
      this.filters = this.search.setupFilters({
        adapterName: 'documents/data/pending/filters',
        resetDefaults: {
          contact,
          item
        },
        defaultFiltersData: {
          docType: this.args?.tab?.docType,
          docName: this.args?.tab?.docName,
          contact,
          item,
          taxMethod: defaultTaxMethod
        }
      });

      // get taxMethod from
      this.crud.addLists(this, ['documents/lists/types/docTypes', 'taxMethods/filters', {
        adapterName: 'documents/data/pending/types',
        appendPath: this.filters?._data?.docType,
        options: {
          docType: this.filters?._data?.docType
        }
      }, {
        adapterName: 'documents/data/pending/menu',
        appendPath: `${this.filters?._data?.docType}-${this.filters?._data?.docName}`,
        options: {
          docType: this.filters?._data?.docType,
          docName: this.filters?._data?.docName
        }
      }]);
    }
    afterLoadLists() {
      if (this.isLoaded) {
        return false;
      }
      this.isLoaded = true;
      const tab = this.args.tab;
      this.loadTabTask.perform({
        tab
      });
    }
    get listsMenu() {
      return this.crud?.lists[`documents-data-pending-menu--${this.filters?._data?.docType}-${this.filters?._data?.docName}`] || [];
    }
    get listsPendingDocNames() {
      return this.crud?.lists[`documents-data-pending-types--${this.filters?._data?.docType}`] || [];
    }
    get filtersAdvancedAdapterName() {
      const currentSubTab = this.tabsManager.getActiveSubTab(this.args.tab);
      const activeSubTabLabel = currentSubTab?.type || '';
      if (activeSubTabLabel === 'byProducts') {
        return 'documents/data/pending/products';
      }
      if (activeSubTabLabel === 'byOrders') {
        return 'documents/data/pending/orders';
      }
      return '';
    }
    *loadTabTask({
      tab
    }) {
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        // const model = loadInstructions.getData('model')
        // if (model) {
        //   this.display(loadInstructions.getData('model'))
        // } else {
        //   const id = loadInstructions.getData('find.id')
        //   if (id) {
        //     const found = yield this.findTask.perform({
        //       appendPath: id
        //     })
        //     this.display(found)
        //   }

        //   const query = loadInstructions.getData('search.query')
        //   if (query) {
        //     filters.setData('query', query)
        //     const results = yield this.searchRecordsTask.perform({ filters })
        //     const found = results.get('firstObject')
        //     this.display(found)
        //   }
        // }
      } else {
        const menu = this.listsMenu || [];
        const firstPanelModel = menu.firstObject || {};
        if (RA.isNotNilOrEmpty(firstPanelModel)) {
          this.display(firstPanelModel);
        }
      }
    }
    *refetchTask() {
      // first update lists
      yield this.crud.addLists(this, [{
        adapterName: 'documents/data/pending/types',
        appendPath: this.filters?._data?.docType,
        options: {
          docType: this.filters?._data?.docType
        }
      }, {
        adapterName: 'documents/data/pending/menu',
        appendPath: `${this.filters?._data?.docType}-${this.filters?._data?.docName}`,
        options: {
          docType: this.filters?._data?.docType,
          docName: this.filters?._data?.docName
        }
      }]);
      const currentSubTab = this.tabsManager.getActiveSubTab(this.args.tab);
      if (currentSubTab) {
        return this.display(currentSubTab);
      }
      const menu = this.menuList || [];
      const firstPanelModel = menu.firstObject || {};
      return this.display(firstPanelModel);
    }
    display(subTabOptions) {
      const tab = this.args.tab;

      // @NOTE: this is bad. due to a design flaw
      // when clicking a menu list item, the subTabOptions is a model.
      // after instantiating a subTab the model does not have _data
      // solution, also have ._data for subTab models (keep a copy of subTab in _data)
      this.filters.setData('combined', subTabOptions?.combined || subTabOptions?._data?.combined);
      this.filters.setData('type', subTabOptions?.type || subTabOptions?._data?.type);
      this.filters.reset();
      this.tabsManager.setHeader(tab, this.intl.t(`${this.filters?._data?.docType}`));
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    // @action
    // displayByProducts () {

    // }

    // isDispatchedProcessModel: computed('crud.lists.[]', function () {
    //   const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
    //   const isDispatched = this.get('constants.dispatchProcess.dispatched')
    //   return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses)
    // }),

    // updateDocInfoTask: task(function * (dirty) {
    //   const adapterName = this.adapterName
    //   return yield this.updateRecordTask.perform({
    //     adapterName,
    //     appendPath: '/info',
    //     model: dirty
    //   })
    // }).drop(),

    // documentsListsMenu: computed('listsIsLoaded', 'docType', 'docName', function () {
    //   const docType = this.filters?._data?.docType
    //   const docName = this.filters?._data?.docName
    //   return this.get(`crud.lists.documents-lists-menu--${docType}-${docName}`) || []
    // }),

    // resultsProxy: computed('results.[]', function () {
    //   const results = this.results || []
    //   return R.map(result => nventor.createModel(ModelProxy, {}, {
    //     model: result,
    //     isSelected: false,
    //     showDetails: false // @TODO: get this from settings
    //   }), results)
    // }),

    // dash (model, results) {
    //   const tab = this.tab
    //   this.set('showResults', false)

    //   if (nventor.isNilOrEmpty(results)) {
    //     const adapterName = this.adapterName
    //     const filters = this.filters
    //     filters.setData('query', '')
    //     results = this.fetchDefaultDataTask.perform({ adapterName, filters })
    //     this.set('results', results)
    //   }

    //   const subTabOptions = {
    //     component: 'documents/panel-dash'
    //   }

    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },

    // /**
    //  * display document
    //  * @param  {object} document model
    //  */
    // display (model) {
    //   this.set('showResults', false)
    //   const component = this.get('config.displayComponent')
    //   const subTabOptions = {
    //     component: component,
    //     model: model,
    //     config: this.config
    //   }

    //   const tab = this.tab
    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },

    // new () {
    //   this.set('showResults', false)

    //   const self = this

    //   const component = self.get('config.newComponent')
    //   const subTabOptions = {
    //     component: component,
    //     config: self.get('config')
    //   }

    //   const tab = this.tab
    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },

    // actions: {
    //   dash () {
    //     this.dash()
    //   },

    //   display (model) {
    //     this.set('showResults', false)

    //     if (model) {
    //       this.display(model)
    //     } else {
    //       // if no model then display dash (when model has been deleted)
    //       this.dash()
    //     }
    //   },

    //   new () {
    //     this.new(...arguments)
    //   },

    //   close (panel) {
    //     this.tab.closePanel(panel)
    //   },

    //   edit (model) {
    //     this.set('showResults', false)

    //     const self = this

    //     const component = self.get('config.editComponent')
    //     const subTabOptions = {
    //       component: component,
    //       model: model,
    //       config: self.get('config')
    //     }

    //     const tab = this.tab
    //     this.tabsManager.replaceSubTab(tab, subTabOptions)
    //   }
    // }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "listsIsLoaded", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refetchTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "refetchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _class);
});