define("client/pods/components/admin/payments/card/panels-activity/component", ["exports", "client/config/environment", "client/mixins/crud", "client/mixins/payments-list-by"], function (_exports, _environment, _crud, _paymentsListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _paymentsListBy.default, {
    adapterName: 'admin/payments/card',
    filtersAdapterName: 'admin/payments/card/filters',
    crud: Ember.inject.service(),
    init() {
      this.set('config', _environment.default);
      this._super(...arguments);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.listByTask.perform({
        adapterName: 'admin/payments/card',
        filters
      });
    }
  });
});