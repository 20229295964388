define("client/helpers/kbToMb", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    compute(params) {
      let size = params[0];
      if (size) {
        size = this.newBig(size);
        return size.div(1024).div(1024).toFixed(2) + ' MB';
      }
      return '0 MB';
    }
  });
});