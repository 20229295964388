define("client/pods/products/lists/filters/types/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,
    findAll() {
      const data = [{
        value: this.get('constants.productTypes.inventory'),
        label: this.get('constants.productTypes.inventory')
      }, {
        value: this.get('constants.productTypes.service'),
        label: this.get('constants.productTypes.service')
      }];
      const isVirtualOnly = this.settings?.isFeatureAllowed('features.isVirtualOnly');
      if (isVirtualOnly) {
        data.push({
          value: this.get('constants.productTypes.virtual'),
          label: this.get('constants.productTypes.virtual')
        });
      }
      return _nventor.default.resolve(data);
    }
  });
});