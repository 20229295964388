define("client/pods/components/menus/tab-menu-side/tab-item/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MenusTabMenuNewTabItemComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _class = class MenusTabMenuNewTabItemComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "classNames", this.args?.classNames || 'u-tab-menu__item');
      _defineProperty(this, "translate", true);
      _initializerDefineProperty(this, "menuLabelKey", _descriptor, this);
    }
    get item() {
      return this.args?.item || null;
    }
    get label() {
      return this.item.get?.(this.menuLabelKey);
    }
    get itemComponent() {
      return this.item?._data?.component || this.item?.component || '';
    }
    get itemValue() {
      return this.item?._data?.value || this.item?.value || '';
    }
    get itemLabel() {
      return this.item?._data?.label || this.item?.label || '';
    }

    // get currentActive () {
    //   if (this.args?.isCurrentActive) {
    //     const currentActive = this.args.isCurrentActive

    //     if (currentActive?.label) {
    //       return currentActive.label === this.itemLabel
    //     }

    //     if (currentActive?.value) {
    //       return currentActive.value === this.itemValue
    //     }

    //     return currentActive.component === this.itemComponent
    //   }
    //   return false
    // }

    get subTab() {
      const subTabs = this.args?.subTabs || [];
      const subTab = R.find(R.propEq('component', this.itemComponent))(subTabs);
      return subTab;
    }
    onClick() {
      if (this.args?.onSelect) {
        this.args.onSelect(this.item);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "menuLabelKey", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args?.menuLabelKey || '_data.label';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
});