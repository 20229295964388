define("client/pods/blogs/details/batch/statuses/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new"], function (_exports, R, _adapter, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/blogs/details/master',
    idParam: false,
    serialize(details) {
      const batch = R.map(detail => {
        return {
          _key: detail?._data._key
        };
      })(details);
      return {
        batch
      };
    }
  });
});