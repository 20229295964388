define("client/pods/components/news/panels-dash/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    crud: Ember.inject.service(),
    adapterName: 'news',
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['news/lists/tags', 'statuses']);
    }
  });
});