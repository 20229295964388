define("client/pods/components/app-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // the only purpose the app-container serves is to keep track of isLoggedIn state
  var _default = _exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),
    crud: Ember.inject.service(),
    classNames: ['u-full-height'],
    init() {
      this._super(...arguments);
      this.crud.addLists(this, ['languages']);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.auth.get('isLoggedIn')) {
        this.auth.logout();
      }
    }
  });
});