define("client/pods/components/layouts/btns-group/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LayoutsBtnsGroupComponent extends _component.default {
    get sizeClassNames() {
      return this.args.size || 'small';
    }
  }
  _exports.default = LayoutsBtnsGroupComponent;
});