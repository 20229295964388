define("client/pods/grapesjs/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: categories
   * @author Nafis Rubio
   * @since 17/09/2020
   * @copyright (C) 2020 BigOrdr
   * @description: Constant categories, here all categories are documented
   */
  /**
   * @typedef {Object} Category
   * @property {string} id
   * @property {string} label
   * */
  /**
   * @typedef {Object} Categories
   * @property {Category} complex
   * @property {Category} shop
   * @property {Category} basic
   */
  /**
   *
   * @param intl
   * @return {Categories}
   */
  var _default = intl => ({
    basic: {
      id: 'basic',
      label: intl.t('basic'),
      open: true
    },
    shop: {
      id: 'shop',
      label: intl.t('shop'),
      open: true
    },
    complex: {
      id: 'complex',
      label: intl.t('complex'),
      open: true
    }
  });
  _exports.default = _default;
});