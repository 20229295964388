define("client/pods/components/grapesjs/links-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OZPzaLmz",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"formInitData\",\"@modalDialogIsVisible\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\",\"@website\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@onToggle\",\"@closeOnOverlayClick\"],[\"add link\",[23,3,[]],[28,\"fn\",[[28,\"mut\",[[23,3,[]]],null]],null],false]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-container\",[],[[\"@containerId\",\"@hasColumns\",\"@isUserAllowed\",\"@formInitData\",\"@tab\"],[\"linksModal\",false,true,[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"websitesLinks\",[23,7,[]],[23,6,[]],[23,5,[]],[23,4,[]]]]],[23,6,[]]]],{\"statements\":[[0,\"\\n    \"],[5,\"websites/links-editor\",[],[[\"@model\",\"@website\",\"@formInitData\"],[[23,0,[\"tempModel\"]],[23,8,[]],[23,2,[]]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/btn-cancel\",[],[[\"@onSubmit\"],[[28,\"fn\",[[23,0,[\"cancel\"]],[23,1,[]]],null]]]],[0,\"\\n\\n      \"],[5,\"elements/btn-save\",[],[[\"@onSubmit\"],[[28,\"fn\",[[23,0,[\"save\"]],[23,1,[]]],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/grapesjs/links-modal/template.hbs"
    }
  });
});