define("client/pods/components/ledgers/journals/cashbook/cashbook-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LedgersJournalsCashbookCashbookEditor = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _class = class LedgersJournalsCashbookCashbookEditor extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "date", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "model", _descriptor5, this);
      _initializerDefineProperty(this, "errors", _descriptor6, this);
      _initializerDefineProperty(this, "txType", _descriptor7, this);
      _initializerDefineProperty(this, "contactsList", _descriptor8, this);
      _initializerDefineProperty(this, "docType", _descriptor9, this);
      _initializerDefineProperty(this, "account", _descriptor10, this);
      _initializerDefineProperty(this, "accountKey", _descriptor11, this);
      _initializerDefineProperty(this, "listsIsLoaded", _descriptor12, this);
      _defineProperty(this, "adapterName", 'ledgers/journals/cashbook');
      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'contacts/lists/relationships']);
    }
    *setupTask() {
      const txType = this?.args?.tab?.txType || '';
      const accountKey = this?.args?.accountKey || '';
      const filters = this?.args?.filters || {};
      if (RA.isNilOrEmpty(filters)) {
        return;
      }
      filters.setData('txType', txType);
      filters.setData('accountKey', accountKey);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: filters
      });
      const banksList = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      const account = banksList.findBy('_data._key', accountKey);
      this.account = account;
      this.contactsLists = [];
      this.txType = txType;
      this.accountKey = accountKey;
      this.docType = this?.args?.tab?.docType || '';
    }
    *fetchStatementTask({
      key,
      docType,
      dateStart,
      dateEnd
    }) {
      const params = {
        key,
        docType,
        dateStart,
        dateEnd
      };
      const result = yield this.crud.findTask.perform({
        adapterName: 'contacts/statements',
        appendPath: `/${docType}`,
        params
      });
      return result;
    }
    get displayAction() {
      return this.txType;
    }
    get defaultDescription() {
      let desc = 'payment';
      if (this.txType === 'cashIn') {
        desc = 'receipt';
      }
      return this.intl.t(desc);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "txType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "contactsList", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "docType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "accountKey", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "listsIsLoaded", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatementTask"), _class.prototype), _class);
});