define("client/pods/components/elements/status-tag/component", ["exports", "@glimmer/component", "ramda", "ramda-extension"], function (_exports, _component, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ElementsStatusBadgeComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "translate", R.isNil(this.args.translate) ? this.args.translate : true);
    }
    get statusModel() {
      const list = this.args.list || [];
      const status = this.args.status;
      const statusPath = this.args.statusPath || '_data.value';
      const statusModel = R.find(R_.dotPathEq(statusPath, status))(list);
      return statusModel;
    }
    get statusBadgeClassNames() {
      if (this.args.statusBadgeClassNames) {
        return this.args.statusBadgeClassNames;
      }
      const statusModel = this.statusModel;
      const statusClassNamePath = this.args.statusClassNamePath || '_data.textClassNames' || '_data.classNames';
      return R_.dotPath(statusClassNamePath)(statusModel) || '';
    }
    get statusLabel() {
      if (this.args.statusLabel) {
        return this.args.statusLabel;
      }
      const statusLabelPath = this.args.statusLabelPath || '_data.label';
      const statusModel = this.statusModel;
      return R_.dotPath(statusLabelPath)(statusModel) || '';
    }
  }
  _exports.default = ElementsStatusBadgeComponent;
});