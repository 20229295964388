define("client/pods/components/contacts/groups/table-view/detail-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: ''
    // classNameBindings: ['isDetailRow:table-view__detail-row']
  });
});