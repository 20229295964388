define("client/pods/components/elements/print-content-container/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _obj = {
    dispatchService: Ember.inject.service('dispatch'),
    constantsService: Ember.inject.service('constants'),
    tagName: 'div',
    classNames: ['print-content-container'],
    hasPrintBtn: true,
    hasTotalDue: false,
    batchStatus: null,
    didInsertElement() {
      this._super(...arguments);
      this.setPrintContainer();
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        window.$('#print-container').html('');
      }
    },
    setPrintContainer() {
      const html = this.$().find('.print-content:visible').html();
      window.$('#print-container').html(html);
    },
    allowRequestNewWaybill: Ember.computed('lastPrintTimestamp', function () {
      const isHCT = R.pathEq(['model', '_data', 'transporterExtension'], 'hct')(this);
      if (isHCT) {
        return true;
      }
      return false;
    }),
    hasWaybill: Ember.computed('lastPrintTimestamp', function () {
      const hasWaybill = R.pipe(R.path(['model', '_data', 'waybill']), RA.isNotNilOrEmpty)(this);
      if (hasWaybill) {
        return true;
      }
      return false;
    }),
    generateWaybillsTask: (0, _emberConcurrency.task)(function* ({
      isNewWaybill = false,
      isUpdate = false
    } = {}) {
      const documents = [this.model];
      const response = yield this.dispatchService.generateAndDownloadWaybillsTask.perform({
        component: this,
        documents,
        transporterExtension: this.constantsService.transporterExtensions.hct,
        isNewWaybill,
        isUpdate
      });
      this.set('batchStatus', response);
      const lastPrintTimestamp = new Date().getTime();
      this.set('lastPrintTimestamp', lastPrintTimestamp);
      return response;
    }),
    resetBatchStatus() {
      this.set('batchStatus', null);
    },
    actions: {
      printDialog() {
        this.setPrintContainer();
        if (this.onPrintDialog) {
          this.onPrintDialog();
        }
        window.print();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "resetBatchStatus", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetBatchStatus"), _obj), _obj));
});