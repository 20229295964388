define("client/pods/components/documents/pos/profiles-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentsPosProfilesEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('helpers'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('terminal'), _dec5 = Ember.inject.service('pos'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _class = class DocumentsPosProfilesEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "helpersService", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _initializerDefineProperty(this, "terminalService", _descriptor4, this);
      _initializerDefineProperty(this, "posService", _descriptor5, this);
      _initializerDefineProperty(this, "campaignsList", _descriptor6, this);
      _initializerDefineProperty(this, "posShopKey", _descriptor7, this);
      _initializerDefineProperty(this, "printerKey", _descriptor8, this);
      _initializerDefineProperty(this, "model", _descriptor9, this);
      _initializerDefineProperty(this, "einvoicesPrintersTerminalsFilters", _descriptor10, this);
      _initializerDefineProperty(this, "query", _descriptor11, this);
      _defineProperty(this, "adapterName", 'pos/lists/profiles');
      const model = this.args.model;
      const isEditing = this.args.isEditing;
      if (isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data: {
            autoIssueEInvoice: true
          }
        });
      }
      this.fetchCampaignsWithPosEnable.perform();
      const einvoicesPrintersTerminalsFilters = this.search.setupFilters({
        adapterName: 'users/einvoices-printers-terminals/filters'
      });
      this.terminalService.fetchEInvoicesPrinterTerminalsTask.perform({
        filters: einvoicesPrintersTerminalsFilters
      });
      this.einvoicesPrintersTerminalsFilters = einvoicesPrintersTerminalsFilters;
    }
    *fetchCampaignsWithPosEnable() {
      this.campaignsList = yield this.posService.fetchCampaignsWithPosEnableTask.perform();
    }
    *removeTask(onCloseDialogAction = R.identity) {
      const data = {
        adapterName: this.adapterName,
        model: this.model,
        noConfirm: true
      };
      yield this.crud.removeRecordTask.perform(data);
      onCloseDialogAction();
    }
    *saveTask(onCloseDialogAction = R.identity) {
      const data = {
        adapterName: this.adapterName,
        model: this.model
      };
      if (this.model.isDirty) {
        yield this.crud.replaceRecordTask.perform(data);
      } else {
        yield this.crud.createRecordTask.perform(data);
      }
      onCloseDialogAction();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "helpersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "terminalService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "posService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "campaignsList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "posShopKey", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "printerKey", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "einvoicesPrintersTerminalsFilters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchCampaignsWithPosEnable", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignsWithPosEnable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _class);
});