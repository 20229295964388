define("client/pods/components/specs/specs-editor/specs-table/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SpecsSpecsEditorSpecsTableComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _class = class SpecsSpecsEditorSpecsTableComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dragAndDrop", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _defineProperty(this, "tagName", 'span');
      _defineProperty(this, "classNames", []);
      _defineProperty(this, "translate", true);
      _defineProperty(this, "item", null);
      _defineProperty(this, "isViewable", true);
      _defineProperty(this, "labelKey", 'label');
      _defineProperty(this, "isDraggable", true);
      _defineProperty(this, "hasDropBelowContainer", true);
      _defineProperty(this, "hasDropAboveContainer", true);
      _defineProperty(this, "draggable", true);
      _initializerDefineProperty(this, "productsFilters", _descriptor4, this);
      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }]
        }
      });
      this.productsFilters = productsFilters;
    }
    get _hasDropAboveContainer() {
      if (this.hasDropAboveContainer && this.index === 0) {
        return true;
      }
      return false;
    }
    get allowedSpecs() {
      const dirty = this.args.dirty;
      const availableNormalSpecs = dirty?.availableNormalSpecs || [];
      const method = dirty.getData('method') || '';
      if (R.equals(method, 'byDetails') || R.equals(method, 'byDetailsWithGrandTotals')) {
        return R.reject(R.propEq('isAutoDetail', true))(availableNormalSpecs);
      }
      if (R.equals(method, 'byAllOrdersWithOneColumnRow') || R.equals(method, 'byAllOrdersWithOneColumnRowWithSummary')) {
        return R.reject(R.propEq('value', 'item'))(availableNormalSpecs);
      }
      return availableNormalSpecs;
    }

    /**
     * search product
     */
    *searchProductsTask({
      adapterName = 'products',
      query
    }) {
      if (query?.trim?.() !== '') {
        const filters = this.productsFilters;
        // filters.setData('query', query)

        const results = yield this.crud.searchInputTask.perform({
          adapterName: 'products',
          filters,
          component: this,
          resultsProperty: 'productsList'
        }, query);
        return results;
      }
    }
    addTableLookupValue(specChild) {
      const tableLookupArray = specChild?._data?.options?.tableLookupArray || [];
      tableLookupArray.pushObject(Ember.Object.create({
        input: '',
        output: ''
      }));
    }
    removeTableLookupValue(specChild, tableLookupObject) {
      const tableLookupArray = specChild?._data?.options?.tableLookupArray || [];
      tableLookupArray.removeObject(tableLookupObject);
    }
    drop() {
      if (this.onDrop) {
        this.onDrop(...arguments);
      }
    }
    select(disabled, item) {
      if (!disabled) {
        this.onSelect(item);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productsFilters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTableLookupValue", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addTableLookupValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTableLookupValue", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeTableLookupValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drop", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
});