define("client/pods/components/store-location/files/files-default-image/component", ["exports", "client/pods/components/store-location/files/mixins/store-location-files"], function (_exports, _storeLocationFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_storeLocationFiles.default, {
    fetchUploads: true
  });
});