define("client/pods/components/forms/form-input-row/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    tagName: '',
    error: null,
    customBlock: false,
    // complete custom block template
    translate: true,
    hideIsEmpty: false,
    didInsertElement() {
      this._super(...arguments);
      this.setErrorsTracker();
    },
    _tab: Ember.computed('tab', function () {
      if (this.tab) {
        return this.tab;
      }
      const formInitData = this.formInitData;
      return formInitData?.tab;
    }),
    hasErrorClassNames: Ember.computed('error', 'validationError', function () {
      if (this.validationError) {
        return 'has-error';
      }
      const error = this.error;
      if (error) {
        if (RA.isNilOrEmpty(error)) {
          return '';
        }
        return 'has-error';
      }
      return '';
    }),
    showTemplate: Ember.computed('hideIsEmpty', 'value', 'model', 'context', 'formInitData.wizardContainerComponent.updateProps.[]', function () {
      const context = this.context || '';
      const value = this.value || this.get(`model._data.${context}`);
      const hideIsEmpty = this.hideIsEmpty;
      if ((value == null || value === '') && hideIsEmpty) {
        return false;
      }
      const isWizard = this?.formInitData?.wizardContainerComponent?.isWizard;
      if (!isWizard) {
        return true;
      }
      const updateProps = R.pathOr([], ['formInitData', 'wizardContainerComponent', 'updateProps'])(this);
      const isFound = R.includes(context)(updateProps);
      if (isFound) {
        return true;
      }
      return false;
    }),
    validationError: Ember.computed('tab', 'tab.errors.[]', 'validationErrors.[]', function () {
      const validationErrors = this.validationErrors || [];
      if (validationErrors?.length > 0) {
        const found = R.find(R.pathEq(['context', 'key'], this.context))(validationErrors);
        if (found) {
          return this.intl.t(found.message);
        }
      }
      return this.validationService.getValidationErrorMessage({
        tab: this.tab,
        context: this.context
      });
    }),
    /**
     * call fn on TabsManagerModel to set ErrorsTracker
     */
    setErrorsTracker() {
      const tab = this.tab;
      const instanceId = this.model?.instanceId;
      if (tab && instanceId) {
        tab?.setErrorTrackerOnModel?.({
          context: this.context,
          contextArray: this.contextArray || [],
          label: this.label,
          instanceId: instanceId,
          isModal: this.isModal,
          tabId: tab?.id
        });
      }
    }
  });
});