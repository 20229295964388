define("client/pods/components/channels/shop/rewards/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ChannelsShopRewardsTabContainerComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class ChannelsShopRewardsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "constants", _descriptor2, this);
      _initializerDefineProperty(this, "crud", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "dataManager", _descriptor5, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor6, this);
      _initializerDefineProperty(this, "listsIsLoaded", _descriptor7, this);
      _defineProperty(this, "adapterName", 'channels/shop/rewards');
      _defineProperty(this, "filtersAdapterName", 'channels/shop/rewards/filters');
      this.crud.addLists(this, ['statuses', 'channels/shop/rewards/deductions', 'channels/shop/rewards/earnings', 'channels/shop/rewards/periods', 'channels/shop/rewards/requirements', 'channels/shop/rewards/types', 'channels/shop/rewards/lists/menu', 'channels/shop/rewards/lists/sub-panels', 'channels/shop/rewards/lists/sub-panels-editor', 'discounts/types', 'websites', 'products/lists/tags']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      Ember.set(this, 'filters', filters);
    }
    afterLoadLists() {
      this.fetchAndDisplayTask.perform();
    }
    *fetchAndDisplayTask() {
      const filters = this.filters;
      const model = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        query: '',
        filters
      });
      Ember.set(this, 'results', model);
      this.dashFn(model);
    }
    dashFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-dash',
        model: model
      };
      if (!model) {
        const filters = this.filters;
        filters.setData('query', '');
        this.fetchAndDisplayTask.perform();
      }
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }
    displayFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }
    editFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-edit',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }
    dash() {
      this.dashFn();
    }
    display(model) {
      if (model) {
        this.displayFn(model);
      } else {
        this.dashFn();
      }
    }
    edit(model, additionalOptions) {
      this.editFn(model, additionalOptions);
    }
    new() {
      const subTabOptions = {
        component: 'channels/shop/rewards/panels-new'
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "listsIsLoaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchAndDisplayTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAndDisplayTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dash", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "new", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "new"), _class.prototype), _class);
});