define("client/pods/components/websites/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gkvUNFAA",
    "block": "{\"symbols\":[\"@isWizard\",\"@stepsList\",\"@onEdit\",\"@onWizardDone\",\"@onWizardNew\",\"@onWizardNext\",\"@target\"],\"statements\":[[5,\"websites/websites-editor\",[],[[\"@tab\",\"@model\",\"@errors\",\"@isEditing\",\"@isNew\",\"@isWizard\",\"@saveTask\",\"@stepsList\",\"@onAfter\",\"@onCancel\",\"@onEdit\",\"@onWizardDone\",\"@onWizardNew\",\"@onWizardNext\",\"@target\"],[[23,0,[\"tab\"]],[23,0,[\"model\"]],[22,\"errors\"],true,true,[23,1,[]],[22,\"createRecordTask\"],[23,2,[]],[28,\"action\",[[23,0,[]],\"onAfter\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-new/template.hbs"
    }
  });
});