define("client/pods/extensions/apps/tw/t-cat/lists/thermosphere/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ExtensionsAppsTwTCatListsThermosphereAdapter extends _model.default {}
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: ExtensionsAppsTwTCatListsThermosphereAdapter,
    idParam: false,
    findAll() {
      const data = [{
        account: 'unrefrigerated',
        isDefault: true,
        name: 'unrefrigerated',
        rate: '0',
        value: 'unrefrigerated',
        label: 'unrefrigerated'
      }, {
        account: 'refrigerated',
        isDefault: true,
        name: 'refrigerated',
        rate: '0',
        value: 'refrigerated',
        label: 'refrigerated'
      }, {
        account: 'frozen',
        isDefault: true,
        name: 'frozen',
        rate: '0',
        value: 'frozen',
        label: 'frozen'
      }];
      return _nventor.default.resolve(data);
    }
  });
});