define("client/pods/components/flows/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FlowsTabContainerComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class FlowsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "crud", _descriptor2, this);
      _initializerDefineProperty(this, "search", _descriptor3, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);
      _initializerDefineProperty(this, "query", _descriptor5, this);
      _defineProperty(this, "adapterName", 'flows');
      _defineProperty(this, "filtersAdapterName", 'flows/filters');
      this.crud.addLists(this, [
      // 'filters/count',
      'flows/lists/menu', 'statuses']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.filters = filters;
    }
    afterLoadLists() {
      this.loadTabTask.perform();
    }
    *loadTabTask() {
      const tab = this.args.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);
      if (loadInstructions) {
        const model = loadInstructions.getData('model');
        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.crud.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        this.dash({});
      }
    }
    dash(model, results) {
      this.filters.set('showResults', false);
      const tab = this.args.tab;
      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        this.crud.searchRecordsTask.perform({
          adapterName,
          filters
        });
      }
      const subTabOptions = {
        component: 'flows/panels-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    /**
     * display the product
     * @param  {object} product model
     */
    display(model, additionalOptions = {}) {
      this.filters.set('showResults', false);
      const panelOptions = {
        component: 'flows/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    }
    new(subTabOptions) {
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }
    edit(model, additionalOptions) {
      this.allowQuickAdd = false;
      const tab = this.args.tab;
      const panelOptions = {
        component: 'flows/panels-edit',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dash", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "dash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "new", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "new"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _class);
});