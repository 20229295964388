define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/invoice-management/component", ["exports", "client/mixins/date", "ember-concurrency", "ramda-adjunct", "ramda"], function (_exports, _date, _emberConcurrency, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    eInvoicesService: Ember.inject.service('extensions/apps/tw/e-invoices'),
    tagName: '',
    init() {
      this._super(...arguments);
      let telephone = this.get('invoice._data.billingTelephone');
      if (!telephone) {
        telephone = this.get('invoice._data.telephone');
      }
      const customData = Ember.Object.create({
        email: this.get('invoice._data.email'),
        telephone,
        printerKey: this.get('invoice._data.printerKey'),
        twEInvoicePrinterId: this.get('invoice._data.twEInvoicePrinterId'),
        twEInvoiceUserKey: this.get('invoice._data.twEInvoiceUserKey'),
        twEInvoiceAllowPrintingFrom: this.get('invoice._data.twEInvoiceAllowPrintingFrom')
      });
      this.set('custom', customData);
    },
    allowCancel: Ember.computed('invoice._data.{cancellableDateZ,status}', 'modalDialogIsVisible', function () {
      const cancellableDateZ = this.get('invoice._data.cancellableDateZ');
      const status = this.get('invoice._data.status');
      if (status !== 'successful') {
        return false;
      }

      // @NOTE: this is for backwards compatablility
      // can be removed after sep 2021
      const currentPeriod = this.dateService.getPeriod(cancellableDateZ);
      const period = this.dateService.getPeriod(cancellableDateZ);
      let cancellableDate = this.dateService.getMoment(cancellableDateZ);
      const now = this.getMoment();
      if (RA.isOdd(period.mm) && currentPeriod.mm === period.mm) {
        cancellableDate = cancellableDate.date(15);
      }
      if (now.isBefore(cancellableDate)) {
        return true;
      }
      return false;
    }),
    queryStatusTask: (0, _emberConcurrency.task)(function* () {
      const key = this.invoice?._data?._key;
      yield this.crud.ajaxCallTask.perform('PATCH', {
        adapterName: 'extensions/apps/tw/e-invoices',
        data: {
          _key: key
        }
      });
    })
  });
});