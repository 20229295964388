define("client/pods/users/personal-settings/collection/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/users/personal-settings/collection/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/personal/collection',
    modelObj: _model.default,
    idParam: ['userKey', 'menuId'],
    idPrefix: 'users/personal-settings/collection'
  });
});