define("client/pods/components/operator/change-logs/search-filters/component", ["exports", "client/pods/mixins/products-list-by", "client/pods/mixins/crud"], function (_exports, _productsListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, _productsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    init() {
      this.crud.addLists(this, ['products/lists/filters/search', 'products/lists/filters/sort', 'products/lists/filters/status', 'products/lists/types', 'filters/count', 'filters/sortDirection']);
      this._super(...arguments);
    }
  });
});