define("client/pods/websites/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "ramda"], function (_exports, _adapter, _adaptersNew, _model, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';

  const filterTabs = (list, filterByLabel) => R.map(R.pipe(R.unless(R.propSatisfies(R.includes(R.__, filterByLabel), 'label'), R.set(R.lensProp('component'), null))))(list);
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/website/campaigns/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const customData = {
        list: [{
          value: 'settings',
          label: 'settings',
          component: 'websites/websites-editor/settings-editor',
          resource: 'api/protected/websites/sub-panels-edit/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'headers-editor',
          label: 'header-settings',
          component: 'websites/websites-editor/headers-editor',
          resource: 'api/protected/websites/sub-panels-edit/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'style-editor',
          label: 'settings_style',
          component: 'websites/websites-editor/style-editor',
          resource: 'api/protected/websites/sub-panels-edit/settings',
          tabType: 'reuseSubTab'
        }, {
          value: 'seo-editor',
          label: 'seo',
          component: 'websites/websites-editor/seo-editor',
          resource: 'api/protected/websites/sub-panels-edit/settings',
          tabType: 'reuseSubTab'
        }, {
          value: '3rd-party',
          label: '3rd-party',
          component: 'websites/websites-editor/3rd-party-editor',
          resource: 'api/protected/websites/sub-panels-edit/settings',
          tabType: 'reuseSubTab'
        }]
      };
      const isVirtualOnly = this.settings?.isFeatureAllowed('features.isVirtualOnly');
      if (isVirtualOnly) {
        const allowed = ['settings', 'style-editor', 'seo-editor'];
        customData.list = R.pipe(R.propOr([], 'list'), R.filter(item => {
          return R.includes(item.value)(allowed);
        }))(customData);
      }

      // customData.list = filterTabs(customData.list, ['settings', '3rd-party'])
      // const path = this.getFullPath({ pathName: 'findAllPath', appendPath, serverType })
      // return this.ajax.POST(path, customData).then(res => res?.data || [])

      // will be replaced by the code above
      return _nventor.default.resolve(customData.list);
    }
  });
});