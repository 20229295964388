define("client/pods/components/dispatch/bulk-split/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DispatchBulkSplitComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('products'), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _class = class DispatchBulkSplitComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dataManager", _descriptor2, this);
      _initializerDefineProperty(this, "productsService", _descriptor3, this);
      _initializerDefineProperty(this, "search", _descriptor4, this);
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "nextDispatchProcessCode", _descriptor6, this);
      _initializerDefineProperty(this, "products", _descriptor7, this);
      _initializerDefineProperty(this, "productQuery", _descriptor8, this);
      _initializerDefineProperty(this, "successful", _descriptor9, this);
      _initializerDefineProperty(this, "unsuccessful", _descriptor10, this);
      _initializerDefineProperty(this, "submitted", _descriptor11, this);
      this.crud.addLists(this, ['dispatch/lists/processes']);
      this.setup();
    }
    get isSplitBulkDisabled() {
      const fullCount = Number(this?.filters?.fullCount) || 0;
      if (this?.nextDispatchProcess?._data?.code && fullCount > 0) {
        return false;
      }
      return true;
    }
    setup() {
      const filters = this.search.setupFilters({
        adapterName: 'documents/filters'
      });
      this.filters = filters;
      this.submitted = false;
      this.successful = [];
      this.unsuccessful = [];
    }
    get nextDispatchProcess() {
      const dispatchProcesses = this.crud.lists['dispatch-lists-processes'];
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      if (!nextDispatchProcessCode) {
        return {};
      }
      const nextDispatchProcess = R.find(R.pathEq(['_data', 'code'], nextDispatchProcessCode))(dispatchProcesses);
      return nextDispatchProcess;
    }
    *fetchPreviewDocsToSplit() {
      const products = this.products;
      // @TODO: needs filter model
      const filters = this.filters;
      filters.setData('products', products);
      filters.setData('currentDispatchProcess', this?.args?.dispatchProcess?._data?.code || '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: 'documents/sales/invoices/preview',
        filters
      });
    }
    *selectProductTask(item) {
      this.productQuery = '';
      const products = this.products || [];
      const itemData = {
        itemKey: item?._data?._key,
        item: item?._data?.name
      };
      products.removeObject(itemData);
      products.pushObject(itemData);
      this.products = products;
      yield this.fetchPreviewDocsToSplit.perform();
    }
    *removeSelectedTask(item) {
      const products = this.products || [];
      products.removeObject(item);
      this.products = products;
      yield this.fetchPreviewDocsToSplit.perform();
    }
    *bulkSplitTask() {
      const nextDispatchProcess = this.nextDispatchProcess;
      const resp = yield this.crud.ajaxCallTask.perform('PATCH', {
        adapterName: 'documents/sales/invoices',
        appendPath: '/final/split/batch',
        data: {
          products: this.products,
          currentDispatchProcess: this.args?.dispatchProcess?._data?.code,
          nextDispatchProcess: nextDispatchProcess?._data?.code,
          nextDispatchProcessLabel: nextDispatchProcess?._data?.name,
          nextDispatchProcessLabelTranslate: nextDispatchProcess?._data?.translate
        }
      });
      this.successful = R.propOr([], 'successful')(resp);
      this.unsuccessful = R.propOr([], 'unsuccessful')(resp);
      this.submitted = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nextDispatchProcessCode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "products", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "productQuery", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "successful", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "unsuccessful", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "submitted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPreviewDocsToSplit", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPreviewDocsToSplit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProductTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectProductTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSelectedTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeSelectedTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkSplitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "bulkSplitTask"), _class.prototype), _class);
});