define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/has-variants/component", ["exports", "ramda", "client/mixins/crud"], function (_exports, R, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_crud.default, {
    tagName: '',
    showVariantDetails: false,
    hasTitle: true,
    title: Ember.computed('hasTitle', function () {
      if (this.hasTitle) {
        return 'variant children';
      }
      return '';
    }),
    isExpanded: Ember.computed('hasTitle', function () {
      if (this.hasTitle) {
        return false;
      }
      return true;
    }),
    variantChildrenSorted: Ember.computed('item._data.variantChildren.@each.variantChildIndex', function () {
      const variantChildren = this.get('item._data.variantChildren') || [];
      return R.sortWith([R.ascend(R.path(['_data', 'variantChildIndex']))])(variantChildren);
    })
  });
});