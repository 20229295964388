define("client/pods/grapesjs/plugins/scratch-card", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: scratch-card
   * @author Derek Chou
   * @since 02/11/2022
   * @copyright (C) 2022 BigOrdr
   * @description: Scratch Card
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'bigordr-scratch-card';
    const pluginBlockId = `${pluginName}-block`;
    const bigordrScratchCardCover = 'bigordr-scratch-card-cover';
    const bigordrScratchCardBackground = 'bigordr-scratch-card-background';
    const COVER_SELECTOR = '[data-gjs-type="bigordr-scratch-card-cover"]';
    const BACKGROUND_SELECTOR = '[data-gjs-type="bigordr-scratch-card-background"]';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', 'bigordr-scratch-card'])(editor);
    const {
      onDbClick,
      onDrop,
      intl,
      isReadonly,
      /** @type {Categories} */
      categories
    } = options;
    const blockLabel = intl.t(pluginName);
    DomComponents.addType(bigordrScratchCardCover, {
      model: {
        defaults: {
          tagName: 'div',
          attributes: {
            'data-gjs-component': bigordrScratchCardCover,
            alt: 'none'
          },
          style: {
            width: 'auto'
          },
          badgable: false,
          copyable: false,
          draggable: false,
          droppable: false,
          editable: false,
          highlightable: false,
          hoverable: false,
          layerable: false,
          removable: false,
          resizable: false,
          selectable: false,
          stylable: false,
          traits: []
        }
      }
    });
    DomComponents.addType(bigordrScratchCardBackground, {
      model: {
        defaults: {
          tagName: 'div',
          attributes: {
            'data-gjs-component': bigordrScratchCardBackground,
            alt: 'none'
          },
          style: {
            width: 'auto'
          },
          badgable: false,
          copyable: false,
          draggable: false,
          droppable: false,
          editable: false,
          highlightable: true,
          hoverable: false,
          layerable: false,
          removable: false,
          resizable: false,
          selectable: false,
          stylable: false,
          traits: []
        }
      }
    });
    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          tagName: 'div',
          name: blockLabel,
          draggable: true,
          droppable: false,
          attributes: {
            'data-gjs-component': pluginName
          },
          toolbar: [{
            attributes: {
              class: 'fas fa-pencil-alt'
            },
            command(editor) {
              const el = editor.getSelected().getEl();
              if (onDbClick) {
                onDbClick(el.closest('[data-gjs-type="bigordr-scratch-card"]'));
              }
            }
          }, {
            attributes: {
              class: 'fa fa-trash'
            },
            command: 'tlb-delete'
          }],
          components: [{
            type: 'row',
            style: {
              padding: '0px 0px 0px 0px'
            },
            components: [{
              selectable: false,
              removable: false,
              draggable: false,
              type: bigordrScratchCardCover,
              attributes: {},
              traits: []
            }, {
              selectable: false,
              removable: false,
              draggable: false,
              type: bigordrScratchCardBackground,
              attributes: {},
              traits: []
            }],
            badgable: false,
            copyable: false,
            draggable: false,
            droppable: false,
            editable: false,
            highlightable: false,
            hoverable: false,
            layerable: false,
            removable: false,
            resizable: false,
            selectable: false,
            stylable: false,
            traits: []
          }]
        }
      },
      view: {
        // Add easily component specific listeners with `events`
        // Being component specific (eg. you can't attach here listeners to window)
        // you don't need to care about removing them when the component is removed,
        // they will be managed automatically by the editor
        events: {
          dblclick: 'onDbClick'
        },
        onDbClick(event) {
          if (onDbClick && !isReadonly) {
            const el = editor.getSelected().getEl();
            onDbClick(el.closest('[data-gjs-type="bigordr-scratch-card"]'));
          }
        },
        onRender({
          model,
          editor,
          el
        }) {
          const coverEl = el.querySelector(COVER_SELECTOR);
          const backgroundEl = el.querySelector(BACKGROUND_SELECTOR);
          const dataset = el.dataset || {};
          if (RA.isNilOrEmpty(dataset)) {
            return;
          }
          const {
            backgroundBgColor,
            backgroundImageSet,
            backgroundImageUrl,
            backgroundText,
            backgroundTextColor,
            backgroundType,
            coverBgColor,
            coverImageSet,
            coverImageUrl,
            coverText,
            coverTextColor,
            coverType,
            imageHeight,
            imageWidth
          } = dataset;

          // set for cover
          this._setInnerLayout({
            el: coverEl,
            width: imageWidth,
            height: imageHeight,
            imageSet: coverImageSet,
            imageUrl: coverImageUrl,
            text: coverText,
            textColor: coverTextColor,
            type: coverType,
            bgColor: coverBgColor
          });

          // set gor background
          this._setInnerLayout({
            el: backgroundEl,
            width: imageWidth,
            height: imageHeight,
            imageSet: backgroundImageSet,
            imageUrl: backgroundImageUrl,
            text: backgroundText,
            textColor: backgroundTextColor,
            type: backgroundType,
            bgColor: backgroundBgColor
          });
        },
        _setInnerLayout({
          el,
          width,
          height,
          imageSet,
          imageUrl,
          text,
          textColor,
          type,
          bgColor
        }) {
          const hasChildren = el.children.length > 0;
          if (!hasChildren) {
            el.appendChild(document.createElement('div'));
          }
          const innerEl = document.createElement('div');
          innerEl.style.width = `${width}px`;
          innerEl.style.height = `${height}px`;
          innerEl.style.color = textColor;
          innerEl.style.display = 'flex';
          innerEl.style.alignItems = 'center';
          innerEl.style.justifyContent = 'center';
          const textContent = document.createElement('p');
          textContent.innerHTML = text;
          innerEl.appendChild(textContent);

          // @TODO: use different imageUrl from imageSet based on preview device

          const isImage = !!(type === 'backgroundImage' && imageUrl);
          if (isImage) {
            innerEl.style.background = `url(${imageUrl}-/scale_crop/${width}x${height}/center/)`;
          } else {
            innerEl.style.backgroundColor = bgColor;
          }
          const replaceTarget = el.children[0];
          replaceTarget.replaceWith(innerEl);
        }
      }
    });
    BlockManager.add(pluginBlockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: pluginName
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
        <svg width="100%" height="100%"  xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 419 291">
        <defs>
          <style>
            .b,
            .c {
              fill: #fff;
            }

            .d {
              fill: #efefef;
            }

            .e {
              fill: #231815;
            }

            .f {
              fill: #3e3a39;
            }

            .c {
              stroke: #3e3a39;
              stroke-miterlimit: 10;
              stroke-width: 8px;
            }

          </style>
        </defs>
        <g>
          <rect class="b" x="65" y="38" width="337" height="236" />
          <path class="e" d="M408,280H59V32H408v248Zm-337-12H396V44H71v224Z" />
        </g>
        <g>
          <rect class="d" x="19" y="10" width="361" height="236" />
          <path class="e" d="M386,252H13V4H386V252ZM25,240H374V16H25V240Z" />
        </g>
        <path class="b"
          d="M124.29,210.56c54.54-51.08,116.27-93.41,179.32-133.2l-12.11-20.72c-50.38,37.59-100.94,75.32-146.11,119.2-4.25,4.13-4.58,11.19-.94,15.76,3.4,4.27,10.6,6.69,15.48,3.09,52.05-38.32,104.31-76.69,160.93-108.1l-12.11-20.72c-55.55,39.42-109.56,81.02-161.87,124.65-10.39,8.67,3.3,26.54,14.54,18.85,52.47-35.87,106.14-69.95,160.92-102.17l-12.11-20.72c-43.6,30.9-85.63,64.01-125.82,99.22-10.12,8.86,3.38,26.81,14.54,18.85,49.49-35.3,101.12-67.52,154.59-96.45,5.7-3.08,7.5-10.96,4.31-16.42-3.46-5.91-10.6-7.2-16.42-4.31l-15.72,7.82c-5.8,2.89-7.42,11.09-4.31,16.42,3.46,5.91,10.6,7.2,16.42,4.31l15.72-7.82-12.11-20.72c-53.46,28.93-105.1,61.15-154.59,96.45l14.54,18.85c38.67-33.88,79.02-65.75,120.96-95.47,5.28-3.74,7.78-10.48,4.31-16.42-3.27-5.6-10.81-7.6-16.42-4.31-54.78,32.22-108.46,66.3-160.92,102.17l14.54,18.85c50.75-42.33,103.12-82.66,157.01-120.9,5.28-3.75,7.78-10.47,4.31-16.42s-10.73-7.46-16.42-4.31c-56.62,31.42-108.87,69.78-160.93,108.1l14.54,18.85c43.71-42.47,92.49-79.07,141.25-115.45,5.19-3.87,7.84-10.38,4.31-16.42-3.19-5.46-10.92-7.78-16.42-4.31-64.73,40.86-128.17,84.51-184.18,136.96-11.3,10.58,5.7,27.53,16.97,16.97h0Z" />
        <path class="b"
          d="M326.28,94.98c-44.43,23.14-85.31,53.01-120.98,88.17-9.52,9.38,2.91,26.42,14.54,18.85,42.03-27.38,86.04-51.61,131.64-72.49l-12.11-20.72c-45.19,29.08-91.09,58.83-127,99.36-9.13,10.31,2.2,24.98,14.54,18.85,44.16-21.94,88.31-43.88,132.47-65.82,5.8-2.88,7.42-11.1,4.31-16.42-3.46-5.91-10.59-7.2-16.42-4.31-44.16,21.94-88.31,43.88-132.47,65.82l14.54,18.85c34.64-39.1,78.56-67.57,122.15-95.61,5.44-3.5,7.68-10.65,4.31-16.42-3.54-6.05-10.5-7.01-16.42-4.31-45.61,20.88-89.61,45.11-131.64,72.49l14.54,18.85c34.31-33.82,73.39-62.17,116.12-84.42,5.75-2.99,7.46-11.02,4.31-16.42-3.41-5.83-10.65-7.31-16.42-4.31h0Z" />
        <g>
          <path class="f"
            d="M154.6,175.09l9.57-67.1h13.5l10.18,67.1h-9.53l-1.6-11.97h-10.83l-1.45,11.97h-9.84Zm21.05-20.17l-4.5-33.7h-.15l-4.12,33.7h8.77Z" />
          <path class="f"
            d="M223.57,125.22c0,7.22-1.96,11.65-5.87,13.31,4.78,1.6,7.17,6.88,7.17,15.82v1.71c-.05,6.74-1.23,11.59-3.53,14.56-2.3,2.97-6.06,4.46-11.27,4.46h-14.83V107.99h14.53c5.01,0,8.55,1.16,10.64,3.49,2.08,2.33,3.14,6.22,3.16,11.69v2.06Zm-18.11,9.91h3.77c1.75,0,2.9-.56,3.45-1.68,.55-1.12,.82-3.42,.82-6.9v-4.04c-.03-2.59-.38-4.33-1.07-5.2-.69-.88-1.87-1.32-3.55-1.32h-3.43v19.14Zm0,32.03h3.62c2.03,0,3.46-.7,4.27-2.1,.81-1.4,1.22-3.9,1.22-7.51v-5.41c0-3.41-.33-5.81-.99-7.23-.66-1.41-1.93-2.12-3.81-2.12h-4.31v24.36Z" />
          <path class="f"
            d="M257.69,171.18c-2.31,3.11-5.95,4.67-10.9,4.67s-8.66-1.66-11.11-4.98c-2.45-3.32-3.68-8.09-3.68-14.32v-29.24c0-9.99,2.62-16.23,7.85-18.72,1.93-.91,4.27-1.37,7.01-1.37,9.43,0,14.15,5.95,14.15,17.84v9.23h-9.88v-8.58c0-3.46-.29-5.93-.88-7.42-.58-1.49-1.73-2.23-3.43-2.23s-2.88,.79-3.54,2.36c-.66,1.58-.99,3.99-.99,7.24v31.34c0,3.61,.32,6.19,.95,7.74,.63,1.55,1.79,2.33,3.47,2.33s2.83-.79,3.47-2.36c.63-1.58,.95-4.18,.95-7.82v-9.61h10.03v9.49c0,6.48-1.16,11.28-3.47,14.39Z" />
        </g>
        <path class="d"
          d="M124.52,86.27c-26.96,31.05-52.71,63.13-77.31,96.09-3.53,4.73-1.24,12.25,3.09,15.48,5.13,3.83,10.95,2.91,15.76-.94,47.16-37.75,95.3-74.93,149.67-101.83l-12.11-20.72c-51.93,38.17-101.4,79.61-147.91,124.24-4.68,4.49-4.52,12.45,0,16.97,4.95,4.95,11.95,4.13,16.97,0,49.83-41,99.97-82.01,155.95-114.48l-14.54-18.85c-34.24,30.08-68.84,59.76-103.79,89.02l16.97,16.97c37.25-32.66,76.9-62.37,118.66-89.01l-16.42-16.42c-6.92,11.64-19.37,20.18-29.39,29.01-4.86,4.29-4.38,12.59,0,16.97,4.83,4.83,12.09,4.3,16.97,0,11.75-10.36,25.02-20.2,33.14-33.87,6-10.09-6.17-22.96-16.42-16.42-43.45,27.72-84.76,58.79-123.52,92.77-4.88,4.27-4.37,12.6,0,16.97,4.92,4.92,11.99,4.17,16.97,0,34.95-29.26,69.55-58.94,103.79-89.02,10.39-9.13-2.95-25.57-14.54-18.85-57.71,33.47-109.42,75.96-160.8,118.23l16.97,16.97c45.03-43.2,92.77-83.53,143.05-120.48,5.21-3.83,7.82-10.41,4.31-16.42-3.46-5.92-10.59-7.19-16.42-4.31-56.19,27.8-105.8,66.58-154.53,105.58l18.85,14.54c23.37-31.32,47.94-61.72,73.56-91.23,4.25-4.89,4.81-12.16,0-16.97-4.34-4.34-12.7-4.91-16.97,0h0Z" />
        <g>
          <path class="c"
            d="M353.4,210.79c-1.02-1.95-2.34-3.93-3.12-5.97-1.33-3.47-1.47-7.5-2.15-11.13-1.44-7.77-4.03-15.27-7.7-22.28l-5.49-10.49c-.7-1.33-1.88-2.32-3.32-2.77-1.44-.45-2.96-.32-4.3,.38-2.76,1.44-3.83,4.86-2.38,7.62,.08,.15,.02,.33-.13,.41-.15,.08-.33,.02-.41-.13l-2.17-4.14c-.7-1.34-1.88-2.32-3.32-2.77-1.44-.45-2.97-.32-4.31,.38-2.76,1.44-3.83,4.86-2.38,7.62,.08,.15,.02,.33-.13,.41-.15,.08-.33,.02-.41-.13l-2.56-4.9c-.7-1.33-1.88-2.32-3.32-2.77-1.44-.45-2.97-.32-4.3,.38-2.76,1.44-3.83,4.86-2.39,7.62,.08,.15,.02,.33-.13,.41-.15,.08-.33,.02-.41-.13l-8.47-16.2c-.7-1.33-1.87-2.32-3.31-2.77-1.45-.45-2.98-.31-4.31,.38-2.76,1.44-3.83,4.86-2.39,7.62l19.26,36.83c.06,.12,.04,.26-.05,.35-.02,.02-.05,.04-.08,.06-.08,.04-.19,.05-.27,0l-10.07-4.95c-1.44-.45-2.96-.31-4.3,.39-1.34,.7-2.32,1.88-2.77,3.31-1.23,3.92,.14,4.86,10.75,12.14,2.52,1.73,5.66,3.89,9.38,6.51,4.25,3,8.82,5.57,13.59,7.64,1.8,.78,3.03,1.24,3.98,3.02,.79,1.49,1.63,2.97,2.41,4.47,.71,1.35,2.39,1.88,3.74,1.17l26.57-13.89c1.35-.71,1.88-2.39,1.17-3.74Z" />
          <path class="f" d="M294.11,168.27l7,14c2.59,5.18,10.36,.63,7.77-4.54l-7-14c-2.59-5.18-10.36-.63-7.77,4.54h0Z" />
          <path class="f" d="M306.11,166.27l7,14c2.59,5.18,10.36,.63,7.77-4.54l-7-14c-2.59-5.18-10.36-.63-7.77,4.54h0Z" />
          <path class="f" d="M319.11,165.27l7,14c2.59,5.18,10.36,.63,7.77-4.54l-7-14c-2.59-5.18-10.36-.63-7.77,4.54h0Z" />
        </g>
      </svg>
      <div class="block-label">${model.get('label')}</div>
      </div>
    `
    });
    editor.on('block:drag:stop', component => {
      if (component && component.is(pluginName)) {
        editor.select(component);
        if (onDrop) {
          const el = component.getEl();
          onDrop(el);
        }
      }
    });
  };
  _exports.default = _default;
});