define("client/pods/grapesjs/plugins/banner", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @project: bigordr-app
   * @file: banner
   * @author Nafis Rubio
   * @since 18/09/2020
   * @copyright (C) 2020 BigOrdr
   * @description: $DESCRIPTION$
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'bigordr-banner';
    const pluginBlockId = `${pluginName}-block`;
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const margin0 = 'margin-0';
    const {
      intl,
      /** @type {Categories} */
      categories
    } = options;
    const blockLabel = intl.t(pluginName);
    const componentName = intl.t(pluginName);
    DomComponents.addType(pluginName, {
      extend: 'bigordr-strip-image',
      model: {
        defaults: {
          removable: true,
          copyable: true,
          resizable: true,
          draggable: true,
          style: {
            'background-attachment': 'scroll',
            'background-image': 'url(https://picsum.photos/512.webp?grayscale&blur=1)',
            'background-position': 'center center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'object-fit': 'cover',
            height: 'auto',
            width: '100%'
          },
          attributes: {
            'data-class': 'bigordr-banner'
          },
          name: componentName,
          components: [{
            selectable: false,
            type: 'row',
            style: {
              padding: '0px 0px 0px 0px'
            },
            components: [{
              type: 'cell',
              removable: true,
              draggable: false
            }, {
              type: 'cell',
              removable: true,
              draggable: false,
              components: [{
                tagName: 'section',
                name: intl.t('text-block'),
                style: {
                  margin: '10% 0 10% 0',
                  padding: '10px',
                  'text-align': 'center'
                },
                components: [{
                  type: 'text',
                  style: {
                    padding: '10px'
                  },
                  classes: ['bigordr-title', margin0],
                  tagName: 'h1',
                  name: intl.t('title'),
                  content: 'Insert title here',
                  traits: [{
                    type: 'bigordr-animation-text-trait',
                    name: 'animationSelector',
                    label: intl.t('select animation')
                  }, {
                    type: 'bigordr-animation-iteration-trait',
                    name: 'animationIterationSelector',
                    label: intl.t('select iteration count')
                  }, {
                    type: 'bigordr-animation-duration-trait',
                    name: 'animationDuration',
                    label: intl.t('enter duration (sec)')
                  }]
                }, {
                  type: 'text',
                  style: {
                    padding: '10px'
                  },
                  tagName: 'h2',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lobortis ullamcorper lorem. Donec tempor lectus sapien, eu consequat dolor vehicula eu. Vestibulum non felis blandit, condimentum mi ac, pulvinar dolor.',
                  traits: [{
                    type: 'bigordr-animation-trait',
                    name: 'animationSelector',
                    label: intl.t('select animation')
                  }, {
                    type: 'bigordr-animation-iteration-trait',
                    name: 'animationIterationSelector',
                    label: intl.t('select iteration count')
                  }, {
                    type: 'bigordr-animation-duration-trait',
                    name: 'animationDuration',
                    label: intl.t('enter duration (sec)')
                  }],
                  classes: [margin0]
                }]
              }]
            }, {
              type: 'cell',
              removable: true,
              draggable: false
            }]
          }],
          traits: []
        }
      }
    });
    BlockManager.add(pluginBlockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: pluginName
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
    <svg width="100%" height="100%" viewBox="0 0 1024 760" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
    <g transform="matrix(1.08549,0,0,1.08549,-50.9445,-285.797)">
        <g>
            <g transform="matrix(1.37733,0,0,1.65228,-180.565,-864.206)">
                <path d="M207.809,832.911L800.909,832.911" style="fill:none;stroke:black;stroke-width:27.25px;"/>
            </g>
            <g transform="matrix(1,0,0,1,118.44,399.43)">
                <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:398.39px;stroke-linecap:square;"/>
                </g>
                <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:398.39px;stroke-linecap:square;"/>
                </g>
            </g>
            <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,918.773,624.57)">
                <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:398.39px;stroke-linecap:square;"/>
                </g>
                <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:398.39px;stroke-linecap:square;"/>
                </g>
            </g>
        </g>
        <g transform="matrix(1,0,0,1,5.14536,37.2616)">
            <g>
                <g transform="matrix(1.00794,0,0,0.792537,12.4421,44.5258)">
                    <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:35.56px;stroke-linecap:square;stroke-linejoin:miter;"/>
                </g>
                <g transform="matrix(1.08069,0,0,1.08069,-48.6111,-30.4861)">
                    <g transform="matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)">
                        <circle cx="567.803" cy="388.754" r="27.858" style="fill:rgb(209,209,209);stroke:rgb(209,209,209);stroke-width:16.81px;"/>
                    </g>
                    <g transform="matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)">
                        <g transform="matrix(-0.93623,0,0,0.939727,1014.78,130.716)">
                            <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z" style="fill:rgb(209,209,209);"/>
                        </g>
                        <g transform="matrix(-1.01027,0,0,0.756475,916.709,228.489)">
                            <path d="M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z" style="fill:rgb(209,209,209);"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g transform="matrix(0.644138,0,0,0.60847,234.912,-33.029)">
        <text x="260.627px" y="673.073px" style="font-family:'Helvetica-Bold', 'Helvetica';font-weight:700;font-size:551.145px;">T</text>
    </g>
    <g transform="matrix(1.23491,0,0,1.23491,-148.637,-397.634)">
        <text x="299.313px" y="922.5px" style="font-family:'AppleSDGothicNeo-Bold', 'Apple SD Gothic Neo';font-weight:700;font-size:184.248px;">背景圖</text>
    </g>
    </svg>
    <div class="block-label">${model.get('label')}</div>
    </div>`
    });
  };
  _exports.default = _default;
});