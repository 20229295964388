define("client/pods/auth/service", ["exports", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Service.extend((_dec = Ember._action, _obj = {
    server: Ember.inject.service(),
    storage: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    socket: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grapesjs: Ember.inject.service(),
    language: 'zh-tw',
    twoFactorAuthToken: null,
    selectedPod: null,
    init() {
      this._super(...arguments);
      this.set('twoFactorAuthToken', null);
      Ember.set(this, 'selectedPod', null);
    },
    selectCompanyToLoginTask: (0, _emberConcurrency.task)(function* ({
      confirm = false,
      onChange
    } = {}) {
      const podKey = this.selectedPod;
      if (!podKey) {
        this.logout();
        return;
      }
      const pods = this.getUserProp('pods') || [];
      const defaultPod = this.getUserProp('pod') || [];
      let confirmed = true;
      if (confirm) {
        const podData = R.find(R.propEq('podKey', podKey))(pods);
        const msg = this.intl.t('log into new company?', {
          company: podData.company
        });
        confirmed = window.confirm(msg);
      }
      if (!confirmed) {
        podKey = defaultPod;
      }
      if (confirmed) {
        let loginData;
        const tokenData = this.getTokenData();
        if (tokenData.pod === podKey) {
          // same podKey, dont need to re-login
          // no need to re-login with server
          const token = this.getToken();
          loginData = yield this.loginToCompanyTask.perform(podKey, {
            token,
            tokenData
          });
        } else {
          loginData = yield this.loginToCompanyTask.perform(podKey);
        }
        if (onChange) {
          // clear local storage
          this.dataManager.resetCache();
          this.tabsManager.resetTabs();
          // reload pod settings
          yield this.settings.setupTask.perform();
          onChange(loginData);
          this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company, {
            queryParams: {
              reload: true
            }
          });
        } else {
          // return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company)
          return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company, {
            queryParams: {
              reload: true
            }
          });
        }
      }
    }).drop(),
    setLanguage(language) {
      if (language) {
        this.set('language', language);
        if (this.get('isLoggedIn')) {
          this.storage.setWithoutPrefix('language', language);
        }
        return this.intl.setLocale(language);
      }
      return false;
    },
    // setPreferredLanguage (language) {
    //   this.get('storage').set('preferredLanguage', language)
    // },

    resetLogin() {
      this.set('step', 0);
      this.set('loginFailed', false);
      this.set('twoFactorAuthToken', null);
    },
    loginTask: (0, _emberConcurrency.task)(function* (credentials) {
      this.set('loginFailed', false);
      this.set('twoFactorAuthToken', null);

      // Remove localstorage on longin
      try {
        const res = yield this.server.login(credentials);
        const token = R.prop('token')(res);
        const tokenData = this.parseToken(token);
        if (tokenData.isTwoFactorAuthToken) {
          this.set('twoFactorAuthToken', token);
          return {
            isTwoFactorAuth: true
          };
        }
        return yield this._completeLoginTask.perform(res);
      } catch (err) {
        let reload = false;
        if (err.data === 'not connected') {
          reload = true;
        }
        // invalid credentials
        this.setNotAuthenticated('invalid username or password', reload);
      }
    }).drop(),
    loginTwoFactorTask: (0, _emberConcurrency.task)(function* (code) {
      this.set('loginFailed', false);
      // Remove localstorage on longin
      try {
        const twoFactorAuthToken = this.twoFactorAuthToken;
        const res = yield this.server.loginWithTwoFactorAuth(code, twoFactorAuthToken);
        return yield this._completeLoginTask.perform(res);
      } catch (err) {
        let reload = false;
        if (err.data === 'not connected') {
          reload = true;
        }
        // invalid credentials
        this.setNotAuthenticated('invalid two factor authentication code', reload);
      }
    }).drop(),
    resendTwoFactorCodeTask: (0, _emberConcurrency.task)(function* () {
      this.set('loginFailed', false);
      // Remove localstorage on longin
      try {
        const twoFactorAuthToken = this.twoFactorAuthToken;
        yield this.server.loginWithTwoFactorAuth('', twoFactorAuthToken, 'resend');
        return true;
      } catch (err) {
        let reload = false;
        if (err.data === 'not connected') {
          reload = true;
        }
        // invalid credentials
        this.setNotAuthenticated('invalid two factor authentication code', reload);
        return false;
      }
    }).drop(),
    _completeLoginTask: (0, _emberConcurrency.task)(function* (res) {
      const token = R.prop('token')(res);
      const tokenData = this.parseToken(token);
      const userId = tokenData.uid;
      const username = tokenData.username;
      const hasMultiplePods = R.pipe(R.propOr([], 'pods'), R.length, R.gt(R.__, 1))(tokenData);
      const company = tokenData.company;
      this.setAsLoggedIn(company, token, tokenData);
      if (hasMultiplePods) {
        return this.router.transitionTo('protected.companies', username, userId);
      }
      const podKey = tokenData.pod;
      const loginData = yield this.loginToCompanyTask.perform(podKey, {
        token,
        tokenData
      });
      return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company);
    }).drop(),
    loginToCompanyTask: (0, _emberConcurrency.task)(function* (podKey, {
      token,
      tokenData = {}
    } = {}) {
      try {
        if (RA.isNilOrEmpty(tokenData)) {
          const userToken = this.getToken();
          const res = yield this.server.loginToCompany(podKey, userToken);
          token = R.prop('token')(res);
          tokenData = this.parseToken(token);
        }
        if (RA.isNilOrEmpty(token) || !token) {
          return this.logout();
        }
        const company = tokenData.company;
        this.setAsLoggedIn(company, token, tokenData);
        return {
          company,
          username: tokenData.username,
          userId: tokenData.uid,
          language: this.language
        };
      } catch (err) {
        return this.logout();
      }
    }),
    setAsLoggedIn(company, token, tokenData) {
      this.storage.setup(company, tokenData.uid);
      // set is loggedIn = true

      this.storage.set('uid', tokenData.uid);
      this.storage.set('token', token);
      this.storage.set('tokenData', tokenData);
      const language = this.language || tokenData.language;
      this.storage.setWithoutPrefix('language', language);
      this._setIsLoggedIn(true);
    },
    setAutoExtendedToken(token) {
      const tokenData = this.parseToken(token);
      this.storage.set('token', token);
      this.storage.set('tokenData', tokenData);
    },
    getPods() {
      const tokenData = this.storage.get('tokenData') || {};
      return R.propOr([], 'pods')(tokenData);
    },
    getToken() {
      return this.storage.get('token'); // || this.token
    },
    getTokenData() {
      return this.storage.get('tokenData') || {};
    },
    parseToken(token) {
      if (token) {
        try {
          return jwt_decode(token);
        } catch (err) {
          this.setNotAuthenticated('connection error');
          return false;
        }
      }
    },
    isAlreadyAuthenticated(companyName, userId, params) {
      if (companyName && userId) {
        this.storage.setup(companyName, userId);
        const language = params.language || this.language;
        this.set('language', language);
        const isLoggedIn = this.storage.get('isLoggedIn') || false;
        return this._setIsLoggedIn(isLoggedIn);
      }
      return this.logout();
    },
    _setIsLoggedIn(isLoggedIn) {
      if (isLoggedIn) {
        const language = this.storage.getWithoutPrefix('language');
        this.setLanguage(language);
        this.set('isLoggedIn', true);
        this.storage.set('isLoggedIn', true);
        this.socket.connect();
        return true;
      }
      return this.logout();
    },
    /**
     * set loginFailed
     */
    setNotAuthenticated(errorMsg, reload = false) {
      // remove token from session
      this.logout(reload);
      this.set('loginFailed', true);
      if (errorMsg) {
        this.set('loginErrorMsg', errorMsg);
      }
    },
    logout(reload = false) {
      const storageService = this.storage;
      const activeEditors = storageService.get('activeEditors') || [];
      R.forEach(this.grapesjs.removeFromStorage, activeEditors);
      storageService.set('activeEditors', []);
      this.storage.clear();
      this.set('loginFailed', false);
      this.set('isLoggedIn', false);

      // setting token
      this.dataManager.resetCache();
      this.tabsManager.resetTabs();

      // window.localStorage.clear()

      this.socket.disconnect();
      if (reload) {
        return this.router.transitionTo('index', {
          queryParams: {
            reload: true
          }
        });
      }
      return this.router.transitionTo('index');
    },
    getUserProp(prop) {
      const userData = this.storage.get('tokenData');
      if (userData) {
        return userData[prop];
      }
      return '';
    },
    actions: {
      logout() {
        this.logout();
      }
    }
  }, _applyDecoratedDescriptor(_obj, "logout", [_dec], Object.getOwnPropertyDescriptor(_obj, "logout"), _obj), _obj));
});