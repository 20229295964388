define("client/pods/grapesjs/plugins/video", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Common video wrapper types
  const bigordrVideo = 'bigordr-video';
  const bigordrVideoWrapper = 'bigordr-video-wrapper';
  const bigordrVideoFullWidth = 'bigordr-video-full-width';
  const bigordrVideoAlignment = 'bigordr-video-alignment';
  const bigordrVideoAlignmentWrapper = 'bigordr-video-alignment-wrapper';
  const bigordrVideoAlignmentWrapperEmpty = 'bigordr-video-alignment-wrapper-empty';
  const bigordrVideoOuterWrapper = 'bigordr-video-outer-wrapper';
  const bigordrVideoInnerWrapper = 'bigordr-video-inner-wrapper';
  const bigordrVideoInnerWrapperEmpty = 'bigordr-video-inner-wrapper-empty';
  const bigordrVideoOuterWrapperEmpty = 'bigordr-video-outer-wrapper-empty';
  const bigordrVideoFullWidthOuterWrapper = 'bigordr-video-full-width-outer-wrapper';
  const bigordrVideoFullWidthInnerWrapper = 'bigordr-video-full-width-inner-wrapper';
  const bigordrVideoFullWidthOuterWrapperEmpty = 'bigordr-video-full-width-outer-wrapper-empty';
  const bigordrVideoFullWidthInnerWrapperEmpty = 'bigordr-video-full-width-inner-wrapper-empty';
  const styles = `
    <style>
      .${bigordrVideoInnerWrapper} {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
      }

      .${bigordrVideoInnerWrapper} > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .${bigordrVideoInnerWrapper} > video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .${bigordrVideoInnerWrapper} > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    </style>
  `;
  const addVideoAlignment = (component, property, value) => {
    // Flex classes
    const flexContainerJustifyStart = 'flex-container--justify-start';
    const flexContainerJustifyCenter = 'flex-container--justify-center';
    const flexContainerJustifyEnd = 'flex-container--justify-end';
    if (property === 'text-align' && value) {
      component.getEl().classList.remove(flexContainerJustifyStart, flexContainerJustifyCenter, flexContainerJustifyEnd);
      component.removeClass(flexContainerJustifyStart);
      component.removeClass(flexContainerJustifyCenter);
      component.removeClass(flexContainerJustifyEnd);
      switch (value) {
        case 'right':
          component.addClass(flexContainerJustifyEnd);
          break;
        case 'center':
          component.addClass(flexContainerJustifyCenter);
          break;
        case 'left':
        default:
          component.addClass(flexContainerJustifyStart);
          break;
      }
    }
  };
  const setupVideoWrappers = ({
    DomComponents
  }) => {
    DomComponents.addType(bigordrVideoWrapper, {
      model: {
        defaults: {
          tagName: 'div',
          badgable: false,
          copyable: false,
          droppable: false,
          editable: false,
          highlightable: false,
          hoverable: true,
          layerable: true,
          removable: false,
          resizable: false,
          selectable: true,
          stylable: false
        }
      }
    });
    DomComponents.addType(bigordrVideoAlignment, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          classes: [bigordrVideoAlignmentWrapper, 'flex-container-row', 'flex-container--justify-center']
        },
        updated(property, value) {
          addVideoAlignment(this, property, value);
        }
      }
    });
    DomComponents.addType(bigordrVideoOuterWrapperEmpty, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          classes: [bigordrVideoOuterWrapper],
          draggable: false,
          style: {
            'max-width': '640px',
            width: '100%'
          },
          components: [{
            type: bigordrVideoInnerWrapperEmpty
          }]
        }
      }
    });
    DomComponents.addType(bigordrVideoInnerWrapperEmpty, {
      extend: bigordrVideoWrapper,
      draggable: false,
      layerable: false,
      model: {
        defaults: {
          classes: [bigordrVideoInnerWrapper]
        }
      }
    });
    DomComponents.addType(bigordrVideoAlignmentWrapperEmpty, {
      extend: bigordrVideoAlignment,
      model: {
        defaults: {
          components: [{
            type: bigordrVideoOuterWrapperEmpty
          }]
        }
      }
    });
  };
  const setupVideoPlugin = editor => {
    const options = R.pathOr({}, ['Config', 'pluginsOpts', bigordrVideo])(editor);
    const {
      intl,
      /** @type {Categories} */
      categories
    } = options;
    const bigordrVideoBlockName = `${bigordrVideo}-block`;
    const bigordrVideoBlockNameLabel = intl.t('video');
    const bigordrVideoDraggable = '[data-gjs-type=cell], [data-gjs-type="bigordr-video-inner-wrapper"]';
    const {
      DomComponents,
      BlockManager
    } = editor;
    const Component = DomComponents.Component;

    // Video Not Full width
    DomComponents.addType(bigordrVideo, {
      extend: 'video',
      model: {
        defaults: {
          resizable: {
            tc: 0,
            cl: 0,
            cr: 0,
            bc: 0,
            ratioDefault: 1
          },
          draggable: bigordrVideoDraggable,
          layerable: false,
          src: 'https://storage.coverr.co/videos/6az802eRbt56ZV5xcVS6016gycP9aEODfG?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjExNDMyN0NEOTRCMUFCMTFERTE3IiwiaWF0IjoxNTg4NTc2NDA3fQ.6EKzfoX_Z6hpvAHStTg1_DE_aYo7zE-F3US_VO9JgeY',
          toolbar: [{
            attributes: {
              class: 'fa fa-arrow-up'
            },
            command: 'select-parent'
          }, {
            attributes: {
              class: 'fa fa-arrows'
            },
            command: 'tlb-move'
          }, {
            attributes: {
              class: 'fa fa-clone'
            },
            command: 'tlb-clone'
          }, {
            attributes: {
              class: 'fa fa-trash'
            },
            command(gjsEditor) {
              const videoModel = gjsEditor.getSelected();
              const outerWrapper = videoModel.closest(`div.${bigordrVideoAlignmentWrapper}`) || videoModel.closest(`div.${bigordrVideoOuterWrapper}`);
              if (outerWrapper) {
                outerWrapper.remove();
                gjsEditor.selectRemove(videoModel);
              }
            }
          }]
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         */
        initialize(o, opt) {
          this.em = opt.em;
          if (this.get('src')) this.parseFromSrc();
          this.updateTraits();
          this.listenTo(this, 'change:provider', this.updateTraits);
          this.listenTo(this, 'change:videoId change:provider', this.updateSrc);

          // the original code uses ComponentImage as Component,
          // so also extends the initialize function of that
          Component.prototype.initialize.apply(this, arguments);
          const {
            src
          } = this.get('attributes');
          if (src) {
            this.set('src', src, {
              silent: 1
            });
          }
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Set attributes by src string
         */
        parseFromSrc() {
          var prov = this.get('provider');
          var uri = this.parseUri(this.get('src'));
          var qr = uri.query;
          switch (prov) {
            case 'yt':
            case 'ytnc':
            case 'vi':
              var videoId = uri.pathname.split('/').pop();
              this.set('videoId', videoId);
              qr.list && this.set('list', qr.list);
              if (qr.autoplay) this.set('autoplay', 1);
              if (qr.loop) this.set('loop', 1);
              if (parseInt(qr.controls) === 0) this.set('controls', 0);
              if (qr.color) this.set('color', qr.color);
              if (qr.rel === '0') this.set('rel', 0);
              if (qr.modestbranding === '1') this.set('modestbranding', 1);
              break;
            default:
          }
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Update src on change of video ID
         * @private
         */
        updateSrc() {
          const prov = this.get('provider');
          let src = '';
          switch (prov) {
            case 'yt':
              src = this.getYoutubeSrc();
              break;
            case 'ytnc':
              src = this.getYoutubeNoCookieSrc();
              break;
            case 'vi':
              src = this.getVimeoSrc();
              break;
          }
          this.set({
            src
          });
        },
        init() {
          this.listenTo(this, 'change:status', this.handlePropChange);
          this.listenTo(this, 'change:ratio', this.handleRatioChange);
          this.listenTo(this, 'change:align', this.handlePositionChange);
        },
        updated(property /* value, prevValue, model, ...rest */) {
          if (property === 'provider' || property === 'videoId') {
            const parent = this.parent().parent();
            if (parent) {
              const isVideoOuterWrapper = parent.getClasses().includes(bigordrVideoOuterWrapper);
              const style = parent.getStyle();
              if (isVideoOuterWrapper && R.isEmpty(style)) {
                parent.setStyle({
                  'max-width': '640px',
                  width: '100%',
                  height: 'auto'
                });
              }
            }
          }
        },
        handlePropChange(model, status) {
          if (this.previousAttributes().status === 'selected' && status === '') {
            const style = this.getStyle();
            const hasStyle = R.pipe(R.isEmpty, R.not)(style);
            if (hasStyle && style && (style.width || style.height)) {
              const parent = this.closest(`div.${bigordrVideoOuterWrapper}`);
              if (parent) {
                const newStyle = R.mergeRight(style, {
                  'max-width': style.width || '640px',
                  width: '100%'
                });
                this.set('currentStyle', newStyle);
                parent.setStyle(newStyle);
                this.setStyle();
              }
            }
          }
        },
        handleRatioChange(model, ratio) {
          const target = model.closest(`div.${bigordrVideoInnerWrapper}`);
          const id = target.getId();
          if (id) {
            const value = ratio || '56.25%';
            const style = `
            <style>
              #${id} {
                padding-bottom: ${value};
              }
            </style>
          `;
            target.components().add(style);
          }
        },
        handlePositionChange(model, position) {
          const target = model.closest(`div.${bigordrVideoAlignmentWrapper}`);
          const value = position || 'left';
          addVideoAlignment(target, 'text-align', value);
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Update traits by provider
         * @private
         */
        updateTraits() {
          const prov = this.get('provider');
          let tagName = 'iframe';
          let traits;
          switch (prov) {
            case 'yt':
            case 'ytnc':
              traits = this.getYoutubeTraits();
              break;
            case 'vi':
              traits = this.getVimeoTraits();
              break;
            default:
              tagName = 'video';
              traits = this.getSourceTraits();
          }
          this.set({
            tagName
          }, {
            silent: 1
          }); // avoid break in view
          this.set({
            traits
          });
          this.em.trigger('component:toggled');
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return traits for the source provider
         * @return {Array<Object>}
         * @private
         */
        getYoutubeTraits() {
          return [this.getProviderTrait(), {
            label: 'Video ID',
            name: 'videoId',
            placeholder: 'eg. jNQXAC9IVRw',
            changeProp: 1
          }, this.getAutoplayTrait(), this.getLoopTrait(), this.getControlsTrait(), {
            type: 'checkbox',
            label: 'Related',
            name: 'rel',
            changeProp: 1
          }, {
            type: 'checkbox',
            label: 'Modest',
            name: 'modestbranding',
            changeProp: 1
          }, this.getRatioTrait(), this.getPositionTrait()];
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return traits for the source provider
         * @return {Array<Object>}
         * @private
         */
        getVimeoTraits() {
          return [this.getProviderTrait(), {
            label: 'Video ID',
            name: 'videoId',
            placeholder: 'eg. 123456789',
            changeProp: 1
          }, {
            label: 'Color',
            name: 'color',
            placeholder: 'eg. FF0000',
            changeProp: 1
          }, this.getAutoplayTrait(), this.getLoopTrait(), this.getRatioTrait(), this.getPositionTrait()];
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return traits for the source provider
         * @return {Array<Object>}
         * @private
         */
        getSourceTraits() {
          return [this.getProviderTrait(), {
            label: 'Source',
            name: 'src',
            placeholder: 'eg. ./media/video.mp4',
            changeProp: 1
          }, {
            label: 'Poster',
            name: 'poster',
            placeholder: 'eg. ./media/image.jpg'
            // changeProp: 1
          }, this.getAutoplayTrait(), this.getLoopTrait(), this.getControlsTrait(), this.getRatioTrait(), this.getPositionTrait()];
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return the provider trait
         * @return {Object}
         * @private
         */
        getProviderTrait() {
          return {
            type: 'select',
            label: 'Provider',
            name: 'provider',
            changeProp: 1,
            options: [{
              value: 'so',
              name: 'HTML5 Source'
            }, {
              value: 'yt',
              name: 'Youtube'
            }, {
              value: 'ytnc',
              name: 'Youtube (no cookie)'
            }, {
              value: 'vi',
              name: 'Vimeo'
            }]
          };
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return object trait
         * @return {Object}
         * @private
         */
        getAutoplayTrait() {
          return {
            type: 'checkbox',
            label: 'Autoplay',
            name: 'autoplay',
            changeProp: 1
          };
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return object trait
         * @return {Object}
         * @private
         */
        getLoopTrait() {
          return {
            type: 'checkbox',
            label: 'Loop',
            name: 'loop',
            changeProp: 1
          };
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Return object trait
         * @return {Object}
         * @private
         */
        getControlsTrait() {
          return {
            type: 'checkbox',
            label: 'Controls',
            name: 'controls',
            changeProp: 1
          };
        },
        getRatioTrait() {
          return {
            type: 'select',
            label: intl.t('gjs_video ratio'),
            name: 'ratio',
            changeProp: 1,
            default: '56.25%',
            options: [{
              name: intl.t('gjs_portrait'),
              value: '177.78%'
            }, {
              name: intl.t('gjs_landscape'),
              value: '56.25%'
            }]
          };
        },
        getPositionTrait() {
          return {
            type: 'select',
            label: intl.t('gjs-Position'),
            name: 'align',
            changeProp: 1,
            default: 'center',
            options: [{
              name: intl.t('gjs-Left'),
              value: 'left'
            }, {
              name: intl.t('gjs-Center'),
              value: 'center'
            }, {
              name: intl.t('gjs-Right'),
              value: 'right'
            }]
          };
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Returns url to youtube video
         * @return {string}
         * @private
         */
        getYoutubeSrc() {
          const id = this.get('videoId');
          let url = this.get('ytUrl');
          const list = this.get('list');
          url += id + (id.indexOf('?') < 0 ? '?' : '');
          url += list ? `&list=${list}` : '';
          url += this.get('autoplay') ? '&autoplay=1&mute=1' : '';
          url += !this.get('controls') ? '&controls=0&showinfo=0' : '';
          // Loop works only with playlist enabled
          // https://stackoverflow.com/questions/25779966/youtube-iframe-loop-doesnt-work
          url += this.get('loop') ? `&loop=1&playlist=${id}` : '';
          url += this.get('rel') ? '' : '&rel=0';
          url += this.get('modestbranding') ? '&modestbranding=1' : '';
          return url;
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Returns url to youtube no cookie video
         * @return {string}
         * @private
         */
        getYoutubeNoCookieSrc() {
          let url = this.getYoutubeSrc();
          url = url.replace(this.get('ytUrl'), this.get('ytncUrl'));
          return url;
        },
        /**
         * From grapesjs, see: https://github.com/artf/grapesjs/blob/v0.18.4/src/dom_components/model/ComponentVideo.js
         * Returns url to vimeo video
         * @return {string}
         * @private
         */
        getVimeoSrc() {
          var url = this.get('viUrl');
          url += this.get('videoId') + '?';
          url += this.get('autoplay') ? '&autoplay=1&muted=1' : '';
          url += this.get('loop') ? '&loop=1' : '';
          url += !this.get('controls') ? '&title=0&portrait=0&badge=0' : '';
          url += this.get('color') ? '&color=' + this.get('color') : '';
          return url;
        }
      }
    });
    DomComponents.addType(bigordrVideoAlignmentWrapper, {
      extend: bigordrVideoAlignment,
      model: {
        defaults: {
          name: intl.t('video'),
          components: [{
            type: bigordrVideoOuterWrapper
          }]
        }
      }
    });
    DomComponents.addType(bigordrVideoOuterWrapper, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          classes: [bigordrVideoOuterWrapper],
          draggable: false,
          layerable: false,
          style: {
            'max-width': '640px',
            width: '100%'
          },
          components: [{
            type: bigordrVideoInnerWrapper
          }]
        }
      }
    });
    DomComponents.addType(bigordrVideoInnerWrapper, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          classes: [bigordrVideoInnerWrapper],
          draggable: false,
          layerable: false,
          components: [{
            type: bigordrVideo,
            draggable: false,
            layerable: false
          }]
        }
      }
    });
    BlockManager.add(bigordrVideoBlockName, {
      label: bigordrVideoBlockNameLabel,
      category: categories.basic,
      content: {
        draggable: bigordrVideoDraggable,
        type: bigordrVideoAlignmentWrapper,
        content: styles
      },
      render: ({
        model
      }) => `
    <div class="gjs-block__media">
      <svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;">
        <g transform="matrix(0.93504,0,0,1,11.876,4.1044)">
            <g>
                <g transform="matrix(1.07796,0,0,0.792537,-0.957412,77.683)">
                    <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;"/>
                </g>
                <g transform="matrix(1,0,0,1,-5.38389,172.379)">
                    <g transform="matrix(0.512261,0,0,0.512261,245.802,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,-120.793,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,1.40527,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,123.603,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,368,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                </g>
                <g transform="matrix(1,0,0,1,-5.38389,501.655)">
                    <g transform="matrix(0.512261,0,0,0.512261,245.802,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,-120.793,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,1.40527,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,123.603,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,368,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                </g>
                <g transform="matrix(3.66112e-17,0.597907,-0.761557,4.66319e-17,922.089,134.831)">
                    <path d="M623.951,431.185L751.048,585.73L496.854,585.73L623.951,431.185Z"/>
                </g>
            </g>
        </g>
      </svg>
      <div class="block-label">${model.get('label')}</div>
      </div>
    `
    });
  };
  const setupVideoFullWidthPlugin = editor => {
    const options = R.pathOr({}, ['Config', 'pluginsOpts', bigordrVideoFullWidth])(editor);
    const {
      intl,
      /** @type {Categories} */
      categories
    } = options;
    const bigordrVideoFullWidthBlockName = `${bigordrVideoFullWidth}-block`;
    const bigordrVideoFullWidthBlockLabel = intl.t('full width video');
    const {
      DomComponents,
      BlockManager
    } = editor;
    // Full width video wrapper
    DomComponents.addType(bigordrVideoFullWidth, {
      extend: bigordrVideo,
      model: {
        defaults: {
          resizable: false,
          draggable: true,
          src: 'https://storage.coverr.co/videos/UdqLtOWUabcGdhChsop7F02bh02xpjQQ7G?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjExNDMyN0NEOTRCMUFCMTFERTE3IiwiaWF0IjoxNTg4NTcwMjc4fQ.uYn4qPOJtFEIbCNzX4Wt-mChpeJBquO-JD2047wi18M'
        },
        init() {
          // required to override the hooks in video component
          this.listenTo(this, 'change:ratio', this.handleRatioChange);
        },
        updated(/* property, value, prevValue, model, ...rest */
        ) {
          // required to override the hooks in video component
        }
      }
    });
    DomComponents.addType(bigordrVideoFullWidthOuterWrapper, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          name: intl.t('full width video'),
          classes: [bigordrVideoOuterWrapper],
          components: [{
            type: bigordrVideoFullWidthInnerWrapper
          }]
        }
      }
    });
    DomComponents.addType(bigordrVideoFullWidthInnerWrapper, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          draggable: false,
          layerable: false,
          classes: [bigordrVideoInnerWrapper],
          components: [{
            type: bigordrVideoFullWidth,
            draggable: false,
            layerable: false
          }]
        }
      }
    });
    DomComponents.addType(bigordrVideoFullWidthOuterWrapperEmpty, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          classes: [bigordrVideoOuterWrapper],
          components: [{
            type: bigordrVideoFullWidthInnerWrapperEmpty
          }]
        }
      }
    });
    DomComponents.addType(bigordrVideoFullWidthInnerWrapperEmpty, {
      extend: bigordrVideoWrapper,
      model: {
        defaults: {
          classes: [bigordrVideoInnerWrapper]
        }
      }
    });
    BlockManager.add(bigordrVideoFullWidthBlockName, {
      label: bigordrVideoFullWidthBlockLabel,
      category: categories.basic,
      content: {
        type: bigordrVideoFullWidthOuterWrapper,
        content: styles
      },
      render: ({
        model
      }) => `
      <svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
        <g transform="matrix(1,0,0,1,-6.60663,-9.09495e-13)">
            <g transform="matrix(1.37733,0,0,1.65228,-180.565,-864.206)">
                <path d="M207.809,832.911L800.909,832.911" style="fill:none;stroke:black;stroke-width:29.59px;"/>
            </g>
            <g transform="matrix(1,0,0,1,118.44,399.43)">
                <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                </g>
                <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                </g>
            </g>
            <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,918.773,624.57)">
                <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                </g>
                <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                    <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                </g>
            </g>
        </g>
        <g transform="matrix(0.93504,0,0,1,11.876,4.1044)">
            <g>
                <g transform="matrix(1.07796,0,0,0.792537,-0.957412,77.683)">
                    <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;stroke-linecap:square;stroke-linejoin:miter;"/>
                </g>
                <g transform="matrix(1,0,0,1,-5.38389,172.379)">
                    <g transform="matrix(0.512261,0,0,0.512261,245.802,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,-120.793,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,1.40527,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,123.603,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,368,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                </g>
                <g transform="matrix(1,0,0,1,-5.38389,501.655)">
                    <g transform="matrix(0.512261,0,0,0.512261,245.802,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,-120.793,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,1.40527,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,123.603,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                    <g transform="matrix(0.512261,0,0,0.512261,368,-272.972)">
                        <rect x="765.514" y="816.659" width="95.679" height="95.679"/>
                    </g>
                </g>
                <g transform="matrix(3.66112e-17,0.597907,-0.761557,4.66319e-17,922.089,134.831)">
                    <path d="M623.951,431.185L751.048,585.73L496.854,585.73L623.951,431.185Z"/>
                </g>
            </g>
        </g>
      </svg>
      <div class="block-label">${model.get('label')}</div>
`
    });
  };
  const removeVideoWrapperFn = R.curry((outerWrapper, ended) => {
    if (ended) {
      outerWrapper.remove();
    }
  });
  const addEvents = editor => {
    let removeVideoWrapper;
    editor.on('sorter:drag:start', (componentEl, componentModel) => {
      if (componentModel && componentModel.closest && componentModel.is) {
        removeVideoWrapper = removeVideoWrapperFn(componentModel);
        let outerWrapper;
        if (componentModel.is(bigordrVideoFullWidth)) {
          outerWrapper = componentModel.closest(`div.${bigordrVideoOuterWrapper}`);
        } else {
          outerWrapper = componentModel.closest(`div.${bigordrVideoAlignmentWrapper}`);
        }
        removeVideoWrapper = removeVideoWrapperFn(outerWrapper);
      }
    });
    editor.on('sorter:drag:end', ({
      modelToDrop,
      validResult /* dst, srcEl, targetCollection, warns */
    }) => {
      if (modelToDrop && modelToDrop.is && (modelToDrop.is(bigordrVideoFullWidth) || modelToDrop.is(bigordrVideo))) {
        if (removeVideoWrapper) {
          removeVideoWrapper(true);
        }
        if (modelToDrop.is(bigordrVideoFullWidth)) {
          // Add and empty video wrapper
          const videoWrapperOuter = validResult.trgModel.components().find(modelToDrop).replaceWith({
            type: bigordrVideoFullWidthOuterWrapperEmpty
          });
          const videoWrapperInner = videoWrapperOuter.components().findWhere({
            type: bigordrVideoFullWidthInnerWrapperEmpty
          });
          videoWrapperInner.append(modelToDrop);
          return;
        }

        // Add and empty video wrapper
        const videoAlignmentWrapperEmpty = validResult.trgModel.components().find(modelToDrop).replaceWith({
          type: bigordrVideoAlignmentWrapperEmpty
        });
        const videoWrapperOuter = videoAlignmentWrapperEmpty.components().findWhere({
          type: bigordrVideoOuterWrapperEmpty
        });
        const videoWrapperInner = videoWrapperOuter.components().findWhere({
          type: bigordrVideoInnerWrapperEmpty
        });
        const targetStyle = validResult.trgModel.getStyle();
        const value = R.values(targetStyle)[0];
        const key = R.keys(targetStyle)[0];
        addVideoAlignment(videoAlignmentWrapperEmpty, key, value);
        videoWrapperInner.append(modelToDrop);
        const currentStyle = modelToDrop.get('currentStyle');
        if (currentStyle) {
          modelToDrop.parent().parent().setStyle(currentStyle);
        }
      }
    });
    editor.on('component:remove', component => {
      const hasChangeProvider = R.hasPath(['changed', 'provider'])(component);
      if (component && component.is && (component.is(bigordrVideoFullWidth) || component.is(bigordrVideo)) && !hasChangeProvider) {
        let outerWrapper;
        let isOuterWrapperClassName;
        if (component.is(bigordrVideoFullWidth)) {
          outerWrapper = component.parent().parent();
          isOuterWrapperClassName = bigordrVideoOuterWrapper;
        } else {
          outerWrapper = component.parent().parent().parent();
          isOuterWrapperClassName = bigordrVideoAlignmentWrapper;
        }
        if (!outerWrapper) {
          return;
        }
        const isOuterWrapper = outerWrapper.getClasses().includes(isOuterWrapperClassName);
        const hasChildren = outerWrapper.getEl().children.length >= 1;
        if (outerWrapper && hasChildren && isOuterWrapper) {
          outerWrapper.remove();
        }
      }
    });
  };
  var _default = editor => {
    // Video wrappers has to be added before the plugins
    setupVideoWrappers(editor);
    setupVideoPlugin(editor);
    setupVideoFullWidthPlugin(editor);

    // Video Events
    addEvents(editor);
  };
  _exports.default = _default;
});