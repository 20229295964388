define("client/pods/components/tabs/for-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jvwLxtmI",
    "block": "{\"symbols\":[\"subTab\",\"index\",\"@tab\",\"@documentationResource\",\"@isParent\",\"&attrs\",\"&default\",\"@tabs\"],\"statements\":[[4,\"each\",[[23,8,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"tab-parent-container \",[28,\"if\",[[23,1,[\"isActive\"]],\"tab-visible\"],null],\" \",[28,\"if\",[[23,5,[]],\"tab-parent\"],null]]]],[12,\"data-tabs-content\",[23,1,[\"value\"]]],[12,\"data-tabs-content-parent\",[28,\"if\",[[23,1,[\"hasSubTabs\"]],\"true\",\"false\"],null]],[12,\"hidden\",[28,\"if\",[[23,1,[\"isActive\"]],false,true],null]],[13,6],[8],[0,\"\\n    \"],[14,7,[[23,1,[]],[23,2,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[5,\"documentation/features-popup\",[],[[\"@isHelp\",\"@tab\",\"@documentationResource\"],[true,[23,3,[]],[28,\"concat\",[[23,4,[]],\"-help\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/tabs/for-each/template.hbs"
    }
  });
});