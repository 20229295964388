// import DS from 'ember-data'
// import AdapterMixin from '../../../mixins/adapters-new'
// import Model from './model'

// export default DS.Adapter.extend(AdapterMixin, {
//   modelObj: Model,
//   idParam: false
// })
define("client/pods/automations/then-details/adapter", [], function () {
  "use strict";
});