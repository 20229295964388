define("client/pods/components/documentation/documentation-icon/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DocumentationDocumentationIconComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _class = class DocumentationDocumentationIconComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "users", _descriptor2, this);
      _initializerDefineProperty(this, "displayModal", _descriptor3, this);
      _initializerDefineProperty(this, "tags", _descriptor4, this);
      _initializerDefineProperty(this, "dirty", _descriptor5, this);
      _initializerDefineProperty(this, "isEditing", _descriptor6, this);
      _initializerDefineProperty(this, "isNew", _descriptor7, this);
      _initializerDefineProperty(this, "displayMoreToggle", _descriptor8, this);
      _initializerDefineProperty(this, "displayEditModal", _descriptor9, this);
      _defineProperty(this, "adapterName", 'documentation');
    }
    get iconVisible() {
      const documentationId = R.pathOr(null, ['args', 'documentationId'])(this);
      if (!documentationId) {
        return false;
      }
      if (this.allowedEdit) {
        return true;
      }
      if (this.args.onlyShowForEditor) {
        return false;
      }
      return R.pathOr(false, ['args', 'documentation', 'hasDocumentation'])(this);
    }
    get allowedEdit() {
      // const isAdmin = this.users.getProp('isAdmin')
      const isEditor = this.users.isDocumentationsEditor;
      return isEditor;
    }
    showInfo() {
      this.displayModal = !this.displayModal;
    }
    toggleDisplayEditModal() {
      this.displayEditModal = !this.displayEditModal;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "displayModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isEditing", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isNew", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "displayMoreToggle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "displayEditModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showInfo", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDisplayEditModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDisplayEditModal"), _class.prototype), _class);
});