define("client/pods/components/products/inventory/inventory-status/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    translate: true,
    isEmptyWarning: true,
    classNames: [''],
    classNameBindings: ['hasTagClass:tag', 'isEmpty:is-danger'],
    hasTagClass: Ember.computed('product.hasInventory', 'isTag', function () {
      if (this.get('product.hasInventory') && this.isTag) {
        return true;
      }
      return false;
    }),
    qty: Ember.computed('qtyParam', 'product._data.isSet', 'product.inventory._data.{onHandQty,onBookQty,salesInvoicesUndispatchedQty,salesConsignmentsUndispatchedQty,salesOrdersPendingQty,salesConsignmentPendingQty,purchasesPendingQty,purchasesConsignmentPendingQty,availableQty}', function () {
      const isSet = R.pathOr(false, ['product', '_data', 'isSet'])(this);
      if (isSet) {
        return '*';
      }
      const inventory = this.get('product.inventory');
      if (inventory) {
        const qtyParam = this.qtyParam;
        return Number(inventory.get(qtyParam));
      }
      return 0;
    }),
    isEmpty: Ember.computed('isEmptyWarning', 'qty', 'product.inventory._data.{onHandQty,onBookQty,salesInvoicesUndispatchedQty,salesConsignmentsUndispatchedQty,salesOrdersPendingQty,salesConsignmentPendingQty,purchasesPendingQty,purchasesConsignmentPendingQty,availableQty}', function () {
      if (this.isEmptyWarning) {
        const qty = this.qty;
        if (qty === '*') {
          return false;
        }
        const inventory = this.get('product.inventory');
        if (inventory) {
          return inventory.isLessThanEqualQty(0, qty);
        }
      }
      return false;
    })
  });
});