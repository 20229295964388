define("client/pods/components/reports/dashboard/card-revenue-stats/component", ["exports", "ramda", "client/mixins/big"], function (_exports, R, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_big.default, {
    tagName: '',
    commissionNormalPercent: '',
    //set default here

    taxMethod: Ember.computed('isTaxExclusive', function () {
      return this.isTaxExclusive ? 'tax exclusive' : 'tax inclusive';
    }),
    getExcl(param) {
      return this.get(`${param}.exclTotal`);
    },
    getIncl(param) {
      return this.get(`${param}.inclTotal`);
    },
    getValue(param, segmentPropData) {
      if (segmentPropData && !R.isEmpty(segmentPropData)) {
        const param = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';
        const rounding = 0;
        return this.sumPropAndAutoRound(rounding, param, segmentPropData);
      }
      return this.isTaxExclusive ? this.getExcl(param) : this.getIncl(param);
    },
    net: Ember.computed('report.revenue.net.{exclTotal,inclTotal}', 'isTaxExclusive', 'segment.net.[]', function () {
      return this.getValue('report.revenue.net', this.get('segment.net'));
    }),
    invoices: Ember.computed('report.revenue.invoices.{exclTotal,inclTotal}', 'isTaxExclusive', 'segment.invoices.[]', function () {
      return this.getValue('report.revenue.invoices', this.get('segment.invoices'));
    }),
    estimatedCommissionNormalPercent: Ember.computed('isGuest', 'tokenData', 'model', function () {
      return this.report?.revenue?.invoices?.estimatedCommissionNormalPercent;
    }),
    estimatedCommissions: Ember.computed('invoices', 'estimatedCommissionNormalPercent', function () {
      const invoices = this.invoices;
      const estimatedCommissionNormalPercent = this.estimatedCommissionNormalPercent;
      if (!estimatedCommissionNormalPercent) {
        return '';
      }
      const byAmountBig = this.newBig(invoices).times(estimatedCommissionNormalPercent).div(100);
      const commissionInclTotal = byAmountBig.toFixed(0);
      return commissionInclTotal;
    }),
    returns: Ember.computed('report.revenue.returns.{exclTotal,inclTotal}', 'isTaxExclusive', 'segment.returns.[]', function () {
      return this.getValue('report.revenue.returns', this.get('segment.returns'));
    }),
    average: Ember.computed('report.revenue.net.average.{exclTotal,inclTotal}', 'isTaxExclusive', 'segment.average.[]', function () {
      return this.getValue('report.revenue.net.average', this.get('segment.average'));
    })
  });
});