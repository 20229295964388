define("client/pods/components/froala/erp-options-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oBuaK1m7",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@modalDialogIsVisible\",\"@pasteAsPlainText\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@modalDialogIsVisible\",\"@onToggle\",\"@closeOnOverlayClick\",\"@modalSize\"],[[23,2,[]],[28,\"fn\",[[28,\"mut\",[[23,2,[]]],null]],null],false,\"small\"]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@errors\"],[\"froala\",\"pasteAsPlainText\",[22,\"errors\"]]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-toggler\",[],[[\"@label\",\"@toggleValue\"],[\"paste as plain text\",[23,3,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/btn-save\",[],[[\"@icon\",\"@label\",\"@onSubmit\"],[false,\"done\",[23,1,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/froala/erp-options-modal/template.hbs"
    }
  });
});