define("client/pods/components/documents/document-print/print-list/print-doc/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    printDoc: Ember.computed('dispatchProcess._data.code', 'model._data.dispatchProcess', 'isAlwaysPrint', function () {
      const dispatchProcess = this.get('model._data.dispatchProcess');
      if (this.get('isAlwaysPrint')) {
        return true;
      }
      if (this.get('dispatchProcess._data.code') === dispatchProcess) {
        return true;
      }
      if (this.get('dispatchProcess._data.code') === 'unprepared' && dispatchProcess === '') {
        return true;
      }
      return false;
    })
  });
});