define("client/pods/components/operator/change-logs/tab-container/component", ["exports", "ember-concurrency", "client/utils/nventor"], function (_exports, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    settingsService: Ember.inject.service('settings'),
    results: null,
    resultsToggle: false,
    showFilters: true,
    adapterName: 'operator/change-logs',
    filtersAdapterName: 'operator/change-logs/filters',
    init() {
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.crud.addLists(this, ['operator/change-logs/lists/menu', 'publicStatuses']);
      this._super(...arguments);
    },
    afterLoadLists() {
      this._super(...arguments);
      this.dash();
    },
    dash(model, results) {
      this.set('showResults', false);
      const tab = this.tab;
      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        results = this.crud.searchRecordsTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
      }
      const subTabOptions = {
        component: 'operator/change-logs/panels-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    display(model, {
      tabOptions
    } = {}) {
      const browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (browserWidth < 1300) {
        this.set('showResults', false);
      }
      const tab = this.tab;
      const panelOptions = {
        component: 'operator/change-logs/panels-display',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },
    new(subTabOptions) {
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },
    toggleResults(results) {
      const subTab = this.tabsManager.getActiveSubTab(this.tab);
      if (subTab?.component === 'operator/change-logs/panels-dash') {
        return results;
      }
      Ember.set(this, 'showResults', true);
      return results;
    },
    edit(model, {
      tabOptions
    } = {}) {
      this.set('showFilters', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'operator/change-logs/panels-edit',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },
    actions: {
      dash() {
        this.dash();
      },
      display(model, {
        tabOptions
      } = {}) {
        if (model) {
          this.display(...arguments);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },
      new(subTabOptions) {
        // create tab within product tab
        this.new(subTabOptions);
      },
      afterSearch(dashComponent, results) {
        const tab = this.tab;
        if (!_nventor.default.isNilOrEmpty(results)) {
          if (this.tabsManager.isActiveSubTab(tab, {
            component: dashComponent
          })) {
            return this.dash({}, results);
          }
        }
        return this.set('showResults', true);
      }
    }
  }, _applyDecoratedDescriptor(_obj, "dash", [_dec], Object.getOwnPropertyDescriptor(_obj, "dash"), _obj), _applyDecoratedDescriptor(_obj, "display", [_dec2], Object.getOwnPropertyDescriptor(_obj, "display"), _obj), _applyDecoratedDescriptor(_obj, "new", [_dec3], Object.getOwnPropertyDescriptor(_obj, "new"), _obj), _applyDecoratedDescriptor(_obj, "toggleResults", [_dec4], Object.getOwnPropertyDescriptor(_obj, "toggleResults"), _obj), _applyDecoratedDescriptor(_obj, "edit", [_dec5], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _obj));
});