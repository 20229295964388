define("client/pods/components/linepay/provider-editor/component", ["exports", "ramda", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor", "client/pods/options/model"], function (_exports, R, _component, _emberConcurrencyDecorators, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LinepayProviderEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _class = class LinepayProviderEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "modelProxy", _descriptor2, this);
      _initializerDefineProperty(this, "providerErrors", _descriptor3, this);
      _defineProperty(this, "adapterName", this.args?.customAdapterName || this.args?.adapterName || 'settings');
      this.crud.addLists(this, ['payments/linepay/lists/providers']);
      const isEditing = this?.args?.isEditing;
      const model = this.args?.model || {};
      const tab = this.args?.tab || {};
      this.tab = tab;
      this.modelProxy = _nventor.default.createModel(_model.default, {
        enabled: false,
        isDefault: false,
        merchantId: '',
        channelId: '',
        channelSecret: ''
      });
      if (isEditing) {
        const parentModel = R.pathOr(null, ['args', 'parentModel'])(this);
        const linePay = R.pathOr({}, ['_data', 'linePay'])(parentModel);
        const providers = R.pathOr([], ['providers'])(linePay);
        const data = R.pipe(R.pathOr({}, ['_data']), R.clone)(model);
        const currentEditModelIndex = R.findIndex(R.pathEq(['_data', 'merchantId'], data.merchantId))(providers);
        this.modelProxy.set('_data', data);
        this.modelProxy.set('index', currentEditModelIndex);
      }
    }
    get linePayProviderList() {
      // this.crud.lists.payments-linepay-lists-providers
      const paymentsLinepayListsProviders = this.crud?.lists?.['payments-linepay-lists-providers'] || [];
      // @TODO: remove filter when implemented payuni LinePay
      return paymentsLinepayListsProviders.filter(model => {
        if (model._data.value != 'tapPay') {
          return false;
        }
        return true;
      });
    }
    get hasErrors() {
      if (this.providerErrors.length !== 0) {
        return true;
      }
      return false;
    }

    // @TODO: should all use backend validation
    validateProvider() {
      // Uniq names
      const currentMerchantId = R.pathOr('', ['modelProxy', '_data', 'merchantId'])(this);
      if (!currentMerchantId) {
        this.providerErrors = [{
          type: 'isRequired',
          message: 'is required',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
      }
      if (this.hasErrors) {
        throw new Error('Frontend Validation Error: linepayProviderEditor Error');
      }
    }
    isMerchantIdUniq() {
      // Uniq names
      const providers = R.pathOr([], ['args', 'parentModel', '_data', 'linePay', 'providers'])(this);
      const currentMerchantId = R.pathOr('', ['modelProxy', '_data', 'merchantId'])(this);
      const providerMerchantIds = R.pipe(R.map(R.pathOr('', ['_data', 'merchantId'])))(providers);
      const _isCurrentIds = providerMerchantIds?.filter(id => id === currentMerchantId);
      if (_isCurrentIds?.length !== 1) {
        this.providerErrors = [{
          type: 'isUnique',
          message: 'not unique',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
        throw new Error('MerchantId Already in Used!!!');
      }
    }
    *updateProviders({
      remove = false
    } = {}) {
      const parentModel = R.pathOr(null, ['args', 'parentModel'])(this);
      try {
        if (parentModel) {
          const linePay = R.pathOr({}, ['_data', 'linePay'])(parentModel);
          const providers = R.pathOr([], ['providers'])(linePay);
          this.providerErrors = [];
          if (this?.args?.isEditing) {
            const index = this.modelProxy.index;
            providers.removeAt(index);
            if (!remove) {
              providers.insertAt(index, this.modelProxy);
              this.validateProvider();
              this.isMerchantIdUniq();
            }
          } else {
            this.validateProvider();
            const isFirstProvider = R.length(providers) === 0;
            if (isFirstProvider) {
              const merchantId = this?.modelProxy?._data?.merchantId;
              parentModel.set('_data.linePay.defaultProvider', merchantId);
              this.modelProxy.setData('enabled', true);
            }
            providers.addObject(this.modelProxy);
          }
          parentModel.setData('linePay.providers', providers);
          yield this.crud.updateRecordTask.perform({
            adapterName: this?.args?.adapterName,
            component: this,
            model: parentModel,
            updateProps: ['linePay'],
            tab: this.tab
          });
        }
        this.onCloseDialogAction();
      } catch (error) {
        console.log(error.message);
      }
    }
    *cancelEdittingTask() {
      this.isMerchantIdUniq();
      this.onCloseDialogAction();
    }
    onCloseDialogAction() {
      const toggleShowEditor = R.pathOr(null, ['args', 'toggleShowEditor'])(this);
      const onCloseDialogAction = R.pathOr(null, ['args', 'onCloseDialogAction'])(this);
      const onCloseDialogActionListSelector = R.pathOr(null, ['args', 'addItemOptions', 'onCloseDialogActionListSelector'])(this);
      const providers = R.pathOr(null, ['args', 'parentModel', '_data', 'linePay', 'providers'])(this);
      const isFirstProvider = R.length(providers) === 1;
      if (isFirstProvider) {
        return onCloseDialogActionListSelector();
      }
      if (onCloseDialogAction) {
        return onCloseDialogAction();
      }
      if (toggleShowEditor) {
        return toggleShowEditor(false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelProxy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "providerErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateProviders", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateProviders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEdittingTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdittingTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseDialogAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDialogAction"), _class.prototype), _class);
});