define("client/pods/components/forms/form-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8b8TJZph",
    "block": "{\"symbols\":[\"@containerId\",\"&attrs\",\"@isRunning\",\"@formInitData\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isUserAllowed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"form-container \",[23,0,[\"columnsClassName\"]],\" form-container__\",[23,1,[]]]]],[13,2],[8],[0,\"\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n      \"],[14,5,[[23,4,[]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-container/template.hbs"
    }
  });
});