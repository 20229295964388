define("client/pods/filters/criterias/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "client/constants"], function (_exports, _adapter, _adaptersNew, _model, _nventor, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    findAll() {
      const data = [{
        label: 'greater than or equal to',
        value: _constants.default.automationsCriterias.greaterThanOrEqualTo
      }, {
        label: 'greater than',
        value: _constants.default.automationsCriterias.greaterThan
      }, {
        label: 'is between',
        value: _constants.default.automationsCriterias.isBetween
      }, {
        label: 'less than or equal to',
        value: _constants.default.automationsCriterias.lessThanOrEqualTo
      }, {
        label: 'less than',
        value: _constants.default.automationsCriterias.lessThan
      }, {
        label: 'equals',
        value: _constants.default.automationsCriterias.equals
        // }, {
        //   label: 'not equals',
        //   value: constants.automationsCriterias.notEquals
      }];
      return _nventor.default.resolve(data);
    }
  });
});