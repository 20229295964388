define("client/pods/components/documents/document-print/footer-purchases-signature/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vcxaEGCE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"documents-print__section\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"documents-print__signature-table u-border u-full-width\"],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-label\"],[8],[1,[28,\"tt\",[\"from person\"],null],false],[0,\":\"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-value\"],[8],[1,[28,\"user/get\",[\"name\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-label\"],[8],[1,[28,\"tt\",[\"date\"],null],false],[0,\":\"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-value\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-label\"],[8],[1,[28,\"tt\",[\"transporter\"],null],false],[0,\":\"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-value\"],[8],[1,[24,[\"model\",\"_data\",\"transporterLabel\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-label\"],[8],[1,[28,\"tt\",[\"name\"],null],false],[0,\":\"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-value\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-label\"],[8],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-value\"],[8],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-label\"],[8],[1,[28,\"tt\",[\"signature\"],null],false],[0,\":\"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"documents-print__signature-value\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/footer-purchases-signature/template.hbs"
    }
  });
});