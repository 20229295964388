define("client/helpers/taxCalculator", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend(_big.default, {
    constants: Ember.inject.service(),
    rounding: 8,
    unrounded: 8,
    compute(params /* , hash */) {
      const price = params[0];
      const taxMethodKey = params[1];
      const taxRate = params[2];
      const returnValue = params[3] || false;
      // const rounding = hash.rounding || this.get('rounding')
      const unrounded = this.unrounded;
      const taxRateBig = this.newBig(taxRate).plus(1);
      const priceBig = this.newBig(this.get(price));
      let inclPriceBig;
      let exclPriceBig;
      if (taxMethodKey === this.get('constants.taxMethods.exclusive')) {
        exclPriceBig = priceBig;
        inclPriceBig = exclPriceBig.times(taxRateBig);
      } else {
        inclPriceBig = priceBig;
        exclPriceBig = inclPriceBig.div(taxRateBig);
      }
      const taxAmtBig = inclPriceBig.minus(exclPriceBig);
      const values = {
        tax: taxAmtBig.toFixed(unrounded),
        exclPrice: exclPriceBig.toFixed(unrounded),
        inclPrice: inclPriceBig.toFixed(unrounded)
      };
      if (returnValue) {
        return values[returnValue];
      }
      return values;
    }
  });
});