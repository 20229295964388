define("client/pods/components/helpers/drag-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r32J/utk",
    "block": "{\"symbols\":[\"&default\",\"@hasGrip\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"classNames\",\"icon\",\"hoverIcon\"],[\"draggable__grip-icon-container\",\"fa-solid fa-grip-vertical\",\"fas fa-arrows-alt\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"draggable\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"hoverIcon\"],[\"fa-solid fa-grip-vertical\",\"fas fa-arrows-alt\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/drag-container/template.hbs"
    }
  });
});