define("client/pods/helpers/service", ["exports", "ramda-extension"], function (_exports, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let HelpersService = _exports.default = (_dec = Ember._action, _class = class HelpersService extends Ember.Service {
    setValue(model, prop, value) {
      Ember.set(model, prop, value);
    }
    setValueFromModel(model, prop, fromModelProp, fromModel) {
      const fromModelValue = R_.dotPath(fromModelProp)(fromModel) || '';
      Ember.set(model, prop, fromModelValue);
    }
    unsetValue(model, prop, value) {
      Ember.set(model, prop, value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "setValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setValue"), _class.prototype), _class);
});